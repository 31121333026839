import React from 'react';
import { Trans } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import { BannerType } from '../../../types/banner/banner';

import { REFERRAL_DEPOSIT_DFI_BONUS, REFERRER_DEPOSIT_DFI_BONUS } from './referralConst';

export default function SignupBonusLogin({ buttons }) {
  return (
    <Banner type={BannerType.Info} actionTemplate={buttons}>
      <Trans>Get ${{ REFERRAL_DEPOSIT_DFI_BONUS }} worth of DFI for each of your referrals who sign up and make a first-time investment, plus they get ${{ REFERRER_DEPOSIT_DFI_BONUS }} worth of DFI!</Trans>
    </Banner>
  );
}
