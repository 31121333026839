import { MyinfoAuthoriseUrl, MyinfoPerson } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class MyinfoApi extends CakeApiBase {
  async getAuthoriseUrl() {
    return this.requestManager.get<MyinfoAuthoriseUrl>('myinfo');
  }

  async resumeConsent(authCode: string) {
    return this.requestManager.post<MyinfoPerson>('myinfo/resume-consent', {
      authCode,
    });
  }

  async kycConfirm() {
    return this.requestManager.put('myinfo/kyc-confirm');
  }

  async getPersonData() {
    return this.requestManager.get<MyinfoPerson>('myinfo/person');
  }
}
