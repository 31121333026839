import { Coin, CoinNetwork } from '@cakedefi/cake-sdk/schema';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';

export const getWalletState = (state: RootState) => state.wallet;
export const walletByCoinsSelector = createSelector(
  getWalletState,
  s => s.walletByCoins,
);
export const walletCoinsMapSelector = createSelector(getWalletState, s => s.coins);
export const walletCoinByIdSelector = (coinId: string) => createSelector(
  walletCoinsMapSelector,
  walletByCoins => walletByCoins[coinId],
);
export const walletPageLoadingSelector = createSelector(
  getWalletState,
  ({ isLoading, error }) => ({
    isLoading, error,
  }),
);

export const selectCoins = (coins: Coin[], filterFn: (coin: Coin) => boolean) => coins
  .filter(filterFn)
  .map((coin: Coin) => {
    const { id, ...rest } = coin;
    return {
      label: id,
      value: id,
      icon: id,
      id,
      ...rest,
    };
  });

export const walletCoinsSelector = createSelector((s: RootState) => s.wallet, wallet => {
  const { coins } = wallet;
  if (!coins) {
    return [];
  }
  return Object.values(coins);
});

export const walletCoinNetworksSelector = createSelector(walletCoinsSelector, wallets => {
  const coinNetworks = wallets.reduce(
    (networks: Map<string, CoinNetwork>, wallet) => {
      (wallet.networks ?? []).forEach((network) => {
        if (!networks.has(network.id)) {
          networks.set(network.id, network);
        }
      });
      return networks;
    },
    new Map<string, CoinNetwork>(),
  );
  return coinNetworks;
});

export const walletCoinsWithDiscontinuedSelector = (state: RootState) => {
  const { coinsWithDiscontinued } = state.wallet;
  if (!coinsWithDiscontinued) {
    return [];
  }
  return Object.values(coinsWithDiscontinued);
};

export const fiatPurchasableCoinsSelector = createSelector(
  walletCoinsSelector,
  (coins) => selectCoins(coins, (coin: Coin) => coin.coinPurchasableWithFiat),
);

export const fiatSwappableCoinsSelector = createSelector(
  walletCoinsSelector,
  (coins) => selectCoins(coins, (coin: Coin) => coin.fiatTargetSwapPossible),
);

export const fiatBuyCryptoCoinsSelector = createSelector((state: RootState) => state, state => [
  ...fiatPurchasableCoinsSelector(state),
  ...fiatSwappableCoinsSelector(state),
]);

export const coinSwapBaseCoinSelector = createSelector(
  walletCoinsSelector,
  coins => selectCoins(coins, (coin: Coin) => coin.coinSwapBaseCoin),
);

export const walletUserPortfolioSelector = createSelector(
  getWalletState,
  walletState => walletState.walletUserPortfolio,
);
