const Variables = {
  textSecondary: 'rgba(0, 0, 0, .6)',
  textLight: '#615C6B',
  uiSpaceBase: '8px',

  // size tokens/padding/margin
  extraSmall: '4px',
  smaller: '6px',
  small: '8px',
  preMedium: '12px',
  medium: '16px',
  large: '24px',
  larger: '32px',
  preExtraLarge: '40px',
  extraLarge: '48px',
  extraLargeRound: '50px',

  superLarge: '160px',

  // color tokens
  electricBerry: '#5B10FF',
  verificationGreen: '#02B31B',
  green: '#00E676',
  lightGreen: '#28A745',
  kycGreen: '#37AB57',
  red: '#F50057',
  redInvalidControl: '#FF0000',
  gray100: 'rgba(0, 0, 0, 0.1)',
  gray200: 'rgba(0, 0, 0, 0.2)',
  gray300: 'rgba(0, 0, 0, 0.3)',
  gray400: 'rgba(0, 0, 0, 0.4)',
  gray600: 'rgba(0, 0, 0, 0.6)',
  blueInform: '#F5F4F7',
  black: '#000',
  white: '#fff',
  whiteABit: '#eee',
  faded: 'rgba(0, 0, 0,.16)',
  blue: '#1A77F2',
  lightBlue: '#EAFFFF',
  lightBlueBorder: '#D4EDED',
  transparent: 'rgba(0, 0, 0, 0)',
  pink: '#FF00FF',
  pink80: 'rgba(255, 0, 175, 0.08)',
  pink100: 'rgba(255, 0, 175, 0.1)',
  rumSwizzle: '#FAF6E7',
  lightElBerry: '#F0EAFF',
  white600: 'rgba(255, 255, 255, 0.6)',
  lightNeutral: 'rgba(2, 0, 18, 0.6)',
  warningYellow: '#FFCA28',
  backgroundWarningYellow: '#FF962914',
  superLightBlue: '#F5F1FF',
  moreLightElBerry: '#C1A4FF',
  backgroundGray: '#F4F3F7',
  hyperBerry: '#F2ECFF',
  hyperBerry6: '#5F42FF',
  brass: 'rgba(212, 89, 0, 0.6)',
  marketingLabel: '#0B0B40',
  lightNeutral0: '#615C6A',
  cultured: '#F4F3F6',
  vampireBlack: '#05010F',
  bronze: '#B08453',
  hyperberryDark1: '#080514',
  hyperberryDark2: '#0D0A24',
  hyperberryDark3: '#17103D',
  hyperberryDark4: '#241961',
  hyperberryDark5: '#3B299E',
  hyperberryDark7: '#7578FF',
  cakeEliteYellow: '#D0AD6A',
  cakeEliteYellowText: '#816B42',
  pureSilver: '#9A95A4',
  surface2: '#F9F8FA',
  lightPurple25: 'rgba(234, 71, 237, 0.25)',
  lightCyan: '#00F5D4',
  lightPurple: '#EFE7FF',

  // shadow
  primaryBoxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.04)',

  // borders
  primaryBorder: '1px solid rgba(0, 0, 0, .2)',
  fadedBorder: '.5px solid rgba(0,0,0,.16)',
  focusedBorder: '2px solid #5B10FF',
  uiBorderWidth: '0.5px',

  breakpoints: {
    xs: '360px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1440px',
  },

  fontFamily: {
    monoSpace: '"IBM Plex Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    spaceGrotesk: 'Space Grotesk',
  },

  text: {
    fontSize: '1.6rem',
    letterSpacing: '0.44px',
    lineHeight: '2.4rem',
    marginBottom: 0,
  },

  // popover
  popoverBaseStyle: {
    padding: '4px 16px',
  },

  // banner
  bannerTypography: {
    fontSize: '1.6rem',
    fontWeight: '500',
    minHeight: '65px',
  },

  // tables
  tableHeaderTypography: {
    fontSize: '1.6rem',
    fontWeight: '500',
  },
  tableBodyTypography: {
    fontSize: '1.6rem',
    fontWeight: '500',
  },
  fontSize: {
    small: '1.4rem',
    base: '1.6rem',
    extraBase: '1.8rem',
    h0: '2rem',
    h1: '2.4rem',
    giant: '3.4rem',
    superGiant: '10rem',
  },

  fontWeight: {
    semiBold: 400,
    bold: 500,
    mediumBold: 600,
    extraBold: 700,
  },

  // freezer
  freezerBaseApyBackgroundColor: '#DFD9FF',
  freezerBonusApyBackgroundColor: '#BFB3FF',

  baseText: {
    fontSize: '1.6rem',
    letterSpacing: '0.44px',
    lineHeight: '2.4rem',
  },
  zIndex: {
    standaloneModal: 1100,
    modal: 1040,
    dropdown: 1041,
  },
};

export const CoinIconSize = {
  width: Variables.larger,
  height: Variables.larger,
};

export const CoinIconSizeInline = {
  display: 'inline',
  width: Variables.larger,
  height: Variables.larger,
};

export const PopoverPadding = {
  padding: `${Variables.extraSmall} ${Variables.medium}`,
};

export const getMinWidth = breakpoint => `@media (min-width: ${breakpoint})`;
export const getMaxWidthBreakPoint = breakpoint => `@media (max-width:${breakpoint})`;
export const getMinWidthXxl = getMinWidth(Variables.breakpoints.xxl);
export const getMinWidthXl = getMinWidth(Variables.breakpoints.xl);
export const getMinWidthLg = getMinWidth(Variables.breakpoints.lg);
export const getMinWidthMd = getMinWidth(Variables.breakpoints.md);
export const getMinWidthSm = getMinWidth(Variables.breakpoints.sm);
export const getMinWidthXs = getMinWidth(Variables.breakpoints.xs);
export const isDesktop = (width: number) => width > 992;
export default Variables;
