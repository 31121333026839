// TODO: eslint is complaining about TS types, will convert back to TS when we enable eslint/ts
export class HeaderLink {
  constructor(
    text,
    link,
    children,
    isDisable,
    popoverContent,
    textLabel,
    isExternal = false,
  ) {
    this.text = text;
    this.link = link;
    this.children = children;
    this.isDisable = isDisable;
    this.popoverContent = popoverContent;
    this.textLabel = textLabel;
    this.isExternal = isExternal;
  }
}
