import { UserQuizzesInfo } from '../../schema/learnEarn';
import { CakeApiBase } from '../CakeApiBase';

interface UpdateUserQuizRecord {
  quizId: string;
  quizQuestionId: string;
  separateQuizProgressId?: string;
}

export class LearnEarnApi extends CakeApiBase {
  async fetchUserQuizzesInfo() {
    return this.requestManager.get<UserQuizzesInfo>('/quiz');
  }

  async claimEarnSlot(quizId: string) {
    return this.requestManager.post<void>('/quiz/claim-earn-slot', { quizId });
  }

  async updateUserQuizRecord({
    quizId,
    quizQuestionId,
    separateQuizProgressId,
  }: UpdateUserQuizRecord) {
    return this.requestManager.post<void>(`/quiz/${quizId}/${quizQuestionId}/update`, {
      separateQuizProgressId,
    });
  }
}
