import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/rootReducer';
import { getCoinPriceInUSD } from '../../../liquidity-mining/liquidityMiningUtils';
import { getWalletState } from '../../../wallet/walletSelectors';

export const getIsEthLuckyDrawActive = (state: RootState) => state.ETHLuckyDraw.isCampaignStillActive;
export const getIsEthLuckyDrawDataLoading = (state: RootState) => state.ETHLuckyDraw.state === 'LOADING';
export const getIsEthLuckyDrawDataLoadStatus = (state: RootState) => state.ETHLuckyDraw.state;
export const getEthLuckyDrawExpiryDate = (state: RootState) => state.ETHLuckyDraw.promotionRetiredOn;
export const getEthLuckyDrawStartDate = (state: RootState) => state.ETHLuckyDraw.promotionVisibleOn;
export const getEthLuckyDrawTicketCount = (state: RootState) => state.ETHLuckyDraw.tickets;
export const getIsEligibleForLuckyDrawStake = (coinId: string) => (state: RootState) => coinId === 'ETH' && state.ETHLuckyDraw.isCampaignStillActive;
export const getTotalStakedForPromo = (state: RootState) => state.ETHLuckyDraw.totalStakedWithInPromo;
export const getTicketCountForUser = (state: RootState) => state.ETHLuckyDraw.tickets;
export const getLossForUser = (state: RootState) => state.ETHLuckyDraw.loss;
export const getBalanceForUser = (state: RootState) => state.ETHLuckyDraw.balance;
export const getCanShowIdleETHSectionForUser = (state: RootState) => (state.ETHLuckyDraw.loss.lending && +state.ETHLuckyDraw.loss.lending > 0)
  || (state.ETHLuckyDraw.loss.idle && +state.ETHLuckyDraw.loss.idle > 0);

export const getEthPriceInUSD = createSelector(
  (state: RootState) => state.wallet.coins,
  (state: RootState) => state.currency.fiat.rates,
  (coins, rates) => {
    if (!coins || !rates) {
      return 0;
    }

    return getCoinPriceInUSD(coins, 'ETH');
  },
);

export const getUserEthWallet = createSelector(
  getWalletState,
  (wallet) => wallet?.walletByCoins?.filter(item => item.id === 'ETH')[0],
);
