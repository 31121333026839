import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { isDefiChainTheme, WebappTheme } from 'types/theme';
import Variables from 'Variables.styles';
import DisclaimerCheckboxBase from './DisclaimerCheckboxBase';

const useStyles = createUseStyles<
  string,
  {
    appTheme: WebappTheme;
  }
>({
  link: ({ appTheme }) => ({
    color: isDefiChainTheme(appTheme)
      ? Variables.pink
      : Variables.electricBerry,
  }),
});

interface DisclaimerCheckboxProps {
  isAcceptedTerms: boolean;
  setIsAcceptedTerms: (value: boolean) => void;
  appTheme?: WebappTheme;
}

export default function DisclaimerCheckbox({
  isAcceptedTerms,
  setIsAcceptedTerms,
  appTheme = 'CAKE',
}: DisclaimerCheckboxProps) {
  const classes = useStyles({
    appTheme,
  });
  return (
    <DisclaimerCheckboxBase
      isAccepted={isAcceptedTerms}
      setIsAccepted={setIsAcceptedTerms}
      appTheme={appTheme}
    >
      <Trans>
        I hereby confirm that I agree to the above and to the <a className={classes.link}href="/terms-and-conditions" target="_blank">Terms of Service</a>.
      </Trans>
    </DisclaimerCheckboxBase>
  );
}
