import { RevenueTxOperation } from '../lib/customType';

const readableRevenueMap: Record<RevenueTxOperation, string> = {
  NA_MKT_FEE_RCVD: 'Share selling',
  NA_FEE_RCVD: 'Withdrawal',
  NA_SPROD_ENTRY_FEE_PAID: 'Sprod entry',
  NA_SPROD_EXIT_FEE_PAID: 'Sprod exit',
  NA_COINSWAP_MARGIN_REV: 'Coinswap',
  NA_NODE_REWARD: 'Node reward',
  NA_NODE_FEE_RCVD: 'Node maintenance',
  NA_LM_RECOVER_FEE_RCVD: 'Liquidity mining recovery',
  NA_LM_REWARD_FEE_RCVD: 'Liquidity mining reward',
  NA_STAKING_REWARD_BOOSTER_RCVD: 'ELITE Staking reward booster',
  NA_LM_DFI_REWARD_BOOSTER_RCVD: 'ELITE Liquidity Mining reward booster',
  NA_5_YEAR_FREEZER_REWARD_BOOSTER_RCVD: 'ELITE 5 years freezer reward booster',
  NA_10_YEAR_FREEZER_REWARD_BOOSTER_RCVD: 'ELITE 10 years freezer reward booster',
  NA_EARN_REWARD_BOOSTER_RCVD: 'ELITE Earn reward booster',
  NA_YIELD_VAULT_REWARD_BOOSTER_RCVD: 'ELITE YieldVault reward booster',
  NA_DCA_DEPOSIT_RCVD: 'Deposit recurring buy',
  NA_DCA_REFERRAL_REWARD_RCVD: 'DCA referral rewards',
  NA_BUNDLE_COMMISSIONS_RCVD: 'Bundle Commissions',
};

export default function convertRevenueOperationToReadable(operation: RevenueTxOperation|string) {
  const readable: string = readableRevenueMap[operation];
  return readable || 'Unknown operation';
}
