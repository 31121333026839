import React from 'react';
import Banner from 'common/components/Banner/Banner';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { BannerType } from 'types/banner/banner';
import infoSvg from 'assets/svg/updates-info-icon.svg';
import Variables from 'Variables.styles';
import ReactSVG from 'react-svg';
import Link from '@material-ui/core/Link';

const useStyles = createUseStyles({
  bannerWrapper: {
    marginTop: '50px',
  },
  bannerContainer: {
    display: 'flex',
    gap: Variables.medium,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
  text: {
    ...Variables.baseText,
    display: '-webkit-box',
    overflow: 'hidden',
    boxOrient: 'vertical',
    marginBottom: '0',
  },
});

export default function RecurringBuyBannerForBuyCrypto() {
  const classes = useStyles();

  return (
    <div className={classes.bannerWrapper}>
      <Banner type={BannerType.Announcement}>
        <div className={classes.bannerContainer}>
          <ReactSVG svgStyle={{ width: '20px', height: '20px' }} className={classes.icon} src={infoSvg} />
          <p className={classes.text}>
            <Trans>Set up a recurring buy for 0 fees and earn a bonus for a limited time. Only on the Bake Mobile app.</Trans>{' '}
            <Link
              href="https://support.bake.io/hc/en-us/articles/21139662577177"
              target="_blank"
              rel="noreferrer"
            >
              <Trans>
                Learn more
              </Trans>
            </Link>
          </p>
        </div>
      </Banner>
    </div>
  );
}
