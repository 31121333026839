import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DEXPrice } from '@cakedefi/cake-sdk';
import { NATIVE_COIN_OBJECT } from './stakingPageUtils';
import sdk from '../sdk';

const initialState = {
  coins: null,
  coinPrices: null,
  shares: null,
  sprods: null,
  sprodBatches: null,
  selectedCoin: NATIVE_COIN_OBJECT.value,
  isLoading: false,
  error: null,
  pairPrice: null,
  minimumAmountInUsd: 0,
};

export const fetchStaking = createAsyncThunk(
  'stakingPage/fetchStaking', () => sdk.StakingPageApi.staking(),
);

export const fetchPairPrice = createAsyncThunk<DEXPrice, string>(
  'stakingPage/fetchPairPrice',
  (pairId) => sdk.DexSwapApi.getPrice(pairId),
);

export const fetchStakingMinAmountInUsd = createAsyncThunk(
  'staking/minimum', () => sdk.StakingApi.getMinimumInput(),
);

const stakingPageSlice = createSlice({
  name: 'stakingPage',
  initialState,
  reducers: {
    changeCoin: (state, action) => {
      const selectedCoinValue = action.payload;
      state.selectedCoin = selectedCoinValue;
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStaking.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchStaking.fulfilled, (state, action) => {
      const stakingResponse = action.payload;
      return {
        ...state,
        ...stakingResponse,
        error: null,
        isLoading: false,
      };
    });
    builder.addCase(fetchStaking.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchPairPrice.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchPairPrice.fulfilled, (state, action) => {
      const pairPrice = action.payload;
      return {
        ...state,
        pairPrice,
        error: null,
        isLoading: false,
      };
    });
    builder.addCase(fetchPairPrice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchStakingMinAmountInUsd.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchStakingMinAmountInUsd.fulfilled, (state, action) => {
      const { minimumAmountInUsd } = action.payload;
      return {
        ...state,
        minimumAmountInUsd,
        error: null,
        isLoading: false,
      };
    });
    builder.addCase(fetchStakingMinAmountInUsd.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { changeCoin, clear } = stakingPageSlice.actions;

export default stakingPageSlice.reducer;
