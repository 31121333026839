import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';

export type Status =
'NEW' | // created and not locked to any user
'LOCKED' | // locked to a user, waiting for the user to complete KYC
'REDEEMED' | // redeemed by user and can no longer be used
'EXPIRED'; // no claim after the expiry date
export interface UserRedeemInfo {
  id: string;
  status: Status;
  redeemedOn: Date;
  VoucherPromotion: {
    name?: string;
    value?: string;
  };
}

export const getUserState = (state: RootState) => state.user;
export const getVoucherState = (state: RootState) => state.voucher;
export const userDetailSelector = createSelector(getUserState, user => user.details);
export const getVoucherInfoForPromo = createSelector(userDetailSelector, details => {
  const voucherInfo = {
    status: '',
    claimedValue: '',
    voucherId: '',
    promoName: '',
  };
  if (details?.userVoucherInfo.length === 0) {
    return voucherInfo;
  }

  const voucher = details?.userVoucherInfo[0];

  if (voucher) {
    voucherInfo.status = voucher.status;
    voucherInfo.claimedValue = voucher.VoucherPromotion.value;
    voucherInfo.voucherId = voucher.id;
    voucherInfo.promoName = voucher.VoucherPromotion.name;
  }
  return voucherInfo;
});

export const getVouchersInfoForPromo = createSelector(userDetailSelector, details => {
  if (!details?.userVoucherInfo.length) {
    return [];
  }
  const redeemedVouchers = details.userVoucherInfo.filter(v => v.status === 'REDEEMED');
  const notRedeemedVouchers = details.userVoucherInfo.filter(v => v.status !== 'REDEEMED');
  return [redeemedVouchers, notRedeemedVouchers] as [UserRedeemInfo[], UserRedeemInfo[]];
});
