import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import constants from './constants';

BigNumber.config({ EXPONENTIAL_AT: 21 });

export default function show8Digits(value: BigNumberConstructable, fmt: BigNumber.Format = constants.BN_STRING_FORMAT): string {
  const bnValue = new BigNumber(value);
  const roundingMode = BigNumber.ROUND_DOWN;

  const valueAsString = bnValue.toFixed();
  const splittedString = valueAsString.split('.');

  const wholeNumber = splittedString[0];
  const wholeNumberDigits = wholeNumber.length;

  let decimals = 8 - wholeNumberDigits;

  if (decimals < 0) {
    decimals = 0;
  } else if (decimals > 5) {
    decimals = 5;
  } // decimals should be between 0 and 5 only

  return bnValue.decimalPlaces(decimals, roundingMode).toFormat(fmt);
}
