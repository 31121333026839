import { CoinPrice } from '@cakedefi/cake-sdk';
import BigNumber from 'bignumber.js';

interface IsAmountLessThanMinimumInUsdProps {
  amount: number | string | BigNumber
  minAmountInUsd: number
  selectedCoin: string
  pricing?: CoinPrice[]
  priceUSD?: string
}

const isAmountLessThanMinimumInUsd = ({
  amount,
  minAmountInUsd,
  selectedCoin,
  pricing,
  priceUSD,
}: IsAmountLessThanMinimumInUsdProps) => {
  // this `coinPriceInUsd` calculated next way - if `priceUSD` not provide then it will be taken/find from `pricing`
  const coinPriceInUsd = priceUSD ?? (pricing?.find(coinPrice => coinPrice.CoinId === selectedCoin)?.priceUSD?.avg ?? '0');
  const coinToSwapAmountInUsd = new BigNumber(amount).multipliedBy(coinPriceInUsd);
  const isAmountLessThenMinimum = new BigNumber(minAmountInUsd).isGreaterThan(coinToSwapAmountInUsd);
  return isAmountLessThenMinimum;
};

export default isAmountLessThanMinimumInUsd;
