export enum BannerType{
  Danger,
  Warning,
  Info,
  NotAvailable,
  Important,
  Announcement,
  Notification,
  Alert,
  Normal,
}
