import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { fetchUserQuizzesInfo, goToModal } from 'learn-earn/learnEarnSlice';
import { ModalHeader, ModalContent, ModalFooter } from 'modals/components';
import { useCakeSelector } from 'app/rootReducer';
import { getLearnEarnState, getCanCallClaimApi, getFullyClaimedStatus, getCurrentLessonStackId, getCurrentLessonStack } from 'learn-earn/learnEarnSelector';
import Button from 'common/components/Button/Button';
import { RewardType } from 'learn-earn/data/learnEarnContent';
import modalStyles from 'common/styles/Modal.styles';
import Variables from 'Variables.styles';
import sdk from 'sdk';
import fetchWithSpinner from 'utils/fetchWithSpinner';
import { IoMdInformationCircle } from 'react-icons/io';
import { ModalType } from './LessonModal';

const useStyles = createUseStyles({
  ...modalStyles,
  cardWrapper: {
    padding: `${Variables.large} ${Variables.large}`,
  },
  imageCard: {
    border: Variables.primaryBorder,
    borderRadius: '10px',
    width: '100%',
  },
  card: {
    padding: '150px',
  },
  startButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  startButton: {
    marginTop: Variables.small,
    marginBottom: Variables.small,
  },
  details: {
    display: 'flex',
  },
  infoIcon: {
    marginRight: Variables.small,
    color: '#FF9629',
  },
});

const ERROR_MESSAGE_REGEXP = /(.*)({{responseError}})(.*)/g;
const errorMessage = 'Update failed: {{responseError}}';

const getRedeemedLabel = (rewardTypeInput: RewardType) => {
  switch (rewardTypeInput) {
    case 'FrozenLiquidityMiningShare':
      return '{{coinId}}-DFI rewards have been fully redeemed.';
    case 'FrozenStakingShare':
    default:
      return '{{coinId}} rewards have been fully redeemed.';
  }
};

interface LessonModalWelcomeProps {
  closeModal: () => void;
}

const LessonModalWelcome = ({ closeModal }: LessonModalWelcomeProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentLessonStack } = useCakeSelector(getLearnEarnState);
  const { rewardType, coinId } = useCakeSelector(getCurrentLessonStack);
  const canCallClaimApi = useCakeSelector(getCanCallClaimApi(currentLessonStack.id));
  const currentStackId = useCakeSelector(getCurrentLessonStackId);
  const showWelcomeModalClaimStatus = useCakeSelector(getFullyClaimedStatus(currentStackId));
  const { t } = useTranslation();

  const modalHeaderTitle = currentLessonStack && `Learn & Earn: ${currentLessonStack.coinName}`;

  const onClickStart = async () => {
    if (canCallClaimApi) {
      try {
        await fetchWithSpinner(() => sdk.LearnEarnApi.claimEarnSlot(currentLessonStack.id), {
          showAlert: false,
          throwError: true,
        });
      } catch (err) {
        const { message } = err;
        const formattedErrorMessage = errorMessage.replace(ERROR_MESSAGE_REGEXP, `$1${message}$3`);
        window.cakepool.showAlert('error', <span>
          <Trans i18nKey={formattedErrorMessage}>{formattedErrorMessage}</Trans>
        </span>);
      }
      dispatch(fetchUserQuizzesInfo());
      dispatch(goToModal(ModalType.LESSON_MODAL));
    } else {
      dispatch(goToModal(ModalType.LESSON_MODAL));
    }
  };

  return (
    <>
      <ModalHeader title={modalHeaderTitle} onClose={closeModal}/>
      <ModalContent>
        <div className={`${classes.centerContent} ${classes.cardWrapper}`}>
          <img
            src={currentLessonStack.welcomeImage}
            alt="Lesson"
            className={classes.imageCard}
          />
        </div>
      </ModalContent>
      <ModalFooter styleType="footerSpaceBetween">
        {
          showWelcomeModalClaimStatus ? <div className={classes.details}>
            <IoMdInformationCircle className={classes.infoIcon} size={23}/>
            {t(getRedeemedLabel(rewardType), { coinId })}
          </div> : <div/>
        }
        <div className={classes.startButtonWrap}>
          <Button primary className={classes.startButton} onClick={onClickStart}>
            <Trans>Start Now</Trans>
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default LessonModalWelcome;
