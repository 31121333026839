import { TransakPaymentOption } from '@cakedefi/cake-sdk';

export class TransakPaymentOptionConverter {
  static toFiatBuyState(paymentMethods: TransakPaymentOption[]) {
    return paymentMethods.map(({ name, icon, ...rest }) => ({
      name,
      logoUrl: icon,
      ...rest,
    }));
  }
}
