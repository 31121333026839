import BigNumber from 'bignumber.js';
import { BigNumberConstructable, Currency } from '../lib';

export default function convertUSDToPreferredCurrency(
  amountInUSD: BigNumberConstructable,
  btcPrice: BigNumberConstructable,
  preferredCurrency: Currency,
  currencyRates: Record<string, BigNumberConstructable>,
) {
  const amountInUSDBn = amountInUSD instanceof BigNumber ? amountInUSD : new BigNumber(amountInUSD);

  switch (preferredCurrency) {
    case 'USD': {
      return amountInUSDBn.toFixed();
    }
    case 'BTC': {
      return amountInUSDBn.dividedBy(btcPrice).toFixed();
    }
    default: {
      if (currencyRates) {
        const foreignCurrencyRate = currencyRates[preferredCurrency];
        return amountInUSDBn.multipliedBy(foreignCurrencyRate).toFixed();
      }
      return amountInUSDBn.toFixed();
    }
  }
}
