import appConfig from 'config/app';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const app = initializeApp(
  appConfig.APP_ENVIRONMENT === 'production'
    ? {
      apiKey: 'AIzaSyAa881evZvUhxG9nsPupGhZsDfyn7CeuOo',
      authDomain: 'cakedefi-app.firebaseapp.com',
      projectId: 'cakedefi-app',
      storageBucket: 'cakedefi-app.firebasestorage.app',
      messagingSenderId: '52245301831',
      appId: '1:52245301831:web:3e1df43fcb5210d78cbf62',
      measurementId: 'G-S35KWVKV92',
    }
    : {
      apiKey: 'AIzaSyBp40sKmQ0wGulkucDXdfUEnsg4zVqwGd4',
      authDomain: 'muffindefi-app.firebaseapp.com',
      projectId: 'muffindefi-app',
      storageBucket: 'muffindefi-app.firebasestorage.app',
      messagingSenderId: '252583629089',
      appId: '1:252583629089:web:3c0b3f7e395d2653ac6e60',
      measurementId: 'G-PYWXRYRKES',
    },
);

export const getFbAnalytics = getAnalytics(app);
