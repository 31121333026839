import Variables, { getMaxWidthBreakPoint } from '../../../Variables.styles';
import { sharedFiatBuyModalStyles } from './FiatBuyModal.styles';

export default {
  ...sharedFiatBuyModalStyles,
  paymentMethod: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: Variables.extraSmall,
    padding: Variables.large,
    border: `1px solid ${Variables.gray200}`,
    marginTop: Variables.small,
    marginBottom: Variables.small,
    '&.selected': {
      border: `2px solid ${Variables.electricBerry}`,
    },
    transition: 'all 50ms',
  },
  paymentMethodLogo: {
    minWidth: '15%',
    marginRight: Variables.medium,
    textAlign: 'center',
    '& > img': {
      maxHeight: '35px',
      width: 'auto',
    },
  },
  paymentMethodName: {
    fontWeight: 'bold',
    flexGrow: 1,
  },
  paymentMethodFees: {
    color: Variables.gray600,
  },
  exclamationIcon: {
    composes: ['fa', 'fa-exclamation-circle'],
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
    paymentMethod: {
      flexDirection: 'column',
    },
    paymentMethodName: {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
};
