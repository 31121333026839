import React,
{ Component } from 'react';

const typeClassNames = {
  error: 'danger',
  default: 'primary',
  primary: 'primary',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
};

export default class Alert extends Component {
  state = {
    isVisible: false,
    content: null,
    type: null,
  };

  show(type, content) {
    this.setState({
      type,
      content,
      isVisible: true,
    });
  }

  resetContent = () => this.setState({ content: null });

  hide() {
    this.setState({
      isVisible: false,
    });
  }

  isVisible() {
    return this.state.isVisible;
  }

  getClassNames() {
    const {
      type,
    } = this.state;
    const color = typeClassNames[type] || typeClassNames.default;
    const colorClass = `alert-${color}`;
    return colorClass;
  }

  render() {
    const {
      isVisible,
      content,
    } = this.state;

    if (!content) {
      return null;
    }

    let classNames = this.getClassNames();
    if (!isVisible) {
      classNames += ' fade-out';
    }
    return (
      <div className='cakepool-alert flex-center'>
        <div className={`cakepool-alert__container ${classNames}`}>
          {content}
        </div>
      </div>
    );
  }
}
