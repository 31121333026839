import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Dropdown from '../../components/DropDown';
import HeaderNavigationLink from './components/HeaderNavigationLink';
import styles from './Header.styles';

const useStyles = createUseStyles({
  ...styles,
  dropdownWidth: {
    width: 'auto',
  },
});

export default function HeaderDropdown({ headerLink }) {
  const classes = useStyles();
  const dropdownRef = useRef(null);
  const { children: links, text, textLabel } = headerLink;
  return (
    <Dropdown
      ref={dropdownRef}
      renderDropdown={() => (
        <div className={classes.dropdownWidth}>
          {links.map((link, index) => (
            <HeaderNavigationLink
              key={`header-link-${index}`}
              headerLink={link}
              className={`${classes.headerLink} dropdown__option`}
              onClick={() => dropdownRef.current.hide()}
            />))}
        </div>
      )}
      alignment="bottomLeft"
    >
      <span className="header-link">
        <Trans>{text}</Trans>
        { !!textLabel && <span className={classes.newLabel}><Trans>{textLabel}</Trans></span> }
        <i className="fa fa-angle-down ml-1"></i>
      </span>
    </Dropdown>
  );
}
