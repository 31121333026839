import React, { useEffect, useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import Button from '../../../../common/components/Button/Button';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput/PhoneNumberInput';
import { useCakeSelector } from '../../../../app/rootReducer';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import {
  setCodeCallId,
  setCodeSendId,
  setMobileNumber,
  setPhoneCountryCode,
} from '../identifiableInformationSlice';
import MobilePhoneStyles from './MobilePhone.styles';

const useStyles = createUseStyles(MobilePhoneStyles);

const MobilePhoneInput = ({ gotoSendingSMSScreen, gotoWallet, reset, isAllowedToUpdate }) => {
  const classes = useStyles();
  const { mobileNumber, country } = useCakeSelector(state => state.userIdentifiableInformation.inputs);
  const { phoneNumberVerified, country: userCountry } = useCakeSelector(state => state.user.details);
  const { previousPath } = useCakeSelector(state => state.user);
  const [isInvalidNumber, setIsInvalidNumber] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const onClickContinue = useCallback(async () => {
    trackOnClickContinue();
    gotoSendingSMSScreen(); // MOBILE_SENDING_SMS
  }, [gotoSendingSMSScreen]);

  const isDisableContinue = !mobileNumber.trim().length || isInvalidNumber;

  const onKeypress = useCallback((e) => {
    if (e.code && e.code === 'Enter' && !isDisableContinue) {
      onClickContinue();
    }
  }, [onClickContinue, isDisableContinue]);

  useEffect(() => {
    if (phoneNumberVerified && isMountedRef.current && !isAllowedToUpdate) {
      gotoWallet();
    }

    dispatch(setCodeCallId(null));
    dispatch(setCodeSendId(null));
    reset();

    window.addEventListener('keydown', onKeypress);
    return () => {
      window.removeEventListener('keydown', onKeypress);
    };
  }, [gotoWallet, phoneNumberVerified, onKeypress, isMountedRef, dispatch, reset, isAllowedToUpdate]);

  const onChange = (payload) => {
    const { phoneNumber, countryCode } = payload;
    if (phoneNumber.length > 0 && !/^([0-9]{1,15})$/.test(phoneNumber)) {
      setIsInvalidNumber(true);
      setValidationMessage('The phone number must be numeric and cannot contain whitespaces.');
    } else {
      setIsInvalidNumber(false);
      setValidationMessage('');
    }
    dispatch(setMobileNumber(phoneNumber));
    dispatch(setPhoneCountryCode(countryCode));
  };

  useEffect(() => {
    gtmTrack('trackEvent', {
      event: iEventNames.initiate_mobile_number_verification,
      event_type: iEventTypes.custom,
      event_data: {
        initiate_location: `from ${previousPath} step`,
        initiate_referrer: previousPath, // this will contain /login
      },
    });
  }, [previousPath]);

  const trackDoItLater = () => {
    gtmTrack('trackEvent', {
      event: iEventNames.postpone_mobile_verification,
      event_type: iEventTypes.custom,
    });
  };

  const trackOnClickContinue = () => {
    gtmTrack('trackEvent', {
      event: iEventNames.submit_mobile_number,
      event_type: iEventTypes.custom,
    });
  };

  const doItLaterButton = (
    <div className="mt-5 text-center" tabIndex={2}>
      <Link to="/wallets" onClick={trackDoItLater}>
        <Button
          primary
          clear
        >
          <Trans>I'll do it later</Trans>
        </Button>
      </Link>
    </div>
  );

  return (
    <article className="floating">
      <div>
        <h1 className={classes.title}>
          <Trans>Mobile number</Trans>
        </h1>
        <div>
          <Trans>Please enter your mobile number. A 6-digit code will be sent to the mobile number for verification.</Trans>
        </div>
        <form className="mt-5">
          <PhoneNumberInput
            defaultCountry={country || userCountry}
            defaultValue={mobileNumber}
            onChange={onChange}
            invalid={isInvalidNumber}
            invalidMessage={<Trans i18nKey={validationMessage}>{validationMessage}</Trans>}
            tabIndex={0}
            isAllowedToUpdate={isAllowedToUpdate}
          />
          <div
            tabIndex={1}
          >
            <Button
              className="mt-4"
              main
              block
              outline
              type="button"
              onClick={onClickContinue}
              disabled={isDisableContinue}
            >
              <Trans>Continue</Trans>
            </Button>
          </div>
          {
            doItLaterButton
          }
        </form>
      </div>
    </article>
  );
};

export default MobilePhoneInput;
