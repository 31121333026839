import React, { Component } from 'react';
import Icon from './Icon';

export default class Modal extends Component {
  state = {
    isVisible: false,
    isSmall: false,
  }

  componentDidMount() {
    this.setState({
      isVisible: this.props.isVisible,
      isSmall: this.props.isSmall,
    });
    document.addEventListener('keydown', this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction.bind(this), false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.setState({
        isVisible: this.props.isVisible || false,
      });
    }
  }

  hide() {
    this.setState({
      isVisible: false,
    });
    this.onClose();
  }

  onClose() {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  escFunction(event) {
    if (event.key === 'Escape') {
      this.onClose();
    }
  }

  renderCloseButton() {
    const { showCloseButton } = this.props;
    if (!showCloseButton) return;
    return (
      <span onClick={() => this.hide()} className="close-button">
        <Icon name="close"></Icon>
      </span>
    );
  }

  render() {
    const { isVisible, isSmall } = this.state;

    if (!isVisible) {
      return null;
    }

    return (
      <div className="cakepool-modal modal open">
        <div className={`modal-wrapper ${isSmall ? 'modal-small' : ''}`}>
          <div className="modal-content" id="modal-content">
            {this.props.children}
          </div>
          {this.renderCloseButton()}
        </div>
        <div className="modal-overlay" onClick={() => this.onClose()}></div>
      </div>
    );
  }
}
