import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import sdk from '../sdk';

interface CurrencyState {
  fiat: {
    rates: {
      [key: string]: number,
    }
  };
  coins: {
    isLoading: boolean;
    pricing: CoinPrice[]
  }
}

const initialState: CurrencyState = {
  fiat: {
    rates: {},
  },
  coins: {
    isLoading: false,
    pricing: [],
  },
};

export const fetchFiatRates = createAsyncThunk(
  'currency/fetchFiatRates',
  async () => {
    const response = await sdk.CurrencyRatesApi.list();
    return response;
  },
);

export const fetchCoinsPricing = createAsyncThunk<any, boolean>(
  'currency/fetchCoinsPricing',
  (isBackgroundRequest) => sdk.CoinApi.latestPricing({}, isBackgroundRequest),
);

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFiatRates.fulfilled, (state, action) => {
      state.fiat.rates = action.payload.rates;
    });

    builder.addCase(fetchCoinsPricing.pending, (state) => {
      state.coins.isLoading = true;
    });

    builder.addCase(fetchCoinsPricing.fulfilled, (state, action) => {
      state.coins.isLoading = false;
      state.coins.pricing = action.payload;
    });

    builder.addCase(fetchCoinsPricing.rejected, state => {
      state.coins.isLoading = false;
    });
  },
});

export default currencySlice.reducer;
