import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  value: null,
  promoCode: null,
  affiliateRefCode: null,
};

const referralCodeSlice = createSlice({
  name: 'referralCode',
  initialState,
  reducers: {
    setReferralCode: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
    },
    setAffiliateRefCode: (state, action: PayloadAction<string>) => {
      state.affiliateRefCode = action.payload;
    },
  },
});

export const { setReferralCode, setPromoCode, setAffiliateRefCode } = referralCodeSlice.actions;

export default referralCodeSlice.reducer;
