import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/rootReducer';
import { OnRampProviderType } from '../../../types/onramp/onramp-provider';
import {
  FiatBuyInitialStepSelectorModal,
  FiatBuyInputGroupsSelectorModel,
  FiatBuyInputsSelectorModel,
  FiatBuyModalState,
  PaymentMethodsSelectorModel,
  TransakFiatSelector,
} from '../../../types/onramp/state';
import { fiatBuyCryptoCoinsSelector } from '../../walletSelectors';
import { calculatePaymentMethodFee } from './utils/calculatePaymentMethodFee';
import { getPriceBreakdownByProvider } from './utils/getPriceBreakdownByProvider';
import { getTransakMinMaxPaymentMethod } from './utils/getTransakMinMaxPaymentMethod';
import { getBanxaMinMaxPaymentMethod } from './utils/getBanxaMinMaxPaymentMethod';
import { currencyFiatRatesSelector } from '../../../currency/currencySelector';
import { setFiatOptions } from './utils/setFiatOptions';
import { CardSelectionItem } from '../../../common/components/CardSelection/CardSelectionItem';

export const getFiatBuyModalState = (state: RootState): FiatBuyModalState => state.fiatBuyModal;

export const fiatBuyModalInputsSelector = createSelector(getFiatBuyModalState, state => {
  const { inputs: fiatInputs, fiatSwappable } = state;
  const inputsModel: FiatBuyInputsSelectorModel = {
    crypto: fiatSwappable.coin || fiatInputs.target,
    cryptoAmount: fiatSwappable.amount || fiatInputs.targetAmount,
    fiat: fiatInputs.source,
    fiatAmount: fiatInputs.sourceAmount,
    source: fiatInputs.source,
  };
  return inputsModel;
});

export const selectedProviderSelector = createSelector(getFiatBuyModalState, (state) => {
  const provider = state.providers.find(({ type }) => type === state.type);
  return {
    provider,
    banxa: state.banxa,
    transak: state.transak,
  };
});

export const fiatSwappableSelector = createSelector(
  getFiatBuyModalState,
  state => state.fiatSwappable,
);

export const providersSelector = createSelector(
  getFiatBuyModalState,
  (state): CardSelectionItem[] => state.providers.map(provider => ({
    label: provider.name,
    value: provider.type,
    icon: provider.icon,
    iconMaxHeight: provider.iconMaxHeight,
    selected: provider.selected,
  })),
);

export const transakFiatSelector = (selectedCoin: string) => createSelector(
  getFiatBuyModalState,
  (fiatBuyModalState) => {
    const { transak, inputs, fiatSwappable } = fiatBuyModalState;
    const selectedCrypto = fiatSwappable.coin || inputs.target || selectedCoin;
    return {
      isLoading: transak.isFiatsLoading,
      isShowTransak: !fiatSwappable.coin && !transak.isFiatsLoading && !!transak.fiats?.length && selectedCrypto !== 'EUROC',
    } as TransakFiatSelector;
  },
);

export const priceBreakdownSelector = createSelector(
  getFiatBuyModalState,
  (state) => {
    const { fiatSwappable } = state;
    const priceBreakdownByProvider = getPriceBreakdownByProvider(state);
    if (fiatSwappable.coin) {
      return {
        ...priceBreakdownByProvider,
        showFeePercentage: false,
        showFeeAmount: true,
      };
    }
    return priceBreakdownByProvider;
  },
);

export const paymentMethodsSelector = createSelector(
  getFiatBuyModalState,
  (state) => {
    const {
      type,
      banxa,
      transak,
      inputs,
      fiatSwappable: { coin: fiatSwappableCoinId },
    } = state;
    switch (type) {
      case OnRampProviderType.TRANSAK: {
        const {
          paymentMethods,
          selectedPaymentMethod,
          isPaymentMethodLoading,
        } = transak;
        const retVal: PaymentMethodsSelectorModel = {
          type,
          fiatCode: inputs.source,
          paymentMethods,
          selectedPaymentMethod,
          isPaymentMethodLoading,
          showGateWayFee: false,
          fiatSwappableCoinId,
        };
        return retVal;
      }

      case OnRampProviderType.BANXA:
      default: {
        const { paymentMethods, selectedPaymentMethod, isPaymentMethodLoading } = banxa;
        const retVal: PaymentMethodsSelectorModel = {
          type,
          fiatCode: inputs.source,
          paymentMethods: paymentMethods?.map(calculatePaymentMethodFee),
          selectedPaymentMethod: calculatePaymentMethodFee(
            selectedPaymentMethod,
          ),
          isPaymentMethodLoading,
          fiatSwappableCoinId,
          showGateWayFee: true,
        };
        return retVal;
      }
    }
  },
);

export const fiatBuyInputGroupsSelector = (selectedCoin: string) => createSelector(
  getFiatBuyModalState,
  currencyFiatRatesSelector,
  fiatBuyCryptoCoinsSelector,
  (fiatBuyModalState, rates, coins) => {
    const { banxa, transak, inputs, fiatSwappable, type, error } = fiatBuyModalState;
    const isBanxa = type === OnRampProviderType.BANXA;
    const isTransak = type === OnRampProviderType.TRANSAK;
    const selectedCrypto = fiatSwappable.coin || inputs.target || selectedCoin;
    const defaultInputCoin = coins.find(coin => coin.value === selectedCrypto);

    const baseSelectorValue = {
      ...fiatBuyModalState,
      defaultInputCoin,
      isBanxa,
      isTransak,
      error,
    };

    switch (type) {
      case OnRampProviderType.TRANSAK: {
        const {
          fiats,
          selectedFiatPrice,
          isFiatsLoading,
          selectedPaymentMethod,
          isFetchingFiatPrice,
          isFetchingCryptoPrice,
        } = transak;
        const fiatOptions = setFiatOptions(fiats, 'symbol');
        const defaultFiatDropdownValue = fiatOptions.find(({ value }) => value === inputs.source);

        const transakRetVal: FiatBuyInputGroupsSelectorModel = {
          ...baseSelectorValue,
          fiatOptions,
          isFiatsLoading,
          defaultFiatDropdownValue,
          ...getTransakMinMaxPaymentMethod(
            selectedPaymentMethod,
            inputs.source,
            inputs.sourceAmount,
            rates,
          ),
          selectedFiatPrice,
          defaultFiatInputValue: null,
          isFetchingFiatPrice,
          isFetchingCryptoPrice,
        };

        return transakRetVal;
      }

      case OnRampProviderType.BANXA:
      default: {
        const {
          isFetchingFiatPrice,
          isFetchingCryptoPrice,
          fiats,
          selectedFiatPrice,
          isFiatsLoading,
          selectedPaymentMethod,
        } = banxa;
        const fiatOptions = setFiatOptions(fiats, 'fiatCode');
        const defaultFiatDropdownValue = fiatOptions.find(({ value }) => value === inputs.source);
        const defaultFiatAmount = selectedFiatPrice
          ? Number(selectedFiatPrice.fiatAmount)
          : inputs.sourceAmount;

        const retVal: FiatBuyInputGroupsSelectorModel = {
          ...baseSelectorValue,
          defaultFiatInputValue: defaultFiatAmount,
          fiatOptions,
          isFiatsLoading,
          defaultFiatDropdownValue,
          selectedFiatPrice,
          isFetchingFiatPrice,
          isFetchingCryptoPrice,
          ...getBanxaMinMaxPaymentMethod(
            selectedPaymentMethod,
            inputs.sourceAmount,
          ),
        };

        return retVal;
      }
    }
  },
);

export const fiatBuyInitialStepSelector = createSelector(
  getFiatBuyModalState,
  fiatBuyModalState => {
    const { banxa, transak, inputs, fiatSwappable, type, error } = fiatBuyModalState;
    const baseResult = {
      inputs,
      type,
      error,
      fiatSwappable,
    };
    switch (type) {
      case OnRampProviderType.TRANSAK: {
        const { fiats, isFiatsLoading } = transak;
        const transakRetVal: FiatBuyInitialStepSelectorModal = {
          ...baseResult,
          fiats,
          isFiatsLoading,
        };
        return transakRetVal;
      }

      case OnRampProviderType.BANXA:
      default: {
        const { fiats, isFiatsLoading } = banxa;
        const banxaRetVal: FiatBuyInitialStepSelectorModal = {
          ...baseResult,
          fiats,
          isFiatsLoading,
        };
        return banxaRetVal;
      }
    }
  },
);

export const providerFiatsSelector = createSelector(getFiatBuyModalState, s => ({
  hasLoadedBanxaFiats: !!s.banxa.fiats,
  hasLoadedTransakFiats: !!s.transak.fiats,
}));
