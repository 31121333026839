import React from 'react';
import { createUseStyles } from 'react-jss';
import LoadingDot from './LoadingDot';
import styles from './InlineThreeDotsLoading.styles';

const useStyles = createUseStyles(styles);

export default function InlineThreeDotsLoading() {
  const classes = useStyles();
  return (
    <div className={classes.threeDots}>
      <LoadingDot delay={0} offset={false} />
      <LoadingDot delay={160} offset />
      <LoadingDot delay={320} offset />
    </div>
  );
}
