import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';

const trackOnClickKyc = () => {
  gtmTrack('trackEvent', {
    event: iEventNames.initiate_identity_verification,
    event_type: iEventTypes.custom,
  });
};

export default trackOnClickKyc;
