import { createSelector } from '@reduxjs/toolkit';
import { isCryptoAmountValidSelector } from './isCryptoAmountValidSelector';
import { fiatBuyInputGroupsSelector } from '../fiatBuyModalSelector';
import { isPositiveAndNotZeroNumber } from '../../../../utils';

export const isDisableContinueButtonSelector = (selectedCoin: string) => createSelector(
  fiatBuyInputGroupsSelector(selectedCoin),
  isCryptoAmountValidSelector,
  (state, { isCryptoAmountValid }) => {
    const { error, selectedFiatPrice, isFiatAmountValid, fiatSwappable, inputs } = state;
    const walletAddress = fiatSwappable.coin
      ? fiatSwappable.walletAddress
      : inputs.walletAddress;

    return !!error
      || !selectedFiatPrice
      || !isFiatAmountValid
      || !isCryptoAmountValid
      || !walletAddress
      || !isPositiveAndNotZeroNumber(inputs.targetAmount)
      || !isPositiveAndNotZeroNumber(inputs.sourceAmount);
  },
);
