import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import Button from 'common/components/Button/Button';
import KycStatusBadge from 'common/kyc/KycStatusBadge';
import { Icon } from 'views/components';
import { useCakeSelector } from 'app/rootReducer';
import { isShowKycBanner, staggeredKycUserStatusSelector, userSumsubKycStatusSelector } from 'user/AccountVerification/accountVerificationSelector';
import { setIsShowKycBanner } from 'user/AccountVerification/accountVerificationSlice';
import { userDetailSelector } from 'user/userSelector';
import KycCurrentLevel from 'common/kyc/KycCurrentLevel';
import { storage } from 'storage/sessionStorage';
import BottomKycBenefits from './BottomKycBenefits';

const useStyles = createUseStyles({
  background: {
    backgroundColor: '#EEEBF3',
  },
  backgroundContainer: {
    composes: 'container',
    display: 'flex',
    flexDirection: 'column',
    gap: Variables.large,
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: Variables.small,
  },
  title: {
    fontWeight: Variables.fontWeight.mediumBold,
    fontSize: '28px',
    lineHeight: '36px',
  },
  subtitle: {
    fontWeight: Variables.fontWeight.semiBold,
    fontSize: '16px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '16px 24px',
    backgroundColor: 'white',
    boxShadow: Variables.primaryBoxShadow,
    borderRadius: Variables.small,
    fontSize: Variables.fontSize.base,
    fontWeight: 500,
    minHeight: '65px',
  },
  statusContainer: {
    marginLeft: Variables.small,
    flex: '0 0 auto',
  },
  button: {
    flex: '0 0 auto',
    padding: `12px ${Variables.large}`,
    minWidth: '220px',
  },
  descriptionContainer: {
    width: '100%',
    padding: Variables.small,
    marginLeft: Variables.small,
    fontWeight: Variables.fontWeight.semiBold,
    fontSize: '16px',
  },
  bannerTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: Variables.medium,
  },
  kycDetailsContainer: {
    backgroundColor: Variables.gray100,
    width: '100%',
    padding: Variables.medium,
    marginTop: Variables.medium,
    borderRadius: Variables.small,
    display: 'flex',
    justifyContent: 'space-between',
    gap: Variables.medium,
  },
  kycDetail: {
    display: 'flex',
    alignItems: 'center',
  },
  kycDetailDescription: {
    paddingLeft: Variables.medium,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    bannerTopContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    descriptionContainer: {
      padding: 0,
      marginLeft: 0,
    },
    statusContainer: {
      marginLeft: 0,
    },
  },
});

const AccountVerificationButton = ({ buttonText }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Button main className={classes.button} onClick={() => history.push('/me/verify-account')}>
      <Trans>{buttonText}</Trans>
    </Button>
  );
};

const VerificationPhone = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Button main className={classes.button} onClick={() => history.push('/verify-phone')}>
      <Trans>Verify phone number</Trans>
    </Button>
  );
};

const bannerMainDescription = (date?: string | null) => ({
  IN_REVIEW: 'Your documents are being reviewed. It usually takes us 1 to 3 days to complete this process. We will notify you via email.',
  POA_REJECTED: 'Your proof of address could not be verified.',
  POI_REJECTED: 'Your identity could not be verified.',
  PHONE_UNVERIFIED: 'Verify your phone number to secure your account.',
  KYC_UNVERIFIED: 'Take a selfie and provide basic information to unlock.',
  TIER1_APPROVED: 'Best way to start baking! You can now deposit, trade and earn cryptocurrency.',
  MYINFO_PENDING: 'Please complete your account verification.',
  KYCFULL_REJECTED: 'Your identity could not be verified.',
  KYC_EXPIRED_SOON: `Please update your Proof of Identity (POI) and submit a new ID or passport before ${date}`,
  KYC_EXPIRED: 'Your Proof of Identity (POI) has expired, please submit a new ID or passport to verify your identity.',
  KYC_MIGRATION: `Please submit your latest KYC info before ${date} to help ensure we comply with international KYC laws.`,
  KYC_MIGRATION_EXPIRED: 'We were unable to verify your identity. Please try again with a valid identification document.',
});

const getLevelOnKycTier = (kycTier: string, isUserEligibleStaggeredKyc: boolean) => {
  switch (kycTier) {
    case 'TIER2':
      return isUserEligibleStaggeredKyc ? 'Level 2' : 'Level 1';
    case 'TIER1':
      return 'Level 1';
    default:
      return 'Level 0';
  }
};

interface BannerInfoType {
  level?: string;
  mainDescription?: string;
  showKycStatusBadge?: boolean;
  button?: JSX.Element | null,
  bottomDisplayComponent?: JSX.Element | null,
}

const getKycBannerInfo = (
  kycTier: string,
  isPhoneVerified: boolean,
  kycProvider: 'SUMSUB' | 'MYINFOSG' | null,
  sumsubKycStatus: string,
  isOpenToReKyc: boolean,
  isUserEligibleStaggeredKyc: boolean,
  poiExpiryDate: string | null,
  isOpenToKycMigration: boolean,
  lastDayToMigrate: string | null,
  { dispatch, classes }: any,
): BannerInfoType => {
  if (!isPhoneVerified) {
    return {
      level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
      mainDescription: bannerMainDescription().PHONE_UNVERIFIED,
      showKycStatusBadge: false,
      button: <VerificationPhone />,
      bottomDisplayComponent: null,
    };
  }

  if (kycProvider === 'MYINFOSG' && sumsubKycStatus === 'PENDING') {
    return {
      level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
      mainDescription: bannerMainDescription().MYINFO_PENDING,
      showKycStatusBadge: false,
      button: <AccountVerificationButton buttonText='Continue with Singpass'/>,
      bottomDisplayComponent: null,
    };
  }

  if (sumsubKycStatus === 'BLACKLISTED' || sumsubKycStatus === 'FINALLYREJECTED') {
    return {
      level: 'Level 0',
      mainDescription: bannerMainDescription().KYCFULL_REJECTED,
      showKycStatusBadge: true,
      button: null,
      bottomDisplayComponent: null,
    };
  }

  if (isOpenToKycMigration && (!sumsubKycStatus || sumsubKycStatus === 'UNVERIFIED')) {
    return {
      level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
      mainDescription: moment().isAfter(lastDayToMigrate)
        ? bannerMainDescription().KYC_MIGRATION_EXPIRED
        : bannerMainDescription(moment(lastDayToMigrate).format('DD-MM-YYYY')).KYC_MIGRATION,
      showKycStatusBadge: false,
      button: <AccountVerificationButton buttonText='Submit KYC Info' />,
      bottomDisplayComponent: null,
    };
  }

  switch (sumsubKycStatus) {
    case 'UNVERIFIED':
      if (kycTier === 'TIER0') {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription().KYC_UNVERIFIED,
          showKycStatusBadge: false,
          button: <AccountVerificationButton buttonText='Verify now'/>,
          bottomDisplayComponent: <BottomKycBenefits />,
        };
      }

      if (kycTier === 'TIER1') {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription().TIER1_APPROVED,
          showKycStatusBadge: false,
          button: (
            <Button primary className={classes.button} onClick={() => dispatch(setIsShowKycBanner(false))}>
              <Trans>Dismiss</Trans>
            </Button>
          ),
          bottomDisplayComponent: (
            <div className={classes.kycDetailsContainer}>
              <div className={classes.kycDetail}>
                <Icon name={'kycBenefit'} svgStyle={{ width: 24, height: 24 }}/>
                <div className={classes.kycDetailDescription}>
                  <Trans>Withdraw up to 10,000 USD per month. You can unlock the withdrawal limit by verifying your proof of address.</Trans>
                </div>
              </div>
            </div>
          ),
        };
      }

      return {};

    case 'SCREENING':
    case 'PENDING':
      if (['TIER0', 'TIER1', 'TIER2'].includes(kycTier)) {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription().IN_REVIEW,
          showKycStatusBadge: true,
          button: null,
          bottomDisplayComponent: null,
        };
      }
      return {};

    case 'REJECTED':
      // ID DOC EXPIRED
      if (isOpenToReKyc && poiExpiryDate && moment().isAfter(poiExpiryDate)) {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription().KYC_EXPIRED,
          showKycStatusBadge: true,
          button: <AccountVerificationButton buttonText='Renew your POI' />,
          bottomDisplayComponent: null,
        };
      }
      if (isUserEligibleStaggeredKyc) {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: (kycTier === 'TIER1' && !isOpenToReKyc) ? bannerMainDescription().POA_REJECTED : bannerMainDescription().POI_REJECTED,
          showKycStatusBadge: true,
          button: <AccountVerificationButton buttonText='Retry verification' />,
          bottomDisplayComponent: null,
        };
      }
      return {
        level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
        mainDescription: bannerMainDescription().KYCFULL_REJECTED,
        showKycStatusBadge: true,
        button: <AccountVerificationButton buttonText='Retry verification' />,
        bottomDisplayComponent: null,
      };

    default:
      // ID DOC EXPIRED SOON AFTER APPROVED
      if (isOpenToReKyc) {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription(moment(poiExpiryDate).format('DD-MM-YYYY')).KYC_EXPIRED_SOON,
          showKycStatusBadge: true,
          button: <AccountVerificationButton buttonText='Renew your POI' />,
          bottomDisplayComponent: null,
        };
      }

      if (kycTier === 'TIER1') {
        return {
          level: getLevelOnKycTier(kycTier, isUserEligibleStaggeredKyc),
          mainDescription: bannerMainDescription().TIER1_APPROVED,
          showKycStatusBadge: false,
          button: (
            <Button primary className={classes.button} onClick={() => dispatch(setIsShowKycBanner(false))}>
              <Trans>Dismiss</Trans>
            </Button>
          ),
          bottomDisplayComponent: (
            <div className={classes.kycDetailsContainer}>
              <div className={classes.kycDetail}>
                <Icon name={'kycBenefit'} svgStyle={{ width: 24, height: 24 }}/>
                <div className={classes.kycDetailDescription}>
                  <Trans>Withdraw up to 10,000 USD per month. You can unlock the withdrawal limit by verifying your proof of address.</Trans>
                </div>
              </div>
            </div>
          ),
        };
      }
      return {
        level: '',
        mainDescription: '',
        showKycStatusBadge: false,
        button: null,
        bottomDisplayComponent: null,
      };
  }
};

const getBannerHeader = (isUserEligibleStaggeredKyc: boolean, isOpenToReKyc: boolean) => {
  let subtitle = 'Unlock or gain full access to Bake features.';
  if (!isUserEligibleStaggeredKyc) {
    subtitle = 'Take a selfie, provide proof of address and basic information to unlock.';
  }
  if (isOpenToReKyc) {
    subtitle = '';
  }
  return {
    title: !isOpenToReKyc ? 'Welcome to Bake Baker!' : '',
    subtitle,
  };
};

const AccountVerificationBanner = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isShowBanner = useCakeSelector(isShowKycBanner);
  const { sumsubKycStatus, isOpenToReKyc, poiExpiryDate } = useCakeSelector(userSumsubKycStatusSelector);
  const userDetails = useCakeSelector(userDetailSelector);
  const { isUserEligibleStaggeredKyc } = useCakeSelector(staggeredKycUserStatusSelector);
  const [isDismissedKycBanner, setIsDismissedKycBanner] = useState(false);
  const showBannerPath = ['/wallets'].includes(location?.pathname);
  const isOpenToKycMigration = userDetails?.kycMigration?.status === 'PENDING_MIGRATION';

  useEffect(() => {
    if (isShowBanner) {
      const isDismissedBanner = storage.getItem('homepageKycBannerBannerDismissed') === 'true';

      if (isDismissedBanner) {
        setIsDismissedKycBanner(true);
        return;
      }
      setIsDismissedKycBanner(false);
    }
  }, [isShowBanner]);

  useEffect(() => {
    if (sumsubKycStatus === 'PENDING' || sumsubKycStatus === 'SCREENING' || sumsubKycStatus === 'REJECTED' || isOpenToReKyc || isOpenToKycMigration) {
      setIsDismissedKycBanner(false);
    }
  }, [sumsubKycStatus, isOpenToReKyc, isOpenToKycMigration]);

  if (!isShowBanner || !showBannerPath || isDismissedKycBanner) {
    return null;
  }

  const { title, subtitle } = getBannerHeader(isUserEligibleStaggeredKyc, isOpenToReKyc || isOpenToKycMigration);

  const {
    level,
    mainDescription,
    showKycStatusBadge,
    button,
    bottomDisplayComponent,
  } = getKycBannerInfo(
    userDetails?.kycTier,
    userDetails?.phoneNumberVerified,
    userDetails?.kycProvider,
    sumsubKycStatus || 'UNVERIFIED',
    isOpenToReKyc,
    isUserEligibleStaggeredKyc,
    poiExpiryDate,
    isOpenToKycMigration,
    userDetails?.kycMigration?.lastDayToMigrate,
    { dispatch, classes },
  );

  return (
    <div className={classes.background}>
      <div className={classes.backgroundContainer}>
        <div className={classes.titleSection}>
          {!!title && <div className={classes.title}>
            <Trans>{title}</Trans>
          </div>}
          {!!subtitle && <div className={classes.subtitle}>
            <Trans>{subtitle}</Trans>
          </div>}
        </div>
        <div className={classes.wrapper}>
          <div className={classes.bannerTopContainer}>
            <KycCurrentLevel level={level}/>
            {
              showKycStatusBadge && <div className={classes.statusContainer}>
                <KycStatusBadge status={sumsubKycStatus} />
              </div>
            }
            <div className={classes.descriptionContainer}>
              <Trans>{mainDescription}</Trans>
            </div>
            {button}
          </div>
          {bottomDisplayComponent}
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationBanner;
