import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  voucherCode: '',
};

const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    setRedeemVoucherCode: (state, action: PayloadAction<string>) => {
      state.voucherCode = action.payload;
    },
  },
});

export const { setRedeemVoucherCode } = redeemSlice.actions;

export default redeemSlice.reducer;
