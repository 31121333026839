import { UserAccessRights, UserProfile } from '@cakedefi/cake-sdk';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../app/rootReducer';
import sdk from '../sdk';
import { setSelectedCurrency, setSelectedLanguage } from './LanguageRegionSettings/languageRegionSettingsSlice';

interface UserState {
  accessRights: UserAccessRights;
  details: any;
  currentPath: string | null;
  previousPath: string | null;
  isWithdrawalOnlyStatus: boolean;
  isCompleteAccessRestricted: boolean;
}

const initialState: UserState = {
  accessRights: null,
  details: null,
  currentPath: null,
  previousPath: null,
  isWithdrawalOnlyStatus: false,
  isCompleteAccessRestricted: false,
};

export const fetchAccessRights = createAsyncThunk<UserAccessRights, void, ThunkApiConfig>(
  'user/fetchAccessRights',
  async () => {
    const response = await sdk.UserInfoApi.getAccessRights();
    return response;
  },
);

export const fetchUserDetails = createAsyncThunk<UserProfile, void, ThunkApiConfig>(
  'user/fetchUserDetails',
  async (_, thunkAPI) => {
    const response = await sdk.UserInfoApi.get();
    const { preference } = response;
    if (preference && preference.preferredLanguage) { thunkAPI.dispatch(setSelectedLanguage(preference.preferredLanguage)); }
    if (preference && preference.preferredCurrency) { thunkAPI.dispatch(setSelectedCurrency(preference.preferredCurrency)); }
    return response;
  },
);

export const reloadUser = () => async (dispatch) => {
  const promise = await dispatch(fetchUserDetails());
  const user = promise.payload;
  if (user.role === 'GENERAL') {
    await dispatch(fetchAccessRights());
  } else {
    await dispatch(clearUser());
    throw new Error('Only general users are allowed to log in');
  }
  return user;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.accessRights = null;
      state.details = null;
    },
    updateUserPreference: (state, action) => {
      if (!(state.details && state.details.preference)) {
        return;
      }
      state.details.preference = {
        ...state.details.preference,
        ...action.payload,
      };
    },
    setKycFullStatus: (state, action) => {
      if (!(state.details && state.details.kycFullStatus)) {
        return;
      }
      state.details = { ...state.details, kycFullStatus: action.payload };
    },
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
    setPreviousPath: (state, action) => {
      state.previousPath = action.payload;
    },
    updateUserDataConsent: (state, action) => {
      state.details.userDataConsent = {
        ...state.details.userDataConsent,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccessRights.fulfilled, (state, action) => {
      state.accessRights = action.payload;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      const userDetails = action.payload;
      state.details = userDetails;
      state.isWithdrawalOnlyStatus = userDetails?.status === 'WITHDRAWAL_ONLY' || userDetails?.userDataConsent?.userConsentStatus === 'WITHDRAWN_WITHDRAWAL_ONLY_IN_SETTINGS';
      state.isCompleteAccessRestricted = userDetails?.userDataConsent?.userConsentStatus === 'WITHDRAWN_FULLY_RESTRICTED_IN_SETTINGS';
    });
  },
});

export const { updateUserPreference, clearUser, setKycFullStatus, setCurrentPath, setPreviousPath, updateUserDataConsent } = userSlice.actions;

export default userSlice.reducer;
