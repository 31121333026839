import { createSlice } from '@reduxjs/toolkit';
import { storage } from 'storage/sessionStorage';

export const RegisterConsentStage = {
  CONSENTS_PAGE: 'CONSENTS_PAGE',
  AGE_RESTRICTION_CONSENT: 'AGE_RESTRICTION_CONSENT',
  PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
  MARKETING_CONSENT: 'MARKETING_CONSENT',
};

interface AuthState {
  loginStep: number;
  registerConsentStage: string;
  email: string;
  password: string;
  loginOtp: string;
  userInputCode: string;
  registerStep: number;
  acceptedTermsAndConditions: boolean;
  acceptedDataConsent: boolean;
  acceptedMarketingConsent: boolean;
  revealInfoToReferrer: boolean;
  isReferralCodeInput: boolean;
  isReferralFieldVisible: boolean;
  registerReCaptchaToken: string;
  isRefCodeNoLongerValid: boolean;
  tempAccessToken: string | null;
  isEmailVerificationSuccess: boolean;
  country: string | null;
  age: string | null;
}

const initialState: AuthState = {
  loginStep: 1,
  registerConsentStage: RegisterConsentStage.CONSENTS_PAGE,
  email: '',
  password: '',
  loginOtp: '',
  registerStep: 1,
  userInputCode: '',
  revealInfoToReferrer: false,
  isReferralCodeInput: false,
  acceptedTermsAndConditions: false,
  acceptedDataConsent: false,
  acceptedMarketingConsent: false,
  isReferralFieldVisible: false,
  registerReCaptchaToken: '',
  isRefCodeNoLongerValid: false,
  tempAccessToken: null,
  isEmailVerificationSuccess: false,
  country: '',
  age: null,
};

export const removeUserConsents = (dispatch) => {
  storage.removeItem('acceptedDataConsent');
  storage.removeItem('acceptedMarketingConsent');
  dispatch(resetAcceptedDataConsent());
  dispatch(resetAcceptedMarketingConsent());
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setConsentStage: (state, action) => {
      state.registerConsentStage = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setLoginOtp: (state, action) => {
      state.loginOtp = action.payload;
    },
    setLoginStep: (state, action) => {
      state.loginStep = action.payload;
    },
    setRegisterStep: (state, action) => {
      state.registerStep = action.payload;
    },
    setUserInputCode: (state, action) => {
      state.userInputCode = action.payload;
    },
    setRevealInfoToReferrer: (state, action) => {
      state.revealInfoToReferrer = action.payload;
    },
    setIsReferralCodeInput: (state, action) => {
      state.isReferralCodeInput = action.payload;
    },
    setAcceptedTermsAndConditions: (state, action) => {
      state.acceptedTermsAndConditions = action.payload;
    },
    setAcceptedDataConsent: (state, action) => {
      state.acceptedDataConsent = action.payload;
      storage.setItem('acceptedDataConsent', action.payload);
    },
    setAcceptedMarketingConsent: (state, action) => {
      state.acceptedMarketingConsent = action.payload;
      storage.setItem('acceptedMarketingConsent', action.payload);
    },
    setIsReferralFieldVisible: (state, action) => {
      state.isReferralFieldVisible = action.payload;
    },
    setRegisterReCaptchaToken: (state, action) => {
      state.registerReCaptchaToken = action.payload;
    },
    setIsRefCodeNoLongerValid: (state, action) => {
      state.isRefCodeNoLongerValid = action.payload;
    },
    setTempAccessToken: (state, action) => {
      state.tempAccessToken = action.payload;
    },
    setIsEmailVerificationSuccess: (state, action) => {
      state.isEmailVerificationSuccess = action.payload;
    },
    resetAcceptedMarketingConsent: (state) => {
      state.acceptedMarketingConsent = false;
    },
    resetAcceptedDataConsent: (state) => {
      state.acceptedDataConsent = false;
    },
    resetLoginStep: (state) => {
      state.loginStep = 1;
    },
    resetUserInputCode: (state) => {
      state.userInputCode = '';
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
  },
});

export const {
  setConsentStage,
  setEmail,
  setPassword,
  setLoginOtp,
  setLoginStep,
  setUserInputCode,
  setRevealInfoToReferrer,
  setIsReferralCodeInput,
  setAcceptedTermsAndConditions,
  setAcceptedDataConsent,
  setAcceptedMarketingConsent,
  setIsReferralFieldVisible,
  setRegisterStep,
  setRegisterReCaptchaToken,
  setIsRefCodeNoLongerValid,
  setTempAccessToken,
  setIsEmailVerificationSuccess,
  resetAcceptedMarketingConsent,
  resetAcceptedDataConsent,
  resetLoginStep,
  resetUserInputCode,
  setCountry,
  setAge,
} = authSlice.actions;

export default authSlice.reducer;
