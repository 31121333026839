import React from 'react';
import { createUseStyles } from 'react-jss';
import { JsonBodySimpleElementProps } from './JSONtoHTMLComponent';

const useStyles = createUseStyles({
  dateTime: {
    fontSize: '1.6rem',
    textAlign: 'right',
  },
});

const UpdatedDateTime = ({ jsonFile }: { jsonFile: JsonBodySimpleElementProps[] }) => {
  const classes = useStyles();
  return (
    <p className={classes.dateTime}>{jsonFile?.[0]?.text}</p>
  );
};

export default UpdatedDateTime;
