import lessonImages from './lessonImages';
import { Stack } from './learnEarnContent';

export const dfiContent: Stack = {
  isAvailable: true,
  id: 'DFI',
  coinId: 'DFI',
  coinName: 'DeFiChain',
  description: 'Native Decentralized Finance enabled on Bitcoin',
  rewardType: 'FrozenStakingShare',
  welcomeImage: lessonImages.dfiCover,
  lessons: [
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'DFI-1',
      lessonIndex: 1,
      title: 'What is DeFiChain',
      parts: [{
        partIndex: 1,
        image: lessonImages.dfiL1P1,
        content: '<b>DeFiChain</b> is a <b>blockchain</b> dedicated to <b>Native Decentralized Finance</b> for <b>Bitcoin</b>. Let’s explore what that means exactly.',
      }, {
        partIndex: 2,
        image: lessonImages.dfiL1P2,
        content: '<b>Decentralized Finance</b> includes all the <b>financial applications</b> you know from more traditional centralized finance (i.e., banks), with the key benefit being that there is <b>no centralized institution</b> you have to trust.',
      }, {
        partIndex: 3,
        image: lessonImages.dfiL1P3,
        content: '<b>Native</b> Decentralized Finance or #NativeDeFi means that the DeFi applications on DeFiChain are <b>built directly on</b> the blockchain, ensuring the <b>highest level of security</b> standards and a greater resistance to hacks.',
      }, {
        partIndex: 4,
        image: lessonImages.dfiL1P4,
        content: 'DeFiChain is a fork of Bitcoin, meaning that its core development started off from the same secure, well audited and robust code base as Bitcoin. As a result, DeFiChain has good synergy with Bitcoin to continue to have <b>seamless integration</b> of <b>decentralized finance</b> applications with the <b>Bitcoin blockchain</b>.',
      }, {
        partIndex: 5,
        image: lessonImages.dfiL1P5,
        content: 'Though DeFiChain is a Bitcoin fork, the blockchain’s consensus is ensured through a hybrid of <b>Proof of Stake</b> and Bitcoin’s Proof of Work. This means that <b>everyone can participate in securing the blockchain</b> and <b>earn block rewards!</b>',
      }, {
        partIndex: 6,
        image: lessonImages.dfiL1P6,
        content: '<b>DeFiChain Staking</b> currently offers {{dfiCoinReturns}}% annual yields, with a stable coin price that correlates closely with Bitcoin due to the high liquidity on the Decentralized Exchange (DEX). This makes DeFiChain <b>perfectly suitable to generate a regular passive income</b>.',
      }],
      quiz: {
        question: 'How can DeFiChain be described in one sentence?',
        answer: 'A',
        options: [{
          option: 'A',
          description: 'DeFiChain is a blockchain dedicated to Native Decentralized Finance for Bitcoin.',
          explanation: 'Correct answer.',
        }, {
          option: 'B',
          description: 'DeFiChain is a fork of Ethereum, enabling fast and reliable second layer Decentralized Finance applications.',
          explanation: 'Wrong. Statement corrected: DeFiChain is a fork of Bitcoin, enabling fast and reliable Native Decentralized Finance applications.',
        }, {
          option: 'C',
          description: 'DeFiChain is a Proof of Work blockchain currently offering {{dfiCoinReturns}}% mining yields for passive income.',
          explanation: 'Wrong. DeFiChain is a Proof of Stake blockchain currently offering {{dfiCoinReturns}}% mining yields for passive income.',
        }, {
          option: 'D',
          description: 'DeFiChain is a blockchain dedicated to Non-Fungible-Tokens and rewarding creators for their art.',
          explanation: 'Wrong. DeFiChain is a blockchain dedicated to Native Decentralized Finance for Bitcoin.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'DFI-2',
      lessonIndex: 2,
      title: 'What is Decentralized Finance',
      parts: [{
        partIndex: 1,
        image: lessonImages.dfiL2P1,
        content: '<b>Decentralized Finance</b> (DeFI) includes all the important <b>financial applications</b> you know from more traditional centralized finance, with the key benefit being that there is <b>no centralized institution</b> you’re required to trust.',
      }, {
        partIndex: 2,
        image: lessonImages.dfiL2P2,
        content: 'Decentralized financial <b>applications</b> include: Lending and <b>borrowing</b>, <b>trading</b>, asset <b>tokenization</b>, distribution of <b>dividends</b>, and more.',
      }, {
        partIndex: 3,
        image: lessonImages.dfiL2P3,
        content: 'A <b>typical financial transaction</b> looks like this: <b>Sender → Bank → Receiver</b>. Decentralized finance <b>cuts out the middle step</b>, making <b>decentralized financial transactions</b> look like this: <b>Sender → Receiver</b>.',
      }, {
        partIndex: 4,
        image: lessonImages.dfiL2P4,
        content: 'Trustless financial applications <b>without an intermediary</b> (i.e. a bank) enables true <b>financial freedom for all</b>, accessible and usable by <b>anyone all around the world</b>. Borderless, without any gatekeeper.',
      }, {
        partIndex: 5,
        image: lessonImages.dfiL2P5,
        content: 'All decentralized finance must be <b>open-source by nature</b>. This level of heightened transparency contributes to <b>long-term security</b>, allowing people to <b>verify the code</b> themselves without the <b>need to trust</b> anyone or anything other than pure mathematics.',
      }, {
        partIndex: 6,
        image: lessonImages.dfiL2P6,
        content: 'DeFiChain already has <b>decentralized trading</b> functionality <b>live and working</b>, with <b>lending</b> and <b>borrowing</b>, as well as <b>asset tokenization coming in Q3 2021</b>. Soon you’ll be able to buy <b>Tesla, Apple and more</b> directly on the <b>blockchain</b> in a decentralized way!',
      }],
      quiz: {
        question: 'What is Decentralized Finance?',
        answer: 'B',
        options: [{
          option: 'A',
          description: 'Better banking software to automate accounting and budget planning',
          explanation: 'No, Decentralized Finance by its nature can\'t be banking software because banks always are centralized institutions.',
        }, {
          option: 'B',
          description: 'Decentralized Finance are all types of financial applications without the need of a third party intermediary like a bank',
          explanation: 'Correct answer.',
        }, {
          option: 'C',
          description: 'Decentralized Finance only refers to peer-to-peer trading between crypto-assets without a centralized party, like an exchange',
          explanation: 'Though the peer-to-peer trading between crypto-assets is one aspect of Decentralized Finance, it is much more than only that.',
        }, {
          option: 'D',
          description: 'Decentralized Finance enables all types of financial applications worldwide, after registering an account and passing a KYC process.',
          explanation: 'No, registering an account and passing a KYC process always includes a centralized party, so this can\'t be Decentralized Finance.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'DFI-3',
      lessonIndex: 3,
      title: '#NativeDeFi vs. DeFi on Ethereum',
      parts: [{
        partIndex: 1,
        image: lessonImages.dfiL3P1,
        content: 'Most DeFi projects are built on the <b>Ethereum</b> blockchain, where applications run on so-called virtual machines. <b>Unlike native blockchains</b>, blockchains like Ethereum offer <b>greater flexibility</b> for developers. This flexibility, however, makes them <b>more risky</b> for <b>decentralized financial applications</b>.',
      }, {
        partIndex: 2,
        image: lessonImages.dfiL3P2,
        content: 'When it comes to financial services, Ethereum uses smart contracts on the <b>application layer</b>, which could be <b>prone</b> to <b>human errors</b> and coding <b>mistakes</b>. In addition, they are <b>simply not built for the function of financial applications</b>, but rather a one-size-fits all solution. There, the code gets compiled and has to run through a virtual machine.',
      }, {
        partIndex: 3,
        image: lessonImages.dfiL3P3,
        content: 'On blockchains like <b>DeFiChain</b>, the execution of the code happens directly <b>on the native blockchain layer</b> — no “on-top” application layer is needed. As a result, DeFiChain is <b>perfectly designed for</b> all kinds of <b>financial applications and services</b>.',
      }, {
        partIndex: 4,
        image: lessonImages.dfiL3P4,
        content: 'Performing <b>DeFi transactions</b> on <b>Ethereum takes more than 3,000 distinct steps</b> — each with its own <b>potential attack</b> surface. On <b>DeFiChain</b>, just a <b>few lines of code</b> are enough, making transactions much more robust against potential attacks, and DeFiChain’s <b>#NativeDeFi</b> approach, <b>more secure</b>.',
      }, {
        partIndex: 5,
        image: lessonImages.dfiL3P5,
        content: 'Different DeFi stakeholders have <b>different preferences</b> when it comes to security. While <b>developers</b> look at it from a <b>purely technical perspective</b>, wanting to be as <b>flexible</b> as possible in writing their code, <b>end users</b> only care that DeFi projects work in their favour by <b>mitigating hacks</b> and malicious attacks. <b>DeFiChain</b> addresses that by providing a <b>secure and safe DeFi platform</b>.',
      }, {
        partIndex: 6,
        image: lessonImages.dfiL3P6,
        content: 'The <b>main benefits of #NativeDeFi</b> include: <b>Consensus</b> happens directly on the <b>native blockchain layer</b>, the transaction <b>costs are low</b>, and the <b>attack surface is low</b>.',
      }],
      quiz: {
        question: 'What are the benefits of #NativeDeFi?',
        answer: 'C',
        options: [{
          option: 'A',
          description: 'DeFi on Ethereum works totally in the same way as on DeFiChain.',
          explanation: 'DeFi on Ethereum works totally different than on DeFiChain, because it is a turing-complete system not designed for it.',
        }, {
          option: 'B',
          description: 'The main benefits of #NativeDeFi are: the consensus happens directly on the native blockchain layer, the transaction costs are high, the attack surface is low.',
          explanation: 'The main benefits of #NativeDeFi are: the consensus happens directly on the native blockchain layer, the transaction costs are LOW, the attack surface is low.',
        }, {
          option: 'C',
          description: 'Native DeFi is more secure than non-Native DeFi because the consensus happens directly on the native blockchain layer, instead of getting compiled and running through a virtual machine like on Ethereum.',
          explanation: 'Correct answer.',
        }, {
          option: 'D',
          description: 'DeFiChain’s main blockchain layer is insufficient to process and validate financial transactions, making it much more secure than virtual machine blockchains.',
          explanation: 'DeFiChain’s main blockchain layer is SUFFICIENT to process and validate financial transactions, making it much more secure than virtual machine blockchains',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'DFI-4',
      lessonIndex: 4,
      title: 'Earn Incredibly High Yields',
      parts: [{
        partIndex: 1,
        image: lessonImages.dfiL4P1,
        content: 'DeFiChain – <b>through</b> various <b>decentralized finance applications</b> – enables everyone to participate and <b>earn incredibly high yields</b>.',
      }, {
        partIndex: 2,
        image: lessonImages.dfiL4P2,
        content: 'Two of DeFiChain’s most important applications are <b>Staking</b> and <b>Liquidity Mining</b>, which offer <b>returns of up to {{highestApyAcrossLmAndStaking}}% per year</b>.',
      }, {
        partIndex: 3,
        image: lessonImages.dfiL4P3,
        content: '<b>Proof of Stake</b> (Staking) is DeFiChain’s consensus algorithm and is comparable to Bitcoin’s <b>Proof of Work</b> (Mining). The difference is that Staking enables <b>anyone</b> to participate even <b>without</b> expensive <b>mining rigs</b> and vast amounts of <b>electricity</b>, to <b>earn new DFI block rewards</b> like bitcoin miners earn new bitcoins.',
      }, {
        partIndex: 4,
        image: lessonImages.dfiL4P4,
        content: '<b>Liquidity Mining</b> is not part of DeFiChain’s consensus algorithm, but serves the purpose of <b>providing liquidity</b> for the <b>decentralized exchange</b>. In return, liquidity miners get <b>block rewards</b>, making it a very <b>lucrative endeavour</b> and a <b>win-win</b> for everyone involved.',
      }, {
        partIndex: 5,
        image: lessonImages.dfiL4P5,
        content: '<b>Liquidity Mining</b> on <b>DeFiChain</b> even allows you to <b>earn up to {{highestApyAcrossLmAndStaking}}%</b> yearly returns on major crypto assets like <b>BTC, ETH</b> and more.',
      }, {
        partIndex: 6,
        image: lessonImages.dfiL4P6,
        content: '<b>Everything</b> is done in a <b>non-custodial</b> manner, meaning that your <b>money</b> is always in your own possession and <b>growing</b> in <b>your own hands</b>.',
      }],
      quiz: {
        question: 'What are the main ways to earn incredibly high yields with DeFiChain?',
        answer: 'B',
        options: [{
          option: 'A',
          description: 'You can open up to 100x leverage positions with just one click, allowing for perfect market timing.',
          explanation: 'Though that will be possible in the near future, this is not one of the main ways to earn high yields with DeFiChain, and in fact extremely risky. Not what DeFiChain is about!',
        }, {
          option: 'B',
          description: 'Staking and Liquidity Mining offer great and easy ways for anyone to earn block rewards up to {{highestApyAcrossLmAndStaking}}% yearly returns.',
          explanation: 'Correct! Sit back, relax and enjoy your passive income, all while profiting from a coin correlating strongly with BTC.',
        }, {
          option: 'C',
          description: 'DFI has increased by more than 15x. You just buy and HODL, nothing more.',
          explanation: 'Though it is true that DFI — DeFiChain\'s native coin — has increased by more than 15x, there are even more ways to earn high yields than just through HODLing.',
        }, {
          option: 'D',
          description: 'You need to buy expensive mining rigs powered by high amounts of electricity, then you can earn up to {{highestApyAcrossLmAndStaking}}% yearly returns with block rewards.',
          explanation: 'No, you don\'t need to buy any expensive mining rigs, and also don\'t need high amounts of electricity to earn up to {{highestApyAcrossLmAndStaking}}% yearly returns with block rewards.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'DFI-5',
      lessonIndex: 5,
      title: 'The Decentralized Exchange',
      parts: [{
        partIndex: 1,
        image: lessonImages.dfiL5P1,
        content: 'DeFiChain’s <b>Decentralized Exchange (DEX)</b> is the cornerstone of most decentralized financial applications in the DeFiChain ecosystem.',
      }, {
        partIndex: 2,
        image: lessonImages.dfiL5P2,
        content: 'DeFiChain’s <b>DEX</b> not only allows you to <b>earn incredibly high yields</b> by providing liquidity, but also really <b>demonstrates</b> the <b>power of true decentralized finance</b>.',
      }, {
        partIndex: 3,
        image: lessonImages.dfiL5P3,
        content: 'Being <b>#NativeDeFi for Bitcoin</b>, you can <b>send actual bitcoins (BTC)</b> to and from the <b>Decentralized Exchange</b>, and trade them for ETH, DFI, USDT, DOGE and many more digital tokens. This is a <b>feature unheard of before</b> in the crypto space.',
      }, {
        partIndex: 4,
        image: lessonImages.dfiL5P4,
        content: 'Unlike most other decentralized exchanges, such as those built on Ethereum, DeFiChain’s DEX is <b>not a website</b> – which itself represents a centralized, vulnerable party – but rather an <b>open-source, publicly available app</b> that can be run by anyone. Because it’s decentralized, it also <b>can’t be shut down</b> by a centralized institution.',
      }, {
        partIndex: 5,
        image: lessonImages.dfiL5P5,
        content: 'The coin <b>powering all transactions</b> and trades is <b>DFI</b>, the <b>native digital coin</b> of <b>DeFiChain</b>. Since its launch, the DFI coin has <b>increased by more than 15x</b>, offering <b>more than {{dfiStakingShareReturns}}% yearly returns</b>, and proving to be <b>relatively stable</b> even in bearish market conditions.',
      }, {
        partIndex: 6,
        image: lessonImages.dfiL5P6,
        content: '<b>Decentralized stocks, loans, dividends</b> and <b>more</b> will be <b>introduced</b> to DeFiChain in the <b>upcoming weeks</b>, making the DFI coin a <b>strong investment</b> with a lot of <b>upside potential</b> that can serve as a <b>reliable, high yielding coin</b> in any <b>well-diversified</b> crypto portfolio.',
      }],
      quiz: {
        question: 'What is DeFiChain’s Decentralized Exchange?',
        answer: 'D',
        options: [{
          option: 'A',
          description: 'DeFiChain’s decentralized exchange is a website like Uniswap, used with a tool very similar to MetaMask.',
          explanation: 'No, DeFiChain\'s decentralized exchange is not a website but an app run directly on your computer.',
        }, {
          option: 'B',
          description: 'Being DeFi on Ethereum, you can send actual ether (ETH) to and from the decentralized exchange, and trade them for BTC, DFI, USDT, DOGE and many more coins.',
          explanation: 'DeFiChain is built on Bitcoin, not Ethereum, meaning you can send actual bitcoin (BTC) to and from the decentralized exchange.',
        }, {
          option: 'C',
          description: 'The coin powering all transactions and trades on DeFiChain’s decentralized exchange is DFI, which has been stable, but not seen any strong price increase yet.',
          explanation: 'It\'s true that DeFiChain\'s native coin DFI powers all transactions and trades, but it did already see strong price increases.',
        }, {
          option: 'D',
          description: 'DeFiChain’s decentralized exchange is the cornerstone of most decentralized financial applications in the DeFiChain ecosystem, enabling trading, liquidity mining and more',
          explanation: 'Correct answer.',
        }],
      },
    },
  ],
};
