import React from 'react';
import { createUseStyles } from 'react-jss';
import { WebappTheme } from 'types/theme';
import styles from './Checkbox.styles';

const useStyles = createUseStyles<string, {
  disabled: boolean,
  bottomSpacing: boolean,
  appTheme: WebappTheme,
}>((styles));

export interface CheckBoxProps {
  disabled?: boolean;
  children: React.ReactNode;
  onChange: any;
  isChecked?: boolean;
  indeterminate?: boolean;
  tabIndex?: number;
  bottomSpacing?: boolean;
  appTheme?: WebappTheme
}

export default function CheckBox(props: CheckBoxProps) {
  const {
    disabled,
    isChecked,
    indeterminate,
    onChange,
    tabIndex = 1,
    children,
    bottomSpacing = false,
    appTheme = 'CAKE',
  } = props;
  const classes = useStyles({
    disabled,
    bottomSpacing,
    appTheme,
  });

  const toggleMouse = () => {
    if (disabled) {
      return;
    }

    onChange(!isChecked);
  };

  const toggleKeyboard = (e: React.KeyboardEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }
    if (e && e.keyCode !== 13 && e.keyCode !== 32) {
      return;
    }

    onChange(!isChecked);
  };

  const getCheckboxClass = () => {
    if (isChecked) {
      return classes.checked;
    }

    if (indeterminate) {
      return classes.indeterminate;
    }

    return classes.unchecked;
  };

  return (
    <div
      className={classes.container}
      onClick={toggleMouse}
      onKeyDown={toggleKeyboard}
    >
      {/* Todo: replace span with input of type checkbox for accessibility */}
      <span tabIndex={tabIndex} className={getCheckboxClass()}></span>
      <div className={classes.label}>{children}</div>
    </div>
  );
}
