import pageView from './pageView';
import trackEvent, { iEventNames, iEventTypes } from './trackEvent';

const gtmEvents = {
  pageView,
  trackEvent,
};

const getUserInformation = (props) => {
  const { userDetails, referralCode } = props;
  const nonLoggedInUserInfo = {
    user_id: null,
    kyc_full_status: null,
    user_country_code: null,
    referral_code: referralCode,
  };

  if (!userDetails) {
    return nonLoggedInUserInfo;
  }

  const { marketingId, kycFullStatus, country, userId } = userDetails;

  return {
    ...nonLoggedInUserInfo,
    // user's moengageId was previously used for all marketing related tracking, but separated now and copied to user's marketingId for existing users
    // we leave moengage_id key as it is so we don't break anything
    moengage_id: marketingId,
    marketing_id: marketingId,
    kyc_full_status: kycFullStatus,
    user_country_code: country,
    referral_code: referralCode,
    user_id: userId,
  };
};

const gtmTrack = (eventName: any, props?: any) => {
  let basicUserInfo = {};
  let eventData = {};
  if (props?.userDetails) {
    basicUserInfo = getUserInformation(props);
  }
  if (props?.event_data && !!props.event_data) {
    eventData = { event_data: { ...props.event_data } };
  }
  gtmEvents[eventName]({
    event: props?.event,
    event_type: props?.event_type,
    user_country_code: props?.user_country_code,
    kyc_full_status: props?.kyc_full_status,
    ...basicUserInfo,
    ...eventData,
  });
};

export default gtmTrack;

export {
  iEventNames,
  iEventTypes,
};
