import React, { FC, ReactElement, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { MdError } from 'react-icons/md';
import { NetworkErrorSlice, NetworkErrorType } from '../types/app/network-error';
import ClientConnectionDownToastStyles from './ClientConnectionDownToast.styles';
import { pingHost } from './networkErrorService';
import { clearNetworkError } from './networkErrorReducer';
import config from '../config/app';

const useStyles = createUseStyles(ClientConnectionDownToastStyles);

const { PING_CLIENT_HOST_INTERVAL } = config;

const ClientConnectionDownToast: FC = (): ReactElement => {
  const classes = useStyles();
  const { hasNetworkError, networkErrorType }: NetworkErrorSlice = useSelector((state: any) => state.networkError);
  const dispatch = useDispatch();

  const isClientConnectionError = hasNetworkError && networkErrorType === NetworkErrorType.CLIENT_CONNECTION_DOWN;

  useEffect(() => {
    if (!isClientConnectionError) {
      return;
    }
    const connectionIntervalChecker = setInterval(async () => {
      await pingHost();
      dispatch(clearNetworkError());
      clearInterval(connectionIntervalChecker);
    }, PING_CLIENT_HOST_INTERVAL);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(connectionIntervalChecker);
    };
  }, [dispatch, isClientConnectionError]);

  if (!isClientConnectionError) {
    return null;
  }

  return createPortal(
    <div className={classes.wrapper}>
      <div className={classes.messageContainer}>
        <MdError className={classes.errorIcon} /><Trans>Your connection is down.</Trans>
      </div>
    </div>,
    document.querySelector('#connection-toast'),
  );
};

export default ClientConnectionDownToast;
