import { createSelector } from '@reduxjs/toolkit';
import { isPositiveAndNotZeroNumber } from 'utils';
import { getFiatBuyModalState } from '../fiatBuyModalSelector';
import { depositFeeUsdSelector } from './depositFeeUsdSelector';

export const isShowETHFeeSelector = createSelector(
  getFiatBuyModalState,
  depositFeeUsdSelector,
  (state, ethDepositFeeUsd) => {
    const { inputs, hasWalletPaidFee } = state;
    const isShowETHFee = inputs.target === 'ETH' && !hasWalletPaidFee;

    return isShowETHFee && isPositiveAndNotZeroNumber(ethDepositFeeUsd);
  },
);
