/**
 * @param language 'cn'|'en' as default
 */
export default function getLapisKnowledgeBaseLinks(language?: string): string {
  switch (language) {
    case 'cn':
      return 'https://cake.zendesk.com/hc/zh-cn/sections/360008118551-Lapis-Service-by-Pool-by-Cake';
    default:
      return 'https://cake.zendesk.com/hc/en-us/sections/360008118551-Lapis-Service-by-Pool-by-Cake';
  }
}
