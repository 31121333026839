import mobileAppQr from 'assets/svg/mobile-download-qr.svg';
import { ModalContent, ModalHeader } from 'modals/components';
import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
    padding: '24px',
  },
  qrCode: {
    width: '100%',
    height: '95px',
  },
  title: {
    fontSize: '42px',
    fontWeight: Variables.fontWeight.extraBold,
    paddingRight: '100px',
    marginBottom: '16px',
  },
  smallTitle: {
    fontSize: '24px',
    fontWeight: Variables.fontWeight.bold,
    margin: 0,
    marginBottom: '16px',
  },
  subtitle: {
    fontSize: '16px',
  },
  qrCodeSection: {
    display: 'grid',
    gridTemplateColumns: '20% 1fr',
    gap: '12px',
  },
  downloadSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: Variables.fontWeight.semiBold,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      paddingRight: '0px',
      lineHeight: '1',
    },
    qrCodeSection: {
      gridTemplateColumns: '30% 1fr',
      gap: '15px',
    },
    qrCode: {
      minWidth: '80px',
    },
  },
});

interface KycAttestationModalProps {
  onClose: () => void;
}
/**
 * Modal shown on login to prompt user redo their KYC attestation when needed
 */
const KycAttestationModal = (props: KycAttestationModalProps) => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <>
      <ModalHeader
        title={''}
        onClose={onClose}
        showCancelButton={true}
        cancelLabel="Close"
      />
      <ModalContent>
        <div className={classes.container}>
          <h1 className={classes.title}>
            <Trans>Identity Information Update Required</Trans>
          </h1>
          <p className={classes.subtitle}>
            <Trans>
              To comply with regulatory requirements, we need you to confirm or
              update your identity information by 31 Oct to avoid any
              interruptions to your account.
            </Trans>
          </p>

          <p className={classes.smallTitle}>
            <Trans>Please use our mobile app to complete this process.</Trans>
          </p>
          <div>
            <ol>
              <li>
                <Trans>Open our mobile app</Trans>
              </li>
              <li>
                <Trans>
                  Click on the top banner on the home screen to confirm or
                  update your identity information.
                </Trans>
              </li>
            </ol>
          </div>

          <div className={classes.qrCodeSection}>
            <img
              src={mobileAppQr}
              alt="nova-mobile-qr"
              className={classes.qrCode}
            />
            <div className={classes.downloadSection}>
              <div>
                <Trans>Scan to download the app</Trans>
              </div>
              <div>
                <Trans>iOS & Android</Trans>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default KycAttestationModal;
