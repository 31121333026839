import React from 'react';
import { createUseStyles } from 'react-jss';
import { WebappTheme } from 'types/theme';
import Variables from 'Variables.styles';
import { CheckBox } from 'views/components';

const useStyles = createUseStyles<
  string,
  {
    appTheme: WebappTheme;
  }
>({
  disclaimerAcceptance: {
    marginTop: Variables.medium,
    marginBottom: Variables.medium,
    fontSize: '1.6rem',
    '& > div:first-child': {
      alignItems: 'flex-start',
    },
  },
});

interface DisclaimerCheckboxBaseProps {
  isAccepted: boolean;
  setIsAccepted: (value: boolean) => void;
  appTheme?: WebappTheme;
  children?: React.ReactNode;
}

export default function DisclaimerCheckboxBase({
  isAccepted,
  setIsAccepted,
  appTheme = 'CAKE',
  children,
}: DisclaimerCheckboxBaseProps) {
  const classes = useStyles({
    appTheme,
  });
  return (
    <div className={classes.disclaimerAcceptance}>
      <CheckBox
        isChecked={isAccepted}
        onChange={(isChecked) => setIsAccepted(isChecked)}
        appTheme={appTheme}
      >
        {children}
      </CheckBox>
    </div>
  );
}
