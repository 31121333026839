import React from 'react';
import { Trans } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import Button from 'common/components/Button/Button';
import { ButtonProps } from 'common/components/Button/ButtonProps';
import Variables from 'Variables.styles';

const useStyles = createUseStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLeft: {
    composes: '$button',
    paddingLeft: Variables.small,
  },
  buttonRight: {
    composes: '$button',
    paddingRight: Variables.small,
  },
});

interface ModalFooterButtonProps extends ButtonProps {
  buttonText: string;
  className?: string;
  arrow?: 'left' | 'right';
}

const ModalFooterButton = (modalFooterButtonProps: ModalFooterButtonProps) => {
  const { buttonText, className, arrow, ...props } = modalFooterButtonProps;
  const classes = useStyles();

  const buttonStyle = (() => {
    if (arrow === 'left') return classes.buttonLeft;
    if (arrow === 'right') return classes.buttonRight;
    return classes.button;
  })();

  return (
    <Button {...props} className={`${buttonStyle} ${className}`}>
      { arrow && arrow === 'left' && <MdKeyboardArrowLeft size="24px"/> }
      <Trans i18nKey={buttonText}>{buttonText}</Trans>
      { arrow && arrow === 'right' && <MdKeyboardArrowRight size="24px"/>}
    </Button>
  );
};

export default ModalFooterButton;
