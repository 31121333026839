import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { MdPrivacyTip, MdStars } from 'react-icons/md';
import { AiFillClockCircle } from 'react-icons/ai';

const useBottomKycBenefitsStyles = createUseStyles({
  container: {
    backgroundColor: '#F5F4F7',
    width: '100%',
    padding: Variables.medium,
    marginTop: Variables.medium,
    borderRadius: Variables.small,
    display: 'flex',
    justifyContent: 'space-between',
    gap: Variables.medium,
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    fontWeight: Variables.fontWeight.semiBold,
    fontSize: '14px',
    paddingLeft: Variables.small,
  },
  icon: {
    color: Variables.gray600,
    height: Variables.large,
    width: Variables.large,
    marginRight: Variables.small,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    container: {
      flexDirection: 'column',
    },
  },
});

export const kycBenefitList = [
  'Deposit, trade and earn crypto.',
  'Receive fraud and identity theft protection.',
  'This process takes less than 3 minutes to complete.',
];

const BottomKycBenefits = () => {
  const classes = useBottomKycBenefitsStyles();
  return (
    <div className={classes.container}>
      <div className={classes.detail}>
        <MdStars className={`${classes.icon} mr-0`}/>
        <div className={classes.description}>
          <Trans>{kycBenefitList[0]}</Trans>
        </div>
      </div>
      <div className={classes.detail}>
        <MdPrivacyTip className={`${classes.icon} mr-0`}/>
        <div className={classes.description}>
          <Trans>{kycBenefitList[1]}</Trans>
        </div>
      </div>
      <div className={classes.detail}>
        <AiFillClockCircle className={`${classes.icon} mr-0`}/>
        <div className={classes.description}>
          <Trans>{kycBenefitList[2]}</Trans>
        </div>
      </div>
    </div>
  );
};

export default BottomKycBenefits;
