import React, { Component } from 'react';
import ReactSvg from 'react-svg';
import { Trans } from 'react-i18next';
import {
  Col,
  Row,
  Container,
  Progress,
} from 'reactstrap';
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';
import Modal from '../Modal';
import SprodEntry from '../SprodEntry';
import SprodDetails from '../SprodDetails';
import Countdown from '../Countdown';
import { getCoinIconName, localizeBigNumber } from '../../../utils';
import getLocalizedDate from '../../../utils/getLocalizedDate';
import MouseOverTooltip from '../MouseOverTooltip';
import info from '../../../assets/svg/icon-info.svg';
import SprodBonusPopover from '../SprodBonusPopover';
import Button from '../../../common/components/Button/Button';
import Icon from '../Icon';
import { PROMO_TYPE_SLUG } from './constants';

const ONE_MINUTE = 60 * 1000;
const mapState = state => ({ userDetails: state.user.details });
const getTimeDifference = (startDate) => {
  const t1 = Date.now();
  const t2 = new Date(startDate).getTime();
  return t2 - t1;
};
const convertMillisecondsToMinutes = milliseconds => Math.floor((milliseconds / ONE_MINUTE) % 60);

class Sprod extends Component {
  interval = null;

  constructor(props) {
    super(props);
    this.state = {
      enteredAmount: null,
      isModalVisible: false,
      timeToCloseSales: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { enteredAmount } = this.props;
    if (prevProps.enteredAmount !== enteredAmount) {
      this.setState({
        enteredAmount,
      });
    }
  }

  componentDidMount() {
    const { startDate } = this.props;
    this.setUpTimer(startDate);
    this.setState({
      enteredAmount: this.props.enteredAmount || null,
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setUpTimer(startDate) {
    if (!startDate) {
      return;
    }

    const timeToCloseSales = this.setTimeToCloseSales(startDate);
    if (timeToCloseSales > 0) {
      this.interval = setInterval(() => {
        this.setTimeToCloseSales(startDate);
      }, ONE_MINUTE);
    }
  }

  setTimeToCloseSales(startDate) {
    const timeToCloseSales = getTimeDifference(startDate);
    this.setState({
      timeToCloseSales,
    });
    return timeToCloseSales;
  }

  renderDetails() {
    const {
      name,
      slug,
      coin,
      tier2Threshold,
      tier2Apy,
      returnPerAnnum,
    } = this.props;

    return (
      <Container>
        <SprodDetails name={name} slug={slug} coinName={coin.name} coinId={coin.id} tier2Threshold={tier2Threshold} tier2Apy={tier2Apy} returnPerAnnum={returnPerAnnum} />
        <div className="entry-modal-footer">
          <Button btnLink className="fright" onClick={() => this.setState({ isModalVisible: false })}><Trans>Close</Trans></Button>
        </div>
      </Container>
    );
  }

  renderBatchNumber() {
    const { batchNumber } = this.props;

    if (!batchNumber) {
      return <span><Trans>Currently unavailable</Trans></span>;
    }

    return <span><Trans>Batch {{ batchNumber }}</Trans> </span>;
  }

  renderProgramHeading() {
    const {
      name,
      batchNumber,
      startDate,
      endDate,
      isEmpty,
    } = this.props;
    const { timeToCloseSales } = this.state;
    return (
      <div>
        <h3><Trans>{name}</Trans></h3>
        <span className="text-secondary">
          {isEmpty ? null : <span><Trans>Batch {{ batchNumber }}</Trans> </span>}
          {startDate && endDate ? (
            <span>({getLocalizedDate(new Date(startDate))} – {getLocalizedDate(new Date(endDate))})</span>
          ) : null}
        </span>
        {startDate ? <Countdown timeToCloseSales={timeToCloseSales} /> : null}
      </div>
    );
  }

  renderStartingAmount() {
    const {
      coinSymbol,
      estimatedAutocompoundAmount,
      batchCap,
      startingAmount,
    } = this.props;

    if (batchCap) {
      const batchCapFormatted = new BigNumber(batchCap).toFormat();

      if (estimatedAutocompoundAmount) {
        const totalStartingAmountBN = new BigNumber(startingAmount).plus(new BigNumber(estimatedAutocompoundAmount));
        const totalStartingAmountFormatted = localizeBigNumber(totalStartingAmountBN, false, 3);

        return (
          <Col md="7" xs="12">
            <span className="flex-vertical-center">
              <Trans>Total starting amount</Trans> / <Trans>cap</Trans>
              {this.renderBatchCapProgressBar()}
            </span>
            <div className="h2">
              {totalStartingAmountFormatted} / {batchCapFormatted} {coinSymbol}
            </div>
          </Col>
        );
      }

      return (
        <Col md="7" xs="12">
          <span className="flex-vertical-center">
            <Trans>Total starting amount</Trans> / <Trans i18nKey="Batch capacity">cap</Trans>
            {this.renderBatchCapProgressBar()}
          </span>
          <div className="h2">
            {localizeBigNumber(startingAmount, false, 3)} / {batchCapFormatted} {coinSymbol}
          </div>
        </Col>
      );
    }

    return (
      <Col md="7" xs="12">
        <span><Trans>Total starting amount</Trans></span>
        <div className="h2">{localizeBigNumber(startingAmount, false, 3)} {coinSymbol}</div>
      </Col>
    );
  }

  renderBatchCapProgressBar = () => {
    const {
      batchCap,
      estimatedAutocompoundAmount,
      startingAmount,
    } = this.props;
    const startingAmountBN = new BigNumber(startingAmount);

    if (!estimatedAutocompoundAmount) {
      const progress = startingAmountBN.dividedBy(new BigNumber(batchCap)).toFixed();

      return (
        <MouseOverTooltip renderTooltip={() => this.renderProgressBarPopover(progress)}>
          <Progress className="batch-cap-progress-bar" value={progress * 100} />
        </MouseOverTooltip>
      );
    }

    const autocompoundBN = new BigNumber(estimatedAutocompoundAmount);
    const batchCapBN = new BigNumber(batchCap);

    const autocompoundProgress = autocompoundBN.dividedBy(batchCapBN).multipliedBy(100).toFixed();
    const newEntriesProgress = startingAmountBN.dividedBy(batchCapBN).multipliedBy(100).toFixed();

    return (
      <MouseOverTooltip renderTooltip={() => this.renderSplittedProgressBarPopover(estimatedAutocompoundAmount, startingAmount)}>
        <Progress className="batch-cap-progress-bar" multi>
          <Progress bar value={autocompoundProgress} className="dotted" />
          <Progress bar value={newEntriesProgress} />
        </Progress>
      </MouseOverTooltip>
    );
  }

  renderProgressBarPopover = (progress) => {
    const normalizedProgress = progress <= 1 ? progress : 1;

    return (
      <React.Fragment>
        <div className="batch-detail-popover">
          {(normalizedProgress * 100).toFixed(0)}%
        </div>
      </React.Fragment>
    );
  }

  renderSplittedProgressBarPopover = (autocompoundAmount, newEntries) => {
    const { coinSymbol } = this.props;
    return (
      <div className="tooltip-wrapper">
        <div className="flex-vertical-center">
          <Progress multi className="batch-cap-progress-bar progress-bar-preview">
            <Progress bar className="dotted" value={100} />
          </Progress>
          <span>
            <span><Trans>Auto-compounded</Trans>: </span>
            {localizeBigNumber(autocompoundAmount)} {coinSymbol}
          </span>
        </div>
        <div className="flex-vertical-center">
          <Progress className="batch-cap-progress-bar progress-bar-preview" value={100} />
          <span>
            <span><Trans>New entries</Trans>: </span>
            {localizeBigNumber(newEntries)} {coinSymbol}
          </span>
        </div>
      </div>
    );
  }

  renderEnteredAmount() {
    const {
      enteredAmount,
    } = this.state;
    const {
      coinSymbol,
      page,
    } = this.props;

    if (page && this.props.userDetails && enteredAmount) {
      return (
        <Row>
          <Col md="7" xs="12" className="my-entry-wrapper-mobile">
            <span>{new BigNumber(enteredAmount).toFixed()} {coinSymbol}</span>
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderExpectedAPY = () => {
    const { returnPerAnnum, dfiRewardPerAnnum, bonusTarget, bonusReturn, coinSymbol, slug, tier2Apy, tier2Threshold } = this.props;
    const expectedReturn = new BigNumber(returnPerAnnum).multipliedBy(100).toFormat();
    const tier2Return = new BigNumber(tier2Apy).multipliedBy(100).toFormat();
    const promoType = slug === PROMO_TYPE_SLUG;

    if (bonusReturn && bonusTarget) {
      const maxTotalReturn = dfiRewardPerAnnum
        ? new BigNumber(returnPerAnnum).plus(bonusReturn).plus(dfiRewardPerAnnum)
          .multipliedBy(100)
          .toFixed()
        : new BigNumber(returnPerAnnum).plus(bonusReturn)
          .multipliedBy(100)
          .toFixed();

      return (
        <Col md="5" xs="12" className="expected-apy">
          <span><Trans>Expected APY</Trans></span>
          <Row className="staking-name h2">
            <Trans>Up to {{ maxTotalReturn }}%</Trans>
            <MouseOverTooltip renderTooltip={() => this.renderBonusExplanationPopover()}>
              <ReactSvg src={info} className="info-icon" />
            </MouseOverTooltip>
          </Row>
        </Col>
      );
    }

    if (dfiRewardPerAnnum) {
      const dfiReturn = new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFormat();
      return (
        <Col md="5" xs="12" className="expected-apy">
          <span><Trans>Expected APY</Trans></span>
          <Row className="h2">
            {new BigNumber(expectedReturn).plus(dfiReturn).toFormat()}%
            <MouseOverTooltip renderTooltip={() => this.renderDfiBonusPopover(expectedReturn, coinSymbol, dfiReturn)}>
              <ReactSvg src={info} className="info-icon" />
            </MouseOverTooltip>
          </Row>
        </Col>
      );
    }

    return (
      <Col md="5" xs="12" className="expected-apy">
        <span><Trans>Expected APY</Trans></span>
        {promoType
          ? (<div><span className="h2 xmas">{expectedReturn}%</span></div>)
          : (<><div className="h2"><Trans>{{ expectedReturn }}% (up to {{ tier2Threshold }} {{ coinSymbol }})</Trans></div>
            <div className="h2">{ tier2Return }% <Trans>for any surplus amount</Trans></div></>)
        }
      </Col>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDfiBonusPopover(expectedReturn, coinSymbol, dfiReturn) {
    return (
      <span className="tooltip-wrapper">
        <span>{expectedReturn}% {coinSymbol} + {dfiReturn}% DFI</span>
      </span>
    );
  }

  renderBonusExplanationPopover() {
    return <SprodBonusPopover {...this.props} />;
  }

  render() {
    const {
      enteredAmount,
      isModalVisible,
    } = this.state;

    const {
      extractedBalance,
      returnPerAnnum,
      name,
      coinSymbol,
      saleClosesOn,
      maturesOn,
      entryFee,
      batchNumber,
      batchId,
      slug,
      compoundOnSettle,
      batchCap,
      refreshEnteredAmount,
      refreshAutoCompoundSettings,
      loadBalances,
      coin,
      isEmpty,
      estimatedAutocompoundAmount,
      durationInDays,
      status,
      bonusReturn,
      bonusTarget,
      dfiRewardPerAnnum,
      dfiCoin,
      startingAmount,
      refreshBatches,
      tier2Threshold,
      tier2Apy,
      feeInPercent,
    } = this.props;
    const promoType = slug === PROMO_TYPE_SLUG;

    const isSaleStillOpen = convertMillisecondsToMinutes(this.state.timeToCloseSales) > 0;

    return (
      <div className={`sprod card ${promoType ? 'xmas' : ''}`}>
        <div className={`lapis-header ${promoType ? 'xmas' : ''}`}>
          {this.renderProgramHeading()}
          <Icon
            name={promoType ? 'xmas-btc' : getCoinIconName(coinSymbol)}
            containerClassName={`coin-icon ${promoType ? 'xmas' : ''}`}
          />
        </div>
        <Row className="lapis-body">
          {!isEmpty
            ? this.renderExpectedAPY()
            : <div className="no-batches-available flex-center"><span><Trans>Currently no batches available. Stay tuned!</Trans></span></div>}
          {!isEmpty ? this.renderStartingAmount() : null}
        </Row>
        {!isEmpty ? (
          <div className="lapis-footer">
            <SprodEntry
              previouslyEnteredAmount={enteredAmount}
              name={name}
              coinSymbol={coinSymbol}
              coin={coin}
              coinBalance={extractedBalance}
              saleClosesOn={saleClosesOn}
              isSaleStillOpen={isSaleStillOpen}
              maturesOn={maturesOn}
              entryFee={entryFee}
              batchNumber={batchNumber}
              batchStatus='SELLING'
              batchId={batchId}
              slug={slug}
              refreshEnteredAmount={refreshEnteredAmount}
              refreshAutoCompoundSettings={refreshAutoCompoundSettings}
              loadBalances={loadBalances}
              returnPerAnnum={returnPerAnnum}
              compoundOnSettle={compoundOnSettle}
              batchCap={batchCap}
              startingAmount={startingAmount}
              estimatedAutocompoundAmount={estimatedAutocompoundAmount}
              durationInDays={durationInDays}
              status={status}
              bonusReturn={bonusReturn}
              bonusTarget={bonusTarget}
              dfiRewardPerAnnum={dfiRewardPerAnnum}
              dfiCoin={dfiCoin}
              refreshBatches={refreshBatches}
              tier2Threshold={tier2Threshold}
              tier2Apy={tier2Apy}
              feeInPercent={feeInPercent}
            />
            <Button btnLink medium onClick={() => this.setState({ isModalVisible: true })}><Trans>Details</Trans></Button>
            <Modal isVisible={isModalVisible} showCloseButton={false} onClose={() => this.setState({ isModalVisible: false })}>
              {this.renderDetails()}
            </Modal>
          </div>
        ) : <div style={{ width: '100%', height: 41, marginTop: 16 }}></div>}
        {this.renderEnteredAmount()}
      </div>
    );
  }
}

export default connect(mapState, null)(Sprod);
