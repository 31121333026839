import Variables from '../../../Variables.styles';

export default {
  itemContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
    gap: Variables.large,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${Variables.large} 0 20px`,
    cursor: 'pointer',
  },
  itemNoIcon: {
    composes: '$item',
    padding: `${Variables.small} 0 20px`,
  },
  logoContainer: {},
  logo: {
    maxHeight: Variables.large,
  },
  name: {
    paddingTop: Variables.medium,
  },
};
