/**
 * @param language 'cn'|'en' as default
 */
export default function getFaqLinks(language?: string): string {
  switch (language) {
    case 'cn':
      return 'https://support.bake.io/zh-TW';
    default:
      return 'https://support.bake.io/en';
  }
}
