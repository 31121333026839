/* eslint-disable no-console */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CakeError } from './CakeError';

export type RequestKeyValuePair = Record<string, string>;
export interface RequestConfig {
  baseUrl?: string;
  url: string;
  method: 'get' | 'post' | 'put' | 'delete';
  headers?: RequestKeyValuePair;
  data?: RequestKeyValuePair;
  params?: RequestKeyValuePair;
}
export type CakeFinalRequestConfig = AxiosRequestConfig;
export type RequestEventCallback = (config: RequestConfig) => Promise<void>;
export default abstract class Request {
  public baseUrl: string;

  public baseHeaders: RequestKeyValuePair;

  public beforeRequest?: (config: RequestConfig) => Promise<void>;

  public afterRequest?: (
    config: RequestConfig,
    response: AxiosResponse,
  ) => Promise<void>;

  public onError?: (
    error: CakeError,
    finalRequestConfig: CakeFinalRequestConfig,
  ) => Promise<void>;

  public debug = false;

  constructor(baseUrl: string, headers = {}) {
    this.baseUrl = baseUrl;
    this.baseHeaders = headers;
  }

  abstract getExtraHeaders(): Promise<Record<string, any>>;

  protected async request<T>(config: RequestConfig) {
    if (this.beforeRequest) {
      await this.beforeRequest(config);
    }
    const {
      url, method, data, params, headers, ...restConfig
    } = config;
    const extraHeaders = await this.getExtraHeaders();
    const requestSettings: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      url,
      method: method || 'get',
      headers: {
        ...this.baseHeaders,
        ...headers,
      },
      withCredentials: true,
      data,
      params,
      ...restConfig,
    };
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const header in extraHeaders) {
      requestSettings.headers[header] = extraHeaders[header];
    }

    if (this.debug) {
      console.log(`--------------------- ${new Date().toLocaleString()}`);
      console.log(JSON.stringify(requestSettings, null, 4));
      console.log('\n\n');
    }
    return new Promise<T>((resolve, reject) => {
      axios(requestSettings)
        .then(async (response) => {
          if (this.afterRequest) {
            await this.afterRequest(config, response);
          }
          if (this.debug) {
            console.log('Status: success\n\n');
            console.log(JSON.stringify(response?.data, null, 4));
            console.log('=============================\n\n');
          }
          if (response) {
            resolve(response?.data as T);
          }
        })
        .catch(async (err) => {
          const cakeError = new CakeError(err);
          if (this.debug) {
            console.log('Status: failed\n\n');
            console.log(cakeError.code, cakeError.message);
            console.log('=============================\n\n');
          }
          if (this.onError) {
            await this.onError(cakeError, requestSettings);
          }
          reject(cakeError);
        });
    });
  }

  get<T>(url: string, params = {}, options = {}): Promise<T> {
    return this.request<T>({
      url,
      method: 'get',
      params,
      ...options,
    });
  }

  post<T>(url: string, data = {}, options = {}): Promise<T> {
    return this.request<T>({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  put<T>(url: string, data = {}, options = {}): Promise<T> {
    return this.request<T>({
      url,
      method: 'put',
      data,
      ...options,
    });
  }

  delete<T>(url: string, options = {}): Promise<T> {
    return this.request<T>({
      url,
      method: 'delete',
      ...options,
    });
  }
}
