import React from 'react';
import { createUseStyles } from 'react-jss';
import TopBannerImage from 'assets/svg/welcome/banner.svg';
import TopBannerBackImage from 'assets/svg/welcome/banner-back.svg';
import { useCakeSelector } from '../../../../app/rootReducer';
import { promotionCampaignDataSelector } from '../../../../referral/referralPromoSelector';

const useStyles = createUseStyles({
  topBanner: {
    borderRadius: '16px',
    width: '426px',
    background: `url(${TopBannerBackImage})`,
    backgroundSize: 'cover',
  },
});

const TopBanner = () => {
  const classes = useStyles();

  const { showWelcomeBonusVisuals } = useCakeSelector(promotionCampaignDataSelector);
  return !!showWelcomeBonusVisuals && (
    <div>
      <img className={classes.topBanner} src={TopBannerImage} alt="Top Banner" />
    </div>
  );
};

export default TopBanner;
