export default {
  PERSONAL_INFO_INPUT: 'PERSONAL_INFO_INPUT',
  MOBILE_NUMBER_INPUT: 'MOBILE_NUMBER_INPUT',
  MOBILE_SENDING_SMS: 'MOBILE_SENDING_SMS',
  MOBILE_CALLING_VERIFICATION: 'MOBILE_CALLING_VERIFICATION',
  MOBILE_NUMBER_VERIFY: 'MOBILE_NUMBER_VERIFY',
  SUMSUB_WEB: 'SUMSUB_WEB',
  CHOOSE_ID_TYPE: 'CHOOSE_ID_TYPE',
  PASSPORT_UPLOAD_METHOD: 'PASSPORT_UPLOAD_METHOD',
  PASSPORT_REQUIREMENTS: 'PASSPORT_REQUIREMENTS',
  PASSPORT_FILE_UPLOAD: 'PASSPORT_FILE_UPLOAD',
  PASSPORT_CAMERA_UPLOAD: 'PASSPORT_CAMERA_UPLOAD',
  NATIONAL_ID_UPLOAD_METHOD: 'NATIONAL_ID_UPLOAD_METHOD',
  NATIONAL_ID_REQUIREMENTS: 'NATIONAL_ID_REQUIREMENTS',
  NATIONAL_ID_CAMERA_FRONTVIEW_UPLOAD: 'NATIONAL_ID_CAMERA_FRONTVIEW_UPLOAD',
  NATIONAL_ID_CAMERA_BACKVIEW_UPLOAD: 'NATIONAL_ID_CAMERA_BACKVIEW_UPLOAD',
  NATIONAL_ID_FILE_FRONTVIEW_UPLOAD: 'NATIONAL_ID_FILE_FRONTVIEW_UPLOAD',
  NATIONAL_ID_FILE_BACKVIEW_UPLOAD: 'NATIONAL_ID_FILE_BACKVIEW_UPLOAD',
  SELFIE_UPLOAD_METHOD: 'SELFIE_UPLOAD_METHOD',
  SELFIE_INTRODUCTION: 'SELFIE_INTRODUCTION',
  SELFIE_CAMERA_UPLOAD: 'SELFIE_CAMERA_UPLOAD',
  SELFIE_FILE_UPLOAD: 'SELFIE_FILE_UPLOAD',
  DRIVER_LICENSE_UPLOAD_METHOD: 'DRIVER_LICENSE_UPLOAD_METHOD',
  DRIVER_LICENSE_REQUIREMENTS: 'DRIVER_LICENSE_REQUIREMENTS',
  DRIVER_LICENSE_FILE_FRONTVIEW_UPLOAD: 'DRIVER_LICENSE_FILE_FRONTVIEW_UPLOAD',
  DRIVER_LICENSE_FILE_BACKVIEW_UPLOAD: 'DRIVER_LICENSE_FILE_BACKVIEW_UPLOAD',
  DRIVER_LICENSE_CAMERA_FRONTVIEW_UPLOAD: 'DRIVER_LICENSE_CAMERA_FRONTVIEW_UPLOAD',
  DRIVER_LICENSE_CAMERA_BACKVIEW_UPLOAD: 'DRIVER_LICENSE_CAMERA_BACKVIEW_UPLOAD',
  PROOF_OF_ADDRESS_DOCUMENT_TYPE: 'PROOF_OF_ADDRESS_DOCUMENT_TYPE',
  PHONE_BILL_UPLOAD_METHOD: 'PHONE_BILL_UPLOAD_METHOD',
  PHONE_BILL_REQUIREMENTS: 'PHONE_BILL_REQUIREMENTS',
  PHONE_BILL_FILE_UPLOAD: 'PHONE_BILL_FILE_UPLOAD',
  PHONE_BILL_CAMERA_UPLOAD: 'PHONE_BILL_CAMERA_UPLOAD',
  BANK_STATEMENT_UPLOAD_METHOD: 'BANK_STATEMENT_UPLOAD_METHOD',
  BANK_STATEMENT_REQUIREMENTS: 'BANK_STATEMENT_REQUIREMENTS',
  BANK_STATEMENT_FILE_UPLOAD: 'BANK_STATEMENT_FILE_UPLOAD',
  BANK_STATEMENT_CAMERA_UPLOAD: 'BANK_STATEMENT_CAMERA_UPLOAD',
  UTILITY_BILL_UPLOAD_METHOD: 'UTILITY_BILL_UPLOAD_METHOD',
  UTILITY_BILL_REQUIREMENTS: 'UTILITY_BILL_REQUIREMENTS',
  UTILITY_BILL_FILE_UPLOAD: 'UTILITY_BILL_FILE_UPLOAD',
  UTILITY_BILL_CAMERA_UPLOAD: 'UTILITY_BILL_CAMERA_UPLOAD',
  GOVERNMENT_DOC_UPLOAD_METHOD: 'GOVERNMENT_DOC_UPLOAD_METHOD',
  GOVERNMENT_DOC_REQUIREMENTS: 'GOVERNMENT_DOC_REQUIREMENTS',
  GOVERNMENT_DOC_FILE_UPLOAD: 'GOVERNMENT_DOC_FILE_UPLOAD',
  GOVERNMENT_DOC_CAMERA_UPLOAD: 'GOVERNMENT_DOC_CAMERA_UPLOAD',
  CONFIRMATION: 'CONFIRMATION',
  MYINFO_CONFIRMATION: 'MYINFO_CONFIRMATION',
};
