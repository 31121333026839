import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setAllLanguages } from '../user/LanguageRegionSettings/languageRegionSettingsUtils';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['languageRegionSettings', 'userAppPreference', 'voucher'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store, null, () => {
  const { selectedLanguage } = store.getState().languageRegionSettings;
  setAllLanguages(selectedLanguage);
});

export type AppDispatch = typeof store.dispatch;
export const useCakeDispatch = () => useDispatch();
