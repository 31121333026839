import {
  ReferralId, ReferralLeaderboard, ReferralStats, ReferralTransactions, ReferralUsers,
} from '../../schema/referral';
import { CakeApiBase } from '../CakeApiBase';

export class ReferralPageApi extends CakeApiBase {
  async referralStats() {
    return this.requestManager.get<ReferralStats>('/referral/stats');
  }

  async referralUsers(offset: number, limit: number) {
    return this.requestManager.get<ReferralUsers>('/referral/users', { offset, limit });
  }

  async referralTransactions(limit: number, offset: number) {
    return this.requestManager.get<ReferralTransactions>('/referral/transactions', { limit, offset });
  }

  async referralId() {
    return this.requestManager.get<ReferralId>('/referral/id');
  }

  async referralLeaderBoard(offset: number, limit: number) {
    return this.requestManager.get<ReferralLeaderboard>('/referral/leaderboard', { offset, limit });
  }

  async referralLeaderBoardAuthenticated(offset: number, limit: number) {
    return this.requestManager.get<ReferralLeaderboard>('/referral/leaderboard/authenticated', { offset, limit });
  }
}
