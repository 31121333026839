import axios from 'axios';
import {
  getVariableType,
} from '../utils';
import { userTokenStorage, userTokenInfoStorage } from '../storage';
import appConfig from '../config/app';

const requestInstance = axios.create({ timeout: appConfig.DEFAULT_HTTP_TIMEOUT });

const jwt = require('jsonwebtoken');

const apiEndpoint = appConfig.API_ENDPOINT;
const overloadStatus = appConfig.OVERLOAD_STATUS;
const {
  REFRESH_TOKEN_MAX_AGE,
} = appConfig;

const configuration = {
  baseURL: apiEndpoint,
  headers: {},
};

function overLoadErrorHandler() {
  setTimeout(() => {
    window.cakepool.hideAlert();
    window.cakepool.showAlert('error', 'Too many requests, please slow down');
  }, 500);
}

const errorHandlers = {
  overLoadErrorHandler,
};

async function refreshToken(options) {
  return new Promise((resolve, reject) => {
    const refreshTokenUrl = 'generate-access-token';
    const requestSettings = {
      url: refreshTokenUrl,
      method: 'post',
      baseURL: configuration.baseURL,
      headers: {
        ...configuration.headers,
        ...options.headers,
        ...getSessionHeaders(),
      },
    };

    requestInstance(requestSettings)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        if (!error.response) {
          return reject(error);
        }

        if (error.response.status === 401 || error.response.status === 403) {
          window.cakepool.logout();
        }

        return reject(error);
      });
  });
}

async function request(options) {
  const userToken = userTokenStorage.get();
  const decodedToken = jwt.decode(userToken);

  // generating a new token 2 mins before current token expires
  if (decodedToken && Math.floor(Date.now() / 1000) + 120 > decodedToken.exp) {
    options.headers = {
      refreshToken: userTokenInfoStorage.get('refreshToken'),
    };
    const tokenInfo = await refreshToken(options);

    userTokenStorage.set(tokenInfo.token, { maxAge: REFRESH_TOKEN_MAX_AGE });
  }

  return new Promise((resolve, reject) => {
    const requestSettings = {
      url: options.url,
      method: options.method || 'get',
      baseURL: configuration.baseURL,
      headers: {
        ...configuration.headers,
        ...options.headers,
        ...getSessionHeaders(),
      },
      data: options.data,
      params: options.params,
      isBackgroundRequest: options.isBackgroundRequest,
    };

    if (options.debugRequest) {
      console.log(requestSettings);
    }
    requestInstance(requestSettings)
      .then((response) => {
        if (response) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        const responseData = (error.response || {}).data;
        const errorData = extractErrorData(responseData);

        // if error is undefined
        if (!errorData) {
          return reject(error);
        }

        if (error.response.status === 401 || error.response.status === 403) {
          window.cakepool.logout();
          return reject(error);
        }

        if (error.response.status === overloadStatus) {
          errorHandlers.overLoadErrorHandler();
        }

        errorData._response = error.response;
        console.error(errorData);
        const {
          code,
        } = errorData;
        const errorHandler = errorHandlers[code];
        if (errorHandler && getVariableType(errorHandler) === 'function') {
          errorHandler(errorData);
        }
        reject(errorData);
      });
  });
}

function getSessionHeaders() {
  const headers = {};
  const userToken = userTokenStorage.get();

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }
  return headers;
}

function extractErrorData(responseData) {
  const variableType = getVariableType(responseData);
  const errorData = variableType === 'string' ? {
    code: 'unknown',
    message: responseData,
  } : responseData;
  return errorData;
}

async function get(url, params = {}, options = {}) {
  return request({
    url,
    method: 'get',
    params,
    ...options,
  });
}

async function post(url, data, options) {
  return request({
    url,
    method: 'post',
    data,
    ...options,
  });
}

async function put(url, data, options) {
  return request({
    url,
    method: 'put',
    data,
    ...options,
  });
}

async function del(url, options) {
  return request({
    url,
    method: 'delete',
    ...options,
  });
}

export default {
  get,
  post,
  put,
  delete: del,

  request,
  configuration,
  errorHandlers,
  getSessionHeaders,
  requestInstance,
};
