import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Button from '../../common/components/Button/Button';
import i18n from '../../i18n';
import { getZendeskBaseLink } from '../../utils/getZendeskLinks';
import FaqStyles from './Faq.styles';

const useStyles = createUseStyles(FaqStyles);

const FaqListing = ({ faqContent }) => {
  const classes = useStyles();
  const [openedIndex, setOpenedIndex] = useState();

  return faqContent.map((content, index) => (
    <article
      className={`${classes.referralFaqItem} ${index === openedIndex ? 'open' : ''}`}
      key={`referralFaq-${index}`}
    >
      <div className="question" onClick={() => setOpenedIndex(index === openedIndex ? null : index)}>
        <div className="icon"><Trans>Q</Trans></div>
        <div className="body">
          {content.question}
        </div>
        <div
          className="icon-small"
        >
        </div>
      </div>
      <div className="answer">
        <div className="icon"><Trans>A</Trans></div>
        <div className="body">
          {content.answer}
        </div>
      </div>
    </article>
  ));
};

const Faq = ({ faqContent = [], isShowHelpDesk = false, customTitle = '' }) => {
  const classes = useStyles();

  if (!faqContent) {
    return null;
  }

  return (
    <section className={classes.container}>
      <h2><Trans>{customTitle || 'Frequently asked questions'}</Trans></h2>
      <section>
        <div className={classes.cardContainer}>
          <FaqListing faqContent={faqContent} />
          {
            isShowHelpDesk && <div className={classes.faqHelpDesk}>
              <h3><Trans>Have more questions? Our affiliate help desk will assist you.</Trans></h3>
              <a href={getZendeskBaseLink(i18n.language)} target="_blank" rel="noopener noreferrer">
                <Button primary className={classes.faqConsultButton}>
                  <Trans>Consult affiliate help desk</Trans>
                </Button>
              </a>
            </div>
          }
        </div>
      </section>
    </section>
  );
};

export default Faq;
