import { Styles } from 'react-jss';
import Variables from 'Variables.styles';
import ModalStyles from 'common/styles/Modal.styles';

const styles: Styles<string, { withinValidity: boolean }> = {
  ...ModalStyles,
  validityText: {
    fontSize: '1.6rem',
    textAlign: 'right',
    color: (props) => (props && props.withinValidity ? Variables.gray600 : Variables.red),
  },
  actionContainer: {
    marginTop: Variables.large,
  },
  confirmText: {
    marginTop: Variables.medium,
  },
};

export default styles;
