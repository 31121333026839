import BigNumber from 'bignumber.js';

import { BigNumberConstructable } from '../lib/customType';
import calculateFee from './calculateFee';

export default function calculateNetAmount(amount: BigNumberConstructable, tradeFeesMultiplier: BigNumberConstructable, decimalPlace?: number) {
  const fee = calculateFee(amount, tradeFeesMultiplier);
  const net = new BigNumber(1).minus(fee);
  return net.toFixed(decimalPlace);
}
