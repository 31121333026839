import Variables, { getMaxWidthBreakPoint } from '../../Variables.styles';

const listItemStyles = {
  display: 'flex',
  padding: '12px 0',
  flexWrap: 'wrap',
};

const content = {
  width: '60%',
  margin: '0 auto',
};

export default {
  header: {
    display: 'flex',
    borderBottom: Variables.primaryBorder,
    alignItems: 'center',
    flexDirection: 'row',
    padding: `${Variables.small} ${Variables.large}`,
    justifyContent: 'space-between',

    '& h2': {
      fontSize: '20px',
      margin: '0',
    },

    '& a, button': {
      marginLeft: 'auto',
    },
  },

  contentFluid: {
    width: '100%',
    margin: '0 auto',
    padding: Variables.large,
  },
  contentContainer: content,
  contentMini: {
    ...content,
  },
  selectTermBlock: {
    marginBottom: '24px',
  },
  selectTermBlockTitle: {
    marginBottom: '8px',
    fontWeight: 'bold',
  },
  selectTermBlockDescription: {},
  content: {
    ...content,
    paddingTop: Variables.extraLarge,
  },
  section: {
    padding: `${Variables.small} 0`,
  },

  centerContent: {
    textAlign: 'center',
    margin: '0 auto',
  },

  rightContent: {
    textAlign: 'right',
  },

  logo: {
    maxWidth: '300px',
  },
  listItemContainer: {
    ...listItemStyles,
    borderBottom: Variables.primaryBorder,
  },
  listItemContainerNoBorder: listItemStyles,
  listItemLabel: {
    margin: '0',
  },
  listItemValue: {
    color: Variables.textSecondary,
    marginLeft: 'auto',
  },

  spacer: {
    padding: `${Variables.medium} 0`,
  },

  footer: {
    borderTop: Variables.primaryBorder,
    marginTop: Variables.larger,
    padding: `${Variables.small} ${Variables.large} ${Variables.small} 0`,
    textAlign: 'right',

    '& button': {
      margin: '0 0 0 auto',
    },
  },

  // TODO: Remove once we have standard popover/tooltip
  tooltip: {
    padding: `0 ${Variables.medium}`,
  },

  exclamationIcon: {
    composes: ['fa', 'fa-exclamation-circle'],
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    content: {
      width: '90%',
      paddingTop: Variables.large,
    },
    contentMini: {
      width: '90%',
    },
  },
  infoDiv: {
    padding: Variables.medium,
    backgroundColor: Variables.cultured,
    borderRadius: Variables.small,
    color: Variables.black,
    fontSize: Variables.fontSize.base,
    marginTop: Variables.medium,
    marginBottom: Variables.larger,
    display: 'flex',
    '& h3': {
      fontStyle: 'normal',
      fontWeight: Variables.fontWeight.bold,
      fontSize: Variables.medium,
      lineHeight: Variables.large,
      marginBottom: 0,
    },
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Variables.medium,
  },
  infoText: {
    margin: 0,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    wordBreak: 'break-all',
  },
};
