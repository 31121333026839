/* eslint-disable camelcase */
import { KycLiteData, KycPassportOnlyCountry, KycRejectionReasonData, UserAccessRights, UserEnableOtp, UserInfo, VerifyInfo, VerifyInfoEncrypted, UserPreference, UserProfile, VerifyOtpForm } from '../../schema/user';
import { CakeApiBase } from '../CakeApiBase';

export interface KycPersonalData {
  firstName: string;
  lastName: string;
  middleName?: string;
  aliasName?: string;
  fullChineseName?: string;
  dateOfBirth: string;
  nationality: string;
  addressLine1: string;
  addressLine2: string;
  countryState?: string;
  country: string;
  zipCode: string;
}

export interface KycPhotoData {
  idFrontImg: string;
  idBackImg: string;
  proofOfAddressImg: string;
  legalDocumentType: string;
  expiryDate: string;
  idStatus: 'UNCHECKED' | 'APPROVED' | 'REJECTED';
  poaStatus: 'UNCHECKED' | 'APPROVED' | 'REJECTED';
  selfieImg: string;
  selfieStatus: 'UNCHECKED' | 'APPROVED' | 'REJECTED';
  idDocumentApproved?: boolean;
  poaDocumentApproved?: boolean;
}

export interface UpdateUserProfileParams {
  firstName?: string;
  lastName?: string;
  language?: string;
  isOnboarded?: boolean;
  country?: string;
}

export enum OTPType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  TOTP = 'TOTP',
}
export interface LoginResponse {
  token: string;
  otpRequired: boolean;
  alternativeOtpMethod: OTPType | null,
}

type UserConsentStatus =
| 'REFUSED_AFTER_LOGIN'
| 'CHANGED_MIND_AFTER_LOGIN'
| 'WITHDRAWN_WITHDRAWAL_ONLY_IN_SETTINGS'
| 'WITHDRAWN_FULLY_RESTRICTED_IN_SETTINGS'
| null;

interface DataConsentResponse {
  isDisplayConsentScreen: boolean;
  hasRefusedDataConsent: boolean;
  isAcceptedDataConsent: boolean;
  userConsentStatus?: UserConsentStatus;
}

type DataConsentWithdrawParams = {
  isWithdrawalOnly: boolean;
  otp?: string;
  otpMethod?: 'SMS' | 'EMAIL' | 'TOTP'; // for 2FA disable scenario, keeping otpMethod as optional
};

type MarketingConsentParams = {
  isConsented: boolean;
}

export class UserInfoApi extends CakeApiBase {
  async login(
    email: string,
    password: string,
    isRememberMe: boolean,
    reCaptchaToken: string,
  ) {
    return this.requestManager.post<LoginResponse>('login', {
      email,
      password,
      isRememberMe,
      'g-recaptcha-response': reCaptchaToken,
    });
  }

  async get() {
    return this.requestManager.get<UserProfile>('me');
  }

  async logout(body = {}) {
    return this.requestManager.post<any>('logout', body);
  }

  async register(
    email: string,
    password: string,
    reCaptchaToken: string,
    isGivenDataConsent: boolean,
    isGivenMarketingConsent: boolean,
    promoCode: string | null | undefined,
    affiliateRefCode: string | null | undefined,
    referredById: string | null | undefined,
    currentLanguage: string,
    utm_source: any,
    utm_medium: any,
    utm_campaign: any,
    revealInfoToReferrer: boolean,
    learnAndEarnCode: string,
    voucherCode: string,
    firstName?: string | null | undefined,
    lastName?: string | null | undefined,
    country?: string | null | undefined,
    age?: string | null | undefined, // this is for honeypot trap
  ) {
    const body: any = {};
    body.age = age; // this is for honeypot trap
    body.email = email;
    body.country = country;
    body.password = password;
    body.firstName = firstName;
    body.lastName = lastName;
    body['g-recaptcha-response'] = reCaptchaToken;
    body.isGivenDataConsent = isGivenDataConsent;
    body.isGivenMarketingConsent = isGivenMarketingConsent;
    body.promoCode = promoCode;
    body.affiliateRefCode = affiliateRefCode;
    body.referredById = referredById;
    body.currentLanguage = currentLanguage;
    body.utm_source = utm_source;
    body.utm_medium = utm_medium;
    body.utm_campaign = utm_campaign;
    body.revealInfoToReferrer = revealInfoToReferrer;
    body.learnAndEarnCode = learnAndEarnCode;
    body.voucherCode = voucherCode;
    return this.requestManager.post<UserInfo>('register', body);
  }

  async forgetPassword(email: string, reCaptchaToken: string) {
    return this.requestManager.post<{ email: string }>('reset-password', {
      email,
      'g-recaptcha-response': reCaptchaToken,
    });
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
    otp: string,
  ) {
    return this.requestManager.post<{ token: string }>('change-password', {
      oldPassword,
      newPassword,
      otp,
    });
  }

  // why need reset pw aside from (forget and change)?
  async resetPassword(token: string, password: string) {
    return this.requestManager.post<UserInfo>('reset-password/token', {
      password,
      token,
    });
  }

  async resendVerify(email: string, reCaptchaToken: string) {
    return this.requestManager.post<VerifyInfo>('verify', {
      email,
      'g-recaptcha-response': reCaptchaToken,
    });
  }

  async resendVerifyEncrypted(encryptedEmail: string, reCaptchaToken: string) {
    return this.requestManager.post<VerifyInfoEncrypted>('resend-verify-encrypted', {
      encryptedEmail,
      'g-recaptcha-response': reCaptchaToken,
    });
  }

  async verify(userEmail: string, token: string) {
    return this.requestManager.put<UserInfo>('verify/email/token', {
      userEmail,
      token,
    });
  }

  async verifyEncrypted(userEncryptedEmail: string, token: string) {
    return this.requestManager.put<UserInfo>('verify/email/token/en', {
      userEmail: userEncryptedEmail,
      token,
    });
  }

  // @WARNING something isnt right, webapp has no usage on this and directly call twillio
  // how do BE verify SMS sent and code get verified correctly?
  // async sendVerificationSms(verificationData) {
  //   return this.sdk.post('sms/send', {
  //     verificationData,
  //   });
  // }

  // async verifyToken(tokenData) {
  //   return this.sdk.post('sms/verify-token', {
  //     tokenData,
  //   });
  // }

  // async verifyKycLite(personalData) {
  //   return this.sdk.post('kyc/lite/verify', {
  //     personalData,
  //   });
  // }

  async verifyKyc(personalData: KycPersonalData, photoData: KycPhotoData) {
    return this.requestManager.post('kyc/verify', {
      personalData,
      photoData,
    });
  }

  async verifyKycFull(personalData: KycPersonalData, photoData: KycPhotoData) {
    return this.requestManager.post('kyc/full/verify', {
      personalData,
      photoData,
    });
  }

  async getVerifiedKycLiteData() {
    return this.requestManager.get<KycLiteData>('kyc/lite');
  }

  async getKycRejectionReason() {
    return this.requestManager.get<KycRejectionReasonData>('kyc/full/rejection');
  }

  async updateProfile(profileData: UpdateUserProfileParams) {
    return this.requestManager.post<UserInfo>('me/profile', profileData);
  }

  async updatePreference(userPreference: Partial<UserPreference>) {
    return this.requestManager.put<UserPreference>('me/preference', userPreference);
  }

  async listActivities(query = {}) {
    return this.requestManager.get('me/activities', query);
  }

  async enableOtp() {
    return this.requestManager.post<UserEnableOtp>('otp/enable');
  }

  async disableOtp(otp: string, otpMethod: OTPType) {
    return this.requestManager.post<any>('otp/disable', {
      otp,
      otpMethod,
    });
  }

  async verifyOtp(otp: string) {
    return this.requestManager.post<UserInfo>('otp/verify', {
      otp,
    });
  }

  async otpLogin(
    tempOtpAuthToken: string,
    otp: string,
    otpMethod: OTPType,
    isRememberMe: boolean,
  ) {
    return this.requestManager.post<LoginResponse>('otp-login', {
      tempOtpAuthToken,
      otp,
      otpMethod,
      isRememberMe,
    });
  }

  async getKycPassportOnlyCountry() {
    return this.requestManager.get<KycPassportOnlyCountry>('kyc-passport-only');
  }

  async getAccessRights() {
    return this.requestManager.get<UserAccessRights>('me/access-rights');
  }

  async updateLastActive() {
    return this.requestManager.post('active');
  }

  async setReferralRulesAccepted(referralRulesAccepted: boolean) {
    return this.requestManager.post('accept-referral-rules', {
      referralRulesAccepted,
    });
  }

  async suspend(token: string) {
    return this.requestManager.post<{ status: string }>('suspend', {
      token,
    });
  }

  async setUserOnboardingRead(id: string) {
    return this.requestManager.put('me/on-boarding/read', {
      id,
    });
  }

  async sendUserOTPEmail(): Promise<void> {
    return this.requestManager.post('me/otp/email');
  }

  async sendUserOTPSms(): Promise<void> {
    return this.requestManager.post('me/otp/sms');
  }

  async verifyUserOTP(body: VerifyOtpForm): Promise<void> {
    return this.requestManager.post('me/otp/verify', body);
  }

  async alternativeOtpRequest(
    tempOtpAuthToken: string,
  ) {
    return this.requestManager.post<LoginResponse>('request-otp', {
      tempOtpAuthToken,
    });
  }

  async postDataConsentAccept(params: { location: 'PROFILE_PAGE' | 'AFTER_LOGIN' }) {
    return this.requestManager.post<DataConsentResponse>('user-consent/data', params);
  }

  async postDataConsentRefuse() {
    return this.requestManager.post<DataConsentResponse>('user-consent/refuse-data-consent');
  }

  async postDataConsentChangeMind() {
    return this.requestManager.post<DataConsentResponse>('user-consent/change-mind-data-consent');
  }

  async postDataConsentWithdraw(params: DataConsentWithdrawParams) {
    return this.requestManager.post<DataConsentResponse>('user-consent/withdraw', params);
  }

  async postMarketingConsent(params: MarketingConsentParams) {
    return this.requestManager.post<DataConsentResponse>('user-consent/marketing', params);
  }
}
