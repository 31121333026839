import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { CheckBox } from '../../../views/components';
import { CONFIRMATION, INITIAL } from './FiatBuyModal';
import fiatToBTCToDFIImage from '../../../assets/svg/fiat-btc-dfi.svg';
import Button from '../../../common/components/Button/Button';
import styles from './FiatSwappableConfirmationPage.styles';

const useStyles = createUseStyles(styles);

export default function FiatSwappableConfirmationPage({ goTo }) {
  const [userHasRead, setUserHasRead] = useState(false);
  const classes = useStyles(styles);
  return (
    <>
      <header className={classes.header}>
        <h2>
          <Trans>Checkout</Trans>
        </h2>
        <Button onClick={() => goTo(INITIAL)} btnLink>
          <Trans>Cancel</Trans>
        </Button>
      </header>
      <div className={classes.content}>
        <div className={classes.section}>
          <div className={classes.centerContent}>
            <img src={fiatToBTCToDFIImage} alt="Fiat to BTC to DFI" />
          </div>
        </div>
        <div className={classes.section}>
          <p>
            <Trans>
              In the next steps, you will be purchasing BTC as an intermediate step. Your purchased BTC will be automatically converted into DFI once they arrive in your account.
            </Trans>
          </p>
          <p className={classes.successText}>
            This WILL NOT affect the amount of DFI you get from this purchase.
          </p>
        </div>
        <div className={classes.section}>
          <CheckBox
            isChecked={userHasRead}
            onChange={(result) => setUserHasRead(result)}
          >
            <Trans>
              I understand that I will be buying BTC, which will then be
              automatically converted into DFI.
            </Trans>
          </CheckBox>
        </div>
      </div>
      <footer className={classes.footer}>
        <Button
          primary
          disabled={!userHasRead}
          onClick={() => goTo(CONFIRMATION)}
        >
          <Trans>Continue</Trans>
        </Button>
      </footer>
    </>
  );
}
