import React, { useEffect, useState } from 'react';
import { userDetailSelector } from 'user/userSelector';
import { useCakeSelector } from 'app/rootReducer';
import { UserInfo } from '@cakedefi/cake-sdk';
import appConfig from 'config/app';

type SnapchatEvents = 'PAGE_VIEW' | 'SIGN_UP' | 'ADD_CART' | 'PURCHASE';

export function trackSnapchat(event: SnapchatEvents, params?: Record<string, string | number>) {
  if (!window.snaptr) {
    return;
  }
  if (params) {
    window.snaptr('track', event, params);
    return;
  }
  window.snaptr('track', event);
}

export default function Snapchat() {
  const userDetail: UserInfo = useCakeSelector(userDetailSelector);
  const [hasInitPageView, setHasInitPageView] = useState(false);
  const userEmail = userDetail?.email;

  useEffect(() => {
    if (!window.snaptr) {
      return;
    }
    if (userEmail) {
      window.snaptr('init', appConfig.SNAPCHAT_CLIENT_ID, {
        user_email: userEmail,
      });
    } else {
      window.snaptr('init', appConfig.SNAPCHAT_CLIENT_ID);
    }

    if (!hasInitPageView) {
      trackSnapchat('PAGE_VIEW');
      setHasInitPageView(true);
    }
  }, [hasInitPageView, userEmail]);

  return <></>;
}
