import Variables from '../../../../Variables.styles';

export default {
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12rem',
    marginBottom: '12rem',
    '& > a': {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    margin: `0 0 ${Variables.medium} 0`,
  },
  invalidMessage: {
    color: Variables.redInvalidControl,
    fontSize: '1.4rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    marginTop: Variables.small,
    marginBottom: Variables.small,
    minHeight: '10px',
  },
};
