import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import sdk from '../../../../sdk';
import { useCakeSelector } from '../../../../app/rootReducer';
import fetchWithSpinner from '../../../../utils/fetchWithSpinner';
import Button from '../../../../common/components/Button/Button';
import { setCodeSendId, setCodeCallId } from '../identifiableInformationSlice';
import MobilePhoneStyles from './MobilePhone.styles';

interface MobilePhoneResendButtonProps {
  setIsRequesting?: any;
  gotoVerifyOTPScreen?: any;
  gotoPhoneCallScreen?: any;
  gotoInputScreen?: any;
  timeLeft?: any;
  startCountdown?: any;
  showCountdownMessage: boolean;
  forceDisabledResendCode?: boolean;
  forceDisabledReceivedCall?: boolean;
  disableChangeMobileNumber?: boolean;
  invalidMessage?: string;
  phoneCountryCode: string;
  mobileNumber: string;
}

const useStyles = createUseStyles(MobilePhoneStyles);

const displayCountdown = (ms) => {
  const d = Number(ms) / 1000;
  const m = `${Math.floor((d % 3600) / 60)}`; // Minutes
  const s = `${Math.floor((d % 3600) % 60)}`; // Seconds
  return `${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
};

const MobilePhoneResendBottom = ({
  setIsRequesting,
  gotoVerifyOTPScreen,
  gotoPhoneCallScreen,
  gotoInputScreen,
  timeLeft,
  startCountdown,
  forceDisabledResendCode,
  forceDisabledReceivedCall,
  disableChangeMobileNumber,
  invalidMessage,
  phoneCountryCode,
  mobileNumber,
}: MobilePhoneResendButtonProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { codeSendId, codeCallId } = useCakeSelector(state => state.userIdentifiableInformation);

  const [disableResendCode, setDisableResendCode] = useState(false);
  const [disableReceivedCall, setDisableReceivedCall] = useState(false);
  const timer = displayCountdown(timeLeft);
  const isResendButtonDisabled = timeLeft > 0 || disableResendCode;

  const buttonText = {
    resendCode: 'Resend code',
    getPhoneCall: 'Get A Phone Call',
    useDifferentMobileNumber: 'Use a different mobile number',
  };

  useEffect(() => {
    if (typeof forceDisabledResendCode !== 'undefined') {
      setDisableResendCode(forceDisabledResendCode);
    }
    if (typeof forceDisabledReceivedCall !== 'undefined') {
      setDisableReceivedCall(forceDisabledReceivedCall);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResendCode = async () => {
    try {
      await fetchWithSpinner(() => sdk.TwilioApi.sendVerificationSms({
        countryCode: phoneCountryCode,
        phone: mobileNumber,
        channel: 'sms',
      }), {
        successMessage: 'Successfully sent you an SMS with your verification code',
        throwError: true,
      });
      dispatch(setCodeSendId(new Date().getTime()));
      startCountdown(60000 * 3); // increase to 3 minutes
      trackOnClick(buttonText.resendCode);
    } catch (e) {
      setDisableResendCode(false);
    }
  };

  const onReceiveCall = () => {
    trackOnClick(buttonText.getPhoneCall);
    dispatch(setCodeCallId(codeSendId));
    if (gotoVerifyOTPScreen) {
      gotoVerifyOTPScreen(); // goto MOBILE_NUMBER_VERIFY_OTP, called from MobilePhoneCallingForVerification
      return;
    }
    if (gotoPhoneCallScreen) {
      gotoPhoneCallScreen(); // MOBILE_PHONE_CALL, called from MobilePhoneVerifyOTP
    }
  };

  const trackOnClick = (text: string) => {
    gtmTrack('trackEvent', {
      event: iEventNames.retry_mobile_verification,
      event_type: iEventTypes.custom,
      event_data: {
        button_text: text,
      },
    });
  };

  return <>
    <div className={classes.invalidMessage}><Trans i18nKey={invalidMessage}>{invalidMessage}</Trans></div>
    <div className={classes.spacingTopMd}>
      <div className={classes.centeredSecondaryText}>
        <Trans>Didn't get a verification code?</Trans>
      </div>
      <div>
        <div className='mt-4'>
          <Button primary clear onClick={onResendCode} disabled={isResendButtonDisabled}>
            {isResendButtonDisabled ? <Trans>Resend code in {{ timer }}</Trans> : <Trans>Resend code</Trans>}
          </Button>
        </div>
        <div className='mt-4'>
          <Button
            primary
            clear
            onClick={onReceiveCall}
            className={classes.btnReceiveCall}
            disabled={disableReceivedCall || (codeSendId !== null && codeCallId !== null && codeSendId === codeCallId) }
          >
            <Trans>Get A Phone Call</Trans>
          </Button>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.wrap}>
          <Button primary clear className={classes.linkLabel} onClick={() => {
            if (!disableChangeMobileNumber) {
              trackOnClick(buttonText.useDifferentMobileNumber);
              if (setIsRequesting) {
                setIsRequesting(false);
              } else if (gotoInputScreen) {
                gotoInputScreen(); // MOBILE_NUMBER_INPUT
              }
            }
          }}>
            <Trans>Use a different mobile number</Trans>
          </Button>
        </div>
      </div>
    </div>
  </>;
};

export default MobilePhoneResendBottom;
