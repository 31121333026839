import React from 'react';
import { Trans } from 'react-i18next';

export default function renderBuyAlertMessage() {
  return (
    <div style={{ maxWidth: '550px' }}>
      <Trans>Successfully bought shares</Trans>
    </div>
  );
}
