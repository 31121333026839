import { CakeApiBase } from '../CakeApiBase';
import { DEXPrice, DEXSwapRequest, DEXSwapWithCount, DusdStabilizationFee } from '../../schema/dex';
import { DEXSwapExpiry } from '../../schema/dex/dex-swap-expiry';
import { MinimumAmountInUsd } from '../../schema';

export class DexSwapApi extends CakeApiBase {
  async getPrice(pairId: string) {
    return this.requestManager.get<DEXPrice>(`/swap/prices/${pairId}`);
  }

  async getRouteWithPairData(from: string, to: string) {
    return this.requestManager.get<DEXPrice[]>(`/swap/route/${from}/${to}`);
  }

  async cancelSwap(id: string) {
    return this.requestManager.post(`/swap/${id}/cancel`);
  }

  async swap(data: DEXSwapRequest) {
    return this.requestManager.post('/swap', data);
  }

  async getDexSwaps(limit?, offset?, status?) {
    const query = { limit, offset, status };
    return this.requestManager.get<DEXSwapWithCount>('/swap', query);
  }

  async getSwapExpiry() {
    return this.requestManager.get<DEXSwapExpiry>('/swap/expiry');
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/swap/minimum');
  }

  async getDusdStabilizationFee() {
    return this.requestManager.get<DusdStabilizationFee>('/swap/DUSD/stabilization-fee');
  }
}
