import {
  SubscriptionPricingTable,
  SubscriptionUserInfo,
  SubscriptionCustomerPoralSession,
  CreateCheckoutSessionPayload,
} from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class SubscriptionApi extends CakeApiBase {
  async getPricingTable() {
    return this.requestManager.get<SubscriptionPricingTable>('subscription/get-pricing-table-info');
  }

  async getUserInfo() {
    return this.requestManager.get<SubscriptionUserInfo>('subscription/get-user-info');
  }

  async getCustomerPortalSession() {
    return this.requestManager.get<SubscriptionCustomerPoralSession>('subscription/get-customer-portal-session');
  }

  async createCheckoutSession(payload: CreateCheckoutSessionPayload) {
    return this.requestManager.post<SubscriptionCustomerPoralSession>('subscription/create-checkout-session', payload);
  }
}
