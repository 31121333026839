import React, { useContext } from 'react';
import Button from 'common/components/Button/Button';
import Variables from 'Variables.styles';
import { Trans } from 'react-i18next';
import sdk from 'sdk';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import { useCakeSelector } from 'app/rootReducer';
import { storage } from 'storage/sessionStorage';
import { createUseStyles } from 'react-jss';
import { ModalContext } from 'modals/ModalContext';
import { useDispatch } from 'react-redux';
import { setRegisterStep } from 'views/Pages/Authentication/authSlice';
import ReactSVG from 'react-svg';
import config from '../../config/app';
import google from '../../assets/svg/sso/icon-google.svg';
import apple from '../../assets/svg/sso/icon-apple-logo.svg';
import ConsentModal from './ConsentModal';

const useStyles = createUseStyles({
  buttonsContainer: {
    paddingTop: Variables.medium,
    textAlign: 'center',
    paddingBottom: Variables.medium,
    display: 'flex',
    flexDirection: 'column',
    gap: Variables.large,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: Variables.extraLarge,
  },
  buttonText: {
    textAlign: 'center',
    flexGrow: 1,
  },
  icon: {
    color: Variables.black,
  },
});

enum RegisterMethodType {
  EMAIL = 'EMAIL',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export default function RegisterMethods() {
  const classes = useStyles();
  const { openModal, closeModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const {
    acceptedDataConsent,
    acceptedMarketingConsent,
  } = useCakeSelector(state => state.auth);

  const clickEventPayload = {
    event: iEventNames.start_sign_up,
    event_type: iEventTypes.custom,
    eventData: {
      signup_provider: '',
    },
  };

  const onRegisterGoogle = () => {
    const trackingPayload = { ...clickEventPayload, ...{ event_data: { signup_provider: 'Google' } } };
    if (typeof window !== 'undefined') {
      const googleAuthUrl = `${config.API_ENDPOINT}auth/google`;
      window.location.href = googleAuthUrl; // nosemgrep: javascript.browser.security.open-redirect.js-open-redirect
      gtmTrack('trackEvent', trackingPayload);
    }
  };

  const onRegisterApple = async () => {
    const trackingPayload = { ...clickEventPayload, ...{ event_data: { signup_provider: 'Apple' } } };
    try {
      const { appleSsoRedirectUrl } = await sdk.AppleSsoApi.getAuthoriseUrl();
      if (appleSsoRedirectUrl) {
        window.location.href = appleSsoRedirectUrl; // nosemgrep: javascript.browser.security.open-redirect.js-open-redirect
        gtmTrack('trackEvent', trackingPayload);
      }
    } catch (err) {
      const { message } = err;
      window.cakepool.showAlert('error', <span><Trans>Error</Trans>: <Trans>{message}</Trans></span>);
    }
  };

  const onRegisterEmail = () => {
    dispatch(setRegisterStep(2));
    closeModal();
  };

  const getContinueClick = (type: RegisterMethodType) => {
    storage.setItem('acceptedDataConsent', acceptedDataConsent);
    storage.setItem('acceptedMarketingConsent', acceptedMarketingConsent);

    switch (type) {
      case RegisterMethodType.GOOGLE:
        return onRegisterGoogle;
      case RegisterMethodType.APPLE:
        return onRegisterApple;
      case RegisterMethodType.EMAIL:
        return onRegisterEmail;
      default:
        return null;
    }
  };

  const showConsentModal = (type: RegisterMethodType) => {
    const onClickContinueEvent = getContinueClick(type);

    openModal({
      component: <ConsentModal closeModal={closeModal} onClickContinueEvent={onClickContinueEvent}/>,
    });
  };

  return (
    <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          clear
          block
          outline
          tabIndex={1}
          type="button"
          onClick={() => showConsentModal(RegisterMethodType.APPLE)}
        >
          <ReactSVG src={apple} className={classes.icon} />
          <div className={classes.buttonText}>
            <Trans>Login with Apple</Trans>
          </div>
        </Button>
        <Button
          className={classes.button}
          clear
          block
          outline
          tabIndex={2}
          type="button"
          onClick={() => showConsentModal(RegisterMethodType.GOOGLE)}
        >
          <ReactSVG src={google} className={classes.icon} />
          <div className={classes.buttonText}>
            <Trans>Login with Google</Trans>
          </div>
        </Button>
        <Button
          className={classes.button}
          clear
          block
          outline
          tabIndex={4}
          type="button"
          onClick={() => showConsentModal(RegisterMethodType.EMAIL)}
        >
          <div className={classes.buttonText}>
            <Trans>Register with email</Trans>
          </div>
        </Button>
      </div>
  );
}
