import Variables from '../../../Variables.styles';

export default {
  loading: {
    borderRadius: '6px',
    width: '80px',
    height: '12px',
    display: 'inline-block',
    background: 'linear-gradient(90deg, rgba(0,0,0,.04) 0%, rgba(0,0,0,.1) 33.3%, rgba(0,0,0,.04) 100%)',
    backgroundSize: Variables.extraLarge,
    animation: '$syncPulse 1s infinite linear',
  },
  '@keyframes syncPulse': {
    '0%': { backgroundPosition: 0 },
    '50%': { backgroundPosition: '48px' },
    '100%': { backgroundPosition: '96px' },
  },
};
