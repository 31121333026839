import React from 'react';
import { Trans } from 'react-i18next';

const ERROR_MESSAGE_REGEXP = /(.*)({{responseError}})(.*)/g;

export default async (func, {
  successMessage = 'Saved',
  errorMessage = 'Update failed: {{responseError}}',
  showAlert = true,
  withSpinner = true,
  throwError = false,
} = {}) => {
  if (withSpinner) {
    window.cakepool.showSpinner();
  }

  try {
    let response = null;
    if (func) {
      response = await func();
    }
    if (showAlert) {
      window.cakepool.showAlert('success', <Trans i18nKey={successMessage}>{successMessage}</Trans>);
    }
    window.cakepool.hideSpinner();
    return response;
  } catch (err) {
    const { message } = err;
    if (showAlert) {
      const formattedErrorMessage = errorMessage.replace(ERROR_MESSAGE_REGEXP, `$1${message}$3`);
      window.cakepool.showAlert('error', <span>
        <Trans i18nKey={formattedErrorMessage}>{formattedErrorMessage}</Trans>
      </span>);
    }
    window.cakepool.hideSpinner();
    if (throwError || !showAlert) {
      throw err;
    }
  }
};
