import { AxiosResponse } from 'axios';
import { CakeApiBase } from '../../CakeApiBase';

export class RazerApi extends CakeApiBase {
  async checkUser() {
    return this.requestManager.get<AxiosResponse>('/campaigns/razer/check');
  }

  async loginUser() {
    return this.requestManager.get<{ clientId: string }>('/campaigns/razer/login');
  }

  async verifyInfo(code: string) {
    return this.requestManager.post<{ msg: string }>('/campaigns/razer/token', { code });
  }

  async checkRestrictionByCountry() {
    return this.requestManager.get<AxiosResponse>('/campaigns/razer/user-restrict-by-country');
  }
}
