import React from 'react';
import { Trans } from 'react-i18next';
import { MouseOverTooltip } from '../../../views/components';
import InfoIcon from './InfoIcon';

interface PopoverContainerProps {
  message: React.ReactNode;
}

export const PopoverContainer = ({ message }: PopoverContainerProps) => (
  <div className="coin-status-popover">
    <p className="text-secondary">
      {
        typeof message === 'string' ? <Trans>{message}</Trans> : message
      }
    </p>
  </div>
);

interface InfoIconPopoverProps {
  popoverMessage: React.ReactNode;
}

export default function InfoIconPopover({ popoverMessage }: InfoIconPopoverProps) {
  return (
    <MouseOverTooltip
      renderTooltip={() => <PopoverContainer message={popoverMessage} />}
    >
      <InfoIcon />
    </MouseOverTooltip>
  );
}
