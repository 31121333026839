import React, { Component } from 'react';
import { Trans } from 'react-i18next';

export default class Countdown extends Component {
  state = {
    days: null,
    hours: null,
    minutes: null,
  }

  componentDidUpdate({ timeToCloseSales: prevTimeToCloseSales }) {
    const { timeToCloseSales } = this.props;
    if (timeToCloseSales !== prevTimeToCloseSales) {
      this.computeDateDifference(timeToCloseSales);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getPluralizedDateEntity(dateEntity, dateEntityValue) {
    if (dateEntityValue === 1) return <Trans>{dateEntity}</Trans>;
    return <Trans>{dateEntity}s</Trans>;
  }

  computeDateDifference(diff) {
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days = Math.floor((diff / (1000 * 60 * 60 * 24)));

    this.setState({
      minutes,
      hours,
      days,
    });
  }

  render() {
    const { timeToCloseSales } = this.props;
    if (timeToCloseSales <= 0) return null;
    const { days, hours, minutes } = this.state;
    return (
      <div className="text-secondary">
        <Trans>Starts in</Trans> { days}<Trans>d</Trans> { hours}<Trans>h</Trans> { minutes}<Trans>m</Trans>
      </div>
    );
  }
}
