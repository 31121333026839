import moment from 'moment';

export const FORMAT_PDF = 'PDF';
export const FORMAT_CSV = 'CSV';
export const getSelectedItems = items => items.filter(({ checked }) => checked);
export const isSelectAll = items => items.length === getSelectedItems(items).length;
export const updateItems = (items, checked) => items.map(item => ({ ...item, checked }));

export function calculateDateRangeFromInput(range) {
  const { months, startDate, endDate } = range;
  if (startDate && endDate) {
    return {
      start: startDate,
      end: endDate,
    };
  }
  if (!months) {
    return {
      start: null,
      end: null,
    };
  }
  return getDateRangeByMonths(months);
}

export function generateRangeByYear(year) {
  const yearMoment = moment([year]);
  const yearEnd = yearMoment.clone().endOf('year');
  const today = moment().endOf('day');
  const endDate = today.isBefore(yearEnd) ? today : yearEnd.endOf('year');

  return {
    label: `${year}`,
    selected: false,
    startDate: yearMoment.toString(),
    endDate: endDate.toString(),
  };
}

export function getDateRangeByMonths(months) {
  const now = moment();
  const endOfToday = now.clone().endOf('day');
  const previousXMonth = now.clone().startOf('day').subtract(months, 'months');

  return {
    start: previousXMonth.toString(),
    end: endOfToday.toString(),
  };
}

export const getEndOfToday = () => {
  const now = moment();
  return now.clone().endOf('day').toString();
};

export function resetQuickRanges(state) {
  state.quickRanges.forEach((x) => { x.selected = false; });
}

export function isPerformTaxExport(format) {
  return format === FORMAT_PDF;
}
