import React from 'react';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  paragraphHeader: {
    fontSize: '1.8rem',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
  paragraph: {
    marginBottom: Variables.medium,
  },
  leftButton: {
    marginRight: Variables.medium,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.xs)]: {
    headerText: {
      flexBasis: '100%',
      marginBottom: Variables.small,
    },
  },
});

export default function PersonalDataConsentTerms({ hideDataCollection = false }: { hideDataCollection?: boolean }) {
  const classes = useStyles();

  const sectionDescription = hideDataCollection
    ? 'You may withdraw your consent for us to use or disclose your personal data at any time by contacting our customer support team, but we may have to close your User Account and stop offering you our Services.'
    : 'You may withdraw your consent for us to collect, use or disclose your personal data at any time by contacting our customer support team, but we may have to close your User Account and stop offering you our Services.';

  const section2Header = hideDataCollection
    ? 'Purpose of data processing'
    : 'Purpose of data collection and processing';

  return (
    <>
      <p><Trans>ID (email), name, phone number, country of residence, IP address.</Trans></p>
      <h3 className={classes.paragraphHeader}><Trans>{section2Header}</Trans></h3>
      <ul>
        <li>
          <Trans>
            Providing, improving and developing our services.
          </Trans>
        </li>
        <li>
          <Trans>
            Researching, designing and launching new features or products.
          </Trans>
        </li>
        <li>
          <Trans>
            Presenting content and information in our Sites in the most effective manner for you and for the device you use.
          </Trans>
        </li>
        <li>
          <Trans>
            Providing you with alerts, updates, materials or information about our services or other types of information that you requested or signed up to.
          </Trans>
        </li>
        <li>
          <Trans>
            Processing payment or credit transactions.
          </Trans>
        </li>
        <li>
          <Trans>
            Complying with laws and regulations applicable to us in any part of the world.
          </Trans>
        </li>
      </ul>

      <p className={classes.paragraph}>
        <Trans>{sectionDescription}</Trans>
      </p>
      <p className={classes.paragraph}>
        <Trans>
          For any enquiries or feedback on the usage of your personal data, please contact our Data Protection Officer (DPO) by emailing us at <a href='mailto:dpo@cake.group'>dpo@cake.group</a>.
        </Trans>
      </p>
    </>
  );
}
