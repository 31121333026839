import React, { Component } from 'react';
import BigNumber from 'bignumber.js';
import { Trans } from 'react-i18next';

const ROUNDS = 2;

const DfiBonusSection = ({ dfiReturn }) => (
  <div className="bonus-explanation-section">
    <div className="bonus-explanation-row small-grey">
      <div><Trans>DFI component</Trans></div>
      <div><Trans>APY (DFI)</Trans></div>
    </div>
    <div className="bonus-explanation-row">
      <div><Trans>Fixed DFI return</Trans></div>
      <div>{dfiReturn}%</div>
    </div>
  </div>
);

export default class BonusPopover extends Component {
  render() {
    const { coinSymbol, bonusTarget, bonusReturn, dfiRewardPerAnnum, returnPerAnnum } = this.props;
    const baseReturn = new BigNumber(returnPerAnnum).multipliedBy(100).toFixed();
    const bonusTargetInPercent = new BigNumber(bonusTarget).multipliedBy(100).toFixed();
    const bonusForFirstRound = new BigNumber(bonusReturn).dividedBy(ROUNDS);
    const returnIfFirstRoundBonusReached = new BigNumber(returnPerAnnum)
      .plus(bonusForFirstRound)
      .multipliedBy(100)
      .decimalPlaces(2)
      .toFixed();
    const returnIfSecondRoundBonusReached = new BigNumber(returnPerAnnum).plus(bonusReturn).multipliedBy(100).toFixed();

    let minTotalReturn = new BigNumber(baseReturn);
    let maxTotalReturn = new BigNumber(returnIfSecondRoundBonusReached);
    let dfiReturn = null;

    if (dfiRewardPerAnnum) {
      dfiReturn = new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFixed();

      minTotalReturn = minTotalReturn.plus(dfiReturn);
      maxTotalReturn = maxTotalReturn.plus(dfiReturn);
    }

    minTotalReturn = minTotalReturn.toFixed();
    maxTotalReturn = maxTotalReturn.toFixed();

    return (
      <div className="bonus-explanation-popover">
        <div className="bonus-explanation-section">
          <div className="bonus-explanation-row small-grey">
            <div><Trans>Indicative price growth</Trans></div>
            <div><Trans>APY ({{ coinSymbol }})</Trans></div>
          </div>
          <div className="bonus-explanation-row">
            <div>&#60;{bonusTargetInPercent}%</div>
            <div>{baseReturn}%</div>
          </div>
          <div className="bonus-explanation-row">
            <div><Trans>Approx.</Trans> &#62;={bonusTargetInPercent}% *</div>
            <div>{returnIfFirstRoundBonusReached}%</div>
          </div>
          <div className="bonus-explanation-row">
            <div><Trans>Approx.</Trans> &#62;={bonusTargetInPercent}% x 2 *</div>
            <div>{returnIfSecondRoundBonusReached}%</div>
          </div>
        </div>
        {dfiReturn && <DfiBonusSection dfiReturn={dfiReturn} />}
        <div className="bonus-explanation-section">
          <div className="bonus-explanation-row">
            <div><Trans i18nKey="Total batch return">Total</Trans></div>
            <div><Trans>{{ minTotalReturn }}% to {{ maxTotalReturn }}%</Trans></div>
          </div>
        </div>
        <div className="bonus-explanation-section">
          <div className="bonus-explanation-row small-grey">
            <div style={{ textAlign: 'start' }}><Trans>* Definite prices will be provided when the batch starts.</Trans></div>
          </div>
        </div>
      </div>
    );
  }
}
