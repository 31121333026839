import { MonthDateTime } from '../lib/customType';

export default function getMonthDateTime(date: Date): MonthDateTime {
  const month = date.toLocaleString('en-us', { month: 'short' });
  const day = (date.getDate()).toString();
  let hours = date.getHours();
  let minutes: number|string = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes}${ampm}`;
  return new MonthDateTime(month, day, time);
}
