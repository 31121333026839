import BigNumber from 'bignumber.js';
import { SwapCoin } from '../../schema/market';
import { CakeApiBase } from '../CakeApiBase';

export class MarketApi extends CakeApiBase {
  async coinSwapStake(
    foreignCoinId: string,
    foreignCoinAmount: BigNumber,
    stakedCoinId: string,
  ) {
    return this.requestManager.post<boolean>('market/coin-swap-stake', {
      foreignCoinId,
      foreignCoinAmount,
      stakedCoinId,
    });
  }

  async swapCoin(
    foreignCoinId: string,
    foreignCoinAmount: BigNumber,
    stakedCoinId: string,
  ) {
    return this.requestManager.post<SwapCoin>('market/swap-coin', {
      foreignCoinId,
      foreignCoinAmount,
      stakedCoinId,
    });
  }

  async reserve(coinId: string, shareAmount: string) {
    return this.requestManager.post('market/reserve', {
      coinId,
      shareAmount,
    });
  }

  async cancel(coinId: string) {
    return this.requestManager.post('market/cancel', {
      coinId,
    });
  }
}
