/**
 * @param language 'it'|'zh'|'zht'|'en' as default
 * Note that we have a TR telegram as well https://t.me/CakeDeFiTR
 */
export default function getTelegramLinkByLanguage(language?: string): string {
  switch (language) {
    case 'en':
      return 'https://t.me/Bake_EN';
    case 'de':
      return 'https://t.me/Bake_DE';
    case 'it':
      return 'https://t.me/Bake_ITA';
    case 'zht':
      return 'https://t.me/BakeZHT';
    default:
      return 'https://t.me/Bake_EN';
  }
}
