import React from 'react';
import { Route } from 'react-router-dom';
import getCakeDeFiWebAppBaseURL from 'utils/getCakeDeFiWebAppBaseURL';

export default function RedirectToExternalUrl({
  path = ['/'],
  targetUrl = getCakeDeFiWebAppBaseURL(),
}) {
  return (
    <Route
      path={path}
      render={() => {
        window.location.replace(targetUrl);
        return null;
      }}
    />
  );
}
