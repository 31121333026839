import React, { useRef } from 'react';
import Button from 'common/components/Button/Button';
import { Trans } from 'react-i18next';
import { Container } from 'reactstrap';
import ClickableElement from './ClickableElement';
import TermsAndConditionsPDF from './TermsAndConditionsPDF';

const TermsAndConditionsModal = ({ closeModal }) => {
  const containerRef = useRef(null);

  return (
    <Container className="tnc-content" ref={containerRef}>
      <TermsAndConditionsPDF customWidth={containerRef?.current?.offsetWidth} />
      <footer>
        <ClickableElement
          minimumDelay={2000}
          onClick={() => closeModal()}>
          <Button
            outline
            className="mr-4"
            btnLink
            type="button"
            tabIndex={5}
          ><Trans>Close</Trans></Button>
        </ClickableElement>
      </footer>
    </Container>
  );
};

export default TermsAndConditionsModal;
