import React from 'react';
import BigNumber from 'bignumber.js';
import { createUseStyles } from 'react-jss';
import Variables, { getMinWidthLg } from '../../../Variables.styles';
import { getShortVersionOfNumber, localizeBigNumber, show8Digits } from '../../../utils';
// eslint-disable-next-line import/no-cycle
import { MouseOverTooltip } from '../../../views/components';
import isZero from '../../../utils/isZero';
import { useCakeSelector } from '../../../app/rootReducer';
import { isObfuscatedSelector } from '../../../app/selectors/userAppPreferenceSelector';

const useStyles = createUseStyles({
  bigNumberDisplay: {
    color: (props: any) => (props.faded ? Variables.faded : Variables.textSecondary),
    fontSize: '1.6rem',
    fontWeight: '500',
    fontFamily: Variables.fontFamily.monoSpace,
    textAlign: 'end',
  },
  obfuscateDisplay: {
    fontFamily: Variables.fontFamily.monoSpace,
    color: Variables.textSecondary,
  },
  currency: {
    color: props => (props.faded ? Variables.faded : Variables.textSecondary),
    fontSize: '1.6rem',
    marginLeft: Variables.extraSmall,
  },
  popover: {
    padding: `${Variables.extraSmall} ${Variables.medium}`,
  },
  popoverCurrency: {
    marginLeft: Variables.extraSmall,
  },
  onlyMobileCurrency: {
    composes: ['$currency'],
  },
  [getMinWidthLg]: {
    onlyMobileCurrency: {
      display: 'none',
    },
  },
});

type NumberLike = (string | number | BigNumber);

const convertToPositive = (children: NumberLike): NumberLike => {
  if (BigNumber.isBigNumber(children) && children.isNegative()) {
    return children.multipliedBy(-1);
  }
  if (typeof children === 'string') {
    if (children.length > 0 && children[0] === '-') {
      return children.substring(1);
    }
  }
  if (typeof children === 'number' && children < 0) {
    return children * -1;
  }
  return children;
};

const ALL_PREFERRED_CURRENCY_CRYPTOS = ['BTC'];

interface GetDisplayChildrenWithoutTooltip {
  children: NumberLike;
  decimalPoints: number;
  fixedDecimals: boolean;
  isDisplayAll: boolean;
  currencyForAutoDecimals: string;
  isShortVersion: boolean;
}

const getDisplayChildrenWithoutTooltip = (args: GetDisplayChildrenWithoutTooltip) => {
  const { children, decimalPoints, fixedDecimals, isDisplayAll, currencyForAutoDecimals, isShortVersion } = args;

  const canConvertToBN = !(new BigNumber(children).isNaN());
  if (!canConvertToBN) return children;

  const isDisplayForeignCurrency = currencyForAutoDecimals && !ALL_PREFERRED_CURRENCY_CRYPTOS.includes(currencyForAutoDecimals);

  // display crypto
  if (isDisplayAll) return localizeBigNumber(children, true);
  if (decimalPoints && fixedDecimals) return localizeBigNumber(children, false, decimalPoints, undefined, true); // fixed dp
  if (decimalPoints) return localizeBigNumber(children, false, decimalPoints); // shows 0dp to specified dp
  if (!isDisplayForeignCurrency) return show8Digits(children);

  // display foreign currencies
  if (isShortVersion) return getShortVersionOfNumber(children, 2);
  return localizeBigNumber(children, false, 2);
};

interface BigNumberDisplayProps {
  children: string | BigNumber | number,
  isSensitiveNumber?: boolean,
  currency?: string, // postfix
  onlyShowCurrencyOnMobile?: boolean,

  className?: string,
  emptyClass?: boolean,
  fadeIfZero?: boolean,

  noTooltip?: boolean, // prioritized
  tooltipMessage?: string, // if noTooltip false
  tooltipDecimals?: number | string, // if no tooltipMessage

  isShortVersion?: boolean, // for foreign currencies
  isAlwaysPositive?: boolean,

  isDisplayAll?: boolean, // prioritized
  decimalPoints?: number, // if isDisplayAll is false
  fixedDecimals?: boolean,
  currencyForAutoDecimals?: string, // if decimalPoints does not exists, takes in coinId/fx currency
}

const BigNumberDisplay = ({
  children,
  isSensitiveNumber = false,
  currency,
  onlyShowCurrencyOnMobile,

  className,
  emptyClass = false,
  fadeIfZero = false,

  noTooltip,
  tooltipMessage,
  tooltipDecimals,

  isShortVersion = false,
  isAlwaysPositive = false,

  isDisplayAll = false,
  decimalPoints,
  fixedDecimals = false,
  currencyForAutoDecimals,
}: BigNumberDisplayProps) => {
  const classes = useStyles(fadeIfZero ? { faded: isZero(children) } : { faded: false });
  const isObfuscated = useCakeSelector(isObfuscatedSelector);

  if (new BigNumber(children).isNaN()) return null;

  const processedChildren = (() => {
    if (isAlwaysPositive) {
      return convertToPositive(children);
    }
    return children;
  })();

  const displayChildrenWithoutTooltip = getDisplayChildrenWithoutTooltip({
    children: processedChildren,
    decimalPoints,
    fixedDecimals,
    isDisplayAll,
    currencyForAutoDecimals,
    isShortVersion,
  });

  const tooltipShowDecimals = tooltipDecimals ? localizeBigNumber(processedChildren, false, tooltipDecimals) : localizeBigNumber(processedChildren, true);
  const tooltipContent = tooltipMessage || tooltipShowDecimals;
  const displayChildrenWithTooltip = displayChildrenWithoutTooltip && (
    <MouseOverTooltip
    className={null}
    renderTooltip={() => <div className={classes.popover}>
        <span>{tooltipContent}</span>
        {currency && <span className={classes.popoverCurrency}>{currency}</span>}
      </div>}
    >
      {displayChildrenWithoutTooltip}
    </MouseOverTooltip>
  );

  const displayCurrency = currency
    && (<span className={className || (onlyShowCurrencyOnMobile ? classes.onlyMobileCurrency : classes.currency)}>{currency}</span>);

  const obfuscatedDisplayEmptyClass = <><>•••</> {currency && <>{' '}{currency}</>}</>;
  const obfuscatedDisplay = <><span className={classes.obfuscateDisplay}>•••</span> {displayCurrency}</>;

  if (isSensitiveNumber && isObfuscated) {
    return emptyClass ? obfuscatedDisplayEmptyClass : obfuscatedDisplay;
  }

  const notObfuscatedDisplayEmptyClass = <>{noTooltip ? displayChildrenWithoutTooltip : displayChildrenWithTooltip}{currency && <>{' '}{currency}</>}</>;
  const notObfuscatedDisplay = (
    <span>
      <span className={className || classes.bigNumberDisplay}>
        {noTooltip ? displayChildrenWithoutTooltip : displayChildrenWithTooltip}
      </span>
      {currency && <>{' '}{displayCurrency}</>}
    </span>);

  return emptyClass ? notObfuscatedDisplayEmptyClass : notObfuscatedDisplay;
};

export default BigNumberDisplay;
