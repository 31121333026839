import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import i18n from '../../../i18n';
import Button from '../Button/Button';
import { getZendeskRequestLink } from '../../../utils/getZendeskLinks';
import CrashReportDialogStyles from './CrashReportDialog.styles';

const useStyles = createUseStyles(CrashReportDialogStyles);

const CrashReportDialog = () => {
  const [isClosed, setIsClosed] = useState(false);
  const classes = useStyles({ isClosed });

  const { hasNetworkError, networkErrorType } = useCakeSelector(state => state.networkError);

  if (hasNetworkError && networkErrorType === 'CLIENT_CONNECTION_DOWN') {
    return null;
  }

  const onClickClose = () => {
    setIsClosed(true);
  };

  const onHelpDeskClick = () => {
    window.open(getZendeskRequestLink(i18n.language), '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}><Trans>It looks like we're having issues.</Trans></h2>
      <h3 className={classes.subtitle}><Trans>Our team has been notified. If you'd like to help, submit a request below.</Trans></h3>
      <div className={classes.actionButtonsWrapper}>
        <Button primary onClick={onHelpDeskClick}><Trans>Submit a request</Trans></Button>
        <Button secondary onClick={onClickClose}><Trans>Close</Trans></Button>
      </div>
    </div>
  );
};

export default CrashReportDialog;
