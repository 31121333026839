import { useCakeSelector } from 'app/rootReducer';
import React, { useCallback, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAccessRightSelector } from 'user/userSelector';
import { isCountryRestricted } from 'utils/isCountryRestricted';
import { AccessRights } from 'types/access-rights/access-rights';
import Button from '../../common/components/Button/Button';
import { ModalContext } from '../../modals/ModalContext';
import { setRedirectOnLogin } from '../../navigation/navigationSlice';
import { getMinWidthSm } from '../../Variables.styles';
import KycUnverifiedModal from '../../views/containers/SharedComponents/kyc/KycUnverifiedModal';
import FiatBuyModal from './FiatBuy/FiatBuyModal';
import { clearFiatBuyModalSlice } from './FiatBuy/fiatBuyModalSlice';
import { fetchWalletAddress } from './FiatBuy/fiatBuyModalSliceThunk';

const useStyles = createUseStyles({
  buyButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  buyLabel: {
    marginRight: 0,
  },
  [getMinWidthSm]: {
    buyButton: {
      flexDirection: 'row',
    },
    buyLabel: {
      marginRight: '4px',
    },
  },
});

const FiatBuyAtHeader = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const buyCryptoWithFiat = useCakeSelector(userAccessRightSelector(AccessRights.BuyCryptoWithFiat));
  const { redirectOnLogin } = useCakeSelector(state => state.navigation);
  const userDetails = useCakeSelector(state => state.user.details);
  const { coins } = useCakeSelector(state => state.wallet);
  const { openModal, closeModal } = useContext(ModalContext);
  const onCloseModal = useCallback(() => {
    closeModal();
    dispatch(clearFiatBuyModalSlice());
  }, [closeModal, dispatch]);

  const getModalBasedOnAccess = useCallback(() => {
    const modalData = buyCryptoWithFiat.access
      ? {
        component: <FiatBuyModal onClose={onCloseModal} selectedCoin="BTC" />,
        onClose: onCloseModal,
      }
      : {
        component: <KycUnverifiedModal action={AccessRights.BuyCryptoWithFiat} onClose={onCloseModal} />,
        fullWidth: false,
        onClose: onCloseModal,
      };

    return modalData;
  }, [buyCryptoWithFiat, onCloseModal]);

  const fetchAndProcessWalletAddress = useCallback(async () => {
    if (buyCryptoWithFiat && !buyCryptoWithFiat.access) {
      openModal(getModalBasedOnAccess());
    }
    window.cakepool.showSpinner();
    const promise: any = await dispatch(fetchWalletAddress('BTC'));
    window.cakepool.hideSpinner();

    if (promise.error && promise.error.code !== 'KycFullNecessary') {
      return window.cakepool.showAlert('error', <Trans>{promise.error.message}</Trans>);
    }
    openModal(getModalBasedOnAccess());
    return null;
  }, [buyCryptoWithFiat, dispatch, openModal, getModalBasedOnAccess]);

  const onClickFiatBuy = useCallback(async () => {
    dispatch(clearFiatBuyModalSlice());
    fetchAndProcessWalletAddress();
  }, [fetchAndProcessWalletAddress, dispatch]);

  const onClickFiatBuyNotLoggedIn = useCallback(async () => {
    dispatch(setRedirectOnLogin('fiatBuy'));
  }, [dispatch]);

  useEffect(() => {
    if (redirectOnLogin
        && isLoggedIn
        && buyCryptoWithFiat
        && buyCryptoWithFiat.access) {
      fetchAndProcessWalletAddress();
      dispatch(setRedirectOnLogin(null));
    }
  }, [dispatch, redirectOnLogin, isLoggedIn, buyCryptoWithFiat, coins, fetchAndProcessWalletAddress]);

  if (!isLoggedIn || !buyCryptoWithFiat) {
    return <Link to="/login"><Button primary medium onClick={onClickFiatBuyNotLoggedIn}><Trans>Buy crypto</Trans></Button></Link>;
  }

  const isRestricted = isCountryRestricted(buyCryptoWithFiat);
  const isUserStatusActive = userDetails?.status === 'ACTIVE';

  if (isRestricted || !isUserStatusActive) {
    return null;
  }

  return (
    <>
      <Button primary medium onClick={onClickFiatBuy}>
        <div className={classes.buyButton}>
          <div><Trans>Buy crypto</Trans></div>
        </div>
      </Button>
    </>
  );
};

export default FiatBuyAtHeader;
