import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './LoadingDot.styles';

const useStyles = createUseStyles(styles);

const LoadingDot = ({ delay, offset }) => {
  const classes = useStyles({
    delay,
    offset,
  });
  return <span className={classes.loadingDot} />;
};

export default LoadingDot;
