import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FreezerCoinInfoState, Shares } from '../../types/freezer/freezer-calculator';
import { StakingTypes } from '../../types/staking/staking-page';

const initialState: FreezerCoinInfoState = {
  inputCoin: 'DFI',
  inputCoinGroup: Shares.STAKING,
  termType: StakingTypes.FLEXIBLE,
  fixedTermDuration: 120,
  fixedTermAutoRenew: false,
};

const freezerCoinInfoSlice = createSlice({
  name: 'freezerCoinInfo',
  initialState,
  reducers: {
    setInputCoin: (state, action: PayloadAction<string>) => {
      state.inputCoin = action.payload;
    },
    setInputCoinGroup: (state, action: PayloadAction<Shares>) => {
      state.inputCoinGroup = action.payload;
    },
    setTermType: (state, action: PayloadAction<string>) => {
      state.termType = action.payload;
    },
    setFixedTermDuration: (state, action: PayloadAction<number>) => {
      state.fixedTermDuration = action.payload;
    },
    setFixedTermAutoRenew: (state, action: PayloadAction<boolean>) => {
      state.fixedTermAutoRenew = action.payload;
    },
  },
});

export const {
  setTermType,
  setFixedTermDuration,
  setFixedTermAutoRenew,
  setInputCoin,
  setInputCoinGroup,
} = freezerCoinInfoSlice.actions;

export default freezerCoinInfoSlice.reducer;
