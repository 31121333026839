import React, { Component } from 'react';
import { Icon } from '../../components';

export default class CustomModal extends Component {
  state = {
    isVisible: false,
    renderContent() { },
  };

  constructor() {
    super();
    const { cakepool } = window;
    cakepool.showModal = (renderContent, callback) => this.showModal(renderContent, callback);
    cakepool.hideModal = () => this.hideModal();
    cakepool.isModalVisible = () => this.isModalVisible();
    cakepool.refreshModal = () => this.refreshModal();
  }

  setModalState(state, callback) {
    this.setState(state, callback);
  }

  showModal(renderContent, callback) {
    this.setState({
      isVisible: true,
      renderContent: renderContent || function rc() { },
    }, callback);
  }

  refreshModal() {
    this.forceUpdate();
  }

  hideModal() {
    this.setState({
      isVisible: false,
    });
  }

  isModalVisible() {
    return this.state.isVisible;
  }

  onClose() {
    if (window.cakepool.onClose) {
      window.cakepool.onClose();
    }

    this.hideModal();
  }

  render() {
    const {
      isVisible,
    } = this.state;
    if (!isVisible) {
      return null;
    }
    return (
      <div className="cakepool-modal">
        <div className="modal open">
          <div className="modal-wrapper">
            <div className="modal-content" id="modal-content">
              {this.state.renderContent()}
            </div>
            <span onClick={() => this.onClose()} className="close-button">
              <Icon name="close"></Icon>
            </span>
          </div>
        </div>
        <div className="modal-overlay" onClick={() => this.onClose()}></div>
      </div>
    );
  }
}
