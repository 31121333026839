import BigNumber from 'bignumber.js';
import { CoinPrice } from '@cakedefi/cake-sdk/schema';

export function convertBTCOrETHToCryptoAmount(
  source: 'BTC' | 'ETH',
  sourceAmount: any,
  target: string,
  targetDecimals: number,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
): string {
  const coinPrice = pricing.find(({ CoinId }) => CoinId === target);
  if (!coinPrice) {
    return null;
  }
  const swapPrices = withoutMarkup ? coinPrice.coinSwapPricesWithoutMargin : coinPrice.coinSwapPrices;
  const priceForShare = new BigNumber(swapPrices?.[source]);

  return new BigNumber(sourceAmount)
    .dividedBy(priceForShare)
    .decimalPlaces(targetDecimals)
    .toFixed();
}
