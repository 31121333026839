import { isPositiveAndNotZeroNumber } from 'utils';
// import { isAmountWithinPaymentMethodLimit } from './isAmountWithinPaymentMethodLimit';

export function isWithinCoinOnSalesAmount(
  isFiatSwappable: boolean,
  calculatedCryptoAmount: any,
) {
  if (!isFiatSwappable || calculatedCryptoAmount === '') {
    return true;
  }
  return isPositiveAndNotZeroNumber(
    calculatedCryptoAmount,
  );
}
