import redEggImg from 'assets/svg/referral/easter-egg-red.svg';
import greenEggImg from 'assets/svg/referral/easter-egg-green.svg';
import blueEggImg from 'assets/svg/referral/easter-egg-blue.svg';
import goldEggImg from 'assets/svg/referral/easter-egg-gold.svg';
import eggHero0Img from 'assets/svg/referral/easter-hero-0.svg';
import eggHero1Img from 'assets/svg/referral/easter-hero-1.svg';
import eggHero2Img from 'assets/svg/referral/easter-hero-2.svg';
import eggHero3Img from 'assets/svg/referral/easter-hero-3.svg';
import eggHero4Img from 'assets/svg/referral/easter-hero-4.svg';

// used in ReferralShareBanner.tsx
export const easterSharethisMessage = 'Earn an additional $10 in rewards this Easter. That’s a total of $40 when you sign up to Bake using my link.';

export const leaderboardInfo = [
  {
    leftImg: redEggImg,
    label: 'Red egg',
    minReferralsNeeded: 2,
    maxReferralsNeeded: 4,
    referralValue: '50',
    totalValue: '50',
  },
  {
    leftImg: greenEggImg,
    label: 'Green egg',
    minReferralsNeeded: 5,
    maxReferralsNeeded: 9,
    referralValue: '100',
    totalValue: '150',
  },
  {
    leftImg: blueEggImg,
    label: 'Blue egg',
    minReferralsNeeded: 10,
    maxReferralsNeeded: 49,
    referralValue: '200',
    totalValue: '350',
  },
  {
    leftImg: goldEggImg,
    label: 'Gold egg',
    minReferralsNeeded: 50,
    referralValue: '400',
    totalValue: '750',
  },
];

const getMilestoneRewards = (referralCount: number) => {
  if (referralCount >= 50) return 50 + 100 + 200 + 400;
  if (referralCount >= 10) return 50 + 100 + 200;
  if (referralCount >= 5) return 50 + 100;
  if (referralCount >= 2) return 50;
  return 0;
};

export const getEasterCampaignReferralBonusEarnedUsd = (referralCount: number) => {
  const rewardPerReferral = 20;
  const referralRewards = referralCount * rewardPerReferral;
  const milestoneRewards = getMilestoneRewards(referralCount);
  return referralRewards + milestoneRewards;
};

export const getEggHeroCount = (referralCount: number) => {
  if (referralCount >= 50) return 4;
  if (referralCount >= 10) return 3;
  if (referralCount >= 5) return 2;
  if (referralCount >= 2) return 1;
  return 0;
};

export const getEggHeroImage = (referralCount: number) => {
  if (referralCount >= 50) return eggHero4Img;
  if (referralCount >= 10) return eggHero3Img;
  if (referralCount >= 5) return eggHero2Img;
  if (referralCount >= 2) return eggHero1Img;
  return eggHero0Img;
};

export const getIsActiveRow = (referralCount: number, min: number, max?: number) => {
  if (!max) {
    return referralCount >= min;
  }
  return referralCount >= min && referralCount <= max;
};
