import Variables from '../../../Variables.styles';

export default {
  headerLink: {
    padding: `${Variables.small} ${Variables.medium} !important`,
  },
  newLabel: {
    position: 'relative',
    bottom: '10px',
    color: Variables.electricBerry,
    fontSize: '1.3rem',
  },
};
