export interface SimplifiedDate {
  significantValue: number;
  unit: 'secs'|'mins'|'hours'|'days';
}

export default function simplifyTimeFormat(seconds: number|string): SimplifiedDate {
  let raw = seconds;
  if (typeof seconds === 'string') {
    raw = parseFloat(seconds);
  }

  let significantValue: number = raw as number;

  // Below 60 seconds [1 minute], return as seconds
  if (seconds < 60) {
    return {
      significantValue: roundValue(significantValue),
      unit: 'secs',
    };
  }

  significantValue /= 60; // Convert to minute

  // Below 60 mins [1 hour], return as minute
  if (significantValue < 60) {
    return {
      significantValue: roundValue(significantValue),
      unit: 'mins',
    };
  }

  significantValue /= 60; // Convert to hour

  // Below 60 mins [1 hour], return as hour
  if (significantValue < 24) {
    return {
      significantValue: roundValue(significantValue),
      unit: 'hours',
    };
  }

  significantValue /= 24; // Convert to day
  return {
    significantValue: roundValue(significantValue),
    unit: 'days',
  };
}

/**
 * Round to two decimal place
 */
function roundValue(num): number {
  return +(`${Math.round(Number(`${num}e+2`))}e-2`);
}
