import BigNumber from 'bignumber.js';
import { Transaction } from '../schema/model';
import { VMTransaction } from '../schema/custom';
import convertRevenueOperationToReadable from './convertRevenueOperationToReadable';

export default function filterExportRevenueTransactionData(transactions: Transaction[]): VMTransaction[] {
  const filteredTransactions: VMTransaction[] = [];

  transactions.forEach((transaction) => {
    let usdEquivalent = '--';
    if (!new BigNumber(transaction.usdEquivalent).isNaN()) {
      usdEquivalent = new BigNumber(transaction.usdEquivalent).toFormat(2);
    }
    filteredTransactions.push({
      id: transaction.id,
      confirmedAt: transaction.confirmedAt,
      coin: transaction.Wallet.CoinId,
      amount: new BigNumber(transaction.amount).shiftedBy(-transaction.Wallet.Coin.decimals).toString(),
      usdEquivalent,
      operation: convertRevenueOperationToReadable(transaction.operation),
    });
  });

  return filteredTransactions;
}
