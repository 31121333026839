import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { OnRampProviderType } from '../../../types/onramp/onramp-provider';
import { FiatBuyModalState } from '../../../types/onramp/state';
import {
  filterApplePayPaymentMethods,
  filterPricesBasedOnPaymentMethods,
  findPaymentMethod,
  findPrice,
  FIAT_TYPE,
  CRYPTO_TYPE,
} from './fiatBuyModalService';
import {
  fetchBanxaFiats,
  fetchBanxaPaymentMethods,
  fetchBanxaFiatPrices,
  fetchWalletAddress,
  fetchFiatSwappableWalletAddress,
  createBanxaOrder,
} from './fiatBuyModalSliceThunk';

export const handleBanxaExtraReducers = (
  builder: ActionReducerMapBuilder<FiatBuyModalState>,
) => {
  builder.addCase(fetchBanxaFiatPrices.fulfilled, (state, action) => {
    if (state.type !== OnRampProviderType.BANXA) {
      return;
    }
    state.banxa.isFetchingCryptoPrice = false;
    state.banxa.isFetchingFiatPrice = false;
    const { response, type } = action.payload;
    const filteredResponse = filterPricesBasedOnPaymentMethods(
      response,
      state.banxa.paymentMethods,
    );
    if (!filteredResponse) {
      return;
    }
    state.banxa.fiatPrices = filteredResponse;
    const [firstPrice] = filteredResponse.prices;
    const selectedPrice = state.banxa.selectedPaymentMethod
      ? findPrice(state.banxa.fiatPrices, state.banxa.selectedPaymentMethod.id)
      : firstPrice;

    if (selectedPrice) {
      if (type === FIAT_TYPE) {
        state.inputs.targetAmount = Number(selectedPrice.coinAmount);
      } else if (type === CRYPTO_TYPE) {
        state.inputs.sourceAmount = Number(selectedPrice.fiatAmount);
      }
    }

    state.banxa.selectedFiatPrice = selectedPrice;
    if (!state.banxa.selectedPaymentMethod && selectedPrice) {
      state.banxa.selectedPaymentMethod = findPaymentMethod(
        state.banxa.paymentMethods,
        selectedPrice.paymentMethodId,
      );
    }
    state.error = null;
  });

  builder.addCase(fetchBanxaFiatPrices.pending, (state, action) => {
    const {
      arg: type,
    } = action.meta;
    state.banxa.isFetchingCryptoPrice = false;
    state.banxa.isFetchingFiatPrice = false;

    if (type === FIAT_TYPE) {
      state.banxa.isFetchingCryptoPrice = true;
    } else if (type === CRYPTO_TYPE) {
      state.banxa.isFetchingFiatPrice = true;
    }

    state.banxa.fiatPrices = null;
    state.banxa.selectedFiatPrice = null;
    state.error = null;
  });

  builder.addCase(fetchBanxaFiatPrices.rejected, (state, action) => {
    state.banxa.isFetchingCryptoPrice = false;
    state.banxa.isFetchingFiatPrice = false;
    state.error = action.error.message;
  });

  builder.addCase(fetchWalletAddress.pending, (state) => {
    state.inputs.isLoadingWalletAddress = true;
    state.inputs.walletAddress = null;
    state.hasWalletPaidFee = null;
    state.guaranteeFeeUsd = null;
  });

  builder.addCase(fetchWalletAddress.fulfilled, (state, action) => {
    const { address, hasPaidFee, guaranteeFeeUsd } = action.payload;
    state.inputs.walletAddress = address;
    state.inputs.isLoadingWalletAddress = false;
    state.guaranteeFeeUsd = guaranteeFeeUsd;
    state.hasWalletPaidFee = hasPaidFee;
  });

  builder.addCase(fetchWalletAddress.rejected, (state) => {
    state.inputs.isLoadingWalletAddress = false;
    state.inputs.walletAddress = null;
    state.hasWalletPaidFee = null;
    state.guaranteeFeeUsd = null;
  });

  builder.addCase(fetchFiatSwappableWalletAddress.pending, (state, action) => {
    state.fiatSwappable.isLoadingWalletAddress = true;
    state.fiatSwappable.walletAddress = null;
  });

  builder.addCase(fetchFiatSwappableWalletAddress.fulfilled, (state, action) => {
    state.fiatSwappable.isLoadingWalletAddress = false;
    state.fiatSwappable.walletAddress = action.payload.address;
  });

  builder.addCase(fetchFiatSwappableWalletAddress.rejected, (state) => {
    state.fiatSwappable.isLoadingWalletAddress = false;
    state.fiatSwappable.walletAddress = null;
  });

  builder.addCase(fetchBanxaFiats.pending, (state) => {
    state.banxa.isFiatsLoading = !state.banxa.fiats;
  });

  builder.addCase(fetchBanxaFiats.fulfilled, (state, action) => {
    state.banxa.fiats = action.payload.fiats;
    state.banxa.isFiatsLoading = false;
  });

  builder.addCase(fetchBanxaFiats.rejected, (state) => {
    state.banxa.isFiatsLoading = false;
  });

  builder.addCase(fetchBanxaPaymentMethods.pending, (state) => {
    state.banxa.isPaymentMethodLoading = true;
    state.banxa.paymentMethods = null;
    state.banxa.selectedPaymentMethod = null;
  });

  builder.addCase(fetchBanxaPaymentMethods.fulfilled, (state, action) => {
    const { selectedFiatPrice } = state.banxa;
    const filteredPaymentMethods = filterApplePayPaymentMethods(action.payload.paymentMethods);
    state.banxa.paymentMethods = filteredPaymentMethods;
    state.banxa.isPaymentMethodLoading = false;
    if (selectedFiatPrice) {
      state.banxa.selectedPaymentMethod = findPaymentMethod(
        filteredPaymentMethods,
        selectedFiatPrice.paymentMethodId,
      ) || filteredPaymentMethods[0];
    }
  });

  builder.addCase(fetchBanxaPaymentMethods.rejected, (state) => {
    state.banxa.isPaymentMethodLoading = false;
    state.banxa.paymentMethods = null;
    state.banxa.selectedPaymentMethod = null;
  });

  builder.addCase(createBanxaOrder.fulfilled, (state, action) => {
    const {
      payload: { order },
    } = action;
    state.order.id = order.id;
  });
};
