import Variables from '../../../Variables.styles';
import ButtonStyles from '../../styles/Button.styles';
import ModalStyles from '../../styles/Modal.styles';

export default {
  ...ModalStyles,
  ...ButtonStyles,
  paragraphHeader: {
    fontSize: '1.8rem',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
  paragraph: {
    fontSize: Variables.fontSize.base,
    letterSpacing: '0.44px',
    lineHeight: '2.4rem',
  },
  lastParagraph: {
    marginBottom: '4.8rem',
  },
};
