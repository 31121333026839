import lessonImages from './lessonImages';
import { Stack } from './learnEarnContent';

export const ethContent: Stack = {
  isAvailable: true,
  id: 'ETH',
  coinId: 'ETH',
  coinName: 'Ethereum',
  description: 'Programmable blockchain with smart contract functionality',
  rewardType: 'FrozenLiquidityMiningShare',
  welcomeImage: lessonImages.ethCover,
  lessons: [
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'ETH-1',
      lessonIndex: 1,
      title: 'What is Ethereum?',
      parts: [{
        partIndex: 1,
        image: lessonImages.ethL1P1,
        content: 'Ethereum is a <strong>decentralized and open-source blockchain with smart contract functionality</strong>. It is also described as a technology for building apps and organizations, holding assets, transacting and communicating without being controlled by a central authority.',
      }, {
        partIndex: 2,
        image: lessonImages.ethL1P2,
        content: 'Ethereum was conceived by Canadian programmer <strong>Vitalik Buterin</strong> and was initially described in a whitepaper that he published in 2013. Several codenamed prototypes of Ethereum were developed over 18 months in 2014 and 2015 by the Ethereum Foundation as part of their proof-of-concept series.'
        + '<br />'
        + '<br />'
        + 'The first live release, Frontier, was launched on <strong>30 July 2015</strong>.',
      }, {
        partIndex: 3,
        image: lessonImages.ethL1P3,
        content: 'According to the team behind it, Ethereum offers the following benefits or advantages:'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li><strong>Banking for everyone</strong> - all you need to access Ethereum and its products is an internet connection.</li>'
        + '<li><strong>A more private internet</strong> - You don\'t need to provide all your personal details to use an Ethereum app.</li>'
        + '<li><strong>A peer-to-peer network</strong> - Ethereum allows you to move money, or make agreements, directly with someone else. You don\'t need to go through intermediary companies.</li>'
        + '<li><strong>Censorship-resistant</strong> - No government or company has control over Ethereum.</li>'
        + '<li><strong>Commerce guarantees</strong> - Customers have a guarantee that funds will only change hands if you provide what was agreed. Likewise, developers can have certainty that the rules won\'t change on them.</li>'
        + '<li><strong>All products are composable</strong> - Since all apps are built on the same blockchain with a shared global state, they can build off each other.</li>'
        + '<ul>',
      }, {
        partIndex: 4,
        image: lessonImages.ethL1P4,
        content: 'Other claims or reasons why people use Ethereum include:'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li><strong>Cheaper and faster cross-border payments</strong> - many stablecoins are built on the Ethereum network. Ethereum and stablecoins simplify the process of sending money overseas.</li>'
        + '<li><strong>The quickest help in times of crisis</strong> - Ethereum can provide unfettered access to the global economy when people are cut off from the outside world.</li>'
        + '<li><strong>Empowering creators</strong> - Ethereum is one of the largest platforms for creators.</li>'
        + '<li><strong>Empowering gamers</strong> - Ethereum is also used for blockchain gaming or play-to-earn games.</li>'
        + '<ul>',
      }, {
        partIndex: 5,
        image: lessonImages.ethL1P5,
        content: 'As of August 2022, the team behind Ethereum managed to generate these numbers:'
        + '<br />'
        + '<ul>'
        + '<ul>'
        + '<li><strong>2,970</strong> projects built on Ethereum</li>'
        + '<li><strong>71M+</strong> accounts / wallets with an ETH balance</li>'
        + '<li><strong>50.5M</strong> smart contracts on Ethereum</li>'
        + '<li><strong>$11.6T</strong>- value moved through the Ethereum network in 2021</li>'
        + '<li><strong>$3.5B</strong> creator earnings on Ethereum in 2021</li>'
        + '<li><strong>1,686.41M (13.1TPS)</strong> - number of transactions today</li>'
        + '<ul>',
      }, {
        partIndex: 6,
        image: lessonImages.ethL1P6,
        content: 'What are the similarities or differences between Ethereum and Bitcoin?'
        + '<br/>'
        + '<br/>'
        + 'Ethereum builds on Bitcoin\'s innovations. Also, both let you use digital money without payment providers or banks. That said, <strong>Ethereum is programmable</strong>. This means that you can build and deploy decentralized applications on its network. It also means that you can build apps that use the blockchain to store data or control what your app can do.',
      }],
      quiz: {
        question: 'Which of the following best describes Ethereum?',
        answer: 'B',
        options: [{
          option: 'A',
          description: 'Ethereum is a centralized blockchain that is controlled by the Ethereum Foundation.',
          explanation: 'Ethereum is not centralized blockchain and is not controlled by any entity. Please try again',
        }, {
          option: 'B',
          description: 'Ethereum is a decentralized and open-source blockchain with smart contract functionality. It was conceived by programmer Vitalik Buterin and was launched in 2015. Also, unlike Bitcoin, Ethereum is programmable.',
          explanation: 'Correct answer!',
        }, {
          option: 'C',
          description: 'Ethereum is a <strong>decentralized and open-source blockchain with smart contract functionality</strong>. It was conceived by programmer Vitalik Buterin and launched in 2013.',
          explanation: 'Ethereum was mentioned in a whitepaper by Vital Buterin in 2013 but was not launched until a few years later. Please try again.',
        }, {
          option: 'D',
          description: 'Ethereum is programmable. This means that you can build and deploy decentralized applications on its network. However, it is not fully decentralized - which allows for a more efficient communication chain and a simpler decision making process.',
          explanation: 'Ethereum is a decentralized blockchain that establishes a peer-to-peer network. It is not controlled by a single entity. Please try again',
        }],
      },
    },

    {
      rewardInUsd: 0.2,
      quizQuestionId: 'ETH-2',
      lessonIndex: 2,
      title: 'What is Ether?',
      parts: [{
        partIndex: 1,
        image: lessonImages.ethL2P1,
        content: 'Ethereum has a <strong>native cryptocurrency called Ether (ETH)</strong>. It is purely digital, and you can send it to anyone, anywhere in the world, almost instantly.',
      }, {
        partIndex: 2,
        image: lessonImages.ethL2P2,
        content: 'Currently, ETH is ranked second to bitcoin (eth) in market capitalization - making it the <strong>second-largest cryptocurrency in the world.</strong>',
      }, {
        partIndex: 3,
        image: lessonImages.ethL2P3,
        content: 'The supply of ETH isn’t controlled by any government or company. It is <strong>decentralized and completely transparent.</strong>',
      }, {
        partIndex: 4,
        image: lessonImages.ethL2P4,
        content: 'Unlike BTC, which has a maximum supply of 21 million, there is <strong>no limit or cap on the ETH supply</strong>. As of August 2022, there are 122,087,902 ETH that are in circulation.',
      }, {
        partIndex: 5,
        image: lessonImages.ethL2P5,
        content: 'Why do people use ETH?'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li><strong>It\'s really yours</strong> - You can control your own funds with your wallet as proof of ownership.</li>'
        + '<li><strong>Secured by cryptography</strong> - Internet money may be new, but it\'s secured by proven cryptography. This protects your wallet, your ETH, and your transactions.</li>'
        + '<li><strong>Peer-to-peer payments</strong> - You can send your ETH without any intermediary service like a bank.</li>'
        + '<li><strong>No centralized control</strong> - There\'s no company or bank that can decide to print more ETH, or change the terms of use.</li>'
        + '<li><strong>Open to anyone</strong> - You only need an internet connection and a wallet to accept ETH.</li>'
        + '<li><strong>Available in flexible amounts</strong> - ETH is divisible up to 18 decimal places so you don\'t have to buy 1 whole ETH.</li>'
        + '<ul>',
      }, {
        partIndex: 6,
        image: lessonImages.ethL2P6,
        content: 'On average, it usually takes anywhere from <strong>15 seconds to five minutes</strong> to process ETH transactions, depending on the amount you pay as a transaction fee and network congestion at the time',
      }],
      quiz: {
        question: 'Which of the following best describes ETH?',
        answer: 'A',
        options: [{
          option: 'A',
          description: 'ETH is the native cryptocurrency of the Ethereum blockchain. It is decentralized, transparent is and is currently ranked 2nd to BTC. It takes about 15 seconds to 5 minutes to process ETH transactions.',
          explanation: 'Correct answer!',
        }, {
          option: 'B',
          description: 'ETH is the native cryptocurrency of the Ethereum blockchain. Similar to BTC and other cryptocurrencies, ETH has a limited supply.',
          explanation: 'There is no supply limit or cap on ETH. Please try again.',
        }, {
          option: 'C',
          description: 'ETH is the native cryptocurrency of the Ethereum blockchain. Similar to fiat money, the supply of ETH is controlled by government offices.',
          explanation: 'The supply of ETH isn’t controlled by any government or company. It is purely decentralized. Please try again',
        }, {
          option: 'D',
          description: 'ETH is the native cryptocurrency of the Ethereum blockchain. You may only acquire 1 unit of ETH or more.',
          explanation: 'ETH is divisible up to 18 decimal places so you don\'t have to buy 1 whole ETH. Please try again',
        }],
      },
    },

    {
      rewardInUsd: 0.2,
      quizQuestionId: 'ETH-3',
      lessonIndex: 3,
      title: 'What Makes Ethereum Work?',
      parts: [{
        partIndex: 1,
        image: lessonImages.ethL3P1,
        content: 'Ethereum has several components that allow it to function the way it does. Among these components, however, <strong>ETH is considered as its lifeblood</strong>. It essentially fuels and secures the entire blockchain. When you send ETH or use an Ethereum application, you\'ll pay a fee in ETH to use the Ethereum network. This fee is an incentive for a block producer to process and verify what you\'re trying to do.',
      }, {
        partIndex: 2,
        image: lessonImages.ethL3P2,
        content: 'Ethereum also relies on <strong>blockchain technology</strong> to store and secure transactions - just like Bitcoin. Where Ethereum is different from Bitcoin, however, is that nodes don’t just have to verify and record transaction data, they also have to keep track of the network’s state.'
        + '<br/>'
        + '<br/>'
        + 'Ethereum’s state is the current information of all the applications running on top of it, including each user’s balance, all the smart contract code, where it’s all stored and any changes that are made.',
      }, {
        partIndex: 3,
        image: lessonImages.ethL3P3,
        content: 'Unlike Bitcoin, which uses a Proof-of-Work mechanism to validate transactions, Ethereum has shifted to a <strong>Proof-of-Stake (PoS)</strong> model for transaction validation. This involves locking your funds for a certain period of time to support the blockchain and validate transactions, and in return, you receive so-called staking rewards.',
      }, {
        partIndex: 4,
        image: lessonImages.ethL3P4,
        content: 'Ethereum is considered as the first blockchain to discover and implement <strong>smart contracts</strong> as part of the functionalities of blockchains. A smart contract is simply a programmable agreement that runs on a blockchain.'
        + '<br />'
        + '<br />'
        + 'Subsequently, this innovation unlocked more blockchain use cases and ultimately brought about the explosion of decentralized applications.',
      }, {
        partIndex: 5,
        image: lessonImages.ethL3P5,
        content: 'The <strong>Ethereum Virtual Machine (EVM)</strong> is Ethereum’s native processing system that allows developers to create smart contracts and lets nodes seamlessly interact with them.'
        + '<br />'
        + '<br />'
        + 'Ethereum developers write smart contracts with Solidity, a programming language much like Javascript and C++. These smart contracts written in Solidity can be read by humans, but not computers. It, therefore, has to be converted into low-level machine instructions – called opcodes – which the EVM can easily understand and execute.',
      }, {
        partIndex: 6,
        image: lessonImages.ethL3P6,
        content: 'How do users interact with Ethereum and its various components? Users interact with Ethereum via <strong>nodes</strong>. In other words, they have to connect their computers to Ethereum by downloading the blockchain software on their system (known as a “client”).'
        + '<br />'
        + '<br />'
        + 'Depending on the Ethereum software client you choose, you may need to download the full copy of the Ethereum blockchain.',
      }],
      quiz: {
        question: 'What Makes Ethereum Work?',
        answer: 'C',
        options: [{
          option: 'A',
          description: 'Several components come together to make Ethereum function the way it does, namely hashgraph, proof-of-work, smart contracts, ethereum virtual machine and ETH.',
          explanation: 'Ethereum is not based on hashgraph. Please try again.',
        }, {
          option: 'B',
          description: 'Several components come together to make Ethereum function the way it does, namely blockchain technology, proof-of-work, smart contracts, ethereum virtual machine and eth.',
          explanation: 'Ethereum relies on ETH, its native cryptocurrency, not BTC. It also uses a proof-of-stake mechanism, not proof-of-work. Please try again',
        }, {
          option: 'C',
          description: 'Several components come together to make Ethereum function the way it does, namely blockchain technology, proof-of-stake, smart contracts, ethereum virtual machine and ETH.',
          explanation: 'Correct answer!',
        }, {
          option: 'D',
          description: 'Several components come together to make Ethereum function the way it does, namely blockchain technology, Delegated Byzantine Fault Tolerance (dBFT), smart contracts, ethereum virtual machine and ETH.',
          explanation: 'Ethereum currently uses proof-of-stake, not dBFT. Please try again',
        }],
      },
    },

    {
      rewardInUsd: 0.2,
      quizQuestionId: 'ETH-4',
      lessonIndex: 4,
      title: 'What Innovations Are Attributed to Ethereum?',
      parts: [{
        partIndex: 1,
        image: lessonImages.ethL4P1,
        content: 'When the Ethereum network launched in 2015, it became the <strong>first project to expand the use cases of blockchains</strong> by introducing unique technologies that allowed people to create their own digital tokens and self-sustaining, autonomous applications.'
        + '<br />'
        + '<br />'
        + 'This innovation paved the way for a broad spectrum of markets including <strong>decentralized finance (DeFi), initial coin offerings (ICOs), stablecoins, non-fungible tokens (NFTs)</strong> and <strong>GameFi</strong>.',
      }, {
        partIndex: 2,
        image: lessonImages.ethL4P2,
        content: 'DeFi or decentralized finance is <strong>an umbrella term for financial services on public blockchains</strong> - often on Ethereum. With DeFi you can do most of the things that banks support — earn interest, borrow, lend, buy insurance, trade derivatives, trade assets, and more — but it’s faster and doesn’t require paperwork or a third party.'
        + '<br/>'
        + '<br/>'
        + 'As with crypto generally, DeFi is global, peer-to-peer (meaning directly between two people, not routed through a centralized system) and open to all.',
      }, {
        partIndex: 3,
        image: lessonImages.ethL4P3,
        content: 'ICO stands for initial coin offering and <strong>refers to a formerly popular method of fundraising capital for early-stage cryptocurrency projects</strong>. Ethereum’s ICO was one of the first real success stories using this relatively new type of fundraising mechanism, raising $15.5 million in 2014.',
      }, {
        partIndex: 4,
        image: lessonImages.ethL4P4,
        content: 'A stablecoin is a <strong>type of digital asset that maintains a stable value by tying it to another asset, such as gold or the U.S. dollar</strong>. Many stablecoins are issued on the Ethereum blockchain.'
        + '<br />'
        + '<br />'
        + 'As ERC-20 tokens, stablecoins on Ethereum have the benefits of other Ethereum tokens: they are efficient to produce and manage issuance; they have global reach making it easy to transact across borders; they are fully auditable; and importantly for decentralized finance, they are interoperable with the rest of Ethereum.',
      }, {
        partIndex: 5,
        image: lessonImages.ethL4P5,
        content: 'Non-fungible tokens (NFTs) are a <strong>special type of crypto asset that allows holders to prove their ownership of real or digital items.</strong>'
        + '<br />'
        + '<br />'
        + 'NFTs are digitally unique. No two NFTs are the same. Moreover, NFTs are compatible with anything built using Ethereum. An NFT ticket for an event can be traded on every Ethereum marketplace, for an entirely different NFT. You could trade a piece of art for a ticket!',
      }, {
        partIndex: 6,
        image: lessonImages.ethL4P6,
        content: 'GameFi is one of the hottest new trends to emerge from the crypto industry. <strong>It combines DeFi and NFTs with blockchain-based online games.</strong>'
        + '<br />'
        + '<br />'
        + 'Alien Worlds was the first game to break through 100,000 users and reached over 2.5 million players. This was, in part, due to its free-to-play nature and the fact that its metaverse world spans across multiple blockchains including Ethereum.',
      }],
      quiz: {
        question: 'What Innovations Are Attributed to Ethereum?',
        answer: 'D',
        options: [{
          option: 'A',
          description: 'Many innovations are attributed to Ethereum, which includes the birth of central decentralized finance (CeDeFi), initial coin offerings (ICOs), stablecoins, non-fungible tokens (NFTs) and GameFi.',
          explanation: 'CeDeFi is not directly attributed to Ethereum. Please try again',
        }, {
          option: 'B',
          description: 'Many innovations are attributed to Ethereum, which includes the birth of decentralized finance (DeFi), Web.3.0, initial coin offerings (ICOs), stablecoins, non-fungible tokens (NFTs) and GameFi.',
          explanation: 'Web 3.0 is not directly attributed to Ethereum. Please try again',
        }, {
          option: 'C',
          description: 'Many innovations are attributed to Ethereum, which includes the birth of decentralized finance (DeFi), initial coin offerings (ICOs), central bank digital currencies (CBDCs), non-fungible tokens (NFTs) and GameFi.',
          explanation: 'CDBCs are not attributed to Ethereum. Please try again',
        }, {
          option: 'D',
          description: 'Many innovations are attributed to Ethereum, which includes the birth of decentralized finance (DeFi), initial coin offerings (ICOs), stablecoins, non-fungible tokens (NFTs) and GameFi.',
          explanation: 'Correct answer!',
        }],
      },
    },

    {
      rewardInUsd: 0.2,
      quizQuestionId: 'ETH-5',
      lessonIndex: 5,
      title: 'What is the Ethereum Merge?',
      parts: [{
        partIndex: 1,
        image: lessonImages.ethL5P1,
        content: 'Over the years, industry experts have pointed out that Ethereum has many limitations and that there are several problems with the network. These concerns include the <strong>high gas fees that users need to pay to complete a transaction on the blockchain, the enormous amount of energy required to process transactions and the network’s inability to scale.</strong>',
      }, {
        partIndex: 2,
        image: lessonImages.ethL5P2,
        content: 'To address some of these concerns, the team behind Ethereum had scheduled an upgrade, which many refer to as <strong>The Merge.</strong>',
      }, {
        partIndex: 3,
        image: lessonImages.ethL5P3,
        content: 'To be more specific, The Merge:'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li>Is the merging of the Ethereum Mainnet with the Beacon Chain proof-of-stake system</li>'
        + '<li>Marked the end of PoW for Ethereum and the beginning of its full transition to proof-of-stake (PoS)</li>'
        + '<li>Is forecasted to reduce Ethereum\'s energy consumption by ~99.95%</li>'
        + '<li>Sets the stage for future scaling upgrades including sharding</li>'
        + '</ul>'
        ,
      }, {
        partIndex: 4,
        image: lessonImages.ethL5P4,
        content: 'What is sharding? Simply put, it:'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li>Is a multi-phase upgrade to improve Ethereum’s scalability and capacity</li>'
        + '<li>Provides secure distribution of data storage requirements, enabling rollups to be even cheaper, and making nodes easier to operate</li>'
        + '<li>Enables layer 2 solutions to offer low transaction fees while leveraging the security of Ethereum</li>'
        + '</ul>',
      }, {
        partIndex: 5,
        image: lessonImages.ethL5P5,
        content: 'To address misconceptions about The Merge, the team behind Ethereum explained that it does NOT:'
        + '<br />'
        + '<br />'
        + '<ul>'
        + '<li>Reduce gas fees</li>'
        + '<li>Significantly improve transaction speed</li>'
        + '<li>Enable staking withdrawals</li>'
        + '<li>Cause APR to increase by 200%</li>'
        + '</ul>',
      }, {
        partIndex: 6,
        image: lessonImages.ethL5P6,
        content: 'All in all, many consider The Merge as <strong>a historical event in the crypto space</strong>.'
        + '<br />'
        + '<br />'
        + 'On the one hand, miners are unhappy about the transition to PoS because it leaves them out of a revenue stream and rendering their mining rigs useless. On the other hand, crypto investors can now get these ETH rewards instead - for example by staking their ETH on Bake.',
      }],
      quiz: {
        question: 'What is the Ethereum Merge?',
        answer: 'A',
        options: [{
          option: 'A',
          description: 'The Merge is mainly the merging of the Ethereum Mainnet with the Beacon Chain proof-of-stake system. It did NOT reduce gas fees, significantly improve transaction speed, enable staking withdrawals or cause APR to increase by 200%.',
          explanation: 'Correct answer!',
        }, {
          option: 'B',
          description: 'The Merge is mainly the merging of the Ethereum Mainnet with the Beacon Chain proof-of-stake system. It did NOT reduce Ethereum’s energy consumption, gas fees or significantly improve transaction speed.',
          explanation: 'The Merge did reduce Ethereum’s energy consumption. Please try again.',
        }, {
          option: 'C',
          description: 'The Merge is mainly the merging of the Ethereum Mainnet with the Beacon Chain proof-of-stake system. It did not mark Ethereum’s transition to  proof-of-stake nor did it reduce gas fees.',
          explanation: 'The Merge DID mark the beginning of Ethereum’s transition to proof-of-stake. Please try again',
        }, {
          option: 'D',
          description: 'The Merge is mainly the merging of the Ethereum Mainnet with the Beacon Chain proof-of-stake system. It did NOT set the stage for future scaling upgrades nor did it reduce gas fees.',
          explanation: 'The Merge DID set the stage for future scaling upgrades, including sharding. Please try again',
        }],
      },
    },
  ],
};
