import React, { Dispatch, SetStateAction } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { CakeBanxaCreateOrder } from '@cakedefi/cake-sdk';
import BigNumberDisplay from '../../../../common/components/BigNumberDisplay/BigNumberDisplay';
import {
  SummaryEntry,
  SummaryTable,
} from '../../../../common/components/SummaryTable/SummaryTable';
import Button from '../../../../common/components/Button/Button';
import Timer from '../../../../common/components/Timer/Timer';
import styles from './BanxaPricePreview.styles';
import DisclaimerAgree from './DisclaimerAgree';
import fiatToBTCToDFIImage from '../../../../assets/svg/fiat-btc-dfi.svg';

const useModalStyles = createUseStyles(styles);
const BANXA_URL_VALID_SECONDS = 60;

interface BanxaPricePreviewProps {
  banxaOrder: CakeBanxaCreateOrder;
  fiatSwappableCoinId: string;
  priceValid: boolean;
  tncAgreement: React.ReactNode;
  userHasRead: boolean;
  setUserHasRead: Dispatch<SetStateAction<boolean>>;
  setPriceValid: Dispatch<SetStateAction<boolean>>;
  goBack: () => void;
}

export default function BanxaPricePreview({
  banxaOrder,
  fiatSwappableCoinId,
  priceValid,
  setPriceValid,
  goBack,
  userHasRead,
  setUserHasRead,
  tncAgreement,
}: BanxaPricePreviewProps) {
  const classes = useModalStyles({ withinValidity: priceValid });
  if (!banxaOrder || !fiatSwappableCoinId) {
    return null;
  }

  const {
    order: { coinCode, coinAmount, fiatAmount, fiatCode, checkoutUrl },
    swapTargetAmount,
  } = banxaOrder;

  const onClickCheckout = () => {
    window.location.href = checkoutUrl;
  };

  const onTimerFinish = () => {
    setPriceValid(false);
  };

  return (
    <>
      <div className={classes.centerContent}>
        <img src={fiatToBTCToDFIImage} alt="Fiat to BTC to DFI" />
      </div>
      <p className={classes.confirmText}>
        <Trans>Please confirm that you agree to the following amounts.</Trans>
      </p>
      <SummaryTable>
        <SummaryEntry
          label={<Trans>Amount of {{ coinCode }} to be bought</Trans>}
        >
          <BigNumberDisplay currency={coinCode}>{coinAmount}</BigNumberDisplay>
        </SummaryEntry>
        <SummaryEntry
          label={<Trans>Amount of {{ fiatSwappableCoinId }} to be received</Trans>}
        >
          <BigNumberDisplay currency={fiatSwappableCoinId}>
            {swapTargetAmount}
          </BigNumberDisplay>
        </SummaryEntry>
        <SummaryEntry label={<Trans>Total due</Trans>}>
          <BigNumberDisplay currency={fiatCode}>{fiatAmount}</BigNumberDisplay>
        </SummaryEntry>
      </SummaryTable>
      <div className={classes.validityText}>
        {priceValid && (
          <span>
            <Trans>Valid for </Trans>
            <Timer initialSecond={BANXA_URL_VALID_SECONDS - 5} onTimerFinish={onTimerFinish} />
          </span>
        )}
        {!priceValid && (
          <span>
            <Trans>Prices expired</Trans>
          </span>
        )}
      </div>
      <div className={classes.section}>
        <DisclaimerAgree
          userHasRead={userHasRead}
          setUserHasRead={setUserHasRead}
          tncAgreement={tncAgreement}
        />
      </div>
      <div className={`${classes.section} ${classes.actionContainer}`}>
        <div className={classes.rightContent}>
          {!priceValid && (
            <Button className="mr-3" btnLink onClick={goBack}>
              <Trans>Go back</Trans>
            </Button>
          )}
          <Button primary onClick={onClickCheckout} disabled={!priceValid || !userHasRead}>
            <Trans>Checkout</Trans>
          </Button>
        </div>
      </div>
    </>
  );
}
