import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  props: {},
};

const allocateModalSlice = createSlice({
  name: 'allocateModal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.show = true;
      state.props = action.payload;
    },
    hideModal: (state) => {
      state.show = false;
      state.props = {};
    },
  },
});

export const { showModal, hideModal } = allocateModalSlice.actions;

export default allocateModalSlice.reducer;
