import Cookies from 'universal-cookie';

function set(name, value, options = {}) {
  // Add secure to cookie options
  options.secure = true;
  try {
    const cookies = new Cookies();
    cookies.set(name, value, { ...options, path: '/' });
  } catch (error) {
    console.error(error);
  }
}

function get(name) {
  try {
    const cookies = new Cookies();
    return cookies.get(name);
  } catch (error) {
    console.error(error);
  }
}

function clear(name) {
  try {
    const cookies = new Cookies();
    return cookies.remove(name, { path: '/' });
  } catch (error) {
    console.error(error);
  }
}

const storageBase = {
  set,
  get,
  clear,
};

export default storageBase;
