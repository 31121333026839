import { getSVGRequiredPath } from './getSVGRequiredPath';

export const SVGNetworks = [
  'cardano',
  'bitcoin_cash',
  'defichain',
  'bitcoin',
  'defi_metachain',
  'ethereum',
  'disco',
  'dogecoin',
  'polkadot',
  'litecoin',
  'polygon',
  'mock',
  'near',
  'energi',
  'pivx',
  'safeinsure',
  'solana',
  'sui',
  'zcoin',
  'tron',
].reduce((acc, network) => {
  acc[network] = getSVGRequiredPath(`networks/icon-network-${network}`);
  return acc;
}, {});
