import { GenericState } from '../common/state';

export type FreezerCalculatorState = {
  inputCoin: string,
  inputCoinGroup: Shares,
  inputAmount: string,
  inputTenureIndex: number,
  isCanFreeze: boolean,
  freezerPromoCalcutator: GenericState<FreezerPromoCalculatar>
  error: string,
  loading: boolean,
  minimumAmountInUsd: number,
};

export type FreezerCoinInfoState = {
  inputCoin: string,
  inputCoinGroup: Shares,
  termType: string,
  fixedTermDuration: number,
  fixedTermAutoRenew: boolean,
}

type FreezerPromoCalculatar = {
  hasReachedPromoBonusCap: boolean,
  availableBonusToPayUsd: string,
}

export type FreezerAPYs = {
  baseApy: number,
  rebate: number,
  baseWithBonusApy: number,
};

export type FreezerAPYsAPRs = {
  baseApy: number,
  rebate: number,
  baseWithBonusApy: number,
  expiry: string,
  annualReturnAndAutoRenewWithAllDurations: any,
};

export type GraphDataSet = {
  label: string,
  data: number[],
  pointRadius: number[],
  pointBackgroundColor: string[],
  pointBorderWidth: number[],
  pointBorderColor: string[],
  backgroundColor: string,
  borderColor: string,
  borderWidth: number,
};

export type GraphDataPoint = {
  day: number,
  withoutBonus: string,
  withBonus: string,
};

export type GraphDataSelectorResult = {
  datasets: GraphDataSet[],
  selectedTenureCashflow: {
    withoutBonus: string,
    withBonus: string,
    currency: string,
    decimals: number,
  },
  selectedTenureAssetCurrency: {
    withBonusAssetCurrency: string,
    withoutBonusAssetCurrency: string,
  }
}

export enum Shares {
  STAKING = 'Staking shares',
  LIQUIDITY_MINING = 'Liquidity mining shares',
}
