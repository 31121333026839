import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initLanguageFromBrowserSettings, getPreferredLanguage, setAllLanguages } from './languageRegionSettingsUtils';
import sdk from '../../sdk';
import { fetchUserDetails } from '../userSlice';
import { ThunkApiConfig } from '../../app/rootReducer';

export const updateLanguageRegionPreference = createAsyncThunk<void, void, ThunkApiConfig>(
  'user/updateLanguageRegionPreference',
  async (_, thunkAPI) => {
    const { selectedLanguage, selectedCurrency } = thunkAPI.getState().languageRegionSettings;
    const { details } = thunkAPI.getState().user;

    const preferredLanguage = getPreferredLanguage(selectedLanguage);
    setAllLanguages(selectedLanguage);

    if (details && details.role === 'GENERAL') {
      await sdk.UserInfoApi.updatePreference({
        preferredLanguage,
        preferredCurrency: selectedCurrency,
      });
      await thunkAPI.dispatch(fetchUserDetails());
    }
  },
);

export const updateLanguagePreference = createAsyncThunk<void, void, ThunkApiConfig>(
  'user/updateLanguagePreference',
  async (language, thunkAPI) => {
    const { details } = thunkAPI.getState().user;

    const preferredLanguage = getPreferredLanguage(language);
    setAllLanguages(language);

    if (details && details.role === 'GENERAL') {
      await sdk.UserInfoApi.updatePreference({
        preferredLanguage,
      });
      await thunkAPI.dispatch(fetchUserDetails());
    }
  },
);

interface LanguageRegionSettingsState {
  selectedLanguage: string;
  selectedCurrency: string;
}

const initialState: LanguageRegionSettingsState = {
  selectedLanguage: initLanguageFromBrowserSettings(),
  selectedCurrency: 'USD',
};

const languageRegionSettingsSlice = createSlice({
  name: 'languageRegionSettings',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
  },
});

export const { setSelectedLanguage, setSelectedCurrency } = languageRegionSettingsSlice.actions;

export default languageRegionSettingsSlice.reducer;
