import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import React from 'react';
import { createUseStyles } from 'react-jss';
import mobilePhoneImage from 'assets/svg/mobile-interface-screen.svg';
import mobileAppQr from 'assets/svg/mobile-download-qr.svg';

import { Trans } from 'react-i18next';

const useStyles = createUseStyles({
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 800,
    marginRight: '100px',
  },
  title: {
    fontSize: Variables.fontSize.giant,
    lineHeight: 'normal',
  },
  subtitle: {
    fontSize: Variables.fontSize.h0,
    fontWeight: Variables.fontWeight.bold,
    lineHeight: 'normal',
  },
  qrContainer: {
    display: 'flex',
    gap: Variables.medium,
    flexDirection: 'row',
    alignItems: 'center',
  },
  qrText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: Variables.fontSize.extraBase,
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  mobileImg: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    pageContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    descriptionContainer: {
      padding: Variables.large,
      marginBottom: Variables.larger,
      marginRight: 0,
    },
  },
});

const MobileDeepLinkPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <div className={classes.imgWrapper}>
        <img src={mobilePhoneImage} className={classes.mobileImg} alt="mobile phone interface" />
      </div>
      <div className={classes.descriptionContainer}>
        <h1 className={classes.title}><Trans>The page you are trying to enter is only available in the Bake app</Trans></h1>
        <p className={classes.subtitle}><Trans>Discover new ways to build and grow your digital assets portfolio. Trusted by over 1 million Bake users.</Trans></p>
        <div className={classes.qrContainer}>
          <img src={mobileAppQr} alt="mobile app qr" />
          <p className={classes.qrText}><Trans>Scan to download the app</Trans><br/><Trans>iOS & Android</Trans></p>
        </div>
      </div>
    </div>
  );
};

export default MobileDeepLinkPage;
