import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { storage } from 'storage/sessionStorage';
import sdk from '../../sdk';
import AccountVerificationStagesEnum from './AccountVerificationStagesEnums';

export const COUNTRIES_WITHOUT_ZIPCODE = ['HK', 'AE'];
export const NATIONALITIES_WITH_FULL_CHINESE_NAME = ['HK', 'TW', 'CN', 'MO'];

export const fetchExistingKycEntry = createAsyncThunk(
  'accountVerification/fetchExistingKycEntry',
  async () => {
    const response = await sdk.UserInfoApi.getVerifiedKycLiteData();
    return response;
  },
);

const initialState = {
  stage: AccountVerificationStagesEnum.MOBILE_NUMBER_INPUT,
  nextStage: null,
  isShowKycBanner: true,
  hasKycEntry: false,
  hasFinishedAStep: false,
  codeSendId: null,
  codeCallId: null,
  hasFullChineseNameField: false,
  rejectionType: null,
  inputs: {
    firstName: '',
    lastName: '',
    aliasName: '',
    middleName: '',
    fullChineseName: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
    nationality: '',
    addressLine1: '',
    addressLine2: '',
    countryState: '',
    country: '',
    zipCode: '',
    mobileNumber: '',
    phoneCountryCode: '',
    identityDocument: {
      type: '',
      expiryDate: {
        day: '',
        month: '',
        year: '',
      },
      frontImage: '',
      backImage: '',
      status: 'UNCHECKED',
    },
    proofOfAddress: {
      frontImage: '',
      status: 'UNCHECKED',
    },
    selfie: {
      frontImage: '',
      status: 'UNCHECKED',
    },
  },
};

const accountVerificationSlice = createSlice({
  name: 'accountVerification',
  initialState,
  reducers: {
    setIsShowKycBanner: (state, action: PayloadAction<boolean>) => {
      state.isShowKycBanner = action.payload;
      storage.setItem('homepageKycBannerBannerDismissed', !action.payload);
    },
    setCodeSendId: (state, action) => {
      state.codeSendId = action.payload;
    },
    setCodeCallId: (state, action) => {
      state.codeCallId = action.payload;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setNextStage: (state, action) => {
      state.nextStage = action.payload;
    },
    resetStage: (state) => {
      state.stage = initialState.stage;
      state.nextStage = initialState.nextStage;
    },
    setFirstName: (state, action) => {
      state.inputs.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.inputs.lastName = action.payload;
    },
    setDobDay: (state, action) => {
      state.inputs.dateOfBirth.day = action.payload;
    },
    setDobMonth: (state, action) => {
      state.inputs.dateOfBirth.month = action.payload;
    },
    setDobYear: (state, action) => {
      state.inputs.dateOfBirth.year = action.payload;
    },
    setNationality: (state, action) => {
      state.inputs.nationality = action.payload;
      if (NATIONALITIES_WITH_FULL_CHINESE_NAME.includes(action.payload)) {
        state.hasFullChineseNameField = true;
      } else {
        state.hasFullChineseNameField = false;
        state.inputs.fullChineseName = '';
      }
    },
    setAddressLine1: (state, action) => {
      state.inputs.addressLine1 = action.payload;
    },
    setAddressLine2: (state, action) => {
      state.inputs.addressLine2 = action.payload;
    },
    setCountryState: (state, action) => {
      state.inputs.countryState = action.payload;
    },
    setCountry: (state, action) => {
      state.inputs.country = action.payload;
      if (COUNTRIES_WITHOUT_ZIPCODE.includes(action.payload)) {
        state.inputs.zipCode = 'N/A';
      } else {
        state.inputs.zipCode = '';
      }
      if (action.payload !== 'US') state.inputs.countryState = '';
    },
    setZipCode: (state, action) => {
      state.inputs.zipCode = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.inputs.mobileNumber = action.payload;
    },
    setPhoneCountryCode: (state, action) => {
      state.inputs.phoneCountryCode = action.payload;
    },
    setIdType: (state, action) => {
      state.inputs.identityDocument.type = action.payload;
    },
    setIdExpiryDay: (state, action) => {
      state.inputs.identityDocument.expiryDate.day = action.payload;
    },
    setIdExpiryMonth: (state, action) => {
      state.inputs.identityDocument.expiryDate.month = action.payload;
    },
    setIdExpiryYear: (state, action) => {
      state.inputs.identityDocument.expiryDate.year = action.payload;
    },
    clearIdExpiry: (state) => {
      state.inputs.identityDocument.expiryDate = initialState.inputs.identityDocument.expiryDate;
    },
    setIdFrontImage: (state, action) => {
      state.inputs.identityDocument.frontImage = action.payload;
    },
    setIdBackImage: (state, action) => {
      state.inputs.identityDocument.backImage = action.payload;
    },
    setSelfieImage: (state, action) => {
      state.inputs.selfie.frontImage = action.payload;
    },
    setProofOfAddressFrontImage: (state, action) => {
      state.inputs.proofOfAddress.frontImage = action.payload;
    },
    setHasFinishedAStep: (state, action) => {
      state.hasFinishedAStep = action.payload;
    },
    setAliasName: (state, action) => {
      state.inputs.aliasName = action.payload;
    },
    setFullChineseName: (state, action) => {
      state.inputs.fullChineseName = action.payload;
    },
    setMiddleName: (state, action) => {
      state.inputs.middleName = action.payload;
    },
    setHasKycEntry: (state, action) => {
      state.hasKycEntry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExistingKycEntry.fulfilled, (state, action) => {
      if (action.payload.data) {
        const {
          firstName,
          lastName,
          middleName,
          aliasName,
          fullChineseName,
          dateOfBirth: dobString,
          nationality,
          country,
          addressLine1,
          addressLine2,
          countryState,
          zipCode,
          idStatus,
          poaStatus,
          selfieStatus,
          rejectionType,
        } = action.payload.data;
        const dateOfBirth = moment(dobString).utc(true);
        state.inputs.firstName = firstName || '';
        state.inputs.lastName = lastName || '';
        state.inputs.middleName = middleName || '';
        state.inputs.aliasName = aliasName || '';
        state.inputs.fullChineseName = fullChineseName || '';
        state.inputs.dateOfBirth.day = dateOfBirth.format('D');
        state.inputs.dateOfBirth.month = dateOfBirth.format('MM');
        state.inputs.dateOfBirth.year = dateOfBirth.format('YYYY');
        state.inputs.nationality = nationality || '';
        state.inputs.country = country || '';
        state.inputs.addressLine1 = addressLine1 || '';
        state.inputs.addressLine2 = addressLine2 || '';
        state.inputs.countryState = countryState || '';
        state.inputs.zipCode = zipCode || '';
        state.hasKycEntry = true;
        state.inputs.identityDocument.status = idStatus || 'UNCHECKED';
        state.inputs.proofOfAddress.status = poaStatus || 'UNCHECKED';
        state.inputs.selfie.status = selfieStatus || 'UNCHECKED';
        state.rejectionType = rejectionType;
      } else {
        state.hasKycEntry = false;
        state.rejectionType = null;
      }
    });
  },
});

export const {
  setIsShowKycBanner,
  setCodeSendId,
  setCodeCallId,
  setStage,
  setNextStage,
  resetStage,
  setFirstName,
  setLastName,
  setMiddleName,
  setAliasName,
  setFullChineseName,
  setDobDay,
  setDobMonth,
  setDobYear,
  setNationality,
  setAddressLine1,
  setAddressLine2,
  setCountryState,
  setCountry,
  setZipCode,
  setMobileNumber,
  setPhoneCountryCode,
  setIdType,
  setIdExpiryDay,
  setIdExpiryMonth,
  setIdExpiryYear,
  clearIdExpiry,
  setIdFrontImage,
  setIdBackImage,
  setSelfieImage,
  setProofOfAddressFrontImage,
  setHasFinishedAStep,
  setHasKycEntry,
} = accountVerificationSlice.actions;

export default accountVerificationSlice.reducer;
