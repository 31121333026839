import { CurrencyRates } from '@cakedefi/cake-sdk';
import BigNumber from 'bignumber.js';

export default function convertUSDToPreferredCurrency(amountInUSD: string | BigNumber | number, btcPrice: string, preferredCurrency: string, currencyRates: CurrencyRates['rates']) {
  const amountInUSDBn = amountInUSD instanceof BigNumber ? amountInUSD : new BigNumber(amountInUSD);

  switch (preferredCurrency) {
    case 'USD': {
      return amountInUSDBn.toFixed();
    }
    case 'BTC': {
      return amountInUSDBn.dividedBy(btcPrice).toFixed();
    }
    default: {
      if (currencyRates) {
        const foreignCurrencyRate = currencyRates[preferredCurrency];
        return amountInUSDBn.multipliedBy(foreignCurrencyRate).toFixed();
      }
      return amountInUSDBn.toFixed();
    }
  }
}
