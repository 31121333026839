import {
  GenericState,
  StateStatus,
  TransactionsQueueWithCount,
} from '@cakedefi/cake-sdk';
import getOffset from '@cakedefi/cake-sdk/util/getOffset';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import appConfig from 'config/app';
import sdk from 'sdk';

export const { DEFAULT_LISTING_PAGE_SIZE: TRANSACTIONS_PER_PAGE } = appConfig;

interface TransactionQueueState extends GenericState<TransactionsQueueWithCount> {
  page: number;
}

const initialState: TransactionQueueState = {
  status: StateStatus.STARTED,
  data: {
    count: null,
    rows: [],
  },
  error: null,
  page: 1,
};

export const fetchTransactionsQueueWithCount = createAsyncThunk<
  TransactionsQueueWithCount,
  { page: number },
  ThunkApiConfig
>('transactionQueue/fetchTransactionsQueueWithCount', ({ page = 1 }, thunkApi) => {
  thunkApi.dispatch(setPage(page));
  const offset = getOffset(page, TRANSACTIONS_PER_PAGE);
  return sdk.WalletApi.listTransactionsQueue({
    offset,
    limit: TRANSACTIONS_PER_PAGE,
  });
});

const transactionQueueSlice = createSlice({
  name: 'transactionQueue',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionsQueueWithCount.pending, (state) => {
      state.status = StateStatus.LOADING;
      state.error = null;
    });

    builder.addCase(fetchTransactionsQueueWithCount.fulfilled, (state, action) => {
      state.status = StateStatus.FINISHED;
      state.data = action.payload;
    });

    builder.addCase(fetchTransactionsQueueWithCount.rejected, (state, action) => {
      state.status = StateStatus.ERROR;
      state.error = action.error;
    });
  },
});

export const { setPage } = transactionQueueSlice.actions;

export default transactionQueueSlice.reducer;
