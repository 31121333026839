import { LiquidityMiningPage, LiquidityMiningPageAuthenticated } from '@cakedefi/cake-sdk';
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import sdk from '../sdk';

interface LiquidityMiningPageState extends LiquidityMiningPageAuthenticated {
  loading: boolean;
  error?: SerializedError;
}

const initialState: LiquidityMiningPageState = {
  liquidityMiningPairs: null,
  currentAddLiquidityPrices: null,
  currentLiquidityPricesOnDefichain: null,
  totalLiquidityShares: null,
  totalLiquiditySharesForUser: null,
  totalEarnings: null,
  loading: null,
  error: null,
};

export const fetchLiquidityMiningPage = createAsyncThunk<LiquidityMiningPage>(
  'liquidityMining/fetchLiquidityMiningPage',
  () => sdk.LiquidityMiningApi.liquidityMiningPage(),
);

export const fetchLiquidityMiningPageAuthenticated = createAsyncThunk<LiquidityMiningPageAuthenticated>(
  'liquidityMining/fetchLiquidityMiningPageAuthenticated',
  async () => {
    const { totalLiquiditySharesForUser, ...rest } = await sdk.LiquidityMiningApi.liquidityMiningPageAuthenticated();
    return ({
      ...rest,
      totalLiquiditySharesForUser: totalLiquiditySharesForUser.filter(item => !!(+item?.totalShares)), // cleanup from LM with zero balance
    });
  },
);

const liquidityMiningSlice = createSlice({
  name: 'liquidityMining',
  initialState,
  reducers: {
    fetchUserLiquidityMiningBalance: (state, action) => {
      state.liquidityMiningPairs = action.payload.liquidityMiningPairs;
      state.currentLiquidityPricesOnDefichain = action.payload.currentLiquidityPricesOnDefichain;
      state.totalLiquiditySharesForUser = action.payload.totalLiquiditySharesForUser;
    },
    updateLiquidityBalanceLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLiquidityMiningPage.fulfilled, (state, action) => {
      state.liquidityMiningPairs = action.payload.liquidityMiningPairs;
      state.currentAddLiquidityPrices = action.payload.currentAddLiquidityPrices;
      state.totalLiquidityShares = action.payload.totalLiquidityShares;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchLiquidityMiningPageAuthenticated.fulfilled, (state, action) => {
      state.liquidityMiningPairs = action.payload.liquidityMiningPairs;
      state.currentAddLiquidityPrices = action.payload.currentAddLiquidityPrices;
      state.currentLiquidityPricesOnDefichain = action.payload.currentLiquidityPricesOnDefichain;
      state.totalLiquidityShares = action.payload.totalLiquidityShares;
      state.totalLiquiditySharesForUser = action.payload.totalLiquiditySharesForUser;
      state.totalEarnings = action.payload.totalEarnings;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchLiquidityMiningPage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchLiquidityMiningPageAuthenticated.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchLiquidityMiningPage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(fetchLiquidityMiningPageAuthenticated.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { fetchUserLiquidityMiningBalance, updateLiquidityBalanceLoading } = liquidityMiningSlice.actions;

export default liquidityMiningSlice.reducer;
