import { DcaProductsResponse, DcaSessionRequest, DcaSessionResponse, DcaReferralCampaignTotalTickets, DcaUserInfo, DcaReferralCampaignUserTickets, DcaReferralCampaignUserReferral, DcaReferralCampaignLeaderboard, DcaReferralCampaignTotalUsers } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class DcaApi extends CakeApiBase {
  async createCheckoutSession(opts: DcaSessionRequest) {
    return this.requestManager.post<DcaSessionResponse>('/dca/create-checkout-session', opts);
  }

  async getUserInfo() {
    return this.requestManager.get<DcaUserInfo>('/dca/get-user-info');
  }

  async getProducts() {
    return this.requestManager.get<DcaProductsResponse>('/dca/get-products');
  }

  async getCustomerPortalSession() {
    return this.requestManager.get<DcaSessionResponse>('/dca/get-customer-portal-session');
  }

  async getReferralCampaignUserTickets() {
    return this.requestManager.get<DcaReferralCampaignUserTickets>('/dca/get-user-tickets');
  }

  async getReferralCampaignTotalTickets() {
    return this.requestManager.get<DcaReferralCampaignTotalTickets>('/dca/get-total-tickets');
  }

  async getReferralCampaignUserReferrals() {
    return this.requestManager.get<DcaReferralCampaignUserReferral>('/dca/get-referral-rewards');
  }

  async getReferralCampaignLeaderboard() {
    return this.requestManager.get<DcaReferralCampaignLeaderboard>('/dca/get-leaderboard');
  }

  async getReferralCampaignTotalUsers() {
    return this.requestManager.get<DcaReferralCampaignTotalUsers>('/dca/get-total-unique-dca-users');
  }
}
