import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from '../sdk';
import { getOffset } from '../utils';
import { ALL_COINS_VALUE } from './transactionPageUtils';

const TRANSACTIONS_PAGE_PAGE = 100;

const initialState = {
  transactions: null,
  hasMore: false,
  isLoading: false,
  page: 1,
  selectedCoin: ALL_COINS_VALUE,
};

export const fetchTransactions = createAsyncThunk('transactionPage/fetchTransactions', (_, thunkAPI) => {
  const { page, selectedCoin } = thunkAPI.getState().transactions;
  const coinIds = selectedCoin !== ALL_COINS_VALUE ? [selectedCoin] : [];
  if (coinIds[0] === 'ETH') {
    coinIds.push('csETH');
  }
  const offset = getOffset(page, TRANSACTIONS_PAGE_PAGE);
  return sdk.WalletApi.listTransactions({
    offset,
    limit: TRANSACTIONS_PAGE_PAGE,
    coinIds,
  });
});

const transactionPageSlice = createSlice({
  name: 'transactionPage',
  initialState,
  reducers: {
    changeCoin: (state, action) => {
      state.selectedCoin = action.payload;
    },
    setNextPage: (state) => {
      state.page += 1;
    },
    resetTransactions: (state) => {
      state.transactions = null;
      state.page = 1;
    },
    clear: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchTransactions.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTransactions.fulfilled]: (state, action) => {
      const transactions = action.payload;
      state.transactions = state.transactions ? [...state.transactions, ...transactions] : transactions;
      state.hasMore = !!transactions.length;
      state.isLoading = false;
    },
    [fetchTransactions.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  changeCoin,
  setNextPage,
  resetTransactions,
  clear: clearTransactionPageSlice,
} = transactionPageSlice.actions;

export default transactionPageSlice.reducer;
