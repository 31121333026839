import Variables from '../../Variables.styles';

export default {
  inlineButton: {
    display: 'inline-block',
  },
  buttonGroup: {
    display: 'flex',
  },
  buttonGroupLeft: {
    marginRight: Variables.medium,
  },
  inlineLinkButton: {
    composes: ['btn', 'btn-link'],
    display: 'inline-block',
  },
  inlinePrimaryButton: {
    composes: ['btn'],
    display: 'inline-block',
  },
};
