import { FreezerPage, FreezerPageAuthenticated } from '@cakedefi/cake-sdk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../sdk';

export const fetchFreezerPageAuthenticated = createAsyncThunk(
  'freezer/fetchFreezePage',
  async (): Promise<FreezerPageAuthenticated> => {
    const freezerResponse = await sdk.FreezerApi.freezerPageAuthenticated();
    const { rows: allFreezes } = await sdk.FreezerApi.getFreezerActions(freezerResponse.newFreezesCount, 0);
    return {
      ...freezerResponse,
      newFreezes: allFreezes,
    };
  },
);

export const fetchFreezerPage = createAsyncThunk(
  'freezer/fetchFreezerPage',
  async (): Promise<FreezerPage> => sdk.FreezerApi.freezerPage(),
);
