import { createSlice } from '@reduxjs/toolkit';
import { FORMAT_CSV, calculateDateRangeFromInput, resetQuickRanges, updateItems, generateRangeByYear, isPerformTaxExport } from './exportTransactionModalService';

export const quickRanges = [
  {
    label: 'All time',
    selected: true,
  },
  {
    label: 'Last 12 months',
    selected: false,
    months: 12,
  },
  {
    label: 'Last 6 months',
    selected: false,
    months: 6,
  },
  {
    label: 'Last 3 months',
    selected: false,
    months: 3,
  },
  ...getRangesByYearUntilCurrentYear(2019),
];

function getRangesByYearUntilCurrentYear(startYear: number) {
  const ranges = [];

  const yearsCount = new Date().getFullYear() - startYear;
  for (let i = 0; i <= yearsCount; i += 1) {
    ranges.push(generateRangeByYear(startYear + i));
  }
  return ranges;
}

const initialState = {
  selectAllCoins: false,
  coins: null,
  selectAllLmPairs: false,
  lmPairs: null,
  startDate: null,
  endDate: null,
  format: FORMAT_CSV,
  currency: 'USD',
  quickRanges,
};

const exportTransactionModalSlice = createSlice({
  name: 'exportTransactionModal',
  initialState,
  reducers: {
    setCoins: (state, action) => {
      const { coins, selectedCoin } = action.payload;
      state.coins = coins.map(({ id }) => ({ id, checked: selectedCoin ? id === selectedCoin : true }));
    },
    toggleCoinSelectAll: (state, action) => {
      const { coins } = state;
      const { selectAll, indeterminate } = action.payload;
      if (indeterminate) {
        state.selectAllCoins = false;
        state.coins = updateItems(coins, false);
        return;
      }
      state.selectAllCoins = !selectAll;
      state.coins = updateItems(coins, state.selectAllCoins);
    },
    toggleCoin: (state, action) => {
      const coinId = action.payload;
      const idx = state.coins.findIndex(({ id }) => id === coinId);
      if (idx !== -1) {
        state.coins[idx].checked = !state.coins[idx].checked;
      }
    },
    setLmPairs: (state, action) => {
      state.lmPairs = action.payload;
    },
    togglePair: (state, action) => {
      const pairId = action.payload;
      const idx = state.lmPairs.findIndex(({ id }) => id === pairId);
      if (idx !== -1) {
        state.lmPairs[idx].checked = !state.lmPairs[idx].checked;
      }
    },
    togglePairSelectAll: (state, action) => {
      const { lmPairs } = state;
      const { selectAll, indeterminate } = action.payload;
      if (indeterminate) {
        state.selectAllLmPairs = false;
        state.lmPairs = updateItems(lmPairs, false);
        return;
      }
      state.selectAllLmPairs = !selectAll;
      state.lmPairs = updateItems(lmPairs, state.selectAllLmPairs);
    },
    selectQuickRange: (state, action) => {
      const idx = action.payload;
      resetQuickRanges(state);
      if (idx !== -1) {
        state.quickRanges[idx].selected = true;
        const range = state.quickRanges[idx];
        const { start, end } = calculateDateRangeFromInput(range);
        state.startDate = start;
        state.endDate = end;
      }
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
      resetQuickRanges(state);
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
      resetQuickRanges(state);
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setFormat: (state, action) => {
      state.format = action.payload;
      const { format, coins, lmPairs } = state;
      const isTaxExport = isPerformTaxExport(format);
      if (isTaxExport) {
        state.coins = updateItems(coins, true);
        state.lmPairs = updateItems(lmPairs, true);
      }
    },
    clear: () => ({ ...initialState }),
  },
});

export const {
  setCoins,
  toggleCoin,
  toggleCoinSelectAll,
  setLmPairs,
  togglePair,
  togglePairSelectAll,
  selectQuickRange,
  setStartDate,
  setEndDate,
  setCurrency,
  setFormat,
  clear: clearExportTransactionModalSlide,
} = exportTransactionModalSlice.actions;

export default exportTransactionModalSlice.reducer;
