import Variables from '../../../Variables.styles';

export default {
  cardWrapper: ({ padding, overflow, rounded }) => ({
    backgroundColor: Variables.white,
    borderRadius: `${rounded}px`,
    boxShadow: Variables.primaryBoxShadow,
    border: Variables.primaryBorder,
    padding,
    overflow,
    '&.active': {
      boxShadow: `0 0 0 2px ${Variables.electricBerry}`,
      color: Variables.electricBerry,
    },
    '&.disabled': {
      cursor: 'auto',
      pointerEvents: 'none',
      opacity: 0.5,
    },
  }),
};
