import React from 'react';
import { createUseStyles } from 'react-jss';
import { BannerType } from '../../../types/banner/banner';
import styles from './Banner.styles';

const useStyles = createUseStyles<string, { type: BannerType; bannerBackgroundColor: string; justifyContent: string; contentMarginLeft?: string; flexBoxForContainer?: boolean}>(
  styles,
);

interface BannerProps {
  children: React.ReactNode;
  actionTemplate?: React.ReactNode;
  type: BannerType;
  bannerBackgroundColor?: string;
  justifyContent?: string;
  contentMarginLeft?: string;
  flexBoxForContainer?: boolean;
}

const Banner = ({
  children,
  actionTemplate,
  type,
  bannerBackgroundColor,
  justifyContent = 'center',
  contentMarginLeft,
  flexBoxForContainer = false,
}: BannerProps) => {
  const classes = useStyles({
    type, bannerBackgroundColor, justifyContent, contentMarginLeft, flexBoxForContainer,
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        {children}
      </div>
      {
        actionTemplate
        && <div className={classes.action}>
          {actionTemplate}
        </div>
      }
    </div>
  );
};

export default Banner;
