import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ModalStyles from 'common/styles/Modal.styles';
import { ModalContent, ModalHeader } from 'modals';
import { ModalContext } from 'modals/ModalContext';
import { useCakeSelector } from 'app/rootReducer';

const useStyles = createUseStyles({
  ...ModalStyles,
  paragraph: {
    fontSize: '1.6rem',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
});

const getFirstDepositLink = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'https://support.bake.io/en/articles/8286166-how-can-i-deposit-cryptocurrencies-from-external-wallets-to-bake';
    case 'it':
      return 'https://support.bake.io/it/articles/8286166-come-posso-depositare-criptovalute-da-portafogli-esterni-a-bake';
    case 'zht':
      return 'https://support.bake.io/zh-TW/articles/360035938492';
    default:
      return 'https://support.bake.io/en/articles/8286166-how-can-i-deposit-cryptocurrencies-from-external-wallets-to-bake';
  }
};

const ElevenElevenRulesModalContent = () => {
  const { closeModal } = useContext(ModalContext);
  const { selectedLanguage } = useCakeSelector(state => state.languageRegionSettings);
  const classes = useStyles();

  return (
    <>
      <ModalHeader title='11.11 Promo FAQ' onClose={closeModal}/>
      <ModalContent>
        <div className={classes.contentMini}>
          <ol className={classes.paragraph}>
            <li>
              <b><Trans>When does the promotion start?</Trans></b>
            </li>
            <Trans>The Double 11 Day promotion will commence at <b>05:00 (UTC) on 11th November 2021</b> and will run until <b>05:00 (UTC) on 25th November 2021.</b></Trans>
            <br/>
            <br/>

            <li>
              <b><Trans>Who is eligible for the extra $11 DFI referral bonus?</Trans></b>
            </li>
            <Trans>The extra $11 in DFI referral bonus can be redeemed by any existing Bake customer who successfully refers a new user (identity verified with a minimum $50 deposit) from 05:00 UTC on 11th November2021 to 05:00 UTC on 25th November 2021.</Trans>
            <br/>
            <br/>

            <li>
              <b><Trans>Where can I find my referral bonus?</Trans></b>
            </li>
            <Trans>Your referral bonus will be allocated into the Freezer product for a duration of 180 days. You can see it by navigating to “Freezer” in the top menu from your Bake account.</Trans>

            <br/>
            <br/>

            <li>
              <b><Trans>How long is my bonus frozen?</Trans></b>
            </li>
            <Trans>Your deposit bonus will be entered into the Freezer product in your Bake account for a duration of 180 days. During this time, it will also generate staking returns. Staking returns are always freely available to do with as you please.</Trans>
            <br/>
            <br/>

            <li>
              <b><Trans>My referee didn’t receive their 11% extra deposit bonus?</Trans></b>
            </li>
            <Trans>Please follow the instructions Q.1 - Q.3 of section outlined the 11% bonus related FAQs section. They must also enter their first-time deposit into one of the following Bake products for a minimum of 90 days:</Trans>
            <br/>
            <br/>
            <ul className={classes.paragraph}>
              <li><Trans>Staking</Trans></li>
              <li><Trans>Lending</Trans></li>
              <li><Trans>Liquidity Mining</Trans></li>
              <li><Trans>Freezer</Trans></li>
            </ul>
            <br/>
            <Trans>Their 11% extra DFI deposit bonus will be automatically allocated into the Freezer product for 180 days. During this time, it will also generate staking returns. Staking returns are always freely available to do with as you please.</Trans>
            <br/>
            <br/>
            <Trans>If your referee withdraws their first time deposit from Bake before the 90 days, you will lose their 11% deposit bonus.</Trans>
          </ol>
          <br/>

          <b><Trans>11% BONUS RELATED FAQs:</Trans></b>
          <br/>
          <br/>

          <ol className={classes.paragraph}>
            <li><b><Trans>Who is eligible for the 11% deposit bonus?</Trans></b></li>
            <ul className={classes.paragraph}>
              <li><Trans>Customers that have completed KYC verification and have not yet made a first deposit into their Bake account.</Trans></li>
              <li><Trans>Customers that have not completed KYC verification and have not yet made a first deposit into their Bake account.</Trans></li>
            </ul>
            <br/>

            <li><b><Trans>What to do next after completing the KYC verification?</Trans></b></li>
            <ul className={classes.paragraph}>
              <li><Trans>All new Bake customers who complete registration, KYC verification and make a first-time deposit between 05:00 UTC on 11th November 2021 to 05:00 UTC on 25th November 2021.</Trans></li>
              <li><Trans>A customer’s first time deposit must be used to enter Staking, Lending, Liquidity Mining and / or Freezer, and remain on the platform for 90 days consecutively in order to receive the 11% bonus.</Trans></li>
              <li><Trans>For first-time deposit, the minimum amount is $50 in crypto and you will receive $5.5 bonus (worth of DFI). If you make first-time deposit up to $1,000, you will receive $110 bonus (worth of DFI). The 11% bonus is capped up to $110 worth of DFI, for a maximum first-time deposit value of $1,000.</Trans></li>
              <li><Trans>Customers that have already received a sign-up bonus are <b>not eligible</b> for the 11% deposit bonus.</Trans></li>
            </ul>
            <br/>

            <li><b><Trans>Where can I find my 11% extra deposit bonus</Trans></b></li>
            <Trans>In order to receive your 11% extra DFI deposit bonus, you must complete your registration, KYC (identity verification) and make a minimum first deposit of $50 into your Bake account before 05:00 UTC on 25th November 2021. You must also allocate your first-time deposit into one of the following Bake products for a minimum of 90 days:</Trans>
            <br/>
            <br/>
            <ul className={classes.paragraph}>
              <li><Trans>Staking</Trans></li>
              <li><Trans>Lending</Trans></li>
              <li><Trans>Liquidity Mining</Trans></li>
              <li><Trans>Freezer</Trans></li>
            </ul>
            <br/>
            <Trans>Your 11% extra DFI deposit bonus will be automatically allocated into the Freezer product for 180 days. During this time, it will also generate staking returns. Staking returns are always freely available to do with as you please.</Trans>
            <br/>
            <br/>
            <Trans>If you withdraw your first time deposit from Bake before the 90days, you will lose your 11% deposit bonus.</Trans>

            <br/>
            <br/>
            <li><b><Trans>How do I make my first deposit into my Bake account?</Trans></b></li>
            <Trans>Please read:</Trans>
            <br/>
            <a target='_blank' rel="noopener noreferrer" href={getFirstDepositLink(selectedLanguage)}><Trans>How to Deposit?</Trans></a>
            <br/>
            <br/>
          </ol>
        </div>
      </ModalContent>
    </>
  );
};

export default ElevenElevenRulesModalContent;
