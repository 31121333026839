export interface Freeze {
  amount: string;
  autoRenew: boolean;
  createdAt: string;
  expiresAt: null | string;
  freezeDurationInMonths: number;
  id: string;
  isExpired: boolean;
  type: FreezeType;
  product: FreezerProduct;
  coinId: string;
  pairId: string;
  decimals: number;
}

export enum FreezeType {
  Confectionery = 'CONFECTIONERY',
  Freezer = 'FREEZER',
  DefichainFreezer = 'DEFICHAIN_FREEZER',
}

export type GetFreezerActionsResponse = {
  count: number,
  rows: Freeze[],
};

export type GetFreezerActionsRequest = {
  limit: number,
  offset: number,
  type: FreezeType,
};

export type FreezePayload = {
  amount: string,
  coinId: string,
  freezeDurationInMonths: number,
};

export enum FreezerProduct {
  STAKING = 'STAKING',
  LIQUIDITY_MINING = 'LIQUIDITY_MINING',
}
