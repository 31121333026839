/**
 * Calculates the target return of one batch
 *
 * @param {number} entryAmount
 * @param {number} returnPerAnnum
 * @returns {number}
 */
import BigNumber from 'bignumber.js';

export default function calculateTargetBatchReturnMultiplier(durationInDays, returnPerYear) {
  const exponent = Math.floor(365 / durationInDays);
  return new BigNumber((((new BigNumber(returnPerYear).toNumber()) + 1) ** (1 / exponent)) - 1);
}
