import { useCakeSelector } from 'app/rootReducer';
import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  header: {
    fontSize: '3.4rem',
    letterSpacing: '0.25px',
    lineHeight: '4rem',
  },
});

export const EasterPromoHeroRight = () => {
  const classes = useStyles();
  // force rerender this component when user changes language
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedLanguage } = useCakeSelector(state => state.languageRegionSettings);

  return (
    <>
      <h1 className={classes.header}>
        <Trans>Earn unlimited rewards with your buddies this Easter.</Trans>
      </h1>
      <p>
        <Trans>Refer a friend and enjoy an extra $10 on top of the current referral payouts. Each successful referral (account verified with a minimum $50 allocated for 28 days into Lending or into the 30-day Freezer) earns the referrer $20, and the referee $40 (both in DFI).</Trans>
        <br />
        <Trans>Join our Easter egg hunt and unlock more bonuses! From 11 to 25 April 2022, the more friends you refer, the more Easter eggs you will find!</Trans>
      </p>
    </>
  );
};

export default EasterPromoHeroRight;
