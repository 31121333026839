import { createSelector } from '@reduxjs/toolkit';
import { walletCoinsSelector } from '../../../walletSelectors';
import { getFiatBuyModalState } from '../fiatBuyModalSelector';

export const depositFeeUsdSelector = createSelector(
  getFiatBuyModalState,
  walletCoinsSelector,
  ({ inputs, guaranteeFeeUsd }, coins) => {
    const coin = coins.find(({ id }) => id === inputs.target);
    return guaranteeFeeUsd ?? coin?.depositFeeUsd;
  },
);
