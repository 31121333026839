import { DEXPrice, LiquidityMiningPair } from '@cakedefi/cake-sdk/schema';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import sdk from 'sdk';
import { DEXModalState, SwapFees } from 'types/dex-modal/dex-modal-state';
import { AnySwapGetQuota } from 'sdk/cake-sdk/src/schema/dex/any-swap-quota';
import { isPositiveAndNotZeroNumber } from 'utils';
import { ThunkApiConfig } from 'app/rootReducer';
import { getDexAmountAfterFee } from '../calculation/calculateSwap';

export const DEX_DEFAULT_FROM_COIN = 'BTC';
export const DEX_DEFAULT_TO_COIN = 'DFI';
export const DEX_ALLOWED_COINS = [DEX_DEFAULT_TO_COIN];
export const DEX_MAX_DECIMALS = 8;
export const DEX_SWAP_VALUE_THRESHOLD = 100_000;
export const isDEXEnabled = (coin: string) => DEX_ALLOWED_COINS.includes(coin);
export const minTradeEngineValueUsd = 10;

export const initialState: DEXModalState = {
  error: '',
  fromCoin: {
    id: '',
    input: 0,
  },
  loading: false,
  pairPrices: null,
  toCoin: {
    id: '',
    input: 0,
  },
  swapType: '',
  priceProtection: 0.1,
  isStaking: false,
  autoStake: false,
  liquidityMiningPairs: [],
  minimumAmountInUsd: 0,
  dusdStabFeeRate: 0,
  fees: [],
};

export const fetchPairPrices = createAsyncThunk<DEXPrice[], {
  from: string,
  to: string
}>(
  'dexModal/fetchPairPrices',
  ({ from, to }) => sdk.DexSwapApi.getRouteWithPairData(from, to),
);

export const fetchSwapMinAmountInUsd = createAsyncThunk(
  'swap/minimum',
  () => sdk.DexSwapApi.getMinimumInput(),
);

export const fetchDusdStabFee = createAsyncThunk(
  'swap/dusd/stabilization-fee',
  () => sdk.DexSwapApi.getDusdStabilizationFee(),
);

export const fetchSwapQuote = createAsyncThunk<AnySwapGetQuota, {
  from: string,
  to: string,
  minAmount: number | string,
  amount: number | string
}, ThunkApiConfig>(
  'swap/getQuote',
  async ({ from, to, minAmount, amount }, thunkAPI) => {
    const result = await sdk.AnySwapApi.getSwapGetQuote(from, to, minAmount);
    const { fees, type, targetAmount: minTargetAmount } = result;
    if (type === 'DEX') {
      await thunkAPI.dispatch(fetchPairPrices({ from, to }));
    }
    if (['TRADING_ENGINE', 'IN_HOUSE'].includes(type) && isPositiveAndNotZeroNumber(amount)) {
      const { fees: swapFees, targetAmount } = await sdk.AnySwapApi.getSwapGetQuote(from, to, amount);
      thunkAPI.dispatch(setQuote({ fees: swapFees, targetAmount, swapType: type, sourceAmount: amount }));
    }
    const { liquidityMiningPairs } = thunkAPI.getState().liquidityMining;
    await thunkAPI.dispatch(setFromCoinInput({ amount, liquidityMiningPairs, swapType: type }));
    return {
      fees,
      type,
      targetAmount: minTargetAmount,
    };
  },
);

const dexModalSlice = createSlice({
  name: 'dexModal',
  initialState,
  reducers: {
    setFromCoinId: (state, action: PayloadAction<string>) => {
      state.fromCoin.id = action.payload;
    },
    setFromCoinInput: (state, action: PayloadAction<{
      amount: string | number,
      liquidityMiningPairs: LiquidityMiningPair[],
      swapType: string,
    }>) => {
      const { amount, liquidityMiningPairs, swapType } = action.payload;
      state.fromCoin.input = amount;
      state.liquidityMiningPairs = liquidityMiningPairs;
      if (+amount === 0) {
        state.toCoin.input = 0;
      }
      if (!state.pairPrices || ['TRADING_ENGINE', 'IN_HOUSE'].includes(swapType)) {
        return;
      }
      // only handle dex swap calculation
      const estimatedToCoinAmount = getDexAmountAfterFee(
        state.fromCoin.id,
        state.toCoin.id,
        state.fromCoin.input,
        state.fees,
        state.pairPrices,
      );
      state.toCoin.input = estimatedToCoinAmount;
    },
    setToCoinId: (state, action: PayloadAction<string>) => {
      state.toCoin.id = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setQuote: (state, action: PayloadAction<{
      fees: SwapFees[],
      swapType: string,
      targetAmount: string | number,
      sourceAmount: string | number,
    }>) => {
      const { fees, swapType, targetAmount, sourceAmount } = action.payload;
      state.fromCoin.input = sourceAmount;

      state.fees = fees;
      state.swapType = swapType;
      state.toCoin.input = Number(targetAmount);
    },
    setPriceProtection: (state, action: PayloadAction<number>) => {
      state.priceProtection = action.payload;
    },
    setInitialDEXModal: (
      state,
      action: PayloadAction<{ fromCoin; toCoin; isStaking }>,
    ) => {
      const { fromCoin, toCoin, isStaking } = action.payload;
      state.fromCoin.id = fromCoin;
      state.toCoin.id = toCoin;
      state.isStaking = isStaking;
      state.autoStake = isStaking;
    },
    setAutoStake: (state, action: PayloadAction<boolean>) => {
      state.autoStake = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPairPrices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPairPrices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pairPrices = payload;
    });
    builder.addCase(fetchPairPrices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchSwapMinAmountInUsd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSwapMinAmountInUsd.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.minimumAmountInUsd = payload?.minimumAmountInUsd;
    });
    builder.addCase(fetchSwapMinAmountInUsd.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchDusdStabFee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDusdStabFee.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.dusdStabFeeRate = +(payload?.fee);
    });
    builder.addCase(fetchDusdStabFee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchSwapQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSwapQuote.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.fees = payload?.fees;
      state.swapType = payload.type;
    });
    builder.addCase(fetchSwapQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setFromCoinId,
  setToCoinId,
  setIsLoading,
  setFromCoinInput,
  setQuote,
  setPriceProtection,
  clear: clearDexModalSlice,
  setInitialDEXModal,
  setAutoStake,
  setError,
  clearError,
} = dexModalSlice.actions;

export default dexModalSlice.reducer;
