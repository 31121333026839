import Variables from '../../../Variables.styles';

const getBoxShadowStyle = (isFocused, isInvalid) => {
  if (isInvalid) {
    return `0 0 0 ${isFocused ? '2px' : '1px'} ${Variables.redInvalidControl}`;
  }

  if (isFocused) {
    return `0 0 0 2px ${Variables.electricBerry}`;
  }

  return `0 0 0 1px ${Variables.gray200}`;
};

export default {
  container: {
    borderRadius: Variables.small,
    '& > div': {
      fontSize: '1.6rem',
      color: Variables.gray600,
      '& > div.selected-flag': {
        background: 'none !important',
      },
    },
  },
  input: {
    outline: 'none !important',
    border: '1px solid transparent',
    boxShadow: ({ isFocused, isInvalid }) => getBoxShadowStyle(isFocused, isInvalid),
    width: '100% !important',
    fontSize: '1.6rem !important',
    WebkitAppearance: 'none',
  },
  invalidControlMessage: {
    color: Variables.redInvalidControl,
    fontSize: '14px !important',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    minHeight: '20px',
    display: 'block',
    visibility: ({ isInvalid }) => (isInvalid ? 'visible' : 'hidden'),
  },
};
