import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',

      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    fallbackLng: 'en', // use en if detected lng is not available

    saveMissing: process.env.NODE_ENV === 'development', // send not translated keys to endpoint

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'languageOnly', // Only load the selected language
  });

export default i18n;
