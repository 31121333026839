import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { CheckBox } from '../../../../views/components';
import { depositFeeUsdSelector } from '../store/depositFeeUsdSelector';
import styles from './AddressCreationAcknowledge.styles';

const useStyles = createUseStyles(styles);

export default function AddressCreationAcknowledge({
  hasAcknowledge,
  setHasAcknowledge,
}) {
  const classes = useStyles();
  const depositFeeUsd = useSelector(depositFeeUsdSelector);

  return (
    <div className={classes.contentMini}>
      <div>
        <Trans>
          As your ETH wallet does not have any deposits as of yet, it is subject
          to a one-time ${{ depositFeeUsd }} address creation fee.
        </Trans>
      </div>
      <div className="mt-4">
        <CheckBox
          isChecked={hasAcknowledge}
          onChange={(result) => setHasAcknowledge(result)}
        >
          <Trans>
            I acknowledge that there is a ${{ depositFeeUsd }} address creation fee for this address.
          </Trans>
        </CheckBox>
      </div>
    </div>
  );
}
