import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import InlineLoadingIndicator from '../../../common/components/InlineLoadingIndicator/InlineLoadingIndicator';
import { MouseOverTooltip } from '../../../views/components';
import styles from './PriceBreakdown.styles';
import { priceBreakdownSelector } from './fiatBuyModalSelector';
import { PriceBreakdownSelectorModel } from '../../../types/onramp/state';

const useStyles = createUseStyles(styles);

const InlineLoadingContent = ({ loading = false, children = null }) => {
  if (!children || loading) {
    return <InlineLoadingIndicator />;
  }
  return children;
};

const ErrorMessage = ({ error, children }) => {
  if (error) {
    return '-';
  }
  return children;
};

export default function PriceBreakdown() {
  const classes = useStyles();
  const {
    feePercentage,
    totalFeeAmount,
    totalFiatAmount,
    fiatCode,
    walletAddress,
    error,
    showFeePercentage,
    showFeeAmount,
    isLoadingWallet,
    feePostFixText,
  }: PriceBreakdownSelectorModel = useSelector(priceBreakdownSelector);

  const calculatedFeePercentageTmpl = (
    <InlineLoadingContent>
      {feePercentage}%
    </InlineLoadingContent>
  );
  const feeAmountTmpl = <InlineLoadingContent>{totalFeeAmount}</InlineLoadingContent>;
  const fiatAmountTmpl = <InlineLoadingContent>{totalFiatAmount}</InlineLoadingContent>;

  return (
    <div
        className={`${classes.content} ${classes.priceContainer}`}
      >
        <div className={classes.listItemContainer}>
          <label className={classes.listItemLabel}>
            <Trans>Gateway fee</Trans> {showFeePercentage && <span>({calculatedFeePercentageTmpl})</span>} {feePostFixText && <span>(<Trans i18nKey={feePostFixText}>{feePostFixText}</Trans>)</span>}
          </label>
          <span className={classes.listItemValue}>
            <ErrorMessage error={error}>
               { showFeeAmount ? <>{feeAmountTmpl} {fiatCode}</> : 'FREE' }
            </ErrorMessage>
          </span>
        </div>
        <div className={classes.listItemContainer}>
          <label className={classes.listItemLabel}>
            <Trans>Total due</Trans>
          </label>
          <span className={classes.listItemValue}>
            <ErrorMessage error={error}>
              {fiatAmountTmpl} {fiatCode}
            </ErrorMessage>
          </span>
        </div>
        <div className={classes.listItemContainer}>
          <label className={classes.listItemLabel}>
            <Trans>Deposit into</Trans>
          </label>
          <span className={classes.listItemValue}>
            <InlineLoadingContent loading={isLoadingWallet}>
              {walletAddress ? (
                <MouseOverTooltip
                  renderTooltip={() => (
                    <div className={classes.tooltip}>
                      {walletAddress}
                    </div>
                  )}
                >
                  <Trans>Bake Wallet</Trans>
                </MouseOverTooltip>
              ) : (
                <span>
                  <i className={classes.exclamationIcon} />{' '}
                  <Trans i18nKey="cakeWalletNotAvailable">Not available</Trans>
                </span>
              )}
            </InlineLoadingContent>
          </span>
        </div>
      </div>
  );
}
