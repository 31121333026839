import React from 'react';
import { Trans } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { createUseStyles } from 'react-jss';
import ModalStyles from 'modals/Modal.styles';
import Variables from 'Variables.styles';
import { PROMO_TYPE_SLUG } from './Sprod/constants';
import { getLapisKnowledgeBaseLink } from '../../utils';
import i18n from '../../i18n';

const getCoinSpecificData = (coinId: string) => {
  switch (coinId) {
    case 'BTC':
      return {
        largerOrEqualAmount: '0.01',
        largerOrEqualApy: '4.0%',
        smallerAmount: '0.01',
        smallerApy: '0.1%',

        // Scenario 1
        scenario1EntryAmount: '10',
        scenario1ReturnAmount: '0.0000075',
        scenario1ReturnAmountOn: '0.01',
        scenario1ReturnAfter: '0.0001915',
        scenario1ReturnAfterOn: '9.99',

        // Scenario 2
        scenario2EntryAmount: '0.01',
        scenario2ReturnAmount: '0.0000075',
      };
    case 'ETH':
      return {
        largerOrEqualAmount: '0.1',
        largerOrEqualApy: '4.0%',
        smallerAmount: '0.1',
        smallerApy: '0.1%',

        // Scenario 1
        scenario1EntryAmount: '100',
        scenario1ReturnAmount: '0.0000752',
        scenario1ReturnAmountOn: '0.1',
        scenario1ReturnAfter: '0.001915',
        scenario1ReturnAfterOn: '99.9',

        // Scenario 2
        scenario2EntryAmount: '0.1',
        scenario2ReturnAmount: '0.0000752',
      };
    case 'USDT':
    case 'USDC':
      return {
        largerOrEqualAmount: '200',
        largerOrEqualApy: '10%',
        smallerAmount: '200',
        smallerApy: '0.1%',

        // Scenario 1
        scenario1EntryAmount: '1000',
        scenario1ReturnAmount: '0.3659076',
        scenario1ReturnAmountOn: '200',
        scenario1ReturnAfter: '0.0153349',
        scenario1ReturnAfterOn: '800',

        // Scenario 2
        scenario2EntryAmount: '200',
        scenario2ReturnAmount: '0.3659076',
      };
    default:
      return {};
  }
};

const useStyles = createUseStyles({
  ...ModalStyles,
  modalBottomPadding: {
    paddingBottom: Variables.medium,
    paddingTop: Variables.medium,
  },
  table: {
    fontSize: '1.6rem',
    border: Variables.primaryBorder,
    padding: Variables.small,
    borderRadius: Variables.small,
    width: '100%',
  },
  tableHeader: {
    background: 'rgba(0, 0, 0, 0.06)',
    fontWeight: Variables.fontWeight.bold,
    padding: Variables.small,
  },
  tableContentContainer: {
    borderBottom: Variables.primaryBorder,
  },
  tableContent: {
    padding: Variables.small,
  },
  noPaddingTop: {
    paddingTop: '0 !important',
  },
  noBorder: {
    border: '0 !important',
  },
});

export default ({ sprodName, coinName, coinId, slug, tier2Threshold, tier2Apy, returnPerAnnum }) => {
  const classes = useStyles();
  if (slug === PROMO_TYPE_SLUG) {
    return getXmasContent();
  }

  const expectedReturn = new BigNumber(returnPerAnnum).multipliedBy(100).toFormat();
  const tier2Return = new BigNumber(tier2Apy).multipliedBy(100).toFormat();

  const coinData = getCoinSpecificData(coinId);
  const {
    largerOrEqualAmount,
    largerOrEqualApy,
    smallerApy,

    scenario1EntryAmount,
    scenario1ReturnAmount,
    scenario1ReturnAmountOn,
    scenario1ReturnAfter,
    scenario1ReturnAfterOn,

    scenario2EntryAmount,
    scenario2ReturnAmount,
  } = coinData;

  const scenario1TotalAmount = new BigNumber(scenario1EntryAmount).plus(scenario1ReturnAmount).plus(scenario1ReturnAfter);
  const scenario2TotalAmount = new BigNumber(scenario2EntryAmount).plus(scenario2ReturnAmount);

  return [
    {
      content: <p><Trans>This service allows you to allocate your {{ coinId }} into a batch that yield returns in just 7 days and benefit from a guaranteed base APY.</Trans></p>,
      key: 1,
    },
    {
      content: <div>
        <h2><Trans>Summary</Trans></h2>
        <ul>
          <li><Trans>Batch period is shortened to give users more flexibility and liquidity.</Trans></li>
          <li><Trans>Base APY is guaranteed.</Trans></li>
          <li><Trans>Users may auto-compound for easy allocation of principal and returns into the next batch.</Trans></li>
          <li><Trans>More benefits and advantages await users of our Lending service. Stay tuned for more updates and upcoming announcements.</Trans></li>
        </ul>
      </div>,
      key: 2,
    },
    {
      content: <div>
        <h2><Trans>Batch</Trans></h2>
        <p><Trans>Each batch starts every Friday at 3 PM (SGT) and ends on the following Friday at 4 PM (SGT).</Trans></p>
      </div>,
      key: 3,
    },
    {
      content: <div>
        <h2><Trans>Rates as of 25 November 2022 for {{ coinId }}</Trans></h2>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={`${classes.tableHeader} ${classes.noBorder}`}><Trans>Amount</Trans></td>
              <td className={`${classes.tableHeader} ${classes.noBorder}`} style={{ width: '40%' }}><Trans>APY</Trans></td>
            </tr>
            <tr className={classes.tableContentContainer}>
              <td className={classes.tableContent}>{'<='} {`${tier2Threshold} ${coinId}`}</td>
              <td className={classes.tableContent}>{expectedReturn}%</td>
            </tr>
            <tr>
              <td className={`${classes.tableContent} ${classes.noBorder}`}>{'>'} {`${tier2Threshold} ${coinId}`}</td>
              <td className={`${classes.tableContent} ${classes.noBorder}`}>{expectedReturn}% (<Trans>up to</Trans> {tier2Threshold} {coinId})</td>
            </tr>
            <tr>
              <td className={`${classes.tableContent} ${classes.noPaddingTop}`}></td>
              <td className={`${classes.tableContent} ${classes.noPaddingTop}`}>{tier2Return}% (<Trans>surplus amount</Trans>)</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.modalBottomPadding} />
      </div>,
      key: 4,
    },
    {
      content: <div>
        <h2><Trans>How does this work?</Trans></h2>
        <p><Trans>For illustrative purposes, let’s use a {{ coinName }} lending batch as an example:</Trans></p>
      </div>,
      key: 5,
    },
    {
      content: <div>
        <h2><Trans>Scenario 1: {{ scenario1EntryAmount }} {{ coinId }} allocated in a 7-day Lending batch</Trans></h2>
        <p><Trans>Return generated will be based on {{ largerOrEqualApy }} APY for the first {{ largerOrEqualAmount }} {{ coinId }}, and {{ smallerApy }} APY on the surplus amount.</Trans></p>
        <ul>
          <li><Trans>Entry amount = {{ scenario1EntryAmount }} {{ coinId }}</Trans></li>
          <li><Trans>Return = {{ scenario1ReturnAmount }} {{ coinId }} on the first {{ scenario1ReturnAmountOn }} {{ coinId }}, {{ scenario1ReturnAfter }} on the surplus {{ scenario1ReturnAfterOn }} {{ coinId }}</Trans></li>
        </ul>
        <p><Trans>Therefore, total amount received over 7 days would be = {{ scenario1TotalAmount }} {{ coinId }}.</Trans></p>
      </div>,
      key: 6,
    },
    {
      content: <div>
        <h2><Trans>Scenario 2: {{ scenario2EntryAmount }} {{ coinId }} allocated in a 7-day Lending batch</Trans></h2>
        <p><Trans>Return generated will be based on {{ largerOrEqualApy }} APY as the entry amount is</Trans>{' <='} {largerOrEqualAmount} {coinId}.</p>
        <ul>
          <li><Trans>Entry amount = {{ scenario2EntryAmount }} {{ coinId }}</Trans></li>
          <li><Trans>Return = {{ scenario2ReturnAmount }} {{ coinId }}</Trans></li>
        </ul>
        <p><Trans>Therefore, total amount received over 7 days would be = {{ scenario2TotalAmount }} {{ coinId }}.</Trans></p>
      </div>,
      key: 7,
    },
    {
      content: <div>
        <h2><Trans>Fees and Commissions</Trans></h2>
        <p><Trans>Bake does not charge fees from users with this service, the listed APY figures are net of all fees and users stand to gain return on the entirety of your entry. For further questions please see our</Trans><a
          href={getLapisKnowledgeBaseLink(i18n.language)}
          rel="noopener noreferrer"
          target="_blank"> <Trans>Knowledge Base section.</Trans></a></p>
      </div>,
      key: 8,
    },
  ];
};

function getXmasContent() {
  return [
    {
      content: <div><p><Trans>For this BTC Lending Xmas Special batch, unlock cash flow from your crypto and get 10% APY on your BTC! Your principal and returns are fully guaranteed and risk-free!</Trans></p></div>,
    },
    {
      content: <div>
        <h2><Trans>Service summary</Trans></h2>
        <ul>
          <li><Trans>BTC Lending Xmas Special Offer.</Trans></li>
          <li><Trans>This BTC Lending Xmas Special batch runs for 4 weeks, starting on 24 December 2021 and ending on Friday 21 January 2022.</Trans></li>
          <li><Trans>The base return of this BTC Lending Xmas Special batch is fully guaranteed (see below).</Trans></li>
          <li><Trans>You are able to enter this BTC Lending Xmas Special with a maximum of up to 1 BTC.</Trans></li>
          <li><Trans>Once you decide on your entry amount (max of 1 BTC), your bitcoins will be locked in option contracts for the entirety of the next 4 weeks.</Trans></li>
          <li><Trans>After the 4 weeks period of the BTC Lending Xmas Special batch, you will have the option to auto-compound (automatically roll-over) into the next batch, withdraw only your proceeds, or your entire principal and returns back to your account.</Trans>
            <ul><li><Trans>Following batches' APY may be subject to change.</Trans></li></ul>
          </li>
          <li><Trans>Lending does not charge fees; we receive commissions directly from our partners.</Trans></li>
        </ul>
        <p><Trans>Read the following sections for more detail on the above points.</Trans></p>
      </div>,
    },
    {
      content: <div>
        <h2><Trans>Batch</Trans></h2>
        <p><Trans>Each Lending batch lasts for 28 days, and starts and ends on a Friday. The same applies to this BTC Lending Xmas Special batch.</Trans></p>
      </div>,
    },
    {
      content: <div>
        <h2><Trans>Timelines & Deadlines</Trans></h2>
        <p>
          <Trans>
            Each batch starts on Friday at 4 p.m. Singapore Time, and ends after 4 weeks on Friday at 4 p.m. Singapore time. After each batch ends, you will be able to view the settlement within 24 hours on your accounts. Our system will process your BTC according to your batch settings and/or allocation preferences.
          </Trans>
        </p>
      </div>,
    },
    {
      content: <div>
        <h2><Trans>Return</Trans></h2>
        <ul>
          <li><Trans>A base guaranteed return APY would be displayed on Bake's Platform for each batch.</Trans></li>
          <li><Trans>The base reward is guaranteed as it is not dependent on BTC spot price at start of batch and the market conditions.</Trans></li>
          <li><Trans>Definite pricing will be provided on the starting day of the batch. This would be decisive and allows all users to independently track which tier a batch would likely end up yielding based on BTC spot price upon batch expiry.</Trans></li>
        </ul>
      </div>,
    },
    {
      content: <div>
        <h2><Trans>Fees and Commissions</Trans></h2>
        <p><Trans>You stand to gain returns on the entirety of your entry. Lending does not charge fees from users, we receive commissions directly from our partners.</Trans></p>
      </div>,
    },
  ];
}
