import { Dialog, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import Variables from 'Variables.styles';
import { ModalContext } from '../ModalContext';
import { ModalContent } from './ModalContent';
import { ModalHeader } from './ModalHeader';

const useStyles = makeStyles<string, { hideBody: boolean }>({
  paper: {
    verticalAlign: 'top',
    overflowY: 'visible',
    height: ({ hideBody }) => (hideBody ? '0px' : 'auto'),
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

const ModalDialog = () => {
  const {
    modalContent: {
      cancelLabel,
      component,
      disableBackdropClick = false,
      disableEscapeKeyDown = false,
      fullWidth = true,
      scroll = 'body',
      title,
      onBackdropClick,
      hideBody = false,
      showCancelButton = true,
      overlayColor,
    },
    closeModal,
    isShowModal,
  } = useContext(ModalContext);
  const classes = useStyles({ hideBody });

  const handleClose = (event, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      if (disableBackdropClick) return;
      onBackdropClick?.();
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return;
    }

    closeModal();
  };

  const wrapper = (contentTmpl: React.ReactNode) => (
    <Dialog
      style={{
        zIndex: Variables.zIndex.modal,
        backgroundColor: overlayColor,
      }}
      classes={{
        paper: classes.paper,
        scrollPaper: classes.scrollPaper,
      }}
      fullWidth={fullWidth}
      open={isShowModal}
      maxWidth={'md'}
      scroll={scroll}
      transitionDuration={50}
      onClose={handleClose}
    >
      {contentTmpl}
    </Dialog>
  );

  if (title) {
    return wrapper(
      <>
        <ModalHeader title={title} cancelLabel={cancelLabel} showCancelButton={showCancelButton} />
        <ModalContent>{component}</ModalContent>
      </>,
    );
  }

  return wrapper(component);
};

export default ModalDialog;
