import { createSlice } from '@reduxjs/toolkit';

interface userAppPreferenceInitialState {
  isObfuscated: boolean;
  isHideZeroBalances: boolean;
}

const initialState: userAppPreferenceInitialState = {
  isObfuscated: false,
  isHideZeroBalances: false,
};

const userAppPreferenceSlice = createSlice({
  name: 'userAppPreference',
  initialState,
  reducers: {
    setIsObfuscated: (state, action) => {
      state.isObfuscated = action.payload;
    },
    setHideZeroBalances: (state, action) => {
      state.isHideZeroBalances = action.payload;
    },
  },
});

export const { setIsObfuscated, setHideZeroBalances } = userAppPreferenceSlice.actions;

export default userAppPreferenceSlice.reducer;
