import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { isStakeWithSharesCoin, setCoinToSwapAmount, setSwappableCoinAmount } from './swapCoinModalService';
import sdk from '../../../sdk';
import { ThunkApiConfig } from '../../../app/rootReducer';
import { CoinMap } from '../../../types/coin/coin-map';
import { isDEXEnabled } from '../DEXModal/state/dexModalSlice';
import { StakingAction, StakingTypes } from '../../../types/staking/staking-page';

const initialState = {
  isReloading: false,
  modalConfig: null,
  isDEXEnableForCoin: false,
  inputs: {
    swappableCoinDecimals: null,
    swappableCoin: null,
    swappableCoinAmount: 1,
    coinToSwapDecimals: null,
    coinToSwap: null,
    coinToSwapAmount: 0,
    stakingPayoutApproach: null,
  },
};

export const swapCoin = createAsyncThunk<any, {action?: string}, ThunkApiConfig>('wallet/swapCoin', ({ action }, thunkAPI) => {
  const { inputs } = thunkAPI.getState().swapCoinModal;
  const {
    termType,
    fixedTermDuration,
    fixedTermAutoRenew,
  } = thunkAPI.getState().freezerCoinInfo;
  if (isStakeWithSharesCoin(inputs)) {
    if (action === StakingAction.UPGRADE) {
      return sdk.FreezerApi.freezeStaking(new BigNumber(inputs.coinToSwapAmount).toString(), inputs.coinToSwap, fixedTermDuration, fixedTermAutoRenew);
    }
    if (termType === StakingTypes.FLEXIBLE) {
      return sdk.StakingApi.stake(inputs.coinToSwap, new BigNumber(inputs.coinToSwapAmount));
    }
    return sdk.StakingApi.stake(inputs.coinToSwap, new BigNumber(inputs.coinToSwapAmount), fixedTermDuration, fixedTermAutoRenew);
  }
  return null;
});

export const swapCoinModalSlice = createSlice({
  name: 'swapCoinModalSlice',
  initialState,
  reducers: {
    updateSwappableCoin: (state, action: PayloadAction<{ coin: any; pricing: any[], coins: CoinMap, withoutMarkup: boolean, isStakingOptimized?: boolean }>) => {
      const { coin, pricing, coins, withoutMarkup, isStakingOptimized } = action.payload;
      state.inputs.swappableCoin = coin.value;
      state.inputs.stakingPayoutApproach = coins[coin.value].stakingPayoutApproach;
      state.inputs.swappableCoinDecimals = coin.decimals;
      setCoinToSwapAmount(state, pricing, coins, withoutMarkup, isStakingOptimized);
    },
    updateSwappableCoinAmount: (state, action: PayloadAction<{ amount: number; pricing: any[], coins: CoinMap, withoutMarkup: boolean, isStakingOptimized?: boolean }>) => {
      const { amount, pricing, coins, withoutMarkup, isStakingOptimized } = action.payload;
      state.inputs.swappableCoinAmount = amount;
      setCoinToSwapAmount(state, pricing, coins, withoutMarkup, isStakingOptimized);
    },
    updateCoinToSwap: (state, action: PayloadAction<{ pricing: any[], coin: any, withoutMarkup: boolean, coins?: CoinMap, isStakingOptimized?: boolean }>) => {
      const { coin, pricing, withoutMarkup, coins, isStakingOptimized } = action.payload;
      state.inputs.coinToSwap = coin.value;
      state.inputs.coinToSwapDecimals = coin.decimals;
      setSwappableCoinAmount(state, pricing, withoutMarkup, coins, isStakingOptimized);
    },
    updateCoinToSwapAmount: (state, action: PayloadAction<{ amount: number; pricing: any[], withoutMarkup: boolean, coins?: CoinMap, isStakingOptimized?: boolean }>) => {
      const { amount, pricing, withoutMarkup, coins, isStakingOptimized } = action.payload;
      state.inputs.coinToSwapAmount = amount;
      setSwappableCoinAmount(state, pricing, withoutMarkup, coins, isStakingOptimized);
    },
    updateFlexibleToFixedAmount: (state, action: PayloadAction<{ amount: number;}>) => {
      const { amount } = action.payload;
      state.inputs.coinToSwapAmount = amount;
    },
    reloadPrice: (state, action) => {
      state.isReloading = action.payload;
    },
    setModalConfig: (state, action) => {
      state.modalConfig = action.payload;
    },
    setIsDEXEnable: (state, action: PayloadAction<string>) => {
      state.isDEXEnableForCoin = isDEXEnabled(action.payload);
    },
    clear: () => ({ ...initialState }),
  },
});

export const {
  updateSwappableCoin,
  updateSwappableCoinAmount,
  updateCoinToSwap,
  updateCoinToSwapAmount,
  updateFlexibleToFixedAmount,
  reloadPrice,
  clear: clearSwapCoinModalSlice,
  setModalConfig,
  setIsDEXEnable,
} = swapCoinModalSlice.actions;

export default swapCoinModalSlice.reducer;
