export default function getWithdrawalProcessFaqLink(language) {
  switch (language) {
    case 'it':
      return 'https://support.bake.io/it/articles/8286186-quanto-tempo-ci-vuole-per-l-elaborazione-del-mio-prelievo';
    case 'zht':
      return 'https://support.bake.io/zh-TW/articles/8286186-%E6%88%91%E7%9A%84%E6%8F%90%E6%AC%BE%E9%9C%80%E8%A6%81%E5%A4%9A%E5%B0%91%E6%99%82%E9%96%93%E5%AE%8C%E6%88%90';
    default:
      return 'https://support.bake.io/en/articles/8286186-how-long-does-it-take-for-my-withdrawal-to-be-processed';
  }
}
