import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useCakeSelector } from 'app/rootReducer';
import Button from './common/components/Button/Button';
import { clearNetworkError } from './networkError/networkErrorReducer';
import { pingHost } from './networkError/networkErrorService';
import Variables from './Variables.styles';
import config from './config/app';

const { PING_CLIENT_HOST_INTERVAL } = config;

const useStyles = createUseStyles({
  main: {
    composes: 'main-layout',
  },
  errorPage: {
    padding: Variables.extraLarge,
    textAlign: 'center',
  },
  refreshButton: {
    margin: `${Variables.medium} auto 0 auto`,
  },
  typingCatWrapper: {
    composes: 'typing-cat-wrapper',
  },
  typingCat: {
    composes: 'typing-cat',
  },
  text: {
    composes: 'text',
  },
  typed: {
    composes: 'typed',
  },
});

const Fallback = () => {
  const classes = useStyles();
  const { hasNetworkError, networkErrorType } = useCakeSelector(state => state.networkError);
  const dispatch = useDispatch();

  const isClientNetworkError = hasNetworkError && networkErrorType === 'CLIENT_CONNECTION_DOWN';

  const message = isClientNetworkError
    ? <Trans>Your connection was down while fetching information from the server. Please refresh the page to try again.</Trans>
    : <Trans>There are some issues with the application. Please refresh the page to try again.</Trans>;

  useEffect(() => {
    let connectionIntervalChecker;
    if (isClientNetworkError) {
      connectionIntervalChecker = setInterval(async () => {
        await pingHost();
        dispatch(clearNetworkError());
        clearInterval(connectionIntervalChecker);
      }, PING_CLIENT_HOST_INTERVAL);
    }
    return () => {
      if (connectionIntervalChecker) {
        clearInterval(connectionIntervalChecker);
        connectionIntervalChecker = null;
      }
    };
  }, [dispatch, isClientNetworkError]);

  return (
    <main className={classes.main}>
      <div className={classes.errorPage}>
        <div>{message}</div>
        <Button className={classes.refreshButton} primary medium onClick={() => window.location.reload()}><Trans>Refresh the page</Trans></Button>
      </div>
    </main>
  );
};

export default Fallback;
