import React,
{ Component } from 'react';
import CakeLoader from '../../common/components/CakeLoader/CakeLoader';

export default class Spinner extends Component {
  render() {
    const optionalClasses = this.props.classes || '';
    const classes = `full-size flex-center spinner animated fadeIn ${optionalClasses}`;

    return (
      <div className={classes}>
        <CakeLoader />
      </div>
    );
  }
}
