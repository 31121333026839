import React, { Component } from 'react';
import ReactSvg from 'react-svg';
import { SVGCoins } from 'utils/getSVGCoins';
import { SVGIcons } from 'utils/getSVGIcons';
import { SVGTokens } from 'utils/getSVGTokens';
import { SVGNetworks } from 'utils/getSVGNetworks';

interface IconProps {
  containerClassName?: string;
  id?: string
  name: string;
  svgStyle?: Record<string, string | number>;
  onClick?: (...rest: any) => void;
}

export default class Icon extends Component<IconProps> {
  state = {
    name: null,
    svgStyle: {},
  }

  icons = {
    ...SVGCoins,
    ...SVGIcons,
    ...SVGTokens,
    ...SVGNetworks,
  }

  componentDidMount() {
    const propsSvgStyles = this.props.svgStyle || {};
    this.setState({
      svgStyle: {
        width: 32,
        height: 32,
        ...propsSvgStyles,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getIconContainerStyle() {
    const height = '100%';
    const width = '100%';
    return {
      height,
      width,
      ...this.state.svgStyle,
    };
  }

  render() {
    const {
      svgStyle,
    } = this.state;
    const { containerClassName, name, id, onClick } = this.props;
    const iconLink = this.icons[name];
    if (!iconLink) {
      return (
        <span
          className={'icon__container'}
          style={this.getIconContainerStyle()}
        ></span>
      );
    }

    return (
      <span className={`icon__container ${containerClassName}`} style={this.getIconContainerStyle()} onClick={(...rest) => onClick?.(...rest)} id={id || null}>
        <ReactSvg
          src={iconLink}
          svgStyle={svgStyle}
        ></ReactSvg>
      </span>
    );
  }
}
