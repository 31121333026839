import React,
{ Component } from 'react';
import moment from 'moment';

export default class Timer extends Component {
  state = {
    secondsAgo: 0,
  };

  componentDidMount() {
    this.startInterval();
    this.initTimer();
  }

  componentWillUnmount() {
    this.removeInterval();
  }

  initTimer() {
    const {
      secondsAgo,
      atDateTime,
    } = this.props;
    let calculatedSecondsAgo = Math.floor(secondsAgo);
    if (atDateTime) {
      const now = moment();
      const parsedAtDateTime = moment(atDateTime);
      calculatedSecondsAgo = Math.floor(now.diff(parsedAtDateTime) / 1000);
      if (now.isBefore(parsedAtDateTime)) {
        calculatedSecondsAgo *= -1;
      }
    }
    this.setState({ secondsAgo: calculatedSecondsAgo });
  }

  addSecond() {
    const {
      secondsAgo,
    } = this.state;
    this.setState({
      secondsAgo: secondsAgo + 1,
    });
  }

  startInterval() {
    this.interval = setInterval(this.addSecond.bind(this), 1000);
  }

  removeInterval() {
    clearInterval(this.interval);
  }

  getCounter() {
    const {
      secondsAgo,
    } = this.state;
    let remaining = Math.abs(secondsAgo);
    const hoursAgo = Math.floor(remaining / 3600);
    remaining %= 3600;
    const minutesAgo = Math.floor(remaining / 60);
    remaining %= 60;
    let time = null;
    if (hoursAgo) {
      time = `${hoursAgo}h ${minutesAgo}m`;
    } else
    if (minutesAgo) {
      time = `${minutesAgo}m ${remaining}s`;
    } else {
      time = `${remaining}s`;
    }
    if (secondsAgo > 0) {
      return `${time}`;
    }
    return `In ${time}`;
  }

  render() {
    return (
      <span className="cakepool__timer">
        {this.getCounter()}
      </span>
    );
  }
}
