import axios from 'axios';
import { setNetworkError } from './networkErrorReducer';
import { NetworkErrorType } from '../types/app/network-error';
import config from '../config/app';

const { API_ENDPOINT } = config;

export const pingerInstance = axios.create();

export const pingHost = async () => pingerInstance({ url: `/?ping=${Math.random()}`, method: 'HEAD' });

export const pingServer = async () => pingerInstance({ url: API_ENDPOINT, method: 'HEAD' });

export const handleNetworkError = async (dispatch, isBackgroundRequest: boolean) => {
  try {
    // ping random param to avoid browser cache
    await pingHost();
    if (isBackgroundRequest) {
      return;
    }
    // if successful, means server is down
    dispatch(setNetworkError(NetworkErrorType.SERVER_DOWN));
  } catch {
    // if not successful, means client connection is down
    dispatch(setNetworkError(NetworkErrorType.CLIENT_CONNECTION_DOWN));
  }
};
