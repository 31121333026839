/**
 * @param language 'cn'|'en' as default
 */
export default function getReferralFaqLinks(language?: string): string {
  switch (language) {
    case 'zht':
      return 'https://support.bake.io/zh-TW/collections/5841167-推薦新用戶活動推廣';
    default:
      return 'https://support.bake.io/en/collections/5841167-referral-program';
  }
}
