import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import { Trans } from 'react-i18next';
import {
  isValidEmail,
} from 'utils';
import TextInput from 'common/components/TextInput/TextInput';
import PasswordMeter from 'views/components/PasswordMeter';
import { useCakeSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import {
  setEmail,
  setPassword,
} from 'views/Pages/Authentication/authSlice';

const styles = {
  textField: {
    marginBottom: Variables.large,
  },
  passwordFieldWithMeter: {
    marginBottom: '7px',
  },
};

const useStyles = createUseStyles(styles);

const EmailPasswordInput = ({ onRegister }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { email, password } = useCakeSelector(state => state.auth);
  const [emailValidation, setEmailValidation] = useState(null);

  const onInputKeyPress = (e) => {
    if (e.keyCode === 13) { // enter
      onRegister();
    }
  };

  const onEmailInputKeyUp = (e) => {
    const { value } = e.target;
    if (value === '' || isValidEmail(value)) {
      setEmailValidation('');
    } else {
      setEmailValidation(<Trans>Must be a valid email.</Trans>);
    }
  };

  return (
    <div>
      <TextInput
        autoFocus
        className={classes.textField}
        type="text"
        placeholder={'Email'}
        autoComplete="email"
        label={'Email'}
        value={email}
        onChange={(value) => dispatch(setEmail(value))}
        tabIndex={1}
        onKeyDown={e => onInputKeyPress(e)}
        onKeyUp={e => onEmailInputKeyUp(e)}
        isInvalid={!!emailValidation}
        invalidMessage={emailValidation}
      />
      <TextInput
        className={`${classes.textField} ${password.length > 0 && classes.passwordFieldWithMeter}`}
        type="password"
        placeholder={'Password'}
        autoComplete="new-password"
        label={'Password'}
        value={password}
        onChange={value => dispatch(setPassword(value))}
        tabIndex={2}
        onKeyDown={e => onInputKeyPress(e)}
      />
    { password.length > 0 && <PasswordMeter password={password} /> }
    </div>
  );
};

export default EmailPasswordInput;
