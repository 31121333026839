import BigNumber from 'bignumber.js';
import appConfig from '../config/app';

const friendlyDecimals = appConfig.FRIENDLY_DECIMALS;
BigNumber.config({ EXPONENTIAL_AT: 21 });

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

export default function localizeBigNumber(
  value: any,
  isDisplayAll = false,
  decimalPoints?: any,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  fixedDecimals = false,
) {
  const bnValue = !(new BigNumber(value).isNaN()) ? new BigNumber(value) : new BigNumber(0);

  const decPoint = !decimalPoints ? friendlyDecimals : decimalPoints;

  if (isDisplayAll) {
    return bnValue.toFormat(fmt);
  }

  if (fixedDecimals) {
    return new BigNumber(bnValue).decimalPlaces(decPoint, roundingMode).toFormat(decPoint, fmt);
  }

  return new BigNumber(bnValue).decimalPlaces(decPoint, roundingMode).toFormat(fmt);
}
