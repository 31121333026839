import { useRef } from 'react';

export const useFocus = () => {
  const inputRef = useRef<HTMLInputElement>();
  const setFocus = () => {
    const { current: currentElement } = inputRef;
    if (currentElement) {
      currentElement.focus();
    }
  };

  return [inputRef, setFocus] as const;
};
