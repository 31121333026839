import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PreSwapModalState {
  coinId: string;
  walletByCoins: any;
  share: any;
  coinDecimals: any;
}

const initialState: PreSwapModalState = {
  coinId: null,
  walletByCoins: null,
  share: null,
  coinDecimals: null,
};

export const preSwapModalSlice = createSlice({
  name: 'preSwapModalSlice',
  initialState,
  reducers: {
    setPreSwapModalContent: (
      state,
      action: PayloadAction<{
        coinId: string;
        walletByCoins: any;
        share: any;
        coinDecimals: any;
      }>,
    ) => ({ ...action.payload }),
    clear: () => ({ ...initialState }),
  },
});

export const {
  setPreSwapModalContent,
  clear: clearPreSwapModalContent,
} = preSwapModalSlice.actions;

export default preSwapModalSlice.reducer;
