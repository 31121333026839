import { AuditActionType } from '../lib/customType';

const AuditActionMessage: Record<AuditActionType, string> = {
  REJECT_KYC_SUBMISSION: 'KYC submission rejected',
  APPROVE_KYC_SUBMISSION: 'KYC submission approved',
  EXPORTING_USER_TRANSACTIONS: 'User transactions exported',
  UPDATE_CONFIGURATION: 'Configuration updated',
  CREATE_DFI_TRANSFER: 'DFI transfer created',
  CREATE_SHARES_SALE_LISTING: 'Shares sale listing created',
  SET_DFI_PRICE: 'DFI price set',
  SET_DFI_COINSWAP_ALLOWANCE: 'DFI coinswap allowance set',
  CREATE_PROMOTION: 'Promotion created',
  ADD_TRADE: 'Trade added',
  ADD_HEDGE: 'Hedge added',
  ADD_BONUS_ROUND: 'Bonus round added',
  CREATE_SPROD_BATCH: 'Sprod batch created',
  SETTLE_BATCH: 'Batch settled',
  CANCEL_BATCH: 'Batch cancelled',
  UPDATE_BATCH_INFO: 'Batch info updated',
  SUSPEND_ACCOUNT: 'Account suspended',
  UNSUSPEND_ACCOUNT: 'Account unsuspended',
  REMOVE_2FA: '2FA removed',
  UPDATE_EMAIL: 'Email updated',
  CONFIRM_WITHDRAWAL: 'Withdrawal confirmed',
  CANCEL_WITHDRAWAL: 'Withdrawal cancelled',
  UPDATE_MARGIN: 'Coinswap margin updated',
  USER_SEARCH_PERFORMED: 'User search performed',
  UPDATE_WITHDRAWAL_FEE: 'Update withdrawal fee',
  UPDATE_AUTO_WITHDRAWAL: 'Update auto withdrawal',
  EXPORTING_USER_DEPOSITS: 'User deposits exported',
  ADD_REFERRAL_CODE: 'Referral code added',
  ADD_LENDING: 'Lending added',
  BAN_ACCOUNT: 'Account banned',
  ASSIGN_KYC_SUBMISSION: 'Kyc case assigned',
  REASSIGN_KYC_SUBMISSION: 'Kyc case reassigned',
};

export default function getAuditActionTitle(action: AuditActionType): string {
  const message: string = AuditActionMessage[action];
  if (!message) {
    const words = (action as string).toLowerCase().split('_');
    const titleCased = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1));
    return titleCased.join(' ');
  }
  return message;
}
