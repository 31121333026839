import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import styles from './HeaderLogo.styles';
import { useCakeSelector } from '../../../app/rootReducer';
import { isLoggedInSelector } from '../../../user/userSelector';
import getCakeDeFiWebsiteBaseURL from '../../../utils/getCakeDeFiWebsiteBaseURL';

const useStyles = createUseStyles(styles);

export default function HeaderLogo({ invert = false }) {
  const classes = useStyles();
  const location = useLocation();
  const isUserLoggedIn = useCakeSelector(isLoggedInSelector);

  const getPathName = () => {
    if (['/register', '/welcome'].includes(location.pathname)) return getCakeDeFiWebsiteBaseURL();
    if (isUserLoggedIn) return '/wallets';

    return '/';
  };

  return <Link to={{ pathname: getPathName() }} target="_top" className={`${classes.branchLogo} ${invert ? classes.invertColour : ''}`} />;
}
