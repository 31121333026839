import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCakeSelector } from 'app/rootReducer';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { ModalContent, ModalFooter, ModalHeader } from 'modals/components';
import onboardIdentity from 'assets/svg/onboard-identity.svg';
import { staggeredKycUserStatusSelector, userSumsubKycStatusSelector } from 'user/AccountVerification/accountVerificationSelector';
import KycCurrentLevel from 'common/kyc/KycCurrentLevel';
import Button from 'common/components/Button/Button';
import { ModalContext } from 'modals/ModalContext';
import styles from './KycUnverified.styles';
import BottomKycBenefitsForModal from './common/BottomKycBenefitsForModal';
import getLevelDisplay from './utils/getLevelDisplay';
import KycUnverifiedOnLoginModal from './KycUnverifiedOnLoginModal';
import KycModal from './KycModal';

const actionToProductNameMap: Record<string, string> = {
  addLiquidity: 'unlock liquidity mining',
  enterSprod: 'unlock lending',
  stake: 'unlock staking',
  buyCryptoWithFiat: 'buy crypto',
  withdraw: 'withdraw crypto',
  deposit: 'deposit crypto',
  swapOnDex: 'swap crypto',
  acceptReferralRules: 'start inviting your friends',
  subscription: 'join ELITE',
};

interface ScreenContentItem {
  getTitle: (product?: string) => JSX.Element;
  description?: JSX.Element;
  settingsLink?: string;
  buttonLabel: string;
  isShowKycBottomSection: boolean;
}

const kycRequired = {
  getTitle: (product = 'unlock this feature') => <Trans>Verify your account to {{ product }}.</Trans>,
  description: <Trans>As a regulated FinTech company, Bake is required to verify user identity. This process only takes a few minutes to complete and less than an hour to get approved.</Trans>,
  settingsLink: '/me/verify-account',
  buttonLabel: 'Verify now',
  isShowKycBottomSection: true,
};

const screenContent: Record<string, ScreenContentItem> = {
  KycRequired: kycRequired,
  LiteKycRequired: kycRequired,
  KycInReviewing: {
    getTitle: () => <Trans>Your documents are in review.</Trans>,
    description: <Trans>This process will usually take 1 to 3 days. Once the review is completed, you will be notified via email.</Trans>,
    settingsLink: '#',
    buttonLabel: 'Got it',
    isShowKycBottomSection: false,
  },
  KycExpired: {
    getTitle: () => <Trans>Please submit a new ID or passport to verify your identity.</Trans>,
    description: <Trans>Your Proof of Identity has expired.</Trans>,
    settingsLink: '/me/verify-account',
    buttonLabel: 'Renew your POI',
    isShowKycBottomSection: false,
  },
  KycRejected: {
    getTitle: () => <Trans>Please retry verification.</Trans>,
    description: <Trans>Your verification has been rejected.</Trans>,
    settingsLink: '/me/verify-account',
    buttonLabel: 'Retry verification',
    isShowKycBottomSection: false,
  },
  DepositLimitReached: {
    getTitle: () => <Trans>You have reached your deposit limit. Please complete your account verification to unlock this action.</Trans>,
    description: <Trans>Please verify your account in <i>Profile and settings</i>.</Trans>,
    buttonLabel: 'Go to account verification',
    isShowKycBottomSection: false,
  },
  TwoFARequired: {
    getTitle: () => <Trans>Enable two-factor authentication to unlock this feature</Trans>,
    description: <Trans>Please verify your account in <i>Profile and settings</i>.</Trans>,
    settingsLink: '/me/security',
    buttonLabel: 'Go to settings',
    isShowKycBottomSection: false,
  },
  // shown when user logs in for first time and has not completed KYC for both global and sg users
  default: {
    getTitle: () => <Trans>Verify your account to get started.</Trans>,
    description: kycRequired.description,
    settingsLink: kycRequired.settingsLink,
    buttonLabel: kycRequired.buttonLabel,
    isShowKycBottomSection: true,
  },
};

const useStyles = createUseStyles(styles);

interface KycUnverifiedModalProps {
  action: string;
  onClose: () => void;
  isSwap?: boolean;
  showHeader?: boolean;
  showCancelButton?: boolean,
}

export default function KycUnverifiedModal(props: KycUnverifiedModalProps) {
  const { action, onClose, isSwap = false, showHeader = true, showCancelButton = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { accessRights, details } = useCakeSelector((state) => state.user);
  const { closeModal } = useContext(ModalContext);
  const { isUserEligibleStaggeredKyc } = useCakeSelector(staggeredKycUserStatusSelector);
  const { sumsubKycStatus, isOpenToReKyc, poiExpiryDate } = useCakeSelector(userSumsubKycStatusSelector);

  if (!accessRights[action] && action !== 'mfaVerification') {
    return null;
  }

  if (!details.phoneNumberVerified) {
    return <KycUnverifiedOnLoginModal onClose={onClose} modalType='PHONE_VERIFICATION' showCancelButton={showCancelButton} />;
  }

  // manually passing action to update phone number on profile page to check if user has otp verified or not
  const reasons = action === 'mfaVerification' ? ['TwoFARequired'] : accessRights[action].reasons;

  const isKycInReview = reasons.length > 0
    && ['PENDING', 'SCREENING'].includes(sumsubKycStatus)
    && ['KycRequired', 'LiteKycRequired'].includes(reasons[0]);

  const isKycRejected = reasons.length > 0
    && ['REJECTED'].includes(sumsubKycStatus)
    && ['KycRequired', 'LiteKycRequired'].includes(reasons[0]);

  const isMyinfoPending = details.kycProvider === 'MYINFOSG' && details.kycFullStatus === 'PENDING';

  const isKycExpired = isOpenToReKyc
    && ['REJECTED'].includes(sumsubKycStatus)
    && poiExpiryDate
    && moment().isAfter(poiExpiryDate);

  const isMigrationKycExpired = details.kycMigration?.status === 'PENDING_MIGRATION'
    && details.kycMigration?.lastDayToMigrate
    && moment().isAfter(details.kycMigration?.lastDayToMigrate);

  let content = screenContent.default;

  if (reasons.length > 0) {
    content = screenContent[reasons[0]]; // nosemgrep: javascript.lang.security.audit.detect-bracket-object-injection.detect-bracket-object-injection
    if (isKycInReview && !isMyinfoPending) {
      content = screenContent.KycInReviewing;
    } else if (isKycExpired || isMigrationKycExpired) {
      content = screenContent.KycExpired;
    } else if (isKycRejected) {
      content = screenContent.KycRejected;
    }
  }
  const settingsRedirectUrl = reasons.length === 0 ? '/me' : content?.settingsLink || '/me';

  if (details.kycFullStatus === 'FINALLYREJECTED' || sumsubKycStatus === 'FINALLYREJECTED') {
    return <KycModal onClose={closeModal} modalType="BLACKLISTED"/>;
  }

  return (
    <>
      { showHeader && <ModalHeader title={t('Hi Baker!')} showCancelButton={showCancelButton} onClose={onClose} cancelLabel='Close'/> }
      <ModalContent>
        <div className={classes.content}>
          <img src={onboardIdentity} alt='onboard-identity' className={classes.image}/>
          <h3>{isSwap ? content.getTitle(actionToProductNameMap.swapOnDex) : content.getTitle(actionToProductNameMap[action])}</h3>
          { content?.description && <div>{content.description}</div> }
          { content?.isShowKycBottomSection ? <BottomKycBenefitsForModal /> : null }
        </div>
      </ModalContent>
      <ModalFooter styleType="footerSpaceBetween">
        <KycCurrentLevel level={getLevelDisplay(details?.kycTier, details?.kycFullStatus, isUserEligibleStaggeredKyc)} />
        { content.buttonLabel && <a href={settingsRedirectUrl} onClick={props.onClose}> {/* nosemgrep: typescript.react.security.audit.react-href-var.react-href-var */}
          <Button main>
            <Trans i18nKey={content.buttonLabel}>{content.buttonLabel}</Trans>
          </Button>
        </a> }
      </ModalFooter>
    </>
  );
}
