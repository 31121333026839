import React from 'react';
import ReactSvg from 'react-svg';
import youtube from '../../../assets/svg/social-youtube.svg';

export default function YotubeIcon() {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/c/bake_io">
      <ReactSvg
        src={youtube}
        className="youtube-icon"
      />
    </a>
  );
}
