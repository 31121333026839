import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Switch({
  isActive,
  onChange,
  isDisable = false,
  children = null,
  className = '',
  tabIndex = 0,
  inputRef = null,
}) {
  const id = uuidv4();
  const classes = [isActive && 'cakepool-switch--active', isDisable && 'cakepool-switch--disabled', className].filter(Boolean).join(' ');

  const onInputKeyPress = (e) => {
    if (e.keyCode === 13) { // enter
      const switchClassName = inputRef?.current ? inputRef.current.getAttribute('class') : '';
      if (switchClassName.indexOf('cakepool-switch') !== -1) {
        onChange(!isActive);
      }
    }
  };

  return (
    <span className={`cakepool-switch ${classes}`} onKeyDown={e => onInputKeyPress(e)} onClick={() => !isDisable && onChange(!isActive)}>
      <span className="cakepool-switch__switch" id={id}>
        <span className="cakepool-switch__track" />
        <span className="cakepool-switch__ball" tabIndex={tabIndex} ref={inputRef}/>
      </span>
      {children}
    </span>
  );
}
