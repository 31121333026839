import learnEarnContent, { RewardType, Stack } from './data/learnEarnContent';

export const getStackById = (stackId: string): Stack => learnEarnContent[stackId] as Stack;

export const getLessonIndexFromId = (quizQuestionId: string, stackId: string): number => {
  const stack = getStackById(stackId);
  const selectedLesson = stack.lessons.find(lesson => lesson.quizQuestionId === quizQuestionId);
  return selectedLesson.lessonIndex;
};

export const getIsNotComingSoon = (stackId: string): boolean => {
  const stackKeysWithlessons = Object.keys(learnEarnContent).filter(key => 'lessons' in learnEarnContent[key]);
  return stackKeysWithlessons.includes(stackId);
};

export const getRewardLabel = (coinIdInput: string, rewardTypeInput: RewardType) => {
  switch (rewardTypeInput) {
    case 'FrozenLiquidityMiningShare':
      return `${coinIdInput}-DFI`;
    case 'FrozenStakingShare':
    default:
      return coinIdInput;
  }
};
