import { WalletBalance } from '@cakedefi/cake-sdk';
import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import { NewFreezerData } from './freezer-slice';
import { FreezerTransparencyStats } from './freezer-transparency-stats';
import { FreezeType } from './freezer';

export type FreezerPageResponse = NewFreezerData
  & {
    balances: WalletBalance[],
    coinPrices: CoinPrice[],
    transparencyStats: FreezerTransparencyStats,
    anniversaryPromotionIsActive: boolean,
  };

export type NewFreezeEnriched = {
  id: string,
  amountFrozen: string,
  coinId?: string,
  pairId?: string,
  product: FreezerProduct,
  decimals: number,
  value: string,
  valueDecimals: number,
  defrostDate: string,
  baseApy: string,
  bonusApy: string,
  tenure: number,
  autoRenew: boolean,
  isExtending: boolean,
  type: FreezeType;
  createdAt: string;
};

export type FreezerComponentProps<T> = {
  freeze: T,
};

export enum FreezerProduct {
  STAKING = 'STAKING',
  LIQUIDITY_MINING = 'LIQUIDITY_MINING',
}
