import React,
{ Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Icon,
  Timer,
} from '../../components';

const toasterConfiguration = {
  autoClose: 10000, // 10 seconds
};

export default class Toaster extends Component {
  state = {

  }

  constructor() {
    super();
    const { cakepool } = window;
    /*
    messageOrRenderer: {string| function} to render the content
    options: {object} any properties to be passed to the Toastify.toast in general:
      autoClose: whether want it to have timeout to automatically close itself or not

      custom properties:
      iconName: to show the icon from ../../components/icon based on the name
      isDisableTimer: to not render the timer
      secondsAgo: as the starting point of how many seconds for the timer
      atDateTime: same function for secondsAgo, but this is datetime object to passed to moment(...) and getting calculated
    */
    cakepool.notify = (messageOrRenderer, options = {}) => showToaster(messageOrRenderer, options);
    cakepool.removeNotifications = () => toast.dismiss();
    toast.configure(toasterConfiguration);
  }

  render() {
    return (
      <ToastContainer />
    );
  }
}

function showToaster(messageOrRenderer, options = {}) {
  toast(wrapRenderer(messageOrRenderer, options), {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: 'cakepool-toaster',
    bodyClassName: 'cakepool-toaster__body',
    progressClassName: 'cakepool-toaster__progress',
    pauseOnFocusLoss: false,
    hideProgressBar: true,
    closeButton: false,
    ...options,
  });
}

function wrapRenderer(messageOrRenderer, options) {
  const {
    iconName,
    isDisableTimer,
    secondsAgo = 0,
    atDateTime = null,
    actionBtn = null,
  } = options;
  let content = messageOrRenderer;
  if (typeof (messageOrRenderer) === 'function') {
    content = messageOrRenderer();
  }
  let icon = null;
  if (iconName) {
    icon = (
      <span className="cakepool-toaster__content-icon">
        <Icon name={iconName} />
      </span>);
  }
  let timer = null;
  if (!isDisableTimer) {
    timer = (
      <span className="cakepool-toaster__timer">
        <Timer secondsAgo={secondsAgo} atDateTime={atDateTime} />
      </span>
    );
  }
  const className = `cakepool-toaster__content ${iconName ? 'cakepool-toaster__content--with-icon' : ''}`;
  return () => (
    <span className={className}>
      {icon}
      {content}
      {timer}
      {actionBtn}
    </span>
  );
}
