import { Component, ReactNode } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * ErrorBoundary is a component that catches errors anywhere in its child component tree,
 * and displays a fallback UI instead of the component tree that crashed.
 *
 * This is copied from React docs:
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * This is written as a class component since getDerivedStateFromError is not supported in function components
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * this returns an object instead of using this.setState() because it is a static class
   */
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error(error, info);
    const errorMessage = error?.message ? `ErrorBoundary: ${error.message}` : 'ErrorBoundary';
    datadogLogs.logger.error(errorMessage, { info }, error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Render the fallback UI
      return this.props.fallback;
    }

    // Render the children if there's no error
    return this.props.children;
  }
}

export default ErrorBoundary;
