/* eslint-disable camelcase */
import { SsoAuthLogin } from '../../schema/sso';
import { CakeApiBase } from '../CakeApiBase';
import { OTPType } from './UserInfoApi';

export class SsoApi extends CakeApiBase {
  async confirmRegister(
    ssoAuthToken: string,
    currentLanguage: string,
    isGivenDataConsent: boolean,
    isGivenMarketingConsent: boolean,
    promoCode: string | null | undefined,
    referredById: string | null | undefined,
    utm_source: any,
    utm_medium: any,
    utm_campaign: any,
    revealInfoToReferrer: boolean,
    learnAndEarnCode: string,
    reCaptchaToken: string,
    voucherCode: string,
    country: string | undefined,
  ) {
    return this.requestManager.post<SsoAuthLogin>('auth/confirm-register', {
      ssoAuthToken,
      currentLanguage,
      isGivenDataConsent,
      isGivenMarketingConsent,
      promoCode,
      referredById,
      utm_source,
      utm_medium,
      utm_campaign,
      revealInfoToReferrer,
      learnAndEarnCode,
      'g-recaptcha-response': reCaptchaToken,
      voucherCode,
      country,
    });
  }

  async confirmLogin(
    ssoAuthToken: string,
    reCaptchaToken: string,
    tempOtpAuthToken = '',
    otp = '',
    otpMethod = OTPType.TOTP,
  ) {
    return this.requestManager.post<SsoAuthLogin>('auth/confirm-login', {
      ssoAuthToken,
      tempOtpAuthToken,
      otp,
      otpMethod,
      'g-recaptcha-response': reCaptchaToken,
    });
  }
}
