import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { ModalHeader, ModalContent, ModalFooter } from 'modals/components';
import { setQuizInput, goToModal, saveCurrentProgressForModal } from 'learn-earn/learnEarnSlice';
import { Trans } from 'react-i18next';
import modalStyles from 'common/styles/Modal.styles';
import { useCakeSelector } from 'app/rootReducer';
import { getCurrentQuizData } from 'learn-earn/learnEarnSelector';
import Variables, { getMinWidthSm } from 'Variables.styles';
import ModalFooterButton from 'modals/components/ModalFooterButton';
import LessonModalQuizOption from './LessonModalQuizOption';
import { ModalType } from './LessonModal';

const useStyles = createUseStyles({
  ...modalStyles,
  lessonContentHeight: {
    minHeight: '200px',
  },
  secondaryTheme: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: Variables.primaryBorder,
  },
  [getMinWidthSm]: {
    lessonContentHeight: {
      minHeight: '540px',
    },
  },
});

interface LessonModalQuizProps {
  closeModal: () => void;
  headerTitle: string;
}

const LessonModalQuiz = ({ closeModal, headerTitle }: LessonModalQuizProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details: userDetails } = useCakeSelector(state => state.user);
  const { quizData, userQuizInput } = useCakeSelector(getCurrentQuizData);

  useEffect(() => {
    if (userDetails) {
      dispatch(saveCurrentProgressForModal({ modal: 'QUIZ' }));
    }
  }, [dispatch, userDetails]);

  const onClickBack = () => {
    dispatch(goToModal(ModalType.LESSON_MODAL));
  };

  const onSubmit = async () => {
    dispatch(goToModal(ModalType.RESULT_MODAL));
  };

  const setSelectedQuizAnswer = (option: string) => {
    dispatch(setQuizInput(option));
  };

  return (
    <>
      <ModalHeader title={headerTitle} onClose={closeModal}/>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.lessonContentHeight}`}>
          <p><Trans i18nKey={quizData.question}>{quizData.question}</Trans></p>
          {
            quizData.options.map(quizItem => <LessonModalQuizOption
              key={quizItem.option}
              quizOptionData={quizItem}
              selected={userQuizInput === quizItem.option}
              setSelectedQuizAnswer={setSelectedQuizAnswer}
              />)
          }
        </div>
      </ModalContent>
      <ModalFooter styleType="footerSpaceBetween">
        <ModalFooterButton primary className={classes.secondaryTheme} buttonText="Back" onClick={onClickBack} arrow="left" />
        <ModalFooterButton primary disabled={!userQuizInput} onClick={onSubmit} buttonText="Submit" />
      </ModalFooter>
    </>
  );
};

export default LessonModalQuiz;
