import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VoucherState {
  isLoading: boolean;
  voucherCode: string;
}

const initialState: VoucherState = {
  isLoading: false,
  voucherCode: '',
};

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setVoucherCodeInLocal: (state, action: PayloadAction<{code: string}>) => {
      const { code } = action.payload;
      state.voucherCode = code;
    },
    setVoucherLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setVoucherCodeInLocal,
  setVoucherLoading,
} = voucherSlice.actions;

export default voucherSlice.reducer;
