import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../types/common/state';
import { FreezerTransparencyState } from '../../types/freezer/freezer-transparency-stats';
import { fetchFreezerPage, fetchFreezerPageAuthenticated } from '../freezerSlice';

const initialState: FreezerTransparencyState = {
  data: {
    tenureChoiceDistribution: null,
    remainingTenureDistribution: null,
    totalFrozenAssets: null,
  },
  anniversaryPromotionIsActive: false,
  status: StateStatus.STARTED,
  error: null,
};

const freezerHighlightsSlice = createSlice({
  name: 'freezerHighlights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFreezerPage.fulfilled, (state, action) => {
      state.data = action.payload;
      state.anniversaryPromotionIsActive = action.payload.anniversaryPromotionIsActive;
      state.status = StateStatus.FINISHED;
    });
    builder.addCase(fetchFreezerPageAuthenticated.fulfilled, (state, action) => {
      state.data = action.payload.transparencyStats;
      state.anniversaryPromotionIsActive = action.payload.anniversaryPromotionIsActive;
      state.status = StateStatus.FINISHED;
    });
    builder.addCase(fetchFreezerPage.pending, (state) => {
      state.status = StateStatus.LOADING;
    });
    builder.addCase(fetchFreezerPageAuthenticated.pending, (state) => {
      state.status = StateStatus.LOADING;
    });
    builder.addCase(fetchFreezerPage.rejected, (state, action) => {
      state.error = action.error;
      state.status = StateStatus.ERROR;
    });
    builder.addCase(fetchFreezerPageAuthenticated.rejected, (state, action) => {
      state.error = action.error;
      state.status = StateStatus.ERROR;
    });
  },
});

export default freezerHighlightsSlice.reducer;
