import { Home } from '../../schema/home';
import { CakeApiBase } from '../CakeApiBase';

export class HomeApi extends CakeApiBase {
  async home() {
    return this.requestManager.get<Home>('home');
  }

  async homeAuthenticated() {
    return this.requestManager.get<Home>('home/authenticated');
  }
}
