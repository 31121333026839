import React from 'react';
import BigNumber from 'bignumber.js';
import { Trans } from 'react-i18next';
import { CoinPrice, UserAccessRights } from '@cakedefi/cake-sdk/schema';
import { isCountryRestricted } from 'utils/isCountryRestricted';
import { localizeBigNumber } from '../utils';
import isShareEmpty from '../utils/isShareEmpty';
import { clear as clearStaking } from './StakingPageSlice';

export const NODES_PER_PAGE = 100;
export const ALL_COIN_OBJECT = {
  label: <Trans>All coins</Trans>,
  value: 'AllCoins',
};
export const NATIVE_COIN_OBJECT = {
  value: 'Native',
  label: <Trans>Native currency</Trans>,
};
export const CoinSlideState = {
  PREV: 'prevPage',
  NEXT: 'nextPage',
};

export const clearStakingPageStore = (dispatch) => {
  dispatch(clearStaking());
};

export const mapCoinsAndStakeableShares = (coinPrices: CoinPrice[], shares, coins, accessRights: UserAccessRights) => {
  const coinPricesMapped = {};
  const usdtAccessRights = accessRights?.usdtAccess ? !isCountryRestricted(accessRights.usdtAccess) : true;
  coinPrices.forEach((coin) => {
    coinPricesMapped[coin.CoinId] = coin.coinSwapPrices;
  });

  const stakeableShares = shares.filter(share => (share.id !== 'USDT' || usdtAccessRights) && share.stakeable);
  stakeableShares.sort((a, b) => {
    const aNoShare = isShareEmpty(a);
    const bNoShare = isShareEmpty(b);
    const sameStateAvailability = aNoShare === bNoShare;
    if (sameStateAvailability) {
      const aReturnPA = new BigNumber(a.stakingApy);
      const bReturnPA = new BigNumber(b.stakingApy);
      if (aReturnPA.isGreaterThan(bReturnPA)) return -1;
      return 1;
    }
    if (aNoShare) return 1;
    if (bNoShare) return -1;
    return 0;
  });

  const stakingApyShares = stakeableShares.filter(({ stakingApy }) => stakingApy !== 'NaN');
  const newStakingCoinIds = coins.filter(coin => coin?.isNewStakingCoin).map(coin => coin.id);

  const enrichedStakingApyShares = newStakingCoinIds.length ? stakingApyShares.map(share => {
    if (newStakingCoinIds.includes(share.id)) {
      return ({
        ...share,
        isNewStakingCoin: true,
      });
    }
    return share;
  })
    : stakingApyShares;

  return {
    stakeableShares,
    stakingApyShares: enrichedStakingApyShares,
    coinPricesMapped,
  };
};

export const processWalletByCoins = (coins, shares, wallets) => {
  const processedCoins = coins.map((coin) => {
    const share = shares.find(({ id }) => id === coin.id);
    return {
      ...share,
      ...coin,
      share: {
        locked: 0,
        movable: 0,
        total: 0,
        priceUSD: coin.priceUSD,
      },
      native: {
        locked: 0,
        movable: 0,
        total: 0,
        priceUSD: coin.priceUSD,
      },
    };
  });

  const processedWalletByCoins = processedCoins.reduce((walletByCoins, coin) => {
    walletByCoins[coin.id] = coin;
    return walletByCoins;
  }, {});

  if (wallets) {
    for (let i = 0; i < wallets.length; i += 1) {
      const wallet = wallets[i];
      const coinId = wallet.coin.id;
      if (processedWalletByCoins[coinId]) {
        processedWalletByCoins[coinId][wallet.kind.toLowerCase()] = wallet;
      }
    }
  }

  return processedWalletByCoins;
};

export const getPriceForShare = (selectedCoin, coinId, coinPricesMapped) => {
  const selectedCoinValue = selectedCoin || NATIVE_COIN_OBJECT.value;
  const coinPriceForShare = coinPricesMapped[coinId];
  if (selectedCoinValue === 'Native') {
    return `1 ${coinId}`;
  }
  if (coinPriceForShare) {
    const baseCoinPrice = coinPriceForShare[selectedCoinValue] || 0;
    const roundedBaseCoinPrice = localizeBigNumber(baseCoinPrice, false, 8, BigNumber.ROUND_HALF_UP);
    return `${roundedBaseCoinPrice} ${selectedCoin}`;
  }
  return 'N/A';
};

export const mapCoinsAndNodes = (coins, allNodes) => {
  const coinMap = new Map();
  allNodes.forEach((node) => {
    const { coin } = node;
    if (!coinMap.has(coin)) {
      coinMap.set(coin, {
        coin,
        nodes: [],
        activePage: 1,
      });
    }
    const coinDetail = coinMap.get(coin);
    coinDetail.nodes.push(node);
  });

  coinMap.forEach((value) => {
    const { nodes } = value;
    value.pageCount = Math.ceil(nodes.length / NODES_PER_PAGE);
  });

  return coins.map((coin) => {
    const { name } = coin;
    const coinWithNodes = coinMap.get(name) || {};
    return {
      ...coin,
      nodes: coinWithNodes.nodes || [],
      pageCount: coinWithNodes.pageCount || 0,
      activePage: 1,
      slideState: null,
      isOpen: false,
    };
  });
};

export const findCoinIndex = (state, coin) => state.nodeCoins.findIndex(({ id }) => id === coin.id);
