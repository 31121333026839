import React from 'react';
import { Trans } from 'react-i18next';
import Variables from 'Variables.styles';
import HeaderLogo from 'views/containers/MainLayout/HeaderLogo';
import { createUseStyles } from 'react-jss';
import RegisterForm from '../RegisterForm';
import AffiliateBlock from './AffiliateBlock';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12rem',
    marginBottom: '12rem',
    '& > a': {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    marginBottom: Variables.large,
  },
});

const RegisterSection = ({ page, withLogo = false }) => {
  const classes = useStyles();
  return (
    <article className="floating">
      <div>
        <h1 className={classes.title}>
          <Trans>Sign up</Trans>
        </h1>
        <AffiliateBlock />
        <div>
          <RegisterForm page={page} />
        </div>
        {withLogo && (
          <div className={classes.header}>
            <HeaderLogo invert={true} />
          </div>
        )}
      </div>
    </article>
  );
};

export default RegisterSection;
