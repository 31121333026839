import React,
{ Component } from 'react';
import CakeSpinner from '../../components/Spinner';

export default class Spinner extends Component {
  constructor() {
    super();
    this.state = {
      isVisible: false,
      className: '',
    };
    const { cakepool } = window;
    cakepool.showSpinner = options => this.showSpinner(options);
    cakepool.hideSpinner = () => this.hideSpinner();
    cakepool.isSpinnerVisible = () => this.isSpinnerVisible();
  }

  showSpinner(options = {}) {
    this.setState({
      className: '',
      ...options,
      isVisible: true,
    });
  }

  hideSpinner() {
    this.setState({
      isVisible: false,
    });
  }

  isSpinnerVisible() {
    return this.state.isVisible;
  }

  getCssClasses() {
    const {
      className,
    } = this.state;
    return `cakepool-spinner ${className}`;
  }

  render() {
    const {
      isVisible,
    } = this.state;
    if (!isVisible) {
      return null;
    }
    const cssClasses = this.getCssClasses();
    return (
      <div className={cssClasses}>
        <CakeSpinner />
      </div>
    );
  }
}
