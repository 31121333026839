import React from 'react';
import { Trans, Translation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import {
  Col,
  Row,
  Container,
  Table,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextInput from 'common/components/TextInput/TextInput';
import { isShowLendingBannerForUSSelector, userAccessRightsSelector } from 'user/userSelector';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import isAmountLessThanMinimumInUsd from 'utils/checkAmountLessThanMinimumInUsd';
import { AccessRights } from 'types/access-rights/access-rights';
import SprodDetails from './SprodDetails';
import BasePage from '../BasePage';
import {
  CheckBox,
  ClickableElement,
  Modal,
  MouseOverTooltip,
} from '.';
import sdk from '../../sdk';
import { getCoinIconName, isPositiveAndNotZeroNumber, isValidDecimalNumber, localizeBigNumber } from '../../utils';
import getLocalizedDate from '../../utils/getLocalizedDate';
import calculateTargetBatchReturnMultiplier from '../../utils/calculateTargetBatchReturnMultiplier';
import KycUnverifiedModal from '../containers/SharedComponents/kyc/KycUnverifiedModal';
import { reloadUser } from '../../user/userSlice';
import { clearModalOpenOnRedirect } from '../../navigation/navigationSlice';
import Button from '../../common/components/Button/Button';
import BigNumberDisplay from '../../common/components/BigNumberDisplay/BigNumberDisplay';
import ModalLoadingIndicator from '../../common/components/ModalLoadingIndicator/ModalLoadingIndicator';
import Icon from './Icon';
import { fetchSprodMinAmountInUsd } from './Sprod/state/sprodSlice';
import { minAmountInUsdSprodSelector } from './Sprod/state/sprodSelector';

const mapState = (state) => ({
  accessRights: userAccessRightsSelector(state),
  userDetails: state.user.details,
  modalOpenOnRedirect: state.navigation.modalOpenOnRedirect,
  isRestrictUser: isShowLendingBannerForUSSelector(state),
  minAmountInUsd: minAmountInUsdSprodSelector(state),
});
const mapDispatch = ({ reloadUser, clearModalOpenOnRedirect, fetchSprodMinAmountInUsd });

class SprodEntry extends BasePage {
  state = {
    entryAmount: '0',
    updateEntry: false,
    cancelEntry: false,
    showDetails: false,
    isAcceptSprodEntryTerms: false,
    isEntryModalVisible: false,
    compoundOnSettle: this.props.userDetails ? this.props.userDetails.preference.compoundOnSettle : null,
    preference: {},
    isKycUnverfiedModalVisible: false,
    isProcessingSprodEntryUpdate: false,
  }

  componentDidMount() {
    const { modalOpenOnRedirect, coinSymbol } = this.props;
    if (modalOpenOnRedirect.modal === 'LAPIS' && modalOpenOnRedirect.props.coin === coinSymbol) {
      const { status, accessRights } = this.props;
      const { access } = accessRights.enterSprod;

      const capReached = this.batchCapReached();
      const isAcceptingEntries = status === 'SELLING';

      if (access && isAcceptingEntries && !capReached) {
        this.setEntryAmount(null);
        this.onClickEnter();
      }
      this.props.clearModalOpenOnRedirect();
    }
    this.props.fetchSprodMinAmountInUsd();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { compoundOnSettle } = this.props;
    const { isEntryModalVisible } = this.state;
    if (prevProps.compoundOnSettle !== compoundOnSettle) {
      this.setState({
        compoundOnSettle: compoundOnSettle || (this.props.userDetails ? this.props.userDetails.preference.compoundOnSettle : null),
      });
    }
    if (isEntryModalVisible !== prevState.isEntryModalVisible && isEntryModalVisible) {
      const user = await this.props.reloadUser();
      this.setState({
        preference: user.preference,
      });
    }
  }

  setEntryAmount = (entryAmount) => {
    if (!isValidDecimalNumber(entryAmount)) return;
    const { coin } = this.props;

    if (new BigNumber(entryAmount).dp() > coin.decimals) {
      this.setState({
        entryAmount: new BigNumber(entryAmount).toFormat(coin.decimals, BigNumber.ROUND_DOWN),
      });
      return;
    }

    this.setState({
      entryAmount,
    });
  }

  onClickEnter = () => {
    const { previouslyEnteredAmount } = this.props;
    this.setState({
      updateEntry: false,
      cancelEntry: false,
      showDetails: false,
    });
    if (!this.props.userDetails) {
      this.navigate('/login');
      return;
    }

    this.setEntryAmount(new BigNumber(previouslyEnteredAmount).toFixed());
    this.setState({
      isEntryModalVisible: true,
    });
  }

  canEnter = () => this.hasEnoughBalance() && this.anythingHasChanged() && this.isPositiveEntryAmount() && !this.wouldGoOverBatchCap() && !this.renderIsAmountLessThanMinimum();

  entryAmountHasChanged = () => {
    const { previouslyEnteredAmount } = this.props;
    const { entryAmount } = this.state;
    const previusAmountBN = new BigNumber(previouslyEnteredAmount);
    const entryAmountBN = new BigNumber(entryAmount);
    return !(previusAmountBN.eq(entryAmountBN));
  }

  autoCompoundSettingHasChanged = () => {
    const { compoundOnSettle: oldCompoundSetting } = this.props;
    const { compoundOnSettle } = this.state;
    return compoundOnSettle !== oldCompoundSetting;
  }

  anythingHasChanged = () => this.entryAmountHasChanged() || this.autoCompoundSettingHasChanged();

  isPositiveEntryAmount = () => {
    const { entryAmount } = this.state;
    const isEnterAmountPositive = isPositiveAndNotZeroNumber(entryAmount);
    return isEnterAmountPositive;
  }

  hasEnoughBalance = () => {
    const {
      coinBalance,
      previouslyEnteredAmount,
    } = this.props;

    const { entryAmount } = this.state;
    const entryAmountBN = new BigNumber(entryAmount);
    const hasEnoughBalance = new BigNumber(coinBalance).plus(previouslyEnteredAmount).isGreaterThanOrEqualTo(entryAmountBN);

    return hasEnoughBalance;
  }

  setValue(key, value) {
    this.setState({
      [key]: value,
    });
  }

  toggleDetailsModal = () => {
    const { updateEntry, showDetails } = this.state;
    this.setState({
      updateEntry: !updateEntry,
      showDetails: !showDetails,
    });
  }

  toggleModalContent = () => {
    const { updateEntry } = this.state;
    this.setState({
      updateEntry: !updateEntry,
    });
  }

  toggleCancelModal = () => {
    const { cancelEntry } = this.state;
    this.setState({
      cancelEntry: !cancelEntry,
    });
  }

  cancelSprodEntry = async () => {
    const {
      coinSymbol,
      batchId,
      batchStatus,
      refreshEnteredAmount,
      loadBalances,
      refreshBatches,
    } = this.props;
    const { entryAmount, isProcessingSprodEntryUpdate } = this.state;

    if (isProcessingSprodEntryUpdate) {
      return; // Probably unnecessary, just to make sure
    }

    this.setState({ isProcessingSprodEntryUpdate: true });

    try {
      const body = {
        sprodBatchId: batchId,
      };
      await sdk.SprodApi.cancelEntry(body);
      await refreshBatches();
      this.cakepool.showAlert('success', <span><Trans>Cancelled entry successfully of {{ entryAmount }} {{ coinSymbol }}</Trans></span>);
      this.setState({
        cancelEntry: false,
      });
      this.setState({
        isEntryModalVisible: false,
      });

      await refreshEnteredAmount(batchId, batchStatus);
      await loadBalances();
    } catch (err) {
      const { message } = err;
      this.cakepool.showAlert('error', <span><Trans>Cancellation of entry failed</Trans>: <Trans>{message}</Trans></span>);
    }

    this.setState({ isProcessingSprodEntryUpdate: false });
  }

  updateSprodEntry = async () => {
    const {
      coinSymbol,
      batchId,
      batchStatus,
      refreshEnteredAmount,
      refreshAutoCompoundSettings,
      loadBalances,
      previouslyEnteredAmount,
      refreshBatches,
    } = this.props;
    const { compoundOnSettle, isProcessingSprodEntryUpdate } = this.state;

    if (isProcessingSprodEntryUpdate) {
      return;
    }

    this.setState({ isProcessingSprodEntryUpdate: true });
    const actualEntryAmount = this.getActualEntryAmount();
    const entryAmountChanged = !(new BigNumber(actualEntryAmount).eq(previouslyEnteredAmount));

    try {
      if (entryAmountChanged) {
        await sdk.SprodApi.enterSprod(actualEntryAmount, batchId);
        await refreshBatches();
      }

      await sdk.SprodApi.updateBatchSettings(batchId, { autoCompound: compoundOnSettle });
      await this.props.reloadUser();
      if (entryAmountChanged) {
        this.cakepool.showAlert('success', <span><Trans>Entered batch successfully with</Trans> {actualEntryAmount} {coinSymbol}</span>);
      } else {
        this.cakepool.showAlert('success', <span><Trans>Successfully updated auto compound settings</Trans></span>);
      }

      this.setState({
        updateEntry: false,
        isEntryModalVisible: false,
      });

      await refreshEnteredAmount(batchId, batchStatus);
      await refreshAutoCompoundSettings(batchId);
      await loadBalances();
      gtmTrack('trackEvent', {
        event: iEventNames.allocate_product,
        event_type: iEventTypes.custom,
      });
    } catch (err) {
      const { message } = err;
      this.setState({ isEntryModalVisible: false });
      if (entryAmountChanged) {
        this.cakepool.showAlert('error', <span><Trans>Entering batch failed</Trans>: <Trans>{message}</Trans></span>);
      } else {
        this.cakepool.showAlert('error', <span><Trans>Updating auto compound settings failed</Trans>: <Trans>{message}</Trans></span>);
      }
    }
    this.setState({ isProcessingSprodEntryUpdate: false });
  }

  getActualEntryAmount = () => {
    const {
      batchCap,
      previouslyEnteredAmount,
      startingAmount,
      estimatedAutocompoundAmount,
    } = this.props;
    const { entryAmount } = this.state;

    const entryAmountBN = new BigNumber(entryAmount);
    const batchCapBN = new BigNumber(batchCap);
    const currentStartingAmountBN = new BigNumber(startingAmount);
    const estimatedAutocompoundAmountBN = estimatedAutocompoundAmount ? new BigNumber(estimatedAutocompoundAmount) : new BigNumber(0);

    if (entryAmountBN.isLessThan(previouslyEnteredAmount)) {
      return entryAmount;
    }

    const entryAmountDifferenceAfterFees = entryAmountBN.minus(previouslyEnteredAmount);
    const newStartingAmount = currentStartingAmountBN.plus(entryAmountDifferenceAfterFees);
    const batchCapForNewEntries = batchCapBN.minus(estimatedAutocompoundAmountBN);

    if (newStartingAmount.isGreaterThan(batchCapForNewEntries)) {
      const amountToFillUpBatch = batchCapForNewEntries.minus(currentStartingAmountBN).plus(previouslyEnteredAmount);
      return amountToFillUpBatch.toFixed();
    }
    return entryAmount;
  }

  renderDetails() {
    const {
      name,
      slug,
      coin,
      tier2Threshold,
      tier2Apy,
      returnPerAnnum,
    } = this.props;
    return (
      <Container>
        <SprodDetails name={name} slug={slug} coinName={coin.name} coinId={this.props.coin.id} tier2Threshold={tier2Threshold} tier2Apy={tier2Apy} returnPerAnnum={returnPerAnnum} />
        <div className="entry-modal-footer">
          <Button primary className="fright" onClick={this.toggleDetailsModal}><Trans>Back</Trans></Button>
        </div>
      </Container>
    );
  }

  setEntryMaxAmount(maxAmount) {
    this.setState({
      entryAmount: maxAmount,
    });
  }

  calculateBatchReturn(returnPerAnnum) {
    const {
      durationInDays,
    } = this.props;
    const { entryAmount } = this.state;

    const targetBatchReturnMultiplier = calculateTargetBatchReturnMultiplier(durationInDays, returnPerAnnum);
    return new BigNumber(entryAmount).multipliedBy(targetBatchReturnMultiplier);
  }

  calculateExpectedDfiReturn = () => {
    const { dfiRewardPerAnnum, coin, dfiCoin } = this.props;
    const { priceUSD } = coin;
    const returnInNativeCoin = this.calculateBatchReturn(dfiRewardPerAnnum);
    const returnInDollar = new BigNumber(returnInNativeCoin).multipliedBy(priceUSD);
    return returnInDollar.dividedBy(dfiCoin.priceUSD);
  }

  renderEntryConfirmation() {
    const {
      isAcceptSprodEntryTerms,
      entryAmount,
      compoundOnSettle,
      isProcessingSprodEntryUpdate,
    } = this.state;
    const {
      name,
      batchNumber,
      previouslyEnteredAmount,
      saleClosesOn,
      maturesOn,
      coinSymbol,
      bonusReturn,
      bonusTarget,
      returnPerAnnum,
      coin,
      dfiCoin,
      dfiRewardPerAnnum,
    } = this.props;

    if (isProcessingSprodEntryUpdate) {
      return (
        <Container className="modal-loading">
          <ModalLoadingIndicator />
        </Container>
      );
    }

    const updateButtonLabel = previouslyEnteredAmount === '0' ? 'Enter' : 'Update';
    const batchNumberStr = batchNumber.toString();
    const canEnter = isAcceptSprodEntryTerms && !isProcessingSprodEntryUpdate;
    const bonusTargetInPercent = bonusTarget ? new BigNumber(bonusTarget).multipliedBy(100).toFixed() : null;
    const returnWithBonus = bonusReturn ? new BigNumber(returnPerAnnum).plus(bonusReturn).toFixed() : null;

    const expectedReturnWithoutBonus = localizeBigNumber(this.calculateBatchReturn(returnPerAnnum), false, coin.decimals);
    const expectedReturnWithBonus = returnWithBonus ? localizeBigNumber(this.calculateBatchReturn(returnWithBonus), false, coin.decimals) : null;
    const expectedDfiReturn = dfiRewardPerAnnum ? localizeBigNumber(this.calculateExpectedDfiReturn(), false, dfiCoin.decimals) : null;

    return (
      <Container className="update-entry-modal">
        <h2><Trans>Entering</Trans> {name}</h2>
        <Table>
          <tbody>
            <tr>
              <td><b><Trans>Batch number</Trans></b></td>
              <td>{batchNumber}</td>
            </tr>
            <tr>
              <td><b><Trans>Start date</Trans></b></td>
              <td>{getLocalizedDate(saleClosesOn)}</td>
            </tr>
            <tr>
              <td><b><Trans>End date</Trans></b></td>
              <td>{getLocalizedDate(maturesOn)}</td>
            </tr>
            <tr>
              <td><b><Trans>My entry</Trans></b></td>
              <td>{new BigNumber(entryAmount).toFormat()} {coinSymbol}</td>
            </tr>
            <tr>
              <td><b><Trans>Expected return in {{ coinSymbol }}</Trans></b></td>
              <td>{expectedReturnWithoutBonus} {coinSymbol}</td>
            </tr>
            {bonusTarget && bonusReturn ? (
              <tr>
                <td><b><Trans>Expected return in {{ coinSymbol }} if price growth approx. &#62;={{ bonusTargetInPercent }}%</Trans></b></td>
                <td>{expectedReturnWithBonus} {coinSymbol}</td>
              </tr>
            ) : null}
            {dfiRewardPerAnnum ? (
              <tr>
                <td><b><Trans>Additional expected return in DFI</Trans></b></td>
                <td>{expectedDfiReturn} DFI</td>
              </tr>
            ) : null}
            <tr>
              <td><b><Trans>Auto-compound</Trans></b></td>
              <td><Trans>{this.getCompoundPreferenceText(compoundOnSettle)}</Trans></td>
            </tr>
          </tbody>
        </Table><br />
        <div className="flex-vertical-center">
          <CheckBox
            isChecked={isAcceptSprodEntryTerms}
            onChange={isChecked => this.setValue('isAcceptSprodEntryTerms', isChecked)}
          >
          </CheckBox>
          <div><Trans>I have fully understood the <span className="link" onClick={this.toggleDetailsModal}>conditions and the risks involved</span>. I am entering {{ name }} batch {{ batchNumberStr }} at my own risk and judgment.</Trans></div>
        </div>
        <div className="entry-modal-footer">
          <Button disabled={!canEnter} primary className="fright margin-top-20" onClick={this.updateSprodEntry}><Trans>{updateButtonLabel}</Trans></Button>
          <Button btnLink className="fright margin-top-20" onClick={this.toggleModalContent}><Trans>Cancel</Trans></Button>
        </div>
      </Container>
    );
  }

  renderCancelConfirmation() {
    const { name, previouslyEnteredAmount, coinSymbol } = this.props;
    const previouslyEnteredAmountFormated = new BigNumber(previouslyEnteredAmount).toFixed();
    return (
      <Container className="cancel-confirmation-modal">
        <Trans>Do you really want to cancel your entry to {{ name }} of {{ previouslyEnteredAmountFormated }} {{ coinSymbol }}?</Trans>
        <div className="entry-modal-footer cancel-confirmation-footer">
          <Button btnLink className="fright margin-top-20" onClick={this.toggleCancelModal}><Trans>Ignore</Trans></Button>
          <Button primary className="fright margin-top-20" onClick={this.cancelSprodEntry}><Trans>Yes, Cancel My Entry</Trans></Button>
        </div>
      </Container>
    );
  }

  wouldGoOverBatchCap = () => {
    const { entryAmount } = this.state;
    const { previouslyEnteredAmount } = this.props;

    const entryAmountBN = new BigNumber(entryAmount);
    return this.batchCapReached() && entryAmountBN.isGreaterThan(previouslyEnteredAmount);
  }

  renderIsAmountLessThanMinimum() {
    const { entryAmount } = this.state;
    const { coin, minAmountInUsd } = this.props;

    const isAmountLessThanMinimum = isAmountLessThanMinimumInUsd({
      amount: entryAmount,
      priceUSD: coin.priceUSD,
      minAmountInUsd,
      selectedCoin: coin.id,
    });

    return isAmountLessThanMinimum;
  }

  renderExplanationText() {
    const { entryAmount } = this.state;
    const { coinSymbol, saleClosesOn, minAmountInUsd } = this.props;

    const isAmountLessThanMinimum = this.renderIsAmountLessThanMinimum();

    if (isAmountLessThanMinimum && !!(+entryAmount)) {
      return <span className="help-text"><Trans>The amount you enter must at least be worth ${{ minAmountInUsd }}</Trans></span>;
    }

    if (!isPositiveAndNotZeroNumber(entryAmount)) {
      return null;
    }

    if (this.wouldGoOverBatchCap()) {
      return <span className="help-text"><Trans>You cannot increase your entry amount because the batch cap is reached.</Trans></span>;
    }

    if (!this.hasEnoughBalance()) {
      return <span className="help-text"><Trans>You don't have enough available {{ coinSymbol }} in your wallet.</Trans></span>;
    }

    const date = getLocalizedDate(new Date(saleClosesOn));

    return (
      <span className="text-secondary"><Trans>You may edit your entry amount, or cancel before the batch commences on {{ date }}, after which it will be put on hold.</Trans></span>
    );
  }

  renderCancelEntryButton() {
    const { previouslyEnteredAmount } = this.props;
    const disableCancel = previouslyEnteredAmount === '0';

    if (disableCancel) return null;

    return (
      <Col md="6" className="cancel-entry">
        <button className="cancel-button" disabled={disableCancel} onClick={this.toggleCancelModal}>
          <Trans>Cancel my entry</Trans>
        </button>
      </Col>
    );
  }

  renderEnterModal() {
    const {
      name,
      coinSymbol,
      saleClosesOn,
      maturesOn,
      previouslyEnteredAmount,
      coinBalance,
      isRestrictUser,
      tier2Threshold,
      tier2Apy,
      returnPerAnnum,
    } = this.props;

    const expectedReturn = new BigNumber(returnPerAnnum).multipliedBy(100).toFormat();
    const tier2Return = new BigNumber(tier2Apy).multipliedBy(100).toFormat();
    const exampleAmount = Number(tier2Threshold) * 2;
    const exampleAmountInString = exampleAmount.toString();
    const remainingAmount = (exampleAmount - Number(tier2Threshold)).toString();

    const { updateEntry, cancelEntry, showDetails, entryAmount } = this.state;
    const updateButtonLabel = previouslyEnteredAmount === '0' ? 'Enter' : 'Update';

    const maxAvailableBalance = new BigNumber(coinBalance).plus(previouslyEnteredAmount).toFixed();

    if (updateEntry) {
      return this.renderEntryConfirmation();
    }
    if (cancelEntry) {
      return this.renderCancelConfirmation();
    }
    if (showDetails) {
      return this.renderDetails();
    }
    const helpText = this.renderExplanationText();

    return (
      <Translation>
        {
          t => <React.Fragment>
            <Container className="sprod-entry-modal">
              <div className="content-wrapper">
                <Row>
                  <Col md="11" xs="10">
                    <h2><Trans>Entry</Trans> {name}</h2>
                  </Col>
                  <Col md="1" xs="2">
                    <Icon name={getCoinIconName(coinSymbol)} containerClassName="big-coin-icon" />
                  </Col>
                </Row>

                <div className='table-container'>
                  <div className='mb-8 fontweight-500'><Trans>APY Rates for</Trans> {coinSymbol}</div>
                  <div className='table-wrapper'>
                    <table className='table-style'>
                      <tbody>
                        <tr className='fontweight-500'>
                          <td style={{ border: '0' }} ><Trans>Amount</Trans></td>
                          <td style={{ width: '40%', border: '0' }}><Trans>APY</Trans></td>
                        </tr>
                        <tr className='white-background'>
                          <td>{'<='} {tier2Threshold} {coinSymbol}</td>
                          <td>{expectedReturn}%</td>
                        </tr>
                        <tr>
                          <td className='white-background' style={{ border: '0' }}>{'>'} {tier2Threshold} {coinSymbol}</td>
                          <td className='white-background' style={{ border: '0' }}><Trans>{{ expectedReturn }}% (up to {{ tier2Threshold }} {{ coinSymbol }})</Trans></td>
                        </tr>
                        <tr>
                          <td className='white-background' style={{ border: '0' }}></td>
                          <td className='white-background' style={{ border: '0' }}>{tier2Return}% (<Trans>surplus amount</Trans>)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='fontweight-500'><Trans>For example, if you’re allocating {{ exampleAmountInString }} {{ coinSymbol }}</Trans> </div>
                  <div><Trans>You’ll get {{ expectedReturn }}% APY on {{ tier2Threshold }} {{ coinSymbol }}, and {{ tier2Return }}% APY on the remaining {{ remainingAmount }} {{ coinSymbol }}.</Trans></div>
                </div>

                <Row>
                  <Col md="6" className="entry-input">
                    <TextInput
                      className="mb-24"
                      inputMode='decimal'
                      placeholder={'Entry amount'}
                      label={'Entry amount'}
                      value={entryAmount}
                      onChange={value => this.setEntryAmount(value)}
                      tabIndex={1}
                      suffixLabel={coinSymbol}
                      isShowHelpText={!!helpText}
                      invalidMessage={helpText}
                      onClick={event => event.target.select()}
                      disabled={isRestrictUser}
                      autoFocus />
                  </Col>
                  {this.renderCancelEntryButton()}
                </Row>
                <Row className="entry-modal-details-wrapper">
                  <Col md="6" className="entry-modal-detail sale-closes-on-date">
                    <span><Trans>Batch period starts on</Trans></span>
                    <span>{getLocalizedDate(new Date(saleClosesOn))}</span>
                  </Col>
                  <Col md="6" className="entry-modal-detail batch-matures-on-date">
                    <span><Trans>Batch period ends on</Trans></span>
                    <span>{getLocalizedDate(new Date(maturesOn))}</span>
                  </Col>
                </Row>
                {isPositiveAndNotZeroNumber(entryAmount) ? this.renderCompoundPreferenceMenu() : null}
              </div>
              <Row className="entry-modal-footer">
                <Col>
                  <span className="wallet-balance"><Trans>Available Balance</Trans></span><br />
                  <ClickableElement
                    onClick={() => this.setEntryMaxAmount(maxAvailableBalance)}
                    minimumDelay={0}>
                    <span>
                      <BigNumberDisplay isSensitiveNumber isDisplayAll emptyClass>{maxAvailableBalance}</BigNumberDisplay>
                    </span>
                  </ClickableElement>
                </Col>
                <Col className="entry-modal-cta-buttons">
                  <Button btnLink onClick={() => this.setState({ isEntryModalVisible: false })}><Trans>Cancel</Trans></Button>
                  <Button disabled={!this.canEnter()} primary onClick={this.toggleModalContent}><Trans>{updateButtonLabel}</Trans></Button>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        }
      </Translation>
    );
  }

  handleOptionChange = value => {
    const { isRestrictUser } = this.props;
    if (isRestrictUser) {
      return;
    }

    this.setState({ compoundOnSettle: value });
  }

  renderCompoundPreferenceText = () => {
    const { coinSymbol } = this.props;
    const { entryAmount, compoundOnSettle } = this.state;
    if (compoundOnSettle === 'NO_COMPOUNDING') {
      return (
        <span><Trans>Neither your batch entry, nor your returns will be auto-compounded upon maturity.</Trans></span>
      );
    }
    if (compoundOnSettle === 'ONLY_ENTRY') {
      return (
        <span><Trans>Your entry amount of {{ entryAmount }} {{ coinSymbol }} will be auto-compounded upon maturity.</Trans></span>
      );
    }
    if (compoundOnSettle === 'ENTRY_AND_RETURN') {
      return (
        <span><Trans>Your entry amount of {{ entryAmount }} {{ coinSymbol }} and returns will be auto-compounded upon maturity.</Trans></span>
      );
    }
    return null;
  }

  getCompoundPreferenceText = (setting) => {
    if (setting === 'NO_COMPOUNDING') {
      return 'No auto-compound';
    }
    if (setting === 'ONLY_ENTRY') {
      return 'Enter my principal amount only';
    }
    if (setting === 'ENTRY_AND_RETURN') {
      return 'Enter my principal amount and returns';
    }
    return null;
  }

  renderCompoundPreferenceMenu = () => {
    const { compoundOnSettle } = this.state;
    const { isRestrictUser, coinSymbol } = this.props;

    return (
      <Row className="compound-preference-menu">
        <Col md="6" className="sprod-auto-compound">
          <h3><Trans>Auto compound for this batch</Trans></h3>
          <div className="pb-8">
            <Trans>Auto-compound is an option that allows you to automatically enter the principal and returns from the previous batch into the next batch.</Trans>
          </div>
          {this.renderCompoundPreferenceText()}
        </Col>
        <Col md="6" className="sprod-compound-settings pr-0">
          <form className="full-height">
            <div className="sprod-compound-settings-option">
              <input
                type="radio"
                name="sprod-details-compound-settings-option"
                value="NO_COMPOUNDING"
                onChange={() => null}
                checked={compoundOnSettle === 'NO_COMPOUNDING'}
                disabled={isRestrictUser}
              />
              <label
                onClick={() => this.handleOptionChange('NO_COMPOUNDING')}>
                <Trans>{this.getCompoundPreferenceText('NO_COMPOUNDING')}</Trans>
              </label>
            </div>
            <div className="sprod-compound-settings-option">
              <input
                type="radio"
                name="sprod-details-compound-settings-option"
                value="ONLY_ENTRY"
                onChange={() => null}
                checked={compoundOnSettle === 'ONLY_ENTRY'}
                disabled={isRestrictUser}
              />
              <label onClick={() => this.handleOptionChange('ONLY_ENTRY')}>
                <Trans>{this.getCompoundPreferenceText('ONLY_ENTRY')}</Trans>
              </label>
            </div>
            <div className="sprod-compound-settings-option">
              <input
                type="radio"
                name="sprod-details-compound-settings-option"
                value="ENTRY_AND_RETURN"
                onChange={() => null}
                checked={compoundOnSettle === 'ENTRY_AND_RETURN'}
                disabled={isRestrictUser}
              />
              <label onClick={() => this.handleOptionChange('ENTRY_AND_RETURN')}>
                <Trans>{this.getCompoundPreferenceText('ENTRY_AND_RETURN')}</Trans>
              </label>
            </div>
          </form>
        </Col>
        <Col xs="12" className="compound-preference-info-text">
          <div>
            <span><Trans>This will overwrite the {{ coinSymbol }} Lending batches in your profile.</Trans></span>
          </div>
        </Col>
      </Row>
    );
  }

  renderButtonTooltip = message => (
    <div className="button-disabled-popover">
      <dl>
        <dt><Trans>{message}</Trans></dt>
      </dl>
    </div>
  )

  batchCapReached = () => {
    const { startingAmount, batchCap, estimatedAutocompoundAmount } = this.props;
    if (estimatedAutocompoundAmount) {
      return new BigNumber(startingAmount).plus(estimatedAutocompoundAmount).isGreaterThanOrEqualTo(new BigNumber(batchCap));
    }
    return new BigNumber(startingAmount).isGreaterThanOrEqualTo(new BigNumber(batchCap));
  }

  renderEnterButton = () => {
    const { status, accessRights, isSaleStillOpen, isRestrictUser } = this.props;
    const { access } = (accessRights && accessRights.enterSprod) || {};

    const capReached = this.batchCapReached();
    const isAcceptingEntries = status === 'SELLING';
    const isDisableEnterButton = isRestrictUser || (!isSaleStillOpen ? true : (capReached || !isAcceptingEntries));
    const button = (
      <Button primary medium disabled={isDisableEnterButton} onClick={this.onClickEnter}>
        <Trans i18nKey="Enter batch">Enter</Trans>
      </Button>
    );

    if (!access) {
      return (
        <Button primary medium onClick={() => this.setState({ isKycUnverfiedModalVisible: true })}>
          <Trans>Enter</Trans>
        </Button>
      );
    }

    if (!isAcceptingEntries) {
      return (
        <MouseOverTooltip
          key={'enterSprod'}
          renderTooltip={() => this.renderButtonTooltip('We are no longer accepting entries for this batch. A new batch will open soon.')}>
          {button}
        </MouseOverTooltip>
      );
    }

    if (capReached) {
      return (
        <MouseOverTooltip
          key={'enterSprod'}
          renderTooltip={() => this.renderButtonTooltip('There is no space left in this batch. Please try with the next one.')}>
          {button}
        </MouseOverTooltip>
      );
    }

    return button;
  }

  renderMyEntryButton = () => {
    const { status } = this.props;
    const isAcceptingEntries = status === 'SELLING';

    const button = (
      <Button btnLink medium onClick={this.onClickEnter} disabled={!isAcceptingEntries}>
        <Trans>My entry</Trans>
      </Button>
    );

    if (isAcceptingEntries) {
      return button;
    }

    return (
      <MouseOverTooltip
        key={'enterSprod'}
        renderTooltip={() => this.renderButtonTooltip('We are no longer accepting entries for this batch. You can\'t change your entry amount or cancel it anymore.')}>
        {button}
      </MouseOverTooltip>
    );
  }

  render() {
    const {
      previouslyEnteredAmount,
      coinSymbol,
      userDetails,
    } = this.props;
    const { isEntryModalVisible, isKycUnverfiedModalVisible } = this.state;

    if (userDetails && previouslyEnteredAmount && !(new BigNumber(previouslyEnteredAmount).isEqualTo(0))) {
      return (
        <React.Fragment>
          <div className="my-entry-wrapper-desktop">
            {this.renderMyEntryButton()}
            <span>{new BigNumber(previouslyEnteredAmount).toFixed()} {coinSymbol}</span>
          </div>
          <Modal isVisible={isEntryModalVisible} showCloseButton={false} onClose={() => this.setState({ isEntryModalVisible: false })}>
            {this.renderEnterModal()}
          </Modal>
          <Modal isSmall isVisible={isKycUnverfiedModalVisible} showCloseButton={false} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })}>
            <KycUnverifiedModal action={AccessRights.EnterSprod} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })} />
          </Modal>
        </React.Fragment>
      );
    }

    if (userDetails) {
      return (
        <React.Fragment>
          {this.renderEnterButton()}
          <Modal isVisible={isEntryModalVisible} showCloseButton={false} onClose={() => this.setState({ isEntryModalVisible: false })}>
            {this.renderEnterModal()}
          </Modal>
          <Modal isSmall isVisible={isKycUnverfiedModalVisible} showCloseButton={false} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })}>
            <KycUnverifiedModal action={AccessRights.EnterSprod} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })} />
          </Modal>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Button primary btnLink onClick={this.onClickEnter}><Trans>Enter</Trans></Button>
        <Modal isVisible={isEntryModalVisible} showCloseButton={false} onClose={() => this.setState({ isEntryModalVisible: false })}>
          {this.renderEnterModal()}
        </Modal>
        <Modal isSmall isVisible={isKycUnverfiedModalVisible} showCloseButton={false} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })}>
          <KycUnverifiedModal action={AccessRights.EnterSprod} onClose={() => this.setState({ isKycUnverfiedModalVisible: false })} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapState, mapDispatch)(SprodEntry));
