import React, { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pdfContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface ITermsAndConditionsPDFProps {
  customWidth?: number
  pdfPath?: string;
}

const TermsAndConditionsPDF = ({ customWidth, pdfPath }: ITermsAndConditionsPDFProps) => {
  const [numPages, setNumPages] = useState(0);
  const classes = useStyles();
  const pdfView = useRef(null);

  const onDocumentLoadSuccess = ({ numPages: newPageCount }) => {
    setNumPages(newPageCount);
  };

  const getPageWidth = () => {
    if (customWidth) return customWidth;

    if (pdfView?.current) {
      if (pdfView.current.offsetWidth > 800) return 800;

      return pdfView.current.offsetWidth;
    }

    return undefined;
  };

  return (
    <div ref={pdfView} className={classes.pdfContainer}>
      <Document
        file={pdfPath}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={getPageWidth()}
            />
          ),
        )}
      </Document>
    </div>
  );
};

TermsAndConditionsPDF.defaultProps = {
  pdfPath: process.env.REACT_APP_TNC_PDF_URL,
};

export default TermsAndConditionsPDF;
