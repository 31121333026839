import React from 'react';
import { createUseStyles } from 'react-jss';

import styles from './CakeLoader.styles';

const useStyles = createUseStyles(styles);

export default function CakeLoader({ size = '32px' }) {
  const classes = useStyles({ size });

  return (
    <div className={classes.loader}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={classes.spinnerGroup}>
          <circle
            className={classes.track}
            cx="12"
            cy="12"
            r="10"
            fill="none"
            strokeWidth="4"
          ></circle>
          <circle
            className={classes.spinner}
            cx="12"
            cy="12"
            r="10"
            fill="none"
            strokeWidth="4"
          ></circle>
        </g>
        <path
          className={classes.slice}
          d="M1.299 16.58a1.497 1.497 0 0 1-.756-.903 12.709 12.709 0 0 1 0-7.355 1.5 1.5 0 0 1 1.932-.982l9.27 3.244a1.5 1.5 0 0 1 0 2.832l-9.27 3.244a1.496 1.496 0 0 1-1.176-.08z"
        />
      </svg>
    </div>
  );
}
