import { Styles } from 'react-jss';
import Variables from '../../Variables.styles';

const styles: Styles = {
  container: {
    composes: ['referral-faq', 'container'],
  },
  cardContainer: {
    composes: ['card', 'rewards-card', 'card-border'],
  },
  referralFaqItem: {
    borderBottom: `${Variables.uiBorderWidth} solid ${Variables.faded}`,
    alignItems: 'flex-start',

    '& .question': {
      display: 'flex',
      padding: '20px 24px',
      cursor: 'pointer',
    },
    '& .answer': {
      display: 'none',
      padding: '0 24px 20px',
    },
    '&.open .answer': {
      display: 'flex',
    },
    '& .icon': {
      flexBasis: '32px',
      flexShrink: '0',
      color: Variables.textSecondary,
    },
    '& .body': {
      flexGrow: '1',
      '& .text-block:last-child': {
        marginBottom: '0',
      },
      '& .list-moved': {
        marginLeft: '60px',
      },
    },
  },
  faqHelpDesk: {
    paddingLeft: Variables.large,
    paddingRight: Variables.large,
    paddingTop: Variables.large,
    paddingBottom: Variables.extraLarge,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  faqConsultButton: {
    whiteSpace: 'normal',
  },
};

export default styles;
