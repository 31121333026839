import moment from 'moment';
import { DateObject } from '../lib';

export const SUPPORTED_DATE_FORMATS = ['D-MM-YYYY', 'DD-MM-YYYY'];

export const DateValidationError = {
  INVALID_DAY: 'INVALID_DAY',
  INVALID_YEAR: 'INVALID_YEAR',
  INVALID_DATE: 'INVALID_DATE',
  TOO_YOUNG: 'TOO_YOUNG',
  DATE_NOT_FILLED: 'DATE_NOT_FILLED',
  DATE_EXPIRED: 'DATE_EXPIRED',
  DATE_NOT_WITHIN_VALIDITY_PERIOD: 'DATE_NOT_WITHIN_VALIDITY_PERIOD',
} as const;

type ErrorTypeKey = keyof typeof DateValidationError;

const codeToMessage = {
  INVALID_DAY: () => 'Please enter a day between 1 to 31',
  INVALID_YEAR: ({ minYear, maxYear }) => `Please enter a year between ${minYear} and ${maxYear}`,
  INVALID_DATE: () => 'Please enter a valid date',
  TOO_YOUNG: ({ minAge }) => `You need to be at least ${minAge} years old`,
  DATE_NOT_FILLED: () => 'Please fill in all the date fields',
  DATE_EXPIRED: () => 'Date has passed, please use a new one',
  DATE_NOT_WITHIN_VALIDITY_PERIOD: ({ months }) => `Date must be valid in the next ${months} months`,
};

export const getErrorMessage = (code: ErrorTypeKey, props = null) => {
  if (!code) {
    return '';
  }
  return codeToMessage[code](props);
};

export const validateDay = (value: string) => {
  const valueInNumber = Number(value);
  const isFilledAndIsZero = value.length === 2 && valueInNumber === 0;
  const isBetween1to31 = valueInNumber >= 0 && valueInNumber < 32;
  return (isBetween1to31 && !isFilledAndIsZero);
};

export const validateYear = (value: string, minYear: number, maxYear: number) => {
  if (value.length !== 4) {
    return;
  }
  const valueInNumber = Number(value);
  const isBetweenMinAndMaxYears = valueInNumber >= minYear && valueInNumber <= maxYear;
  // eslint-disable-next-line consistent-return
  return isBetweenMinAndMaxYears;
};

export const validateWholeDate = (value: moment.MomentInput, dateFormat = SUPPORTED_DATE_FORMATS) => {
  const isValidDate = moment(value, dateFormat, true).isValid();
  return isValidDate;
};

export const validateWholeDateObject = (dateObject: DateObject, dateFormat = SUPPORTED_DATE_FORMATS) => {
  const dateString = `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  const isValidDate = moment(dateString, dateFormat, true).isValid();
  return isValidDate;
};

export const validateMinAge = (value: moment.MomentInput, minAge: number, dateFormat = SUPPORTED_DATE_FORMATS) => {
  const isOverMinAge = moment().diff(moment(value, dateFormat, true), 'years') >= minAge;
  return isOverMinAge;
};

export const validateMinAgeDateObject = (dateObject: DateObject, minAge: number, dateFormat = SUPPORTED_DATE_FORMATS) => {
  const dateString = `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  const isOverMinAge = moment().diff(moment(dateString, dateFormat, true), 'years') >= minAge;
  return isOverMinAge;
};

export const validateDateFilled = (dateObject: DateObject) => {
  const { day, month, year } = dateObject;
  return (day.length === 1 || day.length === 2) && month && year.length === 4;
};

export const validateDateExpiry = (
  selectedDate: moment.MomentInput,
  expiredDate: moment.MomentInput,
  dateFormat = SUPPORTED_DATE_FORMATS,
) => moment(selectedDate, dateFormat, true).diff(moment(expiredDate, dateFormat, true)) > 0;

export const validateValidityPeriod = (
  selectedDate: moment.MomentInput,
  maxValidityMonths: number,
  dateFormat = SUPPORTED_DATE_FORMATS,
) => moment(selectedDate, dateFormat, true).diff(moment().add(maxValidityMonths, 'months')) > 0;
