export interface SubscriptionBenefit {
  text: string;
  subtext?: string,
  hideOnLogin?: boolean;
}

export interface SubscriptionPeriod {
  startAt: Date;
  endAt: Date;
}

export interface SubscriptionBoosterRewardPool {
  usedInUsd: string;
  capacityInUsd: string;
}

export enum SubscriptionInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export interface SubscriptionUserInfo {
  title: string;
  tier: number;
  interval: SubscriptionInterval,
  benefits: SubscriptionBenefit[];
  currentPeriod: SubscriptionPeriod;
  status: string;
  boosterRewardPool: SubscriptionBoosterRewardPool;
  freeWithdrawal: SubscriptionFreeWithdrawal;
  cancelAtPeriodEnd: boolean;
  dexSwapRateDiscount: number;
  subscriptionStartDate: Date,
}

export interface SubscriptionFreeWithdrawal {
  used: number;
  total: number;
}

export interface SubscriptionPaymentProviderDetails {
  pricingTableId: string;
  publishableKey: string;
}

export enum SubscriptionProductStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface SubscriptionPricingItem {
  tier: number;
  name: string;
  description: string;
  status: SubscriptionProductStatus;
  monthlyCapacityInUsd: string;
  subscriptionProductId: string;
  metadata: Partial<Record<string, string>>;
  benefits: { text: string, preferredIcon: string, subtext?: string }[];
  price: string; // BigNumber
  period: SubscriptionInterval;
}

export interface SubscriptionPricingTable {
  paymentProvider: string;
  paymentProviderDetails: SubscriptionPaymentProviderDetails;
  pricingTables: SubscriptionPricingItem[];
}

export interface SubscriptionCustomerPoralSession {
  url: string;
}

export interface CreateCheckoutSessionPayload {
  subscriptionProductId: string;
}
