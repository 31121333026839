import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OnRampProviderType,
  ONRAMP_PROVIDERS,
} from '../../../types/onramp/onramp-provider';
import { FiatBuyModalState } from '../../../types/onramp/state';
import { TransakPaymentOptionConverter } from '../../../types/onramp/transak-payment';
import { convertBTCOrETHToCryptoAmount } from '../../../utils/convertBTCETHToCryptoAmount';
import { convertCryptoToBTCorETHAmount } from '../../../utils/convertCryptoToBTCorETHAmount';
import { handleBanxaExtraReducers } from './banxaExtraReducers';
import { BASE_RETURN_URL, findPrice } from './fiatBuyModalService';
import { handleTransakExtraReducers } from './transakExtraReducers';
import { CoinMap } from '../../../types/coin/coin-map';

export const DEFAULT_CRYPTO_AMOUNT = '0.03';

const getInitialState = (banxaFiats?: any[], transakFiats?: any[]): FiatBuyModalState => {
  const initialState: FiatBuyModalState = {
    type: OnRampProviderType.BANXA,
    providers: ONRAMP_PROVIDERS,
    inputs: {
      source: null,
      target: null,
      targetAmount: DEFAULT_CRYPTO_AMOUNT,
      sourceAmount: null,
      walletAddress: null,
      isLoadingWalletAddress: false,
      returnUrlOnSuccess: `${BASE_RETURN_URL}success`,
      returnUrlOnCancelled: `${BASE_RETURN_URL}cancelled`,
      returnUrlOnFailure: `${BASE_RETURN_URL}failed`,
    },
    order: {
      id: null,
    },
    fiatSwappable: {
      coin: null,
      amount: null,
      decimals: null,
      walletAddress: null,
      isLoadingWalletAddress: false,
    },
    hasWalletPaidFee: false,
    guaranteeFeeUsd: null,
    banxa: {
      fiats: banxaFiats,
      isFiatsLoading: false,
      fiatPrices: null,
      selectedFiatPrice: null,
      isPaymentMethodLoading: false,
      paymentMethods: null,
      selectedPaymentMethod: null,
      isFetchingCryptoPrice: false,
      isFetchingFiatPrice: false,
    },
    transak: {
      fiats: transakFiats,
      isFiatsLoading: false,
      selectedFiatPrice: null,
      paymentMethods: null,
      isPaymentMethodLoading: false,
      selectedPaymentMethod: null,
      isFetchingCryptoPrice: false,
      isFetchingFiatPrice: false,
    },
    error: null,
  };
  return initialState;
};

const fiatBuyModalSlice = createSlice({
  name: 'fiatBuyModal',
  initialState: getInitialState(),
  reducers: {
    updateInputField: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      state.inputs[fieldName] = fieldValue;
    },
    setBanxaSelectedPaymentMethod: (state, action) => {
      state.banxa.selectedPaymentMethod = action.payload;
      const selectedPrice = findPrice(
        state.banxa.fiatPrices,
        state.banxa.selectedPaymentMethod.id,
      );
      state.banxa.selectedFiatPrice = selectedPrice;
      if (selectedPrice) {
        const selectedCoinAmount = Number(selectedPrice.coinAmount);
        const selectedSourceAmount = Number(selectedPrice.fiatAmount);
        if (state.inputs.targetAmount !== selectedCoinAmount) {
          state.inputs.targetAmount = selectedCoinAmount;
        }
        if (state.inputs.sourceAmount !== selectedSourceAmount) {
          state.inputs.sourceAmount = selectedSourceAmount;
        }
      }
    },
    setTransakSelectedPaymentMethod: (state, action) => {
      state.transak.selectedPaymentMethod = action.payload;
    },
    setOnRampProvider: (state, action) => {
      const { payload: selectedProvider } = action;
      if (selectedProvider === state.type) {
        return;
      }
      const newProviders = state.providers.map((provider) => ({
        ...provider,
        selected: provider.type === selectedProvider,
      }));
      state.inputs.sourceAmount = '';
      state.providers = newProviders;
      state.type = selectedProvider;
    },
    setTransakDefaultSource: (state, action) => {
      const { fiats } = state.transak;
      const { payload: selectedSymbol } = action;
      if (fiats.length) {
        const preferredFiat = fiats.find(
          ({ symbol }) => symbol === selectedSymbol,
        );
        const [firstFiat] = fiats;
        const selectedFiat = preferredFiat || firstFiat;
        if (!selectedFiat) {
          return;
        }
        const mappedPaymentMethods = TransakPaymentOptionConverter.toFiatBuyState(
          selectedFiat.paymentOptions,
        );
        state.inputs.source = selectedFiat.symbol;
        state.transak.paymentMethods = mappedPaymentMethods;
        [state.transak.selectedPaymentMethod] = mappedPaymentMethods;
      }
    },
    setCryptoAmountWithFiatSwappableAmount: (state) => {
      const {
        fiatSwappable: { coin, amount },
      } = state;

      if (coin) {
        state.inputs.targetAmount = amount;
      }
    },
    convertFiatSwappableToCryptoAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        pricing: any[];
        coins: CoinMap;
        withoutMarkup: boolean;
      }>,
    ) => {
      const {
        amount: payloadAmount,
        pricing,
        coins,
        withoutMarkup,
      } = action.payload;
      const {
        fiatSwappable: { coin },
      } = state;
      if (!coin) {
        state.inputs.targetAmount = payloadAmount;
        return;
      }

      state.fiatSwappable.amount = payloadAmount;
      state.inputs.targetAmount = convertCryptoToBTCorETHAmount(
        coin,
        payloadAmount,
        'BTC',
        coins.BTC?.decimals,
        pricing,
        withoutMarkup,
      );
    },
    setSwappableCoin: (
      state,
      action: PayloadAction<{
        id: string;
        decimals: number;
      }>,
    ) => {
      const { id, decimals } = action.payload;
      state.fiatSwappable.coin = id;
      state.fiatSwappable.decimals = decimals;
      state.inputs.target = 'BTC';
    },
    convertCryptoToFiatSwappableAmount: (
      state,
      action: PayloadAction<{ pricing: any[], withoutMarkup: boolean }>,
    ) => {
      const {
        inputs: { targetAmount: cryptoAmount },
        fiatSwappable: { coin, decimals },
      } = state;
      const { pricing, withoutMarkup } = action.payload;

      if (!coin) {
        return;
      }

      state.fiatSwappable.amount = convertBTCOrETHToCryptoAmount(
        'BTC',
        cryptoAmount,
        coin,
        decimals,
        pricing,
        withoutMarkup,
      );
    },
    clearFiatSwappable: (state) => ({
      ...state,
      fiatSwappable: {
        amount: null,
        coin: null,
        decimals: null,
        walletAddress: null,
        isLoadingWalletAddress: false,
      },
    }),
    clearOrder: (state) => ({
      ...state,
      order: {
        id: null,
      },
    }),
    clear: (state) => ({
      ...getInitialState(state.banxa.fiats, state.transak.fiats),
    }),
  },
  extraReducers: (builder) => {
    handleBanxaExtraReducers(builder);
    handleTransakExtraReducers(builder);
  },
});

export const {
  updateInputField,
  setBanxaSelectedPaymentMethod,
  setTransakSelectedPaymentMethod,
  setOnRampProvider,
  setTransakDefaultSource,
  setSwappableCoin,
  setCryptoAmountWithFiatSwappableAmount,
  convertFiatSwappableToCryptoAmount,
  convertCryptoToFiatSwappableAmount,
  clearFiatSwappable,
  clearOrder: clearFiatBuyOrder,
  clear: clearFiatBuyModalSlice,
} = fiatBuyModalSlice.actions;

export default fiatBuyModalSlice.reducer;
