import { withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import LoadingModal from './components/LoadingModal';

function loadable(page) {
  return Loadable({
    loader: () => page,
    loading: LoadingModal,
  });
}

function hocs(page) {
  return withRouter(loadable(page));
}

// withRouter with grant component/page access to { history, match } via this.props
// for programmatically navigate usage, check path/params etc purposes
const Login = hocs(import('./Pages/Authentication/Login'));
const Page404 = hocs(import('./Pages/Errors/Page404'));
const Page500 = hocs(import('./Pages/Errors/Page500'));

export {
  Page404,
  Page500,
  Login,
};
