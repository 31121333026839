import { Coin } from '@cakedefi/cake-sdk/schema';
import BigNumber from 'bignumber.js';
import sdk from '../sdk';
import { CoinMap } from '../types/coin/coin-map';
import { WalletByCoin } from '../types/coin/wallet-by-coin';
import { isEmpty } from '../utils';

const DEFAULT_SHARE_BALANCE = {
  locked: 0,
  movable: 0,
  total: 0,
  available: 0,
  pending: 0,
};

const DEFAULT_YIELDVAULT_BALANCE = {
  locked: 0,
  movable: 0,
  total: 0,
  available: 0,
  pending: 0,
};

const DEFAULT_NATIVE_BALANCE = {
  movable: 0,
  total: 0,
  active: 0,
  onSale: 0,
  bonus: 0,
};

export const BANXA_CHECKOUT_PARAMS = 'banxaCheckoutStatus';
export const INTERNAL_ORDER_ID = 'internalOrderId';
export const TRANSAK_CHECKOUT_PARAMS = 'transakCheckoutStatus';

const isShareEmpty = share => new BigNumber(share.total).isEqualTo(0);

// return new sorted shares and not mutate existing shares
const sortShares = shares => [...shares].sort((a, b) => {
  const aNoShare = isShareEmpty(a);
  const bNoShare = isShareEmpty(b);
  const sameStateAvailability = aNoShare === bNoShare;
  if (sameStateAvailability) {
    if (a.name < b.name) return -1;
    return 1;
  }
  if (aNoShare) return 1;
  if (bNoShare) return -1;
  return 0;
});

const indexBalancesByKind = balances => balances.reduce((accum, current) => {
  accum[`${current.coin.id}[${current.kind}]`] = current;
  return accum;
}, {});

const putBalancesIntoWallets = (shares: any[], balances: any[], coins: CoinMap): WalletByCoin[] => {
  const indexedBalances = indexBalancesByKind(balances);
  const walletsWithBalances: WalletByCoin[] = shares.map((share) => {
    const nativeBalanceKey = `${share.id}[NATIVE]`;
    const shareBalanceKey = `${share.id}[SHARE]`;
    const yieldVaultBalanceKey = `${share.id}[YIELD_VAULT]`;
    const coin = coins[share.id] ?? {};
    share.native = nativeBalanceKey in indexedBalances ? indexedBalances[nativeBalanceKey] : { ...DEFAULT_NATIVE_BALANCE };
    share.share = shareBalanceKey in indexedBalances ? indexedBalances[shareBalanceKey] : { ...DEFAULT_SHARE_BALANCE };
    share.yieldVault = yieldVaultBalanceKey in indexedBalances ? indexedBalances[yieldVaultBalanceKey] : { ...DEFAULT_YIELDVAULT_BALANCE };
    return {
      ...share,
      ...coin,
    };
  });
  return walletsWithBalances;
};

export const processWalletByCoins = (shares: any[], balances: any[], coins: CoinMap): WalletByCoin[] => {
  const sortedShares = sortShares(shares);
  const walletsByCoins = putBalancesIntoWallets(sortedShares, balances, coins);
  return walletsByCoins;
};

export const indexCoinsById = (coins: Coin[]): CoinMap => coins.reduce((acc, current) => {
  acc[current.id] = current;
  return acc;
}, {});

const handleBanxaCheckout = (banxaCheckoutStatus: string, allParams: URLSearchParams) => {
  const internalOrderId = allParams.get(INTERNAL_ORDER_ID);

  sdk.BanxaApi.updateOrder({
    banxaCheckoutStatus,
    internalOrderId,
  });

  switch (banxaCheckoutStatus) {
    case 'success':
      window.cakepool.showAlert('success', 'Your order with BANXA was created successfully. Please check your inbox shortly for further information.');
      break;

    case 'failed':
      window.cakepool.showAlert('error', 'Failed to create order with BANXA');
      break;

    case 'cancelled':
      window.cakepool.showAlert('error', 'Your order with BANXA was canceled');
      break;

    default:
      break;
  }
};

const handleTransakCheckout = (searchParams) => {
  const orderId = searchParams.get('orderId');
  if (orderId) {
    sdk.TransakApi.completeOrder(orderId);
    window.cakepool.showAlert('success', 'Your order with TRANSAK was created successfully. Please check your inbox shortly for further information.');
  }
};

export const handleFiatOnrampCheckout = (params, history) => {
  if (!params) {
    return;
  }
  const searchParams = new URLSearchParams(params);
  const banxaCheckoutResult = searchParams.get(BANXA_CHECKOUT_PARAMS);
  const transakCheckoutResult = searchParams.get(TRANSAK_CHECKOUT_PARAMS);

  if (banxaCheckoutResult) {
    handleBanxaCheckout(banxaCheckoutResult, searchParams);
  }

  if (transakCheckoutResult) {
    handleTransakCheckout(searchParams);
  }

  if (banxaCheckoutResult || transakCheckoutResult) {
    history.push('/wallets');
  }
};

export const addNumberSign = (num: number | string) => {
  if (num > 0) {
    return `+${num}`;
  }
  if (num < 0) {
    return `-${num}`;
  }
  return num;
};

export const summarizeBalanceInUsd = (balances, coinsPricing) => {
  let coin = new BigNumber(0);
  let share = new BigNumber(0);
  let lending = new BigNumber(0);

  // pull latest pricings
  const pricings = {};

  for (let i = 0; i < coinsPricing.length; i += 1) {
    const price = coinsPricing[i];
    if (!isEmpty(price)) {
      pricings[price.CoinId] = price;
    }
  }

  // balances
  if (balances.length > 0) {
    let aggregatedTotal = new BigNumber(0);

    balances.forEach((item) => {
      let balance = new BigNumber(+item.total);

      // check priceUSD available, and convert balance into USD currency
      const priceUSD = pricings[item.coin.id]?.priceUSD?.avg;
      if (priceUSD) {
        balance = balance.multipliedBy(priceUSD);
      }

      if (item.kind === 'NATIVE' && priceUSD) {
        coin = coin.plus(balance);
        lending = lending.plus(new BigNumber(item.lockedActive).multipliedBy(priceUSD));
      }

      if (item.kind === 'SHARE') {
        share = share.plus(balance);
        aggregatedTotal = aggregatedTotal.plus(balance);
      }

      if (item.kind === 'YIELD_VAULT') {
        share = share.plus(balance);
        aggregatedTotal = aggregatedTotal.plus(balance);
      }
    });
  }

  return {
    coin,
    share,
    lending,
  };
};
