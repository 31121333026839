import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import sdk from 'sdk';
import {
  Bake4TheWinCampaignDetails,
  Bake4TheWinLuckyDrawTickets,
  Bake4TheWinUnboxedRewards,
  Bake4TheWinUnboxReward,
} from '@cakedefi/cake-sdk/schema/campaign';
import { IBake4TheWinCampaignState } from './bake4TheWinTypes';

const initialState: IBake4TheWinCampaignState = {
  state: 'UNLOADED',
  isCampaignStillActive: false,
  error: null,
  startDate: null,
  endDate: null,
  visualsEndDate: null,
  isVisualsStillActive: false,
  unopenedRewards: 0,
  numberOfTickets: 0,
  unBoxedRewards: [],
  recentlyUnboxedRewardBonusDFIAmount: 0,
};

export const fetchBake4TheWinCampaignInfo = createAsyncThunk<Bake4TheWinCampaignDetails, null, ThunkApiConfig>(
  'campaigns/bake4TheWinCampaignInfo',
  async () => {
    const result = await sdk.CampaignApi.getBake4TheWinCampaignInfo();
    return result;
  },
);

export const fetchBake4TheWinUnOpenedRewardsForUser = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/bake4TheWinUnOpenedRewards',
  async () => {
    const result = await sdk.CampaignApi.getBake4TheWinUnOpenedRewardsForUser();
    return result;
  },
);

export const fetchBake4TheWinOpenedRewardsForUser = createAsyncThunk<Bake4TheWinUnboxedRewards, null, ThunkApiConfig>(
  'campaigns/bake4TheWinOpenedRewards',
  async () => {
    const result = await sdk.CampaignApi.getBake4TheWinOpenedRewardsForUser();
    return result;
  },
);

export const fetchBake4TheWinLuckyDrawTickets = createAsyncThunk<Bake4TheWinLuckyDrawTickets, null, ThunkApiConfig>(
  'campaigns/bake4TheWinLuckyDrawTickets',
  async () => {
    const result = await sdk.CampaignApi.getBake4TheWinLuckyDrawTickets();
    return result;
  },
);

export const openBake4TheWinNextReward = createAsyncThunk<Bake4TheWinUnboxReward, null, ThunkApiConfig>(
  'campaigns/openBake4TheWinNextReward',
  async () => {
    const result = await sdk.CampaignApi.unboxBake4TheWinReward();
    return result;
  },
);

const bake4TheWinCampaignSlice = createSlice({
  name: 'Bake4TheWinCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBake4TheWinCampaignInfo.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinCampaignInfo.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.isCampaignStillActive = action.payload?.isCampaignStillActive;
      state.startDate = action.payload?.startDate;
      state.endDate = action.payload?.endDate;
      state.visualsEndDate = action.payload?.visualsEndDate;
      state.isVisualsStillActive = action.payload?.isVisualsStillActive;
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinCampaignInfo.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchBake4TheWinUnOpenedRewardsForUser.pending, (state) => {
      state.error = null;
      state.unopenedRewards = 0;
    });
    builder.addCase(fetchBake4TheWinUnOpenedRewardsForUser.fulfilled, (state, action) => {
      state.unopenedRewards = action.payload?.unopenedRewards;
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinUnOpenedRewardsForUser.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchBake4TheWinLuckyDrawTickets.pending, (state) => {
      state.error = null;
      state.numberOfTickets = 0;
    });
    builder.addCase(fetchBake4TheWinLuckyDrawTickets.fulfilled, (state, action) => {
      state.numberOfTickets = action.payload?.numberOfTickets;
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinLuckyDrawTickets.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchBake4TheWinOpenedRewardsForUser.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinOpenedRewardsForUser.fulfilled, (state, action) => {
      state.unBoxedRewards = action.payload?.unBoxedRewards;
      state.error = null;
    });
    builder.addCase(fetchBake4TheWinOpenedRewardsForUser.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(openBake4TheWinNextReward.pending, (state) => {
      state.error = null;
    });
    builder.addCase(openBake4TheWinNextReward.fulfilled, (state, action) => {
      state.recentlyUnboxedRewardBonusDFIAmount = action.payload?.bonusDfiAmount;
      state.error = null;
    });
    builder.addCase(openBake4TheWinNextReward.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default bake4TheWinCampaignSlice.reducer;
