import { CakeApiBase } from '../CakeApiBase';
import { EarnCoin, EarnEntryListRequest, InsuranceFund, UserEarnEntry, UserEarnEntryDetails } from '../../schema/earn';
import { MinimumAmountInUsd } from '../../schema';

export class EarnApi extends CakeApiBase {
  async enterEarn(earnCoinId: string, amount: string) {
    return this.requestManager.post('/earn/entries', { amount, earnCoinId });
  }

  async exitEarn(id: string) {
    return this.requestManager.post(`/earn/entries/${id}/remove`);
  }

  async cancelExitEarn(id: string) {
    return this.requestManager.post(`/earn/entries/${id}/remove/cancel`);
  }

  async cancelEarn(id: string) {
    return this.requestManager.post(`/earn/entries/${id}/cancel`);
  }

  async getEarnCoins() {
    return this.requestManager.get<EarnCoin[]>('/earn/coins');
  }

  async getInsuranceFunds() {
    return this.requestManager.get<InsuranceFund[]>('/earn/insurance-fund');
  }

  async getInsuranceFundsByCoinId(coinId: string) {
    return this.requestManager.get<InsuranceFund[]>(`/earn/insurance-fund/${coinId}`);
  }

  async getUserEntries() {
    return this.requestManager.get<UserEarnEntry[]>('/earn/entries');
  }

  async getUserEntriesByEarnCoinId(earnCoinId: string, query: EarnEntryListRequest = {}) {
    return this.requestManager.get<UserEarnEntryDetails[]>(`/earn/entries/${earnCoinId}`, query);
  }

  async getUserClosedEntriesByEarnCoinId(earnCoinId: string, query: EarnEntryListRequest = {}) {
    return this.requestManager.get<UserEarnEntryDetails[]>(`/earn/entries/${earnCoinId}/closed`, query);
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/earn/minimum');
  }
}
