import { CurrentAddLiquidityPrice, CurrentLiquidityPricesOnDefichain, LiquidityMiningPair, TotalEarning } from '@cakedefi/cake-sdk';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { NewFreezerSlice } from '../types/freezer/freezer-slice';

export const liquidityMiningSelector = (state: RootState) => state.liquidityMining;
export const pairsSelector = createSelector(liquidityMiningSelector, state => state.liquidityMiningPairs);
export const lmPairOptionsSelector = createSelector(
  pairsSelector,
  (pairs: LiquidityMiningPair[]) => pairs?.map(({ id }) => ({ id, checked: false })),
);

export const newFreezerByLMSelector = createSelector(
  (state: RootState): NewFreezerSlice => state.newFreezer,
  (entries => entries),
);

export const liquidityMiningPairsSelector = (state: RootState): Record<string, LiquidityMiningPair> => {
  const { liquidityMiningPairs } = state.liquidityMining;
  if (!liquidityMiningPairs || !liquidityMiningPairs.length) {
    return null;
  }
  return liquidityMiningPairs.reduce((accum, current) => ({
    ...accum,
    [current.id]: current,
  }), {});
};

export const currentLiquidityPricesOnDefichainSelector = (state: RootState): Record<string, CurrentLiquidityPricesOnDefichain> => {
  const { currentLiquidityPricesOnDefichain } = state.liquidityMining;
  if (!currentLiquidityPricesOnDefichain || !currentLiquidityPricesOnDefichain.length) {
    return null;
  }
  return currentLiquidityPricesOnDefichain.reduce((accum, current) => ({
    ...accum,
    [current.liquidityMiningPairId]: current,
  }), {});
};

export const currentAddLiquidityPricesSelector = (state: RootState): Record<string, CurrentAddLiquidityPrice> => {
  const { currentAddLiquidityPrices } = state.liquidityMining;
  if (!currentAddLiquidityPrices || !currentAddLiquidityPrices.length) {
    return null;
  }
  return currentAddLiquidityPrices.reduce((accum, current) => ({
    ...accum,
    [current.liquidityMiningPairId]: current,
  }), {});
};

export const totalEarningsSelector = ({ liquidityMining }: RootState): Record<string, TotalEarning> => {
  const { totalEarnings } = liquidityMining;
  if (!totalEarnings || totalEarnings.length === 0) {
    return null;
  }
  return totalEarnings.reduce((earningMap, current) => ({
    ...earningMap,
    [current.pairId]: current,
  }), {});
};

export const totalLiquiditySharesForUserSelector = createSelector(liquidityMiningSelector, liquidityMining => liquidityMining.totalLiquiditySharesForUser);
