import { OnRampProviderType } from '../../../../types/onramp/onramp-provider';
import { FiatBuyModalState, PriceBreakdownSelectorModel } from '../../../../types/onramp/state';
import formatPercentage from '../../../../utils/formatPercentage';
import { calculatePaymentMethodFee } from './calculatePaymentMethodFee';
import { getTransakSpotPrice } from './getTransakSpotPrice';

export function getPriceBreakdownByProvider(state: FiatBuyModalState): PriceBreakdownSelectorModel {
  const { type, banxa, transak, fiatSwappable, inputs, error } = state;
  const baseResult: Pick<PriceBreakdownSelectorModel, 'error' | 'fiatCode' | 'walletAddress' |'showFeeAmount' | 'isLoadingWallet'> = {
    error,
    fiatCode: inputs.source,
    walletAddress: fiatSwappable.coin ? fiatSwappable.walletAddress : inputs.walletAddress,
    showFeeAmount: true,
    isLoadingWallet: fiatSwappable.isLoadingWalletAddress || inputs.isLoadingWalletAddress,
  };

  switch (type) {
    case OnRampProviderType.TRANSAK: {
      const {
        selectedFiatPrice,
      } = transak;
      const transakPrice: PriceBreakdownSelectorModel = {
        ...baseResult,
        totalFeeAmount: selectedFiatPrice?.totalFee,
        totalFiatAmount: selectedFiatPrice?.fiatAmount,
        feePercentage: '0',
        spotPrice: getTransakSpotPrice(selectedFiatPrice),
        showFeePercentage: false,
        feePostFixText: 'included',
      } as any;
      return transakPrice;
    }

    case OnRampProviderType.BANXA:
    default: {
      const { fiatPrices, selectedFiatPrice, selectedPaymentMethod } = banxa;
      const percentageFee = calculatePaymentMethodFee(selectedPaymentMethod)?.percentageFee;
      const banxaPrice: PriceBreakdownSelectorModel = {
        ...baseResult,
        totalFeeAmount: selectedFiatPrice?.feeAmount,
        totalFiatAmount: selectedFiatPrice?.fiatAmount,
        feePercentage: percentageFee && formatPercentage(percentageFee),
        spotPrice: fiatPrices ? fiatPrices.spotPrice : null,
        showFeePercentage: true,
      } as any;
      return banxaPrice;
    }
  }
}
