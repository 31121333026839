import BigNumber from 'bignumber.js';
import {
  getCoinAmount,
  getTotalSharesAmount,
} from '../../liquidityMiningUtils';

export function calculateSharesReceivedByPrimaryAmount(
  primaryAmount: number | string,
  shareDecimals: number | string,
  secondaryDecimals: number,
  primaryCoinSharePrice,
  secondaryCoinSharePrice,
) {
  const sharesReceived = getTotalSharesAmount(
    primaryAmount,
    primaryCoinSharePrice,
    shareDecimals,
  );

  const { secondaryCoinAmount: secondaryCoinAmountToChange } = getCoinAmount(
    { primaryCoinSharePrice, secondaryCoinSharePrice },
    sharesReceived,
  );

  return {
    sharesReceived: sharesReceived.toFixed(),
    secondaryAmount: secondaryCoinAmountToChange
      .decimalPlaces(secondaryDecimals, BigNumber.ROUND_UP)
      .toFixed(),
  };
}
