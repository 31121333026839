import { push } from './dataLayer';

export interface iPageViewProps {
  'event'?: string;
  'event_type'?: string;
  'moengage_id'?: string;
  'kyc_full_status'?: string;
  'referral_code'?: string | null;
  'user_country_code'?: string;
}

const pageView = (pageViewProps: iPageViewProps) => {
  const { moengage_id, kyc_full_status, user_country_code, referral_code } = pageViewProps; // eslint-disable-line
  const pageViewPayload = {
    event: 'cake.page_view',
    event_type: 'automatical',
    user_id: moengage_id, // It was expensive to change the user attribute user_id to moengage_id in GTM. So sending the moengage_id to identify the user and the GTM will expect the user_id
    kyc_full_status, // UNVERIFIED | VERIFIED
    referral_code,
    user_country_code, // IN | SG
  };
  push(pageViewPayload);
};

export default pageView;
