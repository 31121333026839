import { Styles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint, getMinWidthMd } from '../Variables.styles';

const shouldShowScrollBar = (isMobile: boolean) => {
  if (isMobile) {
    return {
      display: 'none',
    };
  }
  return {};
};

const styles: Styles = {
  bottomTab: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    borderTop: Variables.primaryBorder,
    width: '100%',
    zIndex: 120,
    backgroundColor: Variables.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '64px',
    alignItems: 'center',
  },
  assetsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chatIconContainer: {
    padding: '0 8px',
  },
  contentContainer: ({ isMobile }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: Variables.medium,
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': shouldShowScrollBar(isMobile),
    scrollbarWidth: 'none', // hide scrollbar for firefox
  }),
  vipBadgeMobile: {},
  vipBadgeDesktop: {
    display: 'none',
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    contentContainer: {
      width: '100%',
    },
  },
  [getMinWidthMd]: {
    vipBadgeMobile: {
      display: 'none',
    },
    vipBadgeDesktop: {
      display: 'block',
    },
    contentContainer: {
      scrollbarWidth: 'auto',
    },
  },
  balanceContainter: {
    margin: `0 ${Variables.medium}`,
    minWidth: '120px',
    whiteSpace: 'nowrap',

    '&__tooltips': {
      position: 'absolute',
      top: '0',
      marginLeft: '-120px',
      minWidth: '350px',
      display: 'none',
      padding: Variables.medium,
      transform: 'translateY(-105%)',
      backgroundColor: Variables.white,
      border: '0.5px solid rgba(0, 0, 0, 0.16)',
      borderRadius: '8px',
      zIndex: '9080',
      fontSize: '1.6rem',
    },

    '&:hover &__tooltips': {
      display: 'block',
      boxShadow: Variables.primaryBoxShadow,
    },
  },
  secondaryText: {
    display: 'block',
    fontSize: '1.4rem',
    fontWeight: '500',
    lineHeight: '16px',
    color: Variables.textSecondary,
  },
  bignumberText: {
    fontSize: '1.4rem',
  },
  primaryText: {
    display: 'block',
    fontSize: '1.4rem',
    fontWeight: '500',
    lineHeight: Variables.large,
    color: Variables.black,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  eyeButton: {
    height: '36px',
    border: Variables.primaryBorder,
    borderRadius: Variables.extraSmall,
    padding: '8px',
    margin: `0 ${Variables.medium}`,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  balanceButton: {
    margin: `0 ${Variables.medium}`,
    whiteSpace: 'nowrap',
  },
  linkText: {
    '&:focus': {
      outline: 'none !important',
      '&::placeholder': {
        color: 'transparent',
      },
    },
  },
};

export default styles;
