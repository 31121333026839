import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 21 });

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

export default function show8Digits(value) {
  const bnValue = new BigNumber(value);
  const roundingMode = BigNumber.ROUND_DOWN;

  const valueAsString = bnValue.toFixed();
  const splittedString = valueAsString.split('.');

  const wholeNumber = splittedString[0];
  const wholeNumberDigits = wholeNumber.length;

  let decimals = 8 - wholeNumberDigits;

  if (decimals < 0) {
    decimals = 0;
  } else if (decimals > 5) {
    decimals = 5;
  } // decimals should be between 0 and 5 only

  return bnValue.decimalPlaces(decimals, roundingMode).toFormat(fmt);
}
