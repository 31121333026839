import { ModalContent } from 'modals';
import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Spinner } from '../../../views/components';
import styles from '../../styles/Modal.styles';

const useStyles = createUseStyles(styles);

export default function ModalLoadingIndicator({ placeholder = 'Processing...', isSpinnerVisible = true }) {
  const classes = useStyles();

  return (
    <ModalContent dividers={false}>
      <div className={classes.content}>
        {
          isSpinnerVisible && (
            <div className={classes.section}>
              <Spinner />
            </div>
          )
        }
        <div className={classes.section}>
          <div className={classes.centerContent}>
            <p><Trans i18nKey={placeholder}>{placeholder}</Trans></p>
          </div>
        </div>
      </div>
    </ModalContent>
  );
}
