import { RootState } from 'app/rootReducer';
import { getPreferredLanguage } from './languageRegionSettingsUtils';

export const DEFAULT_CURRENCY = 'USD';

export const selectedCurrencySelector = (state: RootState) => {
  const { selectedCurrency } = state.languageRegionSettings;
  if (selectedCurrency) {
    return selectedCurrency;
  }
  return DEFAULT_CURRENCY;
};

export const selectedLanguageSelector = (state: RootState) => getPreferredLanguage(state.languageRegionSettings?.selectedLanguage);
