import React from 'react';
import { createUseStyles } from 'react-jss';
import { Trans } from 'react-i18next';
import Card from '../Card/Card';
import { CardSelectionItem } from './CardSelectionItem';
import styles from './CardSelection.styles';

const useStyles = createUseStyles(styles);

interface CardSelectionProps {
  items: CardSelectionItem[];
  onChange: (value: string) => void;
}

export default function CardSelection({
  items,
  onChange,
}: CardSelectionProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.itemContainer}>
        {items.map(({ label, value, icon, iconMaxHeight, selected }) => (
          <Card selected={selected} key={value}>
            <div className={icon ? classes.item : classes.itemNoIcon} onClick={() => onChange(value)}>
              { icon && <div className={classes.logoContainer}>
                <img
                  style={{ maxHeight: iconMaxHeight }}
                  src={icon}
                  alt={`${label}`}
                />
              </div> }
              <div className={classes.name}><Trans>{label}</Trans></div>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
}
