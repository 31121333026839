import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import appConfig from '../../config/app';

const reCaptchaLanguageMapper = {
  en: 'en',
  de: 'de',
  zht: 'zh-TW',
  it: 'it',
};

interface RecaptchaProps {
  onReCaptchaComplete: (reCaptchaToken: string) => void;
  selectedLanguage: string;
  level: 'secure' | 'regular';
}

const InvisibleRecaptcha = forwardRef((props: RecaptchaProps, ref) => {
  const [isRendered, setIsRendered] = useState(false);
  const { onReCaptchaComplete, selectedLanguage, level } = props;
  const reCaptchaRef = useRef(null); // nosemgrep: typescript.react.security.audit.react-no-refs.react-no-refs
  const [language, setLanguage] = useState('en');
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };
  const siteKey = level === 'secure' ? appConfig.SECURERECAPTCHAKEY : appConfig.REGULARRECAPTCHAKEY;

  const verifyCallback = (reCaptchaToken: string) => {
    onReCaptchaComplete(reCaptchaToken);
  };

  const removeUselessGRecaptcha = () => {
    // if there are more than one grecaptcha-badge, we won't see the badge on the UI
    // so we need to remove the unused grecaptcha-badge and only keep the first one
    const elements = document.querySelectorAll('.grecaptcha-badge');
    if (elements.length > 1) {
      Array.from(elements).slice(1).forEach(element => {
        element.remove();
      });
    }
  };

  useEffect(() => {
    const languageCode = reCaptchaLanguageMapper[selectedLanguage]; // nosemgrep: javascript.lang.security.audit.detect-bracket-object-injection.detect-bracket-object-injection
    if (languageCode) {
      setLanguage(languageCode);
    } else {
      setLanguage('en');
    }
    if (isRendered) {
      reCaptchaRef.current?.reset();
    }
  }, [isRendered, selectedLanguage]);

  useEffect(() => {
    if (isRendered) {
      removeUselessGRecaptcha();
      reCaptchaRef.current?.reset();
    }
  }, [isRendered]);

  useImperativeHandle(
    ref,
    () => ({
      reset: () => reCaptchaRef.current?.reset(),
      submit: () => reCaptchaRef.current?.execute(),
    }),
  );

  return (
    <Reaptcha // nosemgrep: typescript.react.security.audit.react-no-refs.react-no-refs
        ref={reCaptchaRef}
        sitekey={siteKey}
        hl={language}
        size="invisible"
        onVerify={(reCaptchaToken: string) => verifyCallback(reCaptchaToken)}
        key={language}
        onRender={() => setIsRendered(true)}
      />
  );
});

export default memo(InvisibleRecaptcha, (prevProps, nextProps) => (
  prevProps.selectedLanguage === nextProps.selectedLanguage
));
