import React from 'react';
import ReactSelect, { components } from 'react-select';
import Option from './Option';
import getStyles, { getThemes } from './Select.styles';
import InfoIconPopover from '../InfoIconPopover/InfoIconPopover';
import { SelectOption } from '../../../types/select/select-option';

interface SelectProps {
  alignSelf?: 'normal' | 'center',
  border?: boolean;
  containerDisplay?: 'inherit' | 'inline-block' | 'block',
  containerWidth?: string;
  defaultValue?: any;
  isLoading?: boolean;
  isSearchable?: boolean;
  menuPortalTarget?: any,
  minWidth?: string;
  onChange: (option: SelectOption) => void;
  optionComponent?: React.ReactNode;
  options: any;
  paddingType?: 'normal' | 'textInput',
  placeholder?: string;
  tabIndex?: number;
  tooltip?: string;
  value?: SelectOption;
  valueJustify?: string;
  isDisabled?: boolean;
}

const DropdownIndicator = (
  props: any,
) => {
  const { tooltip } = props.selectProps;
  return (
    <>
      { tooltip && <InfoIconPopover popoverMessage={tooltip}/> }
      <components.DropdownIndicator {...props}/>
    </>
  );
};

export default function Select({
  options,
  defaultValue,
  value,
  onChange,
  border = true,
  containerDisplay = 'inherit',
  containerWidth = 'auto',
  isSearchable = false,
  isLoading = false,
  placeholder = '',
  minWidth = 'auto',
  valueJustify = 'flex-end',
  paddingType = 'normal',
  alignSelf = 'center',
  menuPortalTarget,
  tooltip = '',
  optionComponent,
  tabIndex,
  isDisabled = false,
}: SelectProps) {
  const valueContainerPadding = paddingType === 'normal' ? '0 0 0 8px' : '9px 10px 9px 12px';
  const selectStyles = getStyles({ border, containerDisplay, containerWidth, minWidth, valueJustify, valueContainerPadding, alignSelf });
  const valueProps = defaultValue ? { defaultValue } : { value };
  return (
    <ReactSelect
      {...valueProps}
      options={options}
      styles={selectStyles}
      theme={getThemes}
      isSearchable={isSearchable}
      isLoading={isLoading}
      placeholder={placeholder}
      formatOptionLabel={optionComponent ?? Option}
      onChange={onChange}
      menuPortalTarget={menuPortalTarget}
      tooltip={tooltip}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
      }}
      tabIndex={tabIndex}
      isDisabled={isDisabled}
    />
  );
}
