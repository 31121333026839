import isPositiveAndNotZeroNumber from './isPositiveAndNotZeroNumber';

const getCoinsOnSale = (walletByCoins: any[], coinId: string): string => {
  const wallet = walletByCoins.find(({ id }) => id === coinId);
  if (!wallet) {
    return '0';
  }
  const isValid = isPositiveAndNotZeroNumber(wallet.coinsOnSale);
  return isValid ? wallet.coinsOnSale : '0';
};

export default getCoinsOnSale;
