import { CakeApiBase } from '../CakeApiBase';

interface SetBundleIdInCacheBody {
  id: string
}

export class BundleApi extends CakeApiBase {
  async setBundleIdInCache(body: SetBundleIdInCacheBody) {
    return this.requestManager.post<any>('/bundle/set-bundle-id', body);
  }
}
