import storageBase from './storageBase';

const propertyName = 'user-token';

const userTokenStorage = {
  set: (value, options) => storageBase.set(propertyName, value, options),
  get: () => storageBase.get(propertyName),
  clear: () => storageBase.clear(propertyName),
};

export default userTokenStorage;
