import { Styles } from 'react-jss';
import Variables from '../../../Variables.styles';

export const sharedFiatBuyModalStyles = {
  paragraphTitle: {
    fontWeight: 500,
  },
};

const styles: Styles = {
  ...sharedFiatBuyModalStyles,
  priceAndPaymentContainer: {
    padding: Variables.larger,
  },
  paragraphTitle: {
    fontWeight: 500,
  },
  inlineLinkButton: {
    composes: ['btn', 'btn-link'],
    display: 'inline-block',
  },
  providerLogo: {
    marginBottom: Variables.large,
  },
  warningMessage: {
    marginTop: Variables.large,
    marginBottom: Variables.extraLarge,
    textAlign: 'center',
  },
  errorIcon: {
    color: Variables.redInvalidControl,
    fontSize: '2rem',
    marginRight: Variables.extraSmall,
  },
};

export default styles;
