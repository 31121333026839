import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import constants from './constants';

export default function coinsToNativeCurrency(
  coins: BigNumberConstructable,
  usdPrice: BigNumberConstructable,
  currencyRate: BigNumberConstructable,
  format: BigNumber.Format = constants.BN_STRING_FORMAT,
): string {
  if (coins && usdPrice && currencyRate) {
    const a = new BigNumber(coins);
    const b = new BigNumber(usdPrice);

    if (!a.isNaN() && !b.isNaN()) {
      let result = a.multipliedBy(b).multipliedBy(currencyRate);
      if (result.gt(1000 * 1000 * 1000)) {
        result = result.dividedBy(1000 * 1000 * 1000);
        return `${result.toFormat(constants.DISPLAYED_DP, BigNumber.ROUND_DOWN, format)}B`;
      }
      return result.toFormat(constants.DISPLAYED_DP, BigNumber.ROUND_DOWN, format);
    }
  }
  return '0.00';
}
