import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import SegmentedNumberInputStyles from './SegmentedNumberInput.styles';

const useStyles = createUseStyles(SegmentedNumberInputStyles);

const KEY_CODE_ARROW_LEFT = 'ArrowLeft';
const KEY_CODE_ARROW_RIGHT = 'ArrowRight';
const KEY_CODE_BACKSPACE = 'Backspace';
const KEY_CODE_DELETE = 'Delete';

const SegmentedNumberInput = forwardRef(({
  segments = 0,
  isFocusedOnMount = false,
  onComplete,
}, ref) => {
  const childInputs = useRef([]);
  const [values, setValues] = useState(Array(segments).fill(''));
  const classes = useStyles({ segments });

  const onChangeSingleInput = (value, index) => {
    setValues(values.map((v, i) => (i === index ? value : v)));
    if (/^[0-9]/.test(value) && index < segments) {
      if (index < segments - 1) {
        childInputs.current[index + 1].focus();
      }
    }
  };

  const handleSwitchInputs = useCallback((e) => {
    const focusedIndex = childInputs.current.findIndex(childInputRef => ReactDOM.findDOMNode(childInputRef) === document.activeElement);
    const nextFocusedIndex = e.code === KEY_CODE_ARROW_LEFT ? focusedIndex - 1 : focusedIndex + 1;
    if (nextFocusedIndex >= 0 && nextFocusedIndex <= segments - 1) {
      childInputs.current[nextFocusedIndex].focus();
    }
  }, [segments]);

  const handleDeletion = useCallback((e) => {
    const focusedIndex = childInputs.current.findIndex(childInputRef => ReactDOM.findDOMNode(childInputRef) === document.activeElement);
    if (focusedIndex >= 0) {
      e.preventDefault(); // only prevent default when we are focusing on the input
      setValues(values.map((v, i) => (i === focusedIndex ? '' : v)));
      if (focusedIndex > 0) {
        childInputs.current[focusedIndex - 1].focus();
      }
    }
  }, [values]);

  const onKeydown = useCallback((e) => {
    if (e.code && (e.code === KEY_CODE_ARROW_LEFT || e.code === KEY_CODE_ARROW_RIGHT)) {
      return handleSwitchInputs(e);
    }
    if (e.code && (e.code === KEY_CODE_BACKSPACE || e.code === KEY_CODE_DELETE)) {
      return handleDeletion(e);
    }
  }, [handleSwitchInputs, handleDeletion]);

  const onPaste = (e) => {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    e.preventDefault();
    if (!Number.isNaN(Number(pastedText))) {
      const numbersArray = pastedText.substr(0, segments).split('');
      setValues(numbersArray);
    }
  };

  useEffect(() => {
    const accumulatedValue = values.reduce((accum, current) => `${accum}${current}`, '');
    if (accumulatedValue.length === segments) {
      onComplete(accumulatedValue);
    }
    window.addEventListener('keydown', onKeydown);
    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [values, onComplete, segments, onKeydown, isFocusedOnMount]);

  useEffect(() => {
    if (isFocusedOnMount && childInputs.current && childInputs.current.length) {
      childInputs.current[0].focus();
    }
  }, [isFocusedOnMount]);

  useImperativeHandle(
    ref,
    () => ({
      clear: () => setValues(Array(segments).fill('')),
    }),
  );

  return (
    <div className={classes.container} onPaste={onPaste}>
      {
        [...Array(segments).keys()].map(index => (
          <input
            ref={element => childInputs.current.push(element)}
            key={index}
            className={classes.input}
            type="text"
            maxLength={1}
            onFocus={e => e.target.select()}
            onChange={e => onChangeSingleInput(e.target.value, index)}
            inputMode="numeric"
            value={values[index]}
          />
        ))
      }
    </div>
  );
});

export default SegmentedNumberInput;
