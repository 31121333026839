import { MinimumAmountInUsd } from '../../schema';
import { Liquidation, LoanCoin, LoanProtocol, LoanVault } from '../../schema/loan';
import { CakeApiBase } from '../CakeApiBase';

export interface CoinMap {
  [coinId: string]: string;
}

export class LoanApi extends CakeApiBase {
  async getLoanVault() {
    return this.requestManager.get<LoanVault[]>('/loans');
  }

  async addCollateral(protocol: string, collateralMap: CoinMap) {
    return this.requestManager.post(`/loans/${protocol}/collateral/add`, { collateralMap });
  }

  async removeCollateral(protocol: string, collateralMap: CoinMap) {
    return this.requestManager.post(`/loans/${protocol}/collateral/remove`, { collateralMap });
  }

  async getLoanCoins(protocol: string) {
    return this.requestManager.get<LoanCoin[]>(`/loans/${protocol}/coins`);
  }

  async takeLoan(protocol: string, loanMap: CoinMap, swapTo?: string) {
    return this.requestManager.post(`/loans/${protocol}/borrow`, { loanMap, swapTo });
  }

  async getLoanProtocols() {
    return this.requestManager.get<LoanProtocol[]>('/loans/protocols');
  }

  async repayLoan(protocol: string, loanMap: CoinMap) {
    return this.requestManager.post(`/loans/${protocol}/repay`, { loanMap });
  }

  async getLiquidations(protocol: string, offset: number, limit: number) {
    return this.requestManager.get<Liquidation[]>(`/loans/${protocol}/liquidations`, { offset, limit });
  }

  async dismissLiquidation(protocol: string, id: string) {
    return this.requestManager.post(`/loans/${protocol}/liquidations/${id}/dismiss`);
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/loans/minimum');
  }
}
