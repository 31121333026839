import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { getLMPairValueInPreferredCurrencySelector } from 'utils/getLMPairValueInPreferredCurrencySelector';
import { RootState } from '../app/rootReducer';
import { summarizeLMBalanceInUsd } from '../liquidity-mining/liquidityMiningUtils';
import { summarizeBalanceInUsd } from '../wallet/walletService';

const balanceSelector = (state: RootState) => state.wallet.balances;
const currencySelector = (state: RootState) => state.currency;
const lmBalanceSelector = (state: RootState) => state.wallet.lmBalances;

export const balanceSummarySelector = createSelector(currencySelector, balanceSelector, (currency, balances): {
  coin: BigNumber,
  share: BigNumber,
  lending: BigNumber,
} => {
  if (currency.coins.pricing.length === 0 || !balances || balances.length === 0) {
    return null;
  }
  // also filter discontinued coins
  const filteredBalances = balances.filter(balance => !balance.coin.discontinued);
  return summarizeBalanceInUsd(filteredBalances, currency.coins.pricing);
});

export const lmBalanceSummarySelector = createSelector(currencySelector, lmBalanceSelector, (currency, lmBalances) => {
  if (currency.coins.pricing.length === 0 || !lmBalances) {
    return null;
  }
  return summarizeLMBalanceInUsd(lmBalances, currency.coins.pricing);
});

export const totalFrozenLiquidityInPreferredCurrencySelector = createSelector(
  (state: RootState) => state.liquidityMining.totalLiquiditySharesForUser,
  getLMPairValueInPreferredCurrencySelector,
  (totalLiquiditySharesForUser, getLMPairValueInPreferredCurrency) => {
    if (!totalLiquiditySharesForUser) return null;
    const totalFrozen = totalLiquiditySharesForUser.reduce((acc, lmPair) => {
      const { shareValuesInPreferredCurrency } = getLMPairValueInPreferredCurrency(lmPair.frozen, lmPair.liquidityMiningPairId);
      return acc.plus(shareValuesInPreferredCurrency ?? 0);
    }, new BigNumber(0));
    return totalFrozen;
  },
);
