import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib';

// eslint-disable-next-line func-names
export default function (value: BigNumberConstructable) {
  if (BigNumber.isBigNumber(value)) {
    const isBnZero = value.eq(0);
    return isBnZero;
  }

  const isZero = value === 0 || value === '0.00' || value === '0';
  return isZero;
}
