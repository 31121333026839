import React from 'react';
import heroImage from 'assets/svg/referral/eleven-eleven.svg';
import { createUseStyles } from 'react-jss';
import { getMinWidthMd } from 'Variables.styles';

const useStyles = createUseStyles({
  elevenElevenImageHeroWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '176px',
    width: '100%',
  },
  elevenElevenImageHero: {
    width: '100%',
  },
  [getMinWidthMd]: {
    elevenElevenImageHeroWrapper: {
      alignItems: 'center',
      minHeight: '194px',
    },
  },
});

export default function ElevenElevenPromoHeroImage() {
  const classes = useStyles();
  return (
    <div className={classes.elevenElevenImageHeroWrapper}>
      <img className={classes.elevenElevenImageHero} alt='elevenEleven' src={heroImage}/>
    </div>
  );
}
