import React, { useContext } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import Variables from 'Variables.styles';
import { StandaloneModalType } from 'modals/useStandaloneModal';
import { StandaloneModalContext } from 'modals/StandaloneModalContext';

const useStyles = makeStyles({
  paper: {
    verticalAlign: 'top',
    overflowY: 'visible',
    height: 'auto',
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

interface StandaloneModalDialogProps {
  modalType: StandaloneModalType;
  children: React.ReactNode;
  zIndex?: number;
}

const StandaloneModalDialog = ({
  modalType,
  zIndex = Variables.zIndex.standaloneModal,
  children,
}: StandaloneModalDialogProps) => {
  const classes = useStyles();
  const { isShowModal, closeModal } = useContext(StandaloneModalContext);

  const handleClose = (event, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') return;
    closeModal(modalType);
  };

  return (
    <Dialog
      style={{
        zIndex,
      }}
      classes={{
        paper: classes.paper,
        scrollPaper: classes.scrollPaper,
      }}
      fullWidth={false}
      open={isShowModal(modalType)}
      maxWidth={'md'}
      scroll={'body'}
      transitionDuration={50}
      onClose={handleClose}
    >
      {children}
    </Dialog>
  );
};

export default StandaloneModalDialog;
