import bchaddr from 'bchaddrjs';

export enum BchAddressFormatTypes {
  CASH = 'CASH',
  LEGACY = 'LEGACY',
}

export default function getBchAddressInFormat(address: string, format: BchAddressFormatTypes): string | null {
  if (!bchaddr.isValidAddress(address)) {
    return null;
  }
  switch (format) {
    case BchAddressFormatTypes.LEGACY:
      return bchaddr.toLegacyAddress(address);
    case BchAddressFormatTypes.CASH:
      return bchaddr.toCashAddress(address);
    default:
      return null;
  }
}
