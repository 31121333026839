interface Storage {
  setItem(key: string, value: any);
  getItem(key: string);
  removeItem(key: string);
  clear();
}

export class InMemoryStorage implements Storage {
  map: { [key: string]: any };

  constructor() {
    this.map = {};
  }

  setItem(key: string, value: any) {
    this.map[key] = value;
  }

  getItem(key: string) {
    return this.map[key];
  }

  removeItem(key: string) {
    delete this.map[key];
  }

  clear() {
    this.map = {};
  }
}

function getStorageImplementation(): Storage {
  try {
    return window.sessionStorage;
  } catch (e) {
    return new InMemoryStorage();
  }
}

function getLocalStorageImplementation(): Storage {
  try {
    return window.localStorage;
  } catch (e) {
    return new InMemoryStorage();
  }
}

const storage: Storage = getStorageImplementation();
const webLocalStorage: Storage = getLocalStorageImplementation();
export { webLocalStorage, storage };
