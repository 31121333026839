import {
  Bake4TheWinCampaignDetails,
  Bake4TheWinLuckyDrawTickets,
  Bake4TheWinUnboxedRewards,
  Bake4TheWinUnboxReward,
  Bake4TheWinUnOpenedRewards,
  BtcPizzaDayPriceAssumption,
  CampaignDetails,
  CampaignDetailsForUser,
  EthLuckyDrawCalculateTicket,
  EthLuckyDrawCampaignDetails,
  EthLuckyDrawCampaignDetailsForUser,
} from '../../schema/campaign';
import { CakeApiBase } from '../CakeApiBase';

export class CampaignApi extends CakeApiBase {
  async getDetailsForUser() {
    return this.requestManager.get<CampaignDetailsForUser>('/campaign/user');
  }

  async getDetails() {
    return this.requestManager.get<CampaignDetails>('/campaign');
  }

  async getEthLuckyDrawDetailsForUser() {
    return this.requestManager.get<EthLuckyDrawCampaignDetailsForUser>('/campaign/authenticated/eth-lucky-draw');
  }

  async getEthLuckyDrawDetails() {
    return this.requestManager.get<EthLuckyDrawCampaignDetails>('/campaign/eth-lucky-draw');
  }

  async calculateEthLuckyDrawTickets(amount: string) {
    return this.requestManager.post<EthLuckyDrawCalculateTicket>('/campaign/authenticated/eth-lucky-draw/calculate-tickets', { amount });
  }

  async submitBtcPizzaDayAssumption(payload: BtcPizzaDayPriceAssumption) {
    return this.requestManager.put<BtcPizzaDayPriceAssumption>('/campaigns/btc-pizza-prediction', payload);
  }

  async getBtcPizzaDayAssumptionForUser() {
    return this.requestManager.get<BtcPizzaDayPriceAssumption>('/campaigns/btc-pizza-prediction');
  }

  async getBake4TheWinCampaignInfo() {
    return this.requestManager.get<Bake4TheWinCampaignDetails>('/campaigns/cake-birthday-campaign');
  }

  async getBake4TheWinUnOpenedRewardsForUser() {
    return this.requestManager.get<Bake4TheWinUnOpenedRewards>('/campaigns/cake-birthday-campaign/unopened-rewards');
  }

  async getBake4TheWinOpenedRewardsForUser() {
    return this.requestManager.get<Bake4TheWinUnboxedRewards>('/campaigns/cake-birthday-campaign/unboxed-rewards');
  }

  async getBake4TheWinLuckyDrawTickets() {
    return this.requestManager.get<Bake4TheWinLuckyDrawTickets>('/campaigns/cake-birthday-campaign/allocated-tickets');
  }

  async unboxBake4TheWinReward() {
    return this.requestManager.put<Bake4TheWinUnboxReward>('/campaigns/cake-birthday-campaign/unbox');
  }
}
