import { AxiosError, AxiosResponse } from 'axios';

export class CakeError extends Error {
  public code: string;

  public error: AxiosResponse | AxiosError;

  constructor(error: AxiosError) {
    const { response } = error;
    if (response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { code, message } = getResponseError(response);
      super(message);
      this.code = code;
      this.name = code;
      this.error = response;
    } else {
      super(JSON.stringify(error));
      this.message = error.message;
      this.code = error.code;
      this.error = error;
    }
  }
}

const getResponseError = (response: AxiosResponse<any>): {
  code: string,
  message: Error['message'],
} => {
  // when cake-sdk is calling cake-core endpoint that does not exist
  // this can happen if we remove the endpoint in cake-core and cake-sdk
  // is not updated correctly, or still using an older incompatible version
  if (response.status === 404 && typeof response.data === 'string') {
    return {
      code: '404',
      message: 'Unexpected error. Unable to contact server',
    };
  }

  return {
    code: response.data?.code ?? '',
    message: response.data.message ?? '',
  };
};
