import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import BigNumber from 'bignumber.js';
import ReactSvg from 'react-svg';
import { Row, Col } from 'reactstrap';
import proofIcon from '../../../assets/svg/icon-proof.svg';
import {
  getLocalizedDate,
  localizeBigNumber,
} from '../../../utils';
import MouseOverTooltip from '../MouseOverTooltip';
import BigNumberDisplay from '../../../common/components/BigNumberDisplay/BigNumberDisplay';
import { PROMO_TYPE_SLUG } from '../Sprod/constants';
import CopyButton from '../../../common/components/CopyButton/CopyButton';

export default class BatchDetails extends Component {
  renderCoinEntry() {
    const {
      batch,
      batchTableType,
      isLoggedIn,
    } = this.props;

    const { userEntryFee, additionalDfiReward } = batch;
    if (!isLoggedIn || !batch.userEntry || new BigNumber(batch.userEntry).isZero()) return;

    const ongoingBatchesCoinEntryClassName = new BigNumber(batch.userEntry).isGreaterThan(0) ? 'entry-details-ongoing-batches' : 'entry-details-ongoing-batches without-compound';
    if (batchTableType === 'ongoing') {
      return (
        <div className={ongoingBatchesCoinEntryClassName}>
          <div className="coin-entry">
            <b><Trans>My entry</Trans></b>
            <span className="text-secondary">{this.renderUserEntry()}</span>
          </div>
          {additionalDfiReward ? (
            <div className="coin-entry">
              <b><Trans>Fixed DFI return</Trans></b>
              <span className="text-secondary">{this.renderDfiReward(additionalDfiReward)}</span>
            </div>
          ) : null}
          {new BigNumber(userEntryFee).eq(0) ? null : (
            <div className="coin-entry">
              <b><Trans>Entry fee</Trans></b>
              <span className="text-secondary">{this.renderEntryFee()}</span>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="entry-details-past-batches">
        <div className="coin-entry">
          <b><Trans>My entry</Trans></b>
          <span className="text-secondary">{this.renderUserEntry()}</span>
        </div>
        <div className="coin-entry flex-wrap">
          <b><Trans>Actual return</Trans></b>
          {this.renderActualReturn()}
        </div>
      </div>
    );
  }

  renderActualReturn = () => {
    const {
      batch,
      sprod,
    } = this.props;
    const { userReturn, additionalDfiReward } = batch;

    if (!userReturn) {
      return <span className="text-secondary">-</span>;
    }

    return (
      <div>
        <div className="text-secondary">
          <BigNumberDisplay currency={sprod.CoinId}>
            {userReturn}
          </BigNumberDisplay>
        </div>
        {additionalDfiReward ? (
          <div className="text-secondary">
            <BigNumberDisplay currency="DFI">
              {additionalDfiReward}
            </BigNumberDisplay>
          </div>
        ) : null}
      </div>
    );
  }

  async setAutoCompoundOption(optionValue) {
    const {
      batch,
      updateAutoCompounding,
      refreshEstimatedAutocompounds,
      sprod,
    } = this.props;
    await updateAutoCompounding(batch, optionValue);
    await refreshEstimatedAutocompounds(sprod.id);
  }

  renderCompoundSettings() {
    const {
      batch,
      batchTableType,
      isLoggedIn,
      isLoading,
    } = this.props;

    if (!isLoggedIn) return;

    if (batchTableType === 'ongoing' && new BigNumber(batch.userEntry).isGreaterThan(0)) {
      return (
        <div className="batch-details-compound-settings">
          <div className="compound-settings-selected-text">
            <b><Trans>Auto-compound</Trans></b>
            <span>{this.renderAutoCompoundInfo()}</span>
            <span><Trans>will be auto-compounded upon maturity</Trans></span>
          </div>
          <form>
            <fieldset>
              <input type="radio" name="compound-settings-option" value="NO_COMPOUNDING" onChange={() => this.setAutoCompoundOption('NO_COMPOUNDING')} disabled={isLoading} checked={batch.autoCompoundOption === 'NO_COMPOUNDING'} />
              <label><Trans>Do not enter following batch automatically (no auto-compound)</Trans></label>
            </fieldset>
            <fieldset>
              <input type="radio" name="compound-settings-option" value="ONLY_ENTRY" onChange={() => this.setAutoCompoundOption('ONLY_ENTRY')} disabled={isLoading} checked={batch.autoCompoundOption === 'ONLY_ENTRY'} />
              <label><Trans>Entry only</Trans></label>
            </fieldset>
            <fieldset>
              <input type="radio" name="compound-settings-option" value="ENTRY_AND_RETURN" onChange={() => this.setAutoCompoundOption('ENTRY_AND_RETURN')} disabled={isLoading} checked={batch.autoCompoundOption === 'ENTRY_AND_RETURN'} />
              <label><Trans>Entry and return</Trans></label>
            </fieldset>
          </form>
        </div>
      );
    }
  }

  renderAutoCompoundInfo() {
    const { batch, sprod } = this.props;

    const formattedUserEntry = new BigNumber(batch.userEntry).toFormat();

    if (batch.autoCompoundOption === 'NO_COMPOUNDING') {
      return <span><Trans>Neither your batch entry, nor your returns</Trans></span>;
    } if (batch.autoCompoundOption === 'ONLY_ENTRY') {
      return <span><BigNumberDisplay isSensitiveNumber noTooltip emptyClass>{formattedUserEntry}</BigNumberDisplay> {sprod.CoinId} </span>;
    } if (batch.autoCompoundOption === 'ENTRY_AND_RETURN') {
      return <span><BigNumberDisplay isSensitiveNumber noTooltip emptyClass>{formattedUserEntry}</BigNumberDisplay> {sprod.CoinId} + <Trans>your returns</Trans></span>;
    }

    return null;
  }

  renderUserEntry() {
    const { sprod, batch } = this.props;

    if (!batch.userEntry || !sprod.CoinId) {
      return '-';
    }

    return (
      <BigNumberDisplay isSensitiveNumber currency={sprod.CoinId}>
        {batch.userEntry}
      </BigNumberDisplay>
    );
  }

  renderDfiReward(dfiReward) {
    const { sprod, batch } = this.props;

    if (!batch.userEntry || !sprod.CoinId) {
      return '-';
    }

    const userEntryDfiRewardDisplay = localizeBigNumber(dfiReward, false, 8);
    const userEntryDfiRewardTooltip = localizeBigNumber(dfiReward, true);

    return (
      <MouseOverTooltip
        renderTooltip={() => this.renderPopover(userEntryDfiRewardTooltip)}
      >
        <span>{userEntryDfiRewardDisplay} DFI</span>
      </MouseOverTooltip>
    );
  }

  renderEntryFee() {
    const { sprod, batch } = this.props;
    const { userEntry, userEntryFee } = batch;

    if (!userEntry || !sprod.CoinId) {
      return '-';
    }

    const userEntryFeeDisplay = localizeBigNumber(userEntryFee, false, 8);
    const userEntryFeeTooltip = localizeBigNumber(userEntryFee, true);

    return (
      <MouseOverTooltip
        renderTooltip={() => this.renderPopover(userEntryFeeTooltip)}
      >
        <span>{userEntryFeeDisplay} {sprod.CoinId}</span>
      </MouseOverTooltip>
    );
  }

  renderPopover = text => (
    <div className="batch-details-popover">
      {text}
    </div>
  )

  renderSettlementNotice() {
    const { batch, batchTableType } = this.props;
    const localizedDate = getLocalizedDate(new Date(batch.maturesOn), 'MMM DD, YYYY');

    if (batchTableType === 'ongoing' && batch.type === 'FOUR_WEEKLY_CELERY') {
      return (
        <div className="batch-settlement-notice">
          <span><Trans>Trade positions will be revealed on settlement date ({{ localizedDate }}).</Trans></span>
        </div>
      );
    }
  }

  renderTradeActivities() {
    const { batch, sprod } = this.props;
    const { trades, type } = batch;
    const filteredTrades = trades.filter(trade => trade.type !== 'BONUS_ROUND' && trade.type !== 'LENDING');

    if (!sprod || !filteredTrades) return;

    if (filteredTrades.length === 0 && type === 'FOUR_WEEKLY_CELERY') {
      return (
        <div className="batch-trades">
          <b><Trans>Trades</Trans></b>
          <span><Trans>No trades recorded yet.</Trans></span>
        </div>
      );
    }

    if (filteredTrades.length === 0 && type === 'FOUR_WEEKLY_BROCCOLI') {
      return null;
    }

    if (batch.status !== 'SETTLED') {
      return this.renderOngoingTradeActivities(filteredTrades);
    }

    if (type === 'FOUR_WEEKLY_CELERY') {
      return this.renderPastTradeActivities();
    }

    return null;
  }

  renderOngoingTradeActivities = (trades) => {
    const { batch, copyHashProof } = this.props;
    const tradesList = [];

    for (let i = 0; i < trades.length; i += 1) {
      const trade = batch.trades[i];
      tradesList.push(
        <div className="batch-activity" key={`batch-activity-${i}`}>
          <div className="trade-hash-proof max-width-100-pc">
            <span className="batch-activity-text"><Trans>Proof</Trans></span> <span className="trade-hash-display batch-activity-text">{trade.hash}</span>
          </div>
          <div className="trade-hash-copy">
            <CopyButton text={trade.hash} onCopy={() => copyHashProof()} />
          </div>
        </div>,
      );
    }

    return (
      <div className="batch-trades">
        <b><Trans>Trades</Trans></b>
        {tradesList}
      </div>
    );
  }

  renderPastTradeActivities = () => (
    <div className="batch-trades">
      <b><Trans>Trades</Trans></b>
      <div className="trade-history-table">
        {this.renderTradeHistoryHeader()}
        {this.renderTradeHistoryActivities()}
      </div>
    </div>
  )

  renderTradeHistoryHeader = () => {
    const { batch } = this.props;
    return (
      <div className="trade-history-row trade-history-header bold">
        <div><Trans>Earn</Trans> ({batch.sprod.CoinId})</div>
        <div><Trans>Date</Trans></div>
        <div><Trans>Pair</Trans></div>
        <div><Trans>Action</Trans></div>
        <div><Trans>Type</Trans></div>
        <div><Trans>Amount</Trans> ({batch.sprod.CoinId})</div>
        <div><Trans>Strike</Trans></div>
        <div><Trans>Premium</Trans></div>
        <div><Trans>Expiry</Trans></div>
        <div><Trans>Settlement Price</Trans></div>
        <div><Trans>Payoff</Trans></div>
        <div><Trans>Proof</Trans></div>
      </div>
    );
  }

  renderTradeHistoryActivities = () => {
    const { batch, openProofModal } = this.props;
    const trades = batch.trades.filter(trade => trade.type === 'TRADE');
    return trades.map((trade, i) => {
      const { earn, settlement, activity } = trade;
      const earnDisplay = earn ? localizeBigNumber(earn, false, 8) : '-';
      const earnTooltip = earn ? localizeBigNumber(earn, true) : '-';
      const {
        date,
        pair,
        action,
        type,
        amount,
        strike,
        premium,
        expiry,
      } = activity;
      const settlementPrice = settlement ? settlement.settlementPrice : null;
      const payoffAmount = settlement ? settlement.payoffAmount : null;
      return (
        <div className="trade-history-row" key={i}>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Earn</Trans>:</span>
            <MouseOverTooltip
              renderTooltip={() => this.renderPopover(earnTooltip)}
            >
              {earnDisplay}
            </MouseOverTooltip>
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Date</Trans>:</span>
            {date || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Pair</Trans>:</span>
            {pair || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Action</Trans>:</span>
            {action || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Type</Trans>:</span>
            {type || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Amount</Trans>:</span>
            {amount || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Strike</Trans>:</span>
            {strike || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Premium</Trans>:</span>
            {premium || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Expiry</Trans>:</span>
            {expiry || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Settlement Price</Trans>:</span>
            {settlementPrice || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Payoff</Trans>:</span>
            {payoffAmount || '-'}
          </div>
          <div className="space-between-mobile">
            <span className="hide-lg black"><Trans>Proof</Trans>:</span>
            <ReactSvg className="activity-info" src={proofIcon} onClick={() => openProofModal(batch, trade)} /></div>
        </div>
      );
    });
  }

  renderHedges = () => {
    const { batch } = this.props;
    const hedges = batch.trades.filter(trade => trade.type === 'HEDGE');
    if (!hedges || !hedges.length) return;

    if (batch.status === 'SETTLED') {
      return (
        <React.Fragment>
          <div className="hedge-hashes">
            <b><Trans>Hedges</Trans></b>
          </div>
          <ul className="settled-hedge-activities">
            {this.renderSettledHedgeActivities(batch)}
          </ul>
        </React.Fragment>
      );
    }
  }

  renderSettledHedgeActivities = (batch) => {
    const { openProofModal } = this.props;
    const settledHedgeActivities = [];
    const hedges = batch.trades.filter(trade => trade.type === 'HEDGE');
    if (!hedges || !hedges.length) return;

    for (let i = 0; i < hedges.length; i += 1) {
      const hedge = hedges[i];
      settledHedgeActivities.push(
        <li key={`settled-activity-${i}`}>
          <ReactSvg className="activity-info" src={proofIcon} onClick={() => openProofModal(batch, hedge)} />
          <span>{`${hedge.earn} ${batch.sprod.CoinId}`}</span>
        </li>,
      );
    }
    return settledHedgeActivities;
  }

  renderDetails() {
    const { isOpen, batch: { sprod } } = this.props;
    const promoType = sprod?.slug === PROMO_TYPE_SLUG;

    if (!isOpen) return null;

    return (
      <div className={`batch-details-row ${promoType ? 'xmas-background' : ''}`}>
        <div className="entry-details">
          {this.renderCoinEntry()}
          {this.renderCompoundSettings()}
        </div>
        {this.renderBonusSection()}
        {this.renderTradeActivities()}
        {this.renderHedges()}
        {this.renderSettlementNotice()}
      </div>
    );
  }

  renderBonusSection = () => {
    const { batch } = this.props;

    if (batch.type !== 'FOUR_WEEKLY_BROCCOLI') {
      return;
    }

    const { trades } = batch;

    const bonusRoundTrades = trades.filter(trade => trade.type === 'BONUS_ROUND');

    if (!bonusRoundTrades || bonusRoundTrades.length === 0) {
      return null;
    }

    const { coin, currentCoinPriceUsd } = this.props;
    const { dfiRewardPerAnnum, targetReturnPerAnnum, status } = batch;
    const { name: coinName, id: coinId } = coin;

    const baseReturnInPercent = new BigNumber(targetReturnPerAnnum).multipliedBy(100).toFixed();
    const dfiRewardInPercent = new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFixed();
    const minTotalReturn = dfiRewardPerAnnum
      ? new BigNumber(baseReturnInPercent).plus(dfiRewardInPercent).toFixed()
      : baseReturnInPercent;
    const currentPrice = localizeBigNumber(currentCoinPriceUsd, false, 2);
    const isSettleBatch = status === 'SETTLED';

    return (
      <div className="batch-bonus-section">
        <div className="batch-bonus-section-text">
          <p><Trans>Batch provides a guaranteed {{ minTotalReturn }}% p.a. return. Additional bonus will be paid based on {{ coinName }} price at bonus round ending date.</Trans></p>
        </div>
        {!isSettleBatch && (
          <div className="batch-bonus-current-price">
            <div className="bold">
              <Trans>Current {{ coinId }} price</Trans>
            </div>
            <div>${currentPrice}</div>
          </div>
        )}
        {bonusRoundTrades.map(trade => this.renderBonusRound(trade))}
      </div>
    );
  }

  renderBonusRound = (trade) => {
    const { batch } = this.props;
    const { dfiRewardPerAnnum, targetReturnPerAnnum, sprod } = batch;
    const { bonusRound, endTime, startTime, levels } = trade.activity;
    const { settlement } = trade;

    const coinId = sprod.CoinId;
    const localizedStartDate = getLocalizedDate(startTime);
    const localizedEndDate = getLocalizedDate(endTime);

    const baseReturnInPercent = new BigNumber(targetReturnPerAnnum).multipliedBy(100).toFixed();
    const dfiRewardInPercent = new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFixed();
    const minTotalReturn = dfiRewardPerAnnum
      ? new BigNumber(baseReturnInPercent).plus(dfiRewardInPercent).toFixed()
      : baseReturnInPercent;

    const highestReachedLevel = this.getHighestReachedBonusLevel(trade);
    const lowestLevel = levels.reduce((prev, current) => (new BigNumber(prev.target).isLessThan(current.target) ? prev : current));
    const minBonusPriceTarget = localizeBigNumber(lowestLevel.target, false, 2);

    const dfiBonusText = dfiRewardPerAnnum
      ? ` + ${dfiRewardInPercent}% DFI p.a`
      : '';

    return (
      <div className="bonus-round" key={trade.id}>
        <div className="batch-bonus"><Trans>Bonus {{ bonusRound }} ({{ localizedStartDate }} to {{ localizedEndDate }})</Trans></div>
        <Row>
          <Col lg="6" className="batch-bonus-col">
            <div className={`batch-bonus-card ${settlement && !highestReachedLevel ? 'level-reached' : ''}`}>
              <div><Trans>Less than ${{ minBonusPriceTarget }}/{{ coinId }} on {{ localizedEndDate }}</Trans></div>
              <div><Trans>{{ minTotalReturn }}% guaranteed return</Trans></div>
              <div>{baseReturnInPercent}% {coinId}{dfiBonusText}</div>
            </div>
          </Col>
          {levels.map((level) => {
            const { target, bonus } = level;
            const maxNativeCoinReturn = new BigNumber(baseReturnInPercent).plus(bonus).toFixed();
            const maxTotalReturn = new BigNumber(minTotalReturn).plus(bonus).toFixed();
            const bonusPriceTarget = localizeBigNumber(target, false, 2);

            let levelIsReached = false;
            if (highestReachedLevel) {
              levelIsReached = level.target === highestReachedLevel.target;
            }

            return (
              <Col lg="6" className="batch-bonus-col" key={target}>
                <div className={`batch-bonus-card ${levelIsReached ? 'level-reached' : ''}`}>
                  <div><Trans>${{ bonusPriceTarget }}/{{ coinId }} or more on {{ localizedEndDate }}</Trans></div>
                  <div>{maxTotalReturn}% p.a.</div>
                  <div>{maxNativeCoinReturn}% {coinId}{dfiBonusText}</div>
                </div>
              </Col>
            );
          })}
        </Row>
        {this.renderBonusSettlement(trade)}
      </div>
    );
  }

  getHighestReachedBonusLevel = (trade) => {
    const { levels } = trade.activity;
    const { settlement } = trade;

    if (settlement) {
      const reachedLevels = levels.filter(level => new BigNumber(level.target).isLessThanOrEqualTo(settlement.settlementPrice));
      if (reachedLevels.length > 0) {
        return reachedLevels.reduce((prev, current) => (new BigNumber(prev.target).isGreaterThan(current.target) ? prev : current));
      }
    }
    return null;
  }

  renderBonusSettlement = (trade) => {
    const { batch } = this.props;
    const { dfiRewardPerAnnum, targetReturnPerAnnum, sprod } = batch;
    const { settlement } = trade;

    if (!settlement) {
      return null;
    }

    const coinId = sprod.CoinId;

    const baseReturnInPercent = new BigNumber(targetReturnPerAnnum).multipliedBy(100).toFixed();
    const dfiRewardInPercent = new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFixed();
    const minTotalReturn = dfiRewardPerAnnum
      ? new BigNumber(baseReturnInPercent).plus(dfiRewardInPercent).toFixed()
      : baseReturnInPercent;

    const highestReachedLevel = this.getHighestReachedBonusLevel(trade);

    return (
      <div className="bonus-settlement">
        <div>
          <div className="bold"><Trans>End price</Trans></div>
          <div className="text-secondary">${localizeBigNumber(settlement.settlementPrice)}/{coinId}</div>
        </div>
        <div>
          <div className="bold"><Trans>Guaranteed return</Trans></div>
          <div className="text-secondary">{minTotalReturn}%</div>
        </div>
        <div>
          <div className="bold"><Trans>Bonus</Trans></div>
          <div className="text-secondary">
            {highestReachedLevel ? `+${highestReachedLevel.bonus}%` : '0%'}
          </div>
        </div>
        <div>
          <div className="bold"><Trans>APY</Trans></div>
          <div className="text-secondary">
            {highestReachedLevel ? new BigNumber(minTotalReturn).plus(highestReachedLevel.bonus).toFixed() : minTotalReturn}%
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDetails()}
      </React.Fragment>
    );
  }
}
