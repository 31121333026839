import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { DialogTitle } from '@material-ui/core';
import Button from 'common/components/Button/Button';
import { ModalContext } from 'modals/ModalContext';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import { WebappTheme } from 'types/theme';

const useStyles = createUseStyles<string, { appTheme: WebappTheme }>({
  dialogTitleRoot: {
    padding: `${Variables.small} ${Variables.large} !important`,
    borderRadius: Variables.extraSmall,
    background: ({ appTheme }) => (appTheme === 'DEFICHAIN' ? Variables.pink100 : '#fff'),
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalTitle: {
    fontSize: '2rem',
    lineHeight: '3.2rem',
    fontWeight: 600,
    marginBottom: 0,
    paddingRight: Variables.small,
  },
  modalTitleWithCoin: {
    composes: '$modalTitle',
    margin: '0',
    lineHeight: Variables.large,
    display: 'inline-flex',
    alignItems: 'center',
  },
});

interface ModalHeaderProps {
  title?: string | React.ReactNode;
  className?: string;
  showCancelButton?: boolean;
  cancelLabel?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  withCoin?: boolean;
  appTheme?: WebappTheme;
}

const TitleComponent = ({ title }) => {
  if (!title) {
    return null;
  }

  if (typeof title === 'string') {
    return <Trans i18nKey={title}>{title}</Trans>;
  }

  return title;
};

export const ModalHeader = ({
  title,
  className = '',
  showCancelButton = true,
  cancelLabel = 'Cancel',
  children,
  onClose,
  withCoin = false,
  appTheme = 'CAKE',
}: ModalHeaderProps) => {
  const classes = useStyles({
    appTheme,
  });
  const {
    closeModal,
    modalContent: { cancelLabel: contextCancelLabel },
  } = useContext(ModalContext);

  const onClickClose = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  if (children) {
    return (
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        {children}
      </DialogTitle>
    );
  }

  const computedCancelLabel = contextCancelLabel ?? cancelLabel;

  return (
    <DialogTitle
      classes={{
        root: classes.dialogTitleRoot,
      }}
    >
      <div className={`${classes.modalHeader} ${className}`}>
        <h2
          className={withCoin ? classes.modalTitleWithCoin : classes.modalTitle}
        >
          <TitleComponent title={title} />
        </h2>
        {showCancelButton && <Button btnLink onClick={onClickClose} appTheme={appTheme}>
          <Trans i18nKey={computedCancelLabel}>{computedCancelLabel}</Trans>
        </Button>}
      </div>
    </DialogTitle>
  );
};
