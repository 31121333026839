import React from 'react';
import { ReferralPromoTemplate } from 'referral/referralType';
import TigerLunarPromoHeroImage from './TigerLunarPromoHeroImage';
import { TigerLunarPromoHeroRight } from './TigerLunarPromoHeroRight';
import TigerLunarRulesModalContent from './TigerLunarRulesModalContent';

export const TigerLunarPromoTemplate: ReferralPromoTemplate = {
  heroImageComponent: <TigerLunarPromoHeroImage />,
  heroRightComponent: <TigerLunarPromoHeroRight />,
  rulesModalContent: <TigerLunarRulesModalContent />,
  referrerBonusPerReferralUsd: 8,
};
