import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { currentAddLiquidityPricesSelector } from 'liquidity-mining/liquidityMiningSelector';
import { getCoinAmount, getCoinPriceInUSD, getSharesValueInPreferredCurrency } from 'liquidity-mining/liquidityMiningUtils';
import { userPreferredCurrencySelector } from 'user/userSelector';
import { getCoinIdsFromPairId } from 'utils/getCoinIdsFromPairId';

export const getLMPairValueInPreferredCurrencySelector = createSelector(
  (state: RootState) => state.wallet.coins,
  (state: RootState) => state.currency.fiat.rates,
  userPreferredCurrencySelector,
  (state: RootState) => currentAddLiquidityPricesSelector(state),
  (coins, rates, preferredCurrency, currentAddLiquidityPrices) => {
    if (!coins || !rates || !preferredCurrency || !currentAddLiquidityPrices) {
      return (pairAmount) => pairAmount;
    }
    const btcPrice = getCoinPriceInUSD(coins, 'BTC');
    return (pairAmount, pairId: string, isUsePreferredCurrency = true) => {
      const { primaryCoinAmount, secondaryCoinAmount } = getCoinAmount(
        currentAddLiquidityPrices[pairId],
        pairAmount,
      );
      const [primaryCoinId, secondaryCoinId] = getCoinIdsFromPairId(pairId);
      const preferredCurrencyToUse = isUsePreferredCurrency ? preferredCurrency : 'USD';
      return getSharesValueInPreferredCurrency(
        coins,
        rates,
        preferredCurrencyToUse,
        btcPrice,
        {
          id: primaryCoinId,
          amount: primaryCoinAmount,
        },
        {
          id: secondaryCoinId,
          amount: secondaryCoinAmount,
        },
      );
    };
  },
);
