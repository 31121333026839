import React, { FC, HTMLAttributes, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import SummaryTableStyles from './SummaryTable.styles';

const useStyles = createUseStyles(SummaryTableStyles);

type SummaryEntryProp = {
  label: string | ReactElement,
}

export const SummaryTable: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }): ReactElement => {
  const classes = useStyles();
  const wrapperClass = className ? `${className} ${classes.tableWrapper}` : classes.tableWrapper;

  return (
    <div className={wrapperClass}>
      {children}
    </div>
  );
};

export const SummaryEntry: FC<SummaryEntryProp> = ({ children, label }): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.rowLabel}>{typeof label === 'string' ? <Trans>{label}</Trans> : label}</div>
      <div className={classes.rowValue}>{children}</div>
    </div>
  );
};
