export interface Platform {
  id: string;
  name: string;
}

export interface INonCustodialPlatform {
  id: string;
  name: string;
}

export enum WalletAddressPlatformTypeEnum {
  CUSTODIAL = 'CUSTODIAL',
  NON_CUSTODIAL = 'NON_CUSTODIAL',
}
