import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import sdk from 'sdk';
import { FireCampaignDataState } from './fireCampaignTypes';

const initialState: FireCampaignDataState = {
  state: 'UNLOADED',
  isCampaignStillActive: false,
  redemptionDetails: {},
  currentBonusPaid: 0,
  hasUserParticipatedInCampaign: false,
  isUserEligibleForCampaign: true,
  bonusTiers: [],
  error: null,
};

export const fetchFireCampaign = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/fire',
  async (_, thunkAPI) => {
    const result = await sdk.CampaignApi.getDetails();
    return result;
  },
);

export const fetchFireCampaignAuthenticated = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/fire/authenticated',
  async (_, thunkAPI) => {
    const result = await sdk.CampaignApi.getDetailsForUser();
    return result;
  },
);

const fireCampaignSlice = createSlice({
  name: 'FIRECampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFireCampaign.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
      state.currentBonusPaid = 0;
      state.hasUserParticipatedInCampaign = false;
      state.isUserEligibleForCampaign = true;
    });
    builder.addCase(fetchFireCampaignAuthenticated.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
      state.currentBonusPaid = 0;
      state.hasUserParticipatedInCampaign = false;
      state.isUserEligibleForCampaign = false;
    });
    builder.addCase(fetchFireCampaign.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.isCampaignStillActive = false;
      state.redemptionDetails = action.payload?.redemptionDetails;
      state.bonusTiers = action.payload?.bonusTiers;
      state.isUserEligibleForCampaign = true;
      state.error = null;
    });
    builder.addCase(fetchFireCampaignAuthenticated.fulfilled, (state, action) => {
      state.isCampaignStillActive = false;
      state.redemptionDetails = action.payload?.redemptionDetails;
      state.currentBonusPaid = action.payload?.currentBonusPaid;
      state.hasUserParticipatedInCampaign = action.payload?.hasUserParticipatedInCampaign;
      state.isUserEligibleForCampaign = action.payload?.isUserEligibleForCampaign;
      state.bonusTiers = action.payload?.bonusTiers;
      state.state = 'LOADED';
      state.error = null;
    });
    builder.addCase(fetchFireCampaign.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchFireCampaignAuthenticated.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export default fireCampaignSlice.reducer;
