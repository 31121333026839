import moment from 'moment';

/**
 * @description more precisely: fn(day) = (current + day) as date string
 */
export default function displayDurationDays(days: moment.DurationInputArg1, momentFormat = 'dddd, MMM D'): string {
  const dur = moment.duration(days, 'days');
  const ms = moment().valueOf() + dur.asMilliseconds();
  return moment(ms).format(momentFormat);
}
