import appConfig from 'config/app';
import React from 'react';
import { Trans } from 'react-i18next';
import { userPreferredCurrencySelector } from 'user/userSelector';
import isZero from 'utils/isZero';
import { useCakeSelector } from '../../app/rootReducer';
import BigNumberDisplay from '../../common/components/BigNumberDisplay/BigNumberDisplay';
import InlineLoadingIndicator from '../../common/components/InlineLoadingIndicator/InlineLoadingIndicator';
import { tooltipBalanceInPreferredCurrencySelector } from '../tootipBalanceInPreferredCurrencySelector';

const UserBalanceTooltips = () => {
  const balanceSummary = useCakeSelector(tooltipBalanceInPreferredCurrencySelector());
  const preferredCurrency = useCakeSelector(userPreferredCurrencySelector);
  if (!balanceSummary) {
    return (
      <>
        <div style={{ margin: '0 auto' }}><InlineLoadingIndicator /></div>
        <div style={{ margin: '0 auto' }}><InlineLoadingIndicator /></div>
        <div style={{ margin: '0 auto' }}><InlineLoadingIndicator /></div>
      </>
    );
  }

  const {
    stakingBalance,
    lmBalance,
    bundleBalance,
  } = balanceSummary;

  const decimals = preferredCurrency === 'BTC' ? appConfig.DEFAULT_CRYPTO_DECIMALS : 2;
  return (
    <React.Fragment>
      {
        !isZero(stakingBalance) && (
          <div className="wallet-tooltips-content">
            <dt className="wallet-tooltips-content__label"><Trans>Staking Shares</Trans>:</dt>
            <dd className="wallet-tooltips-content__value text-secondary">
              <BigNumberDisplay currency={preferredCurrency} decimalPoints={decimals} isSensitiveNumber noTooltip className="wallet-tooltips-content__value text-secondary">{stakingBalance}</BigNumberDisplay>
            </dd>
          </div>
        )
      }
      {
        !isZero(lmBalance) && (
          <div className="wallet-tooltips-content">
            <dt className="wallet-tooltips-content__label"><Trans>Liquidity Mining</Trans>:</dt>
            <dd className="wallet-tooltips-content__value text-secondary">
              <BigNumberDisplay currency={preferredCurrency} decimalPoints={decimals} isSensitiveNumber noTooltip className="wallet-tooltips-content__value text-secondary">{lmBalance}</BigNumberDisplay>
            </dd>
          </div>
        )
      }
      {
        !isZero(bundleBalance) && (
          <div className="wallet-tooltips-content">
            <dt className="wallet-tooltips-content__label"><Trans>Bundle</Trans>:</dt>
            <dd className="wallet-tooltips-content__value text-secondary">
              <BigNumberDisplay currency={preferredCurrency} decimalPoints={decimals} isSensitiveNumber noTooltip className="wallet-tooltips-content__value text-secondary">{bundleBalance}</BigNumberDisplay>
            </dd>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default UserBalanceTooltips;
