import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { createUseStyles } from 'react-jss';
import QrCode from 'assets/svg/welcome/qr-code.svg';
import PlayStore from 'assets/svg/welcome/2x-google-play.svg';
import AppleStore from 'assets/svg/welcome/2x-app-store.svg';
import PlayStoreMobile from 'assets/svg/welcome/google-play.svg';
import AppleStoreMobile from 'assets/svg/welcome/app-store.svg';

const useStyles = createUseStyles({
  appDownload: {
    display: 'flex',
    alignItems: 'center',
    [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
      display: 'none',
    },
  },
  appDownloadMobile: {
    display: 'none',
    [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
      marginTop: Variables.large,
      marginBottom: Variables.extraLargeRound,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  qrCode: {
    height: '128px',
    width: '128px',
    marginRight: Variables.extraLarge,
    backgroundColor: Variables.white,
    borderRadius: Variables.small,
    padding: Variables.extraSmall,
  },
  playStore: {
    marginRight: Variables.large,
    [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
      width: 'auto !important',
    },
  },
  getTheAppLabel: {
    fontSize: Variables.medium,
    fontWeight: '600',
    letterSpacing: '0.12px',
    lineHeight: Variables.large,
    textTransform: 'uppercase',
  },
});

const GetTheMobileApp = () => {
  const classes = useStyles();
  return (
    <div id="get-mobile-apps">
      <div className={classes.appDownload}>
        <img className={classes.qrCode} src={QrCode} alt="QR code" />
        <div>
          <p className={classes.getTheAppLabel}><Trans>Get the app</Trans></p>
          <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.cakedefi.app' }} target="_blank" rel="noopener noreferrer">
            <img className={classes.playStore} src={PlayStore} alt="Play store icon" />
          </Link>
          <Link to={{ pathname: 'https://apps.apple.com/app/id1564415526' }} target="_blank" rel="noopener noreferrer">
            <img src={AppleStore} alt="Apple store icon" />
          </Link>
        </div>
      </div>
      <div className={classes.appDownloadMobile}>
        <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.cakedefi.app' }} target="_blank" rel="noopener noreferrer">
          <img className={classes.playStore} src={PlayStoreMobile} alt="Play store icon" width="154" height="44" />
        </Link>
        <Link to={{ pathname: 'https://apps.apple.com/app/id1564415526' }} target="_blank" rel="noopener noreferrer">
          <img src={AppleStoreMobile} alt="Apple store icon" width="154" height="44" />
        </Link>
      </div>
    </div>
  );
};

export default GetTheMobileApp;
