import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import { getPromotionActiveDates } from 'referral/referralPromoSelector';
import { getCampaignDateWithTranslation } from '../referralUtils';

const useStyles = createUseStyles({
  header: {
    fontSize: '3.4rem',
    letterSpacing: '0.25px',
    lineHeight: '4rem',
  },
});

export const ElevenElevenPromoHeroRight = () => {
  const classes = useStyles();
  const { selectedLanguage } = useCakeSelector(state => state.languageRegionSettings);
  const { promoBonusActiveFrom, promoBonusActiveUntil } = useCakeSelector(getPromotionActiveDates);
  const CAMPAIGN_START_DATE = getCampaignDateWithTranslation(selectedLanguage, promoBonusActiveFrom);
  const CAMPAIGN_END_DATE = getCampaignDateWithTranslation(selectedLanguage, promoBonusActiveUntil);

  return (
    <>
      <h1 className={classes.header}>
        <Trans>
          You never earn alone! This Double-11 Day, get 11% deposit bonus + $11 in DFI for every friend you refer.
        </Trans>
      </h1>
      <p>
        <Trans>From {{ CAMPAIGN_START_DATE }} to {{ CAMPAIGN_END_DATE }}, for every successful referral (identity verified with a minimum $50 deposit) that uses your unique referral code at sign up, you will earn an additional $11 worth of DFI - and that’s on top of the usual $10, giving you a grand total of $21 worth of DFI for every successful referral!</Trans>
      </p>
    </>
  );
};
