import { FeatureInfo } from '../../schema/custom';
import { WithdrawalList, WithdrawalRequest } from '../../schema/withdrawal';
import { CakeApiBase } from '../CakeApiBase';

export class WithdrawalApi extends CakeApiBase {
  async withdrawalsInfo(query = {}) {
    return this.requestManager.get<FeatureInfo>('wallets/withdrawal/info', query);
  }

  async listWithdrawals(query = {}) {
    return this.requestManager.get<WithdrawalList[]>('wallets/withdrawal', query);
  }

  async withdraw(body) {
    return this.requestManager.post<WithdrawalRequest[]>('wallets/withdraw/request', body);
  }

  async confirmByEmailCode(id: string, code: string) {
    return this.requestManager.put<WithdrawalRequest>(`wallets/withdrawal/confirm/${id}/${code}`);
  }

  async cancelByEmailCode(id: string, code: string) {
    return this.requestManager.put<WithdrawalRequest[]>(`wallets/withdrawal/cancel/${id}/${code}`);
  }

  async manualCancel(transactionId: string) {
    return this.requestManager.put<WithdrawalRequest[]>(`wallets/withdrawal/user-cancel/${transactionId}`);
  }

  async resendWithdrawalConfirmation(id: string) {
    return this.requestManager.post<WithdrawalRequest>('wallets/withdrawal/resend-confirmation', {
      id,
    });
  }
}
