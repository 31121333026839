import BigNumber from 'bignumber.js';
import { Coin, CurrentAddLiquidityPrice, TotalLiquidityShare, WalletUserPortfolioAllocatedAssets, WalletUserPortfolioBundleAsset, WalletUserPortfolioResponse } from '@cakedefi/cake-sdk/schema';
import { currencyFiatRatesSelector } from 'currency/currencySelector';
import { createSelector } from '@reduxjs/toolkit';
import { userAccessRightsSelector, userPreferredCurrencySelector } from 'user/userSelector';
import { getWalletState } from 'wallet/walletSelectors';
import { WalletByCoin } from 'types/coin/wallet-by-coin';
import { currentAddLiquidityPricesSelector, totalLiquiditySharesForUserSelector } from 'liquidity-mining/liquidityMiningSelector';
import { getCoinAmount } from 'liquidity-mining/liquidityMiningUtils';
import { getCoinIdsFromPairId } from 'utils/getCoinIdsFromPairId';
import { CoinMap } from 'types/coin/coin-map';
import { extendWallet } from './extendWallet';

const computeSumForStakingAndYV = (allocatedAssets: WalletUserPortfolioAllocatedAssets, coinId: string) => {
  const stakingAssets = allocatedAssets?.staking ?? [];
  const yieldVaultAssets = allocatedAssets?.yieldVault ?? [];
  const totalSumForStakingAndYV = [...stakingAssets, ...yieldVaultAssets]
    .filter((asset) => asset.coinId === coinId)
    .reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalAmount)), new BigNumber(0));

  return totalSumForStakingAndYV;
};

const computeSumForLM = (totalLiquiditySharesForUser: TotalLiquidityShare[], currentAddLiquidityPrices: Record<string, CurrentAddLiquidityPrice>, coinId: string) => {
  const totalSumForLM = totalLiquiditySharesForUser.map(userShare => {
    const { liquidityMiningPairId: id, totalShares } = userShare;

    const [PrimaryCoinId, SecondaryCoinId] = getCoinIdsFromPairId(id);

    const { primaryCoinAmount, secondaryCoinAmount } = getCoinAmount(
      currentAddLiquidityPrices[id],
      totalShares,
    );

    return [
      {
        pairId: id,
        coinId: PrimaryCoinId,
        totalAmount: primaryCoinAmount,
      },
      {
        pairId: id,
        coinId: SecondaryCoinId,
        totalAmount: secondaryCoinAmount,
      },
    ];
  })
    .flat()
    .filter((asset) => asset.coinId === coinId)
    .reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalAmount)), new BigNumber(0));

  return totalSumForLM;
};

const computeSumForBundles = (bundleAssets, coinId: string) => {
  const totalSumForBundles = bundleAssets
    .filter((asset) => asset.coinId === coinId)
    .reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalAmount)), new BigNumber(0));

  return totalSumForBundles;
};

export const calculateTotalAndAllocatedAmount = (
  extendedWallet: WalletByCoin,
  walletUserPortfolio: WalletUserPortfolioResponse,
  coins: CoinMap,
  bundleAssets: WalletUserPortfolioBundleAsset[],
  totalLiquiditySharesForUser: TotalLiquidityShare[],
  currentAddLiquidityPrices: Record<string, CurrentAddLiquidityPrice>,
) => {
  let allocatedAmount = new BigNumber(0);
  let allocatedAssetsSumForStakingAndYV = new BigNumber(0);
  let bundleAssetsSumTotalAmount = new BigNumber(0);
  let liquidityMiningSumTotalAmount = new BigNumber(0);
  let totalPortfolio = new BigNumber(0);
  let totalAvailableAmount = new BigNumber(0);

  const coin = coins[extendedWallet.id] || {} as Coin;

  if (walletUserPortfolio?.portfolioAssets && walletUserPortfolio.portfolioAssets[coin.id]) {
    totalPortfolio = new BigNumber(walletUserPortfolio.portfolioAssets[coin.id].totalAmount);
  }

  if (walletUserPortfolio?.availableAssets && walletUserPortfolio.availableAssets[coin.id]) {
    totalAvailableAmount = new BigNumber(walletUserPortfolio.availableAssets[coin.id].totalAmount);
  }

  if (walletUserPortfolio?.allocatedAssets) {
    allocatedAssetsSumForStakingAndYV = computeSumForStakingAndYV(walletUserPortfolio.allocatedAssets, coin.id);
  }

  if (totalLiquiditySharesForUser && currentAddLiquidityPrices) {
    liquidityMiningSumTotalAmount = computeSumForLM(totalLiquiditySharesForUser, currentAddLiquidityPrices, coin.id);
  }

  if (bundleAssets?.length) {
    bundleAssetsSumTotalAmount = computeSumForBundles(bundleAssets, coin.id);
  }

  allocatedAmount = BigNumber.sum(
    allocatedAssetsSumForStakingAndYV,
    bundleAssetsSumTotalAmount,
    liquidityMiningSumTotalAmount,
  );

  return {
    ...extendedWallet,
    available: totalAvailableAmount,
    totalPortfolio,
    allocatedAmount,
  };
};

export const portfolioSelector = createSelector(
  getWalletState,
  userPreferredCurrencySelector,
  currencyFiatRatesSelector,
  totalLiquiditySharesForUserSelector,
  currentAddLiquidityPricesSelector,
  userAccessRightsSelector,
  (walletState, userPreferredCurrency, rates, totalLiquiditySharesForUser, currentAddLiquidityPrices, userAccessRights): WalletByCoin[] => {
    const { walletByCoins, coins, lapisAPYs, walletUserPortfolio, bundleAssets } = walletState;

    if (!walletByCoins) {
      return null;
    }

    return walletByCoins
      .map((wallet) => extendWallet(wallet as WalletByCoin, coins, userPreferredCurrency, rates, lapisAPYs, userAccessRights, walletUserPortfolio))
      .map((extendedWallet) => calculateTotalAndAllocatedAmount(extendedWallet, walletUserPortfolio, coins, bundleAssets, totalLiquiditySharesForUser, currentAddLiquidityPrices));
  },
);
