import { CakeApiBase } from '../CakeApiBase';
import { DeactivateAccountParams, DeactivationStatus } from '../../schema/deactivation';

export class DeactivateApi extends CakeApiBase {
  async getStatus() {
    return this.requestManager.get<DeactivationStatus>('/deactivate/status');
  }

  async init(body: DeactivateAccountParams) {
    return this.requestManager.post('/deactivate', body);
  }
}
