import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { createUseStyles } from 'react-jss';
import Rated from 'assets/svg/welcome/rated.svg';
import TopBanner from 'common/components/WelcomeBanners/TopBanner/TopBanner';
import GetTheMobileApp from '../GetTheMobileApp';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

const useStyles = createUseStyles({
  topBanner: {
    [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
      display: 'none',
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    marginBottom: Variables.large,
  },
  rated: {
    marginTop: Variables.large,
    [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
      width: 'auto !important',
    },
  },
  container: {
    marginBottom: Variables.large,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const WelcomePagePromotionSection = () => {
  const classes = useStyles();
  return (
    <article className="floating">
      <div className={classes.topBanner}>
        <TopBanner />
      </div>
      <div>
        <Box mt={4}>
          <GetTheMobileApp />
        </Box>
        <Link to={{ pathname: 'https://www.trustpilot.com/review/cakedefi.com' }} target="_blank" rel="noopener noreferrer">
          <img className={classes.rated} src={Rated} alt="Rated excellent on Trustpilot" />
        </Link>
      </div>
    </article>
  );
};

export default WelcomePagePromotionSection;
