import BigNumber from 'bignumber.js';
import appConfig from '../config/app';
import localizeBigNumber from './localizeBigNumber';

const friendlyDecimals = appConfig.FRIENDLY_DECIMALS;
BigNumber.config({ EXPONENTIAL_AT: 21 });

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

// this fn will return numbers upto 2 decimal points
export default function getShortVersionOfNumber(value, decimals = friendlyDecimals) {
  const bnValue = new BigNumber(value);
  const roundingMode = BigNumber.ROUND_DOWN;

  if (bnValue.gt(1000 * 1000 * 1000)) {
    const result = bnValue.dividedBy(1000 * 1000 * 1000).decimalPlaces(decimals, roundingMode).toFormat(decimals, fmt);
    return `${result}B`;
  }

  if (bnValue.gt(1000 * 1000)) {
    const result = bnValue.dividedBy(1000 * 1000).decimalPlaces(decimals, roundingMode).toFormat(decimals, fmt);
    return `${result}M`;
  }
  return localizeBigNumber(bnValue.decimalPlaces(decimals, roundingMode), false, decimals, undefined, true);
}
