import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from 'sdk';

interface SprodState {
  error: string
  loading: boolean
  minimumAmountInUsd: number
}

export const initialState: SprodState = {
  error: '',
  loading: false,
  minimumAmountInUsd: 0,
};

export const fetchSprodMinAmountInUsd = createAsyncThunk(
  'sprods/sprod-batch/minimum',
  () => sdk.SprodApi.getMinimumInput(),
);

const sprodSlice = createSlice({
  name: 'sprodMain',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSprodMinAmountInUsd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSprodMinAmountInUsd.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.minimumAmountInUsd = payload?.minimumAmountInUsd;
    });
    builder.addCase(fetchSprodMinAmountInUsd.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default sprodSlice.reducer;
