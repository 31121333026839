import heroImage from 'assets/svg/referral/easter-hero-left.svg';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getMinWidthMd } from 'Variables.styles';

const useStyles = createUseStyles({
  imageHeroWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '176px',
    width: '100%',
  },
  imageHero: {
    width: '90%',
  },
  [getMinWidthMd]: {
    imageHeroWrapper: {
      alignItems: 'center',
      minHeight: '194px',
    },
  },
});

export default function EasterReferralHeroImage() {
  const classes = useStyles();
  return (
    <div className={classes.imageHeroWrapper}>
      <img className={classes.imageHero} alt='easter' src={heroImage}/>
    </div>
  );
}
