import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import { Trans } from 'react-i18next';
import sdk from 'sdk';
import { reloadUser } from 'user/userSlice';
import { openSignInWindow } from '../api/RazerApi';

export interface IVoucher {
  code: string,
  currency: string,
  redeemed: boolean,
  redeemedOn: string,
  value: string,
  voucherSuccessRedeemed: boolean,
  voucherPromotion: {
    createdAt: string,
    currency: string,
    expiryDate: string,
    id: string,
    name: string,
    updatedAt: string,
    value: string,
  }
}

const initialState = {
  isLoading: false,
  razerIdExist: false,
  userHasRestrictionByCountry: undefined,
  mainMessage: 'Synchronising with Razer...',
  errorMessage: 'This campaign is not available in your country.',
  voucherSuccessRedeemed: false,
};

export const initRazerLoginForm = createAsyncThunk<any, { width: number, left: number }, ThunkApiConfig>(
  'campaigns/razer/login',
  async ({ width, left }, thunkAPI) => {
    const { clientId } = await sdk.RazerApi.loginUser();
    openSignInWindow({ name: 'Bake', width, left, clientId });
  },
);

export const fetchIsRazerIdExistInUser = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/razer/check',
  async (_, thunkAPI) => {
    const result = await sdk.RazerApi.checkUser();
    if (result.status) {
      thunkAPI.dispatch(reloadUser());
    }
    return result;
  },
);

export const fetchIsUserHasRestrictionByCountry = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/razer/user-restrict-by-country',
  async (_, thunkAPI) => {
    const result = await sdk.RazerApi.checkRestrictionByCountry();
    return result;
  },
);

export const fetchRazerId = createAsyncThunk<{ msg: string }, { code: string }, ThunkApiConfig>(
  'campaigns/razer/token',
  async ({ code }) => sdk.RazerApi.verifyInfo(code),
);

export const redeemCode = createAsyncThunk<any, { code: string, excludeRazer?: boolean }, ThunkApiConfig>(
  '/voucher/redeem',
  async ({ code, excludeRazer }, thunkAPI) => {
    const result = await sdk.VoucherInfoApi.redeem(code, excludeRazer);
    thunkAPI.dispatch(reloadUser());
    return result;
  },
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    clearCampaignsSlice: (state) => ({ ...initialState, razerIdExist: state.razerIdExist }),
    setMainMessage: (state, action) => {
      state.mainMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initRazerLoginForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIsRazerIdExistInUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIsUserHasRestrictionByCountry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRazerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(redeemCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(initRazerLoginForm.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchIsRazerIdExistInUser.fulfilled, (state, action) => {
      state.razerIdExist = action.payload?.status;
      state.isLoading = false;
    });
    builder.addCase(fetchIsUserHasRestrictionByCountry.fulfilled, (state, action) => {
      state.userHasRestrictionByCountry = action.payload?.countryHasPromotionRestriction;
      state.isLoading = false;
    });
    builder.addCase(fetchRazerId.fulfilled, (state, action) => {
      if (action.payload.msg === 'success') {
        state.razerIdExist = true;
        state.mainMessage = 'Success. This window will be closed';
      } else {
        state.mainMessage = 'This razer account already exist in the Bake system';
      }
      state.isLoading = false;
    });
    builder.addCase(redeemCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.voucherSuccessRedeemed = true;
      window.cakepool.showAlert('success', <Trans>Voucher successfully applied</Trans>);
    });
    builder.addCase(initRazerLoginForm.rejected, (state) => {
      state.isLoading = false;
      state.mainMessage = 'Something wrong. This window will be closed';
    });
    builder.addCase(fetchIsRazerIdExistInUser.rejected, (state) => {
      state.isLoading = false;
      state.mainMessage = 'Something wrong. This window will be closed';
    });
    builder.addCase(fetchIsUserHasRestrictionByCountry.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action?.error?.message;
      state.mainMessage = 'Something wrong. This window will be closed';
    });
    builder.addCase(fetchRazerId.rejected, (state) => {
      state.isLoading = false;
      state.mainMessage = 'Something wrong. This window will be closed';
    });
    builder.addCase(redeemCode.rejected, (state, action) => {
      state.isLoading = false;
      window.cakepool.showAlert('error', <Trans>{action.error?.message}</Trans>);
    });
  },
});

export const { clearCampaignsSlice, setMainMessage } = campaignsSlice.actions;

export default campaignsSlice.reducer;
