import React from 'react';
import {
  Container,
} from 'reactstrap';
import { Trans } from 'react-i18next';

import LapisDetailsContent from './LapisDetailsContent';

const SprodDetails = (props) => {
  const { name: sprodName, coinName, coinId, slug, tier2Threshold, tier2Apy, returnPerAnnum } = props;
  return (
    <React.Fragment>
      <Container className="sprod-details">
        <h1><Trans>{{ sprodName }} Service</Trans></h1>
        {LapisDetailsContent({ sprodName, coinName, coinId, slug, tier2Threshold, tier2Apy, returnPerAnnum }).map(section => (
            <div key={section.key}>
              {section.content}
            </div>
        ))}
      </Container>
    </React.Fragment>
  );
};

export default SprodDetails;
