import {
  AddLiquidity,
  AddLiquidityPrice,
  AddLiquidityRequest,
  AddLiquidityRequestSimpleMode,
  LiquidityMiningBalance,
  LiquidityMiningPage,
  LiquidityMiningPageAuthenticated,
  MinimumAmountInUsd,
  RemoveLiquidity,
  RemoveLiquidityPrice,
  RemoveLiquidityRequest,
  AddLiquidityRequestSimpleModeV2,
  RemoveLiquidityRequestV2,
} from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class liquidityMiningApi extends CakeApiBase {
  async liquidityMiningPage() {
    return this.requestManager.get<LiquidityMiningPage>('liquidity-mining');
  }

  async liquidityMiningPageAuthenticated() {
    return this.requestManager.get<LiquidityMiningPageAuthenticated>('liquidity-mining/authenticated');
  }

  async removeLiquidityPrice(pairId: string) {
    return this.requestManager.get<RemoveLiquidityPrice>(`liquidity-mining/remove-liquidity-prices/${pairId}`);
  }

  async removeLiquidity(data: RemoveLiquidityRequest) {
    return this.requestManager.post<RemoveLiquidity>('liquidity-mining/remove-liquidity', data);
  }

  async addLiquidityPrices(pairId: string) {
    return this.requestManager.get<AddLiquidityPrice>(`liquidity-mining/add-liquidity-prices/${pairId}`);
  }

  async addLiquidity(body: AddLiquidityRequest) {
    return this.requestManager.post<AddLiquidity>('liquidity-mining/add-liquidity', body);
  }

  async addLiquiditySimpleMode(body: AddLiquidityRequestSimpleMode) {
    return this.requestManager.post<void>('liquidity-mining/add-liquidity/simple-mode', body);
  }

  async addLiquiditySimpleModeV2(body: AddLiquidityRequestSimpleModeV2) {
    return this.requestManager.post<void>('liquidity-mining/v2/add-liquidity/simple-mode', body);
  }

  async removeLiquidityV2(data: RemoveLiquidityRequestV2) {
    return this.requestManager.post<RemoveLiquidity>('liquidity-mining/v2/remove-liquidity', data);
  }

  async liquidityMiningBalance(isBackgroundRequest: boolean) {
    return this.requestManager.get<LiquidityMiningBalance>('liquidity-mining/balance', null, {
      isBackgroundRequest,
    });
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/liquidity-mining/minimum');
  }
}
