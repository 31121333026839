import { AppleAuthUrl, SsoAuthPersonData } from '../../schema/sso';
import { CakeApiBase } from '../CakeApiBase';

export class AppleSsoApi extends CakeApiBase {
  async getAuthoriseUrl() {
    return this.requestManager.get<AppleAuthUrl>('auth/apple');
  }

  async getPersonData(query = {}) {
    return this.requestManager.post<SsoAuthPersonData>('auth/apple/auth-code', query);
  }

  async loginWithAuthorizationCode(code: string, isIos?: boolean) {
    return this.requestManager.post<SsoAuthPersonData>('auth/apple/auth-code', {
      code,
      isIos,
    });
  }
}
