import React, { Component } from 'react';

export default class ClickableElement extends Component {
  state = {
    isWaitingProcess: false,
    isWaitingDelay: false,
  }

  startTimer() {
    if (!this.props.minimumDelay) return false;
    this.setState({
      isWaitingDelay: true,
    });
    const self = this;
    setTimeout(() => {
      if (this._isMounted) {
        self.setState({
          isWaitingDelay: false,
        });
      }
    }, this.props.minimumDelay);
  }

  isAcceptingClick() {
    const {
      isWaitingProcess,
      isWaitingDelay,
    } = this.state;
    return !isWaitingProcess && !isWaitingDelay;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async onClick() {
    if (!this.isAcceptingClick()) return false;
    this.setState({
      isWaitingProcess: true,
    });
    this.startTimer();
    await this.props.onClick();
    if (this._isMounted) {
      this.setState({
        isWaitingProcess: false,
      });
    }
  }

  async onInputKeyPress(e) {
    if (e.keyCode === 13) { // enter
      if (!this.isAcceptingClick()) return false;
      this.setState({
        isWaitingProcess: true,
      });
      this.startTimer();
      await this.props.onKeyDown();
      if (this._isMounted) {
        this.setState({
          isWaitingProcess: false,
        });
      }
    }
  }

  render() {
    const { children, minimumDelay, className, ...otherProps } = this.props;
    const isClick = otherProps.onClick !== undefined;
    if (isClick) {
      return (
        <div
          {...otherProps}
          className={`${className} clickable-hover`}
          onClick={() => this.onClick()}>
          {children}
        </div>
      );
    }
    return (
      <div
        {...otherProps}
        className={`${className} clickable-hover`}
        onKeyDown={e => this.onInputKeyPress(e)}>
        {children}
      </div>
    );
  }
}
