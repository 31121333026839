import Variables from '../../Variables.styles';

export const getBoxShadowStyle = (isFocused?: boolean, isInvalid?: boolean) => {
  if (isInvalid) {
    return `0 0 0 ${isFocused ? '2px' : '1px'} ${Variables.redInvalidControl}`;
  }

  if (isFocused) {
    return `0 0 0 2px ${Variables.electricBerry}`;
  }

  return `0 0 0 1px ${Variables.gray200}`;
};
