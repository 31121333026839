import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Card from 'common/components/Card/Card';
import { useCakeSelector } from 'app/rootReducer';
import Variables from 'Variables.styles';
import { getDynamicPercentageRates } from 'learn-earn/learnEarnSelector';
import { QuizOption } from '../data/learnEarnContent';

const useStyles = createUseStyles({
  optionCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 15fr',
    margin: `${Variables.medium} 0`,
    padding: Variables.medium,
    cursor: 'pointer',
    '&.active': {
      boxShadow: `0 0 0 2px ${Variables.electricBerry}`,
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  optionCardCorrect: {
    composes: '$optionCard',
    boxShadow: 'none',
    border: `2px solid ${Variables.verificationGreen}`,
  },
  optionCardWrong: {
    composes: '$optionCard',
    boxShadow: 'none',
    border: `2px solid ${Variables.red}`,
  },
  optionLetter: {
    color: Variables.gray600,
  },
  optionLetterCorrect: {
    color: Variables.verificationGreen,
  },
  optionLetterWrong: {
    color: Variables.red,
  },
});

interface LessonModalQuizOptionProps {
  quizOptionData: QuizOption;
  selected?: boolean;
  setSelectedQuizAnswer?: (option: string) => void;
  isQuizCorrectAnswer?: boolean;
}

const LessonModalQuizOption = (props: LessonModalQuizOptionProps) => {
  const { quizOptionData, selected, setSelectedQuizAnswer, isQuizCorrectAnswer } = props;
  const classes = useStyles();
  const isInQuiz = !!setSelectedQuizAnswer; // check if in quiz or result modal
  const dynamicPercentageRates = useCakeSelector(getDynamicPercentageRates);
  const { t } = useTranslation();

  const { option, description } = quizOptionData;

  const inQuizComponent = <Card className={classes.optionCard} selected={selected} onClick={() => setSelectedQuizAnswer(option)}>
      <h3 className={classes.optionLetter}><Trans i18nKey={`option${option}`}>{option}</Trans></h3>
      <span><Trans i18nKey={`option${description}`}>{t(description, dynamicPercentageRates)}</Trans></span>
    </Card>;

  const inResultCorrectComponent = <Card className={classes.optionCardCorrect}>
      <h3 className={classes.optionLetterCorrect}><Trans i18nKey={`option${option}`}>{option}</Trans></h3>
      <span>{t(description, dynamicPercentageRates)}</span>
    </Card>;

  const inResultWrongComponent = <Card className={classes.optionCardWrong}>
      <h3 className={classes.optionLetterWrong}><Trans i18nKey={`option${option}`}>{option}</Trans></h3>
      <span>{t(description, dynamicPercentageRates)}</span>
    </Card>;

  const inResultComponent = isQuizCorrectAnswer ? inResultCorrectComponent : inResultWrongComponent;

  return (
    <>
      { isInQuiz ? inQuizComponent : inResultComponent }
    </>
  );
};

export default LessonModalQuizOption;
