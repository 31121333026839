import { RemoveLiquidity, RemoveLiquidityPrice } from '@cakedefi/cake-sdk';
import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../app/rootReducer';
import sdk from '../../../sdk';

interface RemoveLiquidityState extends RemoveLiquidityPrice {
  removeLiquidityFeeMultiplier: any;
  inputs: any;
  isLoading: boolean;
  error?: SerializedError;
}

const initialState: RemoveLiquidityState = {
  isLoading: false,
  error: null,
  blockHeight: null,
  validUntilTimestamp: null,
  liquidityMiningPairId: null,
  primaryCoinSharePrice: null,
  secondaryCoinSharePrice: null,
  dexPriceStability: null,
  removeLiquidityFeeMultiplier: null,
  inputs: {
    enteredShares: 0,
  },
  isCoinsSwappable: false,
};

export const removeLiquidityPrice = createAsyncThunk<RemoveLiquidityPrice, string>(
  'removeLiquidityModal/removeLiquidityPrice',
  pairId => sdk.LiquidityMiningApi.removeLiquidityPrice(pairId),
);

export const removeLiquidity = createAsyncThunk<RemoveLiquidity, null, ThunkApiConfig>('removeLiquidityModal/removeLiquidityPrice', (_, thunkAPI) => {
  const { removeLiquidityModal } = thunkAPI.getState();
  const { liquidityMiningPairId: pairId, blockHeight, inputs: { enteredShares } }: any = removeLiquidityModal;

  return sdk.LiquidityMiningApi.removeLiquidityV2({
    pairId,
    blockHeight,
    liquidityShareAmount: enteredShares,
  });
});

const removeLiquidityModalSlice = createSlice({
  name: 'removeLiquidityModal',
  initialState,
  reducers: {
    setEnteredShares: (state, action) => {
      state.inputs.enteredShares = action.payload;
    },
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(removeLiquidityPrice.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(removeLiquidityPrice.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
      error: null,
    }));
    builder.addCase(removeLiquidityPrice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});
export const { setEnteredShares, clear: clearRemoveLiquidityModalSlice } = removeLiquidityModalSlice.actions;
export default removeLiquidityModalSlice.reducer;
