import BigNumber from 'bignumber.js';
import { Coin, CurrentLiquidityPricesOnDefichain } from '@cakedefi/cake-sdk/schema';
import { CoinMap } from 'types/coin/coin-map';
import { isEmpty } from '../utils';
import getValueInPreferredCurrency from '../utils/getValueInPreferredCurrency';

export const getCoinByCoinId = (coins: CoinMap, coinId: string): Coin => {
  if (!coins) {
    return {} as Coin;
  }
  return Object.values(coins).find(({ id }) => id === coinId);
};

export const getCoinPriceInUSD = (coins: CoinMap, coinId: string): string => {
  const coin = getCoinByCoinId(coins, coinId);
  return coin?.priceUSD as string;
};

export const getCoinValueInPreferredCurrency = (coins, coinId, amount, btcPrice, preferredCurrency, currencyRates) => {
  const priceInUSD = getCoinPriceInUSD(coins, coinId);
  const amountInPreferredCurrency = getValueInPreferredCurrency(amount, priceInUSD, btcPrice, preferredCurrency, currencyRates);
  return amountInPreferredCurrency;
};

export const getSharesValueInPreferredCurrency = (coins, currencyRates, preferredCurrency, btcPrice, primaryCoin, secondaryCoin) => {
  if (!coins || !currencyRates) {
    return {};
  }
  const primaryValueInPreferredCurrency = getCoinValueInPreferredCurrency(coins, primaryCoin.id, primaryCoin.amount, btcPrice, preferredCurrency, currencyRates);
  const secondaryValueInPreferredCurrency = getCoinValueInPreferredCurrency(coins, secondaryCoin.id, secondaryCoin.amount, btcPrice, preferredCurrency, currencyRates);
  const preferredCurrencyDecimals = getPreferredCurrencyDecimals(coins, preferredCurrency);
  const shareValuesInPreferredCurrency = new BigNumber(primaryValueInPreferredCurrency).plus(secondaryValueInPreferredCurrency).decimalPlaces(preferredCurrencyDecimals).toFixed();
  return {
    preferredCurrencyDecimals,
    shareValuesInPreferredCurrency,
  };
};

function getPreferredCurrencyDecimals(coins, preferredCurrency) {
  const coin: any = getCoinByCoinId(coins, preferredCurrency);
  return coin ? coin.decimals : 2;
}

export const getCoinAmount = (pair: Partial<CurrentLiquidityPricesOnDefichain>, totalSharesPerPair: string|number|BigNumber) => {
  if (!pair) {
    return {
      primaryCoinAmount: null,
      secondaryCoinAmount: null,
    };
  }
  const primaryCoinSharePriceBigNumber = new BigNumber(pair.primaryCoinSharePrice);
  const secondaryCoinsharePriceBigNumber = new BigNumber(pair.secondaryCoinSharePrice);
  const totalSharesBigNumber = new BigNumber(totalSharesPerPair);
  const primaryCoinAmount = totalSharesBigNumber.multipliedBy(primaryCoinSharePriceBigNumber);
  const secondaryCoinAmount = totalSharesBigNumber.multipliedBy(secondaryCoinsharePriceBigNumber);

  return {
    primaryCoinAmount,
    secondaryCoinAmount,
  };
};

export const getTotalSharesAmount = (coinAmount, coinSharePrice, decimals) => new BigNumber(coinAmount)
  .dividedBy(new BigNumber(coinSharePrice))
  .decimalPlaces(decimals, BigNumber.ROUND_DOWN);

export const validateAmountIsInsufficient = (coinInput, balance) => {
  const coinInputBigNumber = new BigNumber(coinInput);
  const balanceBigNumber = new BigNumber(balance);

  return coinInputBigNumber.isGreaterThan(balanceBigNumber);
};

const isInvalidNumber = bn => bn.isZero() || bn.isNaN();

export const calculatePriceDeviation = (primaryValueInUSD, secondaryValueInUSD) => {
  if (isInvalidNumber(primaryValueInUSD) || isInvalidNumber(secondaryValueInUSD)) {
    return new BigNumber(0);
  }

  return primaryValueInUSD
    .dividedBy(secondaryValueInUSD)
    .minus(1)
    .abs();
};

export const isHighDeviation = bn => new BigNumber(bn).isLessThanOrEqualTo(95);

export const summarizeLMBalanceInUsd = (lmBalances, coinsPricing) => {
  const pricings = {};
  coinsPricing.forEach((price) => {
    if (!isEmpty(price)) pricings[price.CoinId] = price;
  });

  const { currentLiquidityPricesOnDefichain, liquidityMiningPairs, totalLiquiditySharesForUser } = lmBalances;

  const usdValues = liquidityMiningPairs?.map((lmPair) => {
    const lmShares = totalLiquiditySharesForUser.find(p => p.liquidityMiningPairId === lmPair.id);
    const lmPrice = currentLiquidityPricesOnDefichain.find(p => p.liquidityMiningPairId === lmPair.id);
    const primaryPrice = pricings[lmPair.PrimaryCoinId];
    const secondaryPrice = pricings[lmPair.SecondaryCoinId];

    if (!lmShares || !lmPrice || !primaryPrice || !secondaryPrice) {
      return new BigNumber(0);
    }
    const coinAmounts = getCoinAmount(lmPrice, lmShares.totalShares);
    const primaryCoinUsdValue = coinAmounts.primaryCoinAmount.multipliedBy(primaryPrice.priceUSD.avg);
    const secondaryCoinUsdValue = coinAmounts.secondaryCoinAmount.multipliedBy(secondaryPrice.priceUSD.avg);
    return primaryCoinUsdValue.plus(secondaryCoinUsdValue);
  });

  return usdValues?.reduce((curr, acc) => acc.plus(curr), new BigNumber(0));
};
