import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import constants from './constants';

export default function coinsToUSD(
  coins: BigNumberConstructable,
  price: BigNumberConstructable,
  fmt: BigNumber.Format = constants.BN_STRING_FORMAT,
) {
  if (coins && price) {
    const a = new BigNumber(coins);
    const b = new BigNumber(price);

    let result = a.multipliedBy(b);
    if (result.gt(1000 * 1000 * 1000)) {
      result = result.dividedBy(1000 * 1000 * 1000);
      return `${result.toFormat(constants.DISPLAYED_DP, BigNumber.ROUND_DOWN, fmt)}B`;
    }
    return result.toFormat(constants.DISPLAYED_DP, BigNumber.ROUND_DOWN, fmt);
  }
  return '0.00';
}
