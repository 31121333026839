import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { MouseOverTooltip } from 'views/components';
import styles from './HeaderNavigationLink.styles';

const useStyles = createUseStyles(styles);

const HeaderNavigationLink = ({ headerLink, className, onClick = null }) => {
  const classes = useStyles();
  const { text, link, isDisable, popoverContent, textLabel = '', isExternal } = headerLink;

  if (isDisable) {
    return (
      <span className={classes.menuLinkDisabled}>
        <MouseOverTooltip
          renderTooltip={() => (
            <div className={classes.popover}>
              <Trans>{popoverContent}</Trans>
            </div>
          )}
        >
          <Trans>{text}</Trans>
        </MouseOverTooltip>
      </span>
    );
  }

  return (
    <Link
      to={isExternal ? { pathname: link } : link}
      target={isExternal ? '_blank' : ''}
      className={className}
      onClick={onClick}
    >
      <Trans>{text}</Trans>
      { !!textLabel && <span className={classes.newLabel}><Trans>{textLabel}</Trans></span> }
    </Link>
  );
};

export default HeaderNavigationLink;
