export const getZendeskBaseLink = (language) => {
  switch (language) {
    case 'en':
      return 'https://support.bake.io/en';
    case 'it':
      return 'https://support.bake.io/it';
    case 'cn':
    case 'zht':
      return 'https://support.bake.io/zh-TW';
    default:
      return 'https://support.bake.io/en';
  }
};

export const getZendeskRequestLink = (language) => `${getZendeskBaseLink(language)}`;

export const getKYCProofOfIdentityFAQ = (language) => {
  switch (language) {
    case 'de':
      return `${getZendeskBaseLink(language)}/articles/360038921292-Welche-Dokumente-werden-f%C3%BCr-den-Identit%C3%A4tsnachweis-KYC-akzeptiert-`;
    case 'it':
      return `${getZendeskBaseLink(language)}/articles/360038921292-Quali-documenti-sono-accettati-per-la-prova-dell-identit%C3%A0-KYC-`;
    default:
      return `${getZendeskBaseLink('en')}/articles/360038921292-What-documents-are-accepted-for-Proof-of-Identity-KYC-`;
  }
};

export const getKYCProofOfAddressFAQ = (language) => {
  switch (language) {
    case 'de':
      return `${getZendeskBaseLink(language)}/articles/360036036832-Welche-Adressnachweise-werden-f%C3%BCr-KYC-akzeptiert-`;
    default:
      return `${getZendeskBaseLink('en')}/articles/360036036832-Which-documents-are-accepted-as-Proof-of-Address-POA-for-KYC-`;
  }
};
