import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';

export type ModalContentState = {
  cancelLabel?: string;
  component: React.ReactNode;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  scroll?: DialogProps['scroll'];
  fullWidth?: boolean;
  title?: string | React.ReactNode;
  showCancelButton?: boolean;
  onBackdropClick?: () => void;
  onClose?: () => void;
  hideBody?: boolean; // hides default body and use modal's one
  overlayColor?: string;
};

export type UseModalTypes = {
  closeModal: () => void;
  modalContent: ModalContentState;
  openModal: (content: ModalContentState) => void;
  isShowModal: boolean;
};

export default (): UseModalTypes => {
  const [isShowModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContentState>(
    {} as ModalContentState,
  );

  const openModal = (content = null) => {
    if (content) {
      setModalContent(content);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    const { onClose } = modalContent;

    if (onClose) {
      onClose();
    }

    setShowModal(false);
  };

  return { isShowModal, openModal, closeModal, modalContent };
};
