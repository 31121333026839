import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './InlineLoadingIndicator.styles';

const useStyles = createUseStyles(styles);

export default function InlineLoadingIndicator() {
  const classes = useStyles();
  return (
    <span className={classes.loading}></span>
  );
}
