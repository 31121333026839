import BigNumber from 'bignumber.js';

const BN_STRING_FORMAT: BigNumber.Format = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

// coin/share display dp
const DISPLAYED_DP = 2;
const FRIENDLY_DECIMALS = 5;
const DEFAULT_CRYPTO_DECIMALS = 8;

export default {
  BN_STRING_FORMAT,
  DISPLAYED_DP,
  FRIENDLY_DECIMALS,
  DEFAULT_CRYPTO_DECIMALS,
};
