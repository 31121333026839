import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createUseStyles } from 'react-jss';
import Icon from '../../../views/components/Icon';
import Button from '../Button/Button';

const useStyles = createUseStyles({
  copyButton: {
    width: '44px',
    height: '44px',
    padding: '5px',
    borderRadius: '100%',
  },
});

interface Props {
  text: string;
  onCopy?: () => void;
}

const CopyButton = ({ text, onCopy }: Props) => {
  const classes = useStyles();
  return (
    <Button className={classes.copyButton}>
      <CopyToClipboard text={text} onCopy={onCopy} >
        <Icon name="copy" containerClassName="copy-icon" />
      </CopyToClipboard>
    </Button>
  );
};

export default CopyButton;
