import React from 'react';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import getCakeDeFiWebsiteBaseURL from '../../../utils/getCakeDeFiWebsiteBaseURL';

const baseUrl = getCakeDeFiWebsiteBaseURL();

const useStyles = createUseStyles({
  paragraphHeader: {
    fontSize: '1.8rem',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
  paragraph: {
    marginBottom: Variables.medium,
  },
  leftButton: {
    marginRight: Variables.medium,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.xs)]: {
    headerText: {
      flexBasis: '100%',
      marginBottom: Variables.small,
    },
  },
});

export default function MarketingConsentTerms() {
  const classes = useStyles();

  return (
    <>
      <p className={classes.paragraph}>
        <Trans>
          We would love to keep you in the loop with the latest news, offers, and product features tailored just for you.
        </Trans>
      </p>
      <p className={classes.paragraph}>
        <Trans>
          Your privacy is our priority, and we commit to safeguarding your personal data.
        </Trans>
      </p>
      <p className={classes.paragraph}>
        <Trans>
          We send out marketing communications via the following methods:
        </Trans>
      </p>

      <ul>
        <li>
          <Trans>
            Email: Direct updates about new products, features, offers and articles
          </Trans>
        </li>
        <li>
          <Trans>
            Push Notification: Instant alerts on new products, features, offers and articles
          </Trans>
        </li>
      </ul>

      <p className={classes.paragraph}>
        <Trans>You can customize your preferences or unsubscribe at any time via account settings in the Bake app.</Trans>
      </p>
      <p className={classes.paragraph}>
        <Trans>For more information on how we use your personal data, please visit Bake's <a href={`${baseUrl}/privacy-policy/`} target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href='https://app.bake.io/terms-and-conditions' target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</Trans>
      </p>
    </>
  );
}
