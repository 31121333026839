import Variables from '../../../Variables.styles';

export default {
  wrapper: {
    position: 'fixed',
    bottom: Variables.large,
    right: Variables.large,
    marginLeft: Variables.large,
    tabIndex: 99999,
    backgroundColor: Variables.white,
    borderRadius: Variables.small,
    border: Variables.primaryBorder,
    padding: Variables.large,
    maxWidth: '450px',
    minWidth: '320px',
    display: props => (props && props.isClosed ? 'none' : 'inherit'),
    zIndex: 999,
  },
  title: {
    fontSize: '2.4rem',
    marginBottom: 0,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1.6rem',
    fontWeight: 400,
    textAlign: 'center',
  },
  actionButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
};
