/* eslint-disable max-classes-per-file */
import BigNumber from 'bignumber.js';

export type CaptchaType = 'GOOGLE' | 'GEETEST';
export type AssetType = 'NATIVE' | 'SHARE' | 'COLLATERAL' | 'YIELD_VAULT'
export type DateConstructable = string | number | Date;
export type BigNumberConstructable = string | number | BigNumber;
export type RevenueTxOperation =
  'NA_MKT_FEE_RCVD' |
  'NA_FEE_RCVD' |
  'NA_SPROD_ENTRY_FEE_PAID' |
  'NA_SPROD_EXIT_FEE_PAID' |
  'NA_COINSWAP_MARGIN_REV' |
  'NA_NODE_FEE_RCVD' |
  'NA_LM_RECOVER_FEE_RCVD' |
  'NA_LM_REWARD_FEE_RCVD' |
  'NA_NODE_REWARD' |
  'NA_STAKING_REWARD_BOOSTER_RCVD' |
  'NA_LM_DFI_REWARD_BOOSTER_RCVD' |
  'NA_5_YEAR_FREEZER_REWARD_BOOSTER_RCVD' |
  'NA_10_YEAR_FREEZER_REWARD_BOOSTER_RCVD' |
  'NA_EARN_REWARD_BOOSTER_RCVD' |
  'NA_YIELD_VAULT_REWARD_BOOSTER_RCVD' |
  'NA_DCA_DEPOSIT_RCVD' |
  'NA_DCA_REFERRAL_REWARD_RCVD' |
  'NA_BUNDLE_COMMISSIONS_RCVD';
export type TxOperation = // exclude all fee tx types
  'NA_MKT_FEE_PAID' |
  'NA_WITHDRAWAL' |
  'NA_DEPOSIT' |
  'NA_NODE_REWARD' |
  'EXIT_SPROD' |
  'NA_FEE_PAID' |
  'NA_CUSTOM' |
  'SH_CUSTOM' |
  'NA_TRANSFER_RCVD' |
  'SH_TRANSFER_RCVD' |
  'SH_SPROD_DFI_REWARD' |
  'SH_SPROD_DFI_CONFECTIONERY_REWARD' |
  'NA_COINSWAP_RCVD' |
  'NA_COINSWAP_SENT' |
  'NA_REFERRAL_REWARD_RCVD' |
  'NA_LM_REWARD' |
  'NA_LM_DFI_REWARD' |
  'NA_LM_CONSUME' |
  'NA_LM_RECOVER' |
  'NA_LM_RECOVER_FEE_PAID' |
  'NA_CONFECTIONERY_SWAP_SENT' |
  'NA_CONFECTIONERY_SWAP_RCVD' |
  'SH_SIGNUP_BONUS_RCVD' |
  'SH_SIGNUP_TIER0_BONUS_RCVD' |
  'SH_SIGNUP_TIER0_BONUS_RETURN_SENT' |
  'SH_REFERRER_SIGNUP_BONUS_RCVD' |
  'NA_ADMIN_OTC_SENT' |
  'NA_ADMIN_OTC_RCVD' |
  'NA_FREEZER_STAKING_BONUS' |
  'NA_FREEZER_LIQUIDITY_MINING_BONUS' |
  'NA_5_YEAR_FREEZER_REWARD' |
  'NA_10_YEAR_FREEZER_REWARD' |
  'MINT_SHARES' |
  'BURN_SHARES' |
  'NA_DEPOSIT_FEE_PAID' |
  'NA_REIMBURSEMENT_RCVD' |
  'SH_FREEZER_PROMO_BONUS_RCVD' |
  'NA_PROMO_BONUS_RCVD' |
  'SH_PROMO_BONUS_RCVD' |
  'SH_KYC_COMPLETED_BONUS_RCVD' |
  'NA_DEX_SWAP' |
  'NA_DEX_SWAP_FEE_PAID' |
  'NA_MKT_SENT' |
  'NA_MKT_RCVD' |
  'NA_ADD_COLLATERAL' |
  'NA_REMOVE_COLLATERAL' |
  'NA_TAKE_LOAN' |
  'NA_LOAN_FEE_PAID' |
  'NA_REPAY_LOAN' |
  'CO_LIQUIDATE_COLLATERAL' |
  'LO_REPAY_LIQUIDATION' |
  'LO_INTEREST_PAID' |
  'LO_INTEREST_RCVD' |
  'NA_DEPOSIT_FEE_REIMBURSEMENT_RCVD' |
  'NA_DFI_GESTURE_RCVD' |
  'NA_EARN_ENTER' |
  'NA_EARN_EXIT' |
  'NA_EARN_INSURANCE_RCVD' |
  'EN_EARN_REWARD' |
  'TOKEN_SPLIT_IN' |
  'TOKEN_SPLIT_OUT' |
  'SH_CONVERT_ETH_RCVD' |
  'NA_CONVERT_CSETH_RCVD' |
  'VOTING_FEE_RCVD' |
  'NA_ENTER_YIELD_VAULT_SENT' |
  'NA_EXIT_YIELD_VAULT_RCVD' |
  'NA_YIELD_VAULT_REWARD' |
  'NA_STAKING_REWARD_BOOSTER_RCVD' |
  'NA_LM_DFI_REWARD_BOOSTER_RCVD' |
  'NA_5_YEAR_FREEZER_REWARD_BOOSTER_RCVD' |
  'NA_10_YEAR_FREEZER_REWARD_BOOSTER_RCVD' |
  'NA_EARN_REWARD_BOOSTER_RCVD'|
  'NA_YIELD_VAULT_REWARD_BOOSTER_RCVD' |
  'NA_DCA_DEPOSIT_RCVD' |
  'DCA_PROMO_BONUS_RCVD' |
  'SH_DCA_PROMO_BONUS_RCVD'|
  'DEPOSIT_PERCENTAGE_FEE' |
  'NA_DCA_REFERRAL_REWARD_RCVD' |
  'NA_COIN_PURCHASE_DEPOSIT_RCVD' |
  'NA_ASSET_SWAP_SENT' |
  'NA_ASSET_SWAP_RCVD' |
  'NA_ASSET_SWAP_FEE_SENT' |
  'NA_BUNDLE_ENTER_SENT' |
  'NA_BUNDLE_EXIT_RCVD' |
  'NA_BUNDLE_ENTER_FEE_SENT' |
  'NA_BUNDLE_EXIT_FEE_SENT' |
  'NA_DEPOSIT_PERCENTAGE_FEE_SENT' |
  'NA_DUST_CONVERSION_RCVD' |
  'NA_DUST_CONVERSION_SENT' |
  'NA_BUNDLE_COMMISSIONS_RCVD' |
  'NA_BUNDLE_REFERRAL_REWARD_RCVD' |
  'NA_BUNDLE_NEW_USER_REWARD_RCVD' |
  'YP_YIELD_PRODUCT_RCVD' |
  'NA_YIELD_PRODUCT_RCVD' |
  'YP_YIELD_PRODUCT_SENT' |
  'NA_YIELD_PRODUCT_SENT' |
  'NA_BUNDLE_REFERRAL_REWARD_RCVD' |
  'NA_BUNDLE_NEW_USER_REWARD_RCVD' |
  'NA_INACTIVE_ADMIN_FEE_SENT' |
  'NA_BUY_LIQUID_SHARES' |
  'NA_SELL_LIQUID_SHARES'

export type CakeEliteOperation = 'CAKE_ELITE_YEARLY' | 'CAKE_ELITE_MONTHLY';

export type TxStatus = 'REQ_USER_CONF' | 'PENDING' | 'CONFIRMED' | 'CANCELLED';
export type WalletActivityType =
  'DEPOSIT_COIN' |
  'WITHDRAW_COIN' |
  'NODE_REWARD' |
  'BONUS_SHARE' |
  'BUY_SHARE' |
  'SELL_SHARE' |
  'FEE' |
  'SWAP_OUT_COIN' |
  'SWAP_IN_COIN' |
  'ENTER_SPROD' |
  'EXIT_SPROD' |
  'SPROD_ENTRY_FEE' |
  'SPROD_EXIT_FEE' |
  'CUSTOM' |
  'TRANSFER_RCVD' |
  'SPROD_DFI_REWARD' |
  'SPROD_DFI_CONFECTIONERY_REWARD' |
  'REFERRAL_SENT' |
  'REFERRAL_RCVD' |
  'LIQUIDITY_MINING_REWARD' |
  'ADD_LIQUIDITY' |
  'REMOVE_LIQUIDITY' |
  'REMOVE_LIQUIDITY_FEE' |
  'CONFECTIONERY_SWAP_OUT' |
  'CONFECTIONERY_SWAP_IN' |
  'SIGNUP_BONUS' |
  'REFERRER_SIGNUP_BONUS' |
  'OTC_RCVD' |
  'OTC_SENT' |
  'PROMO_BONUS_RCVD' |
  'KYC_COMPLETED_BONUS' |
  'ADD_COLLATERAL' |
  'REMOVE_COLLATERAL' |
  'TAKE_LOAN' |
  'LOAN_FEE_PAID' |
  'REPAY_LOAN' |
  'REPAY_LIQUIDATION' |
  'LIQUIDATE_COLLATERAL' |
  'INTEREST_PAID' |
  'DFI_GESTURE_RCVD' |
  'ETH_GATEWAY_FEE_REIMBURSEMENT' |
  'ENTER_EARN' |
  'EXIT_EARN' |
  'INSURANCE_RCVD_FROM_EARN' |
  'EARN_REWARD' |
  'TOKEN_SPLIT_IN' |
  'TOKEN_SPLIT_OUT' |
  'DEPOSIT_PERCENTAGE_FEE' |
  'BUY_ETH_SHARE' |
  'SELL_ETH_SHARE' |
  'STAKING_REWARD_BOOSTER' |
  'LM_DFI_REWARD_BOOSTER' |
  '5_YEAR_FREEZER_REWARD_BOOSTER' |
  '10_YEAR_FREEZER_REWARD_BOOSTER' |
  'EARN_REWARD_BOOSTER' |
  'YIELD_VAULT_REWARD_BOOSTER' |
  'COIN_PURCHASE_DEPOSIT' |
  'ASSET_SWAP_SENT' |
  'ASSET_SWAP_RCVD' |
  'ASSET_SWAP_FEE' |
  'BUNDLE_ENTER_SENT' |
  'BUNDLE_EXIT_RCVD' |
  'BUNDLE_ENTER_FEE_SENT' |
  'BUNDLE_EXIT_FEE_SENT' |
  'DUST_CONVERSION_RCVD' |
  'DUST_CONVERSION_SENT' |
  'BUNDLE_COMMISSIONS' |
  'BUNDLE_REFERRAL_RCVD' |
  'BUNDLE_NEW_USER_REWARD_RCVD' |
  'YIELD_PRODUCT_ENTER_RCVD' |
  'YIELD_PRODUCT_EXIT_SENT' |
  'YIELD_PRODUCT_EXIT_RCVD' |
  'YIELD_PRODUCT_ENTER_SENT' |
  'BUNDLE_REFERRAL_RCVD' |
  'BUNDLE_NEW_USER_REWARD_RCVD' |
  'INACTIVE_ADMIN_FEE_SENT' |
  'BUY_LIQUID_SHARES' |
  'SELL_LIQUID_SHARES' |
  'unknown';
export type RecentTransactionCategory = 'SPROD' | 'NATIVE';
export type ExportCsvColumn =
  'Date' |
  'Operation' |
  'Amount' |
  'Cryptocurrency' |
  'FIAT value' |
  'FIAT currency' |
  'Transaction ID' |
  'Withdrawal address'|
  'Reference';
export type UserExportCsvRow = Record<ExportCsvColumn, string>;
export type AdminExportCsvColumn =
  'id' |
  'confirmedAt' |
  'Date' | // compulsory for sorting to work
  'Amount' |
  'usdEquivalent' |
  'extAddress' |
  'status' |
  'coin' |
  'operation';
export type AdminExportCsvRow = Record<AdminExportCsvColumn, string>;
export type FeatureInaccessibleReason =
  'KycRequired' |
  'LiteKycRequired' |
  'DepositLimitReached' |
  'TwoFARequired';
export type Currency = 'USD' | 'EUR' | 'BTC' | 'CNY' | 'SGD' | 'CHF' | 'AUD' | 'MYR' | 'CAD' | 'GBP';
export type AuditActionType =
  'REJECT_KYC_SUBMISSION' |
  'APPROVE_KYC_SUBMISSION' |
  'EXPORTING_USER_TRANSACTIONS' |
  'UPDATE_CONFIGURATION' |
  'CREATE_DFI_TRANSFER' |
  'CREATE_SHARES_SALE_LISTING' |
  'SET_DFI_PRICE' |
  'SET_DFI_COINSWAP_ALLOWANCE' |
  'CREATE_PROMOTION' |
  'ADD_TRADE' |
  'ADD_HEDGE' |
  'ADD_BONUS_ROUND' |
  'CREATE_SPROD_BATCH' |
  'SETTLE_BATCH' |
  'CANCEL_BATCH' |
  'UPDATE_BATCH_INFO' |
  'SUSPEND_ACCOUNT' |
  'UNSUSPEND_ACCOUNT' |
  'REMOVE_2FA' |
  'UPDATE_EMAIL' |
  'CONFIRM_WITHDRAWAL' |
  'CANCEL_WITHDRAWAL' |
  'UPDATE_MARGIN' |
  'USER_SEARCH_PERFORMED' |
  'UPDATE_WITHDRAWAL_FEE' |
  'UPDATE_AUTO_WITHDRAWAL' |
  'EXPORTING_USER_DEPOSITS' |
  'ADD_REFERRAL_CODE' |
  'ADD_LENDING' |
  'BAN_ACCOUNT' |
  'ASSIGN_KYC_SUBMISSION' |
  'REASSIGN_KYC_SUBMISSION';

export class CoinMovability {
  public hasCoin: boolean;

  public hasShare: boolean;

  constructor(hasCoin: boolean, hasShare: boolean) {
    this.hasCoin = hasCoin;
    this.hasShare = hasShare;
  }

  legacy(): boolean[] {
    return [this.hasCoin, this.hasShare];
  }
}

export class MonthDateTime {
  public month: string;

  public date: string;

  public time: string;

  constructor(month: string, date: string, time: string) {
    this.month = month;
    this.date = date;
    this.time = time;
  }

  legacy(): string[] {
    return [this.month, this.date, this.time];
  }
}

export class DateObject {
  public day: string;

  public month: string;

  public year: string;

  constructor(day: string, month: string, year: string) {
    this.day = day;
    this.month = month;
    this.year = year;
  }
}
