import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BanxaPriceRequest,
  CakeBanxaCreateOrder,
  CakeBanxaCreateOrderParams,
  CakeBanxaCreateOrderRequest,
  DepositAddress,
  TransakOrderRequest,
  TransakPrice,
  TransakPriceRequest,
} from '@cakedefi/cake-sdk/schema';
import { FiatBuyModalState } from '../../../types/onramp/state';
import sdk from '../../../sdk';
import { FIAT_TYPE, CRYPTO_TYPE } from './fiatBuyModalService';
import { clearFiatBuyOrder } from './fiatBuyModalSlice';
import { ThunkApiConfig } from '../../../app/rootReducer';

export const fetchBanxaFiats = createAsyncThunk('wallet/fetchBanxaFiats', () => sdk.BanxaApi.getFiats());

export const fetchBanxaFiatPrices = createAsyncThunk<
  any,
  string | null,
  ThunkApiConfig
>('wallet/fetchBanxaFiatPrices', async (type = CRYPTO_TYPE, thunkAPI) => {
  const { inputs } = thunkAPI.getState().fiatBuyModal;
  const options: BanxaPriceRequest = {
    source: inputs.source,
    target: inputs.target,
  };
  if (type === FIAT_TYPE) {
    options.sourceAmount = inputs.sourceAmount;
  } else if (type === CRYPTO_TYPE) {
    options.targetAmount = inputs.targetAmount;
  }
  const response = await sdk.BanxaApi.getPrices(options);
  return {
    response,
    type,
  };
});

export const fetchBanxaPaymentMethods = createAsyncThunk<
  any,
  null,
  ThunkApiConfig
>('wallet/fetchBanxaPaymentMethods', (_, thunkAPI) => {
  const {
    inputs: { source, target },
  } = thunkAPI.getState().fiatBuyModal;
  return sdk.BanxaApi.getPaymentMethods({
    source,
    target,
  });
});

export const createBanxaOrder = createAsyncThunk<
  CakeBanxaCreateOrder,
  null,
  ThunkApiConfig
>('wallet/createBanxaOrder', async (_, thunkAPI) => {
  const {
    inputs: {
      targetAmount,
      ...restInputs
    },
    fiatSwappable: { coin, amount },
    banxa: { selectedFiatPrice },
  }: FiatBuyModalState = thunkAPI.getState().fiatBuyModal;

  const request: CakeBanxaCreateOrderRequest = {
    ...(restInputs as any),
    paymentMethodId: selectedFiatPrice.paymentMethodId,
  };

  let createOrderParams: CakeBanxaCreateOrderParams = {
    request,
  } as CakeBanxaCreateOrderParams;

  if (coin && amount) {
    createOrderParams = {
      ...createOrderParams,
      swapTargetCoinId: coin,
      swapTargetAmount: amount,
    };
  }
  const response = await sdk.BanxaApi.createOrder(createOrderParams);
  return response;
});

export const fetchTransakFiats = createAsyncThunk(
  'wallet/fetchTransakFiats',
  () => sdk.TransakApi.getFiats(),
);

export const fetchTransakPrice = createAsyncThunk<
  {
    response: TransakPrice;
    type: string;
  },
  string | null,
  ThunkApiConfig
>('wallet/fetchTransakPrice', async (type = CRYPTO_TYPE, thunkAPI) => {
  const {
    inputs,
    transak,
  }: FiatBuyModalState = thunkAPI.getState().fiatBuyModal;
  const options: TransakPriceRequest = {
    fiatCurrency: inputs.source,
    cryptoCurrency: inputs.target,
    isBuyOrSell: 'BUY',
    paymentMethod: transak.selectedPaymentMethod.id,
  };
  if (type === FIAT_TYPE) {
    options.fiatAmount = inputs.sourceAmount;
  } else if (type === CRYPTO_TYPE) {
    options.cryptoAmount = inputs.targetAmount;
  }

  const response = await sdk.TransakApi.getPrice(options);
  return {
    response,
    type,
  };
});

export const fetchTransakCheckoutURL = createAsyncThunk<
  string,
  null,
  ThunkApiConfig
>('wallet/fetchTransakCheckoutURL', async (_, thunkAPI) => {
  const {
    inputs,
    transak,
    fiatSwappable: { coin, amount },
  }: FiatBuyModalState = thunkAPI.getState().fiatBuyModal;
  let request: TransakOrderRequest = {
    fiatCurrency: inputs.source,
    fiatAmount: inputs.sourceAmount,
    cryptoCurrencyCode: inputs.target,
    paymentMethod: transak.selectedPaymentMethod.id,
    walletAddress: inputs.walletAddress,
  };
  if (coin && amount) {
    request = {
      ...request,
      fiatSwappableCoinId: coin,
      fiatSwappableAmount: amount,
    };
  }
  const { checkoutURL } = await sdk.TransakApi.getCheckoutURL(request);
  return checkoutURL;
});

export const fetchWalletAddress = createAsyncThunk<DepositAddress, string, ThunkApiConfig>(
  'wallet/fetchWalletAddress',
  async (coinId, thunkAPI) => {
    const { wallet: { walletByCoins } } = thunkAPI.getState();
    const coin = walletByCoins.find(({ id }) => id === coinId);
    return sdk.WalletApi.getDepositAddress(coinId, coin.networks[0].id);
  },
);

export const fetchFiatSwappableWalletAddress = createAsyncThunk<
  any,
  string,
  ThunkApiConfig
>('wallet/fetchFiatSwappableWalletAddress', async (coinId, thunkAPI) => {
  thunkAPI.dispatch(fetchWalletAddress('BTC'));
  const {
    wallet: { walletByCoins },
  } = thunkAPI.getState();
  const coin = walletByCoins.find(({ id }) => id === coinId);
  return sdk.WalletApi.getDepositAddress(coinId, coin.networks[0].id);
});

export const cancelBanxaOrder = createAsyncThunk<any, null, ThunkApiConfig>(
  'wallet/cancelBanxaOrder',
  async (_, thunkAPI) => {
    const {
      order: { id: orderId },
      fiatSwappable: { coin: fiatSwappableCoinId },
    } = thunkAPI.getState().fiatBuyModal;
    if (fiatSwappableCoinId && orderId) {
      thunkAPI.dispatch(clearFiatBuyOrder());
      return sdk.BanxaApi.cancelOrder(orderId);
    }
    return null;
  },
);
