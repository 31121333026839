import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ModalStyles from 'common/styles/Modal.styles';
import { ModalContent, ModalHeader } from 'modals';
import { ModalContext } from 'modals/ModalContext';
import { useCakeSelector } from 'app/rootReducer';

const useStyles = createUseStyles({
  ...ModalStyles,
  paragraph: {
    fontSize: '1.6rem',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
});

const getFaqLink = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'https://support.bake.io/en-us/articles/4415672978841';
    case 'it':
      return 'https://support.bake.io/it/articles/4415672978841';
    case 'zht':
      return 'https://support.bake.io/zh-TW/articles/4415672978841';
    default:
      return 'https://support.bake.io/en-us/articles/4415672978841';
  }
};

const TigerLunarRulesModalContent = () => {
  const { closeModal } = useContext(ModalContext);
  const { selectedLanguage } = useCakeSelector(state => state.languageRegionSettings);
  const classes = useStyles();

  return (
    <>
      <ModalHeader title='Lunar New Year Promo Rules' onClose={closeModal}/>
      <ModalContent>
        <div className={classes.contentMini}>
          <div className={classes.paragraph}>
            <b><Trans>ELIGIBILITY FOR EXTRA 8 DFI REWARD</Trans></b>
          </div>
          <ul className={classes.paragraph}>
            <li>
              <Trans>The 8 DFI extra referral bonus can be redeemed by any new and existing Bake customer who successfully refers a new user (identity verified with a minimum $50 deposit) from 05:00 UTC on 27 January 2022 to 23:59 UTC on 10 February 2022.</Trans>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>Extra promotional 8 DFI referral bonus will be in addition to the current referral bonus for the referrer (existing Bake customer).</Trans>
                </li>
                <li>
                  <Trans>The referee will also receive 8 DFI in addition to the current referee bonus.</Trans>
                </li>
                <li>
                  <Trans>The DFI referral bonus in total will be applied to both the referrer and referee’s respective Staking Freezer for a minimum of 180 days, and will be rewarding DFI staking rewards every 12 hours. After the 180 days duration, they can choose to use the DFI outside of the freezer and apply it to any other Bake product.</Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>All new customers that sign up with a referral code during the campaign period, including:</Trans>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>Customers that have not completed KYC verification and have not previously made a first-time deposit.</Trans>
                </li>
                <li>
                  <Trans>Customers that have completed KYC verification but have not yet made a first-time deposit.</Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>All eligibility details with $ are in USD.</Trans>
            </li>
          </ul>

          <div className={classes.paragraph}>
            <b><Trans>ELIGIBILITY FOR LUNAR FREEZER 7% DFI STAKING FREEZER BONUS</Trans></b>
          </div>
          <ul className={classes.paragraph}>
            <li>
              <Trans>Available for all freezers on Bake excluding the DASH Staking Freezer.</Trans>
            </li>
            <li>
              <Trans>Valid for entries at 12, 24, 36, 60 and 120 months from 05:00 UTC on 27 January 2022 to 23:59 UTC on 10 February 2022.</Trans>
            </li>
            <li>
              <Trans>Example of dToken liquidity pool entry:</Trans>
              <div>
                <Trans>User A inputs 166.66DFI-500dUSD (around $1000 in total value at the time of writing) into the Liquidity Mining Freezer for DFI-dUSD pair for 24 months.</Trans>
              </div>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>User A will receive an extra $1000*7% = $70 worth of DFI Staking Freezer entry for the duration of 1 year.</Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>Example of DFI staking freezer entry:</Trans>
              <div>
                <Trans>User B inputs 88 DFI (around $264 in total value at the time of writing) into the DFI Staking Freezer for 60 months.</Trans>
              </div>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>User B will receive regular 5 year staking freezer APR bonus.</Trans>
                </li>
                <li>
                  <Trans>User B will receive an extra $264*7% = $18.48 worth of DFI Staking Freezer entry for the duration of 1 year.</Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>7% DFI Staking Freezer Bonus to be received per user has a maximum of $88 worth of DFI.</Trans>
            </li>
            <li>
              <Trans>All eligibility examples and details with $ are in USD.</Trans>
            </li>
          </ul>

          <br/>
          <div className={classes.paragraph}>
            <b><Trans>FAQs</Trans></b>
          </div>
          <div className={classes.paragraph}>
            <Trans>Please read: </Trans><a target='_blank' rel="noopener noreferrer" href={getFaqLink(selectedLanguage)}><Trans>FAQ</Trans></a> {/* nosemgrep: typescript.react.security.audit.react-href-var.react-href-var */}
          </div>
          <br/>
          <br/>
        </div>
      </ModalContent>
    </>
  );
};

export default TigerLunarRulesModalContent;
