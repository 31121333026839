import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import IconInfoGraySolid from '../../../assets/svg/icon-info-gray-solid.svg';

const useStyles = createUseStyles({
  container: {
    backgroundColor: Variables.white,
    borderRadius: Variables.extraSmall,
    border: Variables.primaryBorder,
    padding: Variables.medium,
    display: 'flex',
    gap: Variables.medium,
    textAlign: 'left',
  },
  title: {
    fontSize: Variables.fontSize.base,
    textAlign: 'left',
    marginBottom: Variables.extraSmall,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: Variables.fontSize.base,
  },
  terms: {
    marginTop: Variables.small,
    fontSize: Variables.fontSize.small,
    color: Variables.lightNeutral,
  },
});

interface PromoCodeInfoProps {
  title: string;
  subtitle: string;
  terms?: string;
  containerStyles?: any;
}

const PromoCodeInfo = ({ title, subtitle, terms, containerStyles }: PromoCodeInfoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={containerStyles}>
      <div>
        <img src={IconInfoGraySolid} style={{ width: 16 }} alt={'info-icon'} />
      </div>
      <div>
        <div>
          <span className={classes.title}><Trans>{title}</Trans></span><br />
          <span className={classes.subtitle}> <Trans>{subtitle}</Trans></span>
        </div>
        {!!terms && <div className={classes.terms}><Trans>{terms}</Trans></div>}
      </div>
    </div>
  );
};

export default PromoCodeInfo;
