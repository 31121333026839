import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';

const useStyles = createUseStyles({
  levelContainer: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    marginRight: '32px',
  },
  levelTitle: {
    color: Variables.gray600,
    fontSize: '12px',
    fontWeight: Variables.fontWeight.bold,
    lineHeight: '16px',
  },
  level: {
    fontWeight: Variables.fontWeight.extraBold,
  },
});

interface KycCurrentLevelProps {
  level: string;
}

const KycCurrentLevel = ({ level }: KycCurrentLevelProps) => {
  const classes = useStyles();

  return (
    <div className={classes.levelContainer}>
      <div className={classes.levelTitle}>
        <Trans>Current KYC Level</Trans>
      </div>
      <div className={classes.level}>
        <Trans>{level}</Trans>
      </div>
    </div>
  );
};

export default KycCurrentLevel;
