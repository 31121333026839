import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Spinner } from '../../../views/components';
import formatPercentage from '../../../utils/formatPercentage';
import { paymentMethodsSelector } from './fiatBuyModalSelector';
import { PaymentMethodsSelectorModel } from '../../../types/onramp/state';
import { setPaymentMethod } from './fiatBuyModalAction';

import styles from './PaymentMethods.styles';
import { convertCryptoToFiatSwappableAmount } from './fiatBuyModalSlice';
import { useCakeSelector } from '../../../app/rootReducer';
import { currencyPricingSelector } from '../../../currency/currencySelector';

const useStyles = createUseStyles(styles);

const FeeTemplate = ({
  fee,
  fiatSwappableCoinId,
  showGateWayFee,
  processingTime,
}) => {
  const { t } = useTranslation();

  const feeTemplate = (() => {
    if (fee === 'No') {
      return t('No gateway fee');
    }
    return `${fee} ${t('gateway fee')}`;
  })();

  if (showGateWayFee) {
    if (fiatSwappableCoinId && fee !== 'No') {
      return `${fee} - Will be reimbursed in DFI on completion`;
    }
    return feeTemplate;
  }

  const translateProcessingTime = (processingTimeInput) => {
    const processingTimeArr = processingTimeInput.split(' ');
    const wordsToTranslate = ['days', 'minutes', 'to'];
    for (let i = 0; i < processingTimeArr.length; i += 1) {
      if (wordsToTranslate.includes(processingTimeArr[i])) {
        processingTimeArr[i] = t(processingTimeArr[i]);
      }
    }
    return processingTimeArr.join(' ');
  };

  return translateProcessingTime(processingTime);
};

const PaymentMethods = ({ cancelRequestBeforeFetching }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    fiatCode,
    paymentMethods,
    isPaymentMethodLoading,
    selectedPaymentMethod,
    fiatSwappableCoinId,
    showGateWayFee,
    type,
  }: PaymentMethodsSelectorModel = useCakeSelector(paymentMethodsSelector);
  const pricing = useCakeSelector(currencyPricingSelector);
  const updateSelectedPaymentMethod = (paymentMethod) => {
    if (selectedPaymentMethod?.id !== paymentMethod.id) {
      setPaymentMethod(dispatch, type, paymentMethod, cancelRequestBeforeFetching);
      dispatch(convertCryptoToFiatSwappableAmount({ pricing, withoutMarkup: false }));
    }
  };

  const calculateFee = (percentageFee, fixedFee) => {
    if (percentageFee === 0 && fixedFee === 0) {
      return 'No';
    }

    if (percentageFee) {
      return `${formatPercentage(percentageFee)}%`;
    }

    if (fixedFee) {
      return `${fixedFee} ${fiatCode}`;
    }

    return null;
  };

  if (paymentMethods) {
    const hasNoPaymentMethods = paymentMethods.length === 0;
    return (
      <>
        <p className={classes.paragraphTitle}><Trans>Payment mode</Trans></p>
        {hasNoPaymentMethods
          && <p><i className={classes.exclamationIcon} /> <Trans>No payment method available for {{ fiatCode }}</Trans></p>}

        {paymentMethods.map((paymentMethod) => {
          const { id, name, logoUrl, percentageFee, fixedFee, processingTime } = paymentMethod;
          const selectedMethodClassName = id === selectedPaymentMethod?.id ? 'selected' : '';
          const fee = calculateFee(percentageFee, fixedFee);
          return (
            <div
              key={id}
              onClick={updateSelectedPaymentMethod.bind(null, paymentMethod)}
              className={`${classes.paymentMethod} ${selectedMethodClassName}`}
            >
              <div className={classes.paymentMethodLogo}>
                <img src={logoUrl} alt={name}></img>
              </div>
              <div className={classes.paymentMethodName}>
                <Trans>{name}</Trans>
              </div>
              <div className={classes.paymentMethodFees}>
                <Trans>
                  <FeeTemplate
                    fee={fee}
                    fiatSwappableCoinId={fiatSwappableCoinId}
                    showGateWayFee={showGateWayFee}
                    processingTime={processingTime}
                  ></FeeTemplate>
                </Trans>
              </div>
            </div>
          );
        })}
      </>
    );
  }
  return (
    <>
      {isPaymentMethodLoading && <Spinner />}
    </>
  );
};

export default PaymentMethods;
