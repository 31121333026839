import React from 'react';
import heroImage from 'assets/svg/referral/tiger-lunar-hero.svg';
import { createUseStyles } from 'react-jss';
import { getMinWidthMd } from 'Variables.styles';

const useStyles = createUseStyles({
  tigerLunarImageHeroWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '176px',
    width: '100%',
  },
  tigerLunarImageHero: {
    height: '261px',
    width: '100%',
  },
  [getMinWidthMd]: {
    tigerLunarImageHeroWrapper: {
      alignItems: 'center',
      minHeight: '194px',
    },
  },
});

export default function TigerLunarPromoHeroImage() {
  const classes = useStyles();
  return (
    <div className={classes.tigerLunarImageHeroWrapper}>
      <img className={classes.tigerLunarImageHero} alt='tigerLunar' src={heroImage}/>
    </div>
  );
}
