import Variables from '../../../Variables.styles';

const getBoxShadow = (boxShadow, state) => {
  if (state.isFocused) {
    return `0 0 0 1px ${Variables.electricBerry}`;
  }
  return boxShadow;
};

export default function getSelectCustomStyles({ border, containerDisplay, containerWidth, minWidth, valueJustify, valueContainerPadding, alignSelf }) {
  const controlStyles = ({ borderWidth, boxShadow, flexWrap, ...otherStyles }, state) => ({
    ...otherStyles,
    borderColor: state.isFocused ? Variables.electricBerry : otherStyles.borderColor,
    borderWidth: border ? borderWidth : 0,
    boxShadow: border ? getBoxShadow(boxShadow, state) : 'none',
    cursor: 'pointer',
    color: Variables.black,
    flexWrap: 'unset',
    minHeight: '40px',
    minWidth: '120px',
  });

  const singleValueStyles = ({
    maxWidth,
    position,
    top,
    transform,
    ...otherStyles
  }) => ({
    ...otherStyles, color: Variables.black, display: 'flex', justifyContent: 'space-between', flex: '1',
  });

  return {
    container: styles => ({
      ...styles,
      display: containerDisplay,
      width: containerWidth,
      alignSelf,
    }),
    option: styles => ({
      ...styles,
      color: Variables.black,
      cursor: 'pointer',
    }),
    control: controlStyles,
    singleValue: singleValueStyles,
    placeholder: styles => ({
      ...styles,
      top: 0,
      position: 'relative',
      transform: 'none',
      color: Variables.gray600,
    }),
    valueContainer: styles => ({
      minWidth,
      ...styles,
      padding: valueContainerPadding,
      justifyContent: valueJustify,
      flexWrap: 'unset',
    }),
    menu: styles => ({
      ...styles,
      zIndex: Variables.zIndex.dropdown,
    }),
    menuPortal: styles => ({ ...styles, zIndex: Variables.zIndex.dropdown }),
    groupHeading: base => ({
      ...base,
      fontSize: '1.6rem',
      color: Variables.black,
      textTransform: 'none',
      marginBottom: Variables.medium,
    }),
  };
}

const gray = {
  100: '#eee',
  200: '#ddd',
  300: '#ccc',
  900: '#999',
};

export function getThemes(theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: gray[300],
      primary25: gray[100],
    },
  };
}
