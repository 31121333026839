import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subPage: null,
  previousSubPage: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSubPage: (state, action) => {
      state.subPage = action.payload;
      state.previousSubPage = null;
    },
    setPreviousSubPage: (state, action) => {
      state.previousSubPage = action.payload;
      state.subPage = null;
    },
  },
});

export const { setSubPage, setPreviousSubPage } = settingsSlice.actions;

export default settingsSlice.reducer;
