import { Styles } from 'react-jss';
import Variables from 'Variables.styles';

const styles: Styles = {
  popover: {
    padding: `${Variables.extraSmall} ${Variables.medium}`,
  },
  menuLinkDisabled: {
    fontSize: '1.8rem',
    padding: '6px 16px',
    display: 'block',
    cursor: 'pointer',
    color: 'grey',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  newLabel: {
    position: 'relative',
    bottom: '10px',
    color: Variables.electricBerry,
    fontSize: '1.3rem',
  },
};

export default styles;
