import ButtonBase from '@material-ui/core/ButtonBase';
import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './Button.styles';
import { ButtonProps } from './ButtonProps';

const useStyles = createUseStyles<string, ButtonProps>(styles);

const Button = ({
  children,
  main,
  primary,
  secondary,
  danger,
  block,
  small,
  medium,
  large,
  className,
  btnLink,
  appTheme = 'CAKE',
  clear,
  outline,
  heroPrimary,
  mediumWide,
  textTransform,
  ...props
}: ButtonProps) => {
  const classes = useStyles({
    small, medium, large, block, appTheme, mediumWide, textTransform, ...props,
  });

  let buttonClassName = classes.borderless;

  if (primary) {
    buttonClassName = classes.primary;
  } else if (main) {
    buttonClassName = classes.main;
  } else if (danger) {
    buttonClassName = classes.danger;
  } else if (secondary) {
    buttonClassName = classes.secondary;
  } else if (btnLink) {
    buttonClassName = classes.btnLink;
  } else if (heroPrimary) {
    buttonClassName = classes.heroPrimary;
  }
  if (clear) {
    buttonClassName = `${buttonClassName} ${classes.clear}`;
  }
  if (outline) {
    buttonClassName = `${buttonClassName} ${classes.outline}`;
  }

  return (
    <ButtonBase className={`${buttonClassName} ${className || ''}`} {...props}>{children}</ButtonBase>
  );
};

export default Button;
