export default function getMomentLangFromi18n(lang) {
  switch (lang) {
    case 'de':
      return 'de';
    case 'cn':
    case 'zh':
    case 'zht':
      return 'zh-hk';
    case 'it':
      return 'it';
    default:
      return 'en';
  }
}
