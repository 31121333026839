import React from 'react';
import { ReferralPromoTemplate } from 'referral/referralType';
import EasterPromoHeroImage from './components/EasterPromoHeroImage';
import EasterPromoHeroRight from './components/EasterPromoHeroRight';
import EasterRulesModalContent from './EasterRulesModalContent';

export const EasterPromoTemplate: ReferralPromoTemplate = {
  heroImageComponent: <EasterPromoHeroImage />,
  heroRightComponent: <EasterPromoHeroRight />,
  rulesModalContent: <EasterRulesModalContent />,
  referrerBonusPerReferralUsd: null,
};
