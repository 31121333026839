import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import sdk from 'sdk';
import { UserQuizInfo } from '@cakedefi/cake-sdk/schema/learnEarn';
import { ModalType } from './components/LessonModal';
import { Stack } from './data/learnEarnContent';
import { getLessonIndexFromId } from './learnEarnUtils';

type SaveProgressModal = 'WELCOME' | 'LESSON' | 'QUIZ' | 'RESULT';

interface SaveProgress {
  lessonIndex: number;
  partIndex: number;
  modal: SaveProgressModal;
  quizAnswer?: string;
}

interface LearnEarnState {
  userQuizzesInfo: UserQuizInfo[];
  isLoading: boolean;

  currentModal: string;
  currentLessonStack: null | Stack;
  currentLessonIndex: number;
  currentLessonPartIndex: number;
  userQuizInput: string;

  userSavedProgressDatabase: Record<string, number>;
  userSavedProgressBrowser: Record<string, SaveProgress>;
}

export const fetchUserQuizzesInfo: any = createAsyncThunk('learnEarn/fetchUserQuizzesInfo', () => sdk.LearnEarnApi.fetchUserQuizzesInfo());

const initialState: LearnEarnState = {
  userQuizzesInfo: [],
  isLoading: false,

  currentModal: ModalType.WELCOME_MODAL,
  currentLessonStack: null,
  currentLessonIndex: 1,
  currentLessonPartIndex: 1,
  userQuizInput: '',

  userSavedProgressDatabase: {},
  userSavedProgressBrowser: {},
};

const learnEarnSlice = createSlice({
  name: 'learnEarn',
  initialState,
  reducers: {
    setLessonStack: (state, action: PayloadAction<Stack>) => {
      state.currentLessonStack = action.payload;
    },
    reloadLearnEarnModal: (state, action: PayloadAction<{ isloadFromLocalStorage: boolean } | undefined>) => {
      state.userQuizInput = initialState.userQuizInput;

      if (!state.currentLessonStack) return;

      const savedProgressDatabase = current(state.userSavedProgressDatabase);
      const savedProgressBrowser = current(state.userSavedProgressBrowser);
      const { id } = state.currentLessonStack;

      // load both 'lesson' & 'part' progress
      const loadFromLocalStorage = () => {
        if (savedProgressBrowser[id]?.modal === ModalType.QUIZ_MODAL) {
          state.currentModal = ModalType.QUIZ_MODAL;
        } else if (savedProgressBrowser[id]?.modal === ModalType.RESULT_MODAL) {
          state.userQuizInput = savedProgressBrowser[id].quizAnswer;
          state.currentModal = ModalType.RESULT_MODAL;
        } else if (savedProgressBrowser[id]?.modal === ModalType.LESSON_MODAL) {
          state.currentModal = ModalType.LESSON_MODAL;
        } else {
          state.currentModal = ModalType.WELCOME_MODAL;
        }

        state.currentLessonIndex = savedProgressBrowser[id].lessonIndex;
        state.currentLessonPartIndex = savedProgressBrowser[id].partIndex;
      };

      // load only 'lesson' progress
      const loadFromDatabase = (indexToLoad: number, loadAsNextLesson: boolean) => {
        if (indexToLoad === 1 && !loadAsNextLesson) {
          state.currentModal = ModalType.WELCOME_MODAL;
        } else {
          state.currentModal = ModalType.LESSON_MODAL;
        }
        const maxLessonsCount = state.currentLessonStack.lessons.length;
        const nextLessonIndex = loadAsNextLesson ? indexToLoad + 1 : indexToLoad;
        if (maxLessonsCount >= nextLessonIndex) {
          state.currentLessonIndex = nextLessonIndex;
        } else if (maxLessonsCount === indexToLoad) { // reach max lesson
          state.currentLessonIndex = indexToLoad;
        }
        state.currentLessonPartIndex = initialState.currentLessonPartIndex;
      };

      const loadInitialState = () => {
        state.currentModal = initialState.currentModal;
        state.currentLessonIndex = initialState.currentLessonIndex;
        state.currentLessonPartIndex = initialState.currentLessonPartIndex;
      };

      const nextQuizQuestionIndex = (() => {
        const userQuizzesInfo = current(state.userQuizzesInfo);
        const userQuizInfo = userQuizzesInfo.find(item => item.id === id);
        const nextQuizQuestionId = userQuizInfo?.nextIncompletedQuizQuestionId;
        if (nextQuizQuestionId) {
          return getLessonIndexFromId(nextQuizQuestionId, userQuizInfo.UserQuiz.quizId);
        }
        return null;
      })();

      const isLocalStorageAlignedWithNextQn = savedProgressBrowser[id] && (!nextQuizQuestionIndex || (savedProgressBrowser[id].lessonIndex === nextQuizQuestionIndex));
      if (action.payload?.isloadFromLocalStorage || isLocalStorageAlignedWithNextQn) {
        loadFromLocalStorage();
      } else if (nextQuizQuestionIndex) { // determined from server, for any unpaid quiz
        loadFromDatabase(nextQuizQuestionIndex, false);
      } else if (savedProgressDatabase[id]) {
        loadFromDatabase(savedProgressDatabase[id], true);
      } else {
        loadInitialState();
      }
    },
    resetQuizState: (state) => {
      state.userQuizInput = initialState.userQuizInput;
      const { id } = state.currentLessonStack;
      if (state.userSavedProgressBrowser[id]) {
        state.userSavedProgressBrowser[id].quizAnswer = null;
      }
    },
    goToModal: (state, action) => {
      state.currentModal = action.payload;
    },
    goToNextLesson: (state) => {
      const lessonData = state.currentLessonStack.lessons.find(lesson => lesson.lessonIndex === state.currentLessonIndex);
      if (state.currentLessonPartIndex < lessonData.parts.length) {
        state.currentLessonPartIndex += 1;
      }
    },
    goToNextLessonAndUpdateProgress: (state) => {
      const lessonData = state.currentLessonStack.lessons.find(lesson => lesson.lessonIndex === state.currentLessonIndex);
      if (state.currentLessonPartIndex < lessonData.parts.length) {
        state.currentLessonPartIndex += 1;
        learnEarnSlice.caseReducers.saveCurrentProgressForLesson(state);
      }
    },
    goToPreviousLesson: (state) => {
      if (state.currentLessonPartIndex > 1) {
        state.currentLessonPartIndex -= 1;
      } else if (state.currentLessonPartIndex === 1) {
        if (state.currentLessonIndex > 1) {
          state.currentLessonIndex -= 1;
          const lessonData = state.currentLessonStack.lessons.find(lesson => lesson.lessonIndex === state.currentLessonIndex);
          state.currentLessonPartIndex = lessonData.parts.length;
          state.currentModal = ModalType.RESULT_MODAL;
        }
      }
    },
    goToPreviousLessonAndUpdateProgress: (state) => {
      if (state.currentLessonPartIndex > 1) {
        state.currentLessonPartIndex -= 1;
        learnEarnSlice.caseReducers.saveCurrentProgressForLesson(state);
      } else if (state.currentLessonPartIndex === 1) {
        if (state.currentLessonIndex > 1) {
          state.currentLessonIndex -= 1;
          const lessonData = state.currentLessonStack.lessons.find(lesson => lesson.lessonIndex === state.currentLessonIndex);
          state.currentLessonPartIndex = lessonData.parts.length;
          state.currentModal = ModalType.RESULT_MODAL;
          learnEarnSlice.caseReducers.saveCurrentProgressForLesson(state);
        }
      }
    },
    setQuizInput: (state, action: PayloadAction<string>) => {
      state.userQuizInput = action.payload;
    },
    saveCurrentProgressForLesson: (state) => {
      state.userSavedProgressBrowser = { ...state.userSavedProgressBrowser, [state.currentLessonStack.id]: { lessonIndex: state.currentLessonIndex, partIndex: state.currentLessonPartIndex, modal: 'LESSON' } };
    },
    saveCurrentProgressForModal: (state, action: PayloadAction<{modal: SaveProgressModal, quizAnswer?: string}>) => {
      const { modal, quizAnswer } = action.payload;
      state.userSavedProgressBrowser = { ...state.userSavedProgressBrowser, [state.currentLessonStack.id]: { lessonIndex: state.currentLessonIndex, partIndex: state.currentLessonPartIndex, modal, quizAnswer } };
    },
    updateUserSavedProgress: (state, action: PayloadAction<SaveProgress>) => {
      state.userSavedProgressBrowser = { ...state.userSavedProgressBrowser, [state.currentLessonStack.id]: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserQuizzesInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserQuizzesInfo.fulfilled, (state, action) => {
      state.isLoading = false;

      state.userQuizzesInfo = action.payload.userQuizzesInfo;
      const { userQuizzesInfo }: { userQuizzesInfo: UserQuizInfo[]} = action.payload;

      const savedProgressDatabase = {};
      userQuizzesInfo.forEach((userQuizInfo) => {
        if (userQuizInfo.UserQuiz?.progress) {
          const storedLessonIndex = getLessonIndexFromId(userQuizInfo.UserQuiz.progress, userQuizInfo.UserQuiz.quizId);
          savedProgressDatabase[userQuizInfo.UserQuiz.quizId] = storedLessonIndex;
        } else {
          savedProgressDatabase[userQuizInfo.UserQuiz.quizId] = 0;
        }
      });
      state.userSavedProgressDatabase = savedProgressDatabase;
    });
    builder.addCase(fetchUserQuizzesInfo.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setLessonStack,
  reloadLearnEarnModal,
  resetQuizState,
  goToModal,
  goToNextLesson,
  goToNextLessonAndUpdateProgress,
  goToPreviousLesson,
  goToPreviousLessonAndUpdateProgress,
  setQuizInput,
  saveCurrentProgressForModal,
  updateUserSavedProgress,
} = learnEarnSlice.actions;

export default learnEarnSlice.reducer;
