export default function getLapisKnowledgeBaseLink(language) {
  switch (language) {
    case 'en':
      return 'https://cake.zendesk.com/hc/en-us/sections/360008118551-Lapis-Service-by-Pool-by-Cake';
    case 'zht':
      return 'https://cake.zendesk.com/hc/zh-cn/sections/360008118551-Lapis-Service-by-Pool-by-Cake';
    default:
      return 'https://cake.zendesk.com/hc/en-us/sections/360008118551-Lapis-Service-by-Pool-by-Cake';
  }
}
