import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import { BANXA_CHECKOUT_PARAMS } from '../../walletService';

export const CRYPTO_TYPE = 'target';
export const FIAT_TYPE = 'source';
export const BASE_RETURN_URL = `${window.location.origin}/wallets?${BANXA_CHECKOUT_PARAMS}=`;

const supportedAgentMapping = {
  safari: isSafari,
  ios: isIOS,
  macos: isMacOs,
};

const isApplePay = paymentMethod => paymentMethod.paymentType === 'WORLDPAYAPPLE';

export const findPaymentMethod = (paymentMethods: any[], paymentMethodId: string) => (paymentMethods?.find(p => p.id === paymentMethodId));

export const findPrice = (fiatPrices, paymentMethodId: string) => (fiatPrices
  ? fiatPrices.prices.find(p => p.paymentMethodId === paymentMethodId)
  : null);

export const isCurrentDeviceSupportPaymentMethod = (supportedAgents) => {
  if (!supportedAgents) {
    return true;
  }
  return supportedAgents.some(
    ({ os, browser }) => supportedAgentMapping[os] && supportedAgentMapping[browser],
  );
};

export const filterApplePayPaymentMethods = (paymentMethods: any[]) => paymentMethods.reduce((filteredPaymentMethods, paymentMethod) => {
  if (isApplePay(paymentMethod)) {
    if (isCurrentDeviceSupportPaymentMethod(paymentMethod.supportedAgents)) {
      return [...filteredPaymentMethods, paymentMethod];
    }
    return filteredPaymentMethods;
  }
  return [...filteredPaymentMethods, paymentMethod];
}, []);

export const filterPricesBasedOnPaymentMethods = (priceResponse, paymentMethods: any[]) => {
  if (!priceResponse || !paymentMethods) {
    return;
  }
  const { prices } = priceResponse;
  const pricesArr = Object.values(prices);
  const filteredPrices = pricesArr.filter(({ paymentMethodId }) => paymentMethods.some(({ id }) => id === paymentMethodId));
  // eslint-disable-next-line consistent-return
  return {
    ...priceResponse,
    prices: filteredPrices,
  };
};

export const getBanxaSourceCurrencyFallback = (preferredCurrency: string) => {
  const banxaSupportedSourceCurrencies = ['USD', 'EUR', 'AUD', 'GBP', 'CAD'];
  return banxaSupportedSourceCurrencies.includes(preferredCurrency)
    ? preferredCurrency
    : 'USD';
};
