import { TwilioApiVerificationRequest, TwilioVerifyToken } from '../../schema/twilio';
import { CakeApiBase } from '../CakeApiBase';

export class TwilioApi extends CakeApiBase {
  async sendVerificationSms(verificationData: TwilioApiVerificationRequest) {
    return this.requestManager.post('kyc/sms/send', {
      verificationData,
    });
  }

  async verifyToken(verificationData: TwilioApiVerificationRequest) {
    return this.requestManager.post<TwilioVerifyToken>('kyc/verify/token', {
      verificationData,
    });
  }
}
