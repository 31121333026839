import { FacebookAuthUrl, SsoAuthPersonData } from '../../schema/sso';
import { CakeApiBase } from '../CakeApiBase';

export class FacebookSsoApi extends CakeApiBase {
  async getAuthoriseUrl() {
    return this.requestManager.get<FacebookAuthUrl>('auth/facebook');
  }

  async getPersonData(query = {}) {
    return this.requestManager.post<SsoAuthPersonData>('/auth/facebook/auth-code', query);
  }

  async loginWithAccessToken(accessToken: string) {
    return this.requestManager.post<SsoAuthPersonData>('auth/facebook/access-token', {
      accessToken,
    });
  }
}
