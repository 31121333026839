import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import isZero from 'utils/isZero';
import { useCakeSelector } from '../app/rootReducer';
import { isObfuscatedSelector } from '../app/selectors/userAppPreferenceSelector';
import { setIsObfuscated } from '../app/userAppPreferenceSlice';
import BigNumberDisplay from '../common/components/BigNumberDisplay/BigNumberDisplay';
import Button from '../common/components/Button/Button';
import InlineLoadingIndicator from '../common/components/InlineLoadingIndicator/InlineLoadingIndicator';
import { fetchBalance, fetchLMBalance } from '../wallet/walletSlice';
import styles from './BalanceBar.styles';
import { balanceInPreferredCurrencySelector } from './balanceInPreferredCurrencySelector';
import UserBalanceTooltips from './components/UserBalanceTooltips';
import ChatIcon from './components/ChatIcon';

const useStyles = createUseStyles<string, { isMobile: boolean }>(styles);

export const updateAuthenticatedLayout = (isLoggedIn: boolean): void => {
  if (isLoggedIn) {
    document.getElementsByTagName('body')[0]?.classList.add('authenticated');
  } else {
    document.getElementsByTagName('body')[0]?.classList.remove('authenticated');
  }
};

const BalanceBar = () => {
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const [isLoadedBalance, setIsLoadedBalance] = useState(false);
  const { details: userDetails } = useCakeSelector(state => state.user);
  const { allocatedAmount, totalAmount, frozenAmount, availableAmount, preferredCurrency } = useCakeSelector(balanceInPreferredCurrencySelector);
  const isObfuscated = useCakeSelector(isObfuscatedSelector);

  useEffect(() => {
    if (userDetails) {
      if (!isLoadedBalance) {
        dispatch(fetchBalance());
        dispatch(fetchLMBalance(true));
        setIsLoadedBalance(true);
      }
      updateAuthenticatedLayout(true);
    } else {
      updateAuthenticatedLayout(false);
    }
  }, [dispatch, userDetails, isLoadedBalance]);

  const onChangeIsObfuscated = (isObfuscate: boolean) => {
    dispatch(setIsObfuscated(!isObfuscate));
  };

  if (!userDetails) return null;

  return (
    <div className={classes.bottomTab}>
      <div className={classes.contentContainer}>
        <div className={classes.assetsContainer}>
          <div className={classes.balanceContainter}>
            <span className={classes.primaryText}><Trans>Total assets worth</Trans></span>
            <span className={classes.secondaryText}>
              {totalAmount
                ? <BigNumberDisplay noTooltip className={classes.bignumberText} isSensitiveNumber currency={preferredCurrency} currencyForAutoDecimals={preferredCurrency} isShortVersion>{totalAmount}</BigNumberDisplay>
                : <InlineLoadingIndicator />
              }
            </span>
          </div>
          <div className={classes.balanceContainter}>
            <span className={classes.primaryText}><Trans>Allocated</Trans></span>
            <span className={classes.secondaryText}>
              {allocatedAmount
                ? <BigNumberDisplay noTooltip className={classes.bignumberText} isSensitiveNumber currency={preferredCurrency} currencyForAutoDecimals={preferredCurrency} isShortVersion>{allocatedAmount}</BigNumberDisplay>
                : <InlineLoadingIndicator />
              }
            </span>
            {
              !isZero(allocatedAmount) && (
                <div className={`${classes.balanceContainter}__tooltips`}>
                  <UserBalanceTooltips />
                </div>
              )
            }
          </div>
          <div className={classes.balanceContainter}>
            <span className={classes.primaryText}><Trans>Fixed</Trans></span>
            <span className={classes.secondaryText}>
              {frozenAmount
                ? <BigNumberDisplay noTooltip className={classes.bignumberText} isSensitiveNumber currency={preferredCurrency} currencyForAutoDecimals={preferredCurrency} isShortVersion>{frozenAmount}</BigNumberDisplay>
                : <InlineLoadingIndicator />
              }
            </span>
          </div>
          <div className={classes.balanceContainter}>
            <span className={classes.primaryText}><Trans>Available</Trans></span>
            <span className={classes.secondaryText}>
              {availableAmount
                ? <BigNumberDisplay noTooltip className={classes.bignumberText} isSensitiveNumber currency={preferredCurrency} currencyForAutoDecimals={preferredCurrency} isShortVersion>{availableAmount}</BigNumberDisplay>
                : <InlineLoadingIndicator />
              }
            </span>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <span className={classes.eyeButton} onClick={() => onChangeIsObfuscated(isObfuscated)}>
            {isObfuscated ? <IoMdEyeOff /> : <IoMdEye />}
          </span>
          <span className={classes.balanceButton}>
            <Link to='/wallets' >
              <Button className={classes.linkText}>
                <Trans>ALL ASSETS</Trans>
              </Button>
            </Link>
          </span>
        </div>
        {/* <div className={classes.vipBadgeMobile}>
          {renderVipBadge()}
        </div> */}
      </div>
      {/* <div className={classes.vipBadgeDesktop}>
        {renderVipBadge()}
      </div> */}
      <div className={classes.chatIconContainer}>
        <ChatIcon />
      </div>
    </div>
  );
};

export default BalanceBar;
