import { createSelector } from '@reduxjs/toolkit';
import { CoinOnSalesSelector } from '../../../../types/onramp/state';
import { getFiatBuyModalState } from '../fiatBuyModalSelector';
import { isWithinCoinOnSalesAmount } from '../utils/isWithinCoinOnSalesAmount';

export const isCryptoAmountValidSelector = createSelector(
  getFiatBuyModalState,
  (fiatBuyState): CoinOnSalesSelector => {
    const {
      fiatSwappable: { coin: fiatSwappableCoinId, amount },
    } = fiatBuyState;
    if (fiatSwappableCoinId) {
      return {
        isCryptoAmountValid: isWithinCoinOnSalesAmount(
          !!fiatSwappableCoinId,
          amount,
        ),
      };
    }
    return {
      isCryptoAmountValid: true,
    };
  },
);
