import Variables, { getMaxWidthBreakPoint } from '../../../Variables.styles';

export default {
  container: {
    display: 'flex',
  },
  input: {
    borderRadius: Variables.small,
    border: '1px solid transparent',
    boxShadow: ({ isInvalid }) => (isInvalid ? `0 0 0 2px ${Variables.redInvalidControl}` : `0 0 0 1px ${Variables.gray200}`),
    outline: 'none',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    padding: '12px 16px',
    width: Variables.extraLarge,
    textAlign: 'center',
    WebkitAppearance: 'none',
    '&:not(:first-child)': {
      marginLeft: Variables.medium,
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${Variables.electricBerry}`,
    },
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    input: {
      width: '44px',
      fontSize: '1.6rem',
      padding: '10px',
      '&:not(:first-child)': {
        marginLeft: Variables.small,
      },
    },
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.xs)]: {
    input: {
      width: '40px',
      fontSize: '1.6rem',
      padding: '10px 6px',
      '&:not(:first-child)': {
        marginLeft: Variables.small,
      },
    },
  },
};
