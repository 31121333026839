import React from 'react';
import ReactSvg from 'react-svg';
import { createUseStyles } from 'react-jss';
import kycUnverified from 'assets/svg/kyc/kyc-unverified-icon.svg';
import kycVerified from 'assets/svg/kyc/kyc-verified-icon.svg';
import kycRejected from 'assets/svg/kyc/kyc-rejected-icon.svg';
import kycInReview from 'assets/svg/kyc/kyc-in-review-icon.svg';
import Variables from 'Variables.styles';
import { Trans } from 'react-i18next';
import { useCakeSelector } from 'app/rootReducer';
import { userDetailSelector } from 'user/userSelector';

const useStyles = createUseStyles<string, { badgeDetails: any, isSemiTransparent: boolean, isWhiteBg: boolean }>({
  kycStatusIconContainer: ({ badgeDetails, isSemiTransparent, isWhiteBg }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: Variables.small,
    background: isWhiteBg ? 'white' : badgeDetails.backgroundColor,
    color: badgeDetails.textColor,
    padding: Variables.small,
    paddingRight: '16px',
    borderRadius: Variables.large,
    width: 'fit-content',
    opacity: (isSemiTransparent ? 0.5 : 1),
  }),
  title: {
    fontSize: Variables.fontSize.base,
    fontWeight: Variables.fontWeight.bold,
    lineHeight: '24px',
  },
  icon: {
    marginTop: '-1px',
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '24px',
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
});

export enum KycStatusType {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  SCREENING = 'SCREENING',
  REJECTED = 'REJECTED',
  UNVERIFIED = 'UNVERIFIED',
  BLACKLISTED = 'BLACKLISTED',
  FINALLYREJECTED = 'FINALLYREJECTED',
}

const KycStatusBadge = ({ status, isSemiTransparent = false, isWhiteBg = false }) => {
  const details = useCakeSelector(userDetailSelector);
  const { kycProvider } = details;
  const badgeDetails = kycStatusBadgeDetails(status, kycProvider);
  const classes = useStyles({ badgeDetails, isSemiTransparent, isWhiteBg });

  return (
    <div className={classes.kycStatusIconContainer}>
      <ReactSvg src={badgeDetails.icon} className={classes.icon}/>
      <div className={classes.title}>
        <Trans>{badgeDetails.title}</Trans>
      </div>
    </div>
  );
};

const kycStatusBadgeDetails = (status: KycStatusType, kycProvider: 'SUMSUB' | 'MYINFOSG' | null) => {
  if (status === KycStatusType.APPROVED) {
    return {
      title: 'VERIFIED',
      icon: kycVerified,
      textColor: '#429E5C',
      backgroundColor: '#D1ECD8',
    };
  }

  if (status === KycStatusType.REJECTED || status === KycStatusType.BLACKLISTED || status === KycStatusType.FINALLYREJECTED) {
    return {
      title: 'REJECTED',
      icon: kycRejected,
      textColor: '#C43636',
      backgroundColor: '#F9CFCF',
    };
  }

  if ((status === KycStatusType.PENDING && kycProvider !== 'MYINFOSG') || status === KycStatusType.SCREENING) {
    return {
      title: 'IN REVIEW',
      icon: kycInReview,
      textColor: Variables.black,
      backgroundColor: '#F4EAC6',
    };
  }

  return {
    title: 'UNVERIFIED',
    icon: kycUnverified,
    textColor: Variables.black,
    backgroundColor: Variables.lightElBerry,
  };
};

export default KycStatusBadge;
