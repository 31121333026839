import React, { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import InfoIconPopover from 'common/components/InfoIconPopover/InfoIconPopover';
import Card from 'common/components/Card/Card';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import Switch from 'views/components/Switch';
import { ModalContext } from '../../modals/ModalContext';
import ReferralSettingUpdateConfirmationModal from '../../referral/components/ReferralSetting/ReferralSettingUpdateConfirmationModal';

const useStyles = createUseStyles({
  cardContainer: { width: '100%' },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
    padding: '1em',
    borderBottom: Variables.primaryBorder,
  },
  revealContainer: {
    padding: '0.5em',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  revealText: { fontWeight: 500, fontSize: '14px', textAlign: 'left', marginLeft: '10px' },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    revealContainer: {
      justifyContent: 'flex-start',
    },
    revealText: {
      textAlign: 'left',
      fontSize: Variables.fontSize.small,
      marginLeft: '12px',
    },
  },
  referralCode: {
    color: Variables.gray400,
  },
});

export default function ReferralUI({
  showReferralCodeMessage,
  revealInfoToReferrer,
  sessionReferralCode,
  referralCodeText,
  onReveal,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { openModal, closeModal } = useContext(ModalContext);

  const turnOnPreferenceRevealInfoToReferrer = useCallback(async ({ onAcceptFunc }) => {
    openModal({
      component: (
        <ReferralSettingUpdateConfirmationModal
          closeModal={closeModal}
          onAccept={onAcceptFunc}
        />
      ),
    });
  }, [closeModal, openModal]);

  const switchOnChange = (event) => {
    if (revealInfoToReferrer === false) {
      turnOnPreferenceRevealInfoToReferrer({
        onAcceptFunc: () => {
          onReveal(event);
          closeModal();
        },
      });
    } else {
      onReveal(event);
    }
  };

  return <>
    <div className="input-group margin-top-24 margin-bottom-24 align-center" style={referralCodeText}>
      <Card className={classes.cardContainer}>
        {showReferralCodeMessage && <div className={classes.container}>
          <div>
            <Trans>Referral code has been applied</Trans>
          </div>
          <div className={classes.referralCode}>
            {sessionReferralCode}
          </div>
        </div>}
        <div className={classes.revealContainer}>
          <div>
            <Switch
              isActive={revealInfoToReferrer}
              onChange={event => switchOnChange(event)}
            >
              <span className={classes.revealText}>
                <Trans>Reveal my contact information to my referrer</Trans>
              </span>
            </Switch>
          </div>
          <div>
            <InfoIconPopover popoverMessage={t('Your shown contact information will be your name, email address, and registration status.')}/>
          </div>
        </div>
      </Card>
    </div>
  </>;
}
