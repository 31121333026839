import { CashflowPeriodType, CashflowRewards } from '../../schema';
import { WalletPage } from '../../schema/custom';
import { CakeApiBase } from '../CakeApiBase';

export class WalletPageApi extends CakeApiBase {
  async walletPage() {
    return this.requestManager.get<WalletPage>('walletPage');
  }

  async cashflowRewards(period: CashflowPeriodType, numOfPeriods?: number) {
    return this.requestManager.post<CashflowRewards>('/cashflow-charts/rewards', {
      period,
      numOfPeriods,
    });
  }
}
