import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { HeaderLink } from 'types/header/header-link';
import Variables from 'Variables.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCakeSelector } from '../../app/rootReducer';
import { getCanShowFireBannersSelector, getFireCampaignDataLoadStatus } from './fireCampaignSelector';
import getCakeDeFiWebsiteBaseURL from '../../utils/getCakeDeFiWebsiteBaseURL';
import { fetchFireCampaign, fetchFireCampaignAuthenticated } from './fireCampaignSlice';

const useStyles = createUseStyles({
  outerWrapperDesktop: {
    display: 'inline-block',
    width: '128px',
    height: '18px',
    paddingBottom: '22px',
    paddingLeft: '16px',
  },
  wrapperDesktop: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
  },
  wrapperMobile: {
    display: 'inline-block',
    height: '50px',
    cursor: 'pointer',
    padding: '6px 16px',
  },
  container: {
    background: 'linear-gradient(0deg, #6242FF, #6242FF), linear-gradient(90deg, #6242FF 0%, #7776FF 100%)',
    border: '2px solid #8A73FF',
    borderRadius: '100px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Variables.small,
    width: '128px',
    height: '36px',
  },
  text: {
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
});

const FireCampaignHeaderLink = ({ index, isMobile = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details: userDetails } = useSelector((state: any) => state.user);

  const headerLink = new HeaderLink(
    '\u{1F525} F.I.R.E.',
    `${getCakeDeFiWebsiteBaseURL()}/fire`,
    null,
    null,
    null,
    null,
    true,
  );
  const { text, link } = headerLink;
  const canShowFireBanners = useCakeSelector(getCanShowFireBannersSelector);
  const fireCampaignDataLoadStatus = useCakeSelector(getFireCampaignDataLoadStatus);

  useEffect(() => {
    if (fireCampaignDataLoadStatus === 'UNLOADED') {
      if (userDetails) {
        dispatch(fetchFireCampaignAuthenticated());
      } else {
        dispatch(fetchFireCampaign());
      }
    }
  }, [dispatch, fireCampaignDataLoadStatus, userDetails]);

  if (!canShowFireBanners) {
    return null;
  }

  const onClickButton = () => {
    window.open(link, '_blank');
  };

  return (
    <div className={isMobile ? null : classes.outerWrapperDesktop}>
      <div
        className={isMobile ? classes.wrapperMobile : classes.wrapperDesktop}
        key={`header-link-${index}`}
        onClick={onClickButton}
      >
        <div className={classes.container}>
          <div className={classes.text}>
            <Trans>{text}</Trans>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FireCampaignHeaderLink;
