import {
  IHasIncomingDepositsResponse,
  IIncomingDeposit,
  IUpdateOriginatorInfoRequest,
} from '../../schema/incoming-deposits';
import { CakeApiBase } from '../CakeApiBase';

export class IncomingDepositsAPI extends CakeApiBase {
  async hasIncomingDeposits() {
    return this.requestManager.get<IHasIncomingDepositsResponse>(
      '/wallets/deposit/has-deposits-pending-information',
    );
  }

  async getIncomingDeposits() {
    return this.requestManager.get<IIncomingDeposit[]>(
      '/wallets/deposit/deposits-pending-information',
    );
  }

  async updateOriginatorInfo({
    depositId,
    originatorInformation,
  }: IUpdateOriginatorInfoRequest) {
    return this.requestManager.put(
      `/wallets/deposit/originator-information/${depositId}`,
      { originatorInformation },
    );
  }
}
