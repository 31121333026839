import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from '../../sdk';
import { StateStatus } from '../../types/common/state';
import { WhitelistAddressSlice } from '../../types/whitelist-address/whitelist-address-slice';

const initialState: WhitelistAddressSlice = {
  whitelists: {
    status: StateStatus.STARTED,
    data: null,
    error: null,
  },
  platforms: {
    status: StateStatus.STARTED,
    data: null,
    error: null,
  },
};

export const fetchWhitelistAddress = createAsyncThunk(
  'whitelistAddresses/fetchAddresses',
  () => sdk.WhitelistedAddressApi.getAll(),
);

export const fetchPlatforms = createAsyncThunk(
  'whitelistAddresses/fetchPlatforms',
  () => sdk.PlatformApi.getPlatforms(),
);

const whitelistAddressesSlice = createSlice({
  name: 'whitelistAddresses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWhitelistAddress.pending, (state) => {
      state.whitelists.status = StateStatus.LOADING;
      state.whitelists.error = null;
    });

    builder.addCase(fetchWhitelistAddress.fulfilled, (state, action) => {
      state.whitelists.data = action.payload;
      state.whitelists.status = StateStatus.FINISHED;
      state.whitelists.error = null;
    });

    builder.addCase(fetchWhitelistAddress.rejected, (state, action) => {
      state.whitelists.error = action.error;
      state.whitelists.status = StateStatus.ERROR;
    });

    builder.addCase(fetchPlatforms.pending, (state) => {
      state.platforms.status = StateStatus.LOADING;
      state.platforms.error = null;
    });

    builder.addCase(fetchPlatforms.fulfilled, (state, action) => {
      state.platforms.data = action.payload;
      state.platforms.status = StateStatus.FINISHED;
      state.platforms.error = null;
    });

    builder.addCase(fetchPlatforms.rejected, (state, action) => {
      state.platforms.error = action.error;
      state.platforms.status = StateStatus.ERROR;
    });
  },
});

export default whitelistAddressesSlice.reducer;
