import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import styles from './DetailedModal.styles';

const useStyles = createUseStyles(styles);

export default function DetailedModal({ isThirdAnniversaryPromoActive }) {
  const classes = useStyles();

  if (isThirdAnniversaryPromoActive) {
    return (
      <>
        <div className={classes.contentMini}>
          <h3 className={classes.paragraphHeader}>
            <Trans>How it works</Trans>
          </h3>
          <p className={classes.paragraph}>
            <Trans>
            When you sign up for a Bake account between 07.06.2022 and 30.06.2022 and meet the following qualifications, you will have a chance to win $3,333 in DFI! The lucky winner will be drawn on the 1st of July 2022.
            </Trans>
          </p>
          <p className={classes.paragraph} style={{ marginBottom: 8 }}>
            <Trans>These are the criteria:</Trans>
          </p>
          <p className={classes.paragraph}>
            <Trans>
            When you register and invest $50 or more (in any coin) into our Lending, Staking Freezer or Liquidity Mining Freezer product, you will automatically be eligible for the $3,333 lucky draw.
            </Trans>
          </p>
          <p className={classes.paragraph}>
            <Trans>
            The bonus will automatically be credited to your account and invested into the 1-month Staking Freezer. DFI price will be based on the rate on 30.06.22 10:00 UTC
            </Trans>
          </p>
          <p className={classes.paragraph}>
            <Trans>
            More specifically, these are the options available to qualify:
            </Trans>
          </p>
          <ul className={classes.lastParagraph}>
            <li><Trans>Enter a Lending batch</Trans></li>
            <li><Trans>Enter the Liquidity Mining Freezer (for at least 1 month)</Trans></li>
            <li><Trans>Enter the Staking Freezer (for at least 1 month)</Trans></li>
          </ul>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.contentMini}>
        <h3 className={classes.paragraphHeader}>
          <Trans>WELCOME BONUS</Trans>
        </h3>
        <p className={classes.paragraph} style={{ marginBottom: 8 }}>
          <Trans>Receive US$30 worth of bonus in DFI upon completion of the steps below:</Trans>
        </p>
        <ol>
          <li>
            <Trans>
              Deposit at least US$50 into your Bake account. It doesn’t matter which coin you use, as long as the minimum amount is at least US$50.
            </Trans>
          </li>
          <li>
            <Trans>
              Next, allocate at least US$50 into Staking or Liquidity Mining.
            </Trans>
          </li>
          <li>
            <Trans>
            Minimum vesting period: In case you use our Staking or Liquidity Mining product, then you also have to allocate the Staking or Liquidity Mining shares into our Freezer. The minimum tenure is 1 month.
            </Trans>
          </li>
          <li>
            <Trans>And that's it, you're all set.</Trans>
          </li>
        </ol>

        <p className={classes.lastParagraph}>
          <Trans>
            Upon doing so, the above mentioned DFI bonus will be automatically credited to your account and be locked in our Freezer for 180 days.
            It will generate staking rewards during this period and while remaining locked in the Freezer.
          </Trans>
        </p>
      </div>
    </>
  );
}
