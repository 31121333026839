import BigNumber from 'bignumber.js';

export default function calculateTargetBatchReturnMultiplier(durationInDays: number, returnPerYear: number): number {
  const exponent = Math.floor(365 / durationInDays);

  // ori: new BigNumber((((new BigNumber(returnPerYear).toNumber()) + 1) ** (1 / exponent)) - 1);
  // should have test case
  return new BigNumber(returnPerYear).plus(1).pow(new BigNumber(1).div(exponent)).minus(1)
    .toNumber();
}
