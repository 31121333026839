/* eslint-disable no-await-in-loop */
import BigNumber from 'bignumber.js';
import { CakeApiBase } from '../CakeApiBase';
import { AutoCompoundingSettingCounts, CancelEntry, EnterSprod, OverrideAllActiveAutocompoundSettingsRequest, SprodBatchSettings } from '../../schema/sprod';
import { BigNumberConstructable } from '../../lib';
import { MinimumAmountInUsd } from '../../schema';

export class SprodApi extends CakeApiBase {
  // NOT IN USE and bad practice of looping nested API within 1 API func
  // async listSprods() {
  //   return this.sdk.get('sprods');
  // }

  // async listBatches(user, sprod, stati = [], limit = null, offset = null) {
  //   let queryStr = 'sprods/sprod-batches/?';

  //   if (sprod) {
  //     queryStr = queryStr.concat(`&sprodId=${sprod.id}`);
  //   }

  //   if (limit) {
  //     queryStr = queryStr.concat(`&limit=${limit}`);
  //   }

  //   if (offset) {
  //     queryStr = queryStr.concat(`&offset=${offset}`);
  //   }

  //   if (stati) {
  //     queryStr = queryStr.concat(`&stati=${JSON.stringify(stati)}`);
  //   }

  //   const batches = await this.sdk.get(queryStr);
  //   const extendedBatches = [];

  //   // should not loop more nested API call within 1 API call function
  //   // the UI may look freeze for user with large volume of data
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const batch of batches) {
  //     const extendedBatch = await this.attachExtendedInformationOnBatch(batch, sprod, user);
  //     extendedBatches.push(extendedBatch);
  //   }
  //   return extendedBatches;
  // }

  // async attachExtendedInformationOnBatch(batch, sprod, user) {
  //   if (sprod) {
  //     batch.sprod = sprod;
  //   }

  //   if (user) {
  //     batch.userEntry = await this.getBatchUserEntry(batch.id, batch.status);
  //     try {
  //       if (batch.userEntry !== '0') {
  //         const settings = await this.listBatchSettings(batch.id);
  //         if (settings.compoundOnSettle) {
  //           batch.autoCompoundOption = settings.compoundOnSettle;
  //         }
  //       }
  //     } catch (err) {
  //       batch.autoCompoundOption = null;
  //     }
  //   }

  //   if (batch.status !== 'SELLING') {
  //     if (user) {
  //       batch.userReturn = batch.status === 'SETTLED' && !(new BigNumber(batch.userEntry).isEqualTo(0)) ? await getBatchUserReturn(batch.id) : null;
  //       batch.additionalDfiReward = batch.status === 'SETTLED' && !(new BigNumber(batch.userEntry).isEqualTo(0)) ? await getAdditionalDfiReward(batch.id) : null;
  //     }

  //     if (batch.status !== 'SETTLED' && batch.status !== 'CANCELED') {
  //       batch.progress = this.sdk.util.calculateBatchProgress(batch.saleClosesOn, batch.maturesOn);
  //     }
  //   }

  //   return batch;
  // }

  // async getCountOfBatches(sprod, stati) {
  //   let queryStr = 'sprods/sprod-batch/count?';

  //   if (sprod) {
  //     queryStr = queryStr.concat(`&sprodId=${sprod.id}`);
  //   }
  //   if (stati) {
  //     queryStr = queryStr.concat(`&stati=${JSON.stringify(stati)}`);
  //   }
  //   return this.sdk.get(queryStr);
  // }

  async listActivities(sprodBatchId = null) {
    if (!sprodBatchId) this.requestManager.get('sprods/sprod-activities');
    return this.requestManager.get(`sprods/sprod-activities/?sprodBatchId=${sprodBatchId}`);
  }

  async listBatchSettings(sprodBatchId: string) {
    return this.requestManager.get<SprodBatchSettings>(`sprods/sprod-batch/settings/?sprodBatchId=${sprodBatchId}`);
  }

  async enterSprod(amount: BigNumberConstructable, sprodBatchId: string) {
    return this.requestManager.post<EnterSprod>('sprods/sprod-batch/enter', {
      amount,
      sprodBatchId,
    });
  }

  async getSprodBySlug(sprodSlug) {
    return this.requestManager.get(`sprods/sprod/${sprodSlug}`);
  }

  async updateBatchSettings(sprodBatchId, { autoCompound }) {
    await this.requestManager.post(`sprods/sprod-batch/settings/?sprodBatchId=${sprodBatchId}&autoCompound=${autoCompound}`);
  }

  async getBatchUserEntry(sprodBatchId, batchStatus): Promise<string> {
    let transactionStatus = '';
    if (batchStatus === 'SELLING') {
      transactionStatus = 'PENDING';
    } else if (batchStatus === 'ONGOING' || batchStatus === 'MATURED') {
      transactionStatus = 'LOCKED';
    } else if (batchStatus === 'SETTLED') {
      transactionStatus = 'CONFIRMED';
    } else if (batchStatus === 'CANCELED') {
      transactionStatus = 'CANCELLED';
    }

    if (!transactionStatus) return null;

    const response = await (this.requestManager.get('sprods/sprod-batch/entry-amount', {
      sprodBatchId,
      transactionStatus,
    }) as Promise<{ amount: string }>);

    return new BigNumber(response.amount).toFixed();
  }

  async getBatchUserReturn(sprodBatchId) {
    const response = await (this.requestManager.get('sprods/sprod-batch/return', {
      sprodBatchId,
    }) as Promise<{ amount: string }>);

    return response.amount;
  }

  async getAdditionalDfiReward(sprodBatchId) {
    const response = await (this.requestManager.get('sprods/sprod-batch/dfi-return', {
      sprodBatchId,
    }) as Promise<{ amount: string }>);

    return response.amount;
  }

  async cancelEntry(body = {}) {
    return this.requestManager.post<CancelEntry>('sprods/sprod-batch/cancel-entry', body);
  }

  async getStartingAmount(query = {}) {
    return this.requestManager.get('sprods/sprod-batch/starting-amount', query);
  }

  async getEstimatedAutocompounds(sprodBatchId): Promise<string | null> {
    const response = await (this.requestManager.get(`sprods/sprod-batch/${sprodBatchId}/autocompounds`) as Promise<{
      estimatedAutocompounds: string;
    }>);
    return response.estimatedAutocompounds ? new BigNumber(response.estimatedAutocompounds).toFixed() : null;
  }

  async overrideAllActiveAutocompoundSettings(body: OverrideAllActiveAutocompoundSettingsRequest) {
    return this.requestManager.post('sprods/sprod-batch/override-settings', body);
  }

  async getAutocompoundSettingCounts() {
    return this.requestManager.get<AutoCompoundingSettingCounts>('sprods/sprod-batch/autocompound-setting-counts');
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/sprods/sprod-batch/minimum');
  }
}
