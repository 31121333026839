export const FEE_TYPE = {
  PERCENTAGE: 'percentage',
  FIXED: 'fixed',
};

export const calculateTotalFee = (payment, type = FEE_TYPE.PERCENTAGE) => {
  if (!payment) {
    return 0;
  }

  return payment.transactionFees.reduce(
    (totalFee, transaction) => totalFee
      + transaction.fees.reduce((transactionFee, fee) => {
        if (fee.type === type) {
          return transactionFee + fee.amount;
        }
        return transactionFee;
      }, 0),
    0,
  );
};

export const calculatePaymentMethodFee = paymentMethod => ({
  ...paymentMethod,
  percentageFee: calculateTotalFee(paymentMethod),
  fixedFee: calculateTotalFee(paymentMethod, FEE_TYPE.FIXED),
});
