import { ThunkApiConfig } from 'app/rootReducer';
import sdk from 'sdk';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DcaReferralCampaignLeaderboard, DcaReferralCampaignUserReferral, DcaReferralCampaignUserTickets, DcaReferralCampaignTotalUsers } from '@cakedefi/cake-sdk';
import { DcaReferralState } from './dcaReferralTypes';

const initialState: DcaReferralState = {
  state: 'UNLOADED',
  error: null,
  userRaffleTickets: 0,
  bonusReferralTickets: 0,
  totalRaffleUsers: 0,
  totalNumberOfSubscribedReferralsForCurrentMonth: 0,
  totalPaidCommissionsFromPreviousMonth: '0',
  totalUnpaidCommissionForCurrentMonth: '0',
  usersLeaderboardByReferralCounts: [],
  usersLeaderboardByReferralAmount: [],
};

export const fetchUserTickets = createAsyncThunk<DcaReferralCampaignUserTickets, null, ThunkApiConfig>(
  'dca/get-user-tickets',
  async () => {
    const result = await sdk.DcaApi.getReferralCampaignUserTickets();
    return result;
  },
);

export const fetchTotalUsers = createAsyncThunk<DcaReferralCampaignTotalUsers, null, ThunkApiConfig>(
  'dca/get-total-users',
  async () => {
    const result = await sdk.DcaApi.getReferralCampaignTotalUsers();
    return result;
  },
);

export const fetchUserReferralInfo = createAsyncThunk<DcaReferralCampaignUserReferral, null, ThunkApiConfig>(
  'dca/get-referrals-info',
  async () => {
    const result = await sdk.DcaApi.getReferralCampaignUserReferrals();
    return result;
  },
);

export const fetchDcaCampaignLeaderboard = createAsyncThunk<DcaReferralCampaignLeaderboard, null, ThunkApiConfig>(
  'dca/get-leader-board',
  async () => {
    const result = await sdk.DcaApi.getReferralCampaignLeaderboard();
    return result;
  },
);

const dcaReferralSlice = createSlice({
  name: 'DcaReferralCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTickets.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchUserTickets.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.userRaffleTickets = action.payload?.userRaffleTickets ?? 0;
      state.bonusReferralTickets = action.payload?.bonusReferralTickets ?? 0;
      state.error = null;
    });
    builder.addCase(fetchUserTickets.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchTotalUsers.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchTotalUsers.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.totalRaffleUsers = action.payload?.totalUniqueDcaUsers ?? 0;
      state.error = null;
    });
    builder.addCase(fetchTotalUsers.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchUserReferralInfo.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchUserReferralInfo.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.totalNumberOfSubscribedReferralsForCurrentMonth = action.payload?.totalNumberOfSubscribedReferralsForCurrentMonth ?? 0;
      state.totalPaidCommissionsFromPreviousMonth = action.payload?.totalPaidCommissionsFromPreviousMonth ?? '0';
      state.totalUnpaidCommissionForCurrentMonth = action.payload?.totalUnpaidCommissionForCurrentMonth ?? '0';
      state.error = null;
    });
    builder.addCase(fetchUserReferralInfo.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });

    builder.addCase(fetchDcaCampaignLeaderboard.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchDcaCampaignLeaderboard.fulfilled, (state, action) => {
      state.state = 'LOADED';
      // format into LeaderboardRow type where value key stores referralCount and amountInUsd
      state.usersLeaderboardByReferralCounts = (action.payload?.usersLeaderboardByReferralCounts ?? []).map(item => ({
        referralId: item.referralId ?? '',
        value: item.referralCount ? String(item.referralCount) : '',
        boost: item.boost ? String(item.boost) : '',
      }));
      state.usersLeaderboardByReferralAmount = (action.payload?.usersLeaderboardByReferralAmount ?? []).map(item => ({
        referralId: item.referralId ?? '',
        value: item.amountInUsd ? String(item.amountInUsd) : '',
        boost: item.boost ? String(item.boost) : '',
      }));
      state.error = null;
    });
    builder.addCase(fetchDcaCampaignLeaderboard.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export default dcaReferralSlice.reducer;
