import { CakeBanxaPaymentMethodRequest, CakeBanxaPaymentMethods } from '../../schema/banxa/banxa-payment-methods';
import { BanxaFiats, BanxaPrice, BanxaPriceRequest, CakeBanxaCheckoutDetails, CakeBanxaCreateOrder, CakeBanxaCreateOrderParams } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class BanxaApi extends CakeApiBase {
  async getFiats() {
    return this.requestManager.get<BanxaFiats>('/banxa/fiats');
  }

  async getPrices(quotationParams: BanxaPriceRequest) {
    return this.requestManager.get<BanxaPrice>('/banxa/prices', quotationParams);
  }

  async getPaymentMethods(query: CakeBanxaPaymentMethodRequest) {
    return this.requestManager.get<CakeBanxaPaymentMethods>('/banxa/payment-methods', query);
  }

  async createOrder(body: CakeBanxaCreateOrderParams) {
    return this.requestManager.post<CakeBanxaCreateOrder>('/banxa/orders', body);
  }

  async updateOrder(body: CakeBanxaCheckoutDetails) {
    return this.requestManager.put('/banxa/orders', body);
  }

  async cancelOrder(orderId: string) {
    return this.requestManager.put(`/banxa/orders/cancel/${orderId}`);
  }
}
