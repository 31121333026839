import { RootState } from '../../app/rootReducer';

export const getCanShowVisualsSelector = (state: RootState) => state.Bake4TheWinCampaign.isVisualsStillActive;
export const getCampaignDataLoadStatus = (state: RootState) => state.Bake4TheWinCampaign.state;
export const getCampaignDataError = (state: RootState) => state.Bake4TheWinCampaign.error;
export const getCampaignExpiryDate = (state: RootState) => state.Bake4TheWinCampaign.endDate;
export const getIsCampaignStillActive = (state: RootState) => state.Bake4TheWinCampaign.isCampaignStillActive;
export const getNumberOfTickets = (state: RootState) => state.Bake4TheWinCampaign.numberOfTickets;
export const getUnOpenedBoxCount = (state: RootState) => state.Bake4TheWinCampaign.unopenedRewards;
export const getWinnings = (state: RootState) => state.Bake4TheWinCampaign.unBoxedRewards;
