import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import Button from '../../../../common/components/Button/Button';
import getCakeDeFiWebsiteBaseURL from '../../../../utils/getCakeDeFiWebsiteBaseURL';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12rem',
    marginBottom: '12rem',
    '& > a': {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    margin: `0 0 ${Variables.medium} 0`,
  },
  learnMore: {
    textTransform: 'uppercase',
    letterSpacing: '0.86px',
    fontWeight: 500,
  },
});

const VerifyIdentity = ({ gotoKyc, gotoWallets }) => {
  const classes = useStyles();

  return (
    <article className="floating">
      <div>
        <h1 className={classes.title}>
          <Trans>Verify Identity</Trans>
        </h1>
        <div>
          <Trans>
            Regulations require us to verify the identity of customers.
          </Trans>
          <Trans>
            Your identity data will be stored securely and will not shared with any third parties.
          </Trans>
        </div>
        <div className="mt-5 text-center">
          <a href={`${getCakeDeFiWebsiteBaseURL()}/privacy-policy`} target="_blank" className={classes.learnMore} rel="noreferrer">
            <Trans>Learn More About Bake Data</Trans><br/><Trans>Privacy And Security</Trans>
          </a>
        </div>
        <div className="mt-5 text-center">
          <Button
            className="mt-5"
            primary
            block
            type="button"
            onClick={() => gotoKyc()}
          >
            <Trans>Begin Verification</Trans>
          </Button>
          <Button
            className="mt-5"
            primary
            block
            clear
            type="button"
            onClick={() => gotoWallets()}
            style={{ margin: '0 auto' }}
          >
            <Trans>I'll do it later</Trans>
          </Button>
        </div>
      </div>
    </article>
  );
};

export default VerifyIdentity;
