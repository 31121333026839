import React, { Component } from 'react';

import ClickableElement from './ClickableElement';
import Icon from './Icon';

export default class VideoThumbnail extends Component {
  render() {
    const { src, alt, onClick } = this.props;

    return (
      <ClickableElement onClick={onClick}>
        <div className="video-thumbnail">
          <img src={src} alt={alt} />
          <div className="video-thumbnail-icon-wrapper">
            <Icon containerClassName="video-thumbnail-icon" name="playCircle" svgStyle={{ width: '25px', height: '25px', fill: 'white' }}/>
          </div>
        </div>
      </ClickableElement>
    );
  }
}
