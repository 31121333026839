import { createSelector } from '@reduxjs/toolkit';
import { StateStatus } from '@cakedefi/cake-sdk';
import { RootState } from 'app/rootReducer';
import moment from 'moment';
import { ElevenElevenPromoTemplate } from './ElevenElevenPromo/ElevenElevenPromoTemplate';
import { TigerLunarPromoTemplate } from './TigerLunar/TigerLunarPromoTemplate';
import { EasterPromoTemplate } from './EasterPromo/EasterPromoTemplate';
import { ReferralPromoType } from './referralType';
import { getEasterCampaignReferralBonusEarnedUsd } from './EasterPromo/EasterUtils';

const promotionCampaignSelector = (state: RootState) => state.promotionCampaign;

export const promotionCampaignDataSelector = createSelector(
  promotionCampaignSelector,
  (s) => s.data,
);

export const getPromotionActiveDates = createSelector(
  promotionCampaignDataSelector,
  ({ promoBonusActiveFrom, promoBonusActiveUntil }) => (
    {
      promoBonusActiveFrom,
      promoBonusActiveUntil,
    }
  ),
);

export const getPromotionTemplate = createSelector(
  promotionCampaignDataSelector,
  (promoData) => {
    const { activePromotion } = promoData;
    switch (activePromotion) {
      case ReferralPromoType.ELEVEN_ELVEN:
        return ElevenElevenPromoTemplate;
      case ReferralPromoType.TIGER_PROMO:
        return TigerLunarPromoTemplate;
      case ReferralPromoType.EASTER_PROMO:
        return EasterPromoTemplate;
      default:
        return {
          heroImageComponent: null,
          heroRightComponent: null,
          rulesModalContent: null,
          referrerBonusPerReferralUsd: null,
        };
    }
  },
);

export const referralPromoVmSelector = createSelector(
  promotionCampaignSelector,
  getPromotionTemplate,
  ({ data, status }, { referrerBonusPerReferralUsd }) => {
    const referralCount = Number(data?.confirmedPromotionReferralsCount || 0);
    return {
      confirmedPromotionReferralsCount: referralCount,
      totalEarned: (referralCount * referrerBonusPerReferralUsd).toFixed(2),
      isLoading: status === StateStatus.LOADING,
    };
  },
);

export const easterReferralPromoVmSelector = createSelector(
  promotionCampaignSelector,
  ({ data, status }) => {
    const referralCount = Number(data?.confirmedPromotionReferralsCount || 0);
    const bonusEarned = getEasterCampaignReferralBonusEarnedUsd(referralCount).toFixed(2);
    return {
      confirmedPromotionReferralsCount: referralCount,
      totalEarned: bonusEarned,
      isLoading: status === StateStatus.LOADING,
    };
  },
);

export const getIsPromotionPeriodSelector = createSelector(
  promotionCampaignDataSelector,
  ({ activePromotion, promotionRetiredOn, promotionVisibleOn }) => {
    if (!activePromotion || !promotionRetiredOn || !promotionVisibleOn) {
      return null;
    }
    return !!activePromotion && moment().isBetween(promotionVisibleOn, promotionRetiredOn);
  },
);

export const getIsStakingPromotionPeriodSelector = createSelector(
  promotionCampaignDataSelector,
  ({ activeStakingPromotion, stakingPromoBonusActiveUntil, stakingPromoBonusActiveFrom }) => {
    if (!activeStakingPromotion || !stakingPromoBonusActiveUntil || !stakingPromoBonusActiveFrom) {
      return null;
    }
    return !!activeStakingPromotion && moment().isBetween(stakingPromoBonusActiveFrom, stakingPromoBonusActiveUntil);
  },
);

export const getIsLMFreezerPromotionPeriodSelector = createSelector(
  promotionCampaignDataSelector,
  ({ activeLMPromotion, lmPromoBonusActiveUntil, lmPromoBonusActiveFrom }) => {
    if (!activeLMPromotion || !lmPromoBonusActiveUntil || !lmPromoBonusActiveFrom) {
      return null;
    }
    return !!activeLMPromotion && moment().isBetween(lmPromoBonusActiveFrom, lmPromoBonusActiveUntil);
  },
);

export const isThirdAnniversaryPromoActiveSelector = createSelector(
  getIsPromotionPeriodSelector,
  promotionCampaignDataSelector,
  (isPromotionActive, { activePromotion }) => isPromotionActive && activePromotion === '3rd_ANNIVERSARY_PROMO_2022',
);

export const getLMDTokensPromoActiveSelector = createSelector(
  getIsPromotionPeriodSelector,
  promotionCampaignDataSelector,
  (isPromotionActive, { activePromotion }) => isPromotionActive && activePromotion === ReferralPromoType.LM_DTOKENS_PROMO_2022,
);

export const getETHLMFreezerPromoActiveSelector = createSelector(
  getIsLMFreezerPromotionPeriodSelector,
  promotionCampaignDataSelector,
  (isPromotionActive, { activeLMPromotion }) => isPromotionActive && (activeLMPromotion === ReferralPromoType.ETH_LM_FREEZER_PROMOTION_2022),
);

export const getElevenEleven2022PromoActiveSelector = createSelector(
  getIsStakingPromotionPeriodSelector,
  promotionCampaignDataSelector, (isPromotionActive, { activeStakingPromotion }) => isPromotionActive && activeStakingPromotion === ReferralPromoType['11_11_PROMOTION_2022'],
);

export const getRazer3PromoActiveSelector = createSelector(
  promotionCampaignDataSelector,
  ({ razer3CampaignActiveFrom, razer3CampaignRetiredOn }) => moment().isBetween(razer3CampaignActiveFrom, razer3CampaignRetiredOn),
);
