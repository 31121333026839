import React, { useCallback, useState, useEffect } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { createUseStyles } from 'react-jss';
import fetchWithSpinner from '../../../utils/fetchWithSpinner';
import styles from './PhoneNumberInput.styles';
import sdk from '../../../sdk';
import 'react-intl-tel-input/dist/main.css';

const useStyles = createUseStyles(styles);

const PhoneNumberInput = ({
  defaultCountry,
  invalid = false,
  invalidMessage,
  onChange,
  defaultValue = '',
  tabIndex = 1,
  isAllowedToUpdate = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const classes = useStyles({ isFocused, isInvalid: invalid });

  const fetchWhitelistedCountries = async () => {
    try {
      const onboardingCountries = await fetchWithSpinner(
        () => sdk.OnboardingCountriesApi.getWhitelistedCountries(), { showAlert: false },
      );
      const formattedCountries = onboardingCountries.filter(c => (isAllowedToUpdate || c?.onboarding)).map(({ codeAlpha2 }) => codeAlpha2);
      setCountryOptions(formattedCountries);
    } catch (err) {
      window.cakepool.showAlert('error', err.message);
    }
  };

  useEffect(() => {
    fetchWhitelistedCountries();
  // eslint-disable-next-line
  }, []);

  const onChangeInput = () => {
    const phoneInput = document.querySelector('#phoneInput');
    const phoneCountry = document.querySelector('.selected-flag');
    if (phoneInput && phoneCountry && onChange) {
      const phoneNumber = phoneInput.value;
      const countryCode = phoneCountry.title.split(': +')[1];
      onChange({
        phoneNumber,
        countryCode,
      });
    }
  };

  const customPlaceholder = useCallback((value) => {
    const numbersOnly = value.match(/\d+/g);
    return numbersOnly.join('');
  }, []);

  return (
    <div
      tabIndex={tabIndex}
    >
      <IntlTelInput
        autoPlaceholder={true}
        customPlaceholder={customPlaceholder}
        defaultCountry={defaultCountry ? defaultCountry.toLowerCase() : null}
        separateDialCode={true}
        onSelectFlag={onChangeInput}
        onPhoneNumberChange={onChangeInput}
        onPhoneNumberFocus={() => setIsFocused(true)}
        onPhoneNumberBlur={() => setIsFocused(false)}
        containerClassName={`${classes.container} intl-tel-input`}
        inputClassName={`${classes.input} input-group intl-tel-input-element`}
        fieldId='phoneInput'
        defaultValue={defaultValue}
        format={false}
        formatOnInit={false}
        onlyCountries={countryOptions}
      />
      <span className={classes.invalidControlMessage}>{invalidMessage}</span>
    </div>
  );
};

export default PhoneNumberInput;
