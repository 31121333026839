import React, { Suspense, lazy } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { withTranslation, Translation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getQueryParam } from 'utils/getQueryParam';
import PopupBanner from 'common/components/PopupBanner/PopupBanner';
import Button from 'common/components/Button/Button';
import getCakeDeFiWebsiteBaseURL from 'utils/getCakeDeFiWebsiteBaseURL';
import getCakeDeFiWebAppBaseURL from 'utils/getCakeDeFiWebAppBaseURL';
import MobileDeepLinkPage from 'views/components/MobileDeeplinkPage';
import { mobileAppDeeplinks } from 'config/constant';
import { getZendeskLanguage } from '../../../user/LanguageRegionSettings/languageRegionSettingsUtils';
import appConfig from '../../../config/app';
import BasePage from '../BasePage';
import Header from './Header';
import Footer from './Footer';
import {
  Page404,
} from '../..';
import {
  Alert,
  Modal,
  Spinner,
  Toaster,
  Tooltip,
} from '../SharedComponents';
import ServerDownPage from '../../Pages/Errors/ServerDownPage';
import Fallback from '../../../Fallback';
import getLanguageFromLocalStorage from '../../../utils/getLanguageFromLocalStorage';
import CrashReportDialog from '../../../common/components/CrashReportDialog/CrashReportDialog';
import ClientConnectionDownToast from '../../../networkError/ClientConnectionDownToast';
import { setNetworkError } from '../../../networkError/networkErrorReducer';
import CakeSpinner from '../../components/Spinner';
import BalanceBar from '../../../balance-bar/BalanceBar';
import ErrorBoundary from './ErrorBoundary';

const DataConsentTermsPage = lazy(() => import('views/Pages/Root/DataCollectionTermsConsent/DataConsentTerms'));
const TermsAndConditions = lazy(() => import('views/Pages/Root/TermsAndConditions/TermsAndConditions'));

const mapState = state => ({
  userDetails: state.user.details,
  networkError: state.networkError,
  popupBanner: state.popupBanner,
  isWithdrawalOnlyStatus: state.user.isWithdrawalOnlyStatus,
  isCompleteAccessRestricted: state.user.isCompleteAccessRestricted,
});

const mapDispatch = ({ setNetworkError });

export const PagesWithoutBalanceBar = ['/register', '/verify-phone', '/verify-country', '/verify-identity', '/email-verification/email', '/data-consent-terms'];
export const PageWithoutMenuBalanceBarAndFooter = ['/data-consent-terms'];

const generateHomeRedirectRoute = (isAuthenticated: boolean) => {
  const redirect = (() => {
    if (isAuthenticated) {
      return 'wallets';
    }
    const isPromoOrRef = getQueryParam('promo') || getQueryParam('ref');
    if (isPromoOrRef) {
      return 'register';
    }
    return 'welcome';
  })();

  return (
    <Route key="home" name="Home" path="/" exact>
      <Redirect to={`/${redirect}`} />
    </Route>
  );
};

function closeAnyOpenedTooltip() {
  const hasTooltipObject = window && window.cakepool && window.cakepool.hideTooltip;
  if (hasTooltipObject) {
    window.cakepool.hideTooltip();
  }
}

class Layout extends BasePage {
  constructor(props) {
    super(props);
    const { history } = props;
    history.listen(() => {
      closeAnyOpenedTooltip();
    });
  }

  render() {
    const { networkError, userDetails, isWithdrawalOnlyStatus, isCompleteAccessRestricted } = this.props;
    const routes = userDetails?.userDataConsent?.isDisplayConsentScreen ? this.getDataConsentRoutes() : this.getRoutes();
    const showBalanceBar = !PagesWithoutBalanceBar.includes(this.props.location.pathname);
    const showMenuBalanceBarAndFooter = !PageWithoutMenuBalanceBarAndFooter.includes(this.props.location.pathname);
    const isAccessRestricted = isWithdrawalOnlyStatus || isCompleteAccessRestricted;

    return (
      <ErrorBoundary
        fallback={<>
          <Header {...this.props} />
          {this.renderHelmet()}
          <Fallback />
          <Footer canShowMenu={showMenuBalanceBarAndFooter && !isAccessRestricted} />
          <Translation>
            {() => <CrashReportDialog />}
          </Translation>
        </>
        }
      >
        <Suspense fallback={<CakeSpinner classes="margin-top-20" />}>
          <Header {...this.props} canShowMenu={showMenuBalanceBarAndFooter} isUserStatusWithdrawal={isAccessRestricted}></Header>
          {this.renderHelmet()}
          <Alert {...this.props}></Alert>
          <Translation>
            {
              () => <React.Fragment>
                <main className="main-layout">
                  {networkError.hasNetworkError && networkError.networkErrorType === 'SERVER_DOWN'
                    ? <ServerDownPage />
                    : (
                      <Switch>
                        {routes}
                        {mobileAppDeeplinks.map((path, index) => (
                          <Route key={index} path={path} component={MobileDeepLinkPage} />
                        ))}
                        <Route component={Page404}></Route>
                      </Switch>
                    )
                  }
                </main>
              </React.Fragment>
            }
          </Translation>
          {
            (showMenuBalanceBarAndFooter && !isAccessRestricted) && (
              <Translation>
                {
                  () => <Footer canShowMenu={true}></Footer>
                }
              </Translation>
            )
          }
          <Modal {...this.props}></Modal>
          <Spinner {...this.props}></Spinner>
          <Tooltip {...this.props}></Tooltip>
          <Toaster {...this.props}></Toaster>
          {
            showBalanceBar && (
              <BalanceBar />
            )
          }
          <ClientConnectionDownToast />
          <PopupBanner
            isActive={this.props.popupBanner.isActive}
            iconName={this.props.popupBanner.iconName}
            title={this.props.popupBanner.title}
            buttonCallback={this.props.popupBanner.buttonCallback}
            isAlert={this.props.popupBanner.isAlert}
            buttonNode={<Button secondary onClick={this.props.popupBanner.buttonCallback}>{this.props.popupBanner.buttonLabel}</Button>}
          />
        </Suspense>
      </ErrorBoundary>
    );
  }

  renderHelmet = () => {
    const { routeConfig: routeConfigs } = this.props;
    const { pathname: currentPath } = this.props.location;
    const currentRouteConfig = routeConfigs.find(routeConfig => routeConfig.path === currentPath);

    const isPublic = currentRouteConfig && currentRouteConfig.isPublic
      ? currentRouteConfig.isPublic : false;

    const defaultTitle = 'Get cash flow from cryptocurrencies';
    const defaultDescription = 'The most transparent way to put your Bitcoin and other cryptocurrencies to work.';

    const title = currentRouteConfig?.title ? currentRouteConfig.title : defaultTitle;

    const description = currentRouteConfig?.description ? currentRouteConfig.description : defaultDescription;
    const ogImage = currentRouteConfig?.ogImage ?? `${getCakeDeFiWebsiteBaseURL()}/img/social/root-en.png`;
    const ogUrl = currentRouteConfig?.ogUrl ?? getCakeDeFiWebAppBaseURL();

    const TranslatedDocumentHead = withTranslation()(this.DocumentHead);
    return (
      <TranslatedDocumentHead
        title={isPublic ? title : defaultTitle}
        description={description}
        keywords={'bitcoin, lending, crypto, cryptocurrency, staking, blockchain'}
        ogTitle={currentRouteConfig?.ogTitle ?? defaultTitle}
        ogDescription={currentRouteConfig?.ogDescription ?? defaultDescription}
        ogLocale={'en_US'}
        ogImage={ogImage}
        ogUrl={ogUrl}
      />);
  }

  // eslint-disable-next-line class-methods-use-this
  DocumentHead(props) {
    const {
      t,
      title,
      description,
      keywords,
      ogTitle,
      ogDescription,
      ogLocale,
      ogImage,
      ogUrl,
    } = props;

    const lang = getLanguageFromLocalStorage();

    return <Helmet>
      <html lang={getZendeskLanguage(lang)} />
      <title>Bake – {t(title)}</title>
      <meta name="description" content={t(description)}></meta>
      <meta name="keywords" content={t(keywords)}></meta>
      <meta property="og:title" content={`Bake – ${t(ogTitle)}`}></meta>
      <meta property="og:description" content={t(ogDescription)}></meta>
      <meta property="og:locale" content={t(ogLocale)}></meta>
      <meta property="og:image" content={ogImage}></meta>
      <meta property="og:url" content={ogUrl}></meta>
      <meta property="twitter:url" content={ogUrl}></meta>
    </Helmet>;
  }

  getDataConsentRoutes = () => (
    <>
      <Route
        key="data-consent"
        path="/data-consent-terms"
        name="Data Consent Terms"
        exact={true}
        component={DataConsentTermsPage}
      />
      <Route
        key="data-consent"
        path="/terms-and-conditions"
        name="Terms and Conditions"
        exact={true}
        isPublic={true}
        component={TermsAndConditions}
      />
      <Route
        path="*" // Always display Data Consent screen (except T&C page) as isDisplayConsentScreen flag is true from BE
        render={(props) => <Redirect to={props?.location?.pathname === '/terms-and-conditions' ? '/terms-and-conditions' : '/data-consent-terms'} />}
      />
    </>
  );

  getRoutes() {
    const {
      routeConfig,
      history,
      location,
      isWithdrawalOnlyStatus,
      isCompleteAccessRestricted,
    } = this.props;
    if (location.hash.startsWith('#/')) {
      history.push(location.hash.replace('#', ''));
    }

    const isAuthenticated = this.props.userDetails || false;
    const filteredRoutes = (isAuthenticated && (isWithdrawalOnlyStatus || isCompleteAccessRestricted))
      ? routeConfig.filter((route) => !route.isWithdrawalOnlyUserRestricted)
      : routeConfig;

    const routes = [];
    for (let i = 0; i < filteredRoutes.length; i += 1) {
      const key = `route-${i}`;
      const route = filteredRoutes[i];
      const {
        path,
        like,
        name,
        isPublic,
        isAuthenticatedRestricted,
        authenticatedRestrictedRedirection = appConfig.DEFAULT_AUTHENTICATED_RESTRICTED_REDIRECT,
      } = route;
      const isExact = !like;
      if (isAuthenticated && isAuthenticatedRestricted) {
        routes.push(
          <Redirect
            key={key}
            path={path}
            exact={isExact}
            to={{
              pathname: authenticatedRestrictedRedirection,
            }} />,
        );
      } else if (!isAuthenticated && !isPublic) {
        routes.push(
          <Redirect
            key={key}
            path={path}
            exact={isExact} to={{
              pathname: '/login',
            }} />,
        );
      } else if (route.component) {
        routes.push(
          <Route
            key={key}
            path={path}
            exact={isExact}
            name={name}
            render={props => (<route.component {...props}></route.component>)}
          />,
        );
      }
    }
    routes.push(generateHomeRedirectRoute(isAuthenticated));
    return routes;
  }
}

export default connect(mapState, mapDispatch)(Layout);
