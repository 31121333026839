import Variables from 'Variables.styles';
import { ModalContext } from 'modals/ModalContext';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import getCakeDeFiWebsiteBaseURL from 'utils/getCakeDeFiWebsiteBaseURL';
import TermsAndConditionsModal from 'views/components/TermsAndConditionsModal';

const useStyles = createUseStyles({
  linkText: {
    paddingLeft: Variables.extraSmall,
  },
});

const TermsAndPrivacy = () => {
  const classes = useStyles();
  const { openModal, closeModal } = useContext(ModalContext);

  const showTncModal = () => {
    openModal({
      component: <TermsAndConditionsModal closeModal={closeModal} />,
    });
  };

  const onInputKeyPress = (e) => {
    if (e.keyCode === 13) { // enter
      showTncModal();
    }
  };

  return (
    <div>
      <Trans>By signing up you consent and agree to the Bake</Trans>
      <span
        tabIndex={5}
        className={`${classes.linkText} link`}
        onClick={() => showTncModal()}
        onKeyDown={e => onInputKeyPress(e)}
      >
        <Trans>Terms and Conditions</Trans>
      </span><Trans> and </Trans>
      <a href={`${getCakeDeFiWebsiteBaseURL()}/privacy-policy`} target="_blank" className="link" rel="noreferrer">
        <Trans>Privacy Policy</Trans>
      </a>
    </div>
  );
};

export default TermsAndPrivacy;
