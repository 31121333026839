import { CheckboxProps } from '@material-ui/core';
import { Styles } from 'react-jss';
import { isDefiChainTheme, WebappTheme } from 'types/theme';
import Variables from '../../Variables.styles';
import { CheckBoxProps } from './CheckBox';

const checkboxBg = '#f4f3f6';

const getBaseCheckboxIcon = (appTheme: WebappTheme = 'CAKE') => ({
  width: Variables.large,
  height: Variables.large,
  position: 'absolute',
  left: '0',
  borderRadius: Variables.extraSmall,
  border: `1px solid ${Variables.gray200}`,
  background: checkboxBg,
});

const styles: Styles = {
  container: ({ bottomSpacing, disabled }: CheckBoxProps) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '36px',
    position: 'relative',
    outline: 'none',
    marginBottom: bottomSpacing ? Variables.larger : 0,
    cursor: disabled ? 'default' : 'pointer',
  }),
  unchecked: ({ appTheme }: CheckBoxProps) => ({
    ...getBaseCheckboxIcon(appTheme),
  }),
  indeterminate: ({ appTheme }: CheckBoxProps) => ({
    ...getBaseCheckboxIcon(appTheme),
    '&:before': {
      content: "''",
      width: '13px',
      height: '3px',
      background: Variables.electricBerry,
      position: 'absolute',
      top: '10px',
      left: '5px',
    },
  }),
  checked: ({ appTheme }: CheckBoxProps) => ({
    ...getBaseCheckboxIcon(appTheme),
    backgroundImage:
      `url('data:image/svg+xml;utf8,<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m17 8.5-7.5 7-2.5-3.5" fill="none" stroke="%23${isDefiChainTheme(appTheme) ? 'FF00FF' : '5B10FF'}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>')`,
    backgroundColor: isDefiChainTheme(appTheme) ? Variables.pink80 : 'rgb(242, 235, 254)',
  }),
  label: {
    marginBottom: 0,
    color: ({ disabled }: CheckboxProps) => (disabled ? Variables.gray300 : 'inherit'),
  },
};

export default styles;
