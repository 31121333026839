import React from 'react';
import ReactSvg from 'react-svg';
import linkedin from '../../../assets/svg/social-linkedin.svg';

export default function LinkedInIcon() {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/bake_io">
      <ReactSvg
        src={linkedin}
        className="linkedin-icon"
      />
    </a>
  );
}
