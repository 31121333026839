import { useState } from 'react';

export enum StandaloneModalType {
  MOBILE_POPUP_ON_LOGIN = 'MOBILE_POPUP_ON_LOGIN',
}

type StandaloneModalState = {
  [K in StandaloneModalType]: boolean;
};

const initialState = Object.keys(StandaloneModalType)
  .reduce((acc, key) => {
    acc[StandaloneModalType[key]] = false;
    return acc;
  }, {} as StandaloneModalState);

export type UseStandaloneModalTypes = {
  isShowModalMap: StandaloneModalState;
  isShowModal: (type: StandaloneModalType) => boolean;
  showModal: (type: StandaloneModalType) => void;
  closeModal: (type: StandaloneModalType) => void;
};

const useStandaloneModal = () => {
  const [isShowModalMap, setShowModalMap] = useState<StandaloneModalState>(initialState);

  const showModal = (type: StandaloneModalType) => {
    setShowModalMap({
      ...isShowModalMap,
      [type]: true,
    });
  };

  const closeModal = (type: StandaloneModalType) => {
    setShowModalMap({
      ...isShowModalMap,
      [type]: false,
    });
  };

  const isShowModal = (type: StandaloneModalType) => isShowModalMap[type];

  return { isShowModalMap, isShowModal, showModal, closeModal };
};

export default useStandaloneModal;
