/**
 * @param lang 'cn'|'zht'|'it'|'en' as default
 */
export default function getMomentLangFromi18n(lang?: string): string {
  switch (lang) {
    case 'cn':
    case 'zh':
    case 'zht':
      return 'zh-hk';
    case 'it':
      return 'it';
    default:
      return 'en';
  }
}
