import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import InvalidBigNumberError from '../error/InvalidBigNumberError';

export default function calculateFee(amount: BigNumberConstructable, tradeFeesMultiplier: BigNumberConstructable, decimalPlace?: number): string {
  const amountBN = new BigNumber(amount);
  let multiplierBN = new BigNumber(tradeFeesMultiplier);

  if (amount === '' || amount === null) {
    // eslint-disable-next-line no-param-reassign
    amount = new BigNumber(0);
  }

  if (tradeFeesMultiplier === '' || tradeFeesMultiplier === null) {
    multiplierBN = new BigNumber(0);
  }

  if (amountBN.isNaN()) {
    throw new InvalidBigNumberError('amountBN');
  }

  const fee = amountBN.multipliedBy(multiplierBN);
  if (fee.isNaN()) {
    throw new InvalidBigNumberError('amountBN');
  }

  return fee.toFixed(decimalPlace);
}
