import type { iPageViewProps } from './pageView';
import type { iEventProps } from './trackEvent';

const push = (props: iPageViewProps | iEventProps) => {
  if (!window?.dataLayer) {
    throw new Error('GTM dataLayer not found');
  }
  window.dataLayer.push(props);
};

export {
  push,
};
