import { CakeApiBase } from '../CakeApiBase';
import { Coin } from '../../schema/coin';
import { CoinPrice, ProductAPYs } from '../../schema/coins';

export class CoinApi extends CakeApiBase {
  async list(autoSortByName = true) {
    const coins = (await this.requestManager.get('coins')) as Coin[];

    if (autoSortByName) {
      coins.sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      });
    }
    return coins;
  }

  async latestPricing(query = {}, isBackgroundRequest = false) {
    return this.requestManager.get<CoinPrice[]>('coins/pricing', query, {
      isBackgroundRequest,
    });
  }

  async getCoinAPYs() {
    return this.requestManager.get<ProductAPYs>('coins/apy');
  }
}
