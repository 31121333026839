import { TransakCheckoutURL, TransakFiats, TransakOrderRequest, TransakPrice, TransakPriceRequest } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class TransakApi extends CakeApiBase {
  async getFiats() {
    return this.requestManager.get<TransakFiats>('/transak/fiats');
  }

  async getPrice(query: TransakPriceRequest) {
    return this.requestManager.get<TransakPrice>('/transak/price', query);
  }

  async getCheckoutURL(query: TransakOrderRequest) {
    return this.requestManager.get<TransakCheckoutURL>('/transak/order', query);
  }

  async completeOrder(orderId: string) {
    return this.requestManager.post('/transak/complete-order', {
      orderId,
    });
  }
}
