import BigNumber from 'bignumber.js';
import { CoinAssetMap } from '../schema/custom';

export default function positiveCoinSwapBalance(wallets: CoinAssetMap, kind = 'native') {
  const coins = Object.keys(wallets);
  for (let i = 0; i < coins.length; i += 1) {
    if (wallets[coins[i]].coinSwapBaseCoin
      && new BigNumber(wallets[coins[i]][kind].movable).isGreaterThan(0)) return true;
  }
  return false;
}
