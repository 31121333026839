import { Promotions, PromotionsAuthenticated } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export interface PromoDeepLink {
  code: string;
}

export class PromotionApi extends CakeApiBase {
  async promotionsAuthenticated() {
    return this.requestManager.get<PromotionsAuthenticated>('promotions/authenticated');
  }

  async promotions() {
    return this.requestManager.get<Promotions>('promotions');
  }

  async create(code) {
    return this.requestManager.post<PromoDeepLink>('promotions/set-promo', { code });
  }

  async retrieve() {
    return this.requestManager.get<PromoDeepLink>('promotions/retrieve');
  }
}
