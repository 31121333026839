import transactionDescriptions from './transactionDescriptions.json';

import allowedCountriesEN from './allowed-countries-en.json';
import allowedNationalitiesEN from './allowed-nationalities-en.json';
import usaStateList from './usa-state-list.json';
import riskWarning from './risk-warning.json';
import termAndConditions from './term-n-conditions.json';
import updatedDateTime from './updated-date-time.json';
import redeemTermAndConditions from './redeem-term-n-conditions.json';
import updatedDateTimeRedeem from './redeem-t-n-c-updated-date-time.json';
import openingTnc from './tnc-opening.json';

export {
  transactionDescriptions,
  allowedCountriesEN,
  allowedNationalitiesEN,
  usaStateList,
  riskWarning,
  termAndConditions,
  updatedDateTime,
  updatedDateTimeRedeem,
  redeemTermAndConditions,
  openingTnc,
};
