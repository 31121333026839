import { Styles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint, getMinWidthLg } from '../../Variables.styles';

const styles: Styles = {
  paragraphTitle: {
    fontWeight: 500,
  },
  currencySelectionContainer: {
    display: 'grid',
    alignItems: 'center',
    marginBottom: Variables.larger,
  },
  flexibleCurrencySelection: {
    width: '60%',
    margin: '24px auto 0',
  },
  flexibleCurrencySelectionSubTitle: {
    marginTop: Variables.small,
  },
  flexibleCurrencySelectionInput: {
    marginTop: Variables.medium,
  },
  flexibleTextBoxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: Variables.small,
  },
  flexibleCurrencySelectionInputNumber: {
    flex: 1,
  },
  flexibleTextBoxMaxButton: {
    padding: '14px 16px',
    minWidth: '60px',
    marginLeft: '16px',
  },
  currencySelection: {
  },
  textBoxWrapper: {
    marginBottom: '7px',
  },
  exchangeIcon: {
    fontSize: '2em',
    color: Variables.gray600,
    position: 'relative',
    top: '20px',
    flexGrow: 1,
    textAlign: 'center',
    margin: '0 10px',
  },
  inputWithButton: {
    display: 'flex',
    alignItems: 'center',
  },
  maxButton: {
    marginBottom: '7px',
    minWidth: '60px',
    padding: '10px',
  },
  [getMinWidthLg]: {
    currencySelectionContainer: {
      gridTemplateColumns: '1fr minmax(80px, 200px) 1fr',
    },
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
    currencySelection: {
      width: '100%',
    },
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    exchangeIcon: {
      top: '10px',
      marginBottom: '10px',
    },
  },
};

export default styles;
