import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getCoinIconName } from 'utils';
import { Icon } from 'views/components';
import styles from './InputGroup.styles';
import Select from '../Select/Select';
import InlineThreeDotsLoading from '../InlineThreeDotsLoading/InlineThreeDotsLoading';

const useStyles = createUseStyles<
  string,
  { isFocus: boolean; invalid: boolean }
>(styles);

interface InputGroupProps {
  defaultDropdownValue?: any;
  onInputChange?: any;
  onDropdownChange?: any;
  defaultInputValue?: string | number;
  inputRef?: any;
  options?: any[];
  type?: string;
  controlledInput?: boolean;
  value?: string | number;
  minInputValue?: number;
  inputPlaceHolder?: string;
  dropdownPlaceHolder?: string;
  isLoading?: boolean;
  isInputLoading?: boolean;
  showDropdown?: boolean;
  coin?: string;
  invalid?: boolean;
  invalidMessage?: React.ReactNode;
  disabled?: boolean;
}

export default function InputGroup({
  defaultDropdownValue,
  onInputChange,
  onDropdownChange,
  defaultInputValue,
  inputRef,
  options,
  type = 'text',
  controlledInput,
  value,
  minInputValue = 0,
  inputPlaceHolder = '',
  dropdownPlaceHolder = '',
  isLoading,
  isInputLoading,
  showDropdown = true,
  coin,
  invalid,
  invalidMessage,
  disabled,
}: InputGroupProps) {
  const valueProps = controlledInput
    ? { value }
    : { defaultValue: defaultInputValue };
  const selectKey = (defaultDropdownValue && defaultDropdownValue.value) || '';
  const [isFocus, setFocus] = useState(false);
  const classes = useStyles({
    isFocus,
    invalid,
  });
  return (
    <div className={classes.inputGroupWrapper}>
      <div className={classes.inputGroupContainer}>
        <input
          className={classes.inputGroupControl}
          type={type}
          step="any"
          min={minInputValue}
          {...valueProps}
          placeholder={inputPlaceHolder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => onInputChange && onInputChange(e.target.value)}
          ref={inputRef}
          disabled={disabled}
        ></input>
        {isInputLoading && (
          <span className={classes.inputLoading}>
            <InlineThreeDotsLoading></InlineThreeDotsLoading>
          </span>
        )}
        {showDropdown ? (
          <Select
            key={selectKey}
            options={options}
            defaultValue={defaultDropdownValue}
            onChange={(selectValue) => onDropdownChange && onDropdownChange(selectValue)
            }
            containerDisplay='inline-block'
            border={false}
            isLoading={isLoading}
            placeholder={dropdownPlaceHolder}
          ></Select>
        ) : (
          <div className={classes.coinDisplay}>
            <Icon
              name={getCoinIconName(coin)}
            />
            <span className={classes.coinLabel}>{coin}</span>
          </div>
        )}
      </div>
      <span className={classes.invalidControlMessage}>{invalidMessage}</span>
    </div>
  );
}
