import sdk from 'sdk';
import moment from 'moment';
import { webLocalStorage } from 'storage/sessionStorage';
import { fetchLeaderBoard, fetchLeaderBoardAuthenticated, fetchReferredUsers } from './referralSlice';
import { ReferralCampaignType } from './referralType';

export function getReferralId(referralId) {
  return referralId || '●●●●●●●';
}

export const fetchReferralLeaderBoardData = (dispatch, userDetail) => {
  if (userDetail) {
    dispatch(fetchLeaderBoardAuthenticated());
    return;
  }
  dispatch(fetchLeaderBoard());
};

export const getCampaignTypeText = (type: ReferralCampaignType) => {
  if (type === ReferralCampaignType.MidAutumn) {
    return 'Mooncake';
  }
  return 'Keg';
};

export const getCampaignDateWithTranslation = (lang: string, date: string): string => {
  const dateMoment = moment.utc(date);
  const year = dateMoment.format('YYYY');
  const monthfull = dateMoment.format('MMMM');
  const day = dateMoment.format('DD');
  const time = dateMoment.format('HH:mm');

  if (lang === 'zht') return `${year}年${monthfull}${day}日${time}點 UTC`;
  if (lang === 'it') return `${day} ${monthfull} ${year} alle ${time} UTC`;
  return `${time} UTC on ${dateMoment.format('MMMM D, YYYY')}`;
};

export const isMidAutumnCampaignCountry = (countryCode: string) => Object.keys(midAutumnCountryCodeMap).includes(countryCode);

export const midAutumnCountryCodeMap = {
  SG: '65',
  MY: '60',
  TH: '66',
  ID: '62',
  HK: '852',
  TW: '886',
  CN: '86',
  KR: '82',
  JP: '81',
  AU: '61',
  PH: '63',
};

export const convertCountryCallingCodeToCountryCode = (callingCode: string) => Object.entries(midAutumnCountryCodeMap).find(
  ([_, value]) => value === callingCode,
)?.[0];

export const getUserCountryCode = async (setLoading: (value: boolean) => void) => {
  const userLocationLocalStorage: string = webLocalStorage.getItem('userLocation');
  if (userLocationLocalStorage) {
    return userLocationLocalStorage;
  }
  try {
    setLoading(true);
    const { countryCode } = await sdk.LocationApi.getUserLocation();
    setLoading(false);
    if (countryCode) {
      webLocalStorage.setItem('userLocation', countryCode);
    }
    return countryCode;
  } catch (err) {
    return '';
  }
};

export const fetchReferredUsersData = (dispatch) => {
  dispatch(fetchReferredUsers());
};
