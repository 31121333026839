import React from 'react';
import JSONtoHTMLComponent, { JsonBodyElementProps, JsonBodySimpleElementProps } from 'assets/data/tnc/JSONtoHTMLComponent';
import { updatedDateTimeRedeem, redeemTermAndConditions } from '@cakedefi/cake-sdk/assets';
import UpdatedDateTime from '../../assets/data/tnc/UpdatedDateTime';

const TermsAndConditionsRedeem = () => (
  <div>
    <UpdatedDateTime jsonFile={updatedDateTimeRedeem as JsonBodySimpleElementProps[]} />
    <JSONtoHTMLComponent jsonFile={redeemTermAndConditions as JsonBodyElementProps[]} />
  </div >
);
export default TermsAndConditionsRedeem;
