import { INonCustodialPlatform, Platform } from '../..';
import { CakeApiBase } from '../CakeApiBase';

export class PlatformApi extends CakeApiBase {
  async getPlatforms() {
    return this.requestManager.get<Platform[]>('platforms');
  }

  async getNonCustodialPlatforms() {
    return this.requestManager.get<INonCustodialPlatform[]>('platforms/non-custodial-wallets');
  }
}
