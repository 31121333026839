import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import BigNumber from 'bignumber.js';
import {
  Badge,
  Progress,
} from 'reactstrap';
import {
  getLocalizedDate,
  getCoinIconName,
} from '../../../utils';
import Icon from '../Icon';
import MouseOverTooltip from '../MouseOverTooltip';
import BatchDetails from './BatchDetails';
import { PROMO_TYPE_SLUG } from '../Sprod/constants';

export default class BatchRow extends Component {
  state = {
    detailsOpened: false,
  }

  toggleDetails() {
    const { detailsOpened } = this.state;
    this.setState({
      detailsOpened: !detailsOpened,
    });
  }

  userHasEntryInBatch = batch => batch.userEntry && new BigNumber(batch.userEntry).isGreaterThan(0);

  renderDetailsLabel() {
    const { batch } = this.props;
    const { detailsOpened } = this.state;

    if (!batch) {
      return '';
    }

    if (detailsOpened) {
      return 'Close (special case)';
    }

    if (this.userHasEntryInBatch(batch)) {
      return 'My entry';
    }

    return 'Details';
  }

  renderBatchEntryStatus() {
    const { batch } = this.props;

    if (this.userHasEntryInBatch(batch)) {
      return <Badge color="secondary"><Trans i18nKey="Entered batch">Entered</Trans></Badge>;
    }
  }

  renderBatchStatus() {
    const { status } = this.props.batch;
    let statusColor = '';
    let statusText = status;

    switch (status) {
      case 'SETTLED':
        statusColor = 'success';
        statusText = 'Transferred';
        break;
      case 'ONGOING':
        statusColor = 'secondary';
        break;
      case 'MATURED':
        statusColor = 'primary';
        statusText = 'Concluded';
        break;
      case 'CANCELED':
        statusColor = 'danger';
        statusText = 'Canceled';
        break;
      default:
        statusColor = 'secondary';
        break;
    }

    return <span className={`text-${statusColor}`}><Trans>{statusText}</Trans></span>;
  }

  renderProgress() {
    const { batch, batchTableType } = this.props;

    if (batchTableType === 'past') {
      return this.renderBatchStatus();
    }

    return (
      <MouseOverTooltip renderTooltip={() => this.renderProgressBarPopover()}>
        <Progress className="batch-progress-bar" value={batch.progress * 100} />
      </MouseOverTooltip>
    );
  }

  renderProgressBarPopover() {
    const { batch } = this.props;
    const normalizedProgress = batch.progress <= 1 ? batch.progress : 1;

    return (
      <React.Fragment>
        <div className="batch-detail-popover">
          {(normalizedProgress * 100).toFixed(0)}%
        </div>
      </React.Fragment>
    );
  }

  renderActualPaReturn(batchReturnPerAnnum) {
    if (!batchReturnPerAnnum || batchReturnPerAnnum === 'NaN') {
      return '-';
    }

    const roundedBatchReturnPerAnnum = new BigNumber(batchReturnPerAnnum).multipliedBy(100).toFixed(2);

    return this.renderColoredNumber(roundedBatchReturnPerAnnum);
  }

  // eslint-disable-next-line class-methods-use-this
  renderColoredNumber(number, suffix = '') {
    if (new BigNumber(number).isGreaterThan(0)) {
      return <span className="positive-number mobile-font">{number}{suffix}%</span>;
    } if (new BigNumber(number).isLessThan(0)) {
      return <span className="negative-number mobile-font">{number}{suffix}%</span>;
    }
    return number;
  }

  renderExpectedReturnPerAnnum = () => {
    const { batch, coin } = this.props;
    const { targetReturnPerAnnum, dfiRewardPerAnnum } = batch;
    const expectedReturn = new BigNumber(targetReturnPerAnnum).multipliedBy(100).toFormat();

    if (!dfiRewardPerAnnum) {
      return (
        <div>
          {expectedReturn}
          <span className="mobile-font">%</span>
        </div>
      );
    }

    const dfiReturn = dfiRewardPerAnnum ? new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFormat() : null;

    return (
      <div>
        <MouseOverTooltip renderTooltip={() => this.renderDfiBonusPopover(expectedReturn, coin.id, dfiReturn)}>
          <span className="mobile-font">{new BigNumber(expectedReturn).plus(dfiReturn).toFormat()}</span>
          <span className="mobile-font">%</span>
        </MouseOverTooltip>
      </div>
    );
  }

  renderActualReturnPerAnnum = () => {
    const { batch, coin } = this.props;
    const { batchReturnPerAnnum, dfiRewardPerAnnum } = batch;

    if (!batchReturnPerAnnum || batchReturnPerAnnum === 'NaN') {
      return (
        <div>-</div>
      );
    }

    const roundedBatchReturnPerAnnum = new BigNumber(batchReturnPerAnnum).multipliedBy(100).toFixed(2);

    if (!dfiRewardPerAnnum) {
      return (
        <div>
          {this.renderColoredNumber(roundedBatchReturnPerAnnum)}
        </div>
      );
    }

    const dfiReturn = dfiRewardPerAnnum ? new BigNumber(dfiRewardPerAnnum).multipliedBy(100).toFormat() : null;

    return (
      <div>
        <MouseOverTooltip renderTooltip={() => this.renderDfiBonusPopover(roundedBatchReturnPerAnnum, coin.id, dfiReturn)}>
          {this.renderColoredNumber(new BigNumber(roundedBatchReturnPerAnnum).plus(dfiReturn).toFormat())}
        </MouseOverTooltip>
      </div>
    );
  }

  renderDfiBonusPopover = (expectedReturn, coinSymbol, dfiReturn) => (
    <span className="tooltip-wrapper">
      <span>{expectedReturn}% {coinSymbol} + {dfiReturn}% DFI</span>
    </span>
  );

  render() {
    const {
      batch,
      sprod,
      batchTableType,
      isLoggedIn,
      isLoading,
      updateAutoCompounding,
      copyHashProof,
      openProofModal,
      coin,
      refreshEstimatedAutocompounds,
      currentCoinPriceUsd,
    } = this.props;
    const { detailsOpened } = this.state;
    const promoType = sprod?.slug === PROMO_TYPE_SLUG;

    return (
      <React.Fragment>
        <div className={`gr mobile-font ${detailsOpened ? 'gr-no-border' : ''} ${promoType ? 'xmas-background' : ''}`}>
          <div className="sprod-icon gd g-ar g-lg-o1 flex-right-desktop">
            <MouseOverTooltip renderTooltip={() => <span className="batch-detail-popover">{coin.id}</span>}>
              <div className="sprod-icon-wrapper">
                <Icon
                  name={promoType ? 'xmas-btc' : getCoinIconName(coin.id)}
                  svgStyle={promoType ? {
                    width: '42px',
                    height: '42px',
                    marginTop: '-4px',
                  } : {
                    width: '32px',
                    height: '32px',
                  }}
                />
              </div>
            </MouseOverTooltip>
          </div>
          <div className="sprod-title gd g-ar g-lg-o2 flex-right-desktop">
            <span><span className="hide-lg"><Trans>Batch</Trans></span> {batch.batch}</span>
          </div>
          <div className="sprod-status gd g-lg-o10 flex-right-desktop">{this.renderBatchEntryStatus()}</div>
          <div className="sprod-date gd g-lg-o4 flex-right-desktop">{getLocalizedDate(new Date(batch.saleClosesOn))}</div>
          <div className="sprod-date gd g-lg-o5 flex-right-desktop">
            <span className="mobile-font">
              <span className="hide-lg"> &nbsp;&ndash; </span>
              {getLocalizedDate(new Date(batch.maturesOn))}
            </span>
          </div>
          <div className="sprod-progress gd g-lg-o3 flex-right-desktop">
            {this.renderProgress()}
          </div>
          <div className="sprod-value gd g-ar g-lg-o6 flex-right-desktop">
            <div className="hide-lg sprod-label">
              <Trans>Starting value</Trans>
            </div>
            <div>
              {new BigNumber(batch.amount).toFormat()}
              <span className="mobile-font"> {sprod ? sprod.CoinId : ''}</span>
            </div>
          </div>
          <div className="sprod-value gd g-ar g-lg-o7 flex-right-desktop">
            <div className="hide-lg sprod-label">
              <Trans>Target APY</Trans>
            </div>
            {this.renderExpectedReturnPerAnnum()}
          </div>
          <div className="sprod-value gd g-ar g-lg-o8 mb-24-m flex-right-desktop">
            <div className="hide-lg sprod-label">
              <Trans>Actual APY</Trans>
            </div>
            {this.renderActualReturnPerAnnum()}
          </div>
          <div className="sprod-action gd g-lg-o9 flex-right-desktop">
            <span className="batch-details" onClick={() => this.toggleDetails()}><Trans>{this.renderDetailsLabel()}</Trans></span>
          </div>
        </div>
        <BatchDetails
          isOpen={detailsOpened}
          batch={batch}
          sprod={sprod}
          isLoggedIn={isLoggedIn}
          isLoading={isLoading}
          batchTableType={batchTableType}
          updateAutoCompounding={updateAutoCompounding}
          copyHashProof={copyHashProof}
          openProofModal={openProofModal}
          coin={coin}
          currentCoinPriceUsd={currentCoinPriceUsd}
          refreshEstimatedAutocompounds={refreshEstimatedAutocompounds}
        />
      </React.Fragment>
    );
  }
}
