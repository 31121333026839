import zxcvbn from 'zxcvbn';
import appConfig from 'config/app';
import isEmpty from './isEmpty';

const { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH, ZXCVBN_SCORE_THRESHOLD } = appConfig;

export default (password: string): boolean => !isEmpty(password)
  && password.length >= MIN_PASSWORD_LENGTH
  && password.length <= MAX_PASSWORD_LENGTH
  && zxcvbn(password).score >= ZXCVBN_SCORE_THRESHOLD;
