import React from 'react';
import { Trans } from 'react-i18next';
import { getCoinIconName } from '../../../utils';
import Icon from '../../../views/components/Icon';

export default function Option({ icon, label }) {
  return (
    <div className='d-flex align-items-center'>
      {icon && (
        <span className="mr-3">
          <Icon
            name={getCoinIconName(icon)}
          />
        </span>
      )}
      <span><Trans>{label}</Trans></span>
    </div>
  );
}
