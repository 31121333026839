import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import sdk from 'sdk';
import {
  IHasIncomingDepositsResponse,
  IIncomingDeposit,
  IUpdateOriginatorInfoRequest,
} from '@cakedefi/cake-sdk/schema/incoming-deposits';
import { INonCustodialPlatform, StateStatus } from '@cakedefi/cake-sdk';
import { IIncomingDepositsState } from './incomingDepositsTypes';

const initialState: IIncomingDepositsState = {
  state: StateStatus.IDLE,
  originatorInfoUpdateStatus: StateStatus.IDLE,
  error: null,
  incomingDeposits: [],
  nonCustodialPlatforms: [],
  loading: false,
  originatorInfo: {
    isOwnedByCurrentUser: false,
    customPlatformName: '',
    walletType: 'PLATFORM_WALLET',
  },
  selectedIncomingDeposit: null,
};

export const fetchHasIncomingDeposits = createAsyncThunk<
  IHasIncomingDepositsResponse,
  null
>('has-incoming-deposits', async () => {
  const result = await sdk.IncomingDepositsAPI.hasIncomingDeposits();
  return result;
});

export const fetchIncomingDeposits = createAsyncThunk<IIncomingDeposit[], null>(
  'incoming-deposits',
  async () => {
    const result = await sdk.IncomingDepositsAPI.getIncomingDeposits();
    return result;
  },
);

export const updateOriginatorInfo = createAsyncThunk<any, null, ThunkApiConfig>(
  'update-originator-info',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const { originatorInfo } = state.incomingDeposits;
    const {
      customPlatformName,
      platformName,
      walletType,
      lastName,
      firstName,
      isOwnedByCurrentUser,
      countryOfResidence,
    } = originatorInfo;

    const payload: IUpdateOriginatorInfoRequest = {
      originatorInformation: {
        isOriginatorAddressOwner: isOwnedByCurrentUser,
        originatorFirstName: firstName,
        originatorLastName: lastName,
        isCustodialWallet: walletType === 'PLATFORM_WALLET',
        platform: customPlatformName !== '' ? customPlatformName : platformName,
      },
      depositId: state.incomingDeposits.selectedIncomingDeposit.id,
    };

    if (!isOwnedByCurrentUser) {
      payload.originatorInformation.originatorCountry = countryOfResidence;
    }

    const result = await sdk.IncomingDepositsAPI.updateOriginatorInfo(payload);
    thunkAPI.dispatch(clearOriginatorInfoAction());
    return result;
  },
);

export const clearOriginatorInfoAction = createAction('clear-originator-info');

export const fetchNonCustodialPlatforms = createAsyncThunk<
  INonCustodialPlatform[],
  null
>('non-custodial-platforms', async () => {
  const result = await sdk.PlatformApi.getNonCustodialPlatforms();
  return result;
});

const incomingDepositsSlice = createSlice({
  name: 'IncomingDeposits',
  initialState,
  reducers: {
    setSelectedIncomingDeposit: (state, action) => {
      state.selectedIncomingDeposit = action.payload;
    },
    setOriginatorInfo: (state, action) => {
      state.originatorInfo = { ...state.originatorInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearOriginatorInfoAction, (state) => {
      state.originatorInfo = {
        isOwnedByCurrentUser: false,
        customPlatformName: '',
      };
    });
    builder.addCase(fetchIncomingDeposits.pending, (state) => {
      state.state = StateStatus.LOADING;
      state.error = null;
    });
    builder.addCase(fetchIncomingDeposits.fulfilled, (state, action) => {
      state.state = StateStatus.FINISHED;
      state.incomingDeposits = action.payload;
      state.originatorInfo = {
        isOwnedByCurrentUser: false,
        customPlatformName: '',
      };
      state.error = null;
    });
    builder.addCase(fetchIncomingDeposits.rejected, (state, action) => {
      state.state = StateStatus.ERROR;
      state.error = action.payload;
    });
    builder.addCase(fetchNonCustodialPlatforms.pending, (state) => {
      state.state = StateStatus.LOADING;
      state.error = null;
    });
    builder.addCase(fetchNonCustodialPlatforms.fulfilled, (state, action) => {
      state.state = StateStatus.FINISHED;
      state.nonCustodialPlatforms = action.payload;
      state.error = null;
    });
    builder.addCase(fetchNonCustodialPlatforms.rejected, (state, action) => {
      state.state = StateStatus.ERROR;
      state.error = action.payload;
    });
    builder.addCase(updateOriginatorInfo.pending, (state) => {
      state.originatorInfoUpdateStatus = StateStatus.LOADING;
      state.error = null;
    });
    builder.addCase(updateOriginatorInfo.fulfilled, (state, action) => {
      state.originatorInfoUpdateStatus = StateStatus.FINISHED;
      state.error = null;
    });
    builder.addCase(updateOriginatorInfo.rejected, (state, action) => {
      state.originatorInfoUpdateStatus = StateStatus.ERROR;
      state.error = action.payload;
    });
  },
});

export const { setSelectedIncomingDeposit, setOriginatorInfo } = incomingDepositsSlice.actions;
export default incomingDepositsSlice.reducer;
