import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCakeSelector } from 'app/rootReducer';
import { createUseStyles } from 'react-jss';
import { ModalContent, ModalFooter, ModalHeader } from 'modals/components';
import kycPhone from 'assets/svg/kyc/kyc-phone.svg';
import onboardIdentity from 'assets/svg/onboard-identity.svg';
import { staggeredKycUserStatusSelector } from 'user/AccountVerification/accountVerificationSelector';
import KycCurrentLevel from 'common/kyc/KycCurrentLevel';
import Button from 'common/components/Button/Button';
import { userDetailSelector } from 'user/userSelector';
import { ToShowDialogTypeOnLoginType } from 'navigation/navigationSlice';
import styles from './KycUnverified.styles';
import BottomKycBenefitsForModal from './common/BottomKycBenefitsForModal';
import getLevelDisplay from './utils/getLevelDisplay';

interface ContentItem {
  icon: string;
  title: JSX.Element;
  description: JSX.Element;
  settingsLink: string;
  buttonLabel: string;
  isShowKycBottomSection: boolean;
}

const getScreenContent = (modalType: ToShowDialogTypeOnLoginType): ContentItem => {
  if (modalType === 'PHONE_VERIFICATION') {
    return {
      icon: kycPhone,
      title: <Trans>Verify your phone number.</Trans>,
      description: <Trans>Verify your phone number to secure your account.</Trans>,
      settingsLink: '/verify-phone',
      buttonLabel: 'Verify phone number',
      isShowKycBottomSection: false,
    };
  }

  // shown when user logs in and is not verified
  return {
    icon: onboardIdentity,
    title: <Trans>Verify your account to get started.</Trans>,
    description: <Trans>As a regulated FinTech company, Bake is required to verify user identity. This process only takes a few minutes to complete and less than an hour to get approved.</Trans>,
    settingsLink: '/me/verify-account',
    buttonLabel: 'Verify now',
    isShowKycBottomSection: true,
  };
};

const useStyles = createUseStyles(styles);

interface KycUnverifiedOnLoginModalProps {
  onClose: () => void;
  modalType: ToShowDialogTypeOnLoginType;
  showCancelButton?: boolean;
}

export default function KycUnverifiedOnLoginModal(props: KycUnverifiedOnLoginModalProps) {
  const { onClose, modalType, showCancelButton = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const details = useCakeSelector(userDetailSelector);
  const { isUserEligibleStaggeredKyc } = useCakeSelector(staggeredKycUserStatusSelector);

  const content = getScreenContent(modalType);

  return (
    <>
      <ModalHeader title={t('Hi Baker!')} onClose={onClose} showCancelButton={showCancelButton} cancelLabel='Close'/>
      <ModalContent>
        <div className={classes.content}>
          <img src={content.icon} alt='verification-icon' className={classes.image}/>
          <h3>{content.title}</h3>
          { content?.description && <div>{content.description}</div> }
          { content?.isShowKycBottomSection ? <BottomKycBenefitsForModal /> : null }
        </div>
      </ModalContent>
      <ModalFooter styleType="footerSpaceBetween">
        <KycCurrentLevel level={getLevelDisplay(details?.kycTier, details?.kycFullStatus, isUserEligibleStaggeredKyc)} />
        { content.buttonLabel && <a href={content.settingsLink} onClick={props.onClose}> {/* nosemgrep: typescript.react.security.audit.react-href-var.react-href-var */}
          <Button main>
            <Trans i18nKey={content.buttonLabel}>{content.buttonLabel}</Trans>
          </Button>
        </a> }
      </ModalFooter>
    </>
  );
}
