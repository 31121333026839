import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { isMobile } from 'react-device-detect';
import Variables, { getMinWidthLg, getMaxWidthBreakPoint } from 'Variables.styles';
import iconClose from 'assets/svg/icon-close.svg';
import { Icon } from '../../../views/components';

const useStyles = createUseStyles({
  base: {
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      position: 'relative',
      zIndex: '100',
    },
  },
  background: {
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      backgroundColor: 'black',
      opacity: '0.5',
      position: 'fixed',
      width: '100%',
      height: '100%',
      inset: 0,
    },
  },
  container: {
    position: 'fixed',
    left: '50%',
    bottom: Variables.large,
    transform: 'translateX(-50%)',
    tabIndex: 99999,
    zIndex: 10000,
    backgroundColor: Variables.white,
    borderRadius: Variables.small,
    border: Variables.primaryBorder,
    boxShadow: Variables.primaryBoxShadow,
    padding: Variables.large,
    maxWidth: '700px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      left: 0,
      transform: 'none',
      bottom: 0,
      borderRadius: '8px 8px 0px 0px',
      flexDirection: 'column',
      minWidth: '100%',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '16px',
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      display: 'block',
    },
  },
  title: {
    fontSize: '1.6rem',
    textAlign: 'left',
    marginBottom: 0,
    fontWeight: 'bold',
  },
  alertTitle: {
    fontSize: Variables.fontSize.base,
    textAlign: 'left',
    marginBottom: 0,
    fontWeight: Variables.fontWeight.bold,
    maxWidth: '450px',
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: 0,
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      display: 'block',
      '& button': {
        width: '100%',
        marginTop: Variables.medium,
      },
    },
  },
  closeBtnContainer: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    boxSizing: 'content-box',
    width: '1em',
    height: '1em',
    padding: '0.25em 0.25em',
    color: Variables.black,
    background: `url(${iconClose})`,
    border: 0,
    borderRadius: '0.25rem',
    opacity: '0.5',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      opacity: '0.75',
    },
    [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
      display: 'none',
    },
  },
  [getMinWidthLg]: {
    content: {
      flexDirection: 'row',
    },
  },
});

interface PopupBannerProps {
  title: string;
  subtitle?: string;
  isActive?: boolean;
  iconName?: string;
  buttonNode: React.ReactNode;
  isAlert?: boolean;
  buttonCallback?: () => void;
}

/**
 * Mini popup banner, best for errors or alerts
 */
const PopupBanner = (props: PopupBannerProps) => {
  const {
    isActive = false,
    title,
    subtitle,
    iconName,
    buttonNode,
    isAlert,
    buttonCallback,
  } = props;
  const classes = useStyles();

  if (!isActive) return null;

  return (
    <div className={classes.base}>
      <div className={classes.background}></div>
      <div className={classes.container}>
        <div>
          { iconName?.length > 0 && <Icon name={iconName} svgStyle={isAlert && { width: 48, height: 48 }} /> }
        </div>
        <div className={classes.content}>
          <div>
            <p className={isAlert ? classes.alertTitle : classes.title}><Trans>{title}</Trans></p>
            { !!subtitle && <p className={classes.subtitle}><Trans>{subtitle}</Trans></p> }
          </div>
          { (isAlert && isMobile)
            ? <button type="button" className={classes.closeBtn} onClick={buttonCallback}></button>
            : <div className={classes.actionButtonsContainer}>{buttonNode}</div>
          }
        </div>
      </div>
    </div>
  );
};

export default PopupBanner;
