import Request from './request';

export interface ClientConfig {
  baseUrl: string;
  accessTokenMaxAge: number; // seconds
  defaultPollingInterval: number; // milliseconds

  // should be revamped
  // make use of long live credential as jwt secret
  // sign short live (even one time use) token with the secret on client side to remove static token usage
  refreshTokenMaxAge: number; // seconds
  stayLoggedInMaxAge: number; // seconds
  debug?: boolean;
  refreshTokenIntervalMs?: number;
}

export type UserTokenType = 'BASIC' | 'REFRESH';
export interface SessionStorage {
  get: (type: UserTokenType) => Promise<string>;
  set: (type: UserTokenType, token: string) => Promise<void>; // for any auto token refresh purpose
  remove: (type?: UserTokenType) => Promise<void>; // delete all token when type not specified
  loggedOut: () => Promise<void>; // a callback, both voluntary and involantary
}

export class CakeRequestManager extends Request {
  protected config: ClientConfig;

  protected sessionStorage: SessionStorage;

  public userToken?: string;

  constructor(config: ClientConfig, sessionStorage: SessionStorage, customHeaders = {}) {
    super(config.baseUrl, customHeaders);
    this.config = config;
    this.sessionStorage = sessionStorage;
    this.debug = config.debug || false;
  }

  async getExtraHeaders(): Promise<Record<string, any>> {
    const headers: Record<string, any> = {};

    if (this.userToken) {
      headers.Authorization = `Bearer ${this.userToken}`;
    }
    return headers;
  }
}
