import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import constants from './constants';
import localizeBigNumber from './localizeBigNumber';

const friendlyDecimals = constants.FRIENDLY_DECIMALS;
BigNumber.config({ EXPONENTIAL_AT: 21 });

export default function getShortVersionOfNumber(value: BigNumberConstructable, decimals = friendlyDecimals, fmt: BigNumber.Format = constants.BN_STRING_FORMAT) {
  const bnValue = new BigNumber(value);
  const roundingMode = BigNumber.ROUND_DOWN;

  if (bnValue.gt(1000 * 1000 * 1000)) {
    const result = bnValue.dividedBy(1000 * 1000 * 1000).decimalPlaces(decimals, roundingMode).toFormat(fmt);
    return `${result}B`;
  }

  if (bnValue.gt(1000 * 1000)) {
    const result = bnValue.dividedBy(1000 * 1000).decimalPlaces(decimals, roundingMode).toFormat(fmt);
    return `${result}M`;
  }

  return localizeBigNumber(bnValue.decimalPlaces(decimals, roundingMode));
}
