import { FreezerPageAuthenticated } from '@cakedefi/cake-sdk';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import sdk from '../../sdk';
import { StateStatus } from '../../types/common/state';
import { GetFreezerActionsResponse } from '../../types/freezer/freezer-api';
import { NewFreeze, NewFreezerSlice, SetNewFreezeIsExtendingPayload } from '../../types/freezer/freezer-slice';
import { FREEZES_PER_PAGE } from '../freezerService';
import { fetchFreezerPageAuthenticated } from '../freezerSlice';

const initialState: NewFreezerSlice = {
  data: {
    newFreezes: [],
    newFreezesCount: 0,
  },
  page: 0,
  status: StateStatus.STARTED,
};

export const refreshNewFreezes = createAsyncThunk(
  'newFreezer/refreshNewFreezes',
  async () => sdk.FreezerApi.getFreezerActions(FREEZES_PER_PAGE, 0),
);

const newFreezerSlice = createSlice({
  name: 'newFreezer',
  initialState,
  reducers: {
    setNextPage: (state) => {
      state.page += 1;
    },
    setAutoRenewForAll: (state, action: PayloadAction<boolean>) => {
      state.data.newFreezes = state.data.newFreezes.map(freeze => ({
        ...freeze,
        autoRenew: action.payload,
      }));
    },
    setIsExtending: (state, action: PayloadAction<SetNewFreezeIsExtendingPayload>) => {
      state.data.newFreezes = state.data.newFreezes.map((freeze) => {
        if (freeze.id === action.payload.id) {
          return {
            ...freeze,
            isExtending: action.payload.isExtending,
          };
        }
        return {
          ...freeze,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFreezerPageAuthenticated.fulfilled, (state, action: PayloadAction<FreezerPageAuthenticated>) => {
      const enrichedNewFreezes: NewFreeze[] = action.payload.newFreezes.map(freeze => ({
        ...freeze,
        isExtending: false,
      }));
      state.data.newFreezes = enrichedNewFreezes;
      state.data.newFreezesCount = action.payload.newFreezesCount;
      state.page = 1;
      state.status = StateStatus.FINISHED;
    });
    builder.addCase(refreshNewFreezes.fulfilled, (state, action: PayloadAction<GetFreezerActionsResponse>) => {
      const { rows, count } = action.payload;
      const enrichedRows: NewFreeze[] = rows.map(row => ({ ...row, isExtending: false }));
      state.data.newFreezes = [...enrichedRows];
      state.data.newFreezesCount = count;
      state.page = 1;
      state.status = StateStatus.FINISHED;
    });
    builder.addCase(fetchFreezerPageAuthenticated.pending, (state) => {
      state.status = StateStatus.LOADING;
    });
    builder.addCase(refreshNewFreezes.pending, (state) => {
      state.status = StateStatus.LOADING;
    });
    builder.addCase(fetchFreezerPageAuthenticated.rejected, (state, action) => {
      state.status = StateStatus.ERROR;
      state.error = action.error;
    });
    builder.addCase(refreshNewFreezes.rejected, (state, action) => {
      state.status = StateStatus.ERROR;
      state.error = action.error;
    });
  },
});

export const {
  setNextPage,
  setAutoRenewForAll,
  setIsExtending,
} = newFreezerSlice.actions;

export default newFreezerSlice.reducer;
