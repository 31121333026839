import { Styles } from 'react-jss';
import Variables from '../../../../Variables.styles';
import phoneIcon from '../../../../assets/svg/icon-phone.svg';

const styles: Styles = {
  phoneCalling: {
    backgroundImage: `url(${phoneIcon})`, // no-repeat fixed left
    backgroundRepeat: 'no-repeat',
    backgroundSize: '32px',
    height: '6rem',
  },
  phoneCallingText: {
    paddingLeft: Variables.extraLarge,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  numberInput: {
    marginTop: Variables.medium,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12rem',
    marginBottom: '12rem',
    '& > a': {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    margin: `0 0 ${Variables.medium} 0`,
    maxWidth: '270px',
    lineHeight: '3.2rem',
  },
  invalidMessage: {
    color: Variables.redInvalidControl,
    fontSize: '1.4rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    marginTop: Variables.small,
    minHeight: '10px',
  },
  spacingTopMd: {
    marginTop: Variables.medium,
  },
  centeredSecondaryText: {
    ...Variables.baseText,
    color: Variables.textSecondary,
    textAlign: 'left',
  },
  wrap: {
    display: 'inline-block',
    marginRight: '1em',
    marginTop: '0.8em',
    '&--end': {
      marginRight: '0',
      marginLeft: '1em',
    },
  },
  linkLabel: {
    fontSize: '1.6rem',
    fontWeight: 500,
    color: Variables.electricBerry,
    cursor: 'pointer',
  },
};

export default styles;
