import { DialogContent, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  dialogContentRoot: {
    padding: '0',
  },
});

export const ModalContent = ({ children, dividers = true }) => {
  const classes = useStyles();
  return (
    <DialogContent dividers={dividers} className={classes.dialogContentRoot}>
      {children}
    </DialogContent>
  );
};
