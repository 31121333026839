import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './Card.styles';

const useStyles = createUseStyles(styles);

export default function Card({ children, padding = 0, className = '', overflow = 'hidden', onClick = null, style = {}, selected = false, rounded = 8, disabled = false }) {
  const activeClass = selected ? 'active' : '';
  const disabledClass = disabled ? 'disabled' : '';
  const classes = useStyles({
    padding,
    overflow,
    rounded,
  });
  return (
    <div className={`${classes.cardWrapper} ${className} ${activeClass} ${disabledClass}`} onClick={onClick} style={style}>
      {children}
    </div>
  );
}
