import banxaLogo from '../../assets/svg/logos/banxa-logo.svg';
import transakLogo from '../../assets/img/transak-logo@2x.png';
import Variables from '../../Variables.styles';

export enum OnRampProviderType {
  BANXA = 'banxa',
  TRANSAK = 'transak',
}

export interface OnRampProvider {
  type: OnRampProviderType;
  name: string;
  icon: string;
  iconMaxHeight: string;
  selected?: boolean;
  tnc: string;
  supportUrl: string;
}

export const ONRAMP_PROVIDERS: OnRampProvider[] = [
  {
    name: 'Transak',
    type: OnRampProviderType.TRANSAK,
    icon: transakLogo,
    iconMaxHeight: '28px',
    selected: false,
    tnc: 'https://www.notion.so/Transak-Terms-of-Service-6d89598211644402b3be63bc3f1468b4',
    supportUrl: 'mailto:support@transak.com',
  },
  {
    name: 'Banxa',
    type: OnRampProviderType.BANXA,
    icon: banxaLogo,
    iconMaxHeight: Variables.medium,
    selected: true,
    tnc: 'https://banxa.com/terms-of-use-agreement/',
    supportUrl: 'https://support.banxa.com/',
  },
];
