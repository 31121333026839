import { CakeApiBase } from '../CakeApiBase';
import { MinimumAmountInUsd, YieldVaultBalance, YieldVaultCoin, YieldVaultStrategy } from '../../schema';

export class YieldVaultApi extends CakeApiBase {
  async getYieldVaultCoins() {
    return this.requestManager.get<YieldVaultCoin[]>('/yield-vault/coins');
  }

  async enterYieldVault(coinId: string, amount: string, strategy: YieldVaultStrategy) {
    return this.requestManager.post(`/yield-vault/${coinId}/enter`, { amount, strategy });
  }

  async exitYieldVault(coinId: string, amount: string, strategy: YieldVaultStrategy) {
    return this.requestManager.post(`/yield-vault/${coinId}/exit`, { amount, strategy });
  }

  async getYieldVaultBalances() {
    return this.requestManager.get<YieldVaultBalance[]>('/yield-vault/balances');
  }

  async getYieldVaultMinInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/yield-vault/minimum');
  }
}
