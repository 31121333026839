import {
  WhitelistAddress,
  AddWhitelistAddressPayload,
  UpdateWhitelistAddressPayload,
  ValidateAddressPayload,
} from '../../schema/whitelist';
import { CakeApiBase } from '../CakeApiBase';

export class WhitelistedAddressApi extends CakeApiBase {
  async addAddress(payload: AddWhitelistAddressPayload) {
    return this.requestManager.post<WhitelistAddress>('whitelist/address', payload);
  }

  async get(networkId: string) {
    return this.requestManager.get<WhitelistAddress[]>('whitelist', { networkId });
  }

  async getAll() {
    return this.requestManager.get<WhitelistAddress[]>('whitelist');
  }

  async updateAddress(addressId: string, payload: UpdateWhitelistAddressPayload) {
    return this.requestManager.put<WhitelistAddress>(`whitelist/${addressId}`, payload);
  }

  async removeAddress(addressId: string): Promise<any> {
    return this.requestManager.delete(`whitelist/${addressId}`);
  }

  async validateAddress(payload: ValidateAddressPayload) {
    return this.requestManager.post('whitelist/address/validate', payload);
  }
}
