import { createSlice } from '@reduxjs/toolkit';
import { OTPType } from 'sdk/cake-sdk/src/api/client/UserInfoApi';

export interface SsoUserData {
  displayName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  profileUrl: string;
  ssoProvider: string;
  ssoAuthToken: string;
  otpRequired: boolean;
  tempOtpAuthToken?: string;
  isNewUser: boolean;
  isExistingUserWithoutSso: boolean;
  status: string,
  alternativeOtpMethod: OTPType | null;
}

const initialState = {
  displayName: null,
  firstName: null,
  lastName: null,
  email: null,
  profileUrl: null,
  ssoProvider: null,
  ssoAuthToken: null,
  otpRequired: false,
  tempOtpAuthToken: null,
  isNewUser: false,
  isExistingUserWithoutSso: false,
  alternativeOtpMethod: null,
};

const oauthSlice = createSlice({
  name: 'oAuth',
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      const { displayName, firstName, lastName, email, profileUrl, ssoProvider, isNewUser } = action.payload;
      state.displayName = displayName;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.profileUrl = profileUrl;
      state.ssoProvider = ssoProvider;
      state.isNewUser = isNewUser;
    },
    setOtpRequired: (state, action) => {
      state.otpRequired = action.payload;
    },
    setAlternativeOtpMethod: (state, action) => {
      state.alternativeOtpMethod = action.payload;
    },
    setTempOtpAuthToken: (state, action) => {
      state.tempOtpAuthToken = action.payload;
    },
    setSsoAuthToken: (state, action) => {
      state.ssoAuthToken = action.payload;
    },
    setIsExistingUserWithoutSso: (state, action) => {
      state.isExistingUserWithoutSso = action.payload;
    },
    clearSsoAuthToken: (state) => {
      state.ssoAuthToken = null;
    },
    clearTempOtpAuthToken: (state) => {
      state.tempOtpAuthToken = null;
    },
    clearProfileData: (state) => {
      state.displayName = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.profileUrl = null;
      state.ssoProvider = null;
    },
  },
});

export const {
  setProfileData,
  clearProfileData,
  setSsoAuthToken,
  clearSsoAuthToken,
  setOtpRequired,
  setAlternativeOtpMethod,
  setTempOtpAuthToken,
  clearTempOtpAuthToken,
  setIsExistingUserWithoutSso,
} = oauthSlice.actions;

export default oauthSlice.reducer;
