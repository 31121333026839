import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import styles from './TextInput.styles';
import { TextBoxFontSize } from './text-box-font-size';

type TextInputProps = {
  name?: string;
  label: string;
  value?: any;
  isInvalid?: boolean;
  isShowHelpText?: boolean;
  defaultValue?;
  className?: string;
  fontSize?: TextBoxFontSize;
  type?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  invalidMessage?: React.ReactNode;
  placeholder?: string;
  suffixLabel?: React.ReactNode;
  bottomHelperText?: React.ReactNode;
  [key: string]: any
}

const useStyles = createUseStyles<string, {
  isFocused: boolean,
  isInvalid: boolean,
  isShowHelpText: boolean,
  fontSize: TextBoxFontSize,
  withSuffix: boolean
}>(styles);

export default ({
  name = '',
  label = '',
  className,
  fontSize = 1.6,
  type = '',
  onFocus,
  onBlur,
  onChange,
  isInvalid = false,
  isShowHelpText = false,
  invalidMessage,
  value,
  inputRef,
  defaultValue,
  placeholder,
  suffixLabel,
  bottomHelperText,
  ...props
}: TextInputProps) => {
  const { t } = useTranslation();

  let valueProps = {};
  let placeholderProps = {};

  if (typeof value !== 'undefined') {
    valueProps = { value };
  }

  if (typeof defaultValue !== 'undefined') {
    valueProps = { defaultValue };
  }

  if (typeof placeholder !== 'undefined') {
    placeholderProps = { placeholder: t(placeholder) };
  }

  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isPasswordType = type === 'password';
  const withSuffix = isPasswordType || !!suffixLabel;
  const classes = useStyles({ isFocused, isInvalid, isShowHelpText, fontSize, withSuffix });

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const getCalculatedType = () => {
    if (type === 'password' && isPasswordVisible) {
      return 'text';
    }
    return type;
  };

  return (
    <div className={className}>
      <div className={classes.wrapper}>
        <div className={classes.inputGroupContainer} onFocus={handleOnFocus} onBlur={handleOnBlur}>
          <input className={classes.input}
            ref={inputRef}
            {...valueProps}
            type={getCalculatedType()}
            name={name}
            onChange={e => onChange && onChange(e.target.value)}
            required
            pattern=".*\S.*"
            {...placeholderProps}
            {...props}
          />
          <label className={classes.label} htmlFor={name}><Trans>{label}</Trans></label>
          {isPasswordType && (
            <span className={classes.suffixLabel} onClick={() => setIsPasswordVisible(isVisible => !isVisible)}>
              {isPasswordVisible ? <MdVisibility/> : <MdVisibilityOff/>}
            </span>
          )}
          {suffixLabel && (
            <span className={classes.suffixLabel}>{suffixLabel}</span>
          )}
        </div>
      </div>
      {!!invalidMessage && <span className={classes.invalidControlMessage}><Trans>{invalidMessage}</Trans></span>}
      {bottomHelperText && <p className={classes.bottomHelperText}><Trans>{bottomHelperText}</Trans></p>}
    </div>
  );
};
