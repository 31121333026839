import { DepositNetworkOption } from 'types/wallet/depositNetworkOption';

export default (network: DepositNetworkOption, coinId: string) => {
  if (network.isNativeCoinOfNetwork) {
    return coinId;
  }

  switch (network.id) {
    case 'ETHEREUM': return `${coinId} (ERC20)`;
    case 'TRON': return `${coinId} Tron (TRC20)`;
    default: return coinId;
  }
};
