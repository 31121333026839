import { transactionDescriptions } from '@cakedefi/cake-sdk/assets';
import i18n from '../i18n';

export default function getTransactionDescription(txType, coin, batch = '', batchCoinId = '', pairId = '', yieldVaultCoinId = '', yieldVaultRewardCoinid = '') {
  const transactionDescriptionKey = transactionDescriptions[txType];
  if (txType.includes('YIELD_VAULT')) {
    if (['ENTER_YIELD_VAULT', 'EXIT_YIELD_VAULT'].includes(txType) && coin !== 'DUSD') {
      return `${i18n.t(transactionDescriptionKey, { coin })} ${i18n.t('({{coinId}} payout)', { coinId: yieldVaultRewardCoinid })}`;
    }
    if (['YIELD_VAULT_REWARD', 'YIELD_VAULT_REWARD_BOOSTER'].includes(txType) && coin === 'DUSD' && yieldVaultCoinId !== 'DUSD') {
      return `${i18n.t(transactionDescriptionKey, { coin })} (${i18n.t('{{coin}} YieldVault reward', { coin: yieldVaultCoinId })})`;
    }
  }
  return i18n.t(transactionDescriptionKey, { coin, batch, batchCoinId, pairId });
}
