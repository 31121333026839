import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KycModalCodeType } from 'views/containers/SharedComponents/kyc/constants/kycModalCodes';

export type ToShowDialogTypeOnLoginType =
  'PHONE_VERIFICATION' |
  'UNVERIFIED' |
  KycModalCodeType |
  null;

interface InitialState {
  redirectOnLogin: any,
  modalOpenOnRedirect: {
    modal: any,
    props: any,
  },
  isDepositModalOpen: any,
  toShowDialogTypeOnLogin: ToShowDialogTypeOnLoginType,
  isShowDialogOnLogin: boolean,
}

const initialState: InitialState = {
  redirectOnLogin: null,
  modalOpenOnRedirect: {
    modal: null,
    props: null,
  },
  isDepositModalOpen: false,

  // generic store to show dialog on login for all features, please set to null after showing
  toShowDialogTypeOnLogin: null,
  // this is to trigger the dialog to show, because login success is invoked before /me endpoint
  isShowDialogOnLogin: false,
};

// Shall use this to store navigation state instead of cakepool
const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setRedirectOnLogin: (state, action) => {
      state.redirectOnLogin = action.payload;
    },
    setModalOpenOnRedirect: (state, action) => {
      state.modalOpenOnRedirect = action.payload;
    },
    clearModalOpenOnRedirect: (state) => {
      state.modalOpenOnRedirect = initialState.modalOpenOnRedirect;
    },
    setIsDepositModalOpen: (state, action) => {
      state.isDepositModalOpen = action.payload;
    },
    setToShowDialogTypeOnLogin: (state, action: PayloadAction<ToShowDialogTypeOnLoginType>) => {
      state.toShowDialogTypeOnLogin = action.payload;
    },
    setIsShowDialogOnLogin: (state, action: PayloadAction<boolean>) => {
      state.isShowDialogOnLogin = action.payload;
    },
  },
});

export const {
  setRedirectOnLogin,
  setModalOpenOnRedirect,
  clearModalOpenOnRedirect,
  setIsDepositModalOpen,
  setToShowDialogTypeOnLogin,
  setIsShowDialogOnLogin,
} = navigationSlice.actions;

export default navigationSlice.reducer;
