import moment from 'moment';
import { RecentTransaction, UserActivityDetail } from '../schema/custom';
import getCsvExportDescription from './getCsvExportDescription';
import { AdminExportCsvRow, UserExportCsvRow } from '../lib/customType';

interface ExportCsvRow {
  Date: string;
}

function getTransactionObj(tx: UserActivityDetail, date, amount, operation, label, isUser): UserExportCsvRow | AdminExportCsvRow {
  if (isUser) {
    return {
      Date: moment(date).format(),
      Operation: `${getCsvExportDescription(operation, tx)}${(label !== '' ? ` (${label})` : '')}`,
      Amount: amount,
      Cryptocurrency: tx.coin.id,
      'FIAT value': tx.usdEquivalent as any,
      'FIAT currency': 'USD',
      'Transaction ID': tx.txid,
      'Withdrawal address': tx.extAddress,
      Reference: tx.id,
    };
  }

  // stick to legacy format (AdminExportCsvRow)
  // just in case admin has some tool (like gsheet script that need the column in specific name)
  // preferrably just remove this redundant type
  return {
    id: tx.id,
    confirmedAt: date,
    Date: date,
    Amount: amount,
    usdEquivalent: tx.usdEquivalent as any,
    extAddress: tx.extAddress,
    status: tx.status,
    coin: tx.coin.id,
    operation: getCsvExportDescription(operation, tx),
  };
}

export async function filterUserExportTransactionData(transactions: RecentTransaction[]): Promise<UserExportCsvRow[]> {
  return filterExportTransactionData<UserExportCsvRow>(transactions, true);
}

export async function filterAdminExportTransactionData(transactions: RecentTransaction[]): Promise<AdminExportCsvRow[]> {
  return filterExportTransactionData<AdminExportCsvRow>(transactions, false);
}

async function filterExportTransactionData<T extends ExportCsvRow>(transactions: RecentTransaction[], isUser: boolean): Promise<Array<T>> {
  const filteredTransaction: Array<UserExportCsvRow|AdminExportCsvRow> = [];
  transactions.forEach((transaction) => {
    if (transaction.getTransactionCategory() === 'SPROD') {
      filteredTransaction.push(getTransactionObj(
        transaction.sprodExit,
        transaction.sprodExit.confirmedAt,
        transaction.getNetNative(),
        'EXIT_SPROD', '', isUser,
      ));
    } else {
      filteredTransaction.push(getTransactionObj(
        transaction.native,
        transaction.native.confirmedAt,
        transaction.getNetNative(),
        'EXIT_SPROD', '', isUser,
      ));
    }
  });

  filteredTransaction.sort((a, b) => new Date(b.Date).valueOf() - new Date(a.Date).valueOf());
  return filteredTransaction as any as T[];
}
