import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { getPromotionActiveDates } from 'referral/referralPromoSelector';
import { useCakeSelector } from 'app/rootReducer';
import { getCampaignDateWithTranslation } from '../referralUtils';

const useStyles = createUseStyles({
  header: {
    fontSize: '3.4rem',
    letterSpacing: '0.25px',
    lineHeight: '4rem',
  },
});

export const TigerLunarPromoHeroRight = () => {
  const classes = useStyles();
  const { selectedLanguage } = useCakeSelector(state => state.languageRegionSettings);
  const { promoBonusActiveFrom, promoBonusActiveUntil } = useCakeSelector(getPromotionActiveDates);
  const CAMPAIGN_START_DATE = getCampaignDateWithTranslation(selectedLanguage, promoBonusActiveFrom);
  const CAMPAIGN_END_DATE = getCampaignDateWithTranslation(selectedLanguage, promoBonusActiveUntil);

  return (
    <>
      <h1 className={classes.header}>
        <Trans>Boost Your Rewards to Infinity and Beyond.</Trans>
      </h1>
      <p>
        <Trans>Unleash your DFI rewards with the Year of the Tiger celebration. Valid from {{ CAMPAIGN_START_DATE }} to {{ CAMPAIGN_END_DATE }}, after your referees have deposited $50 USD, both you and your referees will receive your regular referral and signup bonus, as well as an additional 8 DFI reward in your staking freezer. There is no limit to the amount of referees and DFI rewards that you can have.</Trans>
      </p>
    </>
  );
};
