import React, { Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { isEmpty } from '../../utils';
import 'react-intl-tel-input/dist/main.css';

export default class TelephoneInput extends Component {
  render() {
    const {
      helpText,
      value,
      onFlagChange,
      onNumberChange,
    } = this.props;
    return (
      <div className="telephone-input-wrapper">
        <IntlTelInput
          value={value}
          autoPlaceholder={true}
          separateDialCode={true}
          onSelectFlag={onFlagChange}
          onPhoneNumberChange={onNumberChange}
          containerClassName="intl-tel-input"
          inputClassName="input-group intl-tel-input-element"
          helpText={helpText}
          autoComplete="chrome-off"
          fieldId="telephoneField" />
        {this.renderHelpText()}
      </div>
    );
  }

  renderHelpText() {
    const { helpText } = this.props;
    if (isEmpty(helpText)) return null;
    return <small className="help-text">{helpText}</small>;
  }
}
