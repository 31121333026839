export default function getCurrencySymbol(currency) {
  switch (currency) {
    case 'EUR': {
      return '€';
    }
    case 'BTC': {
      return 'BTC';
    }
    case 'CNY': {
      return '¥';
    }
    case 'SGD': {
      return 'S$';
    }
    case 'CHF':
      return 'CHF';
    case 'AUD':
      return 'AU$';
    case 'MYR':
      return 'RM';
    case 'CAD':
      return 'CA$';
    case 'GBP':
      return '£';
    default: {
      return '$';
    }
  }
}
