import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import sdk from 'sdk';
import { DEXPrice } from '@cakedefi/cake-sdk/schema/dex';
import { DEXSwapExpiry } from '@cakedefi/cake-sdk/schema/dex/dex-swap-expiry';
import { fetchDusdStabFee } from 'wallet/components/DEXModal/state/dexModalSlice';
import { AddLiquidityState } from '../../types/liquidity-mining/add-liquidity-state';
import { calculateSharesReceivedByPrimaryAmount } from './utils/calculateSharesReceivedByPrimaryAmount';

export const initialState: AddLiquidityState = {
  // liquidity prices
  validUntilTimestamp: null,
  liquidityMiningPairId: null,
  blockHeight: null,
  primaryCoinSharePrice: null,
  secondaryCoinSharePrice: null,
  dexPriceStability: null,
  coinPrices: [],

  // dex pair prices
  block: null,
  primaryCoinReserve: null,
  secondaryCoinReserve: null,
  primaryCoinPrice: null,
  secondaryCoinPrice: null,
  LiquidityMiningPairId: null,

  // modal state
  loadingFetchAddLiquidityPrices: false,
  loadingFetchPairPrice: false,
  error: null,
  primaryCoinSimpleModeInput: '',
  primaryCoinInput: '',
  secondaryCoinInput: '',
  fees: 0,
  totalShares: null,
  simpleMode: false,
  simpleModeSwapExpiryInHours: 12,
  normalSwapExpiryInHours: 12,
  selectedPrimaryCoin: '',
  isCoinsSwappable: false,
  minimumAmountInUsd: 0,
  dusdStabFeeRate: 0,

  pairCoinInput: '',
};

export const fetchAddLiquidityPrices = createAsyncThunk<any, string>('liquidityMining/fetchAddLiquidityPrices', pairId => sdk.LiquidityMiningApi.addLiquidityPrices(pairId));

export const fetchPairPrice = createAsyncThunk<DEXPrice, string>(
  'liquidityMining/fetchPairPrice',
  (pairId) => sdk.DexSwapApi.getPrice(pairId),
);

export const fetchExpiryTime = createAsyncThunk<DEXSwapExpiry>(
  'liquidityMining/fetchExpiryTime',
  () => sdk.DexSwapApi.getSwapExpiry(),
);

export const fetchLMMinAmountInUsd = createAsyncThunk(
  'liquidity-mining/minimum', () => sdk.LiquidityMiningApi.getMinimumInput(),
);

const addLiquiditySlice = createSlice({
  name: 'addLiquidity',
  initialState,
  reducers: {
    setPairCoinInput: (state, action) => {
      state.pairCoinInput = action.payload;
    },
    setPrimaryCoinInput: (state, action) => {
      state.primaryCoinInput = action.payload;
    },
    setSecondaryCoinInput: (state, action) => {
      state.secondaryCoinInput = action.payload;
    },
    recalculateSecondaryCoinInputAndTotalShares: (state, action: PayloadAction<{primaryDecimals: number, secondaryDecimals: number}>) => {
      const { primaryCoinSharePrice, secondaryCoinSharePrice, primaryCoinInput } = state;
      const { primaryDecimals, secondaryDecimals } = action.payload;
      const {
        sharesReceived,
        secondaryAmount,
      } = calculateSharesReceivedByPrimaryAmount(primaryCoinInput, primaryDecimals, secondaryDecimals, primaryCoinSharePrice, secondaryCoinSharePrice);
      state.totalShares = sharesReceived;
      state.secondaryCoinInput = secondaryAmount as any;
    },
    setPrimaryCoinSimpleModeInput: (state, action) => {
      state.primaryCoinSimpleModeInput = action.payload;
    },
    setFees: (state, action) => {
      state.fees = action.payload;
    },
    setTotalShares: (state, action) => {
      state.totalShares = action.payload;
    },
    setSimpleMode: (state, action) => {
      state.simpleMode = action.payload;
    },
    toggleSimpleMode: (state) => {
      state.simpleMode = !state.simpleMode;
      state.primaryCoinSimpleModeInput = '';
      state.primaryCoinInput = '';
      state.secondaryCoinInput = '';
      state.totalShares = null;
    },
    changePrimaryCoin: (state, action) => {
      state.selectedPrimaryCoin = action.payload;
    },
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddLiquidityPrices.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      loadingFetchAddLiquidityPrices: false,
      error: null,
    }));

    builder.addCase(fetchAddLiquidityPrices.pending, (state) => {
      state.loadingFetchAddLiquidityPrices = true;
      state.error = null;
    });

    builder.addCase(fetchAddLiquidityPrices.rejected, (state, action) => {
      state.loadingFetchAddLiquidityPrices = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchPairPrice.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      loadingFetchPairPrice: false,
      error: null,
    }));

    builder.addCase(fetchPairPrice.pending, (state) => {
      state.loadingFetchPairPrice = true;
      state.error = null;
    });

    builder.addCase(fetchPairPrice.rejected, (state, action) => {
      state.loadingFetchPairPrice = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchExpiryTime.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      loadingFetchPairPrice: false,
      error: null,
    }));

    builder.addCase(fetchExpiryTime.pending, (state) => {
      state.loadingFetchPairPrice = true;
      state.error = null;
    });

    builder.addCase(fetchExpiryTime.rejected, (state, action) => {
      state.loadingFetchPairPrice = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchLMMinAmountInUsd.pending, (state) => {
      state.loadingFetchPairPrice = true;
      state.error = null;
    });
    builder.addCase(fetchLMMinAmountInUsd.fulfilled, (state, action) => {
      state.loadingFetchPairPrice = false;
      state.error = null;
      state.minimumAmountInUsd = action.payload.minimumAmountInUsd;
    });
    builder.addCase(fetchLMMinAmountInUsd.rejected, (state, action) => {
      state.loadingFetchPairPrice = false;
      state.error = action.error?.message;
    });
    builder.addCase(fetchDusdStabFee.pending, (state) => {
      state.loadingFetchPairPrice = true;
    });
    builder.addCase(fetchDusdStabFee.fulfilled, (state, { payload }) => {
      state.loadingFetchPairPrice = false;
      state.dusdStabFeeRate = +(payload?.fee);
    });
    builder.addCase(fetchDusdStabFee.rejected, (state, action) => {
      state.loadingFetchPairPrice = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setPairCoinInput,
  setPrimaryCoinInput,
  setSecondaryCoinInput,
  setPrimaryCoinSimpleModeInput,
  setFees,
  recalculateSecondaryCoinInputAndTotalShares,
  setTotalShares,
  setSimpleMode,
  toggleSimpleMode,
  changePrimaryCoin,
  clear,
} = addLiquiditySlice.actions;

export default addLiquiditySlice.reducer;
