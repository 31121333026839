import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import HeaderLogo from 'views/containers/MainLayout/HeaderLogo';
import TopBanner from 'common/components/WelcomeBanners/TopBanner/TopBanner';
import RegisterForm from '../RegisterForm';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12rem',
    marginBottom: '12rem',
    '& > a': {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Variables.fontSize.h1,
    marginBottom: Variables.large,
  },
  topBanner: {
    display: 'none',
    [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
      display: 'block',
      marginBottom: '24px',
    },
  },
});

const RegisterSection = ({ page, withLogo = false, registerStep }) => {
  const classes = useStyles();
  return (
    <article className="floating">
      <div>
        {registerStep === 1 && (
          <div className={classes.topBanner}>
            <TopBanner />
          </div>
        )}
        <h1 className={classes.title}>
          <Trans>Sign up</Trans>
        </h1>
        <div>
          <RegisterForm page={page} />
        </div>
        {withLogo && (
          <div className={classes.header}>
            <HeaderLogo invert={true} />
          </div>
        )}
      </div>
    </article>
  );
};

export default RegisterSection;
