export enum AccessRights {
  Stake = 'stake',
  StakeWithCoinSwap = 'stakeWithCoinSwap',
  Unstake = 'unstake',
  Withdraw = 'withdraw',
  Deposit = 'deposit',
  Lending = 'lending',
  EnterSprod = 'enterSprod',
  AcceptReferralRules = 'acceptReferralRules',
  BuyCryptoWithFiat = 'buyCryptoWithFiat',
  AddLiquidity = 'addLiquidity',
  RemoveLiquidity = 'removeLiquidity',
  SwapOnDex = 'swapOnDex',
  Stocks = 'stocks',
  Commodities = 'commodities',
  Etfs = 'etfs',
  NewReferrals = 'newReferrals',
  Promos = 'promos',
  EnterEarn = 'enterEarn',
  ExitEarn = 'exitEarn',
  EnterYieldVault = 'enterYieldVault',
  ExitYieldVault = 'exitYieldVault',
  Subscription = 'subscription',
  EnterStaking = 'enterStaking',
  Dca = 'dca',
  KycTier2 = 'kycTier2',
  WithdrawToMetaChain = 'withdrawToMetaChain',
  UsdtAccess = 'usdtAccess',
}
