import { CakeBanxaCreateOrder } from '@cakedefi/cake-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCakeSelector } from '../../../app/rootReducer';
import { useCakeDispatch } from '../../../app/store';
import Button from '../../../common/components/Button/Button';
import ModalLoadingIndicator from '../../../common/components/ModalLoadingIndicator/ModalLoadingIndicator';
import { OnRampProviderType } from '../../../types/onramp/onramp-provider';
import { SelectedProviderSelectorModel } from '../../../types/onramp/state';
import { TRANSAK_CHECKOUT_PARAMS } from '../../walletService';
import BanxaPricePreview from './components/BanxaPricePreview';
import DisclaimerAgree from './components/DisclaimerAgree';
import { INITIAL, useFiatBuyStyles, useModalStyles } from './FiatBuyModal';
import { fiatSwappableSelector, selectedProviderSelector } from './fiatBuyModalSelector';
import { cancelBanxaOrder, createBanxaOrder, fetchTransakCheckoutURL } from './fiatBuyModalSliceThunk';

const ConfirmationPage = ({ goTo, onClose }) => {
  const [banxaOrder, setBanxaOrder] = useState<CakeBanxaCreateOrder>();
  const [priceValid, setPriceValid] = useState<boolean>(true);
  const { provider }: SelectedProviderSelectorModel = useSelector(selectedProviderSelector);
  const {
    coin: fiatSwappableCoinId,
  } = useCakeSelector(fiatSwappableSelector);
  const { type, name: providerName } = provider;
  const modalClasses = useModalStyles();
  const fiatBuyClasses = useFiatBuyStyles();
  const isRenderTnC = type === OnRampProviderType.BANXA;
  const [userHasRead, setUserHasRead] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useCakeDispatch();

  const handleError = useCallback((err: { message: string }) => {
    const { message } = err;
    window.cakepool.showAlert('error', <span><Trans>{message}</Trans></span>);
    setIsLoading(false);
    onClose();
  }, [onClose]);

  const handleBanxaCheckout = useCallback(async () => {
    const response: any = await dispatch(createBanxaOrder());
    const payload = response.payload as CakeBanxaCreateOrder;
    if (!payload) {
      handleError(response.error);
      return;
    }
    if (fiatSwappableCoinId) {
      setBanxaOrder(payload);
      setIsLoading(false);
    } else {
      window.location.href = payload.order.checkoutUrl;
    }
  }, [dispatch, fiatSwappableCoinId, handleError]);

  const handleTransakCheckout = useCallback(async () => {
    const { payload: checkoutURL }: { payload: string } = (await dispatch(
      fetchTransakCheckoutURL(),
    )) as any;
    window.location.href = `${checkoutURL}&redirectURL=${window.location.origin}/wallets?${TRANSAK_CHECKOUT_PARAMS}=success`;
  }, [dispatch]);

  const checkout = useCallback(async () => {
    setIsLoading(true);
    try {
      if (type === OnRampProviderType.BANXA) {
        await handleBanxaCheckout();
      } else if (type === OnRampProviderType.TRANSAK) {
        await handleTransakCheckout();
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleBanxaCheckout, handleTransakCheckout, handleError, type]);

  const goBackToInitialPage = useCallback(() => {
    goTo(INITIAL);
    dispatch(cancelBanxaOrder());
  }, [goTo, dispatch]);

  useEffect(() => {
    if (fiatSwappableCoinId) {
      checkout();
    }
  }, [fiatSwappableCoinId, checkout]);

  if (isLoading) {
    return (
      <ModalLoadingIndicator
        placeholder={
          fiatSwappableCoinId
            ? 'Confirming your request'
            : 'Processing your order...'
        }
      ></ModalLoadingIndicator>
    );
  }
  const tncSentence = isRenderTnC ? <span><Trans>Please read {{ providerName }}’s <a href={provider.tnc} target="_blank" rel="noopener noreferrer">Terms of Use</a> before using their services.</Trans>{' '}</span> : '';
  const tncAgreement = isRenderTnC ? <span><Trans>, and to {{ providerName }}'s Terms of Use</Trans></span> : '';
  return (
    <>
      <header className={modalClasses.header}>
        <h2>
          <Trans>Disclaimer</Trans>
        </h2>
        <button
          className={fiatBuyClasses.inlineLinkButton}
          onClick={goBackToInitialPage}
        >
          <Trans>Cancel</Trans>
        </button>
      </header>
      <div className={modalClasses.content}>
        <div className={modalClasses.section}>
          <div className={modalClasses.centerContent}>
            <img
              className={fiatBuyClasses.providerLogo}
              src={provider.icon}
              alt={providerName}
            />
          </div>
        </div>
        <div className={modalClasses.section}>
          <p>
            <span>
              <Trans>You will now leave bake.io for your cryptocurrency purchase to be processed by {{ providerName }}. This service is independently provided by {{ providerName }}, which is a separate platform owned by a third party.</Trans>
            </span>
            <span>{' '}{tncSentence}</span>
            <span>
              <Trans>For questions relating to these services, please contact <a href={provider.supportUrl}>{{ providerName }} support</a>. Bake does not assume responsibility for any loss or damage relating to {{ providerName }}'s services.</Trans>
            </span>
          </p>
        </div>

        {!!banxaOrder && (
          <BanxaPricePreview
            banxaOrder={banxaOrder}
            fiatSwappableCoinId={fiatSwappableCoinId}
            priceValid={priceValid}
            tncAgreement={tncAgreement}
            userHasRead={userHasRead}
            setUserHasRead={setUserHasRead}
            setPriceValid={setPriceValid}
            goBack={() => goTo(INITIAL)}
          ></BanxaPricePreview>
        )}

        {!banxaOrder && (
          <>
            <div className={modalClasses.section}>
              <DisclaimerAgree
                userHasRead={userHasRead}
                setUserHasRead={setUserHasRead}
                tncAgreement={tncAgreement}
              />
            </div>

            <div className={modalClasses.section}>
              <div className={modalClasses.rightContent}>
                <Button
                  primary
                  disabled={!userHasRead}
                  onClick={checkout}
                >
                  <Trans>Checkout</Trans>
                </Button>
              </div>
            </div>
          </>
        )}
        <div className={modalClasses.spacer} />
      </div>
    </>
  );
};

export default ConfirmationPage;
