import React from 'react';
import { ReferralPromoTemplate } from 'referral/referralType';
import ElevenElevenPromoHeroImage from './ElevenElevenPromoHeroImage';
import { ElevenElevenPromoHeroRight } from './ElevenElevenPromoHeroRight';
import ElevenElevenRulesModalContent from './ElevenElevenRulesModalContent';

export const ElevenElevenPromoTemplate: ReferralPromoTemplate = {
  heroImageComponent: <ElevenElevenPromoHeroImage />,
  heroRightComponent: <ElevenElevenPromoHeroRight />,
  rulesModalContent: <ElevenElevenRulesModalContent />,
  referrerBonusPerReferralUsd: 21,
};
