import { SsoAuthPersonData } from '../../schema/sso';
import { CakeApiBase } from '../CakeApiBase';

export class GoogleSsoApi extends CakeApiBase {
  async getAuthoriseUrl() {
    return this.requestManager.get('auth/google');
  }

  async getPersonData(payload = {}) {
    return this.requestManager.get<SsoAuthPersonData>('auth/google-callback', payload);
  }

  async loginWithIdToken(idToken: string) {
    return this.requestManager.post<SsoAuthPersonData>('auth/google/id-token', { idToken });
  }
}
