export enum StateStatus {
  IDLE = 'IDLE',
  STARTED = 'STARTED',
  LOADING = 'LOADING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export type GenericState<DataType> = {
  data?: DataType,
  status: StateStatus,
  error?: null | {
    message?: string,
  },
};

export type GenericStateWithPage<T> = GenericState<T> & {
  page: number,
};

export type GenericStateWithInputs<DataType, InputsType> = GenericState<DataType> & {
  inputs: InputsType,
};
