import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import BigNumber from 'bignumber.js';
import { CoinMap } from 'types/coin/coin-map';
import { convertBTCOrETHToCryptoAmount } from 'utils/convertBTCETHToCryptoAmount';
import { convertCryptoToBTCorETHAmount } from 'utils/convertCryptoToBTCorETHAmount';

export const isStakeWithSharesCoin = inputs => inputs.swappableCoin === inputs.coinToSwap;

export const setCoinToSwapAmount = (state, pricing: CoinPrice[], coins: CoinMap, withoutMarkup: boolean, isStakingOptimized?: boolean) => {
  const { swappableCoin, swappableCoinAmount, coinToSwap } = state.inputs;
  if (!pricing || !swappableCoinAmount) {
    return;
  }

  if (isStakeWithSharesCoin(state.inputs)) {
    let coinToSwapAmount = swappableCoinAmount;
    if (isStakingOptimized) {
      coinToSwapAmount = new BigNumber(coinToSwapAmount).multipliedBy(coins[coinToSwap]?.shareRatio ?? 1);
      if (coinToSwapAmount.dp() > coins[coinToSwap]?.decimals) {
        coinToSwapAmount = coinToSwapAmount.toFixed(coins[coinToSwap]?.decimals);
      }
    }
    state.inputs.coinToSwapAmount = coinToSwapAmount;
    return;
  }

  state.inputs.coinToSwapAmount = convertCryptoToBTCorETHAmount(swappableCoin, swappableCoinAmount, coinToSwap, coins[coinToSwap]?.decimals, pricing, withoutMarkup);
};

export const setSwappableCoinAmount = (state, pricing: CoinPrice[], withoutMarkup: boolean, coins?: CoinMap, isStakingOptimized?: boolean) => {
  const { swappableCoin, swappableCoinDecimals, coinToSwap, coinToSwapAmount } = state.inputs;
  if (!pricing || !coinToSwapAmount) {
    return;
  }

  if (isStakeWithSharesCoin(state.inputs)) {
    let swappableCoinAmount = coinToSwapAmount;
    if (isStakingOptimized) {
      swappableCoinAmount = new BigNumber(coinToSwapAmount).dividedBy(coins[coinToSwap]?.shareRatio ?? 1);
      if (swappableCoinAmount.dp() > coins[coinToSwap]?.decimals) {
        swappableCoinAmount = swappableCoinAmount.toFixed(coins[coinToSwap]?.decimals);
      }
    }
    state.inputs.swappableCoinAmount = swappableCoinAmount;
    return;
  }

  state.inputs.swappableCoinAmount = convertBTCOrETHToCryptoAmount(coinToSwap, coinToSwapAmount, swappableCoin, swappableCoinDecimals, pricing, withoutMarkup);
};

export const isCoinSwapBaseCoin = (walletByCoins, coinId) => {
  const coinToSwapCoin = walletByCoins.find(({ id }) => id === coinId);
  return coinToSwapCoin && coinToSwapCoin.coinSwapBaseCoin;
};
