export default {
  loadingDot: ({ delay, offset }) => ({
    fontSize: '4px',
    color: 'hsl(0,0%,80%)',
    animation: `$fadedIn 1s ease-in-out ${delay}ms infinite`,
    backgroundColor: 'currentColor',
    borderRadius: '1em',
    display: 'inline-block',
    marginLeft: offset ? '1em' : null,
    height: '1em',
    verticalAlign: 'top',
    width: '1em',
  }),
  '@keyframes fadedIn': {
    '0%, 80%, 100%': { opacity: 0 },
    '40%': { opacity: 1 },
  },
};
