import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ModalStyles from 'common/styles/Modal.styles';
import Variables from 'Variables.styles';
import { ModalContent, ModalHeader } from 'modals';
import { ModalContext } from 'modals/ModalContext';

const useStyles = createUseStyles({
  ...ModalStyles,
  paragraph: {
    fontSize: '1.6rem',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
  lastParagraph: {
    marginBottom: '3.2rem',
  },
  table: {
    fontSize: '1.6rem',
    border: '1px solid black',
    padding: Variables.small,
    textAlign: 'center',
  },
  tableNote: {
    fontStyle: 'italic',
    fontSize: '1.2rem',
  },
});

const EasterRulesModalContent = () => {
  const { closeModal } = useContext(ModalContext);
  const classes = useStyles();

  return (
    <>
      <ModalHeader title='Easter Campaign Rules' onClose={closeModal}/>
      <ModalContent>
        <div className={classes.contentMini}>
          <ul className={classes.paragraph}>
            <li>
              <Trans>The <b>Easter Egg</b> promotion will commence at <b>18:00 SGT (10:00 UTC) on 11th April, 2022</b> and will run until <b>18:00 SGT (10:00 UTC) on 25th April, 2022.</b></Trans>
            </li>

            <li>
              <Trans>During the promotional period, you have the chance to get increased sign up bonuses:</Trans>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>For every successful referral (account verified with a minimum $50 allocated for 28 days into Lending or into the 30-day Freezer), the <b>referrer</b> will receive <b>$20 worth of DFI</b> in their account (Freezer).</Trans>
                </li>
                <li>
                  <Trans>The <b>referee</b> will receive <b>$40 worth of DFI</b>, in their account (Freezer).</Trans>
                </li>
                <li>
                  <Trans><b>Influencer accounts</b> will also receive a flat <b>$10 worth of DFI</b> on top of their current referral plan. This applies to referrer and referee amounts. All other individual referral plan metrics stay the same.</Trans>
                </li>
                <li>
                  <Trans><b>Referral bonuses</b> will automatically be allocated to the Freezer for 180 days.</Trans>
                </li>
                <li>
                  <Trans><b>Referees must complete their registration, account verification and allocate a minimum of $50 for 28 days into Lending or into the 30-day Freezer during the promotional period, from 18:00 SGT (10:00 UTC) on 11th April, 2022 to 18:00 SGT (10:00 UTC) on 25 April, 2022</b>, in order for the referrer and the referee to receive the promotional referral bonus.</Trans>
                </li>
                <li>
                  <Trans>Referees who sign up, or complete their sign up prior to the start of the promotional campaign period, or after the end of the promotional campaign will not be subject to the increased bonus on offer.</Trans>
                </li>
              </ul>
            </li>

            <span style={{ display: 'flex', width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
              <table className={classes.table} style={{ width: '100%' }}>
                <tr>
                  <th className={classes.table}></th>
                  <th className={classes.table}><Trans>Current referral bonus (for a min $50 allocation for 28 days into Lending or into the 30-day Freezer )</Trans></th>
                  <th className={classes.table}><Trans>Additional Easter bonus (11 - 25 April 2022)</Trans></th>
                  <th className={classes.table}><Trans>Total bonuses</Trans></th>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>Referral</Trans></td>
                  <td className={classes.table}><Trans>$30</Trans></td>
                  <td className={classes.table}><Trans>$10</Trans></td>
                  <td className={classes.table}><Trans>$40</Trans></td>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>Referrer</Trans></td>
                  <td className={classes.table}><Trans>$10</Trans></td>
                  <td className={classes.table}><Trans>$10</Trans></td>
                  <td className={classes.table}><Trans>$20</Trans></td>
                </tr>
              </table>
            </span>
            <br />

            <li>
              <Trans>Referees must use their referrer’s unique referral code (as displayed on https://app.bake.io/referral) in order to receive the bonus.</Trans>
            </li>

            <li>
              <Trans>In addition, as part of the <b>Easter egg</b> promotion, <b>all users have the chance to earn up to $750 worth of DFI</b> as added bonuses — by collecting a total of 5 coloured eggs — <b>on top</b> of their referral bonus.</Trans>
              <ul className={classes.paragraph}>
                <li>
                  <Trans>Fill up your Easter nest by collecting coloured Easter eggs. Each egg gives you a delicious one-time extra bonus. See below for Easter egg rewards:</Trans>
                </li>
                <ul className={classes.paragraph}>
                  <li>
                    <Trans><b>Red egg</b>: receive <b>1 x extra bonus</b> payment of <b>$50 worth of DFI (2x the referral bonus)</b> when you refer 2 new bakers.</Trans>
                  </li>
                  <li>
                    <Trans><b>Green egg</b>: receive <b>1 x extra bonus</b> payment of <b>$100 worth of DFI (4x the referral bonus)</b> when you refer 5 new bakers.</Trans>
                  </li>
                  <li>
                    <Trans><b>Blue egg</b>: receive <b>1 x extra bonus</b> payment of <b>$200 worth of DFI (8x the referral bonus)</b> when you refer 10 new bakers.</Trans>
                  </li>
                  <li>
                    <Trans><b>Golden egg</b> and complete the Easter nest: receive <b>1 x extra bonus</b> payment of <b>$400 worth of DFI (16x the referral bonus)</b> when you refer 50 or more new bakers.</Trans>
                  </li>
                </ul>
              </ul>
            </li>

            <span style={{ display: 'flex', width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
              <table className={classes.table} style={{ width: '100%' }}>
                <tr>
                  <th className={classes.table}><Trans>Total Referrals</Trans></th>
                  <th className={classes.table}><Trans>Easter Egg</Trans></th>
                  <th className={classes.table}><Trans>Extra Bonus (total)</Trans></th>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>0 - 1</Trans></td>
                  <td className={classes.table}><Trans>None</Trans></td>
                  <td className={classes.table}><Trans>No extra bonus</Trans></td>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>2</Trans></td>
                  <td className={classes.table}><Trans>Red</Trans></td>
                  <td className={classes.table}><Trans><b>1 x extra bonus</b> payment of <b>$50 worth of DFI</b> when the referrer secures 2 new referees (account verified with min $50 allocation for 28 days into Lending or into the 30-day Freezer)</Trans></td>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>5</Trans></td>
                  <td className={classes.table}><Trans>Green</Trans></td>
                  <td className={classes.table}><Trans><b>1 x extra bonus</b> payment of <b>$100 worth of DFI</b> when the referrer secures 5 new referees (account verified with min $50 allocation for 28 days into Lending or into the 30-day Freezer)</Trans></td>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>10</Trans></td>
                  <td className={classes.table}><Trans>Blue</Trans></td>
                  <td className={classes.table}><Trans><b>1 x extra bonus</b> payment of <b>$200 worth of DFI</b> when the referrer secures 10 new referees (account verified with min $50 allocation for 28 days into Lending or into the 30-day Freezer)</Trans></td>
                </tr>
                <tr>
                  <td className={classes.table}><Trans>50+</Trans></td>
                  <td className={classes.table}><Trans>Golden</Trans></td>
                  <td className={classes.table}><Trans><b>1 x extra bonus</b> payment of <b>$400 worth of DFI</b> when the referrer secures 50 new referees (account verified with min $50 allocation for 28 days into Lending or into the 30-day Freezer)</Trans></td>
                </tr>
              </table>
            </span>
            <br />

            <li>
              <Trans>This promotion is not just a celebration of the Easter season, but also a celebration of our entire user base. This is an opportunity for us to give back to all of you hard working bakers, who are always pushing to go that extra mile.</Trans>
            </li>

            <li>
              <Trans>For the duration of the promotion, you can earn an additional <b>$750 worth of DFI</b> on top, as extra bonuses, depending on how many Easter eggs you find. These extra bonuses will automatically be allocated to the Freezer product for 180 days.</Trans>
            </li>
          </ul>
        </div>
        <br />
      </ModalContent>
    </>
  );
};

export default EasterRulesModalContent;
