import { CashflowRewards, CoinPrice, FeatureInfo, LendingAPY, LiquidityMiningBalance, Share, UserRewardList, WalletBalance, WalletUserPortfolioBundleAsset, WalletUserPortfolioResponse } from '@cakedefi/cake-sdk';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import { WalletPeriodOption } from 'types/wallet/wallet-period-type';
import appConfig from '../config/app';
import { fetchCoinsPricing } from '../currency/currencySlice';
import { fetchFreezerPageAuthenticated } from '../freezer/freezerSlice';
import { fetchUserLiquidityMiningBalance, updateLiquidityBalanceLoading } from '../liquidity-mining/liquidityMiningSlice';
import sdk from '../sdk';
import { CoinMap } from '../types/coin/coin-map';
import { WalletByCoin } from '../types/coin/wallet-by-coin';
import { fetchTransactionsQueueWithCount } from './components/TransactionQueue/transactionQueueSlice';
import { indexCoinsById, processWalletByCoins } from './walletService';

type WalletState = {
  isBalanceLoading: boolean;
  balances: WalletBalance[];
  coinPrices: CoinPrice[];
  coins: CoinMap;
  coinsWithDiscontinued: CoinMap;
  lapisAPYs: LendingAPY[];
  lmBalances: LiquidityMiningBalance;
  isLmBalancesLoading: boolean;
  rewardsWithPeriod: {
    isLoading: boolean;
    data: CashflowRewards;
    error: string | boolean;
  };
  assetOverview: {
    dailyRewards: CashflowRewards,
    allRewards: CashflowRewards,
    error: string | boolean,
  };
  rewardInfo: FeatureInfo;
  rewards: UserRewardList[];
  rewardsPage: number;
  shares: Share[];
  walletByCoins: WalletByCoin[];
  isLoading: boolean;
  error: string;
  walletUserPortfolio: WalletUserPortfolioResponse;
  bundleAssets: WalletUserPortfolioBundleAsset[];
}

const initialState: WalletState = {
  isBalanceLoading: false,
  walletByCoins: null,
  balances: null,
  lmBalances: null,
  isLmBalancesLoading: true,
  coins: null,
  coinsWithDiscontinued: null,
  coinPrices: null,
  rewardsWithPeriod: {
    data: null,
    isLoading: false,
    error: null,
  },
  assetOverview: {
    dailyRewards: null,
    allRewards: null,
    error: null,
  },
  rewards: null,
  rewardInfo: null,
  rewardsPage: 1,
  lapisAPYs: null,
  shares: null,
  isLoading: false,
  error: null,
  walletUserPortfolio: null,
  bundleAssets: null,
};

const COUNTRY_WHITELISTED_COINS: Record<string, Array<string>> = {
  SG: ['BTC', 'ETH', 'DOT', 'SOL', 'POL', 'SUI', 'USDT', 'USDC'],
};

export const fetchWalletPage = createAsyncThunk('wallet/fetchWalletPage', async (_, thunkAPI) => {
  thunkAPI.dispatch(updateLiquidityBalanceLoading(true));
  const response = await sdk.WalletPageApi.walletPage();
  const { liquidityMiningPairs, currentLiquidityPricesOnDefichain, totalLiquiditySharesForUser, coins, shares } = response;
  const userCountry = (thunkAPI.getState() as any)?.user?.details?.country ?? '';
  const hiddenCoinIds = coins?.filter(coin => coin.isHidden || (COUNTRY_WHITELISTED_COINS[userCountry] && !COUNTRY_WHITELISTED_COINS[userCountry].includes(coin.id))).map(coin => coin.id);
  const cleanedFromHiddenCoinsShares = shares.filter(share => !hiddenCoinIds.includes(share?.id));
  thunkAPI.dispatch(fetchUserLiquidityMiningBalance({ liquidityMiningPairs, currentLiquidityPricesOnDefichain, totalLiquiditySharesForUser }));
  thunkAPI.dispatch(updateLiquidityBalanceLoading(false));
  const visibleCoins = coins?.filter(coin => !coin.isHidden); // prevent to show hidden coins like csETH
  const coinsWithoutDiscontinued = visibleCoins.filter(coin => !coin.discontinued);
  return {
    ...response,
    shares: cleanedFromHiddenCoinsShares,
    coins: coinsWithoutDiscontinued,
    coinsWithDiscontinued: visibleCoins,
  };
});

export const fetchBalance: any = createAsyncThunk<WalletBalance[], any>(
  'wallet/fetchBalances',
  (isBackgroundRequest: boolean) => sdk.WalletApi.getBalance(isBackgroundRequest),
);

export const fetchLMBalance = createAsyncThunk<LiquidityMiningBalance, boolean>(
  'wallet/fetchLMBalances',
  (isBackgroundRequest: boolean) => sdk.LiquidityMiningApi.liquidityMiningBalance(isBackgroundRequest),
);

export const reloadBalances = (isBackgroundRequest?: boolean) => async (dispatch) => {
  const balancePromise = dispatch(fetchBalance(isBackgroundRequest));
  const lmBalancePromise = dispatch(fetchLMBalance(isBackgroundRequest));
  const coinsPricingPromise = dispatch(fetchCoinsPricing(isBackgroundRequest));
  return Promise.all([balancePromise, lmBalancePromise, coinsPricingPromise]);
};

export const fetchCoins: any = createAsyncThunk('wallet/fetchCoins', () => sdk.CoinApi.list());

export const fetchDeposits: any = createAsyncThunk(
  'wallet/fetchDeposits',
  async (nextPage: number) => {
    const query = {
      limit: appConfig.DEFAULT_LISTING_PAGE_SIZE,
      offset: (nextPage - 1) * appConfig.DEFAULT_LISTING_PAGE_SIZE,
    };
    const response = await sdk.WalletApi.listDeposits(query);
    return {
      response,
      nextPage,
    };
  },
);

export const fetchAssetOverview = createAsyncThunk(
  'wallet/fetchAssetOverview',
  async () => {
    try {
      const [dailyRewards, allRewards] = await Promise.all([
        null,
        null,
        // sdk.WalletPageApi.cashflowRewards(WalletPeriodType.DAY, 1),
        // sdk.WalletPageApi.cashflowRewards(WalletPeriodType.ALL),
        //  TODO comment this out if cashflow rewards core proves stable.
      ]);
      return {
        dailyRewards,
        allRewards,
      };
    } catch (error) {
      console.error(error); // eslint-disable-line
      return {
        dailyRewards: null,
        allRewards: null,
        error: true,
      };
    }
  },
);

export const fetchAssetRewards = createAsyncThunk(
  'wallet/fetchAssetRewards',
  async (opt: WalletPeriodOption) => {
    try {
      const response = await sdk.WalletPageApi.cashflowRewards(opt.type, opt.size);
      return {
        response,
      };
    } catch (error) {
      console.error(error); // eslint-disable-line
      return {
        response: null,
        error: true,
      };
    }
  },
);

export const fetchRewards = createAsyncThunk(
  'wallet/fetchRewards',
  async (nextPage: number) => {
    const query = {
      limit: appConfig.DEFAULT_LISTING_PAGE_SIZE,
      offset: (nextPage - 1) * appConfig.DEFAULT_LISTING_PAGE_SIZE,
    };
    const response = await sdk.WalletApi.listUserRewards(query);
    return {
      response,
      nextPage,
    } as any;
  },
);

export const cancelWithdrawal = createAsyncThunk<any, string, ThunkApiConfig>(
  'wallet/cancelWithdrawal',
  async (transactionId, thunkAPI) => {
    const promise = await sdk.WalletApi.cancelWithdrawal(transactionId);
    await thunkAPI.dispatch(reloadBalances());
    thunkAPI.dispatch(fetchTransactionsQueueWithCount({
      page: 1,
    }));
    return promise;
  },
);

export const resendWithdrawalConfirmation = createAsyncThunk<any, string, ThunkApiConfig>(
  'wallet/resendWithdrawalConfirmation',
  async transactionId => sdk.WalletApi.resendWithdrawalConfirmation(transactionId),
);

export const cancelShareOnSale = createAsyncThunk<any, string, ThunkApiConfig>(
  'wallet/cancelShareOnSale',
  async (coinId, thunkAPI) => {
    const promise = await sdk.MarketApi.cancel(coinId);
    await thunkAPI.dispatch(reloadBalances());
    thunkAPI.dispatch(fetchTransactionsQueueWithCount({
      page: 1,
    }));
    return promise;
  },
);

export const fetchWalletUserPortfolio = createAsyncThunk(
  'wallet/user-portfolio',
  async () => sdk.WalletApi.getWalletUserPortfolio(),
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearBalances: (state) => {
      state.balances = null;
    },
    setBalances: (state, action) => {
      state.balances = action.payload;
    },
    setCoinPrices: (state, action) => {
      state.coinPrices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWalletPage.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchWalletPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    builder.addCase(fetchWalletPage.fulfilled, (state, action) => {
      state.isLoading = false;
      const { shares, balances, coins, coinsWithDiscontinued } = action.payload;
      const coinMap = indexCoinsById(coins);
      const coinMapWithDiscontinued = indexCoinsById(coinsWithDiscontinued);
      state.balances = action.payload.balances;
      state.coins = coinMap;
      state.coinsWithDiscontinued = coinMapWithDiscontinued;
      state.walletByCoins = processWalletByCoins(shares, balances, coinMap);
      state.coinPrices = action.payload.coinPrices;
      state.rewards = action.payload.rewards;
      state.rewardInfo = action.payload.rewardInfo;
      state.lapisAPYs = action.payload.lapisAPYs;
      state.shares = action.payload.shares;
      state.bundleAssets = action.payload.bundleAssets;
    });
    builder.addCase(fetchBalance.pending, state => {
      state.isBalanceLoading = true;
    });

    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.isBalanceLoading = false;
      state.balances = action.payload;
    });

    builder.addCase(fetchBalance.rejected, state => {
      state.isBalanceLoading = false;
    });

    builder.addCase(fetchLMBalance.pending, (state, action) => {
      state.isLmBalancesLoading = true;
    });
    builder.addCase(fetchLMBalance.fulfilled, (state, action) => {
      state.lmBalances = action.payload;
      state.isLmBalancesLoading = false;
    });
    builder.addCase(fetchCoins.fulfilled, (state, action) => {
      const visibleCoins = action.payload?.filter(coin => !coin.isHidden); // prevent to show hidden coins like csETH
      state.coins = visibleCoins;
    });
    builder.addCase(fetchWalletUserPortfolio.fulfilled, (state, action) => {
      state.walletUserPortfolio = action.payload;
    });
    builder.addCase(fetchAssetOverview.fulfilled, (state, action) => {
      state.assetOverview.dailyRewards = action.payload.dailyRewards;
      state.assetOverview.allRewards = action.payload.allRewards;
      if (action.payload.error) {
        state.assetOverview.error = true;
      }
    });
    builder.addCase(fetchAssetRewards.fulfilled, (state, action) => {
      state.rewardsWithPeriod.data = action.payload.response;
      state.rewardsWithPeriod.isLoading = false;
      if (action.payload.error) {
        state.rewardsWithPeriod.error = true;
      }
    });
    builder.addCase(fetchAssetRewards.pending, (state) => {
      state.rewardsWithPeriod.isLoading = true;
      state.rewardsWithPeriod.error = null;
    });
    builder.addCase(fetchAssetRewards.rejected, (state, action) => {
      state.rewardsWithPeriod.error = action.error.message;
    });
    builder.addCase(fetchRewards.fulfilled, (state, action) => {
      state.rewards = action.payload.response;
      state.rewardsPage = action.payload.nextPage;
    });
    builder.addCase(fetchFreezerPageAuthenticated.fulfilled, (state, action) => {
      state.balances = action.payload.balances;
      state.coinPrices = action.payload.coinPrices;
    });
  },
});

export const {
  clearBalances,
  setBalances,
  setCoinPrices,
} = walletSlice.actions;

export default walletSlice.reducer;
