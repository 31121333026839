import Variables, { getMaxWidthBreakPoint } from '../../../Variables.styles';

const getBoxShadowStyle = (isFocused, isInvalid) => {
  if (isInvalid) {
    return `0 0 0 ${isFocused ? '2px' : '1px'} ${Variables.redInvalidControl}`;
  }

  if (isFocused) {
    return `0 0 0 2px ${Variables.electricBerry}`;
  }

  return `0 0 0 1px ${Variables.gray200}`;
};

export default {
  inputGroupWrapper: {
    width: '100%',
  },
  inputGroupContainer: {
    composes: 'input-group',
    marginBottom: Variables.small,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    boxShadow: ({ isFocus, invalid }) => getBoxShadowStyle(isFocus, invalid),
    borderRadius: '4px',
    background: Variables.white,
  },
  inputGroupControl: {
    flexGrow: 1,
    width: 'auto !important',
    border: '1px solid transparent !important',
    '&:focus': {
      border: '2px solid transparent !important',
    },
  },
  invalidControlMessage: {
    color: Variables.redInvalidControl,
    fontSize: '14px !important',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    minHeight: '20px',
    position: 'absolute',
    visibility: ({ invalid }) => (invalid ? 'visible' : 'hidden'),
  },
  inputLoading: {
    position: 'absolute',
    bottom: 0,
    left: '16px',
  },
  coinDisplay: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: Variables.medium,
  },
  coinLabel: {
    paddingLeft: Variables.small,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.md)]: {
    inputGroupControl: {
      width: '50% !important',
    },
  },
};
