import React, { PureComponent } from 'react';

const MAX_HEIGHT = 370;
export default class DropDown extends PureComponent {
  state = {
    renderDropdown: function renderDropdown() { },
    isOpen: this.props.isOpen,
    textKey: 'text',
    height: 0,
    width: 0,
    alignment: 'bottomRight',
  };

  static defaultProps = {
    isOpen: false,
    onToggle: () => {},
    control: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.control) {
      return {
        isOpen: props.isOpen,
      };
    }
    return state;
  }

  componentDidUpdate() {
    this.resetProps(this.props);
  }

  componentDidMount() {
    this.resetProps(this.props);
    this.clickListener = this.handleOutsideClick.bind(this);
    document.addEventListener('click', this.clickListener);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickListener);
  }

  resetProps(props) {
    const {
      textKey = 'text',
      alignment = 'bottomLeft',
      renderDropdown = function renderDropdown() { },
    } = props;
    this.setState({
      renderDropdown,
      textKey,
      alignment,
    });
  }

  toggle() {
    const {
      isOpen,
    } = this.state;
    this.props.onToggle(!isOpen);
    if (isOpen) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    const height = this.element.clientHeight + 10;
    const width = this.element.clientWidth;
    this.setState({
      isOpen: true,
      height,
      width,
    });
  }

  hide() {
    this.setState({
      isOpen: false,
    });
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (!this.state.isOpen) {
      return;
    }
    if (this.element.contains(e.target)) {
      return;
    }
    this.props.onToggle(false);
    this.hide();
  }

  getdropdownedText() {
    return this.getText(this.state.selectedOption);
  }

  getText(option) {
    const {
      textKey,
    } = this.state;
    if (!option) return '';
    return option[textKey];
  }

  getOptionsPositioningStyle() {
    const {
      height,
      alignment,
    } = this.state;
    switch (alignment) {
      case 'bottomLeft':
        return {
          left: 0,
          top: height,
        };
      case 'bottomRight':
        return {
          right: 0,
          top: height,
        };
      case 'topLeft':
        return {
          bottom: height,
          left: 0,
        };
      case 'topRight':
        return {
          bottom: height,
          right: 0,
        };
      case 'bottomOffsetRight50':
        return {
          right: -50,
          top: height,
        };
      case 'bottomOffsetRight80':
        return {
          right: -80,
          top: height,
        };
      case 'bottomOffsetRight100':
        return {
          right: -100,
          top: height,
        };
      default:
        return {
          top: height,
          left: 0,
        };
    }
  }

  render() {
    return (
      <span className="dropdown" ref={(element) => { this.element = element; }}>
        <span
          className="dropdown__content"
          onClick={() => this.toggle()}
          ref={(element) => { this.element = element; }}
        >
          {this.props.children}
        </span>
        {this.renderDropdown()}
      </span>
    );
  }

  renderDropdown() {
    const {
      isOpen,
      renderDropdown,
    } = this.state;
    const { hideMenuOnClick, keepOpenWhenClickOn } = this.props;
    if (!isOpen) {
      return null;
    }
    const customStyles = {
      MAX_HEIGHT,
      ...this.getOptionsPositioningStyle(),
    };

    const onClick = (e) => {
      if (e.target.closest('div').className === keepOpenWhenClickOn || !hideMenuOnClick) {
        return null;
      }
      return this.hide();
    };

    return (
      <span className="dropdown__options-container" style={customStyles} onClick={onClick}>
        {renderDropdown()}
      </span>
    );
  }
}
