import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from 'sdk';
import { ThunkApiConfig } from 'app/rootReducer';
import {
  SubscriptionUserInfo,
  SubscriptionPaymentProviderDetails,
  SubscriptionPricingTable,
  SubscriptionPricingItem,
} from '@cakedefi/cake-sdk/schema/subscription';

interface ISubscriptionServiceState {
  state: 'UNLOADED' | 'LOADING' | 'LOADED' | 'ERROR',
  error: any,
  paymentProviderDetails: SubscriptionPaymentProviderDetails,
  pricingTables: SubscriptionPricingItem[],
  userInformation: SubscriptionUserInfo,
  tableLoadingState: 'UNLOADED' | 'LOADING' | 'LOADED' | 'ERROR',
}

const initialState: ISubscriptionServiceState = {
  state: 'UNLOADED',
  error: null,
  paymentProviderDetails: null,
  pricingTables: null,
  userInformation: null,
  tableLoadingState: 'UNLOADED',
};

export const fetchPricingTableInfo = createAsyncThunk<SubscriptionPricingTable, null, ThunkApiConfig>(
  'subscription/pricingTable',
  async (_, thunkAPI) => {
    const result = await sdk.SubscriptionApi.getPricingTable();
    return result;
  },
);

export const fetchUserSubscriptionInfo = createAsyncThunk<SubscriptionUserInfo, null, ThunkApiConfig>(
  'subscription/userSubscriptionInfo',
  async (_, thunkAPI) => {
    const result = await sdk.SubscriptionApi.getUserInfo();
    if (!result || ['canceled', 'incomplete_expired'].includes(result.status)) {
      thunkAPI.dispatch(fetchPricingTableInfo());
    }
    return result;
  },
);

const subscriptionSlice = createSlice({
  name: 'SubscriptionService',
  initialState,
  reducers: {
    clearSubscriptionServiceData: () => (initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPricingTableInfo.pending, (state) => {
      state.tableLoadingState = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchPricingTableInfo.fulfilled, (state, action) => ({
      ...state,
      paymentProviderDetails: action.payload.paymentProviderDetails,
      pricingTables: action.payload.pricingTables,
      tableLoadingState: 'LOADED',
      error: null,
    }));
    builder.addCase(fetchPricingTableInfo.rejected, (state, action) => {
      state.tableLoadingState = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchUserSubscriptionInfo.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchUserSubscriptionInfo.fulfilled, (state, action) => ({
      ...state,
      userInformation: action.payload,
      state: 'LOADED',
      error: null,
    }));
    builder.addCase(fetchUserSubscriptionInfo.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export const { clearSubscriptionServiceData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
