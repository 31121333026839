import React, { Component } from 'react';

export default class Tooltip extends Component {
  state = {
    isVisible: false,
    renderContent() { },
    xOffset: null,
    yOffset: null,
    horizontalAlignment: null,
    verticalAlignment: null,
  };

  constructor() {
    super();
    const { cakepool } = window;
    cakepool.showTooltip = (settings, event) => this.showTooltip(settings, event);
    cakepool.refreshTooltip = () => this.refreshTooltip();
    cakepool.hideTooltip = () => this.hideTooltip();
  }

  showTooltip(settings = {}, event) {
    const {
      xOffset,
      yOffset,
      horizontalAlignment,
      verticalAlignment,
      renderContent,
      showCloseButton,
    } = settings;
    const { innerWidth, innerHeight } = window;
    const halfWidth = innerWidth / 2;
    const halfHeight = innerHeight / 2;
    const temporaryX = typeof (xOffset) === 'number' ? xOffset : event.clientX;
    const temporaryY = typeof (yOffset) === 'number' ? yOffset : event.clientY;
    let calculatedHorizontalAlignment = null;
    let calculatedVerticalAlignment = null;
    let calculatedXOffset = null;
    let calculatedYOffset = null;

    if (horizontalAlignment === 'left') {
      calculatedHorizontalAlignment = horizontalAlignment;
      calculatedXOffset = temporaryX;
    } else if (horizontalAlignment === 'right') {
      calculatedHorizontalAlignment = horizontalAlignment;
      calculatedXOffset = innerWidth - temporaryX;
    } if (temporaryX < halfWidth) {
      calculatedHorizontalAlignment = 'left';
      calculatedXOffset = temporaryX;
    } else {
      calculatedHorizontalAlignment = 'right';
      calculatedXOffset = innerWidth - temporaryX;
    }

    if (verticalAlignment === 'top') {
      calculatedVerticalAlignment = verticalAlignment;
      calculatedYOffset = temporaryY;
    } else if (verticalAlignment === 'bottom') {
      calculatedVerticalAlignment = verticalAlignment;
      calculatedYOffset = innerHeight - temporaryY;
    } else if (temporaryY < halfHeight) {
      calculatedVerticalAlignment = 'top';
      calculatedYOffset = temporaryY;
    } else {
      calculatedVerticalAlignment = 'bottom';
      calculatedYOffset = innerHeight - temporaryY;
    }
    const state = {
      xOffset: calculatedXOffset,
      yOffset: calculatedYOffset,
      horizontalAlignment: calculatedHorizontalAlignment,
      verticalAlignment: calculatedVerticalAlignment,
      isVisible: true,
      renderContent: renderContent || function rc() { },
      showCloseButton,
    };
    this.setState(state);
  }

  refreshTooltip() {
    this.forceUpdate();
  }

  hideTooltip() {
    this.setState({
      isVisible: false,
    });
  }

  getTooltipStyle() {
    const {
      xOffset,
      yOffset,
      horizontalAlignment,
      verticalAlignment,
    } = this.state;
    const style = {};
    style[horizontalAlignment] = xOffset;
    style[verticalAlignment] = yOffset;
    return style;
  }

  renderCloseButton() {
    if (this.state.showCloseButton) {
      return (
        <div className="cakepool-tooltip-close-button" onClick={() => this.hideTooltip()}>
          <i className="fa fa-window-close" aria-hidden="true"></i>
        </div>
      );
    }
  }

  render() {
    const {
      isVisible,
      renderContent,
    } = this.state;
    if (!isVisible) {
      return null;
    }
    const style = this.getTooltipStyle();
    const content = renderContent();
    return (
      <div className="cakepool-tooltip" style={style}>
        {content}
        {this.renderCloseButton()}
      </div>
    );
  }
}
