import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFreezerPageAuthenticated } from 'freezer/freezerSlice';
import sdk from 'sdk';
import { StateStatus } from 'types/common/state';
import { FreezerCalculatorState, Shares } from '../../types/freezer/freezer-calculator';

const initialState: FreezerCalculatorState = {
  inputCoin: 'DFI',
  inputCoinGroup: Shares.STAKING,
  inputAmount: '',
  inputTenureIndex: 0,
  isCanFreeze: false,
  freezerPromoCalcutator: {
    data: {
      hasReachedPromoBonusCap: true,
      availableBonusToPayUsd: '',
    },
    status: StateStatus.STARTED,
    error: null,
  },
  error: '',
  loading: false,
  minimumAmountInUsd: 0,
};

export const fetchFreezeMinAmountInUsd = createAsyncThunk(
  'freezer/minimum',
  () => sdk.FreezerApi.getMinimumInput(),
);

const freezerCalculatorSlice = createSlice({
  name: 'freezerCalculator',
  initialState,
  reducers: {
    setInputCoin: (state, action: PayloadAction<string>) => {
      state.inputCoin = action.payload;
    },
    setInputCoinGroup: (state, action: PayloadAction<Shares>) => {
      state.inputCoinGroup = action.payload;
    },
    setInputAmount: (state, action: PayloadAction<string>) => {
      state.inputAmount = action.payload;
    },
    setInputTenureIndex: (state, action: PayloadAction<number>) => {
      state.inputTenureIndex = action.payload;
    },
    setIsCanFreeze: (state, action: PayloadAction<boolean>) => {
      state.isCanFreeze = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFreezerPageAuthenticated.fulfilled, (state, action) => {
      state.freezerPromoCalcutator.data.hasReachedPromoBonusCap = action.payload.hasReachedPromoBonusCap;
      state.freezerPromoCalcutator.data.availableBonusToPayUsd = action.payload.availableBonusToPayUsd;
      state.freezerPromoCalcutator.status = StateStatus.FINISHED;
    });
    builder.addCase(fetchFreezerPageAuthenticated.pending, (state) => {
      state.freezerPromoCalcutator.status = StateStatus.LOADING;
    });
    builder.addCase(fetchFreezerPageAuthenticated.rejected, (state, action) => {
      state.freezerPromoCalcutator.error = action.error;
      state.freezerPromoCalcutator.status = StateStatus.ERROR;
    });

    builder.addCase(fetchFreezeMinAmountInUsd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFreezeMinAmountInUsd.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.minimumAmountInUsd = payload?.minimumAmountInUsd;
    });
    builder.addCase(fetchFreezeMinAmountInUsd.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setInputAmount,
  setInputTenureIndex,
  setIsCanFreeze,
  setInputCoin,
  setInputCoinGroup,
} = freezerCalculatorSlice.actions;

export default freezerCalculatorSlice.reducer;
