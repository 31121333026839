import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useCakeSelector } from '../../../app/rootReducer';
import InputGroup from '../../../common/components/InputGroup/InputGroup';
import { currencyPricingSelector } from '../../../currency/currencySelector';
import { CoinOption } from '../../../types/coin/coin-option';
import { FiatBuyInputGroupsSelectorModel } from '../../../types/onramp/state';
import { localizeBigNumber } from '../../../utils';
import { fiatBuyCryptoCoinsSelector, fiatPurchasableCoinsSelector, walletCoinsMapSelector } from '../../walletSelectors';
import inputGroupStyles from '../InputGroup.styles';
import { changeCryptoAmount, changeCryptoCode, changeFiatAmount, changeFiatCode } from './fiatBuyModalAction';
import { fiatBuyInputGroupsSelector, fiatBuyModalInputsSelector } from './fiatBuyModalSelector';
import { isCryptoAmountValidSelector } from './store/isCryptoAmountValidSelector';
import { isAmountWithinPaymentMethodLimit } from './utils/isAmountWithinPaymentMethodLimit';

const useInputGroupStyles = createUseStyles(inputGroupStyles);
const DEBOUNCE_DELAY = 250;

const OutOfRangeAmount = ({ min, max }) => (
  <Trans>Please enter a value between {{ min }} and {{ max }}</Trans>
);

const FiatBuyInputGroups = ({ selectedCoin, cancelRequestBeforeFetching }) => {
  const classes = useInputGroupStyles();
  const coinInputRef = useRef(null);
  const fiatInputRef = useRef(null);

  const dispatch = useDispatch();
  const {
    type,
    fiatOptions,
    isFiatsLoading,
    defaultInputCoin,
    defaultFiatInputValue,
    defaultFiatDropdownValue,
    paymentMethodMinAmount,
    paymentMethodMaxAmount,
    isFetchingCryptoPrice,
    isFetchingFiatPrice,
    isFiatAmountValid,
    isBanxa: isEnableDFISelection,
  }: FiatBuyInputGroupsSelectorModel = useCakeSelector(fiatBuyInputGroupsSelector(selectedCoin));

  const {
    crypto,
    cryptoAmount,
    fiat,
    fiatAmount,
  } = useCakeSelector(fiatBuyModalInputsSelector);

  const { isCryptoAmountValid } = useCakeSelector(
    isCryptoAmountValidSelector,
  );

  const coinOptions = useCakeSelector(isEnableDFISelection ? fiatBuyCryptoCoinsSelector : fiatPurchasableCoinsSelector);
  const pricing = useCakeSelector(currencyPricingSelector);
  const coins = useCakeSelector(walletCoinsMapSelector);

  // eslint-disable-next-line
  const onCoinAmountChange = useCallback(debounce((async (value) => {
    changeCryptoAmount(dispatch, type, value, pricing, false, coins, cancelRequestBeforeFetching);
  }), DEBOUNCE_DELAY), [dispatch, type, pricing, coins, cancelRequestBeforeFetching]);

  const onCoinChange = useCallback(async (coin: CoinOption) => {
    const { value } = coin;
    if (value !== crypto) {
      changeCryptoCode(dispatch, type, coin, pricing, false, coins, cryptoAmount, cancelRequestBeforeFetching);
    }
  }, [dispatch, type, crypto, cryptoAmount, pricing, coins, cancelRequestBeforeFetching]);

  const onFiatChange = useCallback(async (fiatOption) => {
    const { value } = fiatOption;
    if (value !== fiat) {
      changeFiatCode(dispatch, type, value, pricing, false, cancelRequestBeforeFetching);
    }
  }, [dispatch, type, fiat, pricing, cancelRequestBeforeFetching]);

  // eslint-disable-next-line
  const onFiatAmountChange = useCallback(debounce((async (value) => {
    const isEnteredFiatWithinRange = isAmountWithinPaymentMethodLimit(paymentMethodMinAmount, paymentMethodMaxAmount, value);
    changeFiatAmount(dispatch, type, value, isEnteredFiatWithinRange, pricing, false, cancelRequestBeforeFetching);
  }), DEBOUNCE_DELAY), [dispatch, type, paymentMethodMinAmount, paymentMethodMaxAmount, pricing, cancelRequestBeforeFetching]);

  useEffect(() => {
    if (fiatInputRef.current) {
      fiatInputRef.current.value = cryptoAmount;
    }
  }, [cryptoAmount]);

  useEffect(() => {
    if (coinInputRef.current) {
      coinInputRef.current.value = fiatAmount;
    }
  }, [fiatAmount]);

  return (
    <>
      <div className={classes.currencySelectionContainer}>
        <div className={classes.currencySelection}>
          <p className={classes.paragraphTitle}>
            <Trans>I want to buy</Trans>
          </p>
          <InputGroup
            type="number"
            inputRef={fiatInputRef}
            options={coinOptions}
            defaultDropdownValue={defaultInputCoin}
            defaultInputValue={cryptoAmount}
            onInputChange={onCoinAmountChange}
            onDropdownChange={onCoinChange}
            isInputLoading={isFetchingCryptoPrice}
            invalid={!isCryptoAmountValid}
          />
        </div>
        <i aria-hidden="true" className={classes.exchangeIcon}></i>
        <div className={classes.currencySelection}>
          <p className={classes.paragraphTitle}>
            <Trans>With</Trans>
          </p>
          <InputGroup
            type="number"
            inputRef={coinInputRef}
            options={fiatOptions}
            isLoading={isFiatsLoading}
            isInputLoading={isFetchingFiatPrice}
            defaultDropdownValue={defaultFiatDropdownValue}
            defaultInputValue={defaultFiatInputValue}
            onInputChange={onFiatAmountChange}
            onDropdownChange={onFiatChange}
            invalid={!isFiatAmountValid}
            invalidMessage={
              <OutOfRangeAmount
                min={localizeBigNumber(paymentMethodMinAmount, false, 2)}
                max={localizeBigNumber(paymentMethodMaxAmount, false, 2)}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default FiatBuyInputGroups;
