import BigNumber from 'bignumber.js';
import { CoinPrice } from '../schema';
import constants from './constants';

export default function convertCryptoToBTCorETHAmount(
  source: string,
  sourceAmount: any,
  target: 'BTC' | 'ETH',
  targetDecimals: number,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
): string {
  const coinPrice = pricing.find(({ CoinId }) => CoinId === source);
  if (!coinPrice) {
    return null;
  }

  const swapPrices = withoutMarkup ? coinPrice.coinSwapPricesWithoutMargin : coinPrice.coinSwapPrices;
  const priceForShare = new BigNumber(swapPrices?.[target]);
  return priceForShare
    .multipliedBy(sourceAmount)
    .decimalPlaces(targetDecimals ?? constants.DEFAULT_CRYPTO_DECIMALS)
    .toFixed();
}
