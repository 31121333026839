import React, { useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import config from '../../../../config/app';
import sdk from '../../../../sdk';
import delay from '../../../../utils/delay';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import normalizePhoneNumber from '../../../../utils/normalizePhoneNumber';
import MobilePhoneResendButton from './MobilePhoneResendButton';
import { reloadUser } from '../../../../user/userSlice';
import MobilePhoneStyles from './MobilePhone.styles';

const useStyles = createUseStyles(MobilePhoneStyles);
const countriesExcludedFromTwilio = config.COUNTRIES_EXCLUDED_FROM_TWILIO;

const MobilePhoneCallingForVerification = ({ gotoInputScreen, gotoVerifyOTPScreen, gotoWallet, timeLeft, startCountdown, isAllowedToUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const { mobileNumber, phoneCountryCode } = useCakeSelector(state => state.userIdentifiableInformation.inputs);
  const { phoneNumberVerified } = useCakeSelector(state => state.user.details);

  const callingForVerification = useCallback(async () => {
    try {
      if (phoneNumberVerified && isMountedRef.current && !isAllowedToUpdate) {
        gotoWallet();
        return;
      }
      const isSkipVerification = countriesExcludedFromTwilio.includes(phoneCountryCode);
      await sdk.TwilioApi.sendVerificationSms({
        countryCode: phoneCountryCode,
        phone: mobileNumber,
        channel: 'call',
      });
      if (isSkipVerification) {
        dispatch(reloadUser());
      }
      delay(5000);
      if (isSkipVerification) {
        gotoWallet();
      } else {
        gotoVerifyOTPScreen();
      }
    } catch (error) {
      window.cakepool.showAlert(
        'error',
        <span>
          <Trans>Phone call failed</Trans>: <Trans>{error.message}</Trans>
        </span>,
      );
      gotoInputScreen();
    }
  }, [dispatch, gotoVerifyOTPScreen, gotoInputScreen, gotoWallet, isMountedRef, mobileNumber, phoneCountryCode, phoneNumberVerified, isAllowedToUpdate]);

  useEffect(() => {
    callingForVerification();
  // eslint-disable-next-line
  }, []);
  return (
    <article className="floating">
      <div className='text-left'>
        <div className={classes.phoneCalling}>
          <div className={classes.phoneCallingText}>
            <Trans>Calling you at your mobile number</Trans>
            <div>{normalizePhoneNumber(phoneCountryCode, mobileNumber)}...</div>
          </div>
        </div>
      </div>
      <div>
        <MobilePhoneResendButton
          forceDisabledReceivedCall={true}
          disableChangeMobileNumber={true}
          timeLeft={timeLeft}
          startCountdown={startCountdown}
          gotoVerifyOTPScreen={gotoVerifyOTPScreen}
          showCountdownMessage={true}
          phoneCountryCode={phoneCountryCode}
          mobileNumber={mobileNumber}
          gotoInputScreen={gotoInputScreen}
        />
      </div>
    </article>
  );
};

export default MobilePhoneCallingForVerification;
