import { UserRewardList, UserRewardListInfo } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class RewardApi extends CakeApiBase {
  async list(query = {}) {
    return this.requestManager.get<UserRewardList[]>('rewards', query);
  }

  async info(query = {}) {
    return this.requestManager.get<UserRewardListInfo>('rewards/info', query);
  }
}
