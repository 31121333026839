import React, { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ModalContext } from '../../../modals/ModalContext';
import NonLoginView from './NonLoginView';
import LoginView from './LoginView';
import DetailedModal from './DetailedModal';
import Button from '../../components/Button/Button';
import { fetchPromotion } from '../../../referral/promotionCampaignSlice';
import { useCakeSelector } from '../../../app/rootReducer';
import { ReferralPromoType } from '../../../referral/referralType';
import { getIsPromotionPeriodSelector, promotionCampaignDataSelector } from '../../../referral/referralPromoSelector';

const DetailedModalButton = ({ minDepositAmount = '$50', isThirdAnniversaryPromoActive }) => {
  const { openModal } = useContext(ModalContext);

  const showDetailedModal = () => {
    openModal({
      component: <DetailedModal minDepositAmount={minDepositAmount} isThirdAnniversaryPromoActive={isThirdAnniversaryPromoActive} />,
      title: isThirdAnniversaryPromoActive ? <Trans>Win $3,333 Worth of Crypto</Trans> : <Trans>Welcome Bonus</Trans>,
    });
  };
  return (
    <Button medium btnLink onClick={() => showDetailedModal()}><Trans>Details</Trans></Button>
  );
};

const ReferButton = () => <Link to="/referral" className="ml-3">
  <Button primary medium><Trans>Refer now</Trans></Button>
</Link>;

export default function SignUpBonusBanner({ isLoggedIn = false }) {
  const dispatch = useDispatch();
  const isPromotionActive = useCakeSelector(getIsPromotionPeriodSelector);
  const { activePromotion } = useCakeSelector(promotionCampaignDataSelector);
  const isBlackFridayPromo = isPromotionActive && activePromotion === ReferralPromoType.BLACK_FRIDAY;
  const minDepositAmount = isBlackFridayPromo ? '$25' : '$50';
  const isThirdAnniversaryPromoActive = isPromotionActive && activePromotion === ReferralPromoType.THIRD_ANNIVERSARY_PROMO_2022;

  useEffect(() => {
    dispatch(fetchPromotion());
  }, [dispatch]);

  if (isLoggedIn) {
    return <LoginView buttons={
      <>
        <DetailedModalButton />
        <ReferButton />
      </>
    } />;
  }

  return (
    <>
      <NonLoginView buttons={<DetailedModalButton isThirdAnniversaryPromoActive={isThirdAnniversaryPromoActive} minDepositAmount={minDepositAmount}/>} minDepositAmount={minDepositAmount} />
    </>
  );
}
