import { Styles } from 'react-jss';
import ModalStyles from '../../../common/styles/Modal.styles';

import Variables from '../../../Variables.styles';

const styles: Styles = {
  ...ModalStyles,
  successText: {
    color: Variables.lightGreen,
    lineHeight: '2.4rem',
    marginBottom: 0,
  },
};

export default styles;
