import React from 'react';
import ReactSvg from 'react-svg';
import facebook from '../../../assets/svg/social-facebook.svg';

export default function FacebookIcon() {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/bake_io">
      <ReactSvg
        src={facebook}
        className="facebook-icon"
      />
    </a>
  );
}
