import React from 'react';
import { createUseStyles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { Trans } from 'react-i18next';
import { ModalHeader, ModalContent } from 'modals/components';
import mobilePhoneImage from 'assets/svg/mobile-interface-screen.svg';
import mobileAppQr from 'assets/svg/mobile-download-qr.svg';

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr',
    gap: '10px',
    alignItems: 'center',
    minHeight: '550px',
  },
  leftSection: {
    paddingLeft: '50px',
    width: '100%',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingRight: '50px',
  },
  image: {
    width: '100%',
    maxHeight: '584px',
  },
  qrCode: {
    width: '100%',
    height: '95px',
  },
  title: {
    fontSize: '42px',
    fontWeight: Variables.fontWeight.extraBold,
    paddingRight: '100px',
    marginBottom: '16px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: Variables.fontWeight.bold,
  },
  qrCodeSection: {
    display: 'grid',
    gridTemplateColumns: '20% 1fr',
    gap: '12px',
  },
  downloadSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: Variables.fontWeight.semiBold,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    leftSection: {
      paddingLeft: '0px',
      marginLeft: '-100px',
    },
    rightSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '0px 30px 30px 30px',
      alignItems: 'center',
    },
    title: {
      paddingRight: '0px',
      lineHeight: '1',
    },
    qrCodeSection: {
      gridTemplateColumns: '30% 1fr',
      gap: '15px',
    },
    qrCode: {
      minWidth: '80px',
    },
  },
});

interface NovaLoginModalProps {
  onClose: () => void;
}

/**
 * Modal shown on login to prompt user to download mobile app
 */
const NovaLoginModal = (props: NovaLoginModalProps) => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <>
      <ModalHeader title={''} onClose={onClose} showCancelButton={true} cancelLabel='Close'/>
      <ModalContent>
        <div className={classes.container}>
          <div className={classes.leftSection}>
            <img src={mobilePhoneImage} alt='nova-mobile' className={classes.image}/>
          </div>
          <div className={classes.rightSection}>
            <h1 className={classes.title}>
              <Trans>Whoa, the Bake app just got better.</Trans>
            </h1>
            <p className={classes.subtitle}>
              <Trans>Discover new ways to build and grow your digital assets portfolio. Over 1 million people trust Bake.</Trans>
            </p>
            <div className={classes.qrCodeSection}>
              <img src={mobileAppQr} alt='nova-mobile-qr' className={classes.qrCode}/>
              <div className={classes.downloadSection}>
                <div><Trans>Scan to download the app</Trans></div>
                <div><Trans>iOS & Android</Trans></div>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default NovaLoginModal;
