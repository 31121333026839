import moment from 'moment';

export default function getDatetimeInUserLocale(date: Date): {month: string, day: string, time: string, year: string} {
  const dateObj = moment(date);
  const time = dateObj.format('h:mm a');
  const day = dateObj.format('DD');
  const month = dateObj.format('MMM');
  const year = dateObj.format('YYYY');
  return { month, day, time, year };
}
