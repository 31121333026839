import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { storage } from 'storage/sessionStorage';
import { getQueryParam } from 'utils/getQueryParam';
import Variables from 'Variables.styles';
import MilesWelcomeBanner from 'assets/img/promo/miles-collab-banner.png';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Variables.large,
  },
  title: {
    fontSize: Variables.fontSize.small,
    color: Variables.gray600,
  },
  affiliateCode: {
    fontSize: Variables.fontSize.base,
  },
  promoBanner: {
    width: '100%',
    marginBottom: Variables.large,
    borderRadius: Variables.extraSmall,
  },
});

const AffiliateBlock = () => {
  const classes = useStyles();
  const affiliateCode = getQueryParam('pid') || (storage.getItem('pid'));
  const isAffiliate = (getQueryParam('utm_source') === 'affiliate') || (storage.getItem('utm_source') === 'affiliate');

  if (!isAffiliate || !affiliateCode) {
    return null;
  }

  const isMilesAffiliate = getQueryParam('utm_medium') === 'Miles';

  if (isMilesAffiliate) {
    const metaTag = document.querySelector('meta[name="apple-itunes-app"]');
    if (metaTag) {
      metaTag.remove();
    }
  }

  return (
    <div className={classes.container}>
      {isMilesAffiliate && <img src={MilesWelcomeBanner} alt="Miles Promo Banner" className={classes.promoBanner} />}

      <div className={classes.title}>
        <Trans>Affiliate code applied</Trans>
      </div>
      <div className={classes.affiliateCode} >{affiliateCode}</div>
    </div>
  );
};

export default AffiliateBlock;
