import React from 'react';
import ReactSvg from 'react-svg';
import twitter from '../../../assets/svg/social-twitter.svg';

export default function TwitterIcon() {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/bake_io">
      <ReactSvg
        src={twitter}
        className="twitter-icon"
      />
    </a>
  );
}
