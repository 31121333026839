import { DEXPrice } from 'sdk/cake-sdk/src/schema';
import BigNumber from 'bignumber.js';
import { SwapFees } from 'types/dex-modal/dex-modal-state';
import { DEX_MAX_DECIMALS } from '../state/dexModalSlice';

export function calculateSwapOutput(pool: DEXPrice, amount: BigNumber, fromCoin: string): BigNumber {
  const primaryCoin = pool.LiquidityMiningPairId.split('-')[0];
  let inputReserve: BigNumber;
  let outputReserve: BigNumber;

  if (fromCoin === primaryCoin) {
    inputReserve = new BigNumber(pool.primaryCoinReserve);
    outputReserve = new BigNumber(pool.secondaryCoinReserve);
  } else {
    inputReserve = new BigNumber(pool.secondaryCoinReserve);
    outputReserve = new BigNumber(pool.primaryCoinReserve);
  }

  const dexFeeRate = new BigNumber(pool.dexFeeBaseRate);
  const amountAfterFee = amount.multipliedBy(new BigNumber(1).minus(dexFeeRate));
  const outputAmount = outputReserve.minus(
    inputReserve.multipliedBy(outputReserve).dividedBy(inputReserve.plus(amountAfterFee)),
  );
  return outputAmount;
}

export function calculateSwapOutputWithoutSlippage(pool: DEXPrice, amount: BigNumber, fromCoin: string): BigNumber {
  const primaryCoin = pool.LiquidityMiningPairId.split('-')[0];
  const priceToUse = fromCoin === primaryCoin ? pool.primaryCoinPrice : pool.secondaryCoinPrice;

  const dexFeeRate = new BigNumber(pool.dexFeeBaseRate);
  const amountAfterFee = amount.multipliedBy(new BigNumber(1).minus(dexFeeRate));

  return amountAfterFee.multipliedBy(priceToUse);
}

export function simulateSwap(
  fromAmount: BigNumber,
  fromCoin: string,
  toCoin: string,
  route: DEXPrice[],
) {
  let amount = fromAmount;
  let coinToSwap = fromCoin;

  // eslint-disable-next-line no-restricted-syntax
  for (const pool of route) {
    const [primaryCoin, secondaryCoin] = pool.LiquidityMiningPairId.split('-');
    amount = calculateSwapOutput(pool, amount, coinToSwap);
    coinToSwap = coinToSwap === primaryCoin ? secondaryCoin : primaryCoin;
  }

  if (coinToSwap !== toCoin) {
    return new BigNumber(0);
  }

  return amount;
}

export function simulateSwapWithoutSlippage(
  fromAmount: BigNumber,
  fromCoin: string,
  toCoin: string,
  route: DEXPrice[],
) {
  let amount = fromAmount;
  let coinToSwap = fromCoin;

  // eslint-disable-next-line no-restricted-syntax
  for (const pool of route) {
    const [primaryCoin, secondaryCoin] = pool.LiquidityMiningPairId.split('-');
    amount = calculateSwapOutputWithoutSlippage(pool, amount, coinToSwap);
    coinToSwap = coinToSwap === primaryCoin ? secondaryCoin : primaryCoin;
  }

  if (coinToSwap !== toCoin) {
    return new BigNumber(0);
  }

  return amount;
}

export function getPriceImpact(fromCoin: string, toCoin: string, route: DEXPrice[]) {
  return (fromAmount: BigNumber) => {
    if (!route || !route.length) return new BigNumber(0);
    const withSlippage = simulateSwap(fromAmount, fromCoin, toCoin, route);
    // console.log(`${toCoin} we would get with slippage: ${withSlippage.toString()}`);
    const withoutSlippage = simulateSwapWithoutSlippage(fromAmount, fromCoin, toCoin, route);
    // console.log(`${toCoin} we would get without slippage: ${withoutSlippage.toString()}`);
    if (withoutSlippage.isZero()) {
      return new BigNumber(0);
    }
    const priceImpact = new BigNumber(1).minus(withSlippage.dividedBy(withoutSlippage)).multipliedBy(100).multipliedBy(-1);
    return priceImpact;
  };
}

export function getDexAmountAfterFee(
  fromCoin: string,
  toCoin: string,
  fromCoinAmount: number | string,
  fees: SwapFees[],
  route: DEXPrice[],
) {
  const fromCoinFeesPercentage = (fees ?? [])
    .filter((fee) => fee.side === fromCoin)
    .reduce((acc, fee) => new BigNumber(acc).plus(fee.value), new BigNumber(0));
  const toCoinFeesPercentage = (fees ?? [])
    .filter((fee) => fee.side === toCoin)
    .reduce((acc, fee) => new BigNumber(acc).plus(fee.value), new BigNumber(0));

  const fromAmountAfterFromCoinFee = new BigNumber(fromCoinAmount).multipliedBy(new BigNumber(1).minus(fromCoinFeesPercentage));
  const estimatedSwapAmount = simulateSwap(fromAmountAfterFromCoinFee, fromCoin, toCoin, route);
  const finalAmountAfterAllFees = estimatedSwapAmount.multipliedBy(new BigNumber(1).minus(toCoinFeesPercentage));
  return finalAmountAfterAllFees.decimalPlaces(DEX_MAX_DECIMALS).toString();
}
