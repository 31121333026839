import { CakeApiBase } from '../CakeApiBase';
import { SprodBatch } from '../../schema/model';
import { LendingPage, LendingPageAuthenticated } from '../../schema/lending';

export class LapisPageApi extends CakeApiBase {
  async lapisPage(slug: string) {
    return this.requestManager.get<LendingPage>('lapis', { slug });
  }

  async lapisPageAuthenticated(slug: string) {
    return this.requestManager.get<LendingPageAuthenticated>('lapis/authenticated', { slug });
  }

  async getPastBatches(slug: string, limit: number, offset: number) {
    const { pastBatches } = await (this.requestManager.get<{ pastBatches: SprodBatch[] }>('lapis/past-batches', { slug, limit, offset }));
    return pastBatches;
  }

  async getPastBatchesAuthenticated(slug: string, limit: number, offset: number) {
    const { pastBatches } = await (this.requestManager.get<{ pastBatches: SprodBatch[] }>('lapis/past-batches/authenticated', { slug, limit, offset }));
    return pastBatches;
  }
}
