import { AxiosResponse } from 'axios';
import { BanxaApi } from '../api/client/BanxaApi';
import { CoinApi } from '../api/client/CoinApi';
import { CurrencyRatesApi } from '../api/client/CurrencyRatesApi';
import { HomeApi } from '../api/client/HomeApi';
import { LapisPageApi } from '../api/client/LapisPageApi';
import { LearnEarnApi } from '../api/client/LearnEarnApi';
import { liquidityMiningApi } from '../api/client/LiquidityMiningApi';
import { MarketApi } from '../api/client/MarketApi';
import { NodeApi } from '../api/client/NodeApi';
import { NotificationApi } from '../api/client/NotificationApi';
import { PromotionApi } from '../api/client/PromotionApi';
import { ReferralPageApi } from '../api/client/ReferralPageApi';
import { RewardApi } from '../api/client/RewardApi';
import { SprodApi } from '../api/client/SprodApi';
import { StakingPageApi } from '../api/client/StakingPageApi';
import { TwilioApi } from '../api/client/TwilioApi';
import { UserInfoApi, LoginResponse, OTPType } from '../api/client/UserInfoApi';
import { DeactivateApi } from '../api/client/DeactivateApi';
import { WalletApi } from '../api/client/WalletApi';
import { WalletPageApi } from '../api/client/WalletPageApi';
import { WhitelistedAddressApi } from '../api/client/WhitelistedAddressApi';
import { WithdrawalApi } from '../api/client/WithdrawalApi';
import { TransakApi } from '../api/client/TransakApi';
import { FreezerApi } from '../api/client/FreezerApi';
import { SessionStorage, ClientConfig, CakeRequestManager } from '.';
import { CakeFinalRequestConfig, RequestConfig } from './request';
import { CakeError } from './CakeError';
import { VipApi } from '../api/client/VipApi';
import { MidAutumnCampaignApi, OlympicsCampaignApi, RazerApi } from '../api/client/campaigns';
import { LocationApi } from '../api/client/LocationApi';
import { DexSwapApi } from '../api/client/DexSwapApi';
import { AnySwapApi } from '../api/client/AnySwapApi';
import { AssetSwapApi } from '../api/client/AssetSwapApi';
import { NetworkApi } from '../api/client/NetworkApi';
import { MyinfoApi } from '../api/client/MyinfoApi';
import { YoutubeApi } from '../api/client/YoutubeApi';
import { DfcVotingApi } from '../api/client/DfcVotingApi';
import { LoanApi } from '../api/client/LoanApi';
import { SumsubApi } from '../api/client/SumsubApi';
import { PlatformApi } from '../api/client/PlatformApi';
import { VoucherInfoApi } from '../api/client/VoucherInfoApi';
import { FacebookSsoApi } from '../api/client/FacebookSsoApi';
import { GoogleSsoApi } from '../api/client/GoogleSsoApi';
import { SsoApi } from '../api/client/SsoApi';
import { AppleSsoApi } from '../api/client/AppleSsoApi';
import { EarnApi } from '../api/client/EarnApi';
import { StakingApi } from '../api/client/StakingApi';
import { CampaignApi } from '../api/client/CampaignApi';
import { SubscriptionApi } from '../api/client/SubscriptionApi';
import { YieldVaultApi } from '../api/client/YieldVaultApi';
import { IncomingDepositsAPI } from '../api/client/IncomingDepositsAPI';
import { DcaApi } from '../api/client/DcaApi';
import { OnboardingCountriesApi } from '../api/client/OnboardingCountriesApi';
import { ContentManagementApi } from '../api/client/ContentManagementApi';
import { BundleApi } from '../api/client/BundleApi';

export class CakeClientSdk {
  private config: ClientConfig;

  protected cakeRequestManager: CakeRequestManager;

  private sessionStorage: SessionStorage;

  public BanxaApi: BanxaApi;

  public CoinApi: CoinApi;

  public CurrencyRatesApi: CurrencyRatesApi;

  public HomeApi: HomeApi;

  public LapisPageApi: LapisPageApi;

  public LearnEarnApi: LearnEarnApi;

  public LiquidityMiningApi: liquidityMiningApi;

  public MarketApi: MarketApi;

  public NodeApi: NodeApi;

  public NotificationApi: NotificationApi;

  public PromotionApi: PromotionApi;

  public ReferralPageApi: ReferralPageApi;

  public RewardApi: RewardApi;

  public SprodApi: SprodApi;

  public StakingPageApi: StakingPageApi;

  public TwilioApi: TwilioApi;

  public UserInfoApi: UserInfoApi;

  public DeactivateApi: DeactivateApi;

  public WalletApi: WalletApi;

  public WalletPageApi: WalletPageApi;

  public WhitelistedAddressApi: WhitelistedAddressApi;

  public WithdrawalApi: WithdrawalApi;

  public TransakApi: TransakApi;

  public FreezerApi: FreezerApi;

  public VipApi: VipApi;

  public OlympicsCampaignApi: OlympicsCampaignApi;

  public MidAutumnCampaignApi: MidAutumnCampaignApi;

  public LocationApi: LocationApi;

  public DexSwapApi: DexSwapApi;

  public AnySwapApi: AnySwapApi;

  public AssetSwapApi: AssetSwapApi;

  public NetworkApi: NetworkApi;

  public MyinfoApi: MyinfoApi;

  public YoutubeApi: YoutubeApi;

  public DfcVotingApi: DfcVotingApi;

  public LoanApi: LoanApi;

  public SumsubApi: SumsubApi;

  public PlatformApi: PlatformApi;

  public VoucherInfoApi: VoucherInfoApi;

  public FacebookSsoApi: FacebookSsoApi;

  public GoogleSsoApi: GoogleSsoApi;

  public SsoApi: SsoApi;

  public AppleSsoApi: AppleSsoApi;

  public RazerApi: RazerApi;

  public EarnApi: EarnApi;

  public StakingApi: StakingApi;

  public CampaignApi: CampaignApi;

  public SubscriptionApi: SubscriptionApi;

  public YieldVaultApi: YieldVaultApi;

  public IncomingDepositsAPI: IncomingDepositsAPI;

  public DcaApi: DcaApi;

  public OnboardingCountriesApi: OnboardingCountriesApi;

  public ContentManagementApi: ContentManagementApi;

  public BundleApi: BundleApi

  private lastNotifReceivedTs: number;

  public onError?: (error: CakeError, finalRequestConfig: CakeFinalRequestConfig) => Promise<void>;

  constructor(config: ClientConfig, sessionStorage: SessionStorage, customHeaders = {}, requestManager?: CakeRequestManager) {
    this.cakeRequestManager = requestManager || new CakeRequestManager(config, sessionStorage, customHeaders);
    this.cakeRequestManager.onError = async (error: CakeError, finalRequestConfig: CakeFinalRequestConfig) => {
      if (this.onError) {
        await this.onError(error, finalRequestConfig);
      }
    };
    this.config = config;
    this.sessionStorage = sessionStorage;
    this.BanxaApi = new BanxaApi(this.cakeRequestManager);
    this.CoinApi = new CoinApi(this.cakeRequestManager);
    this.CurrencyRatesApi = new CurrencyRatesApi(this.cakeRequestManager);
    this.FreezerApi = new FreezerApi(this.cakeRequestManager);
    this.HomeApi = new HomeApi(this.cakeRequestManager);
    this.UserInfoApi = new UserInfoApi(this.cakeRequestManager);
    this.LapisPageApi = new LapisPageApi(this.cakeRequestManager);
    this.LearnEarnApi = new LearnEarnApi(this.cakeRequestManager);
    // eslint-disable-next-line new-cap
    this.LiquidityMiningApi = new liquidityMiningApi(this.cakeRequestManager);
    this.MarketApi = new MarketApi(this.cakeRequestManager);
    this.NodeApi = new NodeApi(this.cakeRequestManager);
    this.NotificationApi = new NotificationApi(this.cakeRequestManager);
    this.PromotionApi = new PromotionApi(this.cakeRequestManager);
    this.ReferralPageApi = new ReferralPageApi(this.cakeRequestManager);
    this.RewardApi = new RewardApi(this.cakeRequestManager);
    this.SprodApi = new SprodApi(this.cakeRequestManager);
    this.StakingPageApi = new StakingPageApi(this.cakeRequestManager);
    this.TwilioApi = new TwilioApi(this.cakeRequestManager);
    this.UserInfoApi = new UserInfoApi(this.cakeRequestManager);
    this.DeactivateApi = new DeactivateApi(this.cakeRequestManager);
    this.WalletApi = new WalletApi(this.cakeRequestManager);
    this.WalletPageApi = new WalletPageApi(this.cakeRequestManager);
    this.TransakApi = new TransakApi(this.cakeRequestManager);
    this.WhitelistedAddressApi = new WhitelistedAddressApi(this.cakeRequestManager);
    this.WithdrawalApi = new WithdrawalApi(this.cakeRequestManager);
    this.FreezerApi = new FreezerApi(this.cakeRequestManager);
    this.VipApi = new VipApi(this.cakeRequestManager);
    this.OlympicsCampaignApi = new OlympicsCampaignApi(this.cakeRequestManager);
    this.MidAutumnCampaignApi = new MidAutumnCampaignApi(this.cakeRequestManager);
    this.LocationApi = new LocationApi(this.cakeRequestManager);
    this.DexSwapApi = new DexSwapApi(this.cakeRequestManager);
    this.AnySwapApi = new AnySwapApi(this.cakeRequestManager);
    this.AssetSwapApi = new AssetSwapApi(this.cakeRequestManager);
    this.NetworkApi = new NetworkApi(this.cakeRequestManager);
    this.MyinfoApi = new MyinfoApi(this.cakeRequestManager);
    this.YoutubeApi = new YoutubeApi(this.cakeRequestManager);
    this.DfcVotingApi = new DfcVotingApi(this.cakeRequestManager);
    this.LoanApi = new LoanApi(this.cakeRequestManager);
    this.SumsubApi = new SumsubApi(this.cakeRequestManager);
    this.PlatformApi = new PlatformApi(this.cakeRequestManager);
    this.VoucherInfoApi = new VoucherInfoApi(this.cakeRequestManager);
    this.FacebookSsoApi = new FacebookSsoApi(this.cakeRequestManager);
    this.GoogleSsoApi = new GoogleSsoApi(this.cakeRequestManager);
    this.SsoApi = new SsoApi(this.cakeRequestManager);
    this.AppleSsoApi = new AppleSsoApi(this.cakeRequestManager);
    this.RazerApi = new RazerApi(this.cakeRequestManager);
    this.EarnApi = new EarnApi(this.cakeRequestManager);
    this.StakingApi = new StakingApi(this.cakeRequestManager);
    this.CampaignApi = new CampaignApi(this.cakeRequestManager);
    this.SubscriptionApi = new SubscriptionApi(this.cakeRequestManager);
    this.YieldVaultApi = new YieldVaultApi(this.cakeRequestManager);
    this.IncomingDepositsAPI = new IncomingDepositsAPI(this.cakeRequestManager);
    this.OnboardingCountriesApi = new OnboardingCountriesApi(this.cakeRequestManager);
    this.DcaApi = new DcaApi(this.cakeRequestManager);
    this.ContentManagementApi = new ContentManagementApi(this.cakeRequestManager);
    this.BundleApi = new BundleApi(this.cakeRequestManager);

    this.lastNotifReceivedTs = new Date().getTime();
  }

  subscribeNotification(cb: (notification: any) => Promise<void>): void {
    // eslint-disable-next-line no-underscore-dangle
    this._loopGetNotification(cb);
  }

  // eslint-disable-next-line no-underscore-dangle
  private async _loopGetNotification(callback: (notifications: any[]) => void) {
    const ts = this.lastNotifReceivedTs || Date.now();
    const notifs = await this.NotificationApi.pull(ts);
    const lastNotifTs = notifs.length > 0 ? new Date((notifs[notifs.length - 1] as { createdAt: string }).createdAt).valueOf() : ts;
    this.lastNotifReceivedTs = lastNotifTs;
    // eslint-disable-next-line no-underscore-dangle
    setTimeout(this._loopGetNotification, this.config.defaultPollingInterval);
    await callback(notifs);
  }

  async login(email: string, password: string, isRememberMe: boolean, reCaptchaToken: string): Promise<LoginResponse> {
    const response = await this.UserInfoApi.login(email, password, isRememberMe, reCaptchaToken);
    return response;
  }

  async otpLogin(tempOtpAuthToken: string, otp: string, otpMethod: OTPType, isRememberMe: boolean): Promise<LoginResponse> {
    const tokens = await this.UserInfoApi.otpLogin(tempOtpAuthToken, otp, otpMethod, isRememberMe);
    return tokens;
  }

  async alternativeOtp(tempOtpAuthToken: string): Promise<LoginResponse> {
    const response = await this.UserInfoApi.alternativeOtpRequest(tempOtpAuthToken);
    return response;
  }

  async logout(body = {}): Promise<void> {
    await this.UserInfoApi.logout(body);
    await this.sessionStorage.remove();
    this.sessionStorage.loggedOut();
  }

  afterRequest(cb: (config: RequestConfig, response: AxiosResponse) => Promise<void>) {
    this.cakeRequestManager.afterRequest = cb;
  }

  // TODO:
  // fill up some customized functions if needed
  // other wise, sdk user can always `sdk.xxxApi.directlyConsume()`

  // for example
}
