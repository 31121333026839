import { TxStatus } from '../lib/customType';

export const CONFIRMED_STATUS = 'CONFIRMED';
export const PENDING_STATUS = 'PENDING';
export const REQUIRE_CONFIRMATION_STATUS = 'REQ_USER_CONF';
export const CANCELLED_STATUS = 'CANCELLED';

const WithdrawalStatusMessage: Record<TxStatus, string> = {
  CONFIRMED: 'Complete',
  PENDING: 'Processing',
  REQ_USER_CONF: 'Awaiting user conf.',
  CANCELLED: 'Cancelled',
};

export default function getWithdrawalStatus(status: TxStatus) {
  const message = WithdrawalStatusMessage[status];
  if (!message) {
    const words = (message as string).toLowerCase().split('_');
    const titleCased = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1));
    return titleCased.join(' ');
  }
  return message;
}
