import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';

const useStyles = createUseStyles({
  listContainer: {
    marginTop: Variables.medium,
    fontSize: '1.6rem',
    paddingLeft: '2rem',
    '& > li': {
      paddingLeft: '1rem',
      marginTop: '1rem',
    },
  },
});

export interface ListProps {
  items: string[];
  order?: boolean;
}

export function List({ items, order = true }: ListProps) {
  const classes = useStyles();
  if (order) {
    return (
      <ol className={classes.listContainer}>
        {items.map((item) => (
          <li>
            <Trans>{{ item }}</Trans>
          </li>
        ))}
      </ol>
    );
  }

  return (
    <ul className={classes.listContainer}>
      {items.map((item) => (
        <li>
          <Trans>{{ item }}</Trans>
        </li>
      ))}
    </ul>
  );
}
