import { DfcVoting, DfcVotingRequest } from '../../schema';
import { CakeApiBase } from '../CakeApiBase';

export class DfcVotingApi extends CakeApiBase {
  async getDfcVoting() {
    return this.requestManager.get<DfcVoting[]>('/dfc-voting');
  }

  async voteDfcVoting(request: DfcVotingRequest) {
    return this.requestManager.post('/dfc-voting/vote', request);
  }
}
