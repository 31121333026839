import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkErrorSlice, NetworkErrorType } from '../types/app/network-error';

const initialState: NetworkErrorSlice = {
  hasNetworkError: false,
  networkErrorType: null,
};

const networkErrorSlice = createSlice({
  name: 'networkError',
  initialState,
  reducers: {
    setNetworkError: (state, action: PayloadAction<NetworkErrorType>) => {
      state.hasNetworkError = true;
      state.networkErrorType = action.payload;
    },
    clearNetworkError: (state) => {
      state.hasNetworkError = false;
      state.networkErrorType = null;
    },
  },
});

export const { setNetworkError, clearNetworkError } = networkErrorSlice.actions;

export default networkErrorSlice.reducer;
