export interface LogoutAction {
  type: GlobalActionTypes.LOGOUT;
}

export enum GlobalActionTypes {
  LOGOUT = 'global/logout',
}

export const logout = (): LogoutAction => ({
  type: GlobalActionTypes.LOGOUT,
});
