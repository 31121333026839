import { getBoxShadowStyle } from 'common/styles/getBoxShadow.styles';
import { Styles } from 'react-jss';
import Variables from '../../../Variables.styles';

const getColor = (isFocused, isInvalid) => {
  if (isInvalid) {
    return Variables.redInvalidControl;
  }
  if (isFocused) {
    return Variables.electricBerry;
  }
  return Variables.gray600;
};

const onFocusedPadding = '18px 16px 6px 16px';
const onFocusedLabel = {
  fontSize: '1.2rem',
  fontWeight: 500,
  lineHeight: Variables.medium,
  paddingTop: '3px',
};

const styles: Styles = {
  wrapper: {},
  inputGroupContainer: {
    position: 'relative',
  },
  label: {
    cursor: 'text',
    color: ({ isFocused, isInvalid }) => getColor(isFocused, isInvalid),
    display: 'block',
    fontSize: ({ fontSize }) => `${fontSize}rem`,
    top: 0,
    left: 0,
    marginBottom: 0,
    padding: '12px 16px',
    border: '1px solid transparent',
    pointerEvents: 'none',
    position: 'absolute',
    transition: 'all .1s ease-in-out',
    width: '100%',
    zIndex: 1,
  },
  input: {
    width: '100%',
    boxShadow: ({ isFocused, isInvalid }) => getBoxShadowStyle(isFocused, isInvalid),
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '12px 16px',
    paddingRight: ({ withSuffix }) => (withSuffix ? '52px' : '16px'),
    fontSize: ({ fontSize }) => `${fontSize}rem`,
    lineHeight: Variables.large,
    transition: 'all .1s ease-in-out',
    WebkitAppearance: 'none',
    '&::placeholder': {
      color: 'transparent',
    },
    '&:disabled:valid': {
      '& ~ label': {
        color: Variables.gray300,
      },
    },
    '&:disabled::placeholder': {
      color: 'transparent',
    },
    '&:valid': {
      padding: onFocusedPadding,
      paddingRight: ({ withSuffix }) => (withSuffix ? '52px' : '16px'),
      '& ~ label': onFocusedLabel,
    },
    '&:disabled:not(:placeholder-shown) ': {
      color: Variables.gray300,
      padding: onFocusedPadding,
      cursor: 'not-allowed',
      paddingRight: ({ withSuffix }) => (withSuffix ? '52px' : '16px'),
      '& ~ label': {
        ...onFocusedLabel,
        color: Variables.gray300,
      },
    },
    '&:focus:not(:placeholder-shown),&:valid': {
      padding: onFocusedPadding,
      paddingRight: ({ withSuffix }) => (withSuffix ? '52px' : '16px'),
      '&::placeholder': {
        color: 'transparent',
      },
      '& ~ label': onFocusedLabel,
    },
    '&:focus': {
      outline: 'none !important',
      padding: onFocusedPadding,
      paddingRight: ({ withSuffix }) => (withSuffix ? '52px' : '16px'),
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
      '& ~ label': onFocusedLabel,
    },
  },
  appendedLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: Variables.medium,
  },
  appendedLabel: {
    paddingLeft: Variables.small,
    color: ({ isInvalid }) => (
      isInvalid ? Variables.redInvalidControl : Variables.gray600
    ),
  },
  invalidControlMessage: {
    display: 'block',
    color: ({ isShowHelpText }) => (isShowHelpText ? Variables.gray600 : Variables.redInvalidControl),
    fontSize: '14px !important',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    minHeight: '20px',
    marginTop: Variables.extraSmall,
    visibility: ({ isInvalid, isShowHelpText }) => ((isInvalid || isShowHelpText) ? 'visible' : 'hidden'),
  },
  suffixLabel: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 50,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    border: 0,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '& svg': {
      fill: Variables.gray600,
      width: 24,
      height: 24,
    },
  },
  bottomHelperText: {
    ...Variables.baseText,
    fontSize: '1.4rem',
    color: Variables.gray400,
    marginBottom: 0,
  },
};

export default styles;
