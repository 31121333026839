import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import sdk from '../../../../sdk';
import fetchWithSpinner from '../../../../utils/fetchWithSpinner';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import { useCakeSelector } from '../../../../app/rootReducer';
import normalizePhoneNumber from '../../../../utils/normalizePhoneNumber';
import ModalLoadingIndicator from '../../../../common/components/ModalLoadingIndicator/ModalLoadingIndicator';
import SegmentedNumberInput from '../../../../common/components/SegmentedNumberInput/SegmentedNumberInput';
import MobilePhoneResendButton from './MobilePhoneResendButton';
import { reloadUser } from '../../../../user/userSlice';
import MobilePhoneStyles from './MobilePhone.styles';

const useStyles = createUseStyles(MobilePhoneStyles);

const INCORRECT_CODE = 'The code you\'ve entered is invalid. Please try again or click on the button to resend a new code.';

const MobilePhoneVerifyOTP = ({ gotoPhoneCallScreen, gotoInputScreen, gotoWallet, timeLeft, startCountdown }): any => {
  const classes = useStyles();
  const { mobileNumber, phoneCountryCode } = useCakeSelector(state => state.userIdentifiableInformation.inputs);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const otpInputRef = useRef(null);
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const trackOnComplete = () => {
    gtmTrack('trackEvent', {
      event: iEventNames.mobile_number_verified,
      event_type: iEventTypes.custom,
    });
  };

  const onComplete = async (code) => {
    setInvalidMessage('');
    setIsRequesting(true);
    try {
      const tokenResponse = await fetchWithSpinner(() => sdk.TwilioApi.verifyToken({
        countryCode: phoneCountryCode,
        phone: mobileNumber,
        token: code,
        channel: 'call',
      }), { showAlert: false });

      if (tokenResponse.isTokenValid && isMountedRef.current) {
        trackOnComplete();
        dispatch(reloadUser());
        gotoWallet();
      }
      setInvalidMessage(INCORRECT_CODE);
    } catch (err) {
      setInvalidMessage(err.message);
    } finally {
      if (otpInputRef.current) otpInputRef.current.clear();
      if (isMountedRef.current) {
        setIsRequesting(false);
      }
    }
  };

  return (
    <>
      {
        isRequesting
          ? <ModalLoadingIndicator placeholder="Verifying your code" isSpinnerVisible={false} />
          : <article className="floating">
            <div>
              <h1 className={classes.title}>
                <Trans>Confirm mobile number</Trans>
              </h1>
              <div className='text-left'>
                <Trans>Enter the 6-digit code that was sent to the mobile number</Trans>
                <span className='ml-2'>{normalizePhoneNumber(phoneCountryCode, mobileNumber)}</span>.
              </div>
              <div className={classes.numberInput}>
                <SegmentedNumberInput
                // eslint-disable-next-line
                // @ts-ignore
                onComplete={onComplete}
                segments={6}
                isFocusedOnMount
                ref={otpInputRef}
              />
              </div>
            </div>
            <MobilePhoneResendButton
              showCountdownMessage={true}
              timeLeft={timeLeft}
              startCountdown={startCountdown}
              gotoPhoneCallScreen={gotoPhoneCallScreen}
              invalidMessage={invalidMessage}
              phoneCountryCode={phoneCountryCode}
              mobileNumber={mobileNumber}
              gotoInputScreen={gotoInputScreen}
            />
          </article>
      }
    </>
  );
};

export default MobilePhoneVerifyOTP;
