import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../app/rootReducer';
import sdk from '../../sdk';

const initialState = {
  twoFactorAuth: {
    isOtpEnabled: false,
    otpInput: null,
    otpEncodedKey: null,
    authUrl: null,
  },
  passwordChange: {
    oldPasswordInput: null,
    newPasswordInput: null,
    newPasswordValidation: true,
  },
};

export const fetchOtpStatus = createAsyncThunk<any, void, ThunkApiConfig>(
  'securitySettings/fetchOtpStatus',
  async () => {
    const response = await sdk.UserInfoApi.get();
    return response.otpVerified;
  },
);

export const fetchOtpDetails = createAsyncThunk<any, void, ThunkApiConfig>(
  'securitySettings/fetchOtpDetails',
  async () => {
    const response = await sdk.UserInfoApi.enableOtp();
    return response;
  },
);

const securitySettingsSlice = createSlice({
  name: 'securitySettingsSlice',
  initialState,
  reducers: {
    setOtpInput: (state, action) => {
      state.twoFactorAuth.otpInput = action.payload;
    },
    setIsOtpEnabled: (state, action) => {
      state.twoFactorAuth.isOtpEnabled = action.payload;
    },
    setOldPasswordInput: (state, action) => {
      state.passwordChange.oldPasswordInput = action.payload;
    },
    setNewPasswordInput: (state, action) => {
      state.passwordChange.newPasswordInput = action.payload;
    },
    setNewPasswordValidation: (state, action) => {
      state.passwordChange.newPasswordValidation = action.payload;
    },
    clear2FA: (state) => {
      state.twoFactorAuth = {
        ...initialState.twoFactorAuth,
        isOtpEnabled: state.twoFactorAuth.isOtpEnabled,
      };
    },
    clearPasswordInputs: (state) => {
      state.passwordChange = initialState.passwordChange;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOtpDetails.fulfilled, (state, action) => {
      state.twoFactorAuth.otpEncodedKey = action.payload.encodedKey;
      state.twoFactorAuth.authUrl = action.payload.authUrl;
    });
    builder.addCase(fetchOtpStatus.fulfilled, (state, action) => {
      state.twoFactorAuth.isOtpEnabled = action.payload;
    });
  },
});

export const { setOtpInput, setIsOtpEnabled, setOldPasswordInput, setNewPasswordInput, setNewPasswordValidation, clear2FA, clearPasswordInputs } = securitySettingsSlice.actions;

export default securitySettingsSlice.reducer;
