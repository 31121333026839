import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { HeaderLink } from 'types/header/header-link';
import Variables from 'Variables.styles';
import CakIcon from 'assets/svg/bake4TheWin/cake.svg';
import { useCakeSelector } from '../../../app/rootReducer';
import {
  getCanShowVisualsSelector,
  getCampaignDataLoadStatus,
} from '../bake4TheWinSelector';
import { fetchBake4TheWinCampaignInfo } from '../bake4TheWinSlice';

const useStyles = createUseStyles({
  outerWrapperDesktop: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: Variables.medium,
  },
  wrapperDesktop: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  wrapperMobile: {
    display: 'inline-block',
    height: 58,
    cursor: 'pointer',
    padding: '6px 16px',
  },
  container: {
    background: `linear-gradient(84.78deg, ${Variables.hyperBerry6} 9.58%, #9610FF 91.72%);`,
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Variables.small,
    width: 168,
    height: 36,
  },
  linkWrapper: {
    whiteSpace: 'nowrap',
    lineHeight: Variables.large,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cakeIcon: {
    width: 18,
    marginRight: 10,
  },
  linkText: {
    color: Variables.white,
  },
});

const Bake4TheWinHeaderLink = ({ index, isMobile = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const headerLink = new HeaderLink(
    'Bake 4 the win',
    '/bake-4-the-win',
  );
  const { text, link } = headerLink;
  const campaignDataLoadStatus = useCakeSelector(getCampaignDataLoadStatus);
  const canShowHeaderLink = useCakeSelector(getCanShowVisualsSelector);

  useEffect(() => {
    if (campaignDataLoadStatus === 'UNLOADED') {
      dispatch(fetchBake4TheWinCampaignInfo());
    }
  }, [dispatch, campaignDataLoadStatus]);

  if (!canShowHeaderLink) {
    return null;
  }

  return (
    <div className={isMobile ? null : classes.outerWrapperDesktop}>
      <div
        className={isMobile ? classes.wrapperMobile : classes.wrapperDesktop}
        key={`header-link-${index}`}
      >
        <div className={classes.container}>
          <Link to={link} className={classes.linkWrapper}>
            <img src={CakIcon} className={classes.cakeIcon} alt="Birthday cake icon" />
            <span className={classes.linkText}><Trans>{text}</Trans></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Bake4TheWinHeaderLink;
