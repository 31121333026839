import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import { isMobile } from 'react-device-detect';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useCakeSelector } from 'app/rootReducer';

const useStyles = createUseStyles<string, { ready: boolean }>({
  widgetWrapper: {
    flexGrow: 1,
  },
  chatBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 110,
  },
  chatBackgroundMobile: {
    composes: '$chatBackground',
    width: '64px',
    height: '64px',
    left: 0,
    bottom: 0,
    borderRight: Variables.primaryBorder,
    backgroundColor: Variables.white,
  },
  chatBackgroundDesktop: {
    composes: '$chatBackground',
    width: '150px',
    height: '64px',
  },
  chatWidget: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '110',
    cursor: 'pointer',
  },
  chatWidgetMobile: {
    composes: '$chatWidget',
    backgroundColor: ({ ready }) => (ready ? '#6400FF' : Variables.gray600),
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'flex',
  },
  chatWidgetDesktop: {
    composes: '$chatWidget',
    backgroundColor: ({ ready }) => (ready ? '#6400FF' : Variables.gray600),
    height: '45px',
    width: '135px',
    borderRadius: '25px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    '& > *': {
      margin: '3px',
    },
  },
  questionIcon: {
    color: 'white',
    fontSize: '24px',
  },
});

export default function ChatIcon() {
  const { details: userDetails } = useCakeSelector((state) => state.user);
  const { isReady } = useCakeSelector((state) => state.zendesk);
  const [ready, setReady] = useState(false);
  const classes = useStyles({
    ready,
  });

  useEffect(() => {
    if (isReady) {
      setReady(true);
    }
  }, [isReady, setReady]);

  const onSupportClick = () => {
    if (userDetails) {
      window.open('https://support.bake.io/en', '_blank', 'noopener,noreferrer');
    }
  };

  if (!userDetails) {
    return null;
  }

  return (
    <div className={classes.widgetWrapper}>
      <div className={isMobile ? classes.chatBackgroundMobile : classes.chatBackgroundDesktop}>
        <div className={isMobile ? classes.chatWidgetMobile : classes.chatWidgetDesktop} onClick={onSupportClick}>
          <AiOutlineQuestionCircle className={classes.questionIcon} />
          {!isMobile && <div><Trans>Support</Trans></div>}
        </div>
      </div>
    </div>
  );
}
