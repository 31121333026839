import { ModalContent, ModalFooter } from 'modals/components';
import React, { useCallback, useRef } from 'react';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import modalStyles from 'common/styles/Modal.styles';
import { createUseStyles } from 'react-jss';
import Switch from 'views/components/Switch';
import { Trans } from 'react-i18next';
import { useCakeSelector } from 'app/rootReducer';
import { RegisterConsentStage, setAcceptedDataConsent, setAcceptedMarketingConsent, setAcceptedTermsAndConditions } from 'views/Pages/Authentication/authSlice';
import Button from 'common/components/Button/Button';
import { useDispatch } from 'react-redux';

const useStyles = createUseStyles({
  ...modalStyles,
  contentContainer: {
    minHeight: '550px',
  },
  inputField: {
    marginTop: Variables.large,
    marginBottom: Variables.large,
  },
  titleText: {
    paddingTop: Variables.large,
    fontSize: Variables.fontSize.h0,
    fontWeight: Variables.fontWeight.extraBold,
  },
  switch: {
    padding: 0,
  },
  staticText: {
    fontSize: '16px',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
    fontWeight: Variables.fontWeight.bold,
  },
  subText: {
    color: Variables.gray400,
  },
  staticTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.5em 1em',
    gap: Variables.small,
    border: Variables.primaryBorder,
    background: Variables.white,
    boxShadow: 'rgb(0 0 0 / 4%) 0px 8px 16px 0px',
    borderRadius: '8px',
  },
  footerContainer: {
    paddingTop: Variables.small,
    paddingBottom: Variables.small,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    staticText: {
      fontSize: Variables.fontSize.small,
      marginLeft: '12px',
    },
  },
});

const UserAllConsentsPage = ({ goto, onClickContinueEvent }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const {
    acceptedTermsAndConditions,
    acceptedMarketingConsent,
    acceptedDataConsent,
  } = useCakeSelector(state => state.auth);

  const onClickConsent = useCallback(async (consent) => {
    if (consent === RegisterConsentStage.AGE_RESTRICTION_CONSENT) {
      if (!acceptedTermsAndConditions) {
        goto(consent);
      } else {
        dispatch(setAcceptedTermsAndConditions(!acceptedTermsAndConditions));
      }
    }
    if (consent === RegisterConsentStage.PERSONAL_DATA_CONSENT) {
      if (!acceptedDataConsent) {
        goto(consent);
      } else {
        dispatch(setAcceptedDataConsent(!acceptedDataConsent));
      }
    }
    if (consent === RegisterConsentStage.MARKETING_CONSENT) {
      if (!acceptedMarketingConsent) {
        goto(consent);
      } else {
        dispatch(setAcceptedMarketingConsent(!acceptedMarketingConsent));
      }
    }
  }, [acceptedDataConsent, acceptedTermsAndConditions, acceptedMarketingConsent, dispatch, goto]);

  return (
    <>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.contentContainer}`}>
          <div className={classes.titleText}>
            <Trans>I acknowledge</Trans>
          </div>
          <div className={classes.inputField}>
            <div className={classes.staticTextWrapper}>
              <div className={classes.staticText}>
                <Trans>I am 18 years old age or older.</Trans>
              </div>
              <Switch
                tabIndex={1}
                inputRef={inputRef}
                className={classes.switch}
                isActive={acceptedTermsAndConditions}
                onChange={() => onClickConsent(RegisterConsentStage.AGE_RESTRICTION_CONSENT)}
              >
              </Switch>
            </div>
          </div>
          <div className={classes.inputField}>
            <div className={classes.staticTextWrapper}>
              <div className={classes.staticText}>
                <Trans>I agree to Bake collecting and processing my personal data.</Trans>
              </div>
              <Switch
                tabIndex={1}
                inputRef={inputRef}
                className={classes.switch}
                isActive={acceptedDataConsent}
                onChange={() => onClickConsent(RegisterConsentStage.PERSONAL_DATA_CONSENT)}
              >
              </Switch>
            </div>
          </div>
          <div className={classes.inputField}>
            <div className={classes.staticTextWrapper}>
              <div>
                <div className={classes.staticText}>
                  <Trans>I agree to receiving marketing communications from Bake on new products, services and features.</Trans>
                </div>
                <div className={classes.subText}><Trans>Optional</Trans></div>
              </div>
              <Switch
                tabIndex={2}
                inputRef={inputRef}
                className={classes.switch}
                isActive={acceptedMarketingConsent}
                onChange={() => onClickConsent(RegisterConsentStage.MARKETING_CONSENT)}
              >
              </Switch>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={`${classes.contentMini} ${classes.footerContainer}`}>
          <Button
            className={classes.button}
            primary
            block
            disabled={!acceptedTermsAndConditions || !acceptedDataConsent}
            type="button"
            onClick={() => onClickContinueEvent()}
          >
            <Trans>Consent required to continue</Trans>
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default UserAllConsentsPage;
