import { MinimumAmountInUsd } from '../../schema';
import { FreezerPage, FreezerPageAuthenticated, GetFreezerActionsResponse } from '../../schema/freeze';
import { CakeApiBase } from '../CakeApiBase';

export class FreezerApi extends CakeApiBase {
  async freezerPage() {
    return this.requestManager.get<FreezerPage>('freezer-page');
  }

  async freezerPageAuthenticated() {
    return this.requestManager.get<FreezerPageAuthenticated>('freezer-page/authenticated');
  }

  async getFreezerActions(limit: number, offset: number) {
    return this.requestManager.get<GetFreezerActionsResponse>('/freezer/freeze', {
      // type,
      limit,
      offset,
    });
  }

  async getFreezerConfectioneryActions(limit: number, offset: number) {
    return this.requestManager.get<GetFreezerActionsResponse>('/freezer/confectionery', {
      limit,
      offset,
    });
  }

  async moveToNewFreezer(id: string, newFreezeDuration: number) {
    return this.requestManager.post(`/confectionery/${id}/move-to-new-freezer`, {
      newFreezeDuration,
    });
  }

  async updateFreezeAutoRenewSetting(id: string, newAutoRenew: boolean) {
    return this.requestManager.post(`/freezer/freeze/${id}/auto-renew-setting`, { autoRenew: newAutoRenew });
  }

  async updateAllFreezeAutoRenewSetting(newAutoRenew: boolean) {
    return this.requestManager.post('/freezer/auto-renew-setting', { autoRenew: newAutoRenew });
  }

  async freezeStaking(amount: string, coinId: string, freezeDurationInMonths: number, autoRenew = false) {
    return this.requestManager.post('/freezer/freeze/staking', {
      amount,
      coinId,
      freezeDurationInMonths,
      autoRenew,
    });
  }

  async freezeLiquidityMining(amount: string, pairId: string, freezeDurationInMonths: number, autoRenew = false) {
    return this.requestManager.post('/freezer/freeze/liquidity-mining', {
      amount,
      pairId,
      freezeDurationInMonths,
      autoRenew,
    });
  }

  async extendFreezeDuration(id: string, freezeDurationInMonths: number) {
    return this.requestManager.post(`/freezer/freeze/${id}/freeze-duration`, { freezeDurationInMonths });
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/freezer/minimum');
  }
}
