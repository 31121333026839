import React, { Context, createContext } from 'react';
import StandaloneModals from 'common/components/StandaloneModals/StandaloneModals';
import useStandaloneModal, { UseStandaloneModalTypes } from './useStandaloneModal';

const StandaloneModalContext: Context<UseStandaloneModalTypes> = createContext({} as UseStandaloneModalTypes);
const { Provider } = StandaloneModalContext;

const StandaloneModalProvider = ({ children }) => {
  const value = useStandaloneModal();

  return (
    <Provider value={value}>
      <StandaloneModals />
      {children}
    </Provider>
  );
};

export { StandaloneModalContext, StandaloneModalProvider };
