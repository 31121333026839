import BigNumber from 'bignumber.js';

export default (value: BigNumber | number | string) => {
  if (BigNumber.isBigNumber(value)) {
    return value.isZero();
  }

  const isZero = value === 0 || value === '0.00' || value === '0';
  return isZero;
};
