import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import constants from './constants';

BigNumber.config({ EXPONENTIAL_AT: 21 });

export default function localizeBigNumber(
  value: BigNumberConstructable,
  isDisplayAll = false,
  decimalPoints = constants.FRIENDLY_DECIMALS,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  fmt: BigNumber.Format = constants.BN_STRING_FORMAT,
): string {
  const bnValue = !(new BigNumber(value).isNaN()) ? new BigNumber(value) : new BigNumber(0);
  const decPoint = decimalPoints;

  if (isDisplayAll) {
    return bnValue.toFormat(fmt);
  }

  return new BigNumber(bnValue).decimalPlaces(decPoint, roundingMode).toFormat(fmt);
}
