export const mobileAppDeeplinks = [
  '/withdrawal/*',
  '/select-asset-screen/*',
  '/repay-loan',
  '/portfolio',
  '/discover/*',
  '/reset-password/*',
  '/verify/*',
  '/account',
  '/referral',
  '/buy/*',
  '/dca/*',
  '/product/select-asset/*',
  '/recurring-buy/*',
];
