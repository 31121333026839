import React, { Component } from 'react';
import ReactQRCode from 'qrcode.react';

export default class QRCode extends Component {
  render() {
    const {
      value,
      size,
    } = this.props;
    return (
      <span className="qrcode">
        <ReactQRCode value={value || ''} size={size} />
      </span>
    );
  }
}
