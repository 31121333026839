// dfi quiz
import dfiCover from 'assets/svg/learnEarn/dfiQuiz/dfi-cover.svg';
import dfiL1P1 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p1.svg';
import dfiL1P2 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p2.svg';
import dfiL1P3 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p3.svg';
import dfiL1P4 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p4.svg';
import dfiL1P5 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p5.svg';
import dfiL1P6 from 'assets/svg/learnEarn/dfiQuiz/lesson-1/l1-p6.svg';
import dfiL2P1 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p1.svg';
import dfiL2P2 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p2.svg';
import dfiL2P3 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p3.svg';
import dfiL2P4 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p4.svg';
import dfiL2P5 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p5.svg';
import dfiL2P6 from 'assets/svg/learnEarn/dfiQuiz/lesson-2/l2-p6.svg';
import dfiL3P1 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p1.svg';
import dfiL3P2 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p2.svg';
import dfiL3P3 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p3.svg';
import dfiL3P4 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p4.svg';
import dfiL3P5 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p5.svg';
import dfiL3P6 from 'assets/svg/learnEarn/dfiQuiz/lesson-3/l3-p6.svg';
import dfiL4P1 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p1.svg';
import dfiL4P2 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p2.svg';
import dfiL4P3 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p3.svg';
import dfiL4P4 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p4.svg';
import dfiL4P5 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p5.svg';
import dfiL4P6 from 'assets/svg/learnEarn/dfiQuiz/lesson-4/l4-p6.svg';
import dfiL5P1 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p1.svg';
import dfiL5P2 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p2.svg';
import dfiL5P3 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p3.svg';
import dfiL5P4 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p4.svg';
import dfiL5P5 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p5.svg';
import dfiL5P6 from 'assets/svg/learnEarn/dfiQuiz/lesson-5/l5-p6.svg';

// btc quiz
import btcCover from 'assets/svg/learnEarn/btcQuiz/btc-cover.svg';
import btcL1P1 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p1.svg';
import btcL1P2 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p2.svg';
import btcL1P3 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p3.svg';
import btcL1P4 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p4.svg';
import btcL1P5 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p5.svg';
import btcL1P6 from 'assets/svg/learnEarn/btcQuiz/lesson-1/l1-p6.svg';
import btcL2P1 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p1.svg';
import btcL2P2 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p2.svg';
import btcL2P3 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p3.svg';
import btcL2P4 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p4.svg';
import btcL2P5 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p5.svg';
import btcL2P6 from 'assets/svg/learnEarn/btcQuiz/lesson-2/l2-p6.svg';
import btcL3P1 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p1.svg';
import btcL3P2 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p2.svg';
import btcL3P3 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p3.svg';
import btcL3P4 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p4.svg';
import btcL3P5 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p5.svg';
import btcL3P6 from 'assets/svg/learnEarn/btcQuiz/lesson-3/l3-p6.svg';
import btcL4P1 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p1.svg';
import btcL4P2 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p2.svg';
import btcL4P3 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p3.svg';
import btcL4P4 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p4.svg';
import btcL4P5 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p5.svg';
import btcL4P6 from 'assets/svg/learnEarn/btcQuiz/lesson-4/l4-p6.svg';
import btcL5P1 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p1.svg';
import btcL5P2 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p2.svg';
import btcL5P3 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p3.svg';
import btcL5P4 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p4.svg';
import btcL5P5 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p5.svg';
import btcL5P6 from 'assets/svg/learnEarn/btcQuiz/lesson-5/l5-p6.svg';

// eth quiz
import ethCover from 'assets/svg/learnEarn/ethQuiz/eth-cover.png';
import ethL1P1 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p1.png';
import ethL1P2 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p2.png';
import ethL1P3 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p3.png';
import ethL1P4 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p4.png';
import ethL1P5 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p5.png';
import ethL1P6 from 'assets/svg/learnEarn/ethQuiz/lesson-1/l1-p6.png';
import ethL2P1 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p1.png';
import ethL2P2 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p2.png';
import ethL2P3 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p3.png';
import ethL2P4 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p4.png';
import ethL2P5 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p5.png';
import ethL2P6 from 'assets/svg/learnEarn/ethQuiz/lesson-2/l2-p6.png';
import ethL3P1 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p1.png';
import ethL3P2 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p2.png';
import ethL3P3 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p3.png';
import ethL3P4 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p4.png';
import ethL3P5 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p5.png';
import ethL3P6 from 'assets/svg/learnEarn/ethQuiz/lesson-3/l3-p6.png';
import ethL4P1 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p1.png';
import ethL4P2 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p2.png';
import ethL4P3 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p3.png';
import ethL4P4 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p4.png';
import ethL4P5 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p5.png';
import ethL4P6 from 'assets/svg/learnEarn/ethQuiz/lesson-4/l4-p6.png';
import ethL5P1 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p1.png';
import ethL5P2 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p2.png';
import ethL5P3 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p3.png';
import ethL5P4 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p4.png';
import ethL5P5 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p5.png';
import ethL5P6 from 'assets/svg/learnEarn/ethQuiz/lesson-5/l5-p6.png';

export default {
  dfiCover,
  dfiL1P1,
  dfiL1P2,
  dfiL1P3,
  dfiL1P4,
  dfiL1P5,
  dfiL1P6,
  dfiL2P1,
  dfiL2P2,
  dfiL2P3,
  dfiL2P4,
  dfiL2P5,
  dfiL2P6,
  dfiL3P1,
  dfiL3P2,
  dfiL3P3,
  dfiL3P4,
  dfiL3P5,
  dfiL3P6,
  dfiL4P1,
  dfiL4P2,
  dfiL4P3,
  dfiL4P4,
  dfiL4P5,
  dfiL4P6,
  dfiL5P1,
  dfiL5P2,
  dfiL5P3,
  dfiL5P4,
  dfiL5P5,
  dfiL5P6,
  btcCover,
  btcL1P1,
  btcL1P2,
  btcL1P3,
  btcL1P4,
  btcL1P5,
  btcL1P6,
  btcL2P1,
  btcL2P2,
  btcL2P3,
  btcL2P4,
  btcL2P5,
  btcL2P6,
  btcL3P1,
  btcL3P2,
  btcL3P3,
  btcL3P4,
  btcL3P5,
  btcL3P6,
  btcL4P1,
  btcL4P2,
  btcL4P3,
  btcL4P4,
  btcL4P5,
  btcL4P6,
  btcL5P1,
  btcL5P2,
  btcL5P3,
  btcL5P4,
  btcL5P5,
  btcL5P6,
  ethCover,
  ethL1P1,
  ethL1P2,
  ethL1P3,
  ethL1P4,
  ethL1P5,
  ethL1P6,
  ethL2P1,
  ethL2P2,
  ethL2P3,
  ethL2P4,
  ethL2P5,
  ethL2P6,
  ethL3P1,
  ethL3P2,
  ethL3P3,
  ethL3P4,
  ethL3P5,
  ethL3P6,
  ethL4P1,
  ethL4P2,
  ethL4P3,
  ethL4P4,
  ethL4P5,
  ethL4P6,
  ethL5P1,
  ethL5P2,
  ethL5P3,
  ethL5P4,
  ethL5P5,
  ethL5P6,
};
