import { useCakeSelector } from 'app/rootReducer';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import config from '../../../../config/app';
import sdk from '../../../../sdk';
import fetchWithSpinner from '../../../../utils/fetchWithSpinner';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import normalizePhoneNumber from '../../../../utils/normalizePhoneNumber';
import MobilePhoneResendButton from './MobilePhoneResendButton';
import { setCodeSendId } from '../identifiableInformationSlice';
import { reloadUser } from '../../../../user/userSlice';

const countriesExcludedFromTwilio = config.COUNTRIES_EXCLUDED_FROM_TWILIO;

const SUCCESS_VERIFIED_PHONE_MESSAGE = 'Successfully verified your phone.';
const SUCCESS_SENT_SMS_CODE = 'Successfully sent you an SMS with your verification code.';

const MobilePhoneSendingSMS = ({ gotoVerifyOTPScreen, gotoInputScreen, timeLeft, startCountdown, gotoWallet, isAllowedToUpdate }) => {
  const { mobileNumber, phoneCountryCode } = useCakeSelector(state => state.userIdentifiableInformation.inputs);
  const { phoneNumberVerified } = useCakeSelector(state => state.user.details);
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const sendingSMS = useCallback(async () => {
    const isSkipVerification = countriesExcludedFromTwilio.includes(phoneCountryCode);
    if (phoneNumberVerified && isMountedRef.current && !isAllowedToUpdate) {
      gotoWallet();
      return;
    }
    try {
      await fetchWithSpinner(() => sdk.TwilioApi.sendVerificationSms({
        countryCode: phoneCountryCode,
        phone: mobileNumber,
        channel: 'sms',
      }), {
        successMessage: isSkipVerification ? SUCCESS_VERIFIED_PHONE_MESSAGE : SUCCESS_SENT_SMS_CODE,
        throwError: true,
      });
      if (isSkipVerification) {
        dispatch(reloadUser());
      } else {
        dispatch(setCodeSendId(new Date().getTime()));
        startCountdown(); // Start countdown
      }
      if (isSkipVerification) {
        gotoWallet();
      } else {
        gotoVerifyOTPScreen(); // MOBILE_NUMBER_VERIFY_OTP
      }
    } catch (error) {
      window.cakepool.showAlert(
        'error',
        <span>
          <Trans>SMS sending failed</Trans>: <Trans>{error.message}</Trans>
        </span>,
      );
      gotoInputScreen(); // MOBILE_NUMBER_INPUT
    }
  }, [dispatch, gotoVerifyOTPScreen, isMountedRef, mobileNumber, phoneCountryCode, phoneNumberVerified, startCountdown, gotoInputScreen, gotoWallet, isAllowedToUpdate]);

  useEffect(() => {
    sendingSMS();
  // eslint-disable-next-line
  }, []); // Run once

  return (
    <article className="floating">
      <div>
        <div className='text-left'>
            <Trans>Sending verification SMS to the mobile number</Trans> <div>{normalizePhoneNumber(phoneCountryCode, mobileNumber)}</div>...
          </div>
      </div>
      <MobilePhoneResendButton
        forceDisabledResendCode={true}
        forceDisabledReceivedCall={true}
        disableChangeMobileNumber={true}
        timeLeft={timeLeft}
        startCountdown={startCountdown}
        showCountdownMessage={false}
        phoneCountryCode={phoneCountryCode}
        mobileNumber={mobileNumber}
        gotoVerifyOTPScreen={gotoVerifyOTPScreen}
        gotoInputScreen={gotoInputScreen}
      />
    </article>
  );
};

export default MobilePhoneSendingSMS;
