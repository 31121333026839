import { Share } from '../../schema';
import { NodeStatus } from '../../schema/node-status';
import { CakeApiBase } from '../CakeApiBase';

export class NodeApi extends CakeApiBase {
  async status(query) {
    return this.requestManager.get<NodeStatus[]>('nodes', query);
  }

  async shares() {
    return this.requestManager.get<Share[]>('shares');
  }
}
