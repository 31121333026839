import getVariableType from './getVariableType';
import isEmpty from './isEmpty';
import isShareEmpty from './isShareEmpty';
import isValidEmail from './isValidEmail';
import parseDateTime from './parseDateTime';
import localizeBigNumber from './localizeBigNumber';
import checkMovableAvailability from './checkMovableAvailability';
import getMonthDateTime from './getMonthDateTime';
import parseSentenceCase from './parseSentenceCase';
import parseTitleCase from './parseTitleCase';
import simplifyTimeFormat from './simplifyTimeFormat';
import isNowLater from './isNowLater';
import plusMilliseconds from './plusMilliseconds';
import calculateFee from './calculateFee';
import calculateNetAmount from './calculateNetAmount';
import calculateTargetBatchReturnMultiplier from './calculateTargetBatchReturnMultiplier';
import fromISOHumanly from './fromISOHumanly';
import fromISOHumanlyDateOnly from './fromISOHumanlyDateOnly';
import getOffset from './getOffset';
import getTotalPages from './getTotalPages';
import coinsToUSD from './coinsToUSD';
import arrayToObject from './arrayToObject';
import displayDurationDays from './displayDurationDays';
import getCoinPrice from './getCoinPrice';
import getCsvExportDescription from './getCsvExportDescription';
import positiveCoinSwapBalance from './positiveCoinSwapBalance';
import getAge from './getAge';
import getFaqLinks from './getFaqLinks';
import getNrgSinsBlogPostLink from './getNrgSinsBlogPostLink';
import getShortFileName from './getShortFileName';
import getTelegramLinkByLanguage from './getTelegramLinkByLanguage';
import getSocialMediaLinks from './getSocialMediaLinks';
import getTelegramLinkVip from './getTelegramLinkVip';
import getMessageForUser from './getMessageForUser';
import getLocale from './getLocale';
import getShortVersionOfNumber from './getShortVersionOfNumber';
import getWithdrawalStatus from './getWithdrawalStatus';
import getLapisKnowledgeBaseLink from './getLapisKnowledgeBaseLink';
import convertUSDtoBTC from './convertUSDToBTC';
import calculateBatchProgress from './calculateBatchProgress';
import show8Digits from './show8Digits';
import formatPercentage from './formatPercentage';
import { filterAdminExportTransactionData, filterUserExportTransactionData } from './filterExportTransactionData';
import filterExportRevenueTransactionData from './filterExportRevenueTransactionData';
import validateMobilePhoneNumber from './validateMobilePhoneNumber';
import validateExpiryDate from './validateExpiryDate';
import coinsToNativeCurrency from './coinsToNativeCurrency';
import getCurrencySymbol from './getCurrencySymbol';
import getReferralFaqLink from './getReferralFaqLink';
import getMomentLangFromi18n from './getMomentLangFromi18n';
import getProofOfIdentityFaqLink from './getProofOfIdentityFaqLink';
import getProofOfAddressFaqLink from './getProofOfAddressFaqLink';
import formatCrypto from './formatCrypto';
import getAuditActionTitles from './getAuditActionTitles';
import getValueInPreferredCurrency from './getValueInPreferredCurrency';
import getBchAddressInFormat from './getBchAddressInFormat';
import validateDecimalPlaces from './validateDecimalPlaces';
import convertBTCOrETHToCryptoAmount from './convertBTCETHToCryptoAmount';
import convertCryptoToBTCorETHAmount from './convertCryptoToBTCorETHAmount';
import convertUSDToPreferredCurrency from './convertUSDToPreferredCurrency';
import isPositiveAndNotZeroNumber from './isPositiveAndNotZeroNumber';
import getCoinsOnSale from './getCoinsOnSale';
import getDecimals from './getDecimals';
import isZero from './isZero';
import {
  DateValidationError,
  getErrorMessage,
  validateDay,
  validateYear,
  validateWholeDate,
  validateWholeDateObject,
  validateMinAge,
  validateMinAgeDateObject,
  validateDateFilled,
  validateDateExpiry,
  validateValidityPeriod,
} from './dateValidation';

export default {
  convertBTCOrETHToCryptoAmount,
  convertCryptoToBTCorETHAmount,
  convertUSDToPreferredCurrency,
  DateValidationError,
  getErrorMessage,
  validateDay,
  validateYear,
  validateWholeDate,
  validateWholeDateObject,
  validateMinAge,
  validateMinAgeDateObject,
  validateDateFilled,
  validateDateExpiry,
  validateValidityPeriod,
  isPositiveAndNotZeroNumber,
  getCoinsOnSale,
  getDecimals,
  arrayToObject,
  getVariableType,
  parseDateTime,
  // set,
  isEmpty,
  isValidEmail,
  isNowLater,
  isShareEmpty,
  isZero,
  localizeBigNumber,
  checkMovableAvailability,
  getMonthDateTime,
  parseSentenceCase,
  parseTitleCase,
  // scrollIntoView,
  // getMousePosition,
  // getCoinIconName,
  // getIconName,
  // getTypeTitle,
  simplifyTimeFormat,
  plusMilliseconds,
  calculateFee,
  calculateNetAmount,
  calculateTargetBatchReturnMultiplier,
  fromISOHumanly,
  fromISOHumanlyDateOnly,
  getOffset,
  getTotalPages,
  // getLanguageCode,
  getLocale,
  // getLocalizedDate,
  // getLocalizedDateFromNow,
  displayDurationDays,
  coinsToUSD,
  getCoinPrice,
  // renderBuyAlertMessage,
  // displayBuySharesMessage,
  // displayDepositCoinMessage,
  // displayWithdrawCoinMessage,
  // displayPoolOutOfShareMessage,
  // displaySettlementInProgressMessage,
  getCsvExportDescription,
  // fileToBase64,
  formatPercentage,
  filterAdminExportTransactionData,
  filterUserExportTransactionData,
  positiveCoinSwapBalance,
  getAge,
  getFaqLinks,
  getShortFileName,
  getTelegramLinkByLanguage,
  getTelegramLinkVip,
  getSocialMediaLinks,
  getNrgSinsBlogPostLink,
  // displayWithdrawRequestMessage,
  getMessageForUser,
  getShortVersionOfNumber,
  getWithdrawalStatus,
  getLapisKnowledgeBaseLink,
  convertUSDtoBTC,
  filterExportRevenueTransactionData,
  calculateBatchProgress,
  validateMobilePhoneNumber,
  validateExpiryDate,
  show8Digits,
  // browserLangToi18nLang,
  coinsToNativeCurrency,
  getCurrencySymbol,
  getReferralFaqLink,
  getMomentLangFromi18n,
  getProofOfIdentityFaqLink,
  getProofOfAddressFaqLink,
  formatCrypto,
  getAuditActionTitles,
  getValueInPreferredCurrency,
  getBchAddressInFormat,
  validateDecimalPlaces,
};
