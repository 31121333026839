import moment, { Moment } from 'moment';
import { Shares } from 'types/freezer/freezer-calculator';
import { FreezerProduct } from 'types/freezer/freezer-page';

export const FREEZES_PER_PAGE = 4;
export const DEFROST_DATE_FORMAT = 'DD MMM, YYYY';
export const FREEZER_DEFAULT_DECIMALS = 2;
export const DEFICHAIN_FREEZER_MONTHS = [60, 120];

export const returnRateType = {
  [FreezerProduct.STAKING]: 'APY',
  [FreezerProduct.LIQUIDITY_MINING]: 'APR',
};

export const freezerAssetTypeText = {
  [Shares.STAKING]: {
    returnRateType: 'APY',
    rebateFeeLabel: 'Rebate of staking fees',
  },
  [Shares.LIQUIDITY_MINING]: {
    returnRateType: 'APR',
    rebateFeeLabel: 'Rebate of liquidity mining fees',
  },
};

export const getNextFreezeDurationInMonths = (daysTillExpiryDate: number): number => {
  if (daysTillExpiryDate < 0) {
    return 0;
  }
  if (daysTillExpiryDate <= 30) {
    return 1;
  }
  if (daysTillExpiryDate <= 90) {
    return 3;
  }
  return 6;
};

export const getDaysTillExpiryDate = (creationDate: string): number => {
  const expiryDate = moment(creationDate).add(180, 'days');
  return expiryDate.diff(moment(), 'days', true);
};

export const formatMonths = (months: number | string): string => {
  moment.relativeTimeThreshold('M', 121);
  return moment().add(months, 'months').from(moment(), true);
};

export const formatDefrostDate = (momentObject: Moment, language?: string): string => {
  if (language === 'zht') {
    return momentObject.format('ll');
  }
  return momentObject.format(DEFROST_DATE_FORMAT);
};

export const initTermDurationOptions = [
  {
    label: '120 months',
    value: 120,
    rewardMultiplier: '2',
    apy: 0,
    expiry: null,
  },
  {
    label: '60 months',
    value: 60,
    rewardMultiplier: '1.5',
    apy: 0,
    expiry: null,
  },
  {
    label: '36 months',
    value: 36,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
  {
    label: '24 months',
    value: 24,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
  {
    label: '12 months',
    value: 12,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
  {
    label: '6 months',
    value: 6,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
  {
    label: '3 months',
    value: 3,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
  {
    label: '1 months',
    value: 1,
    rewardMultiplier: '',
    apy: 0,
    expiry: null,
  },
];
