import { CakeApiBase } from '../CakeApiBase';

export class VoucherInfoApi extends CakeApiBase {
  async validate(code: string) {
    return this.requestManager.post<{ isValid: boolean, value: string }>('voucher/validate', {
      code,
    });
  }

  async redeem(code: string, excludeRazer?: boolean) {
    return this.requestManager.post('voucher/redeem', {
      code,
      excludeRazer,
    });
  }

  async dismiss(voucherId: string) {
    return this.requestManager.put('voucher/dismiss', {
      voucherId,
    });
  }
}
