import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { OnRampProviderType } from '../../../types/onramp/onramp-provider';
import { FiatBuyModalState } from '../../../types/onramp/state';
import { FIAT_TYPE, CRYPTO_TYPE } from './fiatBuyModalService';
import { fetchTransakFiats, fetchTransakPrice } from './fiatBuyModalSliceThunk';

export const handleTransakExtraReducers = (
  builder: ActionReducerMapBuilder<FiatBuyModalState>,
) => {
  builder.addCase(fetchTransakFiats.pending, (state) => {
    state.transak.isFiatsLoading = true;
  });

  builder.addCase(fetchTransakFiats.fulfilled, (state, action) => {
    state.transak.fiats = action.payload.fiats;
    state.transak.isFiatsLoading = false;
  });

  builder.addCase(fetchTransakFiats.rejected, (state) => {
    state.transak.isFiatsLoading = false;
  });

  builder.addCase(fetchTransakPrice.pending, (state, action) => {
    const {
      arg: type,
    } = action.meta;
    state.transak.isFetchingCryptoPrice = false;
    state.transak.isFetchingFiatPrice = false;

    if (type === FIAT_TYPE) {
      state.transak.isFetchingCryptoPrice = true;
    } else if (type === CRYPTO_TYPE) {
      state.transak.isFetchingFiatPrice = true;
    }
    state.transak.selectedFiatPrice = null;
    state.error = null;
  });

  builder.addCase(fetchTransakPrice.fulfilled, (state, action) => {
    if (state.type !== OnRampProviderType.TRANSAK) {
      return;
    }
    const { response: selectedPrice, type } = action.payload;
    state.transak.selectedFiatPrice = selectedPrice;
    if (type === FIAT_TYPE) {
      state.inputs.targetAmount = Number(selectedPrice.cryptoAmount);
    } else if (type === CRYPTO_TYPE) {
      state.inputs.sourceAmount = Number(selectedPrice.fiatAmount);
    }
    state.transak.isFetchingCryptoPrice = false;
    state.transak.isFetchingFiatPrice = false;
    state.error = null;
  });

  builder.addCase(fetchTransakPrice.rejected, (state, action) => {
    const { error } = action;
    if (error.name === 'AbortError') {
      return;
    }
    state.transak.isFetchingCryptoPrice = false;
    state.transak.isFetchingFiatPrice = false;
    state.transak.selectedFiatPrice = null;
    state.error = action.error.message;
  });
};
