import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCakeSelector } from 'app/rootReducer';
import { reloadLearnEarnModal } from 'learn-earn/learnEarnSlice';
import { getCurrentModal, getLearnEarnState, getLessonData, getPreloadImages } from 'learn-earn/learnEarnSelector';
import usePreloadImage from 'learn-earn/hooks/usePreloadImage';
import LessonModalWelcome from './LessonModalWelcome';
import LessonModalLesson from './LessonModalLesson';
import LessonModalQuiz from './LessonModalQuiz';
import LessonModalResult from './LessonModalResult';
import LessonModalCompleted from './LessonModalCompleted';

interface LessonModalProps {
  closeModal: () => void,
  history: any,
}

export const ModalType = {
  WELCOME_MODAL: 'WELCOME',
  LESSON_MODAL: 'LESSON',
  QUIZ_MODAL: 'QUIZ',
  RESULT_MODAL: 'RESULT',
  COMPLETED_MODAL: 'COMPLETED',
};

const LessonModal = ({ closeModal, history }: LessonModalProps) => {
  const dispatch = useDispatch();
  const currentModal = useCakeSelector(getCurrentModal);
  const { details: userDetails } = useCakeSelector(state => state.user);
  const { currentLessonStack } = useCakeSelector(getLearnEarnState);
  const lessonData = useCakeSelector(getLessonData);
  const { t } = useTranslation();
  const headerTitle = lessonData && `${t('Lesson {{number}}', { number: lessonData.lessonIndex })}: ${t(lessonData.title)}`;

  const preloadImages = useCakeSelector(getPreloadImages);
  const { imagesPreloaded } = usePreloadImage(preloadImages); // eslint-disable-line

  useEffect(() => {
    dispatch(reloadLearnEarnModal());
  }, [dispatch, userDetails, currentLessonStack]);

  switch (currentModal) {
    default:
    case ModalType.WELCOME_MODAL:
      return <LessonModalWelcome closeModal={closeModal}/>;
    case ModalType.LESSON_MODAL:
      return <LessonModalLesson closeModal={closeModal} headerTitle={headerTitle}/>;
    case ModalType.QUIZ_MODAL:
      return <LessonModalQuiz closeModal={closeModal} headerTitle={headerTitle}/>;
    case ModalType.RESULT_MODAL:
      return <LessonModalResult closeModal={closeModal} headerTitle={headerTitle} history={history}/>;
    case ModalType.COMPLETED_MODAL:
      return <LessonModalCompleted closeModal={closeModal} headerTitle={headerTitle}/>;
  }
};

export default LessonModal;
