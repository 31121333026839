export default function getVariableType(variable) {
  const variableTypeOf = typeof variable;
  if (variableTypeOf === 'number'
    || variableTypeOf === 'string'
    || variableTypeOf === 'function') {
    return variableTypeOf;
  }
  if (variableTypeOf === 'object') {
    const constructorName = variable.constructor.name;
    if (constructorName === 'Array'
      || constructorName === 'Object') {
      return constructorName.toLowerCase();
    }
    return constructorName;
  }
  return null;
}
