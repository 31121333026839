import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import BigNumber from 'bignumber.js';
import { currencyPricingSelector } from 'currency/currencySelector';
import { isLoggedInSelector } from 'user/userSelector';
import { localizeBigNumber } from 'utils';

const profileVerificationItems = [
  { label: 'Legal name', prop: 'legalName' },
  { label: 'Date of birth', prop: 'dateOfBirth' },
  { label: 'Country of residence', prop: 'countryOfResidence' },
  { label: 'Residential address', prop: 'residentialAddress' },
  { label: 'Country of citizenship', prop: 'countryOfCitizenship' },
  { label: 'Mobile number', prop: 'mobileNumber' },
];

// remove this after myinfo confirm to return mobile and nationality
const myinfoProfileVerificationItems = [
  { label: 'Legal name', prop: 'legalName' },
  { label: 'Date of birth', prop: 'dateOfBirth' },
  { label: 'Country of residence', prop: 'countryOfResidence' },
  { label: 'Residential address', prop: 'residentialAddress' },
  { label: 'Mobile number', prop: 'mobileNumber' },
];

export const profileItemsWithStatusSelector = createSelector(
  (state: RootState) => state.user.details,
  (details) => {
    const { kycFullStatus, kycSource, phoneNumberVerified } = details;
    const verificationItem = kycSource === 'MYINFOSG' ? myinfoProfileVerificationItems : profileVerificationItems;
    return verificationItem.map(item => {
      let status = 'Pending';
      if (kycFullStatus === 'APPROVED') {
        status = 'Success';
      }
      if (item.prop && item.prop === 'mobileNumber' && phoneNumberVerified) {
        status = 'Success';
      }
      return {
        ...item,
        status,
      };
    });
  },
);

export const staggeredKycUserStatusSelector = createSelector(
  (state: RootState) => state.user.details,
  (userDetails) => {
    if (!userDetails?.staggeredKycUserStatus) {
      return {
        isUserEligibleStaggeredKyc: null,
        tier1WithdrawalLimitUsd: null,
        userWithdrawalInTheMonth: null,
      };
    }

    const { isUserEligibleStaggeredKyc, tier1WithdrawalLimitUsd, userWithdrawalInTheMonth } = userDetails?.staggeredKycUserStatus;
    return {
      isUserEligibleStaggeredKyc,
      tier1WithdrawalLimitUsd,
      userWithdrawalInTheMonth,
    };
  },
);

export const getStaggeredKycWithdrawalInfo = createSelector(
  (state: RootState) => state.user?.details,
  (userDetails) => {
    if (!userDetails?.staggeredKycUserStatus) {
      return {
        remainingLimit: '',
        isShowRedProgressBar: false,
        isBelowAlertValue: false,
        amountFullDisplay: null,
        amountLeftDisplay: null,
        tier1WithdrawalLimitUsd: null,
        userWithdrawalInTheMonth: null,
      };
    }

    const { tier1WithdrawalLimitUsd, userWithdrawalInTheMonth } = userDetails?.staggeredKycUserStatus;

    const remainingLimit = new BigNumber(tier1WithdrawalLimitUsd).minus(userWithdrawalInTheMonth).toFixed();
    const amountLeftDisplay = localizeBigNumber(remainingLimit, false, 2);
    const amountFullDisplay = localizeBigNumber(tier1WithdrawalLimitUsd, false, 0);
    const isShowRedProgressBar = new BigNumber(remainingLimit).dividedBy(tier1WithdrawalLimitUsd).isLessThanOrEqualTo(0.15);
    const isBelowAlertValue = new BigNumber(tier1WithdrawalLimitUsd).minus(userWithdrawalInTheMonth).isLessThanOrEqualTo(10);

    const isDisplayWithdrawalCurrentLimits = userDetails?.kycTier === 'TIER1';

    return {
      remainingLimit,
      isShowRedProgressBar,
      isBelowAlertValue,
      amountFullDisplay,
      amountLeftDisplay,
      tier1WithdrawalLimitUsd,
      isDisplayWithdrawalCurrentLimits,
    };
  },
);

export const getIsExceedWithdrawalLimit = (coinId: string, amount: string, withdrawalFeeInCoinAmount: string) => createSelector(
  (state: RootState) => state.user.details,
  currencyPricingSelector,
  getStaggeredKycWithdrawalInfo,
  (userDetails, pricing, staggeredKycWithdrawalInfo) => {
    if (!staggeredKycWithdrawalInfo || !pricing || !userDetails) {
      return false;
    }

    if (userDetails.kycTier === 'TIER2') return false;

    const { remainingLimit } = staggeredKycWithdrawalInfo;

    const coin = pricing.find(item => item.CoinId === coinId);
    if (!coin) {
      return false;
    }

    const coinPrice = coin.priceUSD.avg;
    const coinAmountWithFeeBn = new BigNumber(amount).plus(withdrawalFeeInCoinAmount);
    const coinTotalUsdBn = new BigNumber(coinAmountWithFeeBn).multipliedBy(coinPrice);
    const isExceedWithdrawalLimit = coinTotalUsdBn.isGreaterThan(remainingLimit);
    return isExceedWithdrawalLimit;
  },
);

export const userSumsubKycStatusSelector = createSelector(
  (state: RootState) => state.user.details,
  (userDetails) => {
    if (!userDetails?.sumsubKyc) {
      return {
        sumsubKycStatus: null,
        sumsubLevel: null,
      };
    }

    const { sumsubKycStatus, sumsubLevel, isOpenToReKyc, poiExpiryDate } = userDetails?.sumsubKyc;
    return {
      sumsubKycStatus,
      sumsubLevel,
      isOpenToReKyc,
      poiExpiryDate,
    };
  },
);

export const isShowKycBanner = createSelector(
  isLoggedInSelector,
  (state: RootState) => state.user.details,
  (state: RootState) => state.accountVerification.isShowKycBanner,
  (isLoggedIn, userDetail, isShowKycStatusBanner) => {
    if (!isLoggedIn || !userDetail || !isShowKycStatusBanner) {
      return false;
    }
    const { status } = userDetail?.kycMigration || {};
    if (status && status === 'PENDING_MIGRATION') {
      return true;
    }
    const { isOpenToReKyc } = userDetail?.sumsubKyc;
    if ((userDetail?.kycTier === 'TIER2' && !isOpenToReKyc)) {
      return false;
    }
    const { emailVerified, country } = userDetail;
    return isLoggedIn && emailVerified && !!country;
  },
);

const getDocumentStatus = (status) => {
  if (status === 'REJECTED') {
    return 'Rejected';
  }

  if (status === 'APPROVED') {
    return 'Success';
  }

  return 'Pending';
};

export const documentItemsWithStatusSelector = createSelector(
  (state: RootState) => state.user.details,
  state => state.accountVerification.inputs,
  (details, inputs) => {
    const { kycFullStatus, kycSource } = details;
    const result = [{ label: 'National ID or passport' }, { label: 'Selfie' }, { label: 'Proof of address*' }];
    const myinfoResult = [{ label: 'National ID or passport' }];
    if (kycSource === 'MYINFOSG') {
      return myinfoResult.map(item => ({
        ...item,
        status: kycFullStatus === 'APPROVED' ? 'Success' : 'Pending',
      }));
    }
    if (['PENDING', 'SCREENING', 'APPROVED'].includes(kycFullStatus)) {
      return result.map(item => ({ ...item, status: 'Success' }));
    }

    const { identityDocument, proofOfAddress, selfie } = inputs;

    return [
      { label: result[0].label, status: getDocumentStatus(identityDocument.status) },
      { label: result[1].label, status: getDocumentStatus(selfie.status) },
      { label: result[2].label, status: getDocumentStatus(proofOfAddress.status) },
    ];
  },
);

export const getIsSgResidentSelector = createSelector(
  (state: RootState) => state.user.details,
  (details) => {
    const { country } = details;
    return country === 'SG';
  },
);

export const getIsPhoneNumberVerified = createSelector(
  (state: RootState) => state.user.details,
  (details) => (!!details?.phoneNumberVerified),
);

export const getUserKycTier = createSelector(
  (state: RootState) => state.user.details,
  (details) => (details?.kycTier ?? 'UNVERIFIED'),
);

export const getUserOnboarding = createSelector(
  (state: RootState) => state.user.details,
  (details) => details?.onBoarding ?? [],
);
