import { createSlice } from '@reduxjs/toolkit';

export const COUNTRIES_WITHOUT_ZIPCODE = ['HK', 'AE'];
export const NATIONALITIES_WITH_FULL_CHINESE_NAME = ['HK', 'TW', 'CN', 'MO'];

const initialState = {
  codeSendId: null,
  codeCallId: null,
  inputs: {
    country: '',
    zipCode: '',
    mobileNumber: '',
    phoneCountryCode: '',
  },
};

const userIdentifiableInformation = createSlice({
  name: 'userIdentifiableInformation',
  initialState,
  reducers: {
    setCodeSendId: (state, action) => {
      state.codeSendId = action.payload;
    },
    setCodeCallId: (state, action) => {
      state.codeCallId = action.payload;
    },
    setCountry: (state, action) => {
      state.inputs.country = action.payload;
      if (COUNTRIES_WITHOUT_ZIPCODE.includes(action.payload)) {
        state.inputs.zipCode = 'N/A';
      } else {
        state.inputs.zipCode = '';
      }
    },
    setZipCode: (state, action) => {
      state.inputs.zipCode = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.inputs.mobileNumber = action.payload;
    },
    setPhoneCountryCode: (state, action) => {
      state.inputs.phoneCountryCode = action.payload;
    },
  },
});

export const {
  setCodeSendId,
  setCodeCallId,
  setCountry,
  setZipCode,
  setMobileNumber,
  setPhoneCountryCode,
} = userIdentifiableInformation.actions;

export default userIdentifiableInformation.reducer;
