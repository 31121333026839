import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Trans } from 'react-i18next';
import { OnRampProviderType } from 'types/onramp/onramp-provider';
import { providersSelector, transakFiatSelector } from './fiatBuyModalSelector';
import styles from './FiatOnrampProvider.styles';
import { setOnRampProvider } from './fiatBuyModalSlice';
import { TransakFiatSelector } from '../../../types/onramp/state';
import CardSelection from '../../../common/components/CardSelection/CardSelection';

const useStyles = createUseStyles(styles);

export default function FiatOnrampProvider({ selectedCoin }) {
  const classes = useStyles();
  const providers = useSelector(providersSelector);
  const { isShowTransak }: TransakFiatSelector = useSelector(transakFiatSelector(selectedCoin));

  const dispatch = useDispatch();

  const setProvider = (providerType: string) => {
    dispatch(setOnRampProvider(providerType));
  };

  useEffect(() => {
    if (isShowTransak) {
      dispatch(setOnRampProvider(OnRampProviderType.TRANSAK));
    }
  }, [dispatch, isShowTransak]);

  if (!isShowTransak) {
    return null;
  }

  return (
    <div className={classes.container}>
      <p className={classes.paragraphTitle}>
        <Trans>Choose a payment service</Trans>
      </p>
      <CardSelection
        items={providers}
        onChange={setProvider}
      ></CardSelection>
      <p className={classes.paymentServiceDisclaimer}>
        <Trans>
          Rates and fees may differ between payment services for the various
          payment modes.
        </Trans>
      </p>
    </div>
  );
}
