import React from 'react';
import ReactSvg from 'react-svg';
import reddit from '../../../assets/svg/social-reddit.svg';

export default function RedditIcon() {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/Bakeio/" >
      <ReactSvg
        src={reddit}
        className="reddit-icon"
      />
    </a>
  );
}
