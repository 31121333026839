import { getMinMaxForPaymentMethod } from './getMinMaxForPaymentMethod';

export const getTransakMinMaxPaymentMethod = (
  paymentMethod,
  fiatCurrency: string,
  fiatAmount,
  rates: { [key: string]: number; },
) => {
  if (!paymentMethod) {
    return {
      isFiatAmountValid: true,
    };
  }

  if (fiatAmount === '') {
    return {
      isFiatAmountValid: true,
      paymentMethodMinAmount: paymentMethod.minAmount,
      paymentMethodMaxAmount: paymentMethod.maxAmount,
    };
  }

  return getMinMaxForPaymentMethod(
    paymentMethod.minAmount,
    paymentMethod.maxAmount,
    fiatAmount,
  );
};
