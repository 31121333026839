import React from 'react';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

export default function displayBuySharesMessage(shareName) {
  window.cakepool.notify(() => <div className={'toastr-message'}><Trans>You don't hold {{ shareName }} staking shares. Perhaps you
      should <a href={'/wallets'}
      className={'toastr-link'}><b>buy some?</b></a></Trans></div>, {
    position: toast.POSITION.TOP_CENTER,
    closeButton: true,
    hideProgressBar: true,
    isDisableTimer: true,
    actionBtn: <a href={'/wallets'} style={{
      minWidth: 96, marginRight: 10, marginLeft: 20,
    }} className="btn btn-small"><Trans>Buy</Trans></a>,
  });
}
