import React from 'react';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import { setCodeCallId } from '../identifiableInformationSlice';
import normalizePhoneNumber from '../../../../utils/normalizePhoneNumber';
import MobilePhoneStyles from './MobilePhone.styles';
import Button from '../../../../common/components/Button/Button';

const useStyles = createUseStyles(MobilePhoneStyles);

const MobilePhoneCall = ({ gotoCallingForVerificationScreen, gotoVerifyOTPScreen }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();

  const { mobileNumber, phoneCountryCode } = useCakeSelector(state => state.userIdentifiableInformation.inputs);

  const onCancel = () => {
    if (isMountedRef.current) {
      dispatch(setCodeCallId(null));
      gotoVerifyOTPScreen();
    }
  };

  const onCallMeNow = () => {
    if (isMountedRef.current) {
      gotoCallingForVerificationScreen();
    }
  };

  return (
    <article className="floating">
      <div className={classes.titleWrapper}>
        <h1 className={classes.title}>
          <Trans>Verify mobile number via phone</Trans>
        </h1>
      </div>
      <div className='text-left'>
        <Trans>You will receive a phone call to your mobile number</Trans>
        <div style={{ display: 'inline-block' }}>{normalizePhoneNumber(phoneCountryCode, mobileNumber)}.</div>
        <Trans> The 6-digit confirmation code will be read to you.</Trans>
        <div className='mt-5'>
          <Trans>After that, simply hang up once you're done listening.</Trans>
        </div>
      </div>
      <div className='mt-5 text-center'>
        <Button primary onClick={onCallMeNow} style={{ width: '100%' }}>
          <Trans>Call me now</Trans>
        </Button>
        <Button primary clear onClick={onCancel} className='mt-5'>
          <Trans>Go Back</Trans>
        </Button>
      </div>
    </article>
  );
};

export default MobilePhoneCall;
