import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from 'sdk';
import { ThunkApiConfig } from 'app/rootReducer';
import { EthLuckyDrawCampaignDetailsForUser, EthLuckyDrawCalculateTicket } from '@cakedefi/cake-sdk/schema/campaign';

interface IEthLuckyDrawState extends EthLuckyDrawCampaignDetailsForUser, EthLuckyDrawCalculateTicket {
  state: 'UNLOADED' | 'LOADING' | 'LOADED' | 'ERROR',
  error: any,
}

const initialState: IEthLuckyDrawState = {
  state: 'UNLOADED',
  activePromotion: '',
  promotionVisibleOn: '',
  promotionRetiredOn: '',
  isCampaignStillActive: false,
  balance: {
    lending: '0',
    idle: '0',
  },
  totalStakedWithInPromo: '0',
  tickets: '0',
  loss: {
    lending: '0',
    idle: '0',
  },
  error: null,
};

export const fetchEthLuckyDraw = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/ethLuckyDraw',
  async (_, thunkAPI) => {
    const result = await sdk.CampaignApi.getEthLuckyDrawDetails();
    return result;
  },
);

export const fetchEthLuckyDrawAuthenticated = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/ethLuckyDraw/authenticated',
  async (_, thunkAPI) => {
    const result = await sdk.CampaignApi.getEthLuckyDrawDetailsForUser();
    return result;
  },
);

export const fetchEthLuckyDrawCalculateTickets = createAsyncThunk<EthLuckyDrawCalculateTicket, number, ThunkApiConfig>(
  'campaigns/ethLuckyDraw/calculateTickets',
  async (ethAmount, thunkAPI) => {
    const result = await sdk.CampaignApi.calculateEthLuckyDrawTickets(ethAmount.toString());
    return result;
  },
);

const ethLuckyDrawCampaignSlice = createSlice({
  name: 'ETHLuckyDrawCampaign',
  initialState,
  reducers: {
    clearEthLuckyDrawCampaignData: () => (initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEthLuckyDraw.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchEthLuckyDrawAuthenticated.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchEthLuckyDrawCalculateTickets.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchEthLuckyDraw.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      state: 'LOADED',
      error: null,
    }));
    builder.addCase(fetchEthLuckyDrawAuthenticated.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      state: 'LOADED',
      error: null,
    }));
    builder.addCase(fetchEthLuckyDrawCalculateTickets.fulfilled, (state, action) => {
      state.tickets = action.payload.tickets;
      state.state = 'LOADED';
    });
    builder.addCase(fetchEthLuckyDraw.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(fetchEthLuckyDrawAuthenticated.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export const { clearEthLuckyDrawCampaignData } = ethLuckyDrawCampaignSlice.actions;

export default ethLuckyDrawCampaignSlice.reducer;
