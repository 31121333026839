import React from 'react';
import { Trans } from 'react-i18next';
import { CheckBox } from '../../../../views/components';

export default function DisclaimerAgree({
  userHasRead,
  setUserHasRead,
  tncAgreement,
}) {
  return (
    <CheckBox
      isChecked={userHasRead}
      onChange={(result) => setUserHasRead(result)}
    >
      <Trans>I agree to this Disclaimer</Trans>{tncAgreement}.
    </CheckBox>
  );
}
