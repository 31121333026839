import { BigNumberConstructable } from '@cakedefi/cake-sdk';
import BigNumber from 'bignumber.js';

export const PENDING_STATUS = 'PENDING';
export const LOCKED_STATUS = 'LOCKED';
export const ALL_COINS_VALUE = 'ALL_COINS';
export const allowedNativeStatus = ['CONFIRMED', 'LOCKED'];
export const allowedNativeStatusCsETH = ['CONFIRMED', 'LOCKED', 'PENDING'];
export const allowedShareStatus = ['PENDING', 'CONFIRMED'];

export enum ArrowIcon {
  ARROWUP = 'arrowUp',
  ARROWDOWN = 'arrowDown',
}

export function getArrowIcon(amount: BigNumberConstructable) {
  return new BigNumber(amount).isGreaterThan(0) ? ArrowIcon.ARROWDOWN : ArrowIcon.ARROWUP;
}

export function shouldNotRenderRow(status, transaction) {
  const isCsETH = transaction?.native?.coin?.id === 'csETH';
  const isAllowedNativeStatus = isCsETH ? allowedNativeStatusCsETH.includes(status) : allowedNativeStatus.includes(status);
  return !isAllowedNativeStatus && !isPendingReward(transaction);
}

export function isPendingReward(transaction) {
  return transaction.type === 'NODE_REWARD' && transaction.native.status === PENDING_STATUS;
}

export function isPendingYieldVaultEnterTx(transaction) {
  return transaction.type === 'ENTER_YIELD_VAULT' && transaction.native.status === PENDING_STATUS;
}
