import React, { useEffect, useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import debounce from 'lodash.debounce';
import zxcvbn from 'zxcvbn';
import appConfig from 'config/app';

const DEBOUNCE_DELAY = 150;

const getBackgroundColorWithScore = ({ color, score, barScore }) => (score >= barScore ? `${color}FF` : '#E0DFE2');

const useStyles = createUseStyles<string, { score: number, color: string }>({
  container: {
    display: 'flex',
    gap: '5px',
    flexDirection: 'column',
  },
  meter: {
    display: 'flex',
    gap: '8px',
    marginTop: '5px',
    flexGrow: 1,
    flexBasis: 0,
  },
  passwordStrengthContainer: {
    flexGrow: 1,
    flexBasis: 0,
  },
  passwordStrengthText: {
    fontSize: '1.3rem',
    fontWeight: '500',
    color: ({ color }) => (color),
  },
  baseBar: {
    height: '5px',
    borderRadius: '10px',
  },
  bar1: {
    composes: '$baseBar',
    flexGrow: 2,
    backgroundColor: ({ color, score }) => getBackgroundColorWithScore({ color, score, barScore: 1 }),
  },
  bar2: {
    composes: '$baseBar',
    flexGrow: 4,
    backgroundColor: ({ color, score }) => getBackgroundColorWithScore({ color, score, barScore: 2 }),
  },
  bar3: {
    composes: '$baseBar',
    flexGrow: 6,
    backgroundColor: ({ color, score }) => getBackgroundColorWithScore({ color, score, barScore: 3 }),
  },
  bar4: {
    composes: '$baseBar',
    flexGrow: 8,
    backgroundColor: ({ color, score }) => getBackgroundColorWithScore({ color, score, barScore: 4 }),
  },
});

const warningText = 'Password must be longer than 12 characters and cannot contain common words and patterns. Try using numbers, symbols and mixed case to make it more unique.';

const scoreMap = {
  0: { color: Variables.gray600, strength: `No way! It's too weak. ${warningText}` },
  1: { color: '#FF0100', strength: `Yuck! Very weak. ${warningText}` },
  2: { color: '#FF9529', strength: `Barely edible. ${warningText}` },
  3: { color: '#01B31C', strength: "I like this. It's strong!" },
  4: { color: '#01B31C', strength: "Yummy, it's very strong!" },
};

interface PasswordMeterProps {
  password: string;
}

function PasswordMeter({ password }: PasswordMeterProps) {
  const [color, setColor] = useState('');
  const [score, setScore] = useState(0);
  const [strength, setStrength] = useState('');
  const classes = useStyles({ score, color });

  const isAtLeastMinLength = password.length >= appConfig.MIN_PASSWORD_LENGTH;
  const isAtMostMaxLength = password.length <= appConfig.MAX_PASSWORD_LENGTH;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPasswordChange = useCallback(debounce((async () => {
    let generatedScore = zxcvbn(password).score;

    if (!isAtLeastMinLength || !isAtMostMaxLength) {
      generatedScore = 1;
    }

    setScore(generatedScore);
    setColor(scoreMap[generatedScore].color);
    setStrength(scoreMap[generatedScore].strength);
  }), DEBOUNCE_DELAY), [password]);

  useEffect(() => {
    onPasswordChange();
  }, [onPasswordChange]);

  const passwordInvalidMessage = () => {
    if (!isAtLeastMinLength) {
      return <Trans>Password length must be at least 12 characters.</Trans>;
    }
    if (!isAtMostMaxLength) {
      return <Trans>Too big of a serving! Password must not be longer than 128 characters.</Trans>;
    }
    return <Trans i18nKey={strength}>{strength}</Trans>;
  };

  return (
    <div className={classes.container}>
      <div className={classes.meter}>
        <div className={classes.bar1}/>
        <div className={classes.bar2}/>
        <div className={classes.bar3}/>
        <div className={classes.bar4}/>
      </div>
      <div className={classes.passwordStrengthContainer}>
        <p className={classes.passwordStrengthText}>
          { passwordInvalidMessage() }
        </p>
      </div>
    </div>
  );
}

export default PasswordMeter;
