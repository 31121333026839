import Variables from '../../../Variables.styles';

export default {
  tableWrapper: {
    '& > div:not(:last-child)': {
      borderBottom: Variables.primaryBorder,
    },
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${Variables.small} 0`,
  },
  rowLabel: {
    fontSize: '1.6rem',
    fontWeight: 500,
    flex: '0 0 50%',
  },
  rowValue: {
    fontSize: '1.6rem',
    textAlign: 'right',
  },
};
