import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { HeaderLink } from 'types/header/header-link';
import Variables from 'Variables.styles';
import EthIcon from 'assets/svg/ethLuckyDraw/eth-icon.svg';
import { useCakeSelector } from '../../../app/rootReducer';
import {
  getIsEthLuckyDrawActive,
  getIsEthLuckyDrawDataLoadStatus,
} from './ethLuckyDrawSelector';
import { fetchEthLuckyDraw, fetchEthLuckyDrawAuthenticated } from '../ethLuckyDrawSlice';

const useStyles = createUseStyles({
  outerWrapperDesktop: {
    display: 'inline-block',
    width: 139,
    height: 31,
    paddingBottom: 22,
    paddingLeft: Variables.medium,
  },
  wrapperDesktop: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
  },
  wrapperMobile: {
    display: 'inline-block',
    height: 58,
    cursor: 'pointer',
    padding: '6px 16px',
  },
  container: {
    background: Variables.hyperBerry6,
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Variables.small,
    width: 139,
    height: 48,
  },
  linkWrapper: {
    whiteSpace: 'nowrap',
    lineHeight: Variables.large,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ethIcon: {
    marginRight: 4,
  },
  linkText: {
    color: Variables.white,
  },
});

const EthLuckyDrawHeaderLink = ({ index, isMobile = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details: userDetails } = useSelector((state: any) => state.user);

  const headerLink = new HeaderLink(
    'Win 1 ETH',
    '/lucky-eth',
  );
  const { text, link } = headerLink;
  const ethLuckyDrawDataLoadStatus = useCakeSelector(getIsEthLuckyDrawDataLoadStatus);
  const canShowHeaderLink = useCakeSelector(getIsEthLuckyDrawActive);

  useEffect(() => {
    if (ethLuckyDrawDataLoadStatus === 'UNLOADED') {
      if (userDetails) {
        dispatch(fetchEthLuckyDrawAuthenticated());
      } else {
        dispatch(fetchEthLuckyDraw());
      }
    }
  }, [dispatch, ethLuckyDrawDataLoadStatus, userDetails]);

  if (!canShowHeaderLink) {
    return null;
  }

  return (
    <div className={isMobile ? null : classes.outerWrapperDesktop}>
      <div
        className={isMobile ? classes.wrapperMobile : classes.wrapperDesktop}
        key={`header-link-${index}`}
      >
        <div className={classes.container}>
          <Link to={link} className={classes.linkWrapper}>
            <img src={EthIcon} className={classes.ethIcon} alt="Eth Icon" />
            <span className={classes.linkText}><Trans>{text}</Trans></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EthLuckyDrawHeaderLink;
