import { createSelector } from '@reduxjs/toolkit';

import { userPreferredCurrencySelector } from 'user/userSelector';
import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import convertUSDToPreferredCurrency from '@cakedefi/cake-sdk/util/convertUSDToPreferredCurrency';
import BigNumber from 'bignumber.js';
import { getValueInPreferredCurrency } from '../utils';
import { RootState } from '../app/rootReducer';

const getCurrencyState = (state: RootState) => state.currency;
export const currencyPricingSelector = createSelector(
  getCurrencyState,
  (currency) => currency.coins.pricing,
);

export const currencyFiatRatesSelector = createSelector(
  getCurrencyState,
  currency => currency.fiat.rates,
);

/**
 * A selector that returns a function to preferred currency value.
 * Keep logic of fetching coin prices and fiat rates and using them to calculate preferred currency value.
 * So that we don't need to write the logic of fetching data in different places just to calculate the value
 */

export interface GetValueInPreferredCurrencyFunc {
  (coinAmount: any, coinId: string, currencyCode?: string): string;
}

export const getValueInPreferredCurrencyFuncSelector = createSelector(
  (state: RootState) => state.currency,
  userPreferredCurrencySelector,
  (currency, preferredCurrency): GetValueInPreferredCurrencyFunc => {
    const hasFiatRates = currency.fiat && currency.fiat.rates;
    const hasCoinPrices = currency.coins && currency.coins.pricing;

    if (!hasFiatRates || !hasCoinPrices) {
      return (coinAmount) => coinAmount; // return coin amount instead of crashing
    }

    const { pricing } = currency.coins;
    const { rates } = currency.fiat;

    return (coinAmount, coinId: string, currencyCode: string) => {
      const coinPrice: CoinPrice = pricing.find((coin: CoinPrice) => coin.CoinId === coinId);
      const btcPrice: CoinPrice = pricing.find((coin: CoinPrice) => coin.CoinId === 'BTC');

      if (!coinPrice || !btcPrice) {
        return coinAmount;
      }

      return getValueInPreferredCurrency(
        coinAmount,
        coinPrice.priceUSD.avg,
        btcPrice.priceUSD.avg,
        currencyCode ?? preferredCurrency,
        rates,
      );
    };
  },
);

export const getPricingForCoin = (coinId: string) => createSelector(
  currencyPricingSelector,
  (pricing) => {
    if (!pricing) {
      return null;
    }
    const price = pricing.find(({ CoinId }) => CoinId === coinId);
    return price?.priceUSD?.avg;
  },
);

export interface ConvertUSDToPreferredCurrencyFunc {
  (amountInUSD: string | number | BigNumber): string;
}

export const convertUSDToPreferredCurrencyFuncSelector = createSelector(
  getPricingForCoin('BTC'),
  userPreferredCurrencySelector,
  currencyFiatRatesSelector,
  (btcPrice, preferredCurrency, rates): ConvertUSDToPreferredCurrencyFunc => (amountInUSD: string | number | BigNumber) => convertUSDToPreferredCurrency(
    amountInUSD,
    btcPrice,
    preferredCurrency,
    rates,
  ),
);
