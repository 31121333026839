import ReactGA from 'react-ga';
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import appConfig from 'config/app';

export function initDatadog() {
  if (appConfig.DD_ENABLE && appConfig.DD_ENABLE === 'true') {
    datadogRum.init({
      applicationId: appConfig.DD_APPLICATION_ID,
      clientToken: appConfig.DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: appConfig.DD_SERVICE,
      env: appConfig.DD_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: appConfig.DD_VISIBILITY_LEVEL as DefaultPrivacyLevel,
      allowedTracingOrigins: [
        process.env.REACT_APP_API_ENDPOINT,
        (origin) => origin === process.env.REACT_APP_API_ENDPOINT,
      ],
    });
    datadogRum.startSessionReplayRecording();

    // https://docs.datadoghq.com/logs/log_collection/javascript/
    datadogLogs.init({
      clientToken: appConfig.DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: appConfig.DD_SERVICE,
      env: appConfig.DD_ENV,
      sessionSampleRate: 100,
    });
  }
}

export function initAnalytics() {
  ReactGA.initialize('UA-145606297-1');
  window.addEventListener(
    'hashchange',
    () => {
      ReactGA.set({ page: window.location.hash });
      ReactGA.pageview(window.location.hash);
    },
    false,
  );
}
