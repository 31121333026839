import { getMinWidthLg } from 'Variables.styles';

export default {
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f4f3f7',
    padding: '32px',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '90%',
    margin: '50px auto',
    minWidth: '0px',
    padding: '10px',
  },
  image: {
    marginBottom: '20px',
  },
  [getMinWidthLg]: {
    content: {
      width: '80%',
      minWidth: '900px',
    },
  },
};
