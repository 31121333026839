import Variables from '../../../Variables.styles';

export default {
  loader: ({ size }) => ({
    color: Variables.electricBerry,
    width: size,
    height: size,
  }),
  spinnerGroup: {
    animation: '$rotate 1s linear infinite',
    width: '100%',
    height: '100%',
    transformOrigin: '50%',
  },
  spinner: {
    stroke: 'currentColor',
    strokeLinecap: 'round',
    animation: '$dash 1.8s linear infinite',
    transformOrigin: '50%',
  },
  track: {
    stroke: '#ccc',
  },
  slice: {
    animation: '$pendulum 1.5s linear infinite',

    // animationName: '$pendulum',
    // animationDuration: '1.5s',
    // animationIterationCount: 'infinite',
    transformOrigin: '50%',
    fill: 'currentColor',
  },
  '@keyframes pendulum': {
    '0%': {
      transform: 'rotate(-50deg)',
      animationTimingFunction: 'ease-in-out',
    },
    '50%': {
      transform: 'rotate(-130deg)',
      animationTimingFunction: 'ease-in-out',
    },
    '100%': {
      transform: 'rotate(-50deg)',
    },
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dash': {
    '0%': {
      strokeDasharray: '0, 120',
      strokeDashoffset: 0,
    },
    '50%': {
      strokeDasharray: '80, 120',
      strokeDashoffset: '-40',
    },
    '100%': {
      strokeDasharray: '40, 120',
      strokeDashoffset: '-60',
    },
  },
};
