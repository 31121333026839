import React from 'react';
import { ModalHeader } from 'modals/components';
import { useDispatch } from 'react-redux';
import { useCakeSelector } from 'app/rootReducer';
import { RegisterConsentStage, setConsentStage } from 'views/Pages/Authentication/authSlice';
import UserAllConsentsPage from './UserAllConsentsPage';
import AgeRestrictionConsent from './AgeRestrictionConsent';
import PersonalDataConsent from './PersonalDataConsent';
import MarketingConsent from './MarketingConsent';

const ConsentModalContent = ({ onClickContinueEvent }) => {
  const { registerConsentStage } = useCakeSelector(state => state.auth);
  const dispatch = useDispatch();
  const goto = gotoStage => dispatch(setConsentStage(gotoStage));

  switch (registerConsentStage) {
    case RegisterConsentStage.CONSENTS_PAGE:
      return <UserAllConsentsPage goto={goto} onClickContinueEvent={onClickContinueEvent}/>;
    case RegisterConsentStage.AGE_RESTRICTION_CONSENT:
      return <AgeRestrictionConsent goto={goto} />;
    case RegisterConsentStage.PERSONAL_DATA_CONSENT:
      return <PersonalDataConsent goto={goto} />;
    case RegisterConsentStage.MARKETING_CONSENT:
      return <MarketingConsent goto={goto} />;
    default:
      return null;
  }
};

const getConsentModalTitle = (stage) => {
  switch (stage) {
    case RegisterConsentStage.CONSENTS_PAGE:
      return 'Before we continue';
    case RegisterConsentStage.AGE_RESTRICTION_CONSENT:
      return 'Age Restriction';
    case RegisterConsentStage.PERSONAL_DATA_CONSENT:
      return 'Collection of personal data';
    case RegisterConsentStage.MARKETING_CONSENT:
      return 'Consent for marketing communications';
    default:
      return null;
  }
};

const ConsentModal = ({ closeModal, onClickContinueEvent }) => {
  const { registerConsentStage } = useCakeSelector(state => state.auth);
  const title = getConsentModalTitle(registerConsentStage);
  return (
    <>
      <ModalHeader title={title} onClose={closeModal}/>
      <ConsentModalContent onClickContinueEvent={onClickContinueEvent} />
    </>
  );
};

export default ConsentModal;
