import React,
{ Component } from 'react';
import {
  Alert,
} from '../../components';

export default class GlobalAlert extends Component {
  constructor(props) {
    super(props);
    const { cakepool } = window;
    cakepool.showAlert = (type, content, duration) => this.showAlert(type, content, duration); // duration is optional
    cakepool.hideAlert = () => this.hideAlert();
    cakepool.isAlertVisible = () => this.isAlertVisible();
  }

  timer = null;

  alert;

  resetTimer;

  showAlert(type, content, duration = 4000) {
    this.alert.show(type, content);
    const self = this;// eslint-disable-line
    clearTimeout(this.timer);
    clearTimeout(this.resetTimer);
    this.timer = setTimeout(() => {
      if (self.alert) self.alert.hide();
    }, duration);
    this.resetTimer = setTimeout(() => self?.alert?.resetContent(), duration + 500);
  }

  hideAlert() {
    this.alert.hide();
  }

  isAlertVisible() {
    return this.alert.isVisible();
  }

  render() {
    return (
      <div className='flex-center'>
        <div className="main-layout__global-alert ">
          <Alert ref={(component) => { this.alert = component; }}></Alert>
        </div>
      </div>
    );
  }
}
