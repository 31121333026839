import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import { CoinMap } from '../../../types/coin/coin-map';
import { CoinOption } from '../../../types/coin/coin-option';
import { FiatBuyModalInputsState } from '../../../types/onramp/inputs-state';
import { OnRampProviderType } from '../../../types/onramp/onramp-provider';
import { isPositiveAndNotZeroNumber } from '../../../utils';
import {
  getBanxaSourceCurrencyFallback,
  FIAT_TYPE,
  CRYPTO_TYPE,
} from './fiatBuyModalService';
import {
  clearFiatSwappable,
  setBanxaSelectedPaymentMethod,
  setCryptoAmountWithFiatSwappableAmount,
  convertFiatSwappableToCryptoAmount,
  convertCryptoToFiatSwappableAmount,
  setSwappableCoin,
  setTransakDefaultSource,
  setTransakSelectedPaymentMethod,
  updateInputField,
} from './fiatBuyModalSlice';
import {
  fetchBanxaFiatPrices,
  fetchBanxaFiats,
  fetchBanxaPaymentMethods,
  fetchFiatSwappableWalletAddress,
  fetchTransakFiats,
  fetchTransakPrice,
  fetchWalletAddress,
} from './fiatBuyModalSliceThunk';

export async function initialFetch(
  dispatch,
  type: OnRampProviderType,
  hasLoadedBanxaFiats: boolean,
  hasLoadedTransakFiats: boolean,
  selectedCoin: string,
  preferredCurrency: string,
  inputs: FiatBuyModalInputsState,
  fiatSwappableCoinId: string,
  cancelPriceRequest: any,
  shouldSupportTransak = true,
) {
  const selectedCrypto = inputs.target || selectedCoin;
  const selectedFiat = inputs.source || preferredCurrency;
  dispatch(
    updateInputField({ fieldName: CRYPTO_TYPE, fieldValue: selectedCrypto }),
  );

  if (!hasLoadedBanxaFiats) {
    dispatch(fetchBanxaFiats());
  }

  if (!hasLoadedTransakFiats && shouldSupportTransak) {
    dispatch(fetchTransakFiats());
  }

  switch (type) {
    case OnRampProviderType.BANXA:
    default: {
      dispatch(
        updateInputField({
          fieldName: FIAT_TYPE,
          fieldValue: getBanxaSourceCurrencyFallback(selectedFiat),
        }),
      );

      await dispatch(fetchBanxaPaymentMethods());
      const hasFiatAmount = !!inputs.sourceAmount;
      dispatch(fetchBanxaFiatPrices(fiatSwappableCoinId && hasFiatAmount ? FIAT_TYPE : CRYPTO_TYPE));
      break;
    }

    case OnRampProviderType.TRANSAK: {
      dispatch(clearFiatSwappable());
      dispatch(setTransakDefaultSource(selectedFiat));
      cancelPriceRequest(dispatch(fetchTransakPrice(CRYPTO_TYPE)));
    }
  }
}

export async function changeFiatCode(
  dispatch,
  providerType: OnRampProviderType,
  newFiatCode: string,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
  cancelPriceRequest,
) {
  await dispatch(
    updateInputField({ fieldName: FIAT_TYPE, fieldValue: newFiatCode }),
  );
  if (providerType === OnRampProviderType.BANXA) {
    await dispatch(fetchBanxaPaymentMethods());
  } else if (providerType === OnRampProviderType.TRANSAK) {
    dispatch(setTransakDefaultSource(newFiatCode));
  }
  await fetchPrice(dispatch, providerType, FIAT_TYPE, cancelPriceRequest);
  dispatch(convertCryptoToFiatSwappableAmount({ pricing, withoutMarkup }));
}

export async function changeFiatAmount(
  dispatch,
  providerType: OnRampProviderType,
  fiatValue: number,
  isEnteredFiatWithinRange: boolean,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
  cancelPriceRequest,
) {
  await dispatch(
    updateInputField({ fieldName: 'sourceAmount', fieldValue: fiatValue }),
  );
  if (isPositiveAndNotZeroNumber(fiatValue) && isEnteredFiatWithinRange) {
    await fetchPrice(dispatch, providerType, FIAT_TYPE, cancelPriceRequest);
    dispatch(convertCryptoToFiatSwappableAmount({ pricing, withoutMarkup }));
  }
}

export async function changeCryptoCode(
  dispatch,
  providerType: OnRampProviderType,
  coin: CoinOption,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
  coins: CoinMap,
  cryptoAmount: string,
  cancelPriceRequest,
) {
  if (!coin.fiatTargetSwapPossible) {
    const { value: newCryptoCode } = coin;
    await dispatch(setCryptoAmountWithFiatSwappableAmount());
    dispatch(clearFiatSwappable());
    await dispatch(
      updateInputField({ fieldName: CRYPTO_TYPE, fieldValue: newCryptoCode }),
    );
    dispatch(fetchWalletAddress(newCryptoCode));
  } else {
    await dispatch(setSwappableCoin(coin));
    // set DFI amount to be exact as crypto amount when crypto dropdown change before asking for price from Banxa
    await dispatch(convertFiatSwappableToCryptoAmount({ amount: cryptoAmount, pricing, coins, withoutMarkup }));
    dispatch(fetchFiatSwappableWalletAddress(coin.value));
  }

  if (providerType === OnRampProviderType.BANXA) {
    await dispatch(fetchBanxaPaymentMethods());
  }
  fetchPrice(dispatch, providerType, CRYPTO_TYPE, cancelPriceRequest);
}

export async function changeCryptoAmount(
  dispatch,
  providerType: OnRampProviderType,
  cryptoValue: string,
  pricing: CoinPrice[],
  withoutMarkup: boolean,
  coins: CoinMap,
  cancelPriceRequest,
) {
  await dispatch(convertFiatSwappableToCryptoAmount({
    amount: cryptoValue,
    pricing,
    coins,
    withoutMarkup,
  }));

  if (isPositiveAndNotZeroNumber(cryptoValue)) {
    fetchPrice(dispatch, providerType, CRYPTO_TYPE, cancelPriceRequest);
  }
}

export async function fetchPrice(
  dispatch,
  providerType: OnRampProviderType,
  fetchingType: string,
  cancelPriceRequest,
) {
  switch (providerType) {
    case OnRampProviderType.BANXA:
    default: {
      await dispatch(fetchBanxaFiatPrices(fetchingType));
      break;
    }

    case OnRampProviderType.TRANSAK: {
      const promise = dispatch(fetchTransakPrice(fetchingType));
      cancelPriceRequest(promise);
    }
  }
}

export async function setPaymentMethod(
  dispatch,
  providerType: OnRampProviderType,
  paymentMethod: any,
  cancelPriceRequest,
) {
  switch (providerType) {
    case OnRampProviderType.BANXA:
    default:
      dispatch(setBanxaSelectedPaymentMethod(paymentMethod));
      break;

    case OnRampProviderType.TRANSAK: {
      dispatch(setTransakSelectedPaymentMethod(paymentMethod));
      cancelPriceRequest(dispatch(fetchTransakPrice(CRYPTO_TYPE)));
    }
  }
}
