import { useFocus } from 'common/hooks/useFocus';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';
import Variables from 'Variables.styles';
import Icon from './Icon';

type TextBoxProps = {
  disabled: boolean;
  dropdownTmpl: React.ReactNode;
  label: string;
  onChange;
  placeholder: string;
  tabIndex: number;
  type?: string;
  value: any;
  withdropdown: boolean;
  faded?: boolean
}

const useStyles = createUseStyles({
  faded: {
    color: Variables.gray300,
    backgroundColor: 'revert !important',
  },
  clickableWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
  },
});

export const TextBox = forwardRef<any, TextBoxProps>((props, ref) => {
  const {
    disabled,
    dropdownTmpl,
    label,
    onChange,
    placeholder,
    tabIndex,
    type = 'text',
    value,
    withdropdown,
    faded,
  } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    isPasswordVisible: false,
    showDropdown: false,
  });

  const [inputRef, setFocus] = useFocus();

  useImperativeHandle(ref, () => ({
    hideDropdown: () => {
      hideDropdown();
    },
    focus: () => {
      setFocus();
    },
  }));

  const id = uuidv4();
  const dropdownClass = [withdropdown && 'with-dropdown', faded && classes.faded].filter(Boolean).join(' ');

  const handleOutsideClick = (e) => {//eslint-disable-line
    if (!state.showDropdown) {
      return;
    }

    const dropdownButton = document.getElementById(`dropdown-button_${id}`);

    if (dropdownButton?.contains(e.target)) {
      return;
    }

    setState({ ...state, showDropdown: false });
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const renderDropdownIcon = () => {
    const { showDropdown } = state;

    if (withdropdown) {
      return (
        <span className="input-accessory coin-symbol">
          <Icon
            containerClassName={`list-icon ${showDropdown ? 'active' : ''}`}
            name="list"
            onClick={(e) => onDropdownIconClick(e)}
            id={`dropdown-button_${id}`}
          />
        </span>
      );
    }

    return null;
  };

  const onDropdownIconClick = (e) => {
    e.preventDefault();
    setState({ ...state, showDropdown: !state.showDropdown });
  };

  const renderDropdown = () => {
    const { showDropdown } = state;

    if (!withdropdown || !dropdownTmpl) {
      return null;
    }

    if (showDropdown) {
      return (
        <div className="textbox-dropdown">
          {dropdownTmpl}
        </div>
      );
    }

    return null;
  };

  const hideDropdown = () => {
    setState({
      ...state,
      showDropdown: false,
    });
  };

  return (
    <div className="input-group">
      <label htmlFor={id}>
        <input
          ref={inputRef}
          className={dropdownClass}
          id={id}
          value={value}
          type={type}
          onChange={(event) => onChange(event.currentTarget.value)}
          tabIndex={tabIndex}
          placeholder={placeholder || ''}
          disabled={disabled}
        />
        <span className="label">
          <Trans>{label || ''}</Trans>
        </span>
        {renderDropdownIcon()}
      </label>
      {renderDropdown()}

      {withdropdown && disabled && (
        <div
          onClick={(e) => onDropdownIconClick(e)}
          className={classes.clickableWrapper}
        ></div>
      )}
    </div>
  );
});
