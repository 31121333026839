import BigNumber from 'bignumber.js';
import { BigNumberConstructable, Currency } from '../lib/customType';
import convertUSDToPreferredCurrency from './convertUSDToPreferredCurrency';

const getValueInPreferredCurrency = (
  amountOfCoin: BigNumberConstructable,
  priceUSD: BigNumberConstructable,
  btcPrice: BigNumberConstructable,
  preferredCurrency: Currency,
  currencyRates: Record<Currency, BigNumberConstructable>,
) => {
  if (!amountOfCoin || !priceUSD || !btcPrice || !preferredCurrency) {
    return null;
  }

  const amountInUSD = new BigNumber(amountOfCoin).multipliedBy(priceUSD);

  return convertUSDToPreferredCurrency(amountInUSD, btcPrice, preferredCurrency, currencyRates);
};

export default getValueInPreferredCurrency;
