import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from 'config/app';

export type MerkleTreeBalances = Record<string, string>;

export type MerkleTreeAssets = {
  lending?: MerkleTreeBalances;
  lm?: MerkleTreeBalances;
  staking?: MerkleTreeBalances;
  wallet?: MerkleTreeBalances;
}

export type MerkleTreeLeaf = {
  hash: string;
  timeOfSnapshot: string;
  assets: MerkleTreeAssets
}

export type MerkleTree = {
  userID: string;
  timeOfSnapshot: string;
  assets: MerkleTreeAssets
}

export type MerkleTreeResponseType = {
  root: MerkleTree,
  userLeaf: MerkleTreeLeaf,
  user: MerkleTree,
}

type TransparencyState = {
  state: 'UNLOADED' | 'LOADING' | 'LOADED' | 'ERROR'
  userHash?: string;
  rootMerkleTree?: MerkleTree;
  userMerkleTreeLeaf?: MerkleTreeLeaf;
  userMerkleTree?: MerkleTree;
  error?: any;
}

const initialState: TransparencyState = {
  state: 'UNLOADED',
};

export const fetchUserMerkleTree = createAsyncThunk<MerkleTreeResponseType, any>(
  'transparency/fetchUserMerkleTree',
  async (userHash: string) => {
    const [rootResponse, userLeafResponse, userResponse] = await Promise.all([
      axios.get(`${appConfig.MERKLE_TREE_SERVICE}/root`),
      axios.get(`${appConfig.MERKLE_TREE_SERVICE}/leaf/${userHash}`),
      axios.get(`${appConfig.MERKLE_TREE_SERVICE}/tree/${userHash}`),
    ]);
    const result: MerkleTreeResponseType = {
      root: rootResponse.data as MerkleTree,
      userLeaf: userLeafResponse.data as MerkleTreeLeaf,
      user: userResponse.data as MerkleTree,
    };
    return result;
  },
);

const transparencySlice = createSlice({
  name: 'transparency',
  initialState,
  reducers: {
    clearMerkleTree: (state) => {
      state.state = 'UNLOADED';
      state.rootMerkleTree = null;
      state.userHash = null;
      state.userMerkleTreeLeaf = null;
      state.userMerkleTree = null;
    },
    setUserHash: (state, action: PayloadAction<string>) => {
      state.userHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserMerkleTree.pending, (state) => {
      state.state = 'LOADING';
    });
    builder.addCase(fetchUserMerkleTree.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.rootMerkleTree = action.payload.root;
      state.userMerkleTree = action.payload.user;
      state.userMerkleTreeLeaf = action.payload.userLeaf;
    });
    builder.addCase(fetchUserMerkleTree.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export const { clearMerkleTree, setUserHash } = transparencySlice.actions;
export default transparencySlice.reducer;
