import React from 'react';
import PropTypes from 'prop-types';

const MouseClickTooltip = ({ className = {}, children, renderTooltip, ...props }) => {
  const onMouseClick = e => window.cakepool.showTooltip({ renderContent: renderTooltip, showCloseButton: true }, e);
  return (
    <span
      className={className}
      onClick={e => onMouseClick(e)}
      {...props}>
        {children}
      </span>
  );
};

MouseClickTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderTooltip: PropTypes.func,
};

MouseClickTooltip.defaultProps = {
  className: '',
};

export default MouseClickTooltip;
