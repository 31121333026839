import more from 'assets/svg/icon-more.svg';
import ovenModeNormal from 'assets/svg/icon-oven-mode-normal.svg';
import ovenModeCompact from 'assets/svg/icon-oven-mode-compact.svg';
import eyeClose from 'assets/svg/icon-eye-close.svg';
import eyeOpen from 'assets/svg/icon-eye-open.svg';
import close from 'assets/svg/close-button.svg';
import search from 'assets/svg/icon-search.svg';
import help from 'assets/svg/icon-help.svg';
import arrowUp from 'assets/svg/icon-arrow-up.svg';
import arrowDown from 'assets/svg/icon-arrow-down.svg';
import sortDown from 'assets/svg/icon-sort-down.svg';
import sortUp from 'assets/svg/icon-sort-up.svg';
import next from 'assets/svg/icon-next.svg';
import previous from 'assets/svg/icon-prev.svg';
import trade from 'assets/svg/icon-trade.svg';
import hamburger from 'assets/svg/icon-hamburger.svg';
import info from 'assets/svg/icon-info.svg';
import save from 'assets/svg/icon-save.svg';
import bonus from 'assets/svg/affiliate-feature-bonus.svg';
import unlimited from 'assets/svg/affiliate-feature-unlimited.svg';
import tracking from 'assets/svg/affiliate-feature-tracking.svg';
import list from 'assets/svg/list.svg';
import mailbox from 'assets/svg/mailbox.svg';
import copy from 'assets/svg/icon-copy.svg';
import arrowForward from 'assets/svg/icon-arrow-forward.svg';
import check from 'assets/svg/icon-check.svg';
import error from 'assets/svg/icon-error.svg';
import playCircle from 'assets/svg/icon-play-circle.svg';
import warning from 'assets/svg/icon-warning.svg';
import mail from 'assets/svg/mail-popup-banner-icon.svg';
import mailClose from 'assets/svg/mail-close-icon.svg';
import errorWarning from 'assets/svg/error-warning.svg';
import kycBenefit from 'assets/svg/kyc/kyc-benefit-icon.svg';
import alert from 'assets/svg/icon-alert.svg';
import clock from 'assets/svg/icon-clock.svg';
import iconDone from 'assets/svg/icon-green-check.svg';

export const SVGIcons = {
  more,
  ovenModeNormal,
  ovenModeCompact,
  eyeClose,
  eyeOpen,
  close,
  search,
  help,
  arrowUp,
  arrowDown,
  sortDown,
  sortUp,
  next,
  previous,
  trade,
  hamburger,
  info,
  save,
  bonus,
  unlimited,
  tracking,
  list,
  mailbox,
  copy,
  arrowForward,
  check,
  error,
  playCircle,
  warning,
  mail,
  mailClose,
  errorWarning,
  kycBenefit,
  alert,
  clock,
  iconDone,
};
