import BigNumber from 'bignumber.js';
import convertUSDToPreferredCurrency from './convertUSDToPreferredCurrency';

/**
 *
 * @param {number | string | BigNumber} amountOfCoin - coin amount
 * @param {string} priceUSD - USD price of coin
 * @param {string} btcPrice - BTC price in USD
 * @param {string} preferredCurrency - preferred currency of user
 * @returns {string} - price in preferred currency
 */
const getValueInPreferredCurrency = (amountOfCoin, priceUSD, btcPrice, preferredCurrency, currencyRates) => {
  if (!amountOfCoin || !priceUSD || !btcPrice || !preferredCurrency) {
    return null;
  }

  const amountInUSD = new BigNumber(amountOfCoin).multipliedBy(priceUSD);

  return convertUSDToPreferredCurrency(amountInUSD, btcPrice, preferredCurrency, currencyRates);
};

export default getValueInPreferredCurrency;
