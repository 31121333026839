import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authCode: null,
};

const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState,
  reducers: {
    setMyInfoAuthCode: (state, action) => {
      const { authCode } = action.payload;
      state.authCode = authCode;
    },
    clearMyInfoAuthCode: (state) => {
      state.authCode = null;
    },
  },
});

export const { setMyInfoAuthCode, clearMyInfoAuthCode } = myInfoSlice.actions;

export default myInfoSlice.reducer;
