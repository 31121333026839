const getLevelDisplay = (kycTier: string, kycFullStatus: string, isUserEligibleStaggeredKyc: boolean) => {
  if (isUserEligibleStaggeredKyc) {
    const StaggeredKycLevelMap = {
      TIER0: 'Level 0',
      TIER1: 'Level 1',
      TIER2: 'Level 2',
    };
    return StaggeredKycLevelMap[kycTier] ?? 'Level 0';
  }
  return kycFullStatus === 'APPROVED' ? 'Level 1' : 'Level 0';
};

export default getLevelDisplay;
