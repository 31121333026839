import React, { useState, useCallback } from 'react';
import { Trans, Translation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import { allowedCountriesEN } from '@cakedefi/cake-sdk/assets';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import Select from '../../../../common/components/Select/Select';
import Button from '../../../../common/components/Button/Button';
import useIsMountedRef from '../../../../utils/useIsMountedRef';
import sdk from '../../../../sdk';
import fetchWithSpinner from '../../../../utils/fetchWithSpinner';
import { reloadUser } from '../../../../user/userSlice';
import CountryOrResidenceStyles from './CountryOrResidence.styles';

const useStyles = createUseStyles(CountryOrResidenceStyles);

const CountryOfResidence = ({ gotoVerifyIdentity }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { details } = useCakeSelector(state => state.user);
  const countries = allowedCountriesEN;
  const countryOption = countries.find(item => item.value === details?.country);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [country, setCountry] = useState('');

  const trackOnSaveCountryOfResidence = (countryValue) => {
    gtmTrack('trackEvent', {
      event: iEventNames.country_verified,
      event_type: iEventTypes.custom,
      user_country_code: countryValue,
    });
  };

  const nextScreen = useCallback(() => {
    const saveUserProfile = async () => {
      setInvalidMessage('');
      try {
        const countryValue = country || details?.country;
        const userProfileResponse = await fetchWithSpinner(() => sdk.UserInfoApi.updateProfile({ country: countryValue }), { showAlert: false });
        if (details?.country || (userProfileResponse.country && isMountedRef.current)) {
          trackOnSaveCountryOfResidence(userProfileResponse.country);
          dispatch(reloadUser());
          gotoVerifyIdentity();
        }
      } catch (err) {
        setInvalidMessage(err.message);
      }
    };
    saveUserProfile();
  }, [country, isMountedRef, setInvalidMessage, dispatch, gotoVerifyIdentity, details]);

  const onCountryChange = async (e) => {
    setCountry(e.value);
  };

  return (
    <article className="floating">
      <div>
        <h1 className={classes.title}>
          <Trans>Country of Residence</Trans>
        </h1>
        <div>
          <Trans>Please choose your current Country of Residence</Trans>
        </div>
        <div className="mt-5">
          <Translation>
          {
            t => <Select
              isSearchable
              defaultValue={countryOption}
              options={allowedCountriesEN}
              value={countryOption}
              valueJustify="flex-start"
              onChange={onCountryChange}
              placeholder={t('Country Of Residence')}
              paddingType={'textInput'}
              tooltip={t('If your country is not in the list, you will not be able to verify your account.')}
              tabIndex={6}
            />
          }
          </Translation>
          {
            invalidMessage && (
              <div className={classes.invalidMessage}><Trans i18nKey={invalidMessage}>{invalidMessage}</Trans></div>
            )
          }
          <Button
            disabled={!country}
            className="mt-5"
            primary
            block
            type="button"
            onClick={() => nextScreen()}
            outline
            tabIndex={7}>
            <Trans>Continue</Trans>
          </Button>
        </div>
      </div>
    </article>
  );
};

export default CountryOfResidence;
