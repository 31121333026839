import bakeLogo from '../../../assets/svg/logos/bake-logo.svg';
import bakeLogoCompact from '../../../assets/img/bakeLogo/bake.svg';
import Variables, { getMinWidthSm } from '../../../Variables.styles';

export default {
  invertColour: {
    filter: 'invert(50%) grayscale(100%)',
  },
  branchLogo: {
    boxSizing: 'content-box',
    flexShrink: 0,
    textIndent: '-9999px',
    display: 'block',
    flexBasis: Variables.larger,
    width: Variables.larger,
    height: Variables.larger,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${bakeLogoCompact})`,
    transition: 'all .3s ease',
    backgroundSize: 'cover',
  },
  [getMinWidthSm]: {
    branchLogo: {
      flexBasis: '206px',
      width: '206px',
      height: '40px',
      backgroundSize: 'contain',
      backgroundImage: `url(${bakeLogo})`,
    },
  },
};
