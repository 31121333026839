import { GenericState, PromotionsAuthenticated, StateStatus } from '@cakedefi/cake-sdk/schema';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from 'sdk';

type PromotionCampaignlSlice = GenericState<PromotionsAuthenticated>;

const initialState: PromotionCampaignlSlice = {
  status: StateStatus.STARTED,
  data: {
    activePromotion: null,
    promotionRetiredOn: null,
    promotionVisibleOn: null,
    promoBonusActiveFrom: null,
    promoBonusActiveUntil: null,
    confirmedPromotionReferralsCount: null,
    showWelcomeBonusVisuals: false,
  } as any,
  error: null,
};

export const fetchPromotionAuthenticated = createAsyncThunk(
  'promotionCampaign/fetchPromotionAuthenticated',
  () => sdk.PromotionApi.promotionsAuthenticated(),
);

export const fetchPromotion = createAsyncThunk(
  'promotionCampaign/fetchPromotion',
  () => sdk.PromotionApi.promotions(),
);

const promotionCampaignlSlice = createSlice({
  name: 'promotionCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromotionAuthenticated.pending, (state) => {
      state.status = StateStatus.LOADING;
      state.error = null;
    });

    builder.addCase(fetchPromotionAuthenticated.fulfilled, (state, action) => {
      state.status = StateStatus.FINISHED;
      state.data = action.payload;
      state.error = null;
    });

    builder.addCase(fetchPromotionAuthenticated.rejected, (state, action) => {
      state.status = StateStatus.ERROR;
      state.error = action.payload;
    });

    builder.addCase(fetchPromotion.pending, (state) => {
      state.status = StateStatus.LOADING;
      state.error = null;
    });

    builder.addCase(fetchPromotion.fulfilled, (state, action) => {
      state.status = StateStatus.FINISHED;
      state.data = action.payload as any;
      state.error = null;
    });

    builder.addCase(fetchPromotion.rejected, (state, action) => {
      state.status = StateStatus.ERROR;
      state.error = action.payload;
    });
  },
});

export default promotionCampaignlSlice.reducer;
