import { FeatureInfo, RecentTransaction, TotalQueuedShares, WalletBalance } from '../../schema/custom';
import { UserRewardList } from '../../schema/user-reward-list';
import { AmountInUsd, DepositAddress, DepositList, ExportTaxReportRequest, ExportTransactionsRequest, WalletListRequest, ListTransactionsRequest, WalletWithdrawRequest, WalletUserPortfolioResponse } from '../../schema/wallet';
import { WithdrawalList, WithdrawalRequest } from '../../schema/withdrawal';
import { LastFreeWithdrawalModel, LastWeekAvgDurationModel } from '../../schema/lastFreeWithdrawal';
import { CakeApiBase } from '../CakeApiBase';
import { TransactionsQueueWithCount } from '../../schema/transaction/transaction-queue';
import { PagingRequest } from '../../schema/api/paging-request';

export class WalletApi extends CakeApiBase {
  async get() {
    return this.requestManager.get('wallets');
  }

  async getPromotions() {
    return this.requestManager.get('me/bonus-status');
  }

  async getBalance(isBackgroundRequest = false) {
    return this.requestManager.get<WalletBalance[]>('wallets/balance', null, {
      isBackgroundRequest,
    });
  }

  async getDepositAddress(coin: string, networkId: string) {
    return this.requestManager.get<DepositAddress>(`wallets/deposit-address/${coin}/${networkId}`);
  }

  async listTransactions(query: ListTransactionsRequest = {}) {
    return this.requestManager.get<RecentTransaction[]>('wallets/transactions', query);
  }

  async listTransactionsQueue(request: PagingRequest = {}) {
    return this.requestManager.get<TransactionsQueueWithCount>('wallets/transactions/queue', request);
  }

  async exportTransactions(query: ExportTransactionsRequest = {}) {
    return this.requestManager.get<RecentTransaction[]>('wallets/transactions/export', query);
  }

  async exportTransactionsV2(query: ExportTransactionsRequest = {}) {
    return this.requestManager.get<{ csvString: string }>('wallets/transactions/v2/export', query);
  }

  async exportTaxReport(query: ExportTaxReportRequest = {}) {
    return this.requestManager.get<any>('wallets/transactions/tax-report', query);
  }

  async deposit(coinId: string) {
    return this.requestManager.get(`dev/magictopup/${coinId}`);
  }

  async listDeposits(query: WalletListRequest = {}) {
    return this.requestManager.get<DepositList>('wallets/deposit', query);
  }

  async depositsInfo(query: WalletListRequest = {}) {
    return this.requestManager.get<FeatureInfo>('wallets/deposit/info', query);
  }

  async getDepositAmountInUsd() {
    return this.requestManager.get<AmountInUsd>('wallets/deposit/amount/usd');
  }

  async listUserRewards(query: WalletListRequest = {}) {
    return this.requestManager.get<UserRewardList>('wallets/reward', query);
  }

  async userRewardsInfo(query = {}) {
    return this.requestManager.get<FeatureInfo>('wallets/reward/info', query);
  }

  async listSharesQueued() {
    return this.requestManager.get<TotalQueuedShares>('wallets/shares/queued');
  }

  async sharesQueuedInfo() {
    return this.requestManager.get<FeatureInfo>('wallets/shares/queued/info');
  }

  async cancelPurchaseShare(coinId: string) {
    return this.requestManager.post('wallets/shares/queued/cancel', {
      coinId,
    });
  }

  /* #region WithdrawalApi */
  async cancelWithdrawal(transactionId: string) {
    return this.requestManager.put(
      `wallets/withdrawal/user-cancel/${transactionId}`,
    );
  }

  async resendWithdrawalConfirmation(id: string) {
    return this.requestManager.post<WithdrawalRequest>('wallets/withdrawal/resend-confirmation', {
      id,
    });
  }

  async withdraw(body: WalletWithdrawRequest) {
    return this.requestManager.post<WithdrawalRequest[]>('wallets/withdraw/request', body);
  }

  async withdrawalsInfo(query = {}) {
    return this.requestManager.get<FeatureInfo>('wallets/withdrawal/info', query);
  }

  async listWithdrawals(query: WalletListRequest = {}) {
    return this.requestManager.get<WithdrawalList[]>('wallets/withdrawal', query);
  }

  async getLatestFreeWithdrawal() {
    return this.requestManager.get<LastFreeWithdrawalModel>('wallets/withdrawal/last-free');
  }

  async getLastWeekAvgDuration() {
    return this.requestManager.get<LastWeekAvgDurationModel>('wallets/withdrawal/average-duration');
  }

  async getWalletUserPortfolio() {
    return this.requestManager.get<WalletUserPortfolioResponse>('wallets/user-portfolio');
  }

  /* #endregion */
}
