import React from 'react';
import { DialogActions, makeStyles } from '@material-ui/core';
import Variables from 'Variables.styles';

const useStyles = makeStyles<string, { flex: boolean, withBorderTop: boolean }>({
  dialogActionRoot: ({ flex, withBorderTop }) => ({
    borderTop: withBorderTop ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
    display: flex ? 'flex' : 'block',
    padding: flex ? '8px' : '0',
  }),
  footerRightButton: {
    padding: `${Variables.small} ${Variables.large} ${Variables.small} 0`,
    textAlign: 'right',
  },
  footerSpaceBetween: {
    padding: `${Variables.small} ${Variables.large}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
  },
});

interface ModalFooterProps {
  children: any;
  styleType?: 'footerRightButton' | 'footerSpaceBetween';
  flex?: boolean;
  withBorderTop?: boolean;
}

export function ModalFooter({ children, styleType, flex = false, withBorderTop }: ModalFooterProps) {
  const classes = useStyles({ flex, withBorderTop });
  return (
    <DialogActions className={classes.dialogActionRoot}>
      { styleType ? <div className={classes[styleType]}>{children}</div> : children }
    </DialogActions>
  );
}
