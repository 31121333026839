import React, { ReactNode } from 'react';
import { ModalContent } from 'modals/components';
import { createUseStyles } from 'react-jss';
import { Trans } from 'react-i18next';
import Button from 'common/components/Button/Button';
import kycRejected from 'assets/svg/kyc/kyc-rejected-icon.svg';
import kycVerified from 'assets/svg/kyc/kyc-verified-icon.svg';
import kycInReview from 'assets/svg/kyc/kyc-in-review-icon.svg';
import kycWarning from 'assets/svg/kyc/kyc-warning-icon.svg';
import kycExclaimation from 'assets/svg/kyc/kyc-exclaimation-icon.svg';
import ReactSvg from 'react-svg';
import Variables from 'Variables.styles';
import { Icon } from 'views/components';
import { useCakeSelector } from 'app/rootReducer';
import { getIsPhoneNumberVerified } from 'user/AccountVerification/accountVerificationSelector';
import trackOnClickKyc from 'user/AccountVerification/utils/trackOnClickKyc';
import { useDispatch } from 'react-redux';
import { setStage } from 'user/AccountVerification/accountVerificationSlice';
import Enums from '../../../../user/AccountVerification/AccountVerificationStagesEnums';

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '500px',
    margin: '24px 24px',
    gap: Variables.large,
    padding: Variables.small,
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: Variables.small,
  },
  title: {
    margin: 0,
  },
  icon: {
    height: '60px',
    width: '60px',
    '& svg': {
      height: '60px',
      width: '60px',
    },
  },
  button: {
    width: '100%',
  },
  kycDetailsContainer: {
    backgroundColor: Variables.gray100,
    width: '100%',
    padding: Variables.medium,
    borderRadius: Variables.small,
    display: 'flex',
    justifyContent: 'space-between',
    gap: Variables.small,
  },
  kycDetail: {
    display: 'flex',
    textAlign: 'left',
  },
  kycDetailDescription: {
    paddingLeft: Variables.small,
  },
  buttonContainer: {
    display: 'flex',
    gap: Variables.large,
  },
});

interface KycContentType {
  icon: string;
  title: string;
  subtitle: string;
  description: string;
  bottomSection: ReactNode | null;
  button: ReactNode;
}

type KycModalType = 'PENDING'
| 'REJECTED'
| 'BLACKLISTED'
| 'STAGGERED_APPROVED_LEVEL0TO1'
| 'STAGGERED_APPROVED_LEVEL1TO2'
| 'NON_STAGGERED_APPROVED'
| 'KYC_VERIFICATION_FAIL'
| 'SUMSUB_PROCEED_CONFIRMATION'
| 'COUNTRY_RESTRICTION_LEVEL_2';

const getKycContent = (
  contentType: KycModalType,
  onClose: () => void,
  onProceed: () => void,
  userKycOnboardingCode: string,
  { classes }: any,
): KycContentType => {
  const onBeforeCloseTrack = (type: string) => {
    onClose();
  };

  switch (contentType) {
    // only shown when user tries to access product/feature but account is under review
    // for both staggered and non-staggered
    case 'PENDING':
      return {
        icon: kycInReview,
        title: 'We are currently reviewing your submission',
        subtitle: null,
        description: 'Your documents are under reviewed. This process usually take 1 to 3 days. Once the review is completed you will be notified over email.',
        bottomSection: null,
        button: (
          <Button main className={classes.button} onClick={() => onClose()}>
            <Trans>Got It</Trans>
          </Button>
        ),
      };
    // only shown once after user is logged in
    // for both staggered and non-staggered
    case 'REJECTED':
      return {
        icon: kycRejected,
        title: 'Your account has been rejected.',
        subtitle: null,
        description: 'Sorry, but we could not verify your identity.',
        bottomSection: null,
        button: (
          <a href={'/me/verify-account'} className={classes.button}>
            <Button main className={classes.button} onClick={() => onClose()}>
              <Trans>Retry verification</Trans>
            </Button>
          </a>
        ),
      };

    // show when user is blacklisted
    // for both staggered and non-staggered
    case 'BLACKLISTED':
      return {
        icon: kycWarning,
        title: 'After multiple attempts, we were unable to verify your identity automatically. Please contact Bake Support for further assistance.',
        subtitle: null,
        description: null,
        bottomSection: null,
        button: (
          <Button primary className={classes.button} onClick={() => onBeforeCloseTrack('BLACKLISTED')}>
            <Trans>Close</Trans>
          </Button>
        ),
      };

    // only shown once after user is logged in
    case 'STAGGERED_APPROVED_LEVEL0TO1':
      return {
        icon: kycVerified,
        title: 'Best way to start baking!',
        subtitle: 'Your account has been verified.',
        description: 'You can now deposit, trade and earn cryptocurrency.',
        bottomSection: (
          <div className={classes.kycDetailsContainer}>
            <div className={classes.kycDetail}>
              <Icon name={'kycBenefit'} svgStyle={{ width: 24, height: 24 }}/>
              <div className={classes.kycDetailDescription}>
                <Trans>Withdrawal of up to 10,000 USD per month. You can unlock the withdrawal limit by verifying your proof of address.</Trans>
              </div>
            </div>
          </div>
        ),
        button: (
          <Button main className={classes.button} onClick={() => onBeforeCloseTrack('TIER1')}>
            <Trans>Start Exploring</Trans>
          </Button>
        ),
      };
    // only shown once after user is logged in
    case 'STAGGERED_APPROVED_LEVEL1TO2':
      return {
        icon: kycVerified,
        title: 'Perfect! You have unlocked withdrawal limits.',
        subtitle: null,
        description: 'You can now enjoy unlimited withdrawal limits.',
        bottomSection: null,
        button: (
          <Button main className={classes.button} onClick={() => onBeforeCloseTrack('TIER2')}>
            <Trans>Start Baking</Trans>
          </Button>
        ),
      };
    // only shown once after user is logged in
    case 'NON_STAGGERED_APPROVED':
      return {
        icon: kycVerified,
        title: 'Perfect! Your account has been verified.',
        subtitle: null,
        description: 'You can now deposit, trade and earn cryptocurrency.',
        bottomSection: null,
        button: (
          <Button main className={classes.button} onClick={() => onBeforeCloseTrack('TIER2')}>
            <Trans>Start Baking</Trans>
          </Button>
        ),
      };
    case 'SUMSUB_PROCEED_CONFIRMATION':
      return {
        icon: kycWarning,
        title: 'Are you sure?',
        subtitle: null,
        description: 'You are about to start an application manually. This action cannot be undone.',
        bottomSection: null,
        button: (
          <div className={classes.buttonContainer}>
            <Button primary className={classes.button} onClick={() => onClose()}>
              <Trans>Cancel</Trans>
            </Button>
            <Button main className={classes.button} onClick={() => onProceed()}>
              <Trans>Verify manually</Trans>
            </Button>
          </div>
        ),
      };
    case 'COUNTRY_RESTRICTION_LEVEL_2':
      return {
        icon: kycWarning,
        title: null,
        subtitle: null,
        description: 'As per compliance with regulations, level 2 verification is not available in your region.',
        bottomSection: null,
        button: (
          <div className={classes.buttonContainer}>
            <Button primary className={classes.button} onClick={() => onClose()}>
              <Trans>Close</Trans>
            </Button>
          </div>
        ),
      };
    case 'KYC_VERIFICATION_FAIL':
      return {
        icon: kycExclaimation,
        title: 'Verification failed',
        subtitle: null,
        description: 'Sorry, but we could not verify your identity with myinfo.',
        bottomSection: null,
        button: (
          <Button main className={classes.button} onClick={() => onProceed()}>
            <Trans>Verify manually</Trans>
          </Button>
        ),
      };
    default:
      return null;
  }
};

interface KycModalProps {
  onClose: () => void;
  modalType?: KycModalType;
  userOnboardingCode?: string;
}

const KycModal = ({ onClose, modalType = 'NON_STAGGERED_APPROVED', userOnboardingCode }: KycModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhoneNumberVerified = useCakeSelector(getIsPhoneNumberVerified);

  const onClickSumsubProceed = async () => {
    if (isPhoneNumberVerified) {
      trackOnClickKyc();
      dispatch(setStage(Enums.SUMSUB_WEB));
    }
    onClose();
  };

  const kycContent = getKycContent(modalType, onClose, onClickSumsubProceed, userOnboardingCode, { classes });
  const {
    icon,
    title,
    subtitle,
    description,
    bottomSection,
    button,
  } = kycContent;

  return (
    <>
      <ModalContent>
        <div className={classes.content}>
          <ReactSvg src={icon} className={classes.icon}/>
          <div className={classes.textSection}>
            <h3 className={classes.title}>
              <Trans>{title}</Trans>
            </h3>
            {
              subtitle ? (<h3 className={classes.title}><Trans>{subtitle}</Trans></h3>) : null
            }
          </div>
          {description && <div>
            <Trans>{description}</Trans>
          </div>}
          {bottomSection || null}
          {button}
        </div>
      </ModalContent>
    </>
  );
};

export default KycModal;
