import lessonImages from './lessonImages';
import { Stack } from './learnEarnContent';

export const btcContent: Stack = {
  isAvailable: true,
  id: 'BTC',
  coinId: 'BTC',
  coinName: 'Bitcoin',
  description: 'Decentralized, scarce digital currency',
  rewardType: 'FrozenLiquidityMiningShare',
  welcomeImage: lessonImages.btcCover,
  lessons: [
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'BTC-1',
      lessonIndex: 1,
      title: 'What is Bitcoin',
      parts: [{
        partIndex: 1,
        image: lessonImages.btcL1P1,
        content: 'Bitcoin or BTC is a <b>decentralized digital currency</b> that can be sent by one user to another, peer-to-peer on the Bitcoin network <b>without the need for intermediaries</b> such as banks or any other middlemen.',
      }, {
        partIndex: 2,
        image: lessonImages.btcL1P2,
        content: 'Bitcoin was introduced in 2008 and launched in 2009 by a still unknown person (or group of people) using the pseudonym <b>Satoshi Nakamoto</b>. It is widely considered as the world’s first-ever cryptocurrency.',
      }, {
        partIndex: 3,
        image: lessonImages.btcL1P3,
        content: 'People use bitcoin for various reasons but, because of its permissionless nature, the original intention was to use it as a form of <b>digital cash or currency</b>. Anyone with an Internet connection can send or receive bitcoin, regardless of where they are in the world.',
      }, {
        partIndex: 4,
        image: lessonImages.btcL1P4,
        content: 'Furthermore, bitcoin is considered scarce as there can only be a maximum number of 21 million bitcoins (BTC) in existence.<br><br>This finite supply is what makes it interesting as a long-term investment, since supply is limited but demand is likely to grow.',
      }, {
        partIndex: 5,
        image: lessonImages.btcL1P5,
        content: 'Instead of digital cash, the bitcoin narrative has shifted more towards serving as a <b>store of value</b>. In fact, it has been nicknamed by many as <b>digital gold</b>. This is due to it’s <b>censorship-resistant and scarce</b> nature, combining the best attributes of gold in the digital world.',
      }, {
        partIndex: 6,
        image: lessonImages.btcL1P6,
        content: 'Are you always required to purchase at least one (1) bitcoin? The answer is, no.<br><br>A unit of bitcoin is divisible to <b>eight decimal places</b> (100 millionths of one bitcoin). Hence, it can be purchased in smaller units called a <b>Satoshi</b> - which, as you might have already guessed, was named in homage to Bitcoin’s creator(s) – Satoshi Nakamoto.',
      }],
      quiz: {
        question: 'Which of the following best describes bitcoin?',
        answer: 'A',
        options: [{
          option: 'A',
          description: 'Bitcoin is a decentralized digital currency which was created by Satoshi Nakamoto in 2008. Anyone with an Internet connection can either send or receive it as a form of payment, but it can also be used as a store of value. It is the world’s first-ever cryptocurrency.',
          explanation: 'Correct answer!',
        }, {
          option: 'B',
          description: 'Bitcoin is digital gold and is only used as store of value. It can be divided into smaller units, called Satoshis.',
          explanation: 'Sorry, wrong answer. People use bitcoin for various reasons, not just as a store of value. Please, try again.',
        }, {
          option: 'C',
          description: 'Bitcoin is a decentralized digital currency which was created by Satoshi Nakamura in 2008. Anyone with an Internet connection can either send or receive it as a form of payment, but it can also be used as a store of value.',
          explanation: 'Sorry, wrong answer. Bitcoin was introduced in 2008 and launched in 2009 by a still unknown person (or group of people) named Satoshi Nakamoto. Please, try again. ',
        }, {
          option: 'D',
          description: 'Bitcoin is a digital currency which was created by Satoshi Nakamoto in 2008. Anyone with a bank account can either send or receive it as a form of payment, but it can also be used as a store of value. It is the world’s first-ever cryptocurrency.',
          explanation: 'Sorry, wrong answer. Users can send or receive bitcoins on the Bitcoin network without the need for intermediaries such as banks or any other middlemen. Please, try again.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'BTC-2',
      lessonIndex: 2,
      title: 'How Does Bitcoin Work',
      parts: [{
        partIndex: 1,
        image: lessonImages.btcL2P1,
        content: 'To understand how the Bitcoin network works, it is important to understand the various concepts or components that ties it all up together. So, let’s start with <b>blockchain technology</b>.<br><br>Simply put, a blockchain is a <b>shared public ledger that exists and is digitally distributed across a network</b>. The entire Bitcoin network relies on this technology to record the history of every bitcoin transaction in a <b>transparent, immutable, append-only</b> and <b>decentralized</b> manner.',
      }, {
        partIndex: 2,
        image: lessonImages.btcL2P2,
        content: 'Bitcoin also uses a <b>peer-to-peer network, public-key cryptography</b> and a <b>proof-of-work consensus mechanism</b> to process and verify transactions.<br><br>In Lesson 1, we briefly mentioned that bitcoin is a <b>peer-to-peer digital currency</b>. As such,  users who want to exchange bitcoin with others on the Bitcoin network <b>do not require the approval or assistance of intermediaries to execute and verify transactions</b>.',
      }, {
        partIndex: 3,
        image: lessonImages.btcL2P3,
        content: 'How are bitcoin transactions verified? This is done by <b>network nodes</b> (decentralized computers) via a system called <b>public-key cryptography</b>. What is it? It’s a type of cryptography (a system of encoding and decoding of data) that uses pairs of keys (one public key and one private key).',
      }, {
        partIndex: 4,
        image: lessonImages.btcL2P4,
        content: 'What happens to these verified transactions? They are recorded as <b>blocks on the blockchain</b>. In the Bitcoin network, participants called <b>miners</b> compete to append these blocks.<br><br><b>Proof-of-Work</b> is the consensus mechanism used to <b>confirm</b> that these miners calculate <b>valid</b> alphanumeric codes — called <b>hashes</b> — to verify bitcoin transactions and add the next block to the blockchain.',
      }, {
        partIndex: 5,
        image: lessonImages.btcL2P5,
        content: 'We will further dive into these concepts later on. But, in summary, it is by utilizing these concepts and components that the Bitcoin network is able to provide a <b>fast and reliable</b> payment network that <b>anyone can use</b>.',
      }, {
        partIndex: 6,
        image: lessonImages.btcL2P6,
        content: 'To be more specific, with these various concepts and components coming together, Bitcoin network users are able to benefit from (but not limited to) the following advantages: <br><br>• <b>Payment freedom</b> - users can send and receive bitcoin anywhere and anytime. The transactions are relatively fast too! <br>• <b>Security and control</b> - bitcoin transactions are secure, transparent and irreversible. <br>• <b>Pseudonymity</b> - the network does not require any ID, which makes it suitable for the unbanked and the privacy-conscious. <br>• <b>Censorship-resistance</b> - nobody is able to block or freeze a transaction of any amount.',
      }],
      quiz: {
        question: 'Which of the following  is the simplest way to describe how Bitcoin works? ',
        answer: 'C',
        options: [{
          option: 'A',
          description: 'Bitcoins are sent and received via mobile banking. Banks verify these transactions and ensure that they are secure by requiring users to provide ID.',
          explanation: 'Sorry, wrong answer. Users send or receive bitcoins on the Bitcoin network and do not require the approval or assistance of intermediaries to execute and verify transactions. IDs are also not required. Please, try again.',
        }, {
          option: 'B',
          description: 'Bitcoin transactions are usually in huge amounts. For this reason, a central authority is required to oversee and administer a transaction between two bitcoin users.',
          explanation: 'Sorry, wrong answer. Bitcoins may be received or received in smaller units called Satoshis. However small or huge the amount of bitcoin transactions are, no central authority oversees or administers them. Please, try again.',
        }, {
          option: 'C',
          description: 'The Bitcoin network allows users to send and receive bitcoins in a peer-to-peer manner and without any central authority involved. These transactions are verified by nodes and are recorded as blocks on the blockchain. Miners compete to append these blocks and are given rewards if successful.',
          explanation: 'Correct answer!',
        }, {
          option: 'D',
          description: 'Users of the Bitcoin network may conduct bitcoin transactions on a peer-to-peer basis. A central authority is required as transactions are verified by nodes. Miners, however, are in full control of the network and may change details of any bitcoin transaction.',
          explanation: 'Sorry, wrong answer. No central authority is required. Also, verified bitcoin transactions are immutable. Please, try again.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'BTC-3',
      lessonIndex: 3,
      title: 'What is Bitcoin',
      parts: [{
        partIndex: 1,
        image: lessonImages.btcL3P1,
        content: 'Similar to other cryptocurrencies, bitcoin is created, distributed, traded and stored with the use of a decentralized public ledger system known as a <b>blockchain</b>. And, as mentioned in previous lessons, the Bitcoin network relies on this technology to verify and record all bitcoin transactions in a <b>secure, transparent and immutable manner with no central authority involved</b>.',
      }, {
        partIndex: 2,
        image: lessonImages.btcL3P2,
        content: 'Blockchains are a type of <b>distributed ledger technology</b> (DLT). As such, it can simply be defined as <b>a digital system that records, shares and synchronises transactions across multiple independent computers in different locations at the same time</b>.',
      }, {
        partIndex: 3,
        image: lessonImages.btcL3P3,
        content: 'Besides blockchains, the following are also considered as DLTs:<br><br>• Hashgraph<br>• Directed Acyclic Graph (DAG)<br>• Holochain<br>• Tempo (Radix)<br>• Cerberus',
      }, {
        partIndex: 4,
        image: lessonImages.btcL3P4,
        content: 'Bitcoin transactions are recorded <b>as a chain of blocks</b>, with each block containing a <b>collection of valid bitcoin transactions</b> and a <b>hash</b> (valid alphanumeric codes) of the previous block up to the genesis block in the chain.',
      }, {
        partIndex: 5,
        image: lessonImages.btcL3P5,
        content: 'Blockchain technology helps secure bitcoin transactions, because <b>each network node</b> that runs the blockchain <b>has the same list of blocks and transactions</b>, and can <b>transparently see these new blocks as they’re filled with new transactions</b>. Hence,  <b>it is highly unlikely that anyone can cheat the system</b>.',
      }, {
        partIndex: 6,
        image: lessonImages.btcL3P6,
        content: 'This approach also prevents <b>double-spending</b>, which occurs when a user attempts to use the same unit of bitcoin more than once. How is it prevented? Transactions verified by miners are <b>irreversible, final and cannot be modified</b>. Hence, the potential of a double-spend occurring in the Bitcoin network is highly unlikely.',
      }],
      quiz: {
        question: 'How does blockchain technology secure bitcoin transactions?',
        answer: 'B',
        options: [{
          option: 'A',
          description: 'The central authority which runs the blockchain verifies all transactions.',
          explanation: 'Sorry, wrong answer. No central authority exists on the Bitcoin network. Please, try again.',
        }, {
          option: 'B',
          description: 'Bitcoin transactions are recorded as a chain of blocks, with each block containing a collection of valid bitcoin transactions and a hash of the previous block up to the genesis block in the chain.',
          explanation: 'Correct answer!',
        }, {
          option: 'C',
          description: 'A blockchain has a central server wherein a record of all transactions are kept.',
          explanation: 'Sorry, wrong answer. Bitcoin transactions are recorded as a chain of blocks and are distributed across the network nodes. Please, try again.',
        }, {
          option: 'D',
          description: 'All transactions on the blockchain cannot be viewed and are kept secret from everyone.',
          explanation: 'Sorry, wrong answer. Each network node  that runs the blockchain have the same list of blocks and transactions, and can transparently see these new blocks as they’re filled with new transactions. Please, try again.',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'BTC-4',
      lessonIndex: 4,
      title: 'How does Bitcoin Mining work',
      parts: [{
        partIndex: 1,
        image: lessonImages.btcL4P1,
        content: 'To further understand what bitcoin mining is, it is important to be familiar with <b>timestamping</b>. Simply put, timestamping is a <b>way of proving that something occurred at a particular time</b>.',
      }, {
        partIndex: 2,
        image: lessonImages.btcL4P2,
        content: 'To form a <b>distributed timestamp server</b> – a linked list of blocks of data to be time-stamped and broadcasted publicly – as a peer-to-peer network, the Bitcoin network uses a <b>Proof-of-Work (PoW)</b> system.<br><br>This “work” is often referred to as <b>bitcoin mining</b>.',
      }, {
        partIndex: 3,
        image: lessonImages.btcL4P3,
        content: 'To further understand the connection between bitcoin mining and PoW, we must always remember that - in the Bitcoin network - <b>a new block minting must contain a PoW to be accepted by the rest of the network</b>.<br><br>PoW requires miners to find a number called a <b>nonce</b> (an arbitrary number that can only be used once), such that when the block content is hashed along with the nonce, the result is <b>numerically smaller than the network’s difficulty target</b>.',
      }, {
        partIndex: 4,
        image: lessonImages.btcL4P4,
        content: 'If the mining process comes across as difficult to understand, well that’s because it really is a complex process - particularly the math involved in it. That’s why some miners invest in expensive <b>mining rigs</b> to have higher chances of solving the equations that the blockchain generates.<br><br>Some miners also cooperate by combining their computational resources to gain an added advantage over other miners. This is called a <b>mining pool</b>.',
      }, {
        partIndex: 5,
        image: lessonImages.btcL4P5,
        content: 'As compensation for “putting in work” and for assembling a block of transactions that is accepted and verified by other miners, the successful miner or pool of miners receive <b>block rewards</b> and <b>transaction fees</b> - both in the form of bitcoins.',
      }, {
        partIndex: 6,
        image: lessonImages.btcL4P6,
        content: 'Although it may seem that miners are only motivated by the rewards that they may potentially receive, they actually play an important role in the Bitcoin network as <b>they keep the blockchain consistent, complete and unalterable</b>.<br><br>All in all, PoW - alongside the chaining of blocks - makes modifications of the blockchain extremely hard. Hence, making the Bitcoin network <b>secure and less prone to malicious attacks</b>.',
      }],
      quiz: {
        question: 'What is the main benefit of mining and the PoW system to the Bitcoin network?',
        answer: 'D',
        options: [{
          option: 'A',
          description: 'PoW and bitcoin mining allows miners to receive incentives.',
          explanation: 'Sorry, wrong answer. Being a source of rewards is not the main benefit of PoW and mining, but rather it keeps the Bitcoin network secure. Please, try again.',
        }, {
          option: 'B',
          description: 'PoW, alongside the chaining of blocks, provides excitement to network participants - especially the miners.',
          explanation: 'Sorry, wrong answer. Providing excitement is not the main benefit of PoW and mining, but rather it keeps the Bitcoin network secure. Please, try again.',
        }, {
          option: 'C',
          description: 'PoW and bitcoin mining ensures that bitcoins are exchanged based on the most current market value.',
          explanation: 'Sorry, wrong answer. PoW and bitcoin mining does not take into account the market value of BTC. Please, try again.',
        }, {
          option: 'D',
          description: 'PoW, alongside the chaining of blocks, makes modifications of the blockchain extremely hard - hence, making the Bitcoin network secure and less prone to malicious attacks.',
          explanation: 'Correct answer!',
        }],
      },
    },
    {
      rewardInUsd: 0.2,
      quizQuestionId: 'BTC-5',
      lessonIndex: 5,
      title: 'How Do You Make Money With Bitcoin',
      parts: [{
        partIndex: 1,
        image: lessonImages.btcL5P1,
        content: 'After having a good understanding of what bitcoin is and how the Bitcoin network works, we’ll now discuss how most people make money with bitcoin.<br><br>As mentioned in Lesson 1, bitcoin is considered by some as a <b>digital currency (which can be traded)</b> and as a <b>store of value (which can transfer purchasing power into the future)</b>.',
      }, {
        partIndex: 2,
        image: lessonImages.btcL5P2,
        content: 'Generally, short-term investors prefer <b>trading</b>. As the word implies, it is <b>the act of buying and selling bitcoins with the aim of making a profit on daily market price changes</b>.<br><br>Trading is mostly conducted on a <b>cryptocurrency exchange</b>, which is an online marketplace where users buy, sell and trade cryptocurrencies.',
      }, {
        partIndex: 3,
        image: lessonImages.btcL5P3,
        content: 'However, if you’re a long-term investor, you must definitely consider <b>lending</b> and <b>liquidity mining</b>.<br><br>What is lending? It is, simply, the act of <b>lending your bitcoins to institutional partners for a guaranteed return of your assets (i.e., the bitcoins that you lent) plus interest</b>.<br><br>As a technical intermediary, Bake is able to offer industry leading rates with a focus on transparency and asset segregation, true to our motto –– don\'t just trust us, verify us!',
      }, {
        partIndex: 4,
        image: lessonImages.btcL5P4,
        content: 'What is liquidity mining? <b>Basically, it is the process of providing liquidity to a liquidity pool in order to facilitate trading on a Decentralized Exchange (DEX)</b>.<br><br>If you own bitcoin, you may provide liquidity to the <b>BTC-DFI liquidity pool</b> that Bake provides access to. The <b>annual percentage rate (APR)</b> for this particular pool is currently at <b>{{btcDfiLiquidityMiningRate}}%</b>. So, check it out!',
      }, {
        partIndex: 5,
        image: lessonImages.btcL5P5,
        content: 'At Bake, you may also choose to <b>swap your bitcoin for DFI</b> - the native cryptocurrency of the DeFiChain blockchain. DFI can be <b>staked</b> and as such validate the transactions on the DeFiChain blockchain. For this service participants receive rewards of around <b>{{dfiStakingShareReturns}}% annual percentage yield (APY)</b>.<br><br>You can also double your rewards if you opt for Bake’s <b>Freezer</b> product and lock in your DFI for <b>120 months</b>. So, check it out!',
      }, {
        partIndex: 6,
        image: lessonImages.btcL5P6,
        content: 'All in all, rather than just HODLing, Bake offers bitcoin owners a <b>more secure</b> and <b>transparent</b> means of earning a <b>passive income</b>. So, what are you waiting for? Check out our <b>lending, liquidity mining</b> and <b>staking</b> products, and start “baking” those bitcoins into cash flow-generating assets. Happy baking!',
      }],
      quiz: {
        question: 'Which of the following products provide long-time bitcoin investors with amazing rewards?',
        answer: 'D',
        options: [{
          option: 'A',
          description: 'Lending',
          explanation: 'Sorry, wrong answer. Lending is not the only means of making money with bitcoin.',
        }, {
          option: 'B',
          description: 'Liquidity Mining',
          explanation: 'Sorry, wrong answer. Liquidity Mining is not the only means of making money with bitcoin.',
        }, {
          option: 'C',
          description: 'Staking (once you swap your bitcoin for DFI)',
          explanation: 'Sorry, wrong answer. Staking (once you swap your bitcoins for DFIs) is not the only means of making money with bitcoin.',
        }, {
          option: 'D',
          description: 'All of the above',
          explanation: 'Correct answer!',
        }],
      },
    },
  ],
};
