import BigNumber from 'bignumber.js';
import { BigNumberConstructable } from '../lib/customType';
import localizeBigNumber from './localizeBigNumber';

export default function convertUSDtoBTC(usdBalance: BigNumberConstructable, usdPricePerBTC: BigNumberConstructable, decimals?: number): string {
  const usdBN = new BigNumber(usdBalance);
  const usdBtcRateBN = new BigNumber(usdPricePerBTC);

  if (usdBN.isNaN() || usdBtcRateBN.isNaN() || usdBN.isZero() || usdBtcRateBN.isZero()) {
    return '';
  }

  return localizeBigNumber(usdBN.dividedBy(usdBtcRateBN), false, decimals);
}
