import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  setUserInputCode,
  setIsReferralCodeInput,
  setRevealInfoToReferrer,
  resetUserInputCode,
  setCountry,
  setAge,
} from 'views/Pages/Authentication/authSlice';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import Switch from 'views/components/Switch';
import TextInput from 'common/components/TextInput/TextInput';
import ReferralUI from 'auth/components/ReferralUI';
import { getQueryParam } from 'utils/getQueryParam';
import { storage } from 'storage/sessionStorage';
import Select from 'common/components/Select/Select';
import fetchWithSpinner from 'utils/fetchWithSpinner';
import sdk from '../../../sdk';

const useStyles = createUseStyles({
  inputField: {
    marginBottom: Variables.large,
  },
  emailLabel: {
    marginBottom: Variables.large,
    fontWeight: '500',
    textAlign: 'center',
  },
  revealContainer: {
    position: 'relative',
  },
  switch: {
    padding: 0,
  },
  hidden: {
    display: 'none',
  },
  staticText: {
    fontSize: '16px',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
  },
  switchInInput: {
    position: 'absolute',
    top: 0,
    right: '10px',
    marginTop: '5px',
  },
  staticTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.5em 1em',
    gap: Variables.small,
    border: Variables.primaryBorder,
    background: Variables.white,
    boxShadow: 'rgb(0 0 0 / 4%) 0px 8px 16px 0px',
    borderRadius: '8px',
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    staticText: {
      fontSize: Variables.fontSize.small,
      marginLeft: '12px',
    },
  },
});

const PromoAndReferralInput = ({ referralfield, t, onRegister, isShowEmail = true }) => {
  const classes = useStyles();
  const [revealPromoInput, setRevealPromoInput] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const {
    userInputCode,
    isReferralCodeInput,
    revealInfoToReferrer,
    country,
  } = useCakeSelector(state => state.auth);
  const { voucherCode } = useCakeSelector(state => state.voucher);

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const isAffiliate = (getQueryParam('utm_source') === 'affiliate') || (storage.getItem('utm_source') === 'affiliate');

  useEffect(() => {
    fetchWhitelistedCountries();
  }, []);

  useEffect(() => {
    if (country) {
      const countryOption = countryOptions.find(item => item.value === country);
      setSelectedCountry(countryOption);
    }
  }, [country, countryOptions]);

  const fetchWhitelistedCountries = async () => {
    try {
      const onboardingCountries = await fetchWithSpinner(() => sdk.OnboardingCountriesApi.getWhitelistedCountries(), { showAlert: false });
      const formattedCountries = onboardingCountries.filter(c => c?.onboarding).map((countryDetail) => ({
        label: countryDetail.name,
        value: countryDetail.codeAlpha2,
      }));
      setCountryOptions(formattedCountries);
    } catch (err) {
      window.cakepool.showAlert('error', <Trans>{err.message}</Trans>);
    }
  };

  const onChangeReferralCode = async (e) => {
    await dispatch(setUserInputCode(e));
    if (!Number.isNaN(Number(userInputCode))) {
      await dispatch(setIsReferralCodeInput(true));
    } else {
      await dispatch(setIsReferralCodeInput(false));
    }
  };

  const onInputKeyPress = (e) => {
    if (e.keyCode === 13) { // enter
      onRegister();
    }
  };

  const onChangeShowRevealPromoInput = (event) => {
    setRevealPromoInput(!revealPromoInput);
  };

  useEffect(() => {
    if (!revealPromoInput) {
      dispatch(resetUserInputCode());
    }
  }, [dispatch, revealPromoInput]);

  return (
    <div>
      <div className={classes.inputField}>
        <Select
          isSearchable
          options={countryOptions}
          value={selectedCountry}
          valueJustify="flex-start"
          onChange={event => dispatch(setCountry(event.value))}
          placeholder={t('Country Of Residence')}
          paddingType="textInput"
          tooltip={t('If your country is not in the list, you will not be able to verify your account.')}
          tabIndex={3}
        />
      </div>
      <div className={classes.inputField}>
        <TextInput
          type="text"
          placeholder={t('Enter your age (optional)')}
          label={'Enter your age (optional)'}
          onChange={event => dispatch(setAge(event))}
          tabIndex={4}
          onKeyDown={e => onInputKeyPress(e)}
          className={classes.hidden}
        />
      </div>
      <div style={referralfield} className={classes.inputField}>
        {
          !voucherCode && <>
            <div>
              {
                revealPromoInput ? (
                  <div className={classes.revealContainer}>
                    <TextInput
                      autoFocus
                      type="text"
                      placeholder={t('Promo or referral code (optional)')}
                      label={'Promo or referral code (optional)'}
                      value={userInputCode}
                      onChange={event => onChangeReferralCode(event)}
                      tabIndex={2}
                      onKeyDown={e => onInputKeyPress(e)}
                      disabled={!revealPromoInput}
                      className={!revealPromoInput ? classes.hidden : ''}
                    />
                    <Switch
                      inputRef={inputRef}
                      tabIndex={2}
                      className={classes.switchInInput}
                      isActive={revealPromoInput}
                      onChange={event => onChangeShowRevealPromoInput(event)}
                    >
                    </Switch>
                  </div>
                ) : (
                  <>
                    {!isAffiliate && (
                      <div className={classes.staticTextWrapper}>
                        <div className={classes.staticText}>
                          <Trans>I have a promo/referral code</Trans>
                        </div>
                        <Switch
                          inputRef={inputRef}
                          tabIndex={3}
                          className={classes.switch}
                          isActive={revealPromoInput}
                          onChange={event => onChangeShowRevealPromoInput(event)}
                        >
                        </Switch>
                      </div>
                    )}
                  </>
                )
              }
            </div>

            {isReferralCodeInput && userInputCode.length > 5 && <ReferralUI
              showReferralCodeMessage={false}
              referralCodeText={!Number.isNaN(Number(userInputCode)) ? {} : { display: 'none' }}
              revealInfoToReferrer={revealInfoToReferrer}
              sessionReferralCode={userInputCode}
              onReveal={event => dispatch(setRevealInfoToReferrer(event))}
            />}
          </>
        }
      </div>
    </div>
  );
};

export default PromoAndReferralInput;
