import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  show: false,
  props: null,
};

const manageLiquiditySlice = createSlice({
  name: 'manageLiquidity',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.show = true;
      state.props = action.payload;
    },
    hideModal: (state) => {
      state.show = false;
      state.props = {};
    },
  },
});

export const { showModal, hideModal } = manageLiquiditySlice.actions;

export default manageLiquiditySlice.reducer;
