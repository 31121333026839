import Variables, { getMaxWidthBreakPoint } from '../Variables.styles';

export default {
  modalWrapper: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 1040,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  modalContainer: {
    margin: '8rem auto',
    maxWidth: '975px',
    position: 'relative',
    backgroundColor: Variables.white,
    border: '0.5px solid rgba(0, 0, 0, 0.16)',
    borderRadius: Variables.small,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.8), 0 4px 16px 0 rgba(0, 0, 0, 0.08)',
  },
  modalHeader: {
    display: 'flex',
    padding: `${Variables.small} ${Variables.large}`,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalTitle: {
    fontSize: '2rem',
    lineHeight: '3.2rem',
    fontWeight: 600,
    marginBottom: 0,
    '&-withCoin': {
      margin: '0',
      lineHeight: Variables.large,
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.lg)]: {
    modalContainer: {
      margin: Variables.medium,
    },
  },
};
