import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const PAGE_PADDING = 2;
export default class CustomPagination extends Component {
  changePageTo(page) {
    const {
      onChange,
      currentPage,
      totalPages,
    } = this.props;
    if (page < 1 || currentPage > totalPages) {
      return null;
    }
    onChange(page);
  }

  getPaddedStart() {
    const {
      currentPage,
    } = this.props;
    return Math.max(currentPage - PAGE_PADDING, 1);
  }

  getPaddedEnd() {
    const {
      currentPage,
      totalPages,
    } = this.props;
    return Math.min(currentPage + PAGE_PADDING, totalPages);
  }

  render() {
    return (
      <Pagination
        className="pagination-justify-center"
      >
        {this.renderGoPrevious()}
        {this.renderStartPadding()}
        {this.renderPaginationItems()}
        {this.renderEndPadding()}
        {this.renderGoNext()}
      </Pagination>
    );
  }

  renderGoPrevious() {
    const {
      currentPage,
    } = this.props;
    const isDisabled = currentPage === 1;
    return (
      <PaginationItem disabled={isDisabled}>
        <PaginationLink previous tag="button" onClick={() => this.changePageTo(currentPage - 1)}>Previous</PaginationLink>
      </PaginationItem>
    );
  }

  renderGoNext() {
    const {
      currentPage,
      totalPages,
    } = this.props;
    const isDisabled = currentPage === totalPages;
    return (
      <PaginationItem disabled={isDisabled}>
        <PaginationLink next tag="button" onClick={() => this.changePageTo(currentPage + 1)}>Next</PaginationLink>
      </PaginationItem>
    );
  }

  renderPaginationItems() {
    const items = [];
    const start = this.getPaddedStart();
    const end = this.getPaddedEnd();
    for (let i = start; i <= end; i += 1) {
      items.push(this.renderPaginationItem(i));
    }
    return items;
  }

  renderStartPadding() {
    const shouldEndAt = 1 + PAGE_PADDING;
    const paddedStart = this.getPaddedStart();
    const end = Math.min(paddedStart, shouldEndAt);
    const items = [];
    for (let i = 1; i <= end - 1; i += 1) {
      items.push(this.renderPaginationItem(i));
    }
    if (paddedStart > shouldEndAt) {
      items.push(renderPaginationDots());
    }
    return items;
  }

  renderEndPadding() {
    const {
      totalPages,
    } = this.props;
    const shouldStartAt = totalPages - PAGE_PADDING;
    const paddedEnd = this.getPaddedEnd();
    const start = Math.max(paddedEnd, shouldStartAt);
    const items = [];
    for (let i = start + 1; i <= totalPages; i += 1) {
      items.push(this.renderPaginationItem(i));
    }
    if (paddedEnd < shouldStartAt) {
      items.unshift(renderPaginationDots());
    }
    return items;
  }

  renderPaginationItem(page) {
    const {
      currentPage,
    } = this.props;
    const isActive = page === currentPage;
    const key = `pagination-${page}`;
    return (
      <PaginationItem key={key} active={isActive}>
        <PaginationLink tag="button" onClick={() => this.changePageTo(page)}>{page}</PaginationLink>
      </PaginationItem>
    );
  }
}

function renderPaginationDots() {
  return (
    <PaginationItem key={uuidv4()}>
      <PaginationLink tag="button">...</PaginationLink>
    </PaginationItem>
  );
}
