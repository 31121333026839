import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import sdk from 'sdk';
import { BtcPizzaDayPriceAssumption } from '@cakedefi/cake-sdk/schema/campaign';
import { BtcPizzaDayState } from './btcPizzaDayTypes';

const initialState: BtcPizzaDayState = {
  state: 'UNLOADED',
  isCampaignStillActive: false,
  redemptionDetails: {},
  hasUserParticipatedInCampaign: false,
  isUserEligibleForCampaign: true,
  bonusTiers: [],
  error: null,
  retiredOn: '',
  activeFrom: '',
  campaignName: '',
  btcPrice: '',
  dfiPrice: '',
  isVisualsActive: false,
};

export const fetchBtcPizzaDayCampaign = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/btcPizzaDay',
  async () => {
    const result = await sdk.CampaignApi.getDetails();
    return result;
  },
);

export const fetchBtcPizzaDayPricesForUser = createAsyncThunk<any, null, ThunkApiConfig>(
  'campaigns/btcPizzaDayAuthenticated',
  async () => {
    const result = await sdk.CampaignApi.getBtcPizzaDayAssumptionForUser();
    return result;
  },
);

export const submitBtcPizzaDayPrices = createAsyncThunk<any, BtcPizzaDayPriceAssumption, ThunkApiConfig>(
  'campaigns/btcPizzaDay/submit',
  async (payload) => {
    const result = await sdk.CampaignApi.submitBtcPizzaDayAssumption(payload);
    return result;
  },
);

const btcPizzaDayCampaignSlice = createSlice({
  name: 'BtcPizzaDayCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBtcPizzaDayCampaign.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchBtcPizzaDayCampaign.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.isCampaignStillActive = action.payload?.campaignName === 'BTC Pizza Day - 2023' && action.payload?.isCampaignStillActive;
      state.campaignName = action.payload?.campaignName;
      state.retiredOn = action.payload?.retiredOn;
      state.activeFrom = action.payload?.activeFrom;
      state.isVisualsActive = action.payload?.isVisualsActive;
      state.error = null;
    });
    builder.addCase(fetchBtcPizzaDayCampaign.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
    builder.addCase(submitBtcPizzaDayPrices.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(submitBtcPizzaDayPrices.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.error = null;
    });
    builder.addCase(submitBtcPizzaDayPrices.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.error.message;
    });
    builder.addCase(fetchBtcPizzaDayPricesForUser.pending, (state) => {
      state.state = 'LOADING';
      state.error = null;
    });
    builder.addCase(fetchBtcPizzaDayPricesForUser.fulfilled, (state, action) => {
      state.state = 'LOADED';
      state.error = null;
      state.btcPrice = action.payload?.btcPrice;
      state.dfiPrice = action.payload?.dfiPrice;
    });
    builder.addCase(fetchBtcPizzaDayPricesForUser.rejected, (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
    });
  },
});

export default btcPizzaDayCampaignSlice.reducer;
