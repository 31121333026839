import { FeatureInaccessibleReason } from '../lib/customType';

const reasonToMessage: Record<FeatureInaccessibleReason, string> = {
  KycRequired: 'Finish the Full Tier Account Verification to unlock this feature.',
  LiteKycRequired: 'Finish the Lite Tier Account Verification to unlock this feature.',
  DepositLimitReached: 'You have reached your deposit limit. Please complete the Full Tier Account Verification to unlock this action.',
  TwoFARequired: 'Enable two-factor authentication to unlock this feature',
};

export default function getMessageForUser(reason: FeatureInaccessibleReason): string {
  return reasonToMessage[reason];
}
