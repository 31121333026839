import { btcContent } from './btcContent';
import { dfiContent } from './dfiContent';
import { ethContent } from './ethContent';

export interface Part {
  partIndex: number;
  content: string;
  image: string;
}

export interface QuizOption {
  option: string;
  description: string;
  explanation: string;
}

export interface Quiz {
  question: string;
  answer: string;
  options: QuizOption[]
}

export interface Lesson {
  rewardInUsd: number,
  quizQuestionId: string; // used for api call
  lessonIndex: number; // must be incremental and unique, used for labelling and sending to next page
  title: string;
  parts: Part[];
  quiz: Quiz;
}

export type RewardType = 'FrozenStakingShare' | 'FrozenLiquidityMiningShare';

interface BaseStack {
  id: string; // used for api call
  coinId: string;
  coinName: string;
  description: string;
  rewardType: RewardType,
}

export interface ComingSoonStack extends BaseStack {
  isAvailable: false;
}

export interface Stack extends BaseStack {
  isAvailable: true;
  welcomeImage: string;
  lessons: Lesson[]
}

/*
Note when adding new quiz/lesson:
  - make sure quiz data is added to QuizQuestion table in database - Stack's id = table's quizId, Lesson's quizQuestionId = table's id
  - make sure both Stack's id and Lesson's quizQuestionId do not have underscore, core will split register's learnAndEarnCode with underscore

Design considerations:
  - id and coinId in Stack are separated incase there are multiple stacks with same coinId
*/

const learnEarnContent: Record<string, Stack | ComingSoonStack> = {
  DFI: dfiContent,
  BTC: btcContent,
  ETH: ethContent,
};

export default learnEarnContent;
