import React from 'react';

const topics = [
  {
    title: 'Introduction - What is the WELCOME50 promotion?',
    content: <div>
      <p>The “WELCOME50” (“Promotion”) is a limited time bonus offered by Cake Pte Ltd (the “Company”) to
        its
        users. This promotion gives a new user a ​<b>5% deposit bonus in staking shares</b> ​(“Bonus Staking Shares”) for deposits and
        subsequent share purchases made with the framework as set out below.</p>
      <p>The bonus staking shares, upon the deposit from the user and subsequent purchase of staking shares will be applied
        automatically to the users account.</p>
    </div>,
  },

  {
    title: 'Who can get the WELCOME50 bonus staking shares?',
    content: <p>
      Every new user is eligible to receive the promotion during the first 3 months (90 days) from <b>​signup
      date</b> for
      deposits into his/her account and subsequent share purchase.
    </p>,
  },

  {
    title: 'Is there a maximum deposit value I can get the bonus staking shares on?',
    content: <p>
      Yes, the maximum is $1000 of equivalent deposit value (i.e. up to $50 bonus). Conversion rates will be
      calculated by the Company at its sole discretion.
    </p>,
  },

  {
    title: 'Can I make several deposits and still enjoy the bonus staking shares?',
    content: <p>
      Yes, you can do as many or as few deposits as you like and will be able to receive the promotion up to the maximum
      set out above within the promotion time frame. Each eligible deposit will receive the bonus staking shares automatically
    </p>,
  },

  {
    title: 'Can I sell or withdraw the bonus staking shares?',
    content: <p>Bonus staking shares are locked for 180 days (calculated from the ​<b>corresponding deposit date​</b>).</p>,
  },

  {
    title: 'Can I do withdrawals beside the bonus staking shares?',
    content: <p>
      Of course you are free to withdraw your funds anytime. Please do note that in order to avoid any type of trickery
      any withdrawals within the first 30 days from your <b>​first deposit date​</b> will void all bonus staking shares and its
      profits.
    </p>,
  },

  {
    title: 'Change of Promotion',
    content: <div>
      <p>The offered promotion is subject to change. The company may, at its sole discretion, revise these Terms and
        Conditions (including but not limited to varying the Promotional Period) or withdraw this Promotion at any time
        without prior notice. Any abuse of this Promotion may result in the bonus and bonus generated profits being
        voided at the Company’s sole discretion.</p>
      <p>These Terms and Conditions are governed by the laws of the Republic of Singapore and the parties submit to the
        non-exclusive jurisdiction of the courts of Singapore.</p>
      {/* End Date */}
      {/* <p style={{ color: 'red' }}>This promotion is ending on Monday, 26 August 2019 12:00 noon Singapore Time.</p> */}
    </div>,
  },
];

export default topics;
