import { Currency } from '../lib/customType';

const CurrSymMap: Record<Currency, string> = {
  USD: '$',
  EUR: '€',
  BTC: 'BTC',
  CNY: '¥',
  SGD: 'S$',
  CHF: 'CHF',
  AUD: 'AU$',
  MYR: 'RM',
  CAD: 'CA$',
  GBP: '£',
};

export default function getCurrencySymbol(currency?: Currency): string {
  return currency ? CurrSymMap[currency] : CurrSymMap.USD;
}
