import React from 'react';
import { createUseStyles } from 'react-jss';
import { MdPrivacyTip, MdStars } from 'react-icons/md';
import { AiFillClockCircle } from 'react-icons/ai';
import { Trans } from 'react-i18next';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { kycBenefitList } from '../../../MainLayout/components/AccountVerificationBanner/BottomKycBenefits';

const useStyles = createUseStyles({
  kycDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Variables.medium,
    marginTop: Variables.medium,
  },
  kycDetail: {
    backgroundColor: '#F5F4F7',
    width: '100%',
    padding: Variables.medium,
    borderRadius: Variables.small,
    display: 'flex',
  },
  kycDetailDescription: {
    paddingLeft: Variables.small,
    fontWeight: Variables.fontWeight.semiBold,
    fontSize: '14px',
    textAlign: 'left',
    lineHeight: '2rem',
  },
  icon: {
    color: Variables.gray600,
    height: Variables.large,
    width: Variables.large,
    marginRight: Variables.small,
    flex: '0 0 auto',
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    kycDetailsContainer: {
      flexDirection: 'column',
    },
  },
});

const BottomKycBenefitsForModal = () => {
  const classes = useStyles();

  return (
    <div className={classes.kycDetailsContainer}>
        <div className={classes.kycDetail}>
          <MdStars className={`${classes.icon} mr-0`}/>
          <div className={classes.kycDetailDescription}>
            <Trans>{kycBenefitList[0]}</Trans>
          </div>
        </div>
        <div className={classes.kycDetail}>
          <MdPrivacyTip className={`${classes.icon} mr-0`}/>
          <div className={classes.kycDetailDescription}>
            <Trans>{kycBenefitList[1]}</Trans>
          </div>
        </div>
        <div className={classes.kycDetail}>
          <AiFillClockCircle className={`${classes.icon} mr-0`}/>
          <div className={classes.kycDetailDescription}>
            <Trans>{kycBenefitList[2]}</Trans>
          </div>
        </div>
      </div>
  );
};

export default BottomKycBenefitsForModal;
