import React from 'react';
import { ModalContent, ModalFooter } from 'modals/components';
import { Trans } from 'react-i18next';
import ButtonStyles from 'common/styles/Button.styles';
import ModalStyles from 'common/styles/Modal.styles';
import { createUseStyles } from 'react-jss';
import Button from 'common/components/Button/Button';
import Variables, { getMaxWidthBreakPoint } from 'Variables.styles';
import { RegisterConsentStage, setAcceptedDataConsent } from 'views/Pages/Authentication/authSlice';
import { useDispatch } from 'react-redux';
import PersonalDataConsentTerms from 'common/components/PersonalDataConsentTerms/PersonalDataConsentTerms';

const useStyles = createUseStyles({
  ...ModalStyles,
  ...ButtonStyles,
  contentContainer: {
    minHeight: '550px',
  },
  paragraphHeader: {
    fontSize: '1.8rem',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '2.4rem',
    marginBottom: '1.6rem',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: Variables.small,
    marginLeft: Variables.small,
    marginRight: Variables.small,
  },
  footerRightButtons: {},
  button: {
    marginRight: Variables.small,
  },
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerRightButtons: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      marginRight: 0,
      width: '100%',
      marginBottom: Variables.small,
    },
  },
});

const PersonalDataConsent = ({ goto }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickAgree = () => {
    dispatch(setAcceptedDataConsent(true));
    goto(RegisterConsentStage.CONSENTS_PAGE);
  };

  return (
    <>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.contentContainer}`}>
          <br />
          <h3 className={classes.paragraphHeader}><Trans>Data collected and processed</Trans></h3>
          <PersonalDataConsentTerms />
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={classes.footerContainer}>
          <Button
            outline
            className={classes.button}
            btnLink
            type="button"
            tabIndex={5}
            onClick={() => goto(RegisterConsentStage.CONSENTS_PAGE)}
          ><Trans>Back</Trans></Button>
          <div className={classes.footerRightButtons}>
            <Button
              outline
              className={classes.button}
              btnLink
              type="button"
              tabIndex={5}
              onClick={() => goto(RegisterConsentStage.CONSENTS_PAGE)}
            ><Trans>I don't agree</Trans></Button>
            <Button
              outline
              primary
              type="button"
              tabIndex={5}
              onClick={() => onClickAgree()}
            ><Trans>I Agree</Trans></Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default PersonalDataConsent;
