import { createSlice } from '@reduxjs/toolkit';

interface IUserAppPreferenceInitialState {
  isActive: boolean,
  title: string;
  buttonLabel: string;
  iconName: string,
  buttonCallback: () => void;
}

const initialState: IUserAppPreferenceInitialState = {
  isActive: false,
  title: '',
  buttonLabel: '',
  iconName: '',
  buttonCallback: () => false,
};

const popupBannerSlice = createSlice({
  name: 'userAppPreference',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setButtonLabel: (state, action) => {
      state.buttonLabel = action.payload;
    },
    setIconName: (state, action) => {
      state.iconName = action.payload;
    },
    setiIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    setButtonCallback: (state, action) => {
      state.buttonCallback = action.payload;
    },
    showPopupBanner: (state, action) => ({ ...action.payload }),
    hidePopupBanner: () => ({ ...initialState }),
  },
});

export const { showPopupBanner, hidePopupBanner } = popupBannerSlice.actions;

export default popupBannerSlice.reducer;
