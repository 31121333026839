/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { isEmpty } from '../../utils';

class DatePickerInput extends Component {
  render() {
    const id = uuidv4();
    return (
      <label htmlFor={id}>
        <input id={id} value={this.props.value} onClick={this.props.onClick} type="text" placeholder={this.props.placeholder} readOnly />
        <span className="label">{this.props.label}</span>
      </label>
    );
  }
}

export default class DateTime extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rangeStart: PropTypes.bool,
    rangeEnd: PropTypes.bool,
    modalType: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    dateFormat: PropTypes.string,
    withTime: PropTypes.bool,
    timeOnly: PropTypes.bool,
    timeIntervals: PropTypes.number,
  }

  static get defaultProps() {
    return {
      onChange() { },
      rangeStart: false,
      rangeEnd: false,
      modalType: true,
      withTime: false,
      timeOnly: false,
      timeIntervals: 15,
      dateFormat: 'dd/MM/yyyy h:mm aa',
    };
  }

  renderHelpText() {
    const { helpText } = this.props;
    if (isEmpty(helpText)) return null;
    return <small className="help-text">{helpText}</small>;
  }

  render() {
    return (
      <div tabIndex={this.props.tabIndex} className="datepicker-input-group input-group">
        <DatePicker
          customInput={<DatePickerInput label={this.props.label} placeholder={this.props.placeholder} />}
          selected={this.props.value}
          onChange={this.props.onChange}
          selectsStart={this.props.rangeStart}
          selectsEnd={this.props.rangeEnd}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          minDate={this.props.minDate}
          dateFormat={this.props.dateFormat}
          withPortal={this.props.modalType}
          showTimeSelect={this.props.withTime}
          showTimeSelectOnly={this.props.timeOnly}
          timeIntervals={this.props.timeIntervals}
          showMonthDropdown={this.props.showMonthDropdown}
          showYearDropdown={this.props.showYearDropdown}
        />
        {this.renderHelpText()}
      </div>
    );
  }
}
