import React from 'react';
import PropTypes from 'prop-types';

const MouseOverTooltip = ({ className = '', children, renderTooltip, ...props }) => {
  const onMouseOver = e => window.cakepool.showTooltip({ renderContent: renderTooltip }, e);
  const onMouseOut = () => window.cakepool.hideTooltip();
  return (
    <span
      className={className}
      onMouseOver={e => onMouseOver(e)}
      onMouseOut={onMouseOut}
      {...props}>
        {children}
      </span>
  );
};

MouseOverTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderTooltip: PropTypes.func,
};

MouseOverTooltip.defaultProps = {
  className: '',
};

export default MouseOverTooltip;
