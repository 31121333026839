import { Styles } from 'react-jss';
import Color from 'color';
import { BannerType } from '../../../types/banner/banner';
import Variables, { getMinWidthSm } from '../../../Variables.styles';

function generateBackgroundColorByType(type: BannerType) {
  switch (type) {
    case BannerType.Warning:
      return '#fcffa8';
    case BannerType.NotAvailable:
      return 'rgba(41, 98, 255, 0.08)';
    case BannerType.Danger:
      return 'rgba(255, 0, 0, 0.08)';
    case BannerType.Important:
      return Variables.electricBerry;
    case BannerType.Announcement:
      return Variables.hyperBerry;
    case BannerType.Notification:
      return 'rgb(41, 98, 255, 0.08)';
    case BannerType.Alert:
      return 'rgb(209, 162, 9, 0.08)';
    case BannerType.Normal:
      return Variables.white;
    case BannerType.Info:
    default:
      return '#EAFFFF';
  }
}

function generateColorByType(type: BannerType) {
  switch (type) {
    case BannerType.Danger:
      return '#FF0000';
    case BannerType.Important:
      return Variables.white;
    default:
      return Variables.black;
  }
}

function getPaddingByType(type: BannerType) {
  switch (type) {
    case BannerType.Notification:
      return '0';
    case BannerType.Alert:
      return '0';
    default:
      return '0 20px';
  }
}

const styles: Styles = {
  wrapper: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: ({ justifyContent }) => justifyContent,
    padding: ({ type }) => getPaddingByType(type),
    backgroundColor: ({ type, bannerBackgroundColor }) => (bannerBackgroundColor || generateBackgroundColorByType(type)),
    border: ({ type, bannerBackgroundColor }) => {
      const backgroundColor = bannerBackgroundColor || generateBackgroundColorByType(type);
      const borderColor = Color(backgroundColor).darken(0.1);
      return `1px solid ${borderColor}`;
    },
    borderRadius: Variables.small,
    color: ({ type }) => generateColorByType(type),
    fontSize: '1.6rem',
    fontWeight: 500,
    '& > a': {
      color: Variables.electricBerry,
    },
  },
  content: ({ contentMarginLeft = Variables.medium, type = BannerType.Info, flexBoxForContainer }) => ({
    marginLeft: contentMarginLeft,
    padding: `${Variables.medium} 0`,
    display: flexBoxForContainer ? 'flex' : undefined,
    flex: flexBoxForContainer ? 1 : undefined,
  }),
  action: {
    marginLeft: Variables.medium,
    padding: '10px 0',
    display: 'flex',
    flexBasis: '35%',
    justifyContent: 'flex-end',
  },
  [getMinWidthSm]: {
    wrapper: {
      flexDirection: 'row',
    },
  },
};

export default styles;
