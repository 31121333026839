import { Styles } from 'react-jss';
import Variables, { getMaxWidthBreakPoint } from '../../../Variables.styles';
import { ButtonProps } from './ButtonProps';

const primaryBg = 'rgb(241, 233, 254)';

const getBaseButton = ({ block, textTransform }: ButtonProps) => ({
  display: block ? 'block' : 'inline-block',
  width: block ? '100%' : 'auto',
  borderRadius: Variables.extraSmall,
  padding: `${Variables.small} ${Variables.medium}`,
  textTransform: textTransform ?? 'uppercase',
  transition: 'all .3s ease',
  fontSize: '1.6rem',
  fontWeight: 500,
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  '&:focus': {
    outline: 'none',
  },
});

const getPadding = (props) => {
  if (props.small) {
    return '6px 16px';
  }
  if (props.medium) {
    return '10px 16px';
  }
  if (props.mediumWide) {
    return '10px 20px';
  }
  return '14px 16px';
};

const getStylesWithTheme = (props: ButtonProps, buttonType: string) => {
  const { disabled, pink, ...allStyles } = buttonsStyles[buttonType];
  if (props.disabled) {
    return {
      ...allStyles,
      ...disabled,
    };
  }
  if (props.appTheme === 'DEFICHAIN') {
    return pink;
  }
  return allStyles;
};

const buttonsStyles = {
  primary: {
    color: Variables.electricBerry,
    backgroundColor: primaryBg,
    border: '1px solid rgba(91,16,255,.08)',
    disabled: {
      color: 'rgba(255, 255, 255, 0.6)',
      backgroundColor: Variables.gray200,
    },
    pink: {
      color: Variables.pink,
      backgroundColor: Variables.pink100,
      border: `1px solid ${Variables.pink80}`,
    },
  },
  main: {
    color: Variables.white,
    backgroundColor: Variables.electricBerry,
    border: '1px solid rgba(91,16,255,.08)',
    disabled: {
      color: 'rgba(255, 255, 255, 0.6)',
      backgroundColor: Variables.gray200,
    },
  },
  btnLink: {
    color: '#6400FF',
    disabled: {
      color: Variables.gray300,
    },
    pink: {
      color: Variables.pink,
    },
  },
  borderless: {
    color: Variables.electricBerry,
    pink: {
      color: Variables.pink,
    },
  },
  heroPrimary: {
    backgroundColor: Variables.electricBerry,
    color: Variables.superLightBlue,
  },
};

const styles: Styles = {
  borderless: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    background: 'none',
    border: '0',
    opacity: props.disabled ? 0.4 : 1,
    whiteSpace: '',
    ...getStylesWithTheme(props, 'borderless'),
  }),
  primary: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    whiteSpace: 'nowrap',
    padding: getPadding(props),
    opacity: props.disabled ? '0.65' : '1',
    ...getStylesWithTheme(props, 'primary'),
  }),
  main: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    whiteSpace: 'nowrap',
    padding: getPadding(props),
    opacity: props.disabled ? '0.65' : '1',
    ...getStylesWithTheme(props, 'main'),
  }),
  outline: {
    '&:focus': {
      outline: 'auto !important',
      outlineColor: `${Variables.electricBerry} !important`,
    },
  },
  clear: () => ({
    background: Variables.white,
    color: Variables.electricBerry,
    border: Variables.primaryBorder,
    padding: `${Variables.small} ${Variables.large}`,
    letterSpacing: '0.86px',
    width: 'auto',
  }),
  [getMaxWidthBreakPoint(Variables.breakpoints.sm)]: {
    clear: () => ({
      letterSpacing: 0,
      fontSize: Variables.fontSize.small,
    }),
  },
  secondary: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    backgroundColor: props.disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)',
    color: props.disabled ? 'rgba(255, 255, 255, 0.6)' : Variables.electricBerry,
    border: props.disabled ? '0' : Variables.primaryBorder,
  }),
  danger: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    whiteSpace: 'nowrap',
    padding: getPadding(props),
    backgroundColor: props.disabled ? Variables.gray200 : 'rgba(255,0,0,0.08)',
    border: '1px solid rgba(255, 0, 0, 0.08)',
    color: props.disabled ? 'rgba(255, 255, 255, 0.6)' : Variables.red,
    opacity: props.disabled ? '0.65' : '1',
  }),
  btnLink: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    backgroundColor: Variables.white,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    textDecoration: 'none',
    padding: getPadding(props),
    ...getStylesWithTheme(props, 'btnLink'),
    '&:hover': {
      backgroundColor: '#ebebeb',
    },
  }),
  heroPrimary: (props: ButtonProps) => ({
    extend: getBaseButton(props),
    whiteSpace: 'nowrap',
    padding: getPadding(props),
    opacity: props.disabled ? '0.65' : '1',
    lineHeight: '24px',
    ...getStylesWithTheme(props, 'heroPrimary'),
  }),
};

export default styles;
