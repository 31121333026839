import { createSlice } from '@reduxjs/toolkit';

interface ZendeskInitialState {
  isReady: boolean;
}

const initialState: ZendeskInitialState = {
  isReady: false,
};

const zendeskSlice = createSlice({
  name: 'zendesk',
  initialState,
  reducers: {
    setIsReady: (state, action) => {
      state.isReady = action.payload;
    },
  },
});

export const { setIsReady } = zendeskSlice.actions;

export default zendeskSlice.reducer;
