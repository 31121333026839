import { isAmountWithinPaymentMethodLimit } from './isAmountWithinPaymentMethodLimit';

export const getMinMaxForPaymentMethod = (min: string, max: string, fiatAmount: number) => {
  const paymentMethodMinAmount = Number(min);
  const paymentMethodMaxAmount = Number(max);
  return {
    paymentMethodMinAmount,
    paymentMethodMaxAmount,
    isFiatAmountValid: isAmountWithinPaymentMethodLimit(
      paymentMethodMinAmount,
      paymentMethodMaxAmount,
      fiatAmount,
    ),
  };
};
