import Variables from '../Variables.styles';

export default {
  wrapper: {
    display: 'flex',
    position: 'fixed',
    top: Variables.large,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
  },
  messageContainer: {
    boxShadow: Variables.primaryBoxShadow,
    border: Variables.primaryBorder,
    borderRadius: Variables.extraSmall,
    padding: Variables.medium,
    backgroundColor: Variables.white,
    fontSize: '1.6rem',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    color: Variables.redInvalidControl,
    fontSize: '2rem',
    marginRight: Variables.extraSmall,
  },
};
