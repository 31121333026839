import moment from 'moment';

interface DateFormat {
  default: string;
  [x: string]: string;
}

export default function getFormattedDateByLanguage(date: Date | string, language: string, format: DateFormat): string {
  return moment(date).format(format[language] || format.default);
}
