import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import modalStyles from '../../../common/styles/Modal.styles';
import fiatBuyStyles from './FiatBuyModal.styles';
import ConfirmationPage from './ConfirmationPage';
import InitialPage from './InitialPage';
import FiatSwappableConfirmationPage from './FiatSwappableConfirmationPage';

export const useModalStyles = createUseStyles(modalStyles);
export const useFiatBuyStyles = createUseStyles(fiatBuyStyles);

export const INITIAL = 'initial';
export const SWAPPABLE_CONFIRMATION = 'swappableConfirmation';
export const CONFIRMATION = 'confirmation';

type FiatBuyModalProps = {
  selectedCoin: string;
  onClose: () => void;
}

const FiatBuyModal = ({ onClose, selectedCoin }: FiatBuyModalProps) => {
  const [currentPage, goTo] = useState(INITIAL);

  switch (currentPage) {
    case SWAPPABLE_CONFIRMATION:
      return <FiatSwappableConfirmationPage goTo={goTo} />;

    case CONFIRMATION:
      return <ConfirmationPage goTo={goTo} onClose={onClose} />;
    case INITIAL:
    default:
      return <InitialPage goTo={goTo} onClose={onClose} selectedCoin={selectedCoin} />;
  }
};

export default FiatBuyModal;
