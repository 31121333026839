import React from 'react';
import { createUseStyles } from 'react-jss';
import Variables from 'Variables.styles';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { IoMdInformationCircle } from 'react-icons/io';
import { isLoggedInSelector, userAccessRightSelector } from 'user/userSelector';
import { useCakeSelector } from 'app/rootReducer';
import { AccessRights } from 'types/access-rights/access-rights';

const useStyles = createUseStyles<string>({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0px',
    padding: '16px 24px',
    backgroundColor: 'white',
    boxShadow: Variables.primaryBoxShadow,
    borderRadius: Variables.small,
    ...Variables.bannerTypography,
  },
  iconAndContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
  closeButton: {
    cursor: 'pointer',
  },
});

const RestrictBannerByCountryPromo = () => {
  const hasAccessToPromo = useCakeSelector(userAccessRightSelector(AccessRights.Promos));
  const userHasNoAccessByCountry = !hasAccessToPromo?.access && hasAccessToPromo?.reasons?.includes('CountryRestriction');
  const loggedIn = useCakeSelector(isLoggedInSelector);
  const location = useLocation();
  const showRestrictBannerOnRightPages = ['/learn', '/liquidity-mining', '/lending', '/freezer', '/wallets'].includes(location?.pathname);
  const classes = useStyles();

  if (!showRestrictBannerOnRightPages || !userHasNoAccessByCountry || !loggedIn) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconAndContent}>
        <IoMdInformationCircle
          size={30}
          fill={Variables.warningYellow}
        />
        <div className={classes.content}>
          <div>
            <Trans>
              Learn & Earn rewards in your region have been fully redeemed. You may check with us again for upcoming promotions and announcements. For further information, please read <a href="https://support.bake.io/en/articles/8286093-why-are-bonus-awards-not-available-in-my-region" target="_blank" rel="noreferrer">our FAQ page</a>. Thank you.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictBannerByCountryPromo;
