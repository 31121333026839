import React from 'react';
import BasePage from '../BasePage';
import tncWelcome from '../../assets/data/tnc-welcome10-2019-08-16';

export default class TermsAndConditionsWelcomeComponent extends BasePage {
  render() {
    return (
      <div>
        {
          tncWelcome.map(({ title, content }, index) => <React.Fragment key={index}>
            <h2>{title}</h2>
            <div>{content}</div>
          </React.Fragment>)
        }
      </div>
    );
  }
}
