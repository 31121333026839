import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { HeaderLink } from 'types/header/header-link';
import Variables from 'Variables.styles';
import PizzaIcon from 'assets/svg/btcPizzaDay/pizza-icon.svg';
import { useCakeSelector } from '../../../app/rootReducer';
import {
  getCanShowVisualsSelector,
  getCampaignDataLoadStatus,
} from '../btcPizzaDaySelector';
import { fetchBtcPizzaDayCampaign } from '../btcPizzaDaySlice';

const useStyles = createUseStyles({
  outerWrapperDesktop: {
    display: 'inline-block',
    paddingLeft: Variables.medium,
    verticalAlign: 'middle',
  },
  wrapperDesktop: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  wrapperMobile: {
    display: 'inline-block',
    height: 58,
    cursor: 'pointer',
    padding: '6px 16px',
  },
  container: {
    background: Variables.hyperBerry6,
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Variables.small,
    width: 160,
    height: 36,
  },
  linkWrapper: {
    whiteSpace: 'nowrap',
    lineHeight: Variables.large,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pizzaIcon: {
    width: 20,
    marginRight: 10,
  },
  linkText: {
    color: Variables.white,
  },
});

const BtcPizzaDayHeaderLink = ({ index, isMobile = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const headerLink = new HeaderLink(
    'BTC Pizza Day',
    '/bitcoin-pizza-day',
  );
  const { text, link } = headerLink;
  const campaignDataLoadStatus = useCakeSelector(getCampaignDataLoadStatus);
  const canShowHeaderLink = useCakeSelector(getCanShowVisualsSelector);

  useEffect(() => {
    if (campaignDataLoadStatus === 'UNLOADED') {
      dispatch(fetchBtcPizzaDayCampaign());
    }
  }, [dispatch, campaignDataLoadStatus]);

  if (!canShowHeaderLink) {
    return null;
  }

  return (
    <div className={isMobile ? null : classes.outerWrapperDesktop}>
      <div
        className={isMobile ? classes.wrapperMobile : classes.wrapperDesktop}
        key={`header-link-${index}`}
      >
        <div className={classes.container}>
          <Link to={link} className={classes.linkWrapper}>
            <img src={PizzaIcon} className={classes.pizzaIcon} alt="Pizza Icon" />
            <span className={classes.linkText}><Trans>{text}</Trans></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BtcPizzaDayHeaderLink;
