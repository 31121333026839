import Variables from '../../../Variables.styles';

export default {
  container: {
    marginTop: Variables.larger,
  },
  paragraphTitle: {
    fontWeight: 500,
    marginBottom: Variables.medium,
  },
  paymentServiceDisclaimer: {
    composes: ['text-secondary'],
    marginTop: Variables.medium,
    marginBottom: 0,
    fontSize: '1.6rem',
  },
};
