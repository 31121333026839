import config from '../config/app';

let windowObjectReference = null;
let previousUrl = null;

interface IOpenSiginWindow {
  name: string,
  width: number,
  left: number,
  clientId: string,
}
export const openSignInWindow = async ({ name, width, left, clientId }: IOpenSiginWindow) => {
  const urlToRazer = `${config.LOGIN_URL_TO_RAZER_HEAD}${clientId}${config.LOGIN_URL_TO_RAZER_TAIL}`;

  const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=700, top=200, left=${left}`;

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(urlToRazer, name, strWindowFeatures);
  } else if (previousUrl !== urlToRazer) {
    windowObjectReference = window.open(urlToRazer, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }

  previousUrl = urlToRazer;
};
