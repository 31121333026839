import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default class Select extends Component {
  state = {
    selectedOption: null,
    isOpen: false,
    maxHeight: 300,
    textKey: 'text',
    selectValue: '',
    inputFocus: true,
  };

  id = uuidv4();

  componentDidUpdate(prevProps) {
    const { selectedOption } = this.props;
    if (prevProps.selectedOption !== selectedOption) {
      this.setState({
        selectedOption,
      });
    }
  }

  componentDidMount() {
    this.setState({
      maxHeight: this.props.maxHeight || 300,
      textKey: this.props.textKey || 'text',
      selectedOption: this.props.selectedOption,
    });
    this.clickListener = this.handleOutsideClick.bind(this);
    document.addEventListener('click', this.clickListener);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickListener);
  }

  onClickOption(option) {
    const {
      onChangeOption,
    } = this.props;
    onChangeOption(option);
    this.toggle();
    this.setValue('inputFocus', false);
    this.setValue('selectValue', '');
  }

  toggle() {
    const {
      isOpen,
    } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  show() {
    this.setState({
      isOpen: true,
    });
  }

  hide() {
    this.setState({
      isOpen: false,
    });
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (!this.state.isOpen) {
      return;
    }
    if (this.element.contains(e.target)) {
      return;
    }
    this.hide();
  }

  setValue(key, value) {
    this.setState({
      [key]: value,
    });
    this.forceUpdate();
  }

  render() {
    return (
      <div tabIndex={this.props.tabIndex} className="select " ref={(element) => { this.element = element; }} onKeyDown={e => this.jumpToLetter(e.keyCode)}>
        <div className="select select__text" onClick={() => this.toggle()}>
          {this.getSelectedText()}
          <div className="select__caret flex-center-center">
            <i className="fa fa-caret-down"></i>
          </div>
        </div>
        <span className="label">{this.props.label}</span>
        {this.renderOptions()}
      </div>
    );
  }

  isLetter = str => str.length === 1 && str.match(/[a-z|A-Z]/i);

  jumpToLetter = (keyCode) => {
    const char = String.fromCharCode(keyCode);

    if (!this.isLetter(char)) {
      return;
    }

    const { options } = this.props;

    const firstOptionWhichBeginsWithLetter = options.find(option => option.label.startsWith(char));

    if (!firstOptionWhichBeginsWithLetter) {
      return;
    }

    const element = document.getElementById(`option_${firstOptionWhichBeginsWithLetter.label}_${this.id}`);
    element.scrollIntoView();
  }

  getSelectedText() {
    return this.getText(this.state.selectedOption);
  }

  getText(option) {
    const {
      textKey,
    } = this.state;
    if (!option) return '';

    // look for multilingual keys like text.en and text.de
    if (textKey.includes('.')) {
      const key = textKey.split('.')[0];
      const lang = textKey.split('.')[1];
      return option[key][lang];
    }

    return option[textKey];
  }

  renderOptions() {
    const {
      isOpen,
      maxHeight,
    } = this.state;
    const {
      options,
    } = this.props;
    if (!isOpen) {
      return null;
    }
    const optionElements = [];
    for (let i = 0; i < options.length; i += 1) {
      if (!this.props.inputAutoComplete) {
        optionElements.push(this.renderOption(options[i], i));
      } else if (this.renderOption(options[i], i).props.children.toLowerCase().startsWith(this.state.selectValue.toLowerCase())) {
        optionElements.push(this.renderOption(options[i], i));
      }
    }
    const customStyles = {
      maxHeight,
    };
    return (
      <div className="select select__options" style={customStyles}>
        {optionElements}
      </div>
    );
  }

  renderOption(element, index) {
    const key = `select-item-${index}`;
    return (
      <div
        className="select__option"
        id={`option_${this.getText(element)}_${this.id}`}
        key={key}
        onClick={() => this.onClickOption(element)}>
        {this.getText(element)}
      </div>
    );
  }
}
