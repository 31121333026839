export enum ReferralCampaignType {
  MidAutumn = 'MidAutumn',
  Oktoberfest = 'Oktoberfest',
}

export enum ReferralPromoType {
  RAZER_3 = 'RAZER_3',
  ELEVEN_ELVEN = 'ELEVEN_ELEVEN',
  '11_11_PROMOTION_2022' = '11_11_PROMOTION_2022',
  BLACK_FRIDAY = 'BLACK_FRIDAY_2021',
  TIGER_PROMO = 'TIGER_PROMO_2022',
  EASTER_PROMO = 'EASTER_PROMO_2022',
  THIRD_ANNIVERSARY_PROMO_2022 = '3rd_ANNIVERSARY_PROMO_2022',
  LM_DTOKENS_PROMO_2022 = 'LM_DTOKENS_PROMO_2022',
  PLENTINA_PROMOTION_2022 = 'PLENTINA_PROMOTION_2022',
  ETH_LM_FREEZER_PROMOTION_2022 = 'ETH_LM_FREEZER_PROMOTION_2022',
}

export interface ReferralPromoTemplate {
  heroImageComponent: JSX.Element;
  heroRightComponent: JSX.Element;
  rulesModalContent: JSX.Element;
  referrerBonusPerReferralUsd: number | null,
}
