export default function browserLanguageToi18nLanguage(lang) {
  switch (lang) {
    case 'de':
    case 'de-DE':
    case 'de-AT':
    case 'de-CH':
    case 'de-LI':
    case 'de-LU':
      return 'de';
    case 'cn':
    case 'zh':
    case 'zh-CN':
    case 'zht':
    case 'zh-HK':
    case 'zh-MO':
    case 'zh-TW':
      return 'zht';
    case 'it':
    case 'it-ch':
      return 'it';
    default:
      return 'en';
  }
}
