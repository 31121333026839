import { ModalContent, ModalFooter } from 'modals/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonStyles from 'common/styles/Button.styles';
import ModalStyles from 'common/styles/Modal.styles';
import Variables from 'Variables.styles';
import { Trans } from 'react-i18next';
import Button from 'common/components/Button/Button';
import { RegisterConsentStage, setAcceptedTermsAndConditions } from 'views/Pages/Authentication/authSlice';
import { useDispatch } from 'react-redux';

const useStyles = createUseStyles({
  ...ModalStyles,
  ...ButtonStyles,
  contentContainer: {
    minHeight: '550px',
    paddingTop: Variables.medium,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: Variables.small,
    marginLeft: Variables.small,
    marginRight: Variables.small,
  },
});

const AgeRestrictionConsent = ({ goto }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickAgree = () => {
    dispatch(setAcceptedTermsAndConditions(true));
    goto(RegisterConsentStage.CONSENTS_PAGE);
  };

  return (
    <>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.contentContainer}`}>
          <div>
            <Trans>You must be of legal age of 18 years and above before you can use the Bake Sites and/or the Services. This is due to legal and regulatory requirements.</Trans>
          </div>
          <br/>
          <div>
            <Trans>
              Please take note that we may be required to suspend and/or close your User Account if you do not provide us with true and accurate information.
            </Trans>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
      <div className={classes.footerContainer}>
          <Button
            outline
            className="mr-4"
            btnLink
            type="button"
            tabIndex={5}
            onClick={() => goto(RegisterConsentStage.CONSENTS_PAGE)}
          ><Trans>Back</Trans></Button>
          <div>
            <Button
              outline
              primary
              type="button"
              tabIndex={6}
              onClick={() => onClickAgree()}
            ><Trans>I am 18 years of age and older</Trans></Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default AgeRestrictionConsent;
