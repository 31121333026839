import React, { useState, useEffect } from 'react';

const TwoDigits = ({ num }) => (<>{`${num}`.padStart(2, '0')}</>);

export default function Timer({ initialMinute = 0, initialSecond = 0, delay = 1000, isShowMinutesTwoDigits = false, onTimerFinish = null }) {
  const isSecondsMoreThanAMinute = initialSecond > 59;
  const extraMinutes = isSecondsMoreThanAMinute ? initialMinute + Math.floor(initialSecond / 60) : initialMinute;
  const extraSeconds = isSecondsMoreThanAMinute ? initialSecond % 60 : initialSecond;

  const [minutes, setMinutes] = useState(extraMinutes);
  const [seconds, setSeconds] = useState(extraSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          if (onTimerFinish) {
            onTimerFinish();
          }
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, delay);

    return () => {
      clearInterval(myInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  return (
    <>
      {isShowMinutesTwoDigits ? <TwoDigits num={minutes} /> : minutes}:<TwoDigits num={seconds} />
    </>
  );
}
