/* eslint-disable */
import { CakeClientSdk, SessionStorage, UserTokenType, ClientConfig } from '@cakedefi/cake-sdk';
import { userTokenInfoStorage } from '../storage';
import appConfig from '../config/app';
import { parseStringToBoolean } from 'utils/parseStringToBoolean';

const sdkConfig: ClientConfig = {
  baseUrl: appConfig.API_ENDPOINT,
  accessTokenMaxAge: appConfig.ACCESS_TOKEN_MAX_AGE,
  defaultPollingInterval: Number(appConfig.POLLING_REFRESH_DELAY),
  refreshTokenMaxAge: appConfig.REFRESH_TOKEN_MAX_AGE,
  stayLoggedInMaxAge: appConfig.REMEMBER_ME_MAX_AGE,
};

class LinkedSessionStorage implements SessionStorage {
  async get(type: UserTokenType): Promise<string> {
    let foundToken: string;
    if (type === 'BASIC') {
      foundToken = userTokenInfoStorage.get('user-token');
    } else {
      return userTokenInfoStorage.get('refreshToken');
    }
    return foundToken;
  }

  async set(type: UserTokenType, token: string) {
    const isRememberMe = userTokenInfoStorage.get('isRememberMe');
    const options = {
      maxAge: sdkConfig.refreshTokenMaxAge,
    };
    if (parseStringToBoolean(isRememberMe)) {
      options.maxAge = sdkConfig.stayLoggedInMaxAge;
    }

    // allowed the SDK to store token automatically
    if (type === 'BASIC') {
      userTokenInfoStorage.set('user-token', token, options);
    } else {
      userTokenInfoStorage.set('refreshToken', token, options);
    }
  }

  // remove ONLY raw
  async remove(type?: UserTokenType): Promise<void> {
    if (!type || type === 'BASIC') userTokenInfoStorage.clear('user-token');
    if (!type || type === 'REFRESH') userTokenInfoStorage.clear('refreshToken');
  }

  async loggedOut() {    
  }
}

// L33, update more specific token expiry into SDK to minimize token refresh
const sdk = new CakeClientSdk(sdkConfig, new LinkedSessionStorage());

export default sdk;
