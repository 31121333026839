import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { goToModal, goToNextLesson, goToNextLessonAndUpdateProgress, goToPreviousLesson, goToPreviousLessonAndUpdateProgress, saveCurrentProgressForModal, setQuizInput } from 'learn-earn/learnEarnSlice';
import { ModalHeader, ModalContent, ModalFooter } from 'modals/components';
import { useCakeSelector } from 'app/rootReducer';
import { getQuizStatus, getCurrentLessonData, getDynamicPercentageRates } from 'learn-earn/learnEarnSelector';
import ModalFooterButton from 'modals/components/ModalFooterButton';
import modalStyles from 'common/styles/Modal.styles';
import DOMPurify from 'dompurify';// eslint-disable-line
import Variables, { getMinWidthSm } from 'Variables.styles';
import { ModalType } from './LessonModal';

const useStyles = createUseStyles({
  ...modalStyles,
  imageCard: {
    border: Variables.primaryBorder,
    borderRadius: '10px',
    width: '100%',
  },
  alignRight: {
    justifyContent: 'flex-end;',
  },
  lessonContent: {
    minHeight: '120px',
    marginBottom: '16px',
  },
  lessonContentHeight: {
    minHeight: '200px',
    marginBottom: '20px',
  },
  secondaryTheme: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: Variables.primaryBorder,
  },
  [getMinWidthSm]: {
    lessonContentHeight: {
      minHeight: '500px',
    },
  },
});

interface LessonModalLessonProps {
  closeModal: () => void;
  headerTitle: string;
}

const LessonModalLesson = ({ closeModal, headerTitle }: LessonModalLessonProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details: userDetails } = useCakeSelector(state => state.user);
  const {
    partData,
    nextPartText,
    isFirstLessonFirstPart,
    isLastLesson,
  } = useCakeSelector(getCurrentLessonData);
  const { isAbleToTakeQuiz, quizCorrectAnswer } = useCakeSelector(getQuizStatus);
  const dynamicPercentageRates = useCakeSelector(getDynamicPercentageRates);
  const { t } = useTranslation();
  const sanitizer = DOMPurify.sanitize;

  useEffect(() => {
    if (userDetails) {
      dispatch(saveCurrentProgressForModal({ modal: 'LESSON' }));
    }
  }, [dispatch, userDetails]);

  const onClickBack = () => {
    if (userDetails) {
      dispatch(goToPreviousLessonAndUpdateProgress());
    } else {
      dispatch(goToPreviousLesson());
    }
  };

  const onClickNext = () => {
    if (userDetails) {
      dispatch(goToNextLessonAndUpdateProgress());
    } else {
      dispatch(goToNextLesson());
    }
  };

  const quizButtonText = isAbleToTakeQuiz ? 'Take the quiz' : 'See quiz result';
  const onClickQuiz = () => {
    if (isAbleToTakeQuiz) {
      dispatch(goToModal(ModalType.QUIZ_MODAL));
    } else {
      dispatch(setQuizInput(quizCorrectAnswer));
      dispatch(goToModal(ModalType.RESULT_MODAL));
    }
  };

  if (!partData) {
    return null;
  }

  return (
    <>
      <ModalHeader title={headerTitle} onClose={closeModal}/>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.lessonContentHeight}`}>
          <h3><Trans>Part {{ number: partData.partIndex }}</Trans></h3>
          <div className={classes.lessonContent} dangerouslySetInnerHTML={{ __html: t(sanitizer(partData.content), dynamicPercentageRates) }}/> {/* nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
 */}
          <img
            src={partData.image}
            alt="Lesson"
            className={classes.imageCard}
          />
        </div>
      </ModalContent>
      <ModalFooter styleType="footerSpaceBetween">
        { isFirstLessonFirstPart ? <div /> : <ModalFooterButton primary className={classes.secondaryTheme} buttonText="Back" onClick={onClickBack} arrow="left"/> }
        {
          isLastLesson ? <ModalFooterButton primary onClick={onClickQuiz} buttonText={quizButtonText}/>
            : <ModalFooterButton primary className={classes.secondaryTheme} onClick={onClickNext} buttonText={t('Go to part {{nextPartText}}', { nextPartText })} arrow="right"/>
        }
      </ModalFooter>
    </>
  );
};

export default LessonModalLesson;
