import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sdk from '../sdk';
import { getOffset } from '../utils';

export const REFERRER_PER_PAGE = 5; // for leaderboard
export const REFERRED_USERS_PER_PAGE = 5; // for referred users

const initialState = {
  referredUsers: [],
  leaderBoard: null,
  total: null,
  referralId: null,
  isLoading: false,
  page: 1,
  referredUsersPage: 1,
  isReferredUsersLoading: false,
  totalReferredUsers: null,
};

export const loadReferralId = createAsyncThunk('referral/referralId', () => sdk.ReferralPageApi.referralId());

export const fetchLeaderBoard = createAsyncThunk('referral/leaderboard', (_, thunkAPI) => {
  const { referral: { page } } = thunkAPI.getState();
  const offset = getOffset(page, REFERRER_PER_PAGE);
  return sdk.ReferralPageApi.referralLeaderBoard(offset, REFERRER_PER_PAGE);
});

export const fetchReferredUsers = createAsyncThunk('referral/users', (_, thunkAPI) => {
  const { referral: { referredUsersPage } } = thunkAPI.getState();
  const offset = getOffset(referredUsersPage, REFERRED_USERS_PER_PAGE);
  return sdk.ReferralPageApi.referralUsers(offset, REFERRED_USERS_PER_PAGE);
});

export const fetchLeaderBoardAuthenticated = createAsyncThunk('referral/leaderboardAuthenticated', (_, thunkAPI) => {
  const { referral: { page } } = thunkAPI.getState();
  return sdk.ReferralPageApi.referralLeaderBoardAuthenticated(
    0,
    page * REFERRER_PER_PAGE,
  );
});

const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    setNextPage: (state) => {
      state.page += 1;
    },
    setReferredUsersPage: (state, action) => {
      state.referredUsersPage = action.payload;
    },
    clearReferralSlice: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchReferredUsers.pending]: (state) => {
      state.isReferredUsersLoading = true;
    },
    [fetchReferredUsers.fulfilled]: (state, action) => {
      const { referredUsers, count } = action.payload;
      state.referredUsers = referredUsers;
      state.totalReferredUsers = count;
      state.isReferredUsersLoading = false;
    },
    [fetchReferredUsers.rejected]: (state) => {
      state.isReferredUsersLoading = false;
    },
    [fetchLeaderBoard.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchLeaderBoard.fulfilled]: (state, action) => {
      const { leaderBoard } = state;
      const { referrers, total } = action.payload;
      state.leaderBoard = leaderBoard ? [...leaderBoard, ...referrers] : referrers;
      state.total = total;
      state.isLoading = false;
    },
    [fetchLeaderBoard.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchLeaderBoardAuthenticated.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchLeaderBoardAuthenticated.fulfilled]: (state, action) => {
      const { referrers, total } = action.payload;
      state.leaderBoard = referrers;
      state.total = total;
      state.isLoading = false;
    },
    [fetchLeaderBoardAuthenticated.rejected]: (state) => {
      state.isLoading = false;
    },
    [loadReferralId.fulfilled]: (state, action) => {
      state.referralId = action.payload.referralId;
    },
  },
});

export const { setNextPage, setReferredUsersPage, clearReferralSlice } = referralSlice.actions;

export default referralSlice.reducer;
