import { push } from './dataLayer';

// move to SDK when doing mobile implementation
export enum iEventNames {
  'start_sign_up' = 'cake.start_sign_up',
  'initiate_sign_up' = 'cake.initiate_sign_up',
  'email_verified' = 'cake.email_verified',
  'initiate_mobile_number_verification' = 'cake.initiate_mobile_number_verification',
  'postpone_mobile_verification' = 'cake.postpone_mobile_verification',
  'submit_mobile_number' = 'cake.submit_mobile_number',
  'retry_mobile_verification' = 'cake.retry_mobile_verification',
  'mobile_number_verified' = 'cake.mobile_number_verified',
  'country_verified' = 'cake.country_verified',
  'initiate_identity_verification' = 'cake.initiate_identity_verification',
  'identity_verified' = 'cake.identity_verified',
  'intent_to_deposit_currency' = 'cake.intent_to_deposit_currency',
  'allocate_product' = 'cake.allocate_product',
}

// move to SDK when doing mobile implementation
export enum iEventTypes {
  custom = 'custom',
}

export interface iEventProps {
  'event'?: string;
  'event_type'?: string;
  'event_data'?: null | {
    'button_place?': string
    'button_click_text?': string,
    'button_click_link?': string,
  };
  'user_id'?: string;
}

const trackEvent = (eventProps: iEventProps) => {
  const eventPayload = {
    event: eventProps?.event,
    event_type: 'custom',
    event_data: eventProps?.event_data,
    user_id: eventProps?.user_id,
  };

  push({ event_data: null });
  push(eventPayload);
};

export default trackEvent;
