import { getMinMaxForPaymentMethod } from './getMinMaxForPaymentMethod';

export const getBanxaMinMaxPaymentMethod = (paymentMethod, fiatAmount) => {
  if (!paymentMethod) {
    return {
      isFiatAmountValid: true,
    };
  }
  const [{ min, max }] = paymentMethod.transactionLimits;

  if (fiatAmount === '') {
    return {
      isFiatAmountValid: true,
      paymentMethodMinAmount: min,
      paymentMethodMaxAmount: max,
    };
  }
  return getMinMaxForPaymentMethod(min, max, fiatAmount);
};
