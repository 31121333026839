import BigNumber from 'bignumber.js';

export const isAmountWithinPaymentMethodLimit = (
  min: number,
  max: number,
  amount: number | string,
) => {
  const amountBn = new BigNumber(amount);
  return amountBn.isGreaterThanOrEqualTo(min) && amountBn.isLessThanOrEqualTo(max);
};
