import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { CoinPrice, CurrencyRates } from '@cakedefi/cake-sdk/schema';
import { userDetailSelector, userPreferredCurrencySelector } from 'user/userSelector';
import convertUSDToPreferredCurrency from 'utils/convertUSDToPreferredCurrency';
import { walletUserPortfolioSelector } from 'wallet/walletSelectors';
import { currencyFiatRatesSelector, currencyPricingSelector, getPricingForCoin } from '../currency/currencySelector';
import { getValueInPreferredCurrency, isEmpty, isPositiveAndNotZeroNumber } from '../utils';
import { portfolioSelector } from '../wallet/selectors/portfolioSelector';
import { balanceSummarySelector, totalFrozenLiquidityInPreferredCurrencySelector } from './balanceSummarySelector';

const generateBalanceStats = (
  portfolio: any[],
  totalPortfolioValueInUsd: string,
  usdPricePerBTC: string,
  preferredCurrency: string,
  currencyRates: CurrencyRates['rates'],
  pricing: CoinPrice[],
  totalAllocatedValueInUsd: string,
  totalBundleValueInUsd: string,
  totalAvailableValueInUsd: string,
) => {
  const allocatedAmount = BigNumber.sum(
    convertUSDToPreferredCurrency(totalAllocatedValueInUsd, usdPricePerBTC, preferredCurrency, currencyRates) ?? new BigNumber(0),
    convertUSDToPreferredCurrency(totalBundleValueInUsd, usdPricePerBTC, preferredCurrency, currencyRates) ?? new BigNumber(0),
  );
  const availableAmount = new BigNumber(
    convertUSDToPreferredCurrency(totalAvailableValueInUsd, usdPricePerBTC, preferredCurrency, currencyRates) ?? new BigNumber(0),
  );
  let stakingFrozenAmount = new BigNumber(0);
  const totalAmount = new BigNumber(
    convertUSDToPreferredCurrency(totalPortfolioValueInUsd, usdPricePerBTC, preferredCurrency, currencyRates) ?? new BigNumber(0),
  );
  portfolio.forEach((item) => {
    const priceUSD = pricing?.find(({ CoinId }) => CoinId === item.id)?.priceUSD?.avg ?? item.priceUSD;

    if (isPositiveAndNotZeroNumber(item.share.frozen)) {
      const itemAmountPerPrice = getValueInPreferredCurrency(item.share.frozen, priceUSD, usdPricePerBTC, preferredCurrency, currencyRates);
      stakingFrozenAmount = stakingFrozenAmount.plus(itemAmountPerPrice ?? 0);
    }
  });
  return { totalAmount, allocatedAmount, availableAmount, stakingFrozenAmount };
};

export const balanceInPreferredCurrencySelector = createSelector(
  userDetailSelector,
  balanceSummarySelector,
  currencyFiatRatesSelector,
  getPricingForCoin('BTC'),
  portfolioSelector,
  currencyPricingSelector,
  userPreferredCurrencySelector,
  totalFrozenLiquidityInPreferredCurrencySelector,
  walletUserPortfolioSelector,
  (user, balances, currencyRates, usdPricePerBTC, portfolio, pricing, preferredCurrency, lmFrozenAmount, walletUserPortfolio) => {
    const shouldConvertCurrency = preferredCurrency !== 'USD';
    const cannotConvertCurrency = shouldConvertCurrency && isEmpty(currencyRates);

    if (!user || !balances || !walletUserPortfolio || !portfolio || cannotConvertCurrency || !lmFrozenAmount) {
      return {};
    }

    const {
      totalPortfolioValueInUsd,
      totalAllocatedValueInUsd,
      totalBundleValueInUsd,
      totalAvailableValueInUsd,
    } = walletUserPortfolio;

    const {
      totalAmount,
      allocatedAmount,
      availableAmount,
      stakingFrozenAmount,
    } = generateBalanceStats(
      portfolio,
      totalPortfolioValueInUsd,
      usdPricePerBTC,
      preferredCurrency,
      currencyRates,
      pricing,
      totalAllocatedValueInUsd,
      totalBundleValueInUsd,
      totalAvailableValueInUsd,
    );
    const frozenAmount = lmFrozenAmount.plus(stakingFrozenAmount);

    return {
      totalAmount,
      allocatedAmount,
      availableAmount,
      frozenAmount,
      preferredCurrency,
    };
  },
);
