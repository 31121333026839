import { Network } from '../../schema/network';
import { CakeApiBase } from '../CakeApiBase';

export class NetworkApi extends CakeApiBase {
  async getNetworks() {
    return this.requestManager.get<Network[]>('/networks');
  }

  async getNetworksForCoin(coinId: string) {
    return this.requestManager.get<Network[]>(`/networks/${coinId}`);
  }
}
