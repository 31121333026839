export type SocialMediaType = 'reddit' | 'instagram' | 'facebook' | 'twitter' | 'linkedin' | 'discord' | 'telegram_announcement' | 'youtube';

export default function getSocialMediaLinks(type: SocialMediaType): string {
  switch (type) {
    case 'reddit':
      return 'https://www.reddit.com/r/Bakeio/';
    case 'instagram':
      return 'https://www.instagram.com/cakedefi/';
    case 'facebook':
      return 'https://www.facebook.com/bake.io.official';
    case 'twitter':
      return 'https://twitter.com/bake_io';
    case 'linkedin':
      return 'https://www.linkedin.com/company/bake-io';
    case 'discord':
      return 'https://discord.gg/qVDjc7j';
    case 'telegram_announcement':
      return 'https://t.me/Bake_EN_announcements';
    case 'youtube':
      return 'https://www.youtube.com/@bake_io';
    default:
      return '';
  }
}
