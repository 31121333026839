export default class InvalidBigNumberError extends Error {
  fieldName: string;

  constructor(fieldName?: string) {
    /**
     * expected usage
     * BigNumber(abc).isNaN()
     * new InvalidBigNumberError('abc');
     */
    super('InvalidBigNumberError');
    this.fieldName = fieldName;
  }
}
