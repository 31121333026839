import React from 'react';
import { Trans } from 'react-i18next';
import { getQueryParam } from 'utils/getQueryParam';
import Banner from '../../components/Banner/Banner';
import { BannerType } from '../../../types/banner/banner';

export default function SignupBonusNonLogin({ buttons, isThirdAnniversaryPromoActive }) {
  const utmSource = getQueryParam('utm_source');
  const isBraveSource = utmSource === 'brave';
  if (isBraveSource) {
    return null;
  }

  if (isThirdAnniversaryPromoActive) {
    return (
      <Banner type={BannerType.Info} actionTemplate={buttons}>
        <Trans>JOIN our 3-Year Anniversary Promo for a chance to WIN $3,333. Click here to participate!</Trans>
      </Banner>
    );
  }

  return (
    <Banner type={BannerType.Info} actionTemplate={buttons}>
      <Trans>Enjoy $30 in DFI when you make a first-time investment of $50 and more!</Trans>
    </Banner>
  );
}
