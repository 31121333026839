import React, { Context, createContext } from 'react';
import ModalDialog from './components/ModalDialog';
import useModal, { UseModalTypes } from './useModal';

const ModalContext: Context<UseModalTypes> = createContext({} as UseModalTypes);
const { Provider } = ModalContext;

const ModalProvider = ({ children }) => {
  const { isShowModal, openModal, closeModal, modalContent } = useModal();

  return (
    <Provider value={{ isShowModal, openModal, closeModal, modalContent }}>
      <ModalDialog />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
