import Variables from '../../../Variables.styles';

export default {
  footerLinksContainer: {
    composes: ['row'],
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gap: Variables.medium,
  },
  footerLinks: {
    '& h4': {
      padding: '16px 0',
      marginBottom: '16px',
      borderBottom: '1px solid rgba(255,255,255,.2)',
      lineHeight: '2.4rem',
      fontWeight: '600',
      fontSize: '2rem',
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      marginBottom: '32px',
    },
    '& li': {
      display: 'block',
    },
    '& a, & span': {
      display: 'block',
      color: 'rgba(0,0,0,.6)',
      textDecoration: 'none',
      padding: '4px 0',
      '&:hover': {
        backgroundColor: 'transparent',
        color: Variables.black,
      },
    },
  },
  groupedLinks: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
  },
  footerLogo: {
    marginTop: '56px',
    marginBottom: '32px',
  },
  footerContainer: {
    composes: ['container'],
    marginBottom: Variables.large,
  },
  footerBottomContainer: {
    flex: 1,
  },
  cakeDescriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  cakeDescription: {},
  flexLogo: {
    flexShrink: 0,
    width: 'auto !important',
  },
  cakeUen: {
    fontWeight: '500',
    marginBottom: '1.6rem',
  },
  copyright: {
    fontWeight: '500',
  },
  companyInfoContainer: {
    marginBottom: Variables.larger,
  },
  companyInfo: {
    fontSize: '1.2rem',
    marginBottom: Variables.extraSmall,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
};
