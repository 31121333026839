import React, { Fragment } from 'react';
import { createUseStyles } from 'react-jss';

const styles = {
  mainContainer: {
    marginBottom: 30,
    paddingLeft: 16,
  },
  subContainer: {
    marginLeft: 20,
  },
  secondSubContainer: {
    marginLeft: 36,
  },
  descriptionText: {
    textIndent: -16,
  },
  largeSpace: {
    marginBottom: 20,
  },
  mediumSpaceBold: {
    marginBottom: 6,
    fontWeight: 'bold',
  },
  largeSpaceBoldFontH3: {
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  table: {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
  },
  tableRow: {
    borderBottom: '1px solid black',
  },
  tableCell: {
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
    verticalAlign: 'baseline',
  },
  jsonHtmlContent: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
  },
  jsonHtmlList: {
    '& li': {
      fontSize: '1.6rem',
      fontWeight: 'normal',
      display: 'list-item',
    },
  },
};

export interface JsonBodySimpleElementProps {
  text: string;
}

export interface JsonHtmlListProps {
  ordered?: boolean;
  contents: JsonHtmlContentProps[];
}
export interface JsonHtmlContentProps {
  header?: string;
  text?: string;
  paragraph?: string;
  list?: JsonHtmlListProps;
}

export interface JsonHtmlTableCellProps {
  contents: JsonHtmlContentProps[];
}
export interface JsonHtmlTableRowProps {
  cells: JsonHtmlTableCellProps[];
}
export interface JsonHtmlTableProps {
  rows: JsonHtmlTableRowProps[];
}
export interface JsonBodyElementProps {
  title1: string;
  body1: [
    {
      title2: string;
      preTitle: string;
      desc2: [
        {
          text: string;
          style: string;
          table?: JsonHtmlTableProps;
          desc3?: {
            text: string;
            style: string;
            desc4?: {
              text: string;
              style: string;
              desc3?: {
                text: string;
                style: string;
              }[];
            }[];
          }[];
        }
      ];
    }
  ];
}

const useStyles = createUseStyles(styles);

const JSONtoHTMLComponent = ({ jsonFile }: { jsonFile: JsonBodyElementProps[] }) => {
  const classes = useStyles();

  return (
    <>
      {
        jsonFile.map((item, index: number) => ( // element immutable we can use index like a key
          <div key={index} className={classes.mainContainer}>
            <h2>{item.title1}</h2>
            <br />
            {
              item.body1 && (
                <div className={classes.subContainer}>
                  {item.body1.map((bodyItem, index1) => (
                    <Fragment key={index1}>
                      {bodyItem.preTitle && (
                        <>
                          <br />
                          <div>{bodyItem.preTitle}</div>
                        </>
                      )}
                      {bodyItem.title2 && (
                        <>
                          <br />
                          <h3>{bodyItem.title2}</h3>
                        </>
                      )}
                      {bodyItem.desc2?.map((textItem2, index2) => (
                        <Fragment key={index2}>
                          <div className={textItem2?.style ? classes[textItem2.style] : ''}>
                            {textItem2?.text}
                          </div>
                          {
                            textItem2?.desc3?.length ? (
                              <div className={classes.secondSubContainer}>
                                {textItem2.desc3.map((textItem3, index3) => (
                                  <Fragment key={index3}>
                                    <div className={`${textItem3?.style ? classes[textItem3.style] : ''} ${classes.descriptionText}`}>
                                      {textItem3?.text}
                                    </div>
                                    {
                                      textItem3?.desc4?.length ? (
                                        <div className={classes.secondSubContainer}>
                                          {textItem3.desc4.map((textItem4, index4) => (
                                            <div key={index4} className={classes.descriptionText}>{textItem4?.text}</div>
                                          ))}
                                        </div>
                                      ) : null
                                    }
                                  </Fragment>
                                ))}
                              </div>
                            ) : null
                          }
                          {textItem2.table && <JsonHtmlTable {...textItem2.table}></JsonHtmlTable>}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </div>
              )
            }
          </div>
        ))
      }
    </>
  );
};

function JsonHtmlTable(props: JsonHtmlTableProps) {
  const classes = useStyles();
  return <table className={classes.table}>
    {props.rows.map(row => <JsonHtmlTableRow {...row} />)}
  </table>;
}

function JsonHtmlTableRow(props: JsonHtmlTableRowProps) {
  const classes = useStyles();
  return <tr className={classes.tableRow}>
    {props.cells.map(cell => <JsonHtmlTableCell {...cell} />)}
  </tr>;
}

function JsonHtmlTableCell(props: JsonHtmlTableCellProps) {
  const classes = useStyles();
  return <td className={classes.tableCell}>
    {props.contents.map(content => <JsonHtmlContent {...content} />)}
  </td>;
}

function JsonHtmlContent(props: JsonHtmlContentProps) {
  const classes = useStyles();
  return <div className={classes.jsonHtmlContent}>
    {props.header && <h4 >{props.header}</h4>}
    {props.text}
    {props.paragraph && <p >{props.paragraph}</p>}
    {props.list && <JsonHtmlList {...props.list} />}
  </div>;
}

function JsonHtmlList(props: JsonHtmlListProps) {
  const { contents = [], ordered = false } = props;
  const classes = useStyles();
  const listContent = contents.map(content => <li ><JsonHtmlContent {...content} /></li>);
  if (!ordered) {
    return <ol className={classes.jsonHtmlList}>{listContent}</ol>;
  }
  return <ul className={classes.jsonHtmlList}>{listContent}</ul>;
}

export default JSONtoHTMLComponent;
