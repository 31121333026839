import moment from 'moment';
import { getLanguageButExcludeGerman } from 'utils/getLanguageButExcludeGerman';
import { getMomentLangFromi18n, browserLangToi18nLang } from '../../utils';
import i18n from '../../i18n';

// complicated logics should be placed here

export const initLanguageFromBrowserSettings = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const language = browserLangToi18nLang(browserLanguage || 'en');
  return getLanguageButExcludeGerman(language);
};

export const getPreferredLanguage = language => {
  const preferredLanguage = language === 'cn' ? 'zht' : language;
  return getLanguageButExcludeGerman(preferredLanguage);
};

export const getZendeskLanguage = (language) => {
  if (language === 'zht') {
    return 'zh-Hant';
  }
  return language;
};

export const setZendeskLanguage = (language) => {
  if (window.zE) {
    const currentLanguage = getZendeskLanguage(language);
    const args = ['webWidget', 'setLocale', currentLanguage];
    window.zE(...args);
  }
};

export const setMomentLanguage = (language) => {
  const momentLanguage = getMomentLangFromi18n(language);
  moment.locale(momentLanguage);
};

export const setAllLanguages = (language) => {
  const preferredLanguage = getLanguageButExcludeGerman(language);
  i18n.changeLanguage(preferredLanguage);
  setMomentLanguage(preferredLanguage);
  setZendeskLanguage(preferredLanguage);
};
